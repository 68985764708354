import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const allocQExpandedListAtom = atom({
  key: "alloc-q-expanded-list",
  default: [],
  effects_UNSTABLE: [persistAtom],
});
