import React from "react";
import "./index.less";

interface ISelectedStyleColorSizePicker {
  label: string;
  selected?: string;
  options?: string[];
  overAllocatedSizes?: string[];
  onClick?: (size: string) => void;
}

const SelectedStyleColorSizePicker:React.FC<ISelectedStyleColorSizePicker> = ({
  label,
  selected,
  options,
  overAllocatedSizes,
  onClick,
}) => {
  return (
    <div className="selected-style-color-size-picker">
      {label && <div className="label">{label}</div>}
      {options && (
      <div className="sizes-wrapper">
        {options.map((size) => {
          return (
            <div
              key={size}
              className={`size ${selected === size ? "active" : ""} ${overAllocatedSizes?.includes(size) ? "is-over-allocated" : ""}`}
              onClick={() => onClick?.(size)}
            >
              {size}
            </div>
          );
        })}
      </div>
      )}
    </div>
  );
};

export default SelectedStyleColorSizePicker;
