const decideDepartmentName = (
  name?: string, isApparelAndAccessoriesMerged?: boolean, fallback?: string,
) => {
  switch (name) {
    case "FOOTWEAR": return "Footwear";
    case "APPARELS": return isApparelAndAccessoriesMerged ? "Apparel & Accessories" : "Apparel";
    case "ACCESSORIES": return isApparelAndAccessoriesMerged ? "Apparel & Accessories" : "Accessories";
    case "HOME_CARE": return "All Homecare";
    case "COLD_WEATHER": return "Cold Weather";
    default: return fallback || "N/A";
  }
};

export default decideDepartmentName;
