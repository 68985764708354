import React from "react";
import "./index.less";
import { DatePicker } from "antd";
import { Moment } from "moment";
import Icon from "../Icon";

interface IDartDatepicker {
  label?: string;
  disabled?: boolean;
  value?: Moment;
  onChange?: (changeEvent: any) => void;
  placeholder?: string;
  format?: string;
  pickerProps?: any;
}

const DartDatepicker: React.FC<IDartDatepicker> = ({
  label,
  disabled = false,
  value,
  onChange,
  placeholder,
  format = "MM/DD/YYYY",
  pickerProps = {},
}) => {
  const inputId = `f${(Math.random() * 10000).toString()}`;

  return (
    <div className="dart-datepicker">
      {label ? (
        <label
          htmlFor={inputId}
          className="dart-datepicker-label"
        >
          {label}
        </label>
      ) : ""}
      <DatePicker
        id={inputId}
        format={format}
        suffixIcon={(
          <Icon
            name="Date"
            color="#000000"
            size={19}
          />
        )}
        value={value || null}
        onChange={(v) => onChange?.(v)}
        placeholder={placeholder || ""}
        disabled={disabled}
        showToday={false}
        getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
        dropdownClassName="dart-datepicker-panel"
        {...pickerProps}
        className={`dart-datepicker-target ${pickerProps.className || ""}`}
      />
    </div>
  );
};

export default DartDatepicker;
