const sortBySize = (objArr: any[], keyExtractor = "size_value") => {
  const cloned = objArr.map((v) => ({ ...v }));
  cloned.sort((a: any, b: any) => {
    return sizeValueToLabel(a[keyExtractor]) - sizeValueToLabel(b[keyExtractor]);
  });
  return cloned;
};

// Numerical Sizes stays numerical, but string sizes are converted to number with half sizes in between
const sizeValueToLabel = (value: string | number) => {
  switch (value) {
    case "XXXS": return 0;
    case "XXXS/XXS": return 1;
    case "XXS": return 2;
    case "XXS/XS": return 3;
    case "XS": return 4;
    case "XS/S": return 5;
    case "S": return 6;
    case "S/M": return 7;
    case "M": return 8;
    case "M/L": return 9;
    case "L": return 10;
    case "L/XL": return 11;
    case "XL": return 12;
    case "XL/XXL": return 13;
    case "XXL": return 14;
    case "XXL/XXXL": return 15;
    case "XXXL": return 16;
    case "XXXL/XXXXL": return 17;
    case "XXXXL": return 18;
    case "O/S": return 19;
    default: return +value;
  }
};

export default sortBySize;
