import { Button } from "antd";
import React, { useMemo } from "react";
import moment from "moment";
import Icon from "../../../../components/Icon";
import DartButton from "../../../../components/DartButton";
import DartInput from "../../../../components/DartInput";
import DartSelect from "../../../../components/DartSelect";
import { capitalize, renderTag } from "../../../../utils/helpers";
import "./index.less";

interface IAllocQFiltersBlock {
  filtersExpanded: boolean;
  setFiltersExpanded: (value: boolean) => void;
  filteredCount?: number;
  totalCount?: number;
  updatesDone: number;
  loading: boolean;
  setDiscardModalVisible: (value: boolean) => void;
  handleSaveAllocQChanges: () => void;
  appliedDataFilters: any;
  setAppliedDataFilters: (value: any) => void;
  filterListAction: () => void;
  division: any;
  department: any;
  tier: any;
  tags?: string[];
  newnessDates?: string[];
  wasTouched: boolean;
}

const AllocQFiltersBlock:React.FC<IAllocQFiltersBlock> = ({
  filtersExpanded,
  setFiltersExpanded,
  filteredCount,
  totalCount,
  updatesDone,
  loading,
  setDiscardModalVisible,
  handleSaveAllocQChanges,
  appliedDataFilters,
  setAppliedDataFilters,
  filterListAction,
  division,
  department,
  tier,
  tags,
  newnessDates,
  wasTouched,
}) => {
  // Decide to show or hide reset button using this memoized boolean
  const isNotDefaultValues = useMemo(() => {
    return (
      appliedDataFilters.search !== "" ||
      appliedDataFilters.division !== "ALL" ||
      appliedDataFilters.department !== "ALL" ||
      appliedDataFilters.tier !== "ALL" ||
      appliedDataFilters.tag !== "ALL" ||
      appliedDataFilters.uas !== "ALL" ||
      appliedDataFilters.newness_date !== "ALL" ||
      appliedDataFilters.sort_by !== "None"
    );
  }, [appliedDataFilters]);

  return (
    <div className={`alloc-q-filters-block ${filtersExpanded ? "open" : ""}`}>
      <div className="container-content">
        <div className="top">
          <div className="left">
            <Button
              type="ghost"
              onClick={() => setFiltersExpanded(!filtersExpanded)}
            >
              <Icon
                name="Filters"
                size={18}
                color="#333E47"
              />
              Search & Filters
            </Button>
            <div className="vertical-splitter" />
            {filteredCount !== undefined && totalCount !== undefined ? (
              <div className="filtered-store-counter">
                {`${filteredCount} style colors${filteredCount !== totalCount
                  ? ` (${totalCount - filteredCount} hidden)`
                  : ""}
             `}
              </div>
            ) : "" }
          </div>
          <div className="right">
            {filtersExpanded ? (
              <Button
                className="close-button"
                onClick={() => setFiltersExpanded(false)}
              >
                <Icon
                  name="Close"
                  color="#000000"
                  size={14}
                />
              </Button>
            ) : (
              <>
                {updatesDone > 0 && (
                  <DartButton
                    className="discard-changes-button"
                    onClick={() => setDiscardModalVisible(true)}
                    label="Discard Changes"
                  />
                )}
                <DartButton
                  disabled={(updatesDone < 1 || loading) && !wasTouched}
                  loading={loading}
                  onClick={handleSaveAllocQChanges}
                  label="Save Changes"
                />
              </>
            )}
          </div>
        </div>
        <div className="bottom">
          <div className="flex-column-2">
            <div className="bottom-left">
              <h2>Enter a style number, style-color, description or class</h2>
              <div className="search-input-wrapper">
                <DartInput
                  placeholder="e.g 1113190, 1113190-BLK, Adirondack Boot III or Slipper"
                  value={appliedDataFilters.search}
                  onChange={(e: any) => {
                    setAppliedDataFilters({ ...appliedDataFilters, search: e.target.value });
                  }}
                  action={filterListAction}
                  actionButton={(
                    <Icon
                      name="Search"
                      size={20}
                    />
                )}
                />
              </div>
            </div>
            <div className="bottom-right">
              <h2>Sort By</h2>
              <div className="sort-input-wrapper">
                <DartSelect
                  label=""
                  placeholder="All"
                  selectOptions={[
                    { label: "None", value: "None" },
                    { label: "UAS Ascending", value: "UAS_ASC" },
                    { label: "UAS Descending", value: "UAS_DESC" },
                    { label: "Initial Qty Ascending", value: "QTY_ASC" },
                    { label: "Initial Qty Descending", value: "QTY_DESC" },
                  ]}
                  onChange={(val) => {
                    setAppliedDataFilters({ ...appliedDataFilters, sort_by: val });
                  }}
                  value={appliedDataFilters.sort_by}
                  canClear={false}
                  showSearch={false}
                />
              </div>
            </div>
          </div>
          <h2>Filter By</h2>
          <div className="filter-input-wrapper">
            <DartSelect
              label="Division"
              placeholder="All"
              selectOptions={[
                { label: "All", value: "ALL" },
                ...(Object.values(division || {})?.map((each: any) => ({ label: capitalize(each), value: each }))),
              ]}
              onChange={(val) => {
                setAppliedDataFilters({ ...appliedDataFilters, division: val });
              }}
              value={appliedDataFilters.division}
              canClear={false}
              showSearch={false}
            />
            <DartSelect
              label="Department"
              placeholder="All"
              selectOptions={[
                { label: "All", value: "ALL" },
                ...(Object.values(department || {})?.map((each: any) => ({ label: capitalize(each), value: each }))),
              ]}
              onChange={(val) => {
                setAppliedDataFilters({ ...appliedDataFilters, department: val });
              }}
              value={appliedDataFilters.department}
              canClear={false}
              showSearch={false}
            />
            <DartSelect
              label="Tier"
              placeholder="All"
              selectOptions={[
                { label: "All", value: "ALL" },
                ...(Object.values(tier || {})?.map((each: any) => ({ label: capitalize(each), value: each }))),
              ]}
              onChange={(val) => {
                setAppliedDataFilters({ ...appliedDataFilters, tier: val });
              }}
              value={appliedDataFilters.tier}
              canClear={false}
              showSearch={false}
            />
            <DartSelect
              label="Tag"
              placeholder="All"
              selectOptions={[
                { label: "All", value: "ALL" },
                ...(Object.values(tags || {})?.map((each: any) => ({ label: renderTag(each), value: each }))),
              ]}
              onChange={(val) => {
                setAppliedDataFilters({ ...appliedDataFilters, tag: val });
              }}
              value={appliedDataFilters.tag}
              canClear={false}
              showSearch={false}
            />
            <DartSelect
              label="UAS"
              placeholder="All"
              selectOptions={[{ label: "All", value: "ALL" }, { label: "With UAS", value: "true" }, { label: "Without UAS", value: "false" }]}
              onChange={(val) => {
                setAppliedDataFilters({ ...appliedDataFilters, uas: val });
              }}
              value={appliedDataFilters.uas}
              canClear={false}
              showSearch={false}
            />
            <DartSelect
              label="Final ISD"
              placeholder="All"
              selectOptions={[
                { label: "All", value: "ALL" },
                ...(Object.values(newnessDates || {})?.map((each: any) => ({ label: moment(each).format("MM/DD/YYYY"), value: each }))),
              ]}
              onChange={(val) => {
                setAppliedDataFilters({ ...appliedDataFilters, newness_date: val });
              }}
              value={appliedDataFilters.newness_date}
              canClear={false}
              showSearch={false}
            />
          </div>
          <hr className="filters-splitter" />
          <div className="button-wrapper">
            {isNotDefaultValues && (
            <DartButton
              size="lg"
              icon="Refresh"
              onClick={() => {
                setAppliedDataFilters({
                  search: "", uas: "ALL", division: "ALL", department: "ALL", tier: "ALL", tag: "ALL", newness_date: "ALL", sort_by: "None",
                });
              }}
              className="reset-filters-button"
            />
            )}
            <DartButton
              size="lg"
              label="APPLY FILTERS"
              onClick={filterListAction}
              className="apply-filters-button"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllocQFiltersBlock;
