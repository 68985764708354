import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import Icon, { CommonIconTypes } from "../Icon";
import "./index.less";

interface IDartPrompt {
  className?: string;
  visible?: boolean;
  closable?: boolean;
  title?: React.ReactNode | string;
  icon?: CommonIconTypes | JSX.Element;
  content?: React.ReactNode | string;
  okText?: string;
  onOk?: () => any;
  onClose?: () => any;
  cancelText?: string | boolean;
  onCancel?: () => any;
  okButtonProps?: any;
  width?: number;
  askCancel?: boolean;
  maskClosable?: boolean;
}

const DartPrompt:React.FC<IDartPrompt> = ({
  className,
  visible = false,
  closable = true,
  title,
  icon = "Info",
  content,
  okText,
  onOk,
  onClose,
  cancelText,
  onCancel,
  okButtonProps,
  width = 456,
  askCancel = false,
  maskClosable,
}) => {
  const [open, setOpen] = useState(visible);
  const [isDiscarding, setIsDiscarding] = useState(false);
  const [loading, setLoading] = useState(okButtonProps?.loading || false);

  useEffect(() => {
    if (okButtonProps?.hasOwnProperty("loading")) {
      setLoading(okButtonProps?.loading);
    }
  }, [okButtonProps]);

  useEffect(() => {
    setIsDiscarding(false);
    setOpen(visible);
  }, [visible]);

  const handleAskCancel = () => {
    setIsDiscarding(true);
  };

  return (
    <Modal
      wrapClassName={`dart-prompt ${className}`}
      visible={open}
      closable={closable}
      maskClosable={maskClosable === undefined ? closable : maskClosable}
      title={(
        <div className="head-wrapper">
          {icon && typeof icon === typeof "string" ? (
            <Icon
              name={icon as any}
              color="#E87324"
            />
          ) : icon}
          {title && <span className="title">{title}</span>}
        </div>
      )}
      okText={okText}
      onOk={onOk}
      footer={askCancel ? (
        <div className="custom-footer">
          <div className="discard-button">
            {isDiscarding ? (
              <>
                <Button
                  onClick={() => setIsDiscarding(false)}
                  type="default"
                >
                  Cancel
                </Button>
                <Button
                  onClick={onCancel}
                  type="primary"
                >
                  {`Confirm ${cancelText}`}
                </Button>
              </>
            ) : (
              <Button
                onClick={handleAskCancel}
                type="default"
              >
                {cancelText}
              </Button>
            )}
          </div>
          <div className="confirm-button">
            <Button
              onClick={onOk}
              type="primary"
              {...(okButtonProps || {})}
              loading={loading}
            >
              {okText}
            </Button>
          </div>
        </div>
      ) : undefined}
      cancelText={cancelText}
      cancelButtonProps={{ hidden: cancelText === false }}
      onCancel={onCancel}
      closeIcon={<Icon name="Close" />}
      okButtonProps={okButtonProps}
      width={width}
      destroyOnClose
      afterClose={onClose}
    >
      {content}
    </Modal>
  );
};

export default DartPrompt;
