/* eslint-disable no-param-reassign */
import { selector } from "recoil";
import { sizeProfileAtom } from "../atoms";

export const sizeProfileSelector = selector({
  key: "size-profile-selector",
  get: ({ get }) => {
    const atomData = get(sizeProfileAtom);

    return atomData;
  },
});
