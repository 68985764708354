import React, { useMemo } from "react";
import "./index.less";
import { Button, Tag } from "antd";
import { useRecoilValue, useResetRecoilState } from "recoil";
import DartCollapseCheckboxGroup from "../../../../components/DartCollapseCheckboxGroup";
import Icon from "../../../../components/Icon";
import { capitalize } from "../../../../utils/helpers";
import useEnums from "../../../../utils/hooks/useEnums";
import { storeListAtom } from "../../../../global/atoms";
import countryCodeToName from "../../../../utils/helpers/countryCodeToName";
import { storeListFiltersAtom } from "../../../../global/atoms/store-list-filters-atom";
import paths from "../../../../configs/paths";

interface IStoreListFilters {
  storeFilters?: any;
  setStoreFilters?: (filters: any) => void;
  usingQueryFilters?: boolean;
}

const StoreListFilters:React.FC<IStoreListFilters> = ({
  storeFilters,
  setStoreFilters,
  usingQueryFilters,
}) => {
  const {
    country, allocation_channel, brand,
  } = useEnums();
  const storeList = useRecoilValue(storeListAtom);
  const resetStoreListFilters = useResetRecoilState(storeListFiltersAtom);

  const handleFilterSelect = (filterList: { label: string, value: string }[], key: "country" | "channel" | "brand" | "tags") => {
    setStoreFilters?.({
      ...(storeFilters || {}),
      [key]: filterList,
    });
  };

  const getAppliedFilters = () => {
    const filters: JSX.Element[] = [];
    // If using query filters, ignore other filters
    if (usingQueryFilters) {
      filters.push((
        <Tag
          key="advanced-filters"
          closable
          onClose={() => {
            window.location.href = paths.view_all_stores;
          }}
        >
          Advanced Filters Applied
        </Tag>
      ));
      return filters;
    }
    // map applied filter list as jsx elements of Tags. all except "status" filter as it's excluded from the filter list
    Object.keys(storeFilters || {}).forEach((key) => {
      if (storeFilters[key] && storeFilters[key].length && key !== "status") {
        const list = storeFilters[key];
        for (let k = 0; k < list.length; k += 1) {
          const filter = list[k];
          filters.push((
            <Tag
              key={`${key}-${filter.value}`}
              closable
              onClose={() => {
                const newList = list.filter((item: { label: string, value: string }) => item.value !== filter.value);
                handleFilterSelect(newList, key as any);
              }}
            >
              {filter.label}
            </Tag>
          ));
        }
      }
    });
    return filters;
  };

  const handleResetFilters = () => {
    resetStoreListFilters();
    if (usingQueryFilters) {
      window.location.href = paths.view_all_stores;
    }
  };

  const isFilterApplied = useMemo(() => {
    let isApplied = false;
    Object.keys(storeFilters || {}).forEach((key) => {
      if (storeFilters[key] && storeFilters[key].length && key !== "status") {
        isApplied = true;
      }
    });
    return isApplied;
  }, [storeFilters]);

  return (
    <div className="store-list-filters">
      <div className="applied-filters">
        <h2>FILTER BY</h2>
        <div className={`filters ${!isFilterApplied ? "empty" : ""}`}>
          {getAppliedFilters()}
        </div>
        {isFilterApplied && (
        <Button onClick={handleResetFilters}>
          <Icon name="Refresh" />
          <span>Reset filters</span>
        </Button>
        ) }
      </div>
      <hr />
      <DartCollapseCheckboxGroup
        title="Location"
        options={Object.values(country).map((item) => { return { label: countryCodeToName(item), value: item }; })}
        value={storeFilters.country}
        onChange={(values) => handleFilterSelect(values, "country")}
        editable={!["revalidate", "request"].includes(storeList?.status) && !usingQueryFilters}
      />
      <hr />
      <DartCollapseCheckboxGroup
        title="Brand"
        options={Object.values(brand).map((item) => { return { label: item, value: item }; })}
        value={storeFilters.brand}
        onChange={(values) => handleFilterSelect(values, "brand")}
        editable={!["revalidate", "request"].includes(storeList?.status) && !usingQueryFilters}
      />
      <hr />
      <DartCollapseCheckboxGroup
        title="Channel"
        options={Object.values(allocation_channel).map((item) => { return { label: capitalize(item), value: item }; })}
        value={storeFilters.channel}
        onChange={(values) => handleFilterSelect(values, "channel")}
        editable={!["revalidate", "request"].includes(storeList?.status) && !usingQueryFilters}
      />
      <hr />
    </div>
  );
};

export default StoreListFilters;
