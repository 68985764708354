import React from "react";
import "./index.less";
import {
  Col, Form, Radio, Row,
} from "antd";
import moment from "moment";
import { IStoreProfile } from "../../../../global/interfaces";
import DartInput from "../../../../components/DartInput";
import DartDatepicker from "../../../../components/DartDatepicker";
import { storeFields } from "../../../../utils/validations/store.fields";
import { messages } from "../../../../configs/constants";

interface IStoreAllocationSettings {
  form: any;
  storeDetails?: IStoreProfile;
  editable?: boolean;
  disabled?: boolean;
}

const StoreAllocationSettings:React.FC<IStoreAllocationSettings> = ({
  form,
  storeDetails,
  editable = true,
  disabled,
}) => {
  return (
    <div className={`store-allocation-settings ${disabled ? "disabled" : ""}`}>
      <h2>
        Allocation
        {disabled ? <span>{messages.will_be_copied_from_sister_store}</span> : ""}
      </h2>
      <Form
        disabled={disabled}
        form={form}
        initialValues={storeDetails ? {
          ...storeDetails,
          opening_at: storeDetails?.opening_at ? moment(storeDetails?.opening_at) : false,
          closing_at: storeDetails?.closing_at ? moment(storeDetails?.closing_at) : false,
          allocation_start_at: storeDetails?.allocation_start_at ? moment(storeDetails?.allocation_start_at) : false,
          allocation_stop_at: storeDetails?.allocation_stop_at ? moment(storeDetails?.allocation_stop_at) : false,
        } : {}}
        className="store-allocation-settings-form"
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="allocation_management_team"
              required
              rules={!disabled ? storeFields.allocation_management_team : []}
            >
              <Radio.Group size="large">
                {editable || storeDetails?.allocation_management_team === "ALLOCATION" ? <Radio value="ALLOCATION">Allocation Team</Radio> : ""}
                {editable || storeDetails?.allocation_management_team === "RETAIL" ? <Radio value="RETAIL">Retail Team</Radio> : ""}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[32, 32]}>
          <Col
            span={12}
            className="dates-column"
          >
            <Form.Item
              name="opening_at"
              required
              // field is required but does not matter what date is selected
              rules={!disabled ? storeFields.opening_at : []}
            >
              <DartDatepicker label="Store opens" />
            </Form.Item>
            <Form.Item
              name="closing_at"
              // check if closing_at is after opening_at and before today
              rules={!disabled ? [
                ({ getFieldValue }) => ({
                  validator(f, value) {
                    if (getFieldValue?.("opening_at")) {
                      return (getFieldValue?.("opening_at").isBefore(value) && ((!editable || moment().isBefore(value)))) || !value
                        ? Promise.resolve()
                        : Promise.reject(new Error("Store closing date should be greater than opening date and today's date"));
                    }
                    return Promise.resolve();
                  },
                }),
              ] : []}
            >
              <DartDatepicker label="Store closes (optional)" />
            </Form.Item>
          </Col>
          <Col
            span={12}
            className="dates-column"
          >
            <Form.Item
              name="allocation_start_at"
              required
              // check if allocation_start_at is between opening_at and closing_at dates
              rules={!disabled ? [
                ...storeFields.allocation_start_at,
                ({ getFieldValue }) => ({
                  validator(f, value) {
                    if (getFieldValue?.("closing_at")) {
                      return (getFieldValue?.("closing_at").isAfter(value))
                        ? Promise.resolve()
                        : Promise.reject(new Error("Allocation start date should not be after store close date"));
                    }
                    return Promise.resolve();
                  },
                }),
              ] : []}
            >
              <DartDatepicker label="Start allocation" />
            </Form.Item>
            <Form.Item
              name="allocation_stop_at"
              // check if allocation stop date is greater than allocation start date and less than store closing date
              rules={!disabled ? [
                ({ getFieldValue }) => ({
                  validator(f, value) {
                    if (getFieldValue?.("allocation_start_at") && getFieldValue?.("closing_at")) {
                      return (getFieldValue?.("allocation_start_at").isSameOrBefore(value)
                        && getFieldValue?.("closing_at").isSameOrAfter(value)) || !value
                        ? Promise.resolve()
                        : Promise.reject(new Error("Allocation stop date must be between opening and closing date and later than allocation start"));
                    }
                    return Promise.resolve();
                  },
                }),
              ] : []}
            >
              <DartDatepicker label="Stop allocation (optional)" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            className="note-column"
          >
            <Form.Item
              name="note"
            >
              <DartInput
                transparent
                placeholder="Notes (Optional)"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default StoreAllocationSettings;
