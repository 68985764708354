/* eslint-disable no-param-reassign */
import "./index.less";
import React, {
  useEffect, useMemo, useState,
} from "react";
import { Empty, Spin } from "antd";
import BaseTable, {
  AutoResizer, normalizeColumns, RowKey, unflatten,
} from "react-base-table";
import "react-base-table/styles.css";
import Icon from "../Icon";
import DartCheckbox from "../DartCheckbox";

interface IVirtualizedTable {
  tableStyle?: "default" | "dark";
  fixed?: boolean;
  defaultSelectedRowKeys?: RowKey[];
  defaultExpandedRowKeys?: RowKey[];
  onRowSelect?: (selectedRow: any) => void;
  onSelectedRowsChange?: (selectedRows: any[]) => void;
  onRowExpand?: (expandedRow: any) => void;
  onExpandedRowsChange?: (expandedRows: any[]) => void;
  expandColumnKey?: string;
  selectColumnKey?: string;
  rowClass?: string;
  columns?:Record<string, any>[];
  children?: any;
  selectable?: boolean;
  selectionColumnWidth?: number;
  selectionCellTitle?: string;
  selectionCellComponent?: ({ rowData }: any) => JSX.Element;
  expandable?: boolean;
  width?: number | string;
  height?: number | string;
  childHeight?: number | string;
  data?: any[];
  wrapperClassName?: string;
  header?: JSX.Element;
  loading?: boolean;
  checkboxSize?: "sm" | "md" | "lg";
  withBorderTop?: boolean;
  hasExpandAll?: boolean;
  hasSelectAll?: boolean;
  defaultAllSelected?: boolean;
  fixedFirstColumn?: boolean;
  renderSelectionCellTitle?: boolean;
  [prop:string]:any
}

// use childRender in data objects to render any component when expanding

const DartTable:React.FC<IVirtualizedTable> = ({
  tableStyle = "default",
  fixed = true,
  defaultSelectedRowKeys,
  defaultExpandedRowKeys,
  onRowSelect,
  onSelectedRowsChange,
  onRowExpand,
  onExpandedRowsChange,
  expandColumnKey = "id",
  selectColumnKey = "id",
  rowClass,
  columns = [],
  children,
  selectable = false,
  selectionCellTitle,
  selectionColumnWidth,
  selectionCellComponent,
  expandable = false,
  width = "100%",
  height = 500,
  childHeight,
  data = [],
  wrapperClassName,
  header,
  loading,
  checkboxSize = "sm",
  withBorderTop = false,
  hasExpandAll = false,
  hasSelectAll = false,
  defaultAllSelected = false,
  fixedFirstColumn = false,
  renderSelectionCellTitle = false,
  ...rest
}: any) => {
  const [selectedRK, setSelectedRK] = useState(defaultSelectedRowKeys || []);
  const [expandedRK, setExpandedRK] = useState(defaultExpandedRowKeys || []);

  useEffect(() => {
    if (defaultAllSelected) {
      setSelectedRK(data?.map((d: any) => d[selectColumnKey]));
    }
  }, [defaultAllSelected]);

  const onExpandToggle = (shouldExpand: boolean) => {
    if (shouldExpand) {
      setExpandedRK((data || []).map((r: any) => r[expandColumnKey || "id"]));
      onExpandedRowsChange?.((data || []).map((r: any) => r[expandColumnKey || "id"]));
    } else {
      setExpandedRK([]);
      onExpandedRowsChange?.([]);
    }
  };

  const onSelectToggle = (shouldSelect: boolean) => {
    if (shouldSelect) {
      setSelectedRK((data || []).map((r: any) => r[selectColumnKey || "id"]));
      onSelectedRowsChange?.((data || []).map((r: any) => r[selectColumnKey || "id"]));
    } else {
      setSelectedRK([]);
      onSelectedRowsChange?.([]);
    }
  };

  useEffect(() => {
    if (defaultExpandedRowKeys) {
      setExpandedRK(defaultExpandedRowKeys);
    }
  }, [defaultExpandedRowKeys]);

  useEffect(() => {
    if (defaultSelectedRowKeys) {
      setSelectedRK(defaultSelectedRowKeys);
    }
  }, [defaultSelectedRowKeys]);

  useEffect(() => {
    if (defaultAllSelected) {
      setSelectedRK((data || []).map((r: any) => r[selectColumnKey || "id"]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectColumnKey, defaultAllSelected]);

  const SelectionCell = ({
    rowData, rowIndex, column,
  }: any) => {
    const handleChange = (isChecked:boolean) => {
      const { onChange } = column;
      onChange({
        selected: isChecked, rowData, rowIndex,
      });
    };

    const { selectedRowKeys, rowKey } = column;
    const checked = selectedRowKeys.includes(rowData[rowKey]);

    return (
      <DartCheckbox
        size={checkboxSize}
        checked={checked}
        onChange={handleChange}
      />
    );
  };

  const generateColumns = (columnsArray:any[], props?:any) => {
    return columnsArray.map((column) => ({
      ...props,
      ...column,
      key: column.dataIndex,
      dataKey: column.dataIndex,
      title: column.title,
      width: column.width,
    }));
  };

  const generateData = (cls:any, listData:any[]) => {
    return listData.map((row) => {
      return cls.reduce((rowData:any, column:any) => {
        if (column.dataKey === "cellRenderer") {
          // eslint-disable-next-line no-param-reassign
          rowData[column.dataKey] = column?.cellRenderer?.(rowData, row);
        } else {
          // eslint-disable-next-line no-param-reassign
          rowData[column.dataKey] = row[column.dataKey];
        }
        return rowData;
      },
      {
        ...row,
        children: [{
          id: `${row.id}-detail`,
          content: row.childRender,
        }],
      });
    });
  };

  const handleSelectChange = ({
    selected, rowData, rowIndex,
  }: any) => {
    const key = rowData[selectColumnKey];
    if (selected) {
      if (!selectedRK.includes(key)) {
        setSelectedRK([...selectedRK, key]);
        onSelectedRowsChange?.([...selectedRK, key]);
      }
    } else {
      const index = selectedRK.indexOf(key);
      if (index > -1) {
        setSelectedRK([...selectedRK.filter((e: any) => e !== key)]);
        onSelectedRowsChange?.([...selectedRK.filter((e: any) => e !== key)]);
      }
    }

    onRowSelect?.({
      selected, rowData, rowIndex,
    });
  };

  const handleExpandChange = ({
    expanded, rowData, rowIndex,
  }: any) => {
    const key = rowData[expandColumnKey];
    if (expanded) {
      if (!expandedRK.includes(key)) {
        setExpandedRK([...expandedRK, key]);
        onExpandedRowsChange?.([...expandedRK, key]);
      }
    } else {
      const index = expandedRK.indexOf(key);
      if (index > -1) {
        setExpandedRK([...expandedRK.filter((e: any) => e !== key)]);
        onExpandedRowsChange?.([...expandedRK.filter((e: any) => e !== key)]);
      }
    }

    onRowExpand?.({
      expanded, rowData, rowIndex,
    });
  };

  const rowClassName:any = ({ rowData }: any) => {
    const rC = rowClass || "";
    const key = rowData[selectColumnKey];

    return [rC, (selectedRK || []).indexOf(key) > -1 && "row-selected"]
      .filter(Boolean)
      .concat(" ");
  };

  const colList = generateColumns((columns || []).filter((c: any) => !!c.dataIndex));
  const dataList = generateData(colList, data);

  let cols = colList || normalizeColumns(children);
  const tempFirstIndex = cols.findIndex((e) => e.dataIndex === selectColumnKey);
  const firstIndex = tempFirstIndex === -1 ? 0 : tempFirstIndex;

  if (selectable) {
    const firstPart = cols.slice(0, firstIndex);
    const lastPart = cols.slice(firstIndex, cols.length);
    let size = 46;
    if (checkboxSize === "md") {
      size = 55;
    } else if (checkboxSize === "lg") {
      size = 62;
    }
    if (selectionColumnWidth) {
      size = selectionColumnWidth;
    }
    const selectionColumn = {
      width: size,
      className: "row-selection-checkbox",
      flexShrink: 0,
      flexGrow: 0,
      resizable: false,
      title: selectionCellTitle || "",
      cellRenderer: selectionCellComponent || SelectionCell,
      key: selectColumnKey,
      rowKey: selectColumnKey,
      dataIndex: selectColumnKey,
      selectedRowKeys: selectedRK,
      frozen: fixedFirstColumn ? "left" : "none",
      onChange: handleSelectChange,
      align: renderSelectionCellTitle ? "center" : "left",
    };
    cols = [...(firstPart || []), selectionColumn, ...(lastPart || [])];
  }

  const canExpandAll = expandable && hasExpandAll && data?.length > 0;
  const canSelectAll = selectable && hasSelectAll && data?.length > 0;

  const tableRowActionsToggle = useMemo(() => {
    if (canExpandAll || canSelectAll) {
      return (
        <>
          {canSelectAll && (
            <span
              className="select-toggle-button cursor-pointer"
              onClick={() => onSelectToggle?.(selectedRK?.length === 0)}
            >
              {selectedRK && selectedRK?.length > 0 ? "Deselect all" : "Select all"}
            </span>
          )}
          {canExpandAll && (
          <span
            className="expand-toggle-button cursor-pointer"
            style={{ left: canSelectAll ? "80px" : "0px" }}
            onClick={() => onExpandToggle?.(expandedRK?.length === 0)}
          >
            {expandedRK && expandedRK?.length > 0 ? "Collapse all" : "Expand all"}
          </span>
          )}
        </>
      );
    }
    return <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canExpandAll, canSelectAll, expandedRK, selectedRK]);

  if ((canExpandAll || canSelectAll) && !renderSelectionCellTitle) {
    cols[0].title = "";
  }

  const renderEmpty = () => {
    if (data.length === 0 && !loading) return <Empty description="No matching data found" />;
    return <></>;
  };

  const ExpandIcon = ({ canExpand, ...otherProps }: any) => {
    if (canExpand) {
      return (
        <div
          className="dart-table-expand-icon"
          onClick={() => otherProps.onExpand(!otherProps.expanded)}
        >
          <Icon
            name="DropdownArrow"
            style={{ transform: otherProps.expanded ? "rotateZ(180deg)" : "rotateZ(0deg)" }}
            color="#333E47"
          />
        </div>
      );
    }
    return <></>;
  };

  const rowRenderer = ({ rowData, cells }: any) => {
    if (rowData.content) {
      return (
        <div
          className="child-row-renderer"
          style={{
            height: (typeof childHeight === "number" ? `${childHeight}px` : (childHeight || "100px")),
          }}
        >
          {rowData.content}
        </div>
      );
    }
    return cells;
  };

  const components = expandable ? {
    ExpandIcon,
  } : {};

  const treeData = unflatten(dataList || []);

  return (
    // eslint-disable-next-line
    <div className={`dart-table ${wrapperClassName ?? ""} ${withBorderTop ? "border-top" : ""} ${hasExpandAll ? "has-expand-all" : ""} ${tableStyle === "dark" ? "dark" : "default"} ${rest?.headerRenderer ? "double-header" : ""}`}>
      {header}
      <div style={{
        width,
        height,
      }}
      >
        {loading ? <Spin className="table-loader" /> : ""}
        {tableRowActionsToggle}
        <AutoResizer>
          {({ width: w, height: h }) => {
            return (
              <BaseTable
                fixed={fixed}
                disabled={loading}
                width={w}
                height={h}
                columns={cols}
                data={treeData}
                rowClassName={rowClassName}
                expandColumnKey={expandable ? expandColumnKey : undefined}
                expandedRowKeys={expandedRK || []}
                selectedRowKeys={selectedRK || []}
                onRowExpand={handleExpandChange}
                emptyRenderer={renderEmpty}
                components={components}
                rowRenderer={(rowDataWithCells: any) => rowRenderer(rowDataWithCells)}
                expandIconProps={({ rowData }: any) => ({
                  canExpand: !!rowData.childRender,
                })}
                estimatedRowHeight={childHeight || 50}
                {...rest}
              />
            );
          }}
        </AutoResizer>
      </div>
    </div>
  );
};

DartTable.defaultProps = {
  ...BaseTable.defaultProps,
  onRowSelect: () => false,
  onSelectedRowsChange: () => false,
};

export default DartTable;
