import React from "react";
import "./index.less";
import { Form } from "antd";
import DartMultipleDatePicker from "../../../../components/DartMultipleDatePicker";

const NewnessDropdownFiltersBlock:React.FC = () => {
  return (
    <>
      <div className="newness-dropdown-filters-block-bottom-row">
        <Form.Item name="months">
          <DartMultipleDatePicker
            format="MMM YYYY"
            datePickerProps={{ picker: "month" }}
            selectProps={{ maxTagCount: 2, placeholder: "Select Newness Months" }}
          />
        </Form.Item>
      </div>
    </>
  );
};

export default NewnessDropdownFiltersBlock;
