/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import {
  Form, message, Spin,
} from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import { ISizeRun } from "../../../../../global/interfaces";
import { selectedSizeRunAtom, sizeRunListAtom } from "../../../../../global/atoms";
import paths from "../../../../../configs/paths";
import DartPrompt from "../../../../../components/DartPrompt";
import DartButton from "../../../../../components/DartButton";
import SizeRunPageHeader from "../../blocks/SizeRunPageHeader";
import SizeRunForm from "../../blocks/SizeRunForm";
import { updateSizeRun } from "../../services/sizeRun";

interface ISizeRunView {
  onBack: () => any;
}

const SizeRunView: React.FC<ISizeRunView> = ({
  onBack,
}) => {
  const selectedSizeRun = useRecoilValue(selectedSizeRunAtom);
  const [sizeRunList, setSizeRunList] = useRecoilState(sizeRunListAtom);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [sizeRunData, setSizeRunData] = useState<ISizeRun>();
  const [sizeRunForm] = Form.useForm();

  useEffect(() => {
    if (selectedSizeRun) {
      setSizeRunData((sizeRunList.originalData || []).find((sizeRun: any) => sizeRun.id === selectedSizeRun));
    } else {
      history.push(paths.size_profile);
    }
  }, [sizeRunList.status, selectedSizeRun]);

  const askDelete = () => {
    setDeleteOpen(true);
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    setDeleteOpen(false);
    setDeleteLoading(false);
    history.push(paths.size_profile);
  };

  const handleSave = async () => {
    // check forms for errors
    let hasErrors = false;
    try {
      await sizeRunForm.validateFields();
    } catch (e) {
      hasErrors = true;
    }
    if (hasErrors) {
      message.error("Please check all fields are correct");
      return;
    }

    // get field values from each form
    const sizeRunFormValues = sizeRunForm.getFieldsValue();

    const sizeRunAllArray = sizeRunFormValues.size_run_all?.split(",");

    // Create size run data object
    const finalData = {
      id: selectedSizeRun,
      division: sizeRunFormValues.division,
      department: sizeRunFormValues.department,
      size_category: sizeRunFormValues.department === "FOOTWEAR"
        ? sizeRunFormValues.size_category
        : undefined,
      size_run_type: sizeRunFormValues.size_run_type,
      size_run_all: sizeRunAllArray,
      size_run_min: sizeRunAllArray?.[0],
      size_run_max: sizeRunFormValues.size_run_all
        ? sizeRunAllArray?.[sizeRunAllArray?.length - 1]
        : undefined,
      name: sizeRunFormValues.name,
    };

    setSizeRunData({
      ...sizeRunData,
      ...finalData,
    });

    setLoading(true);

    // Update size run request
    await updateSizeRun(
      finalData, sizeRunList, setSizeRunList,
    );

    setLoading(false);
  };

  const decideTypeOfSize = useMemo(() => {
    const isNumerical = (typeof sizeRunData?.size_run_all === "string"
      ? sizeRunData?.size_run_all?.split(",")
      : (sizeRunData?.size_run_all || []))?.some((e: string) => {
      return /^\d*\.?\d+(?:\/\d*\.?\d+)*(?:,\d*\.?\d+(?:\/\d*\.?\d+)*)*$/.test(e);
    });
    if (isNumerical) {
      return "numerical";
    }
    return "alpha";
  }, [sizeRunData]);

  return (
    <div className="size-run-view">
      {sizeRunData ? (
        <>
          <SizeRunPageHeader
            sizeRun={sizeRunData}
            onBack={onBack}
          />
          <DartPrompt
            title="Delete Size Run?"
            content={(
              <div>
                {`Size run "${sizeRunData?.name}" will be deleted from system. This action can not be reverted.`}
              </div>
            )}
            okText="Yes, delete size run"
            cancelText="Cancel"
            visible={deleteOpen}
            onCancel={() => setDeleteOpen(false)}
            onOk={handleDelete}
            okButtonProps={{ loading: deleteLoading }}
          />
          <SizeRunForm
            form={sizeRunForm}
            sizeRunFormData={{ ...sizeRunData, size_run_type: decideTypeOfSize }}
            editable
          />
          <DartButton
            isSubmit
            loading={loading}
            className="size-run-form-action"
            onClick={handleSave}
            label="Save Changes"
            size="lg"
            style={{ width: "100%" }}
          />
        </>
      ) : <Spin className="spin-loader" />}
    </div>
  );
};

export default SizeRunView;
