import React, { useMemo } from "react";
import "./index.less";
import DartTable from "../../../../components/DartTable";
import columns from "./columns";
import useEnums from "../../../../utils/hooks/useEnums";

export interface IUploadFileRow {
  id: number;
  uuid: string | null;
  doc_type?: string | null;
  import_type?: string | null;
  file?: string | null;
  error_file?: string | null;
  status?: "INITIATED" | "UPLOADED" | "QUEUED" | "PROCESSING" | "COMPLETED" | "COMPLETED_WITH_ERRORS" | "FAILED"
  uploaded_by?: string | null;
  uploaded_by_email?: string | null;
  upload_file_url?: string | null;
  error_file_url?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  childRender?: JSX.Element;
}

interface IUploadFilesTableBlock {
  uploadFileList?: IUploadFileRow[];
  loading?: boolean;
}

const UploadFilesTableBlock:React.FC<IUploadFilesTableBlock> = ({
  uploadFileList,
  loading,
}) => {
  const {
    doc_type, import_type, upload_status,
  } = useEnums();

  // Memoized Columns with necessary props provided
  const getColumns = useMemo(() => {
    return columns({
      doc_type,
      import_type,
      upload_status,
    });
  }, [doc_type, import_type, upload_status]);

  return (
    <div
      key={`${Object.keys(doc_type)?.length}_${Object.keys(import_type)?.length}_${Object.keys(upload_status)?.length}`}
      className="upload-files-table-block"
    >
      <DartTable
        loading={loading}
        columns={getColumns}
        data={(uploadFileList || []).filter((uf) => { return uf.import_type?.indexOf("INVENTORY") === -1; })}
        width="100%"
        height="calc(100vh - 300px)"
        fixed={false}
        wrapperClassName="upload-file-table"
      />
    </div>
  );
};

export default UploadFilesTableBlock;
