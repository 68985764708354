import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const sizeProfileChannelAtom = atom({
  key: "size-profile-channel",
  default: "ALL",
  effects_UNSTABLE: [persistAtom],
});
