const awsconfig = {
  region: "us-west-2",
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
};

const awsBaseUrl = process.env.REACT_APP_BASE_URL;

export { awsconfig, awsBaseUrl };

export default awsconfig;
