import React, {
  useCallback, useEffect, useState,
} from "react";
import "./index.less";
import { useRecoilValue } from "recoil";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { manualReplenishmentAtom } from "../../../../global/atoms";
import DartSelect from "../../../../components/DartSelect";
import Icon from "../../../../components/Icon";
import { IReplenishmentCC } from "../../../../global/interfaces";
import SelectedStyleColorBlockCollapsible from "../../blocks/SelectedStyleColorBlockCollapsible";
import DartTable from "../../../../components/DartTable";
import paths from "../../../../configs/paths";

type sortType = "-sendMore" | "+sendMore" | "-finalInventory" | "+finalInventory"
  | "-targetWOH" | "+targetWOH" | "-remainingInventoryPercentage" | "+remainingInventoryPercentage";

const ManualReplenishmentReview:React.FC = () => {
  const history = useHistory();
  const [sortedBy, setSortedBy] = useState<sortType>("-sendMore");
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [reviewData, setReviewData] = useState<any[]>([]);

  const manualReplenishmentState = useRecoilValue(manualReplenishmentAtom);

  const onSort = (sortBy: sortType) => {
    setSortedBy(sortBy);
    setReviewData([...manualReplenishmentState?.ccs]?.sort((a:any, b:any) => {
      if (sortBy.startsWith("+")) {
        return a[sortBy.substr(1)] - b[sortBy.substr(1)];
      }
      return b[sortBy.substr(1)] - a[sortBy.substr(1)];
    }));
  };

  const mapSortToLabel = (sortBy: sortType) => {
    const map = {
      "-sendMore": "Send More (High to Low)",
      "+sendMore": "Send More (Low to High)",
      "-finalInventory": "Final Inv (High to Low)",
      "+finalInventory": "Final Inv (Low to High)",
      "-targetWOH": "Target WOH (High to Low)",
      "+targetWOH": "Target WOH  (Low to High)",
      "-remainingInventoryPercentage": "Remaining (%) (High to Low)",
      "+remainingInventoryPercentage": "Remaining (%) (Low to High)",
    };

    return map[sortBy];
  };

  const handleCollapseAll = () => {
    setExpandedKeys([]);
  };

  const handleExpandAll = () => {
    setExpandedKeys(manualReplenishmentState?.ccs?.map((e: IReplenishmentCC) => e.cc));
  };

  const toggleCollapse = (key: string) => {
    if (expandedKeys?.indexOf(key) === -1) {
      setExpandedKeys((prev) => [...prev, key]);
    } else {
      setExpandedKeys((prev) => prev.filter((e) => e !== key));
    }
  };

  useEffect(() => {
    setReviewData([...manualReplenishmentState?.ccs].sort((a:any, b:any) => {
      return b.sendMore - a.sendMore;
    }));
  }, [manualReplenishmentState]);

  const handleSKUClick = (cc: string) => {
    history.push(`${paths.manual_replenishment_step_one?.replace(":id", manualReplenishmentState?.processId)}?selectedCC=${cc}`);
  };
  const handleSKUSizeClick = (size: any, cc: string) => {
    history.push(`${paths.manual_replenishment_step_one
      ?.replace(":id", manualReplenishmentState?.processId)}?selectedCC=${cc}&selectedSize=${size?.size}`);
  };

  // Child table columns
  const generateChildColumnsUsingSizes = useCallback((ccSizes?: any, ccKey?: string) => {
    return [
      {
        key: "size",
        dataIndex: "size",
        title: "",
        width: 250,
        align: "center",
        className: "nested-table-replenishment-cc-cell",
        cellRenderer: ({ rowData }: any) => {
          return (
            <div className="anchor-title">
              {rowData?.title}
            </div>
          );
        },
      },
      ...(ccSizes || [])?.sort((a: any, b: any) => {
        return a.size - b.size;
      })?.map((ccSize: any) => {
        return {
          title: (
            <div
              onClick={() => (ccKey && ccSize ? handleSKUSizeClick(ccSize, ccKey) : false)}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {ccSize.size}
            </div>
          ),
          dataIndex: ccSize.size,
          key: ccSize.size,
          width: 150,
          align: "center",
          cellRenderer: ({ rowData }: any) => {
            const cellDataToRender = () => {
              switch (rowData?.key) {
                case "total":
                  return formatter.format(ccSize?.totalInventory);
                case "send_more":
                  return formatter.format(ccSize?.sendMore);
                case "final_inventory":
                  return formatter.format(ccSize?.finalInventory);
                default:
                  return null;
              }
            };
            return (
              <div className="size-cell">
                {cellDataToRender()}
              </div>
            );
          },
        };
      }),
    ];
  }, [manualReplenishmentState]);

  // Formatter to correctly display floats
  const formatter = Intl.NumberFormat("en", { notation: "standard" });

  return (
    <div
      className="manual-replenishment-review"
    >
      <div className="top-filters">
        <div className="left">
          <DartSelect
            label="Sort By"
            placeholder="select sort"
            selectOptions={[
              { value: "-sendMore", label: "Send More (High to Low)" },
              { value: "+sendMore", label: "Send More (Low to High)" },
              { value: "-finalInventory", label: "Final Inv (High to Low)" },
              { value: "+finalInventory", label: "Final Inv (Low to High)" },
              { value: "-targetWOH", label: "Target WOH (High to Low)" },
              { value: "+targetWOH", label: "Target WOH  (Low to High)" },
              { value: "-remainingInventoryPercentage", label: "Remaining (%) (High to Low)" },
              { value: "+remainingInventoryPercentage", label: "Remaining (%) (Low to High)" },
            ]}
            onChange={(v) => (v ? onSort(v as any) : onSort("-sendMore"))}
            value={{ value: sortedBy, label: mapSortToLabel(sortedBy) }}
            canClear={false}
            showSearch={false}
          />
        </div>
        <div className="right">
          <Button onClick={handleExpandAll}>
            <Icon name="Expand" />
            <span>Expand All</span>
          </Button>
          <Button onClick={handleCollapseAll}>
            <Icon name="Collapse" />
            <span>Collapse All</span>
          </Button>
        </div>
      </div>
      <div className="bottom">
        <div className="replenishment-review-wrapper">
          {
            reviewData?.map((cc: IReplenishmentCC) => {
              // Calculate totals across all stores and group by size
              const sizesReducedData: any = {};
              for (let i = 0; i < (cc?.stores?.length || 0); i += 1) {
                for (let sI = 0; sI < (cc?.stores?.[i]?.sizes?.length || 0); sI += 1) {
                  const singleSizeData = cc?.stores?.[i]?.sizes?.[sI];
                  if (singleSizeData?.size) {
                    if (!sizesReducedData?.hasOwnProperty(singleSizeData?.size as string)) {
                      sizesReducedData[singleSizeData?.size] = {
                        totalInventory: 0,
                        sendMore: 0,
                        finalInventory: 0,
                        size: "00",
                      };
                    }
                    const store = cc?.stores?.[i]?.store;
                    if (!store?.excluded) {
                      sizesReducedData[singleSizeData?.size].size = singleSizeData?.size;
                      sizesReducedData[singleSizeData?.size].totalInventory += singleSizeData?.totalInventory || 0;
                      sizesReducedData[singleSizeData?.size].sendMore += singleSizeData?.sendMore || 0;
                      sizesReducedData[singleSizeData?.size].finalInventory += singleSizeData?.finalInventory || 0;
                    }
                  }
                }
              }

              return (
                <SelectedStyleColorBlockCollapsible
                  key={cc?.cc}
                  data={cc}
                  open={expandedKeys?.indexOf(cc?.cc) > -1}
                  onOpenToggle={toggleCollapse}
                  theme="dark"
                  onTitleClick={handleSKUClick}
                  childRender={(
                    <div className="replenishment-review-child-wrapper">
                      <DartTable
                        key={`${cc?.sendMore}_key`}
                        tableStyle="default"
                        height={200}
                        data={[
                          { title: "Total Store Inv", key: "total" },
                          { title: "Send More", key: "send_more" },
                          { title: "Final Inv", key: "final_inventory" },
                        ]}
                        columns={generateChildColumnsUsingSizes(Object.values(sizesReducedData || {}), cc?.cc)}
                        fixed={false}
                      />
                    </div>
                  )}
                />
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

export default ManualReplenishmentReview;
