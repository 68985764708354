import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const manualReplenishmentAtom = atom({
  key: "manual-replenishment",
  default: {
    name: "", ccs: [], brand: undefined, channel: undefined, fulfilments: {}, draft: {}, ccData: {},
  },
  effects_UNSTABLE: [persistAtom],
});
