import React, {
  useCallback, useEffect, useState,
} from "react";
import "./index.less";
import { useRecoilState } from "recoil";
import { useHistory, useParams } from "react-router-dom";
import { message } from "antd";
import { globalFiltersAtom, manualReplenishmentAtom } from "../../../../global/atoms";
import PageTitle from "../../../../components/PageTitle";
import DartButton from "../../../../components/DartButton";
import PageSummary from "../../../../components/PageSummary";
import DartDynamicTabsWithScroll from "../../../../components/DartDynamicTabsWithScroll";
import {
  ReplenishCCDetails, ReplenishSKUDetails, SelectedStyleColorSizePicker, SelectedStyleColorTabHead,
} from "../..";
import {
  queryGet, removeNullValues, updateQueryHashParameter,
} from "../../../../utils/helpers";
import { fetchManualReplenishmentProcess } from "../../services/replenishment";
import paths from "../../../../configs/paths";

const ManualReplenishmentStepOne = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [manualReplenishmentState, setManualReplenishmentState] = useRecoilState(manualReplenishmentAtom);
  const [localCCs, setLocalCCs] = useState<any>([]);
  const [selectedCC, setSelectedCC] = useState<any>(queryGet("selectedCC") || undefined);
  const [selectedSize, setSelectedSize] = useState<any>(queryGet("selectedSize") || undefined);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [globalFilters, setGlobalFilters] = useRecoilState(globalFiltersAtom);

  useEffect(() => {
    document.title = `DART - ${manualReplenishmentState?.name}`;
    if (!globalFilters.brand || !globalFilters.channel) {
      setGlobalFilters((prev: any) => {
        return {
          ...prev, brand: manualReplenishmentState?.brand, channel: manualReplenishmentState?.channel,
        };
      });
    }
  }, [manualReplenishmentState?.name]);

  const navigateToStepTwo = () => {
    history.push(paths.manual_replenishment_step_two?.replace(":id", id));
  };

  const overAllocatedList = (tab: any) => {
    return tab.sizes
      ?.filter((size: { size: string, cc: string, isOverAllocated: boolean }) => size.isOverAllocated)
      ?.map((size: { size: string, cc: string, isOverAllocated: boolean }) => size.size) || [];
  };

  // Count total Store Inventory for size using stores array and active size prop
  const sizeStoreInventoryDataFromStores = (storeList: any, activeSize: string) => {
    let OHTotal = 0;
    let ITTotal = 0;
    let OOTotal = 0;
    let TTLTotal = 0;
    storeList?.map((store: any) => {
      const storeInventoryObject = store?.sizes?.find((e: any) => e.size === activeSize)?.storeInventory;
      OHTotal += storeInventoryObject?.OH;
      ITTotal += storeInventoryObject?.IT;
      OOTotal += storeInventoryObject?.OO;
      TTLTotal += storeInventoryObject?.TTL;
      return store;
    });
    return {
      OH: OHTotal,
      IT: ITTotal,
      OO: OOTotal,
      TTL: TTLTotal,
    };
  };

  // Count total Send More for size using stores array and active size prop
  const sizeSendMoreDataFromStores = (storeList: any, activeSize: string) => {
    return storeList?.reduce((acc: any, store: any) => {
      if (store?.store?.excluded) return acc;
      return acc + store?.sizes?.find((e: any) => e.size === activeSize)?.sendMore;
    }, 0);
  };

  // When Size is selected, extract Size object from selected CC and save it to state
  const buildSelectedSizeObject = useCallback((tab: any, activeSize: any) => {
    const currentSizeParentLevelData = tab?.sizes?.find((sR: any) => sR.size === activeSize);
    const sizeTotalStoreInventory = sizeStoreInventoryDataFromStores(tab?.stores, activeSize);
    const sizeSendMore = sizeSendMoreDataFromStores(tab?.stores, activeSize);
    // get active size total available inventory count
    const sizeTotalAvailableInventory = currentSizeParentLevelData?.allocatableInventory;
    const sizeDCTotalAvailableInventory = currentSizeParentLevelData?.allocatableInventoryDC;

    const storesData = [
      ...(tab?.stores?.map((store: any) => {
        const skuStoreData = store?.sizes?.filter((size: any) => size.size === activeSize)[0];
        const skuStoreTargetWOH = skuStoreData?.targetWOH;

        return removeNullValues({
          ...store,
          ...store?.sizes?.filter((size: any) => size.size === activeSize)[0],
          targetWOH: Math.round(Math.max(tab?.minWOH, skuStoreTargetWOH)),
        }, true);
      }) || []),
    ];

    const sizeTotal4WkSales = (storesData || [])?.filter((e: any) => !e?.store?.excluded)?.reduce((acc: any, store: any) => {
      return acc + store?.sizes?.find((e: any) => e.size === activeSize)?.last4WeeksSales;
    }, 0);

    // (Total Inventory + Send More)/weekly Avg of Last 4 Wks Sales
    const skuTotalTargetWOH = (sizeTotalStoreInventory?.TTL + sizeSendMore) / (sizeTotal4WkSales || 1);
    const sizeTargetWOH = Math.round(Math.max(tab?.minWOH, skuTotalTargetWOH));

    return removeNullValues({
      cc: tab?.cc,
      sku: `${tab.cc}-${activeSize}`,
      department: tab?.department,
      description: tab?.description,
      division: tab?.division,
      klass: tab?.klass,
      storeInventory: sizeTotalStoreInventory,
      total: sizeTotalStoreInventory?.TTL,
      valid: currentSizeParentLevelData?.remainingInventoryPercentage >= 0 && sizeTotalAvailableInventory > 0,
      sendMore: sizeSendMore,
      finalInventory: sizeTotalStoreInventory?.TTL + sizeSendMore,
      targetWOH: sizeTargetWOH,
      allocatable_inv: sizeTotalAvailableInventory,
      allocatableInventory: sizeTotalAvailableInventory,
      allocatableInventoryDC: sizeDCTotalAvailableInventory,
      remainingInventoryPercentage: currentSizeParentLevelData?.remainingInventoryPercentage,
      stores: storesData,
      last4WeekSales: sizeTotal4WkSales,
      sizes: null,
    }, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSize]);

  // Fetch data from BE on initial load and save it to state...
  useEffect(() => {
    if (parseInt(id, 10) && (!manualReplenishmentState?.processId || manualReplenishmentState?.processId !== parseInt(id, 10))) {
      fetchManualReplenishmentProcess(
        +id, setLoading, setManualReplenishmentState, globalFilters?.channel,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, globalFilters?.channel]);

  // On selected CC change, change query parameter selectedCC and on Size change, change query parameter selectedSize
  useEffect(() => {
    if (!selectedCC && !selectedSize && localCCs && localCCs?.[0]?.cc) {
      setSelectedCC(localCCs?.[0]?.cc);
      history.push(paths.manual_replenishment_step_one?.replace(":id", id) + updateQueryHashParameter(
        history.location.search, "selectedCC", localCCs?.[0]?.cc,
      ));
    } else {
      history.location.search = updateQueryHashParameter(
        history.location.search, "selectedCC", selectedCC,
      );
      history.location.search = updateQueryHashParameter(
        history.location.search, "selectedSize", selectedSize,
      );
    }
  }, [history, selectedCC, selectedSize, localCCs]);

  // Initial local state when recoil manualReplenishmentState state changes
  useEffect(() => {
    setLocalCCs(manualReplenishmentState?.ccs || []);
    setCount(manualReplenishmentState?.ccs?.length || 0);
  }, [manualReplenishmentState]);

  // Handle CC removal from local and recoil state. also update count
  const handleRemoveCC = (ccIndex: string | number, ccs: any[]) => {
    // Updated array off CCs depending on removed cc
    const updatedCCS = (ccs || [])?.filter((each) => each.cc !== ccIndex);
    setCount(updatedCCS.length);
    setLocalCCs(updatedCCS);
    setManualReplenishmentState((prev: any) => {
      return {
        ...prev,
        ccs: (prev.ccs || [])?.filter((each: any) => each.cc !== ccIndex),
      };
    });
    // Reset Size selection
    handleSizeSelect("");
    // If CC is left in list, switch to that one, else go back to dashboard
    if (updatedCCS?.[0]?.cc) {
      history.push(paths.manual_replenishment_step_one?.replace(":id", id) + updateQueryHashParameter(
        history.location.search, "selectedCC", updatedCCS?.[0]?.cc?.toString(),
      ));
      setSelectedCC(updatedCCS?.[0]?.cc);
    } else {
      message.info("No more CCs left in Replenishment");
      history.push(paths.replenishment_dashboard);
    }
  };

  // Handle Tab Change, reset selected size drill-down state
  const handleTabChange = (tabIndex: string | number, tabs: any[]) => {
    setSelectedCC((tabs || []).find((each) => each.cc === tabIndex)?.cc);
    // push to query params
    history.push(paths.manual_replenishment_step_one?.replace(":id", id) + updateQueryHashParameter(
      history.location.search, "selectedCC", tabIndex?.toString(),
    ));
    history.push(paths.manual_replenishment_step_one?.replace(":id", id) + updateQueryHashParameter(
      history.location.search, "selectedSize", "",
    ));
    setSelectedSize(undefined);
  };

  // Handle Size Select, if same selected, remove selection
  const handleSizeSelect = useCallback((size: string) => {
    if (selectedSize === size) {
      setSelectedSize(undefined);
    } else {
      setSelectedSize(size);
    }
    // push to query params
    history.push(paths.manual_replenishment_step_one?.replace(":id", id) + updateQueryHashParameter(
      history.location.search, "selectedSize", selectedSize !== size ? size?.toString() : "",
    ));
  }, [history, selectedSize]);

  // Tab Card Render component function
  const tabRender = (tab: any) => {
    return (
      <SelectedStyleColorTabHead
        cc={tab.cc}
        ccClass={tab.class}
      />
    );
  };

  // Tag Content Render component function
  const tagContentRender = useCallback((tab: any) => {
    return {
      id: tab.cc,
      key: tab.cc,
      cc: tab.cc,
      class: tab.klass,
      content: (
        <div className="tab-content">
          <div className="tab-content-header">
            <SelectedStyleColorSizePicker
              label="View by SKU:"
              options={tab.sizes?.map((size: { size: string, cc: string, isOverAllocated: boolean }) => size.size)}
              overAllocatedSizes={overAllocatedList(tab)}
              onClick={handleSizeSelect}
              selected={selectedSize}
            />
          </div>
          <div className="tab-content-body">
            {selectedSize ? (
              <ReplenishSKUDetails
                key={`${selectedCC}_${selectedSize}`}
                skuDetails={buildSelectedSizeObject(tab, selectedSize)}
              />
            ) : (
              <ReplenishCCDetails
                key={selectedCC}
                ccDetails={tab}
                onSKUSizeClick={handleSizeSelect}
              />
            )}
          </div>
        </div>
      ),
    };
  }, [handleSizeSelect, selectedSize, selectedCC]);
  // 1146170-RCR 1144750-DFRT 123431-BAT

  return (
    <div className="manual-replenishment-step-one">
      <PageTitle
        title={loading ? "Loading..." : (manualReplenishmentState?.name || "Oops! Something went wrong")}
        rightNode={(
          <div className="replenishment-steps-wrapper">
            <div className={`replenishment-step active
            ${!loading && (manualReplenishmentState?.name === "" || !manualReplenishmentState?.name) ? "danger" : ""}`}
            />
            <div className="replenishment-step" />
          </div>
      )}
      />
      <div className="container-content">
        <div className="top">
          <PageSummary
            summaryContent={[{
              name: "Style-colors",
              value: count,
              flex: 12,
              loading,
            }]}
          />
          <DartButton
            type="dark"
            label="FINAL REVIEW"
            loading={false}
            disabled={loading || !count}
            onClick={navigateToStepTwo}
          />
        </div>
        <hr />
        <div className={`content ${selectedSize ? "size-selected" : ""}`}>
          {loading || manualReplenishmentState?.name === "" || !manualReplenishmentState?.name ? <></> : (
            <DartDynamicTabsWithScroll
              activeTab={selectedCC || localCCs?.[0]?.cc}
              tabs={localCCs?.map(tagContentRender)}
              tabRender={tabRender}
              onTabChange={handleTabChange}
              onClick={() => {
                handleSizeSelect("");
              }}
              onRemove={handleRemoveCC}
              askRemoveText="Remove Style-Color?"
              destroyInactiveTabPane
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ManualReplenishmentStepOne;
