import moment, { Moment } from "moment";

const xTimeAgo = (dt?: string | Moment | null, suffix = "ago") => {
  if (!dt) return "N/A";
  const localDate: Moment = typeof dt === "string" ? moment(dt) : dt;
  const seconds = (moment().diff(localDate, "seconds"));

  let interval = seconds / 31536000;

  if (interval > 1) {
    const floorInterval = Math.floor(interval);
    return `${floorInterval} ${floorInterval > 1 ? "years" : "year"} ${suffix}`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    const floorInterval = Math.floor(interval);
    return `${floorInterval} ${floorInterval > 1 ? "months" : "month"} ${suffix}`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    const floorInterval = Math.floor(interval);
    return `${floorInterval} ${floorInterval > 1 ? "days" : "day"} ${suffix}`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    const floorInterval = Math.floor(interval);
    return `${floorInterval} ${floorInterval > 1 ? "hours" : "hour"} ${suffix}`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    const floorInterval = Math.floor(interval);
    return `${floorInterval} ${floorInterval > 1 ? "minutes" : "minute"} ${suffix}`;
  }
  return `${seconds <= 0 ? "a few" : seconds} ${seconds === 1 ? "second" : "seconds"} ${suffix}`;
};

export default xTimeAgo;
