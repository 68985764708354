import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const sizeRunListAtom = atom({
  key: "size-run-list",
  default: {
    status: "initial", data: [], channelStoreList: [], originalData: [],
  },
  effects_UNSTABLE: [persistAtom],
});
