export const storeFields = {
  brand: [{ required: true, message: "Please select a brand" }],
  channel: [{ required: true, message: "Please select a channel" }],
  number: [
    {
      pattern: /^\d+$/, message: "Number must be digits",
    },
  ],
  name: [
    { required: true, message: "Please enter a store name" },
    { pattern: /^[a-zA-Z0-9/-=_+@)(\s]+$/, message: "Store name is invalid" },
    { max: 45, message: "Store name is too long" },
  ],
  country: [{ required: true, message: "Please select a country" }],
  state: [
    { required: true, message: "Please enter a state" },
    { pattern: /^[a-zA-Z0-9/-=_+@)(\s]+$/, message: "State is invalid" },
  ],
  city: [
    { required: true, message: "Please enter a city" },
    { pattern: /^[a-zA-Z0-9/-=_+@)(\s]+$/, message: "City is invalid" },
  ],
  district_manager_name: [
    { pattern: /^[a-zA-Z0-9/-=_+@)(\s]+$/, message: "Manager name is invalid" },
  ],
  allocation_management_team: [{ required: true, message: "Please select a management team" }],
  opening_at: [{ required: true, message: "Please select an opening date" }],
  allocation_start_at: [{ required: true, message: "Please select an allocation start date" }],
  warehouse: [{ required: true, message: "Required Field" }],
};
