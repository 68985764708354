/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./index.less";
import {
  Form, message, Spin,
} from "antd";
import { useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import { IUser } from "../../../../global/interfaces";
import { selectedUserAtom, userListAtom } from "../../../../global/atoms";
import paths from "../../../../configs/paths";
import { updateUser } from "../../services/user";
import { UserPageHeader } from "../../index";
import DartPrompt from "../../../../components/DartPrompt";
import DartButton from "../../../../components/DartButton";
import UserForm from "../../blocks/UserForm";

interface IUserEdit {
  onBack: () => any;
}

const UserEdit: React.FC<IUserEdit> = ({
  onBack,
}) => {
  const selectedUser = useRecoilValue(selectedUserAtom);
  const userList = useRecoilValue(userListAtom);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [userData, setUserData] = useState<IUser>();
  const [userForm] = Form.useForm();

  useEffect(() => {
    if (selectedUser) {
      setUserData(userList?.data.find((u: IUser) => u.username === selectedUser));
    } else {
      history.push(paths.user_list);
    }
  }, [userList.status, selectedUser]);

  const askDelete = () => {
    setDeleteOpen(true);
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    setDeleteOpen(false);
    setDeleteLoading(false);
    history.push(paths.user_list);
  };

  const handleSave = async () => {
    // check forms for errors
    let hasErrors = false;
    try {
      await userForm.validateFields();
    } catch (e) {
      hasErrors = true;
    }
    if (hasErrors) {
      message.error("Please check all fields are correct");
      return;
    }

    // get field values from each form
    const userFormValues = userForm.getFieldsValue();

    // Create user data object
    const finalData = {
      username: userData?.username,
      name: userFormValues.name,
      email: userFormValues.email,
      phone_number: userFormValues.phone_number,
      role: userFormValues.role,
      ...(userFormValues.region ? { region: userFormValues.region } : {}), // Only super admin can do that
      job_designation: userFormValues.job_designation,
      enabled: userFormValues.enabled === "true",
    };

    setUserData({
      ...userData,
      ...finalData,
    });

    setLoading(true);

    // Update size run request
    await updateUser(
      finalData,
      setLoading,
      () => {
        history.push(paths.user_list);
      },
    );

    setLoading(false);
  };

  return (
    <div className="user-edit-widget">
      {userData ? (
        <>
          <UserPageHeader
            user={userData}
            onBack={onBack}
          />
          <DartPrompt
            title="Delete User?"
            content={(
              <div>
                {`User "${userData?.name}" will be deleted from system. This action can not be reverted.`}
              </div>
            )}
            okText="Yes, delete user"
            cancelText="Cancel"
            visible={deleteOpen}
            onCancel={() => setDeleteOpen(false)}
            onOk={handleDelete}
            okButtonProps={{ loading: deleteLoading }}
          />
          <UserForm
            form={userForm}
            userFormData={userData}
            editable
          />
          <DartButton
            isSubmit
            loading={loading}
            className="user-edit-form-action"
            onClick={handleSave}
            label="Save Changes"
            size="lg"
            style={{ width: "100%" }}
          />
        </>
      ) : <Spin className="spin-loader" />}
    </div>
  );
};

export default UserEdit;
