import { removeNullValues } from "./index";

const mergeObjectIntoArray = (
  list: any[], obj: any, listKey: string, listKeyName: string, objectKey: string, emptyObjectSpread: any,
) => {
  const mergedValues:any = {};
  (list || []).map((listItem: any) => {
    if (listItem[listKey] && !mergedValues[listItem[listKey]]) {
      mergedValues[listItem[listKey]] = {};
      if (obj?.[listItem[listKey]]) {
        mergedValues[listItem[listKey]] = {
          [listKeyName]: listItem, ...emptyObjectSpread, ...(removeNullValues(obj?.[listItem[listKey]]) || {}),
        };
      } else {
        mergedValues[listItem[listKey]] = {
          [listKeyName]: listItem, [objectKey]: listItem[listKey], ...emptyObjectSpread,
        };
      }
    }
    return listItem;
  });
  return mergedValues;
};

export default mergeObjectIntoArray;
