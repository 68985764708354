import "./index.less";
import {
  Alert, Form, Input, message,
} from "antd";
import { LockOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Auth } from "aws-amplify";
import { useHistory, useParams } from "react-router-dom";
import DartButton from "../../../../components/DartButton";
import { userAtom } from "../../../../global/atoms";
import DartLogo from "../../../../assets/images/logo.png";
import { completeNewPassword } from "../../services/auth";
import paths from "../../../../configs/paths";

const SetPasswordLayout = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const [error, setError] = useState<string | JSX.Element>("");
  const [loading, setLoading] = useState(false);
  const { email } = useParams<{email: string}>();
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    if (user?.error) { setUser({ ...user, error: undefined }); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.data?.idToken]);

  const onSubmit = async (values: any) => {
    setError("");
    setLoading(true);
    // Check if passwords match
    if (values.new !== values?.repeat) {
      setError("Passwords don't match. Please try again.");
      setLoading(false);
      return;
    }
    // Check if new password matches criteria
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,64}$/.test(values.new)) {
      setError((
        <ul>
          {values.new?.length < 8 && <li>At least 8 characters</li>}
          {!/^.{1,64}$/.test(values.new) && <li>Maximum of 64 characters</li>}
          {!/\W/.test(values.new) && <li>At least one special character</li>}
          {![...values.new].some((char: string) => /[A-Z]/.test(char)) && <li>At least one capital letter</li>}
          {![...values.new].some((char: string) => /[a-z]/.test(char)) && <li>At least one lowercase letter</li>}
          {![...values.new].some((char: string) => /\d/.test(char)) && <li>At least one numerical character</li>}
        </ul>
      ));
      setLoading(false);
      return;
    }
    // Check if current password is correct
    try {
      await Auth.signIn(email, values.current);
    } catch (e: any) {
      setError(e.message);
      setLoading(false);
      return;
    }

    // If all above checks are OK, change password
    await completeNewPassword(
      email, values.current, values.new, setUser,
    );

    message.success("Password set successfully");

    history.push(paths.newness);
    setLoading(false);
  };

  return (
    <div className="set-password-form">
      <div
        className="logo-wrap"
        onClick={() => history.push(paths.signin)}
      >
        <img
          src={DartLogo}
          alt="DART"
        />
      </div>
      <h1 className="set-password-header">
        You have logged in for first time
      </h1>
      <h2 className="set-password-subheader">
        Please set a new password to continue using the system
      </h2>
      <div className="inner-wrap">
        <Form
          name="set_password"
          onFinish={(values) => onSubmit(values)}
          form={form}
        >
          <Form.Item
            name="current"
            rules={[
              {
                required: true,
                message: "Current Password is required",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Current Password"
            />
          </Form.Item>
          <Form.Item
            name="new"
            rules={[
              {
                required: true,
                message: "New Password is required",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="New Password"
            />
          </Form.Item>
          <Form.Item
            name="repeat"
            rules={[
              {
                required: true,
                message: "Repeat New Password is required",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Repeat New Password"
            />
          </Form.Item>
          <Form.Item>
            <DartButton
              type="primary"
              label="Set Password"
              size="md"
              loading={user.status === "request" || loading}
              onClick={() => form.submit()}
            />
          </Form.Item>
          {user.error || error ? (
            <Alert
              className="error-wrapper"
              type="error"
              message={user.error || error}
            />
          ) : ""}
        </Form>
      </div>
    </div>
  );
};

export default SetPasswordLayout;
