import React, {useEffect, useState} from "react";
import "./index.less";
import {useHistory, useParams} from "react-router-dom";
import {useRecoilState, useResetRecoilState} from "recoil";
import {message} from "antd";
import {getAllStores, StoreProfileCreate, StoreProfileView,} from "../..";
import {selectedStoreAtom, storeListAtom} from "../../../../global/atoms";
import {IStoreProfile} from "../../../../global/interfaces";
import paths from "../../../../configs/paths";
import {storeListFiltersAtom, storeListFiltersInitialState} from "../../../../global/atoms/store-list-filters-atom";
import {queryGet} from "../../../../utils/helpers";

const StoreProfileLayout:React.FC = () => {
  const { id } = useParams<{id: string}>();
  const [selectedStore, setSelectedStore] = useRecoilState(selectedStoreAtom);
  const [storeList, setStoreList] = useRecoilState(storeListAtom);
  const [retryCount, setRetryCount] = useState(0);
  const resetStoreListFilters = useResetRecoilState(storeListFiltersAtom);
  const history = useHistory();
  const useBrowserBack = queryGet("useBrowserBack") === "true";

  useEffect(() => {
    if (id) {
      setSelectedStore(+id);
    } else if (selectedStore && !id) {
      if (useBrowserBack) {
        history.goBack();
      } else {
        history.push(paths.view_all_stores);
      }
    }
  }, [selectedStore, id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedStore]);

  // on ID change fetch stores if no matching store with ID is found. if after this, still not found, redirect back (below useEffect)
  useEffect(() => {
    if ((id && +id && storeList?.data?.filter((s: IStoreProfile) => s.id === +id)?.length === 0) || storeList?.status !== "success") {
      resetStoreListFilters();
      getAllStores(
        storeList, setStoreList, storeListFiltersInitialState,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, retryCount]);

  // check if store with ID exists, else redirect back to list
  useEffect(() => {
    if ((id && selectedStore
      && storeList?.data?.length > 0
      && storeList?.status === "success"
      && storeList?.data?.filter((s: IStoreProfile) => s.id === +id)?.length === 0) || (id && storeList?.status === "failed")) {
      if (retryCount > 2) {
        message.error("Store not found");
        if (useBrowserBack) {
          history.goBack();
        } else {
          history.push(paths.view_all_stores);
        }
      } else {
        setRetryCount((prev) => prev + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, storeList, retryCount]);

  return (
    <div className="store-profile-layout-wrap">
      <div className="store-profile-layout">
        {selectedStore ? (
          <StoreProfileView
            onBack={() => {
              setSelectedStore(undefined);
              if (useBrowserBack) {
                history.goBack();
              } else {
                history.push(paths.view_all_stores);
              }
            }}
          />
        ) : (
          <StoreProfileCreate
            onBack={() => { setSelectedStore(undefined); history.push(paths.view_all_stores); }}
          />
        )}
      </div>
    </div>
  );
};

export default StoreProfileLayout;
