import React from "react";
import "./index.less";
import { IReplenishmentCC } from "../../../../global/interfaces";
import Icon from "../../../../components/Icon";

interface ISelectedStyleColorBlock {
  ccData: IReplenishmentCC;
  onRemove?: (cc: string) => void;
  theme?: "dark" | "light";
}

/*
* EXAMPLE DATA:
  allocatable_inv: 1860
  cc: "1146170-RCR"
  department: "FOOTWEAR"
  division: "WOMEN"
  klass: "Classic Boot"
  store_inv: 0
  woh: null
* */

const SelectedStyleColorBlock:React.FC<ISelectedStyleColorBlock> = ({
  ccData,
  onRemove,
  theme = "dark",
}) => {
  return (
    <div className={`selected-style-color-block ${theme}`}>
      <div className="cc-info">
        <div className="left">
          <div className="top">
            <span className="bold">{ccData.cc}</span>
            {ccData.gtm && <span>{ccData.gtm}</span> }
            {ccData.description && <span>{ccData.description}</span>}
          </div>
          <div className="bottom">
            <span>{ccData.division}</span>
            <span>{ccData.department}</span>
            {ccData.klass && <span>{ccData.klass}</span>}
          </div>
        </div>
        <div className="right">
          <div className="woh">
            <div className="value">
              {ccData.woh || 0}
            </div>
            <div className="label">
              WOH
            </div>
          </div>
          <div className="store-inv">
            <div className="value">
              {ccData.store_inv || 0}
            </div>
            <div className="label">
              Store Inv
            </div>
          </div>
          <div className="allocatable-inv">
            <div className="value">
              {ccData.allocatable_inv || 0}
            </div>
            <div className="label">
              Allocatable Inv
            </div>
          </div>
        </div>
      </div>
      {onRemove && (
      <div
        className="remove-wrapper"
        onClick={() => onRemove?.(ccData?.cc)}
      >
        <Icon name="Close" />
      </div>
      )}
    </div>
  );
};

export default SelectedStyleColorBlock;
