/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import "./index.less";
import {Form, message, Spin,} from "antd";
import {useRecoilState, useRecoilValue} from "recoil";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {deleteStore, StoreAssortmentTiers, StoreProfileDetails, StoreProfileHeader,} from "../..";
import {ISelectOption, IStoreProfile} from "../../../../global/interfaces";
import DartButton from "../../../../components/DartButton";
import StoreAllocationSettings from "../../blocks/StoreAllocationSettings";
import StoreTags from "../../blocks/StoreTags";
import {selectedStoreAtom, storeListAtom} from "../../../../global/atoms";
import paths from "../../../../configs/paths";
import {arrayCompare, queryGet, valueToAssortmentValue,} from "../../../../utils/helpers";
import {updateStore} from "../../services/store";
import storeListFilters from "../../blocks/StoreListFilters";
import DartPrompt from "../../../../components/DartPrompt";
import useEnums from "../../../../utils/hooks/useEnums";
import {fetchAvailableTags} from "../../../common";
import CanAccess, {ADMIN, MANAGER, SUPER_ADMIN,} from "../../../common/widgets/CanAccess";
import {storeListFiltersInitialState} from "../../../../global/atoms/store-list-filters-atom";

interface IStoreProfileView {
  onBack: () => any;
}

const StoreProfileView:React.FC<IStoreProfileView> = ({
  onBack,
}) => {
  const [storeList, setStoreList] = useRecoilState(storeListAtom);
  const selectedStore = useRecoilValue(selectedStoreAtom);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [tagDeleteLoading, setTagDeleteLoading] = useState(false);
  const [tagDeleteOpen, setTagDeleteOpen] = useState(false);
  const [savePromptVisible, setSavePromptVisible] = useState(false);
  const [deleteTagName, setDeleteTagName] = useState<string | undefined>();
  const [availableTags, setAvailableTags] = useState<any | undefined>();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const [storeData, setStoreData] = useState<IStoreProfile>();
  const [assortmentForm] = Form.useForm();
  const [detailsForm] = Form.useForm();
  const [generalInformationForm] = Form.useForm();
  const [allocationSettingsForm] = Form.useForm();

  const { warehouse_id } = useEnums();
  const warehouseOptions = Object.values(warehouse_id || {})?.map((e) => { return { label: e, value: e }; });

  const useBrowserBack = queryGet("useBrowserBack") === "true";

  useEffect(() => {
    document.title = `DART - ${storeData?.number ? `${storeData?.number} | ` : ""}${storeData?.name?.toUpperCase() || selectedStore} STORE`;
  }, [storeData?.name, selectedStore]);

  useEffect(() => {
    fetchAvailableTags(availableTags, setAvailableTags);
  }, []);

  useEffect(() => {
    if (!selectedTags?.length) {
      setSelectedTags(storeData?.tags || []);
    }
  }, [storeData, availableTags]);

  useEffect(() => {
    if (selectedStore) {
      setStoreData((storeList.data || []).find((store: any) => store.id === selectedStore));
    } else if (useBrowserBack) {
      history.goBack();
    } else {
      history.push(paths.view_all_stores);
    }
  }, [storeList.status, selectedStore]);

  const askDelete = () => {
    setDeleteOpen(true);
  };

  const handleDelete = async () => {
    const generalInformationValues = generalInformationForm.getFieldsValue();
    setDeleteLoading(true);
    await deleteStore(
      { id: selectedStore, ...generalInformationValues }, storeList, setStoreList, storeListFilters,
    );
    setDeleteOpen(false);
    setDeleteLoading(false);
    if (useBrowserBack) {
      history.goBack();
    } else {
      history.push(paths.view_all_stores);
    }
  };

  const askTagDelete = (tag: string) => {
    setTagDeleteOpen(true);
    setDeleteTagName(tag);
  };

  const handleTagDelete = async () => {
    setTagDeleteLoading(true);
    setTagDeleteOpen(false);
    if (deleteTagName) {
      setAvailableTags((prev: any) => { return { ...prev, data: [...prev?.data?.filter((t: string) => t !== deleteTagName)] }; });
      setSelectedTags((prev: any) => prev.filter((t: string) => t !== deleteTagName));
    }
    setTagDeleteLoading(false);
  };

  // Transform Tier data to acceptable BE format
  const transformTierData = (vals: any) => {
    const assortmentTiers: any = {};
    const keys = Object.keys(vals);
    const values: any = Object.values(vals);

    for (let i = 0; i < values.length; i += 1) {
      const key = keys[i];
      const value = values[i];
      const [division, department] = key.split(".");

      // If it's warehouse_id selection, append it to corresponding division.department object
      if (value !== "undefined" && !division.startsWith("WAREHOUSE_")) {
        if (!assortmentTiers?.[division]) {
          assortmentTiers[division] = {};
        }
        if (!assortmentTiers?.[division]?.[department]) {
          assortmentTiers[division][department] = {};
        }
        assortmentTiers[division][department].tier = valueToAssortmentValue(value);
      } else if (division.startsWith("WAREHOUSE_")) {
        assortmentTiers[division.replace("WAREHOUSE_", "")][department].warehouse_rels
          = value?.map((e: ISelectOption, index: number) => {
            return {
              warehouse_id: e.value,
              priority: index,
            };
          }) || [{ warehouse_id: warehouseOptions?.[0]?.value, priority: 0 }];
      }
    }
    return assortmentTiers;
  };

  const handleSave = async () => {
    setSavePromptVisible(false);
    // check forms for errors
    let hasErrors = false;
    try {
      await detailsForm.validateFields();
    } catch (e) {
      hasErrors = true;
    }
    try {
      await generalInformationForm.validateFields();
    } catch (e) {
      hasErrors = true;
    }
    try {
      await allocationSettingsForm.validateFields();
    } catch (e) {
      hasErrors = true;
    }
    try {
      await assortmentForm.validateFields();
    } catch (e) {
      hasErrors = true;
    }
    if (hasErrors) {
      message.error("Please check all fields are correct");
      return;
    }

    // get field values from each form
    const detailsValues = detailsForm.getFieldsValue();
    const generalInformationValues = generalInformationForm.getFieldsValue();
    const allocationSettingsValues = allocationSettingsForm.getFieldsValue();
    const assortmentValues = assortmentForm.getFieldsValue();
    const tags = selectedTags;

    // Convert assortment tiers from dot separated string to object with nested properties
    const assortmentTiers: any = transformTierData(assortmentValues);

    // Create store profile data object
    const finalData = {
      id: selectedStore,
      name: generalInformationValues.name,
      number: generalInformationValues.number === "" ? null : generalInformationValues.number,
      channel: detailsValues.channel,
      brand: detailsValues.brand?.toUpperCase(),
      country: generalInformationValues.country,
      allocation_management_team: allocationSettingsValues.allocation_management_team,
      opening_at: allocationSettingsValues.opening_at ? moment(allocationSettingsValues.opening_at).format("YYYY-MM-DD") : null,
      closing_at: allocationSettingsValues.closing_at ? moment(allocationSettingsValues.closing_at).format("YYYY-MM-DD") : null,
      allocation_start_at: allocationSettingsValues.allocation_start_at
        ? moment(allocationSettingsValues.allocation_start_at).format("YYYY-MM-DD") : null,
      allocation_stop_at: allocationSettingsValues.allocation_stop_at
        ? moment(allocationSettingsValues.allocation_stop_at).format("YYYY-MM-DD") : null,
      state: generalInformationValues.state,
      city: generalInformationValues.city,
      district_manager_name: generalInformationValues.district_manager_name,
      departments: assortmentTiers,
      sister_store_id: generalInformationValues?.sister_store_id?.value || generalInformationValues?.sister_store_id,
      tags,
    };

    setStoreData({
      ...storeData,
      ...finalData,
    });

    setLoading(true);

    // Update store profile request
    await updateStore(
      finalData, storeList, setStoreList, storeListFiltersInitialState,
    );

    setLoading(false);
  };

  // Check if Brand, Channel or any Department Tier / Warehouse ID changed, if yes, prompt about save
  const promptOrSave = () => {
    let hasPromptableChange = false;
    const detailsValues = detailsForm.getFieldsValue();
    if (storeData?.brand !== detailsValues?.brand || storeData?.channel !== detailsValues?.channel) {
      hasPromptableChange = true;
    }

    // Convert assortment tiers from dot separated string to object with nested properties
    const assortmentValues = assortmentForm.getFieldsValue();
    const assortmentTiers: any = transformTierData(assortmentValues);

    Object.keys(assortmentTiers)?.map((division) => {
      Object.keys(assortmentTiers[division])?.map((department) => {
        if ((storeData as any)?.departments?.[division]?.[department]?.tier !== assortmentTiers[division][department]?.tier
          // eslint-disable-next-line
          || !arrayCompare((storeData as any)?.departments?.[division]?.[department]?.warehouse_rels, assortmentTiers[division][department]?.warehouse_rels, "warehouse_id")) {
          hasPromptableChange = true;
        }
        return department;
      });
      return division;
    });

    if (hasPromptableChange) {
      setSavePromptVisible(true);
    } else {
      handleSave();
    }
  };

  return (
    <div className="store-profile-view">
      {storeData ? (
        <>
          <StoreProfileHeader
            store={storeData}
            onDelete={askDelete}
            onBack={onBack}
            deleteLoading={deleteLoading}
          />
          <DartPrompt
            title="Delete this store?"
            content={(
              <div>
                You won’t be able to retrieve information about this store. This can’t be undone.
                <br />
                <br />
                Are you sure you want to delete?
              </div>
            )}
            okText="Yes, delete this store"
            cancelText="No, return to store profile"
            visible={deleteOpen}
            onCancel={() => setDeleteOpen(false)}
            onOk={handleDelete}
            okButtonProps={{ loading: deleteLoading }}
          />
          <StoreProfileDetails
            topForm={detailsForm}
            bottomForm={generalInformationForm}
            storeDetails={storeData}
            editable={false}
          />
          <hr className="splitter" />
          <StoreAssortmentTiers
            form={assortmentForm}
            selected_assortment={storeData.departments}
          />
          <hr className="splitter" />
          <StoreAllocationSettings
            form={allocationSettingsForm}
            storeDetails={storeData}
            editable={false}
          />
          <CanAccess requireRoles={[SUPER_ADMIN, ADMIN, MANAGER]}>
            <>
              <hr className="splitter" />
              <StoreTags
                handleAddTag={(tag) => {
                  setSelectedTags((prev: any) => [...prev, tag]);
                }}
                handleRemoveTag={(tag) => {
                  setSelectedTags((prev:any) => prev.filter((t: string) => t !== tag));
                }}
                handleCreateTag={(tag) => {
                  setAvailableTags((prev: any) => { return { ...prev, data: [...prev?.data, tag] }; });
                }}
                handleDeleteTag={(tag) => {
                  askTagDelete(tag);
                }}
                selectedTags={selectedTags}
                availableTags={availableTags?.data || []}
                loading={availableTags?.status === "request"}
              />
            </>
          </CanAccess>
          <DartPrompt
            title="Remove this tag?"
            content={(
              <div>
                This tag will be removed from this store.
                <br />
                Are you sure you want to proceed?
              </div>
            )}
            okText="Yes, remove this tag"
            cancelText="No, return to store profile"
            visible={tagDeleteOpen}
            onCancel={() => setTagDeleteOpen(false)}
            onOk={handleTagDelete}
            okButtonProps={{ loading: tagDeleteLoading }}
          />
          <DartPrompt
            title="Are you sure ?"
            content={(
              <div>
                This will also change IAQ settings for CCs.
                <br />
                Are you sure you want to proceed?
              </div>
            )}
            okText="Yes"
            cancelText="No"
            visible={savePromptVisible}
            onCancel={() => setSavePromptVisible(false)}
            onOk={handleSave}
          />
          <DartButton
            isSubmit
            loading={loading}
            className="store-profile-form-action"
            onClick={promptOrSave}
            label="Save Changes"
            size="lg"
            style={{ width: "100%" }}
            disabled={selectedTags?.length > 3}
          />
        </>
      ) : <Spin className="spin-loader" />}
    </div>
  );
};

export default StoreProfileView;
