const stringFloatToNumber = (value?: string) => {
  if (value === undefined) {
    return 0;
  }
  if (value.toString().length !== 6) { // 0.0001
    return 0;
  }
  return parseInt(value.toString().replace(".", ""), 10);
};

export default stringFloatToNumber;
