import React, { useEffect, useState } from "react";
import "./index.less";
import {
  Form, message, Modal,
} from "antd";
import { useRecoilState } from "recoil";
import { read, utils } from "xlsx";
import { useHistory } from "react-router-dom";
import moment from "moment";
import DartButton from "../../../../components/DartButton";
import {
  decideDepartmentName, decideDivisionName, removeNullValues,
} from "../../../../utils/helpers";
import {
  FlowDocPreview, getUploadList, NewnessUploadStep2,
} from "../..";
import { sizeRunListAtom, uploadFileListAtom } from "../../../../global/atoms";
import PageTitle from "../../../../components/PageTitle";
import UploadedFileListFiltersBlock from "../../blocks/UploadedFileListFiltersBlock";
import useEnums from "../../../../utils/hooks/useEnums";
import UploadFilesTableBlock from "../../blocks/UploadFilesTableBlock";
import {
  errorFromFlowDocValue,
  flowDocColumnValidations,
  SIZE_RUN_MISMATCH_ERROR_MESSAGE,
} from "../../blocks/FlowDocPreviewBlock/validations";
import { getSizeRunList } from "../../../setup/size-profile";
import { saveFlowDocFile } from "../../services/upload";

const NewnessUploadListLayout = () => {
  const history = useHistory();
  const { import_type } = useEnums();
  const enums = useEnums();
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const [uploadListData, setUploadListData] = useRecoilState(uploadFileListAtom);
  const [preUploadData, setPreUploadData] = useState<any>({});
  const [tableData, setTableData] = useState<any>([]);
  const [initFetch, setInitFetch] = useState(false);
  const [tableFilters, setTableFilters] = useState<any>({});
  const [availableUsers, setAvailableUsers] = useState<any>([]);
  const [userListPassed, setUserListPassed] = useState(false);

  const [debounced, setDebounced] = useState(false);
  const [flowDocToUploadObj, setFlowDocToUploadObj] = useState<any>(undefined);
  const [flowDocErrorCount, setFlowDocErrorCount] = useState(0);
  const [availableSizeRuns, setAvailableSizeRuns] = useRecoilState(sizeRunListAtom);
  const [flowDocProgress, setFlowDocProgress] = useState({ status: "initial", data: {} });

  useEffect(() => {
    if (tableData?.length > 0) {
      if (!userListPassed) {
        const list: any = {};
        for (let i = 0; i < tableData.length; i += 1) {
          list[tableData[i].uploaded_by] = tableData[i].uploaded_by_email;
        }
        setAvailableUsers(list);
        setUserListPassed(true);
      }
    }
  }, [tableData, userListPassed]);

  // Get list of uploaded files
  useEffect(() => {
    if (!initFetch) {
      // Call Service that gets uploaded file list
      getUploadList(uploadListData, setUploadListData);
      getSizeRunList(
        availableSizeRuns, setAvailableSizeRuns, "ALL", false,
      );
      setInitFetch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initFetch]);

  // Re-occurring useEffect that fetches the list again and again every 20 seconds to have updated data
  useEffect(() => {
    if (!flowDocToUploadObj) {
      const interval = setInterval(() => {
        getUploadList(
          uploadListData, setUploadListData, true,
        );
      }, 20000);
      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
    return () => false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadListData, flowDocToUploadObj]);

  // Filter data according to selected filters and prepares them to be accepted by the table
  useEffect(() => {
    setTableData((uploadListData?.data || []).filter((td: any) => {
      let authorFiltered = true;
      let statusFiltered = true;
      const importTypeFiltered = (td.import_type || "").toString().indexOf("FLOW_DOC") > -1;
      if (tableFilters.uploaded_by && td.uploaded_by_email === tableFilters.uploaded_by) {
        authorFiltered = true;
      } else if (tableFilters.uploaded_by) {
        authorFiltered = false;
      }
      if (tableFilters.status && td.status === tableFilters.status) {
        statusFiltered = true;
      } else if (tableFilters.status) {
        statusFiltered = false;
      }
      return authorFiltered && statusFiltered && importTypeFiltered ? td : false;
    }));
  }, [uploadListData?.data, tableFilters]);

  // Handler that validates form field and sets validated data to state
  const handleFormChange = (formItem: any) => {
    const fieldName = formItem?.[0]?.name?.[0];
    const fieldValue = formItem?.[0]?.value;
    if (fieldName !== "file") {
      setPreUploadData(removeNullValues({
        ...preUploadData,
        [fieldName]: fieldValue && fieldValue?.length ? fieldValue : undefined,
        doc_type: ["NEWNESS_DATA_VAR"],
        import_types: ["FLOW_DOC"],
      }));
    }
  };

  // Handler to cancel the upload process and reset all fields
  const handleCancel = () => {
    setPreUploadData({});
    form.setFieldsValue({ file: undefined });
    form.resetFields();
  };

  // Function that parses Excel cell value based on the field (column) name
  const parseValue = (
    field: string, value: any, valueFromArray?: any[],
  ) => {
    switch (field) {
      case "division":
        return Object.keys(enums.division || {}).find((division) => {
          const parsedA = decideDivisionName(enums.division[division]).replace("’", "'");
          const parsedB = value.replace("’", "'");
          return parsedA === parsedB;
        }) || value;
      case "department":
        return Object.keys(enums.department || {}).find((department) => {
          const parsedA = decideDepartmentName(enums.department[department]).replace("’", "'");
          const parsedB = value.replace("’", "'");
          return parsedA === parsedB;
        }) || value;
      case "fulfilment_category":
        if (value?.toLowerCase() === "new") {
          return "NEWNESS";
        }
        if (value?.toLowerCase() === "co") {
          return "REPLENISHMENT";
        }
        return value;
      case "tier":
        return ({
          Special: 0,
          "Tier 1": 1,
          "Tier 2": 2,
          "Tier 3": 3,
          "Tier 4": 4,
        } as any)[value];
      case "size_run_id":
        return valueFromArray?.find((v) => v?.value === value || v?.label === value)?.value || value;
      case "map_break":
        return moment(value, "YYYY-MM-DD").isValid()
        && moment(value, "YYYY-MM-DD").isAfter("1900-01-02")
          ? moment(value, "YYYY-MM-DD").format("YYYY-MM-DD")
          : "1900-01-01";
      case "date":
        if (moment(value, "YYYY-MM-DD").isValid()) {
          return moment(value, "YYYY-MM-DD").format("YYYY-MM-DD");
        } if (moment(value, "MM/DD/YY").isValid()) {
          return moment(value, "MM/DD/YY").format("YYYY-MM-DD");
        }
        return undefined;
      default:
        return value;
    }
  };

  // Handler that uploads the file and opens in live edit mode
  const handleFileUpload = async (files: any) => {
    const delimiter = "±§"; // THIS IS IMPORTANT BECAUSE FLOW-DOC MAY CONTAIN ","
    const f = await (files.file.arrayBuffer());
    const wb = read(f); // parse the array buffer
    const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
    const dataCSV = utils.sheet_to_csv(ws, { FS: delimiter }); // generate CSV
    const rows = dataCSV.split("\n"); // split into rows
    if (rows?.length > 3) {
      const metaRow = rows[0]?.split(delimiter); // row for meta-data, such as Channel of the flow doc
      const commentRow = rows[1]?.split(delimiter); // row for comments
      const headerRow = rows[2]?.split(delimiter); // row for column headers
      const dataRows = rows.slice(3)?.filter((r) => !r.startsWith(delimiter))?.map((r) => r.split(delimiter)); // rows for data
      const flowDocChannel = metaRow?.[0]?.toUpperCase(); // extract flow doc channel
      const buildCCObjectArray = []; // initialize empty CC placeholder array for table
      const nonEmptyHeaderRows = headerRow.filter((e) => e.length > 0); // This was necessary because some excels have 200+ columns with empty cells

      for (let i = 0; i < dataRows.length; i += 1) { // For each row of data, create cc in array
        const cc: any = {}; // initialise empty CC
        let errorCount = 0; // initialise error count
        const errors = []; // initialize empty error array
        const validations = flowDocColumnValidations();
        for (let cK = 0; cK < nonEmptyHeaderRows?.length; cK += 1) { // Loop through columns from nonEmptyHeaderRows
          const headerRowRelatedObject = (validations as any)?.[nonEmptyHeaderRows[cK]?.toUpperCase() as any];
          if (headerRowRelatedObject) { // If column is allowed (necessary) (we need uppercase to match excel)
            const allowedColumnObj = headerRowRelatedObject; // Get allowed column object
            const { apiName, excelName } = allowedColumnObj as any; // extract apiName and excelName
            if (allowedColumnObj) { // if AllowedColumnObj exists
              let appendList: any[] = []; // initialize empty appendList
              if (apiName === "size_run_id") { // Check if column is size_run_id and find available size runs based on department and division
                const departments = availableSizeRuns?.data?.[cc?.division]; // get departments for division
                const sizeRuns = departments?.[cc?.department]; // get size runs for department
                appendList = sizeRuns?.map((sr: any) => { // filter sizeruns based on department and division
                  return {
                    value: sr?.id,
                    label: sr?.name,
                  };
                }) || [];
                cc[apiName] = parseValue(
                  apiName, dataRows[i][cK], appendList, // parse row-cell value based on apiName and use appendList as it's size_run_id
                );
              } else {
                cc[apiName] = parseValue(apiName, dataRows[i][cK]); // other-wise just parse row-cell data
              }
              const error = errorFromFlowDocValue( // Extract FE error for specific value based on apiName and value from validations object
                parseValue(apiName, dataRows[i][cK]), (validations as any)[excelName], appendList,
              );
              errorCount += error?.length > 0 ? 1 : 0; // Increase an error count if error exists
              errors.push({ column: apiName, error }); // push errors under specific column name for each CC
            }
          }
        }

        buildCCObjectArray.push({ // Build final object
          ...cc,
          hasError: errorCount > 0, // Display if row has any error at all or not
          errors, // errors array / always has length but properties can be empty (no error) depending on the column
        });
      }
      setFlowDocToUploadObj({ // finally, set it to flowDocToUploadObj
        channel: flowDocChannel,
        comments: commentRow,
        headers: nonEmptyHeaderRows,
        data: buildCCObjectArray,
      });
    } else if (rows?.length <= 3) {
      message.error("No CCs found in the flow-doc"); // If no CCs were in the flow doc
    } else {
      message.error("No data found in the file"); // If Excel can not be parsed
    }

    handleCancel(); // Close upload modal
  };

  // useEffect hook to check for existing errors and set count of it
  useEffect(() => {
    // reduce errors in different ccs
    setFlowDocErrorCount(flowDocToUploadObj?.data?.reduce((acc: any, curr: any) => {
      return acc + (curr.errors?.filter((err: { column: string, error: string }) => err.error?.length > 0))?.length;
    }, 0));
  }, [flowDocToUploadObj]);

  // Function to parse size run id based on CCs division and department and
  // only assign it in case of bulk edit if it exists
  // This function will check while bulk editing, if size run exists in bulk cc,
  // it will apply the size run, otherwise it will leave old one
  const parseSizeRunIdFromValue = (cc: any, value: any) => {
    const departments = availableSizeRuns?.data?.[cc?.division]; // get departments for division
    const sizeRuns = departments?.[cc?.department]; // get size runs for department
    const sizeRunChoices = sizeRuns?.map((sr: any) => { // filter sizeruns based on department and division
      return {
        value: sr?.id,
        label: sr?.name,
      };
    }) || [];

    const sizeRunExistsInNewChoices = sizeRunChoices.some((sR: any) => sR.value.toString() === value?.toString());

    if (sizeRunExistsInNewChoices) {
      return { error: false, value };
    }

    const sizeRunExistsInOldChoices = sizeRunChoices.some((sR: any) => sR.value.toString() === cc.size_run_id?.toString());

    if (sizeRunExistsInOldChoices) {
      return { error: false, value: cc.size_run_id };
    }

    return { error: SIZE_RUN_MISMATCH_ERROR_MESSAGE, value: cc.size_run_id };
  };

  // Function that dynamically handles uploaded flow doc edit, mapping errors and updating values.
  // Also, if division or department is updated, reset size_run_id (line 277)
  const handleFieldUpdate = (
    ccs: string[], field: string, value: any, error: string,
  ) => {
    setDebounced(false);
    setFlowDocToUploadObj((prev: any) => ({
      channel: prev.channel,
      comments: prev.comments,
      headers: prev.headers,
      data: [
        ...prev.data.map((d: any) => {
          if (ccs.includes(d.cc)) {
            let newSizeRun: any;
            if (ccs.length > 1 && field === "size_run_id") {
              // Applicable if mode is set to bulk and we are changing size run
              newSizeRun = parseSizeRunIdFromValue(d, value);
            }
            return {
              ...d,
              [field]: value,
              hasError: (!!newSizeRun && newSizeRun.error) || !!error || false,
              errors: d.errors?.map((e: any) => {
                if (field === "cc") {
                  return {
                    ...e,
                    error: "",
                  };
                }
                if ((field === "division" || field === "department") && e.column === "size_run_id") {
                  return {
                    ...e,
                    error: "Size Run is required",
                  };
                }
                if (field === "size_run_id" && e.column === "size_run_id" && (!!newSizeRun && newSizeRun.error)) {
                  return {
                    ...e,
                    error: newSizeRun.error,
                  };
                }
                if (e.column === field) {
                  return {
                    ...e,
                    error,
                  };
                }
                return e;
              }),
              ...(field === "division" || field === "department" ? {
                size_run_id: undefined,
              } : {}),
              ...(ccs.length > 1 && field === "size_run_id" ? {
                size_run_id: newSizeRun.value || undefined,
              } : {}),
            };
          }
          return d;
        }),
      ],
    }));
  };

  // Format dataToSend to correct format and attempt dry run.
  // If dry run does not return errors nor warnings, proceed with Save again but skipDryRun
  // If dry run returns errors, show errors and do not proceed with save
  // If dry run returns warnings only, show warnings and allow user proceed with save (show Upload Anyway button)
  // If user modifies anything during that state (dry run warning state), reset to dry run state and re-attempt dry run on next call
  const formatAndSaveFlowDoc = async (dryRun: boolean) => {
    const dataToSend = flowDocToUploadObj?.data?.map((d: any) => {
      return {
        division: d?.division === "" ? undefined : d?.division,
        department: d?.department === "" ? undefined : d?.department,
        brand: d?.brand === "" ? undefined : d?.brand,
        channel: flowDocToUploadObj?.channel === "" ? undefined : flowDocToUploadObj?.channel,
        // eslint-disable-next-line no-nested-ternary
        date: moment(d?.date, "YYYY-MM-DD").isValid()
          ? moment(d?.date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : (moment(d?.date, "MM/DD/YY").isValid()
            ? moment(d?.date, "MM/DD/YY").format("YYYY-MM-DD")
            : undefined
          ),
        cc: d?.cc === "" ? undefined : d?.cc,
        tier: d?.tier === "" ? undefined : d?.tier,
        fulfilment_category: d?.fulfilment_category === "" ? undefined : d?.fulfilment_category,
        size_run_id: d?.size_run_id === "" ? undefined : d?.size_run_id,
        klass: d?.klass === "" ? undefined : d?.klass,
        subklass: d?.subklass === "" ? undefined : d?.subklass,
        gtmcollections: d?.gtmcollections === "" ? undefined : d?.gtmcollections,
        description: d?.description === "" ? undefined : d?.description,
        buy: d?.buy === "" ? undefined : d?.buy,
        map_break: moment(d?.map_break, "YYYY-MM-DD").isValid()
        && moment(d?.map_break, "YYYY-MM-DD").isAfter("1900-01-02")
          ? moment(d?.map_break, "YYYY-MM-DD").format("YYYY-MM-DD")
          : "1900-01-01",
        tag: d?.tag === "" ? undefined : d?.tag,
      };
    });

    await saveFlowDocFile(
      flowDocProgress, setFlowDocProgress, dataToSend, dryRun, (responseData: any) => {
        if (responseData?.data?.message?.length > 0) {
          message.info(responseData?.data?.message);
        }
        if (!dryRun && !Object.keys(responseData?.data?.errors)?.length && Object.keys(responseData?.data?.database_result)?.length) {
          setDebounced(true);
          setTimeout(() => {
            setFlowDocToUploadObj(undefined);
          }, 1000);
          return;
        }
        const ccErrorsDict:any = {};
        for (let i = 0; i < Object.keys(responseData?.data?.errors || {})?.length; i += 1) {
          const ccKey = Object.keys(responseData?.data?.errors)?.[i];
          const cc = ccKey.split("_")?.[0];
          const ccErrors = (responseData?.data?.errors)[ccKey];
          if (ccErrors?.Uniqueness) {
            ccErrors.cc = ccErrors?.Uniqueness;
          }
          ccErrorsDict[cc] = ccErrors;
        }
        setFlowDocToUploadObj((prev: any) => ({
          ...prev,
          data: prev?.data?.map((dt: any) => {
            const currentCCErrors = ccErrorsDict[dt?.cc];
            return {
              ...dt,
              ...(currentCCErrors ? {
                errors: dt?.errors?.map((e: any) => {
                  if (currentCCErrors[e?.column]?.length > 0) {
                    return {
                      ...e,
                      error: `${
                        Object.values(flowDocColumnValidations()).find((fV) => fV.apiName === e?.column)?.uiName
                      }: ${currentCCErrors[e?.column]}`,
                    };
                  }
                  return e;
                }),
              } : {}),
            };
          }),
        }));
        setDebounced(false);
      },
    );
  };

  // Function that asks user if they want to proceed with save if there are errors in the flow doc
  const askFlowDocSave = (dryRun: boolean) => {
    if (dryRun) {
      confirm({
        title: "Are you sure ?",
        content: (
          <>
            <div>
              This will not upload flow doc.
              <br />
              <b>This is just a check before the upload.</b>
              <br />
              Do you want to continue ?
            </div>
          </>
        ),
        okText: "Validate",
        onOk: () => formatAndSaveFlowDoc(true),
      });
    } else if (flowDocErrorCount > 0) {
      confirm({
        title: "Are you sure ?",
        content: (
          <>
            <div>
              There are a total of
              {" "}
              <b>{flowDocErrorCount}</b>
              {" "}
              errors in this flow doc.
              <br />
              <b>This action is not reversible.</b>
              <br />
              Do you want to upload it anyway ?
            </div>
          </>
        ),
        okText: "Proceed",
        onOk: () => formatAndSaveFlowDoc(dryRun),
      });
    } else {
      confirm({
        title: "Are you sure ?",
        content: (
          <>
            <div>
              This action is not reversible.
            </div>
          </>
        ),
        okText: "Proceed",
        onOk: () => formatAndSaveFlowDoc(dryRun),
      });
    }
  };

  return (
    <div
      className="newness-upload-list-layout"
      // necessary for enum issue fix
      key={Object.values(import_type || {})?.length?.toString()}
    >
      <PageTitle
        title="File Upload (NEW)"
        icon="Document"
        onBack={flowDocToUploadObj ? () => { setFlowDocToUploadObj(undefined); } : undefined}
        rightNode={!flowDocToUploadObj ? (
          <div
            className="flow-doc-live-edit-upload"
            data-testid="upload-file-init-button"
          >
            <NewnessUploadStep2
              form={form}
              uploaderProps={{
                disabled: availableSizeRuns?.status !== "success",
                multiple: false,
                fileList: undefined,
                maxCount: 1,
                accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                beforeUpload: (file: any) => {
                  // Validate file type to accept only excel sheets
                  if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    message.error("Selected file type is not allowed");
                  }
                },
                customRequest: handleFileUpload,
              }}
              onFormChange={handleFormChange}
              buttonLabel="Upload Flow Document"
              disabled={availableSizeRuns?.status !== "success"}
              loading={availableSizeRuns?.status === "request" || availableSizeRuns?.status === "revalidate"}
            />
          </div>
        ) : (
          <div
            className="upload-file-init-button"
            data-testid="upload-file-init-button"
          >
            <DartButton
              label="Validate"
              icon="Check"
              size="sm"
              disabled={flowDocProgress.status === "request" || debounced}
              onClick={() => askFlowDocSave(true)}
            />
            <DartButton
              type={flowDocErrorCount > 0 ? "danger_full" : "primary"}
              label="Proceed to Upload"
              icon="ArrowRight"
              size="sm"
              disabled={flowDocProgress.status === "request" || debounced}
              onClick={() => askFlowDocSave(false)}
            />
          </div>
        )}
      />

      {flowDocToUploadObj ? (
        <div className="file-upload-live-edit-wrapper">
          <FlowDocPreview
            flowDocChannel={flowDocToUploadObj?.channel}
            flowDocComments={flowDocToUploadObj?.comments}
            flowDocHeaders={flowDocToUploadObj?.headers}
            flowDocData={flowDocToUploadObj?.data}
            flowDocErrorCount={flowDocErrorCount}
            onFieldUpdate={handleFieldUpdate}
          />
        </div>
      ) : (
        <div className="file-upload-page-wrapper">
          <div
            className="newness-upload-list-filters"
            data-testid="upload-file-filters-wrapper"
          >
            <UploadedFileListFiltersBlock
              onFiltersChange={(filters) => setTableFilters(filters)}
              userList={availableUsers}
            />
          </div>

          <hr style={{ marginTop: 28 }} />

          <div data-testid="upload-file-table-wrapper">
            <UploadFilesTableBlock
              uploadFileList={tableData}
              loading={uploadListData.status === "request"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NewnessUploadListLayout;
