import React from "react";
import "./index.less";
import { Tooltip } from "antd";
import { IReplenishmentCC } from "../../../../global/interfaces";
import EditableCell from "../../../../components/EditableCell";

interface ISelectedStyleColorBlockAdvanced {
  data: IReplenishmentCC;
  theme?: "dark" | "light";
  open?: boolean;
  onWOHChange?: (value: number) => void;
}

const SelectedStyleColorBlockAdvanced:React.FC<ISelectedStyleColorBlockAdvanced> = ({
  data,
  theme = "dark",
  open = false,
  onWOHChange,
}) => {
  const isValid = data?.valid && ((data.sendMore || data?.sendMore === 0) && data.allocatableInventory) && data.sendMore <= data.allocatableInventory;

  // Formatter to correctly display floats
  const formatter = Intl.NumberFormat("en", { notation: "standard" });

  return (
    <div className={`selected-style-color-block-advanced ${theme} ${open ? "open" : ""}`}>
      <div className="cc-info">
        <div className="left">
          <div className="top">
            <span className="bold">{data.sku ?? data.cc}</span>
            {data.gtm && <span>{data.gtm}</span> }
            {data.description && <span>{data.description}</span>}
          </div>
          <div className="bottom">
            <span>{data.division}</span>
            <span>{data.department}</span>
            {data.klass && <span>{data.klass}</span>}
          </div>
        </div>
        <div className="right">
          {onWOHChange && (
          <>
            <div className="min-woh">
              <div className="label">
                Min WOH
              </div>
              <div className="value editable">
                <EditableCell
                  cellData={data.minWOH || 0}
                  onOk={onWOHChange}
                  min={0}
                  max={99999}
                  allowDecimals={false}
                  canBeEmpty={false}
                  allowEmpty={false}
                  hasButtons={false}
                  editable
                  cancelOnBlur
                />
              </div>
            </div>
            <div className="vertical-splitter" />
          </>
          )}
          <div className="storeInv">
            <div className="label margin-0">
              Store Inv
            </div>
            <div className="value multiple">
              <div className="value-item">
                <span>OH</span>
                <span>{formatter.format(data.storeInventory?.OH || 0)}</span>
              </div>
              <div className="value-item">
                <span>IT</span>
                <span>{formatter.format(data.storeInventory?.IT || 0)}</span>
              </div>
              <div className="value-item">
                <span>OO</span>
                <span>{formatter.format(data.storeInventory?.OO || 0)}</span>
              </div>
            </div>
          </div>

          <div className="total">
            <div className="label">
              Total
            </div>
            <div className="value">
              {formatter.format(data.total || 0)}
            </div>
          </div>
          <div className="vertical-splitter" />
          <div className="sendMore">
            <div className="label">
              Send More
            </div>
            <div className={`value ${!isValid ? "flagged" : ""}`}>
              {formatter.format(data.sendMore || 0)}
            </div>
          </div>
          <div className="vertical-splitter" />
          <div className="finalInventory">
            <div className="label">
              Final Inv
            </div>
            <div className="value">
              {formatter.format(data.finalInventory || 0)}
            </div>
          </div>
          <div className="vertical-splitter" />
          <div className="targetWOH">
            <div className="label">
              Target WOH
            </div>
            <div className="value">
              {formatter.format(data.targetWOH || 0)}
            </div>
          </div>
          <div className="vertical-splitter" />
          <div className="allocatableInv">
            {
              Object.keys(data.allocatableInventoryDC || {}).length > 2 ? (
                <>
                  <div className="label">
                    Allocatable Inv
                  </div>
                  <div className="value underlined cursor-pointer">
                    <Tooltip
                      placement="bottom"
                      getTooltipContainer={() => document.body}
                      overlay={(
                        <div>
                          {Object.keys(data.allocatableInventoryDC || {}).map((key) => (
                            <div
                              key={key}
                              style={{ padding: "4px 12px" }}
                            >
                              <b>
                                {key}
                                {": "}
                                {formatter.format(data.allocatableInventoryDC?.[key]?.total || 0)}
                              </b>
                            </div>
                          ))}
                        </div>
                      )}
                    >
                      {formatter.format(data.allocatableInventory || 0)}
                    </Tooltip>
                  </div>
                </>
              ) : (
                <>
                  <div className="label margin-0">
                    Allocatable Inv
                  </div>
                  <div className="value multiple">
                    {Object.keys(data.allocatableInventoryDC || {}).map((key) => (
                      <div className="value-item">
                        <span>{key}</span>
                        <span>{formatter.format(data.allocatableInventoryDC?.[key]?.total || 0)}</span>
                      </div>
                    ))}
                  </div>
                </>
              )
            }
          </div>
          <div className="vertical-splitter" />
          <div className="remainingPercentage">
            <div className="label">
              Remaining (%)
            </div>
            <div className={`value ${!isValid ? "flagged" : ""}`}>
              {formatter.format(data.remainingInventoryPercentage || 0)}
              %
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedStyleColorBlockAdvanced;
