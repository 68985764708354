import { useEffect, useState } from "react";
import { message } from "antd";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../global/atoms";
import { regions } from "../../configs/constants";

type regionReturnType = {
  key?: keyof typeof regions | undefined;
  value?: "EMEA"| "North America" | "Japan" | "China" | string | undefined;
}

const useRegion = (): regionReturnType => {
  const user = useRecoilValue(userAtom);
  const [region, setRegion] = useState<"EMEA"| "North America" | "Japan" | "China" | string | undefined>((regions as any)[user.region]);
  const [regionKey, setRegionKey] = useState<keyof typeof regions | undefined>(user.region);

  const getRegion = async () => {
    try {
      const responseRegion: keyof typeof regions = user.region;
      setRegion(regions[responseRegion]);
      setRegionKey(responseRegion);
    } catch (err: any) {
      message.error("Error while getting region");
    }
  };

  useEffect(() => {
    getRegion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return { key: regionKey, value: region };
};

export default useRegion;
