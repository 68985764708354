import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import {
  useRecoilState, useResetRecoilState, useSetRecoilState,
} from "recoil";
import { useHistory } from "react-router-dom";
import PageTitle from "../../../../components/PageTitle";
import {
  appendNewnessSizesToList, getNewnessList, NewnessByMonth, NewnessFilters,
} from "../..";
import {
  newnessDashboardFiltersAtom,
  newnessListAtom,
  selectedNewnessAtom,
  storeTiersAtom,
} from "../../../../global/atoms";
import paths from "../../../../configs/paths";
import { allocationProcessAtom } from "../../../../global/atoms/allocation-process-atom";
import { orderTypes } from "../../../../configs/constants";

type SelectedCCs = { month: string; data: any; };

const NewnessDashboard = () => {
  const history = useHistory();
  const [newnessList, setNewnessList] = useRecoilState(newnessListAtom);
  const [selectedFilters, setSelectedFilters] = useRecoilState<any>(newnessDashboardFiltersAtom);
  const [savedSelectedCCs, setSavedSelectedCCs] = useRecoilState(selectedNewnessAtom);
  const resetSelectedNewness = useResetRecoilState(selectedNewnessAtom);
  const [allocationProcess, setAllocationProcess] = useRecoilState(allocationProcessAtom);
  const setStoreTiers = useSetRecoilState(storeTiersAtom);
  const [selectedCCs, setSelectedCCs] = useState<SelectedCCs[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [localFilters, setLocalFilters] = useState(selectedFilters || {});

  // On local filters state change, update selected filters state to filter the data correctly
  useEffect(() => {
    setSelectedFilters(localFilters);
    resetSelectedNewness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localFilters]);

  // If allocation process is ongoing, navigate to Review CC page (last step)
  useEffect(() => {
    if (allocationProcess?.status !== "request" && allocationProcess?.data?.processId && allocationProcess?.data?.processData?.length > 0) {
      history.push(paths.review_ccs);
    }
  }, [history, allocationProcess]);

  // Handler to alter selection of CCs list
  const handleChangeCCs = (selected: SelectedCCs[]) => {
    setSelectedCCs(selected);
  };

  // Handler to start loading, clear allocation process and store tiers and re-fetch those data (same as starting new ->
  // -> allocation and abandoning old one)
  const handleSubmit = async () => {
    setLoading(true);
    setAllocationProcess({});
    setStoreTiers([]);
    await appendNewnessSizesToList(
      selectedCCs || savedSelectedCCs.data, setSavedSelectedCCs, localFilters,
    );
    setLoading(false);
    history.push(paths.newness_allocation);
  };

  // Key used to force re-render dashboard newness list when filter is changed
  const reRenderKey = useMemo(() => {
    return selectedFilters.brand + selectedFilters.channel + selectedFilters.months?.length
      + selectedFilters.order_type + selectedFilters.shipping_date;
  }, [selectedFilters]);

  // reset selected CCs on re-render key change
  useEffect(() => {
    setSelectedCCs([]);
    resetSelectedNewness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reRenderKey]);

  useEffect(() => {
    // Call Service that fetches newness list according to filters selected.
    if ((selectedFilters.order_type === orderTypes.cross_dock && selectedFilters.shipping_date)
      || selectedFilters.order_type === orderTypes.regular || !selectedFilters.order_type) {
      getNewnessList(
        newnessList, setNewnessList, {
          brand: selectedFilters.brand,
          channel: selectedFilters.channel,
          months: selectedFilters.months,
          order_type: selectedFilters.order_type || orderTypes.regular,
          shipping_date: selectedFilters.order_type && selectedFilters.order_type === orderTypes.cross_dock
            ? selectedFilters.shipping_date : undefined,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reRenderKey]);

  return (
    <div className="newness-dashboard-page">
      <PageTitle
        title="newness"
        icon="Bag"
      />
      <NewnessFilters
        onFiltersUpdate={(filters) => {
          setLocalFilters(filters);
        }}
        onSubmit={handleSubmit}
        canSubmit={selectedCCs.length > 0}
        isLoading={loading || newnessList?.status === "request"}
        isNewProcess={savedSelectedCCs?.data?.length > 0}
      />
      <hr />
      <NewnessByMonth
        filterData={localFilters}
        onMonthsSelect={handleChangeCCs}
      />
    </div>
  );
};

export default NewnessDashboard;
