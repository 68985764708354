import React, { useEffect } from "react";
import "./index.less";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Icon, { IconType } from "../Icon";

interface IPageTitle {
  title: string;
  tabTitle?: string;
  icon?: IconType | JSX.Element;
  compactView?: boolean;
  uppercase?: boolean;
  leftNode?: JSX.Element;
  alwaysShowLeftNode?: boolean;
  rightNode?: string | JSX.Element;
  onBack?: () => void;
}

const PageTitle: React.FC<IPageTitle> = ({
  title,
  tabTitle,
  icon,
  compactView = false,
  uppercase = true,
  leftNode,
  alwaysShowLeftNode = false,
  rightNode,
  onBack,
}) => {
  useEffect(() => {
    document.title = `DART - ${(tabTitle || title).toUpperCase()}` || "DART";
  }, [tabTitle, title]);

  return (
    <div className={`dart-page-title ${uppercase ? "upper" : ""} ${compactView ? "compact" : ""}`}>
      {onBack && (
        <div className="left">
          <Button
            className="back-button"
            onClick={onBack}
            type="ghost"
            shape="circle"
          >
            <ArrowLeftOutlined style={{ fontSize: 16 }} />
          </Button>
        </div>
      )}
      {compactView || alwaysShowLeftNode ? (
        <div className="left">{leftNode}</div>
      ) : ""}
      {!compactView && (
        <>
          <div className="left" />
          <div className="center">
            {typeof icon === "string" ? (
              <Icon
                name={icon as IconType}
                size={20}
              />
            ) : icon}
            {" "}
            {title}
          </div>
        </>
      )}
      {rightNode && (
        <div className="right">
          {compactView && (
            <>
              {typeof icon === "string" ? (
                <Icon
                  name={icon as IconType}
                  size={20}
                />
              ) : icon }
              {` ${title} | `}
            </>
          )}
          {rightNode}
        </div>
      )}
    </div>
  );
};

export default PageTitle;
