import { selector } from "recoil";
import { userAtom } from "../atoms";

export const userSelector = selector({
  key: "user-selector",
  get: ({ get }) => {
    const atom = get(userAtom);
    return atom;
  },
});
