const renderTag = (str?: string) => {
  if (str) {
    if (str.startsWith("#")) {
      return str;
    }
    return `#${str}`;
  }
  return str;
};

export default renderTag;
