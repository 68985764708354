import { message } from "antd";
import apiClient from "../../../api/apiClient";
import endpoints from "../../../api/endpoints";
import { IStoreProfile } from "../../../global/interfaces";
import { showErrorMessage } from "../../../utils/helpers";

export const getAllStores = async (
  storeList: any, setStoreList: any, storeListFilters: any, mutatedStoreFields?: IStoreProfile, cb?: any, extraIdFilter?: any[],
) => {
  try {
    const storeListOld = [...storeList.data];
    if (mutatedStoreFields) {
      for (let s = 0; s < storeListOld.length; s += 1) {
        if (storeListOld[s].id === mutatedStoreFields?.id) {
          storeListOld[s] = { ...storeListOld[s], ...mutatedStoreFields };
        }
      }
    }
    if (storeList.data.length === 0) {
      setStoreList({ status: "request", data: storeListOld });
    } else {
      setStoreList({ status: extraIdFilter ? "request" : "revalidate", data: storeListOld });
    }

    let paramQuery = "?";

    // loop through storeListFilters keys and values array and add them to paramQuery. exclude status because filtering is done in FE
    Object.keys(storeListFilters).forEach((key) => {
      if (storeListFilters[key].length > 0) {
        for (let o = 0; o < storeListFilters[key].length; o += 1) {
          if (key !== "status") {
            if (typeof storeListFilters[key][o] === "string") {
              paramQuery += `${key}=${storeListFilters[key][o]}&`;
            } else {
              paramQuery += `${key}=${storeListFilters[key][o].value}&`;
            }
          }
        }
      }
    });

    // remove trailing "&" from paramQuery
    if (paramQuery[paramQuery.length - 1] === "&") {
      paramQuery = paramQuery.slice(0, -1);
    }

    const res:any = await apiClient.get(endpoints.store + paramQuery);

    const returnData = extraIdFilter
      ? res.data?.stores.filter((store: any) => {
        return extraIdFilter.includes(store.id?.toString())
          || extraIdFilter.includes(store.number?.toString());
      })
      : res.data?.stores;

    setStoreList({ status: "success", data: returnData || [] });
    cb?.({ status: "success", data: returnData || [] });
    return returnData || [];
  } catch (error: any) {
    setStoreList({ status: "failed", data: [] });
    showErrorMessage(error, "Failed to get store list");
    return [];
  }
};

export const createStore = async (
  isCopy:boolean, storeCreateData: any, setStoreCreateData: any, fields: IStoreProfile, storeList?: any, setStoreList?: any, storeListFilters?: any,
) => {
  try {
    setStoreCreateData({ status: "request", data: storeCreateData.data });
    let res: any;
    if (isCopy) {
      const dataToSend = { ...fields };
      delete dataToSend.departments;
      delete dataToSend.tags;
      delete dataToSend.sister_store_id;
      res = await apiClient.post(`${endpoints.store}/${fields.sister_store_id}/copy`, dataToSend);
    } else {
      const dataToSend = { ...fields };
      delete dataToSend.sister_store_id;
      res = await apiClient.post(endpoints.store, dataToSend);
    }
    setStoreCreateData({ status: "success", data: res.data });
    message.success(`Store created -${fields.number ? ` ${fields.number} ` : " "}${fields.name}`);
    if (storeList && setStoreList && storeListFilters) {
      await getAllStores(
        storeList, setStoreList, storeListFilters,
      );
    }
  } catch (error: any) {
    setStoreCreateData({ status: "failed", data: {} });
    showErrorMessage(error, "Failed to create store");
  }
};

export const updateStore = async (
  fields: IStoreProfile, storeList?: any, setStoreList?: any, storeListFilters?: any,
) => {
  try {
    const dataToSend = { ...fields };
    if (dataToSend?.sister_store_id !== null) {
      delete dataToSend?.sister_store_id;
    }
    delete dataToSend.id;
    await apiClient.patch(`${endpoints.store}/${fields.id}`, dataToSend);
    message.success(`Store updated -${fields.number ? ` ${fields.number} ` : " "}${fields.name}`);
    if (storeList && setStoreList && storeListFilters) {
      await getAllStores(
        storeList, setStoreList, storeListFilters, fields,
      );
    }
  } catch (error: any) {
    showErrorMessage(error, "Failed to update store");
  }
};

export const deleteStore = async (
  store: IStoreProfile, storeList?: any, setStoreList?: any, storeListFilters?: any,
) => {
  try {
    await apiClient.delete(`${endpoints.store}/${store.id}`);
    message.success(`Store deleted -${store.number ? ` ${store.number} ` : " "}${store.name}`);
    if (storeList && setStoreList && storeListFilters) {
      await getAllStores(
        storeList, setStoreList, storeListFilters,
      );
    }
  } catch (error: any) {
    showErrorMessage(error, "Failed to delete store");
  }
};

export const getStoreDepartmentRelationships = async (departmentRelationships: any, setDepartmentRelationships: any) => {
  try {
    setDepartmentRelationships({ status: "request", data: departmentRelationships.data || [] });
    const res = await apiClient.get(endpoints.storeDepartmentRelationships);
    setDepartmentRelationships({ status: "success", data: res.data || [] });
  } catch (error: any) {
    setDepartmentRelationships({ status: "failed", data: [] });
    showErrorMessage(error, "Failed to get store department relationships");
  }
};

export const updateStoreDepartmentRelationships = async (
  departmentRelationships: any, warehouseId: { label: string, value: string }[], setLoadingState: any,
) => {
  try {
    setLoadingState(true);
    await apiClient.patch(endpoints.storeDepartmentRelationships, {
      store_dept_rel_ids: departmentRelationships.map((rel: any) => rel.id),
      warehouse_rels: warehouseId?.map((e, index) => { return { warehouse_id: e.value, priority: index }; }),
    });
    setLoadingState(false);
    message.success("Successfully Assigned");
    return true;
  } catch (error: any) {
    setLoadingState(false);
    showErrorMessage(error, "Failed to update store department relationships");
    return false;
  }
};
