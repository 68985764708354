/* eslint-disable no-useless-escape */
import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import { Input, Tooltip } from "antd";
import Icon from "../Icon";
import LineImage from "../../assets/images/lines.png";

interface IEditableCell {
  editable?: boolean;
  cellData?: any;
  onOk?: (value: any) => void;
  defaultEditMode?: boolean;
  allowSameValueSubmit?: boolean;
  hasButtons?: boolean;
  viewModeTooltip?: string;
  valueSuffix?: string;
  min?: number;
  max?: number;
  cancelOnBlur?: boolean;
  allowDecimals?: boolean;
  emptyText?: string;
  allowEmpty?: boolean;
  canBeEmpty?: boolean;
}

const EditableCell:React.FC<IEditableCell> = ({
  editable = true,
  cellData,
  onOk,
  defaultEditMode = false,
  allowSameValueSubmit = false,
  hasButtons = true,
  viewModeTooltip,
  valueSuffix = "",
  min,
  max,
  cancelOnBlur = false,
  allowDecimals = true,
  emptyText,
  allowEmpty = false,
  canBeEmpty = true,
}) => {
  const [editing, setEditing] = useState(defaultEditMode);
  const [initValue, setInitValue] = useState(cellData);
  const [value, setValue] = useState(cellData);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setInitValue(cellData);
    setValue(cellData);
  }, [cellData]);

  const onClick = () => {
    if (editable) {
      setEditing(true);
    }
  };

  const handleOk = (val: any) => {
    if (validValue) {
      setEditing(false);
      setValue(val);
      if (val?.toString() !== initValue?.toString() || allowSameValueSubmit) {
        onOk?.(val);
      }
    }
  };

  const handleKey = (k: any) => {
    if (validValue && (k.keyCode === 13 || k.code === "Tab" || k.code === "Space")) {
      k.preventDefault();
      k.stopPropagation();
      handleOk(value);
      setEditing(false);
    }
    if (k.code === "Escape") {
      handleCancel();
      setEditing(false);
    }
  };

  const validValue = useMemo(() => {
    if (allowEmpty && (value === "" || value === undefined || value === null)) {
      setHasError(false);
      return true;
    }
    if (!allowEmpty && (value === "" || value === undefined || value === null)) {
      setHasError(true);
      return false;
    }
    if ((min && +value < min) || (max && +value > max)) {
      setHasError(true);
      return false;
    }
    if (allowDecimals && /^\d{0,9}(?:\.\d{0,2})?$/g.test(value)) {
      setHasError(false);
      return true;
    }
    if (allowDecimals && !/^\d{0,9}(?:\.\d{0,2})?$/g.test(value)) {
      setHasError(true);
      return false;
    }
    if (!/^\d+$/g.test(value)) {
      setHasError(true);
      return false;
    }
    if (value !== "" && value?.toString()?.[0] !== "-" && /^\d{0,9}(?:\.\d{0,2})?$/g.test(value)) {
      setHasError(false);
      return true;
    }
    setHasError(true);
    return false;
  }, [allowEmpty, allowDecimals, value, min, max]);

  const handleCancel = () => {
    setEditing(false);
    setValue(initValue);
  };

  const emptyOrValue = useMemo(() => {
    const valueElement = (
      <>
        {viewModeTooltip ? (
          <Tooltip
            overlay={viewModeTooltip}
            placement="right"
            destroyTooltipOnHide
          >
            {(emptyText || value) + valueSuffix}
          </Tooltip>
        ) : (emptyText || value) + valueSuffix}
      </>
    );

    const emptyElement = (
      <img
        src={LineImage}
        width={136}
        height={50}
        alt="N/A"
      />
    );
    if (!canBeEmpty) {
      return valueElement;
    }
    return value === "" || value === null || value === undefined ? emptyElement : valueElement;
  }, [emptyText, canBeEmpty, value, valueSuffix, viewModeTooltip]);

  return (
    <div className={`dart-editable-cell ${hasError ? "has-error" : ""}`}>
      {editing ? (
        <div className="edit-mode-wrapper">
          <Input
            autoFocus
            defaultValue={value || initValue}
            type="number"
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={handleKey}
            onBlur={() => (cancelOnBlur && ((hasButtons && initValue === value) || !hasButtons) ? handleCancel() : false)}
          />
          {hasButtons && (
          <>
            <div
              className="icon-wrap submit-wrap"
              onClick={() => handleOk(value)}
            >
              <Icon
                className="dart-editable-cell-icon submit"
                name="Check"
              />
            </div>
            <div
              className="icon-wrap cancel-wrap"
              onClick={handleCancel}
            >
              <Icon
                className="dart-editable-cell-icon cancel"
                name="Close"
              />
            </div>
          </>
          )}
        </div>
      ) : (
        <div
          className="view-mode-wrapper"
          {...(!hasButtons ? { onClick } : {})}
        >
          {emptyOrValue}
          {editable && hasButtons ? (
            <Icon
              onClick={onClick}
              name="Edit"
              className="dart-editable-cell-hover-icon"
            />
          ) : <></>}
        </div>
      )}
    </div>
  );
};

export default EditableCell;
