/* eslint-disable no-param-reassign */
import { selector } from "recoil";
import { poaListAtom } from "../atoms";

export const poaListSelector = selector({
  key: "poa-list-selector",
  get: ({ get }) => {
    const atomData = get(poaListAtom);

    if (atomData.status === "success") {
      // get parent keys: such as "OTHER" (null), "WOMENS", "MENS", "KIDS", "HOME_CARE"
      const sortedParentKeys = Object.keys(atomData?.data)
        .sort()
        .reverse();

      // Move "OTHER" on top
      [...sortedParentKeys].map((key: string, index: number) => {
        if (key === "OTHER") {
          sortedParentKeys.splice(index, 1);
          sortedParentKeys.unshift("OTHER");
        }
        return key;
      });

      const sortedData: any = [];

      // loop through sorted parent keys and get child keys: such as "FOOTWEAR", "APPAREL", "ACCESSORIES"
      sortedParentKeys.map((key: string) => {
        const keyOrOther = key === "null" || !key ? "OTHER" : key; // Support custom categories
        const sortedChildKeys = Object.keys(atomData?.data?.[key])
          .sort()
          .reverse();

        const sortedChildData: any = [];

        // loop through sorted child keys and assign sorted data to sorted parent as child data
        sortedChildKeys.map((childKey: string) => {
          const childKeyOrCustom = childKey === "null" || !childKey ? "CUSTOM" : childKey; // Support custom categories
          sortedChildData.push({
            key: childKeyOrCustom,
            data: (atomData?.data?.[key]?.[childKey]),
          });
          return childKey;
        });

        // assign sorted child data to sorted parent data
        sortedData.push({ key: keyOrOther, data: sortedChildData });

        return key;
      });

      // return modified object of size run list
      return ({
        status: atomData?.status, data: sortedData, channelStoreList: atomData.channelStoreList,
      });
    }
    return atomData;
  },
});

// DATA EXAMPLE:
/*
{
  "status": "success",
  "data": [
    {
      "key": "WOMEN'S",
      "data": [
        {
          "key": "FOOTWEAR",
          "data": [
            {
              "division": "WOMEN'S",
              "name": "Smth",
              "department": "FOOTWEAR",
              "id": 47
            },
          ]
        },
        ...
       ],
     },
  ]
}
*/
