import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import "./index.less";
import {
  useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState,
} from "recoil";
import {
  Button, message, Spin,
} from "antd";
import { useHistory } from "react-router-dom";
import columns from "./columns";
import {
  poaChannelAtom,
  poaEditingAtom,
  poaExpandedListAtom,
  poaListAtom,
  poaSelectedListAtom,
  poaValuesAtom,
  selectedStoreAtom,
  storeListAtom,
} from "../../../../../global/atoms";
import DartPrompt from "../../../../../components/DartPrompt";
import { updateStore } from "../../../../store";
import decideDivisionName from "../../../../../utils/helpers/decideDivisionName";
import paths from "../../../../../configs/paths";
import {
  createCustomCategoryPOA, getGroupedPOAs, getPOAList, updateCustomCategoryPOA,
} from "../../services/poa";
import { poaListSelector } from "../../../../../global/selectors/poa-list-selector";
import { poaValuesSelector } from "../../../../../global/selectors/poa-values-selector";
import LineImage from "../../../../../assets/images/lines.png";
import EditableCell from "../../../../../components/EditableCell";
import { IPoaEntry, IPoaValue } from "../../../../../global/interfaces";
import {
  CustomCategoryPOA, GroupedPoaCollapse, renderPOATableHeader,
} from "../..";
import DartTable from "../../../../../components/DartTable";
import { faker, mergeObjectIntoArray } from "../../../../../utils/helpers";
import { storeListFiltersAtom } from "../../../../../global/atoms/store-list-filters-atom";
import TableDropdownMenuComponent from "../../../../../components/TableDropdownMenuComponent";

interface IPoaOverview {
  editable?: boolean;
  setActionDisabled?: (disabled?: boolean) => void;
  varianceAllowed?: string;
}

const PoaOverview: React.FC<IPoaOverview> = ({
  editable, setActionDisabled, varianceAllowed,
}) => {
  const history = useHistory();
  const setPoaList = useSetRecoilState<any>(poaListAtom);
  const poaList = useRecoilValue<any>(poaListSelector);
  const setPoaValues = useSetRecoilState<any>(poaValuesAtom);
  const poaValues = useRecoilValue<any>(poaValuesSelector);
  const [expandedList, setExpandedList] = useRecoilState<string[]>(poaExpandedListAtom);
  const [checkedList, setCheckedList] = useRecoilState<string[]>(poaSelectedListAtom);
  const setPoaEditing = useSetRecoilState<{poa: any, poaValues: any}>(poaEditingAtom);
  const [sisterStoreDeleteId, setSisterStoreDeleteId] = useState<number | undefined>(undefined);
  const setSelectedStore = useSetRecoilState<any>(selectedStoreAtom);
  const [storeList, setStoreList] = useRecoilState<any>(storeListAtom);
  const [deleteSSLoading, setDeleteSSLoading] = useState(false);
  const poaChannel = useRecoilValue(poaChannelAtom);
  const [shouldSeeClasses, setShouldSeeClasses] = useState(true);
  const [shouldSeeStyles, setShouldSeeStyles] = useState(true);
  const [shouldSeeCustom, setShouldSeeCustom] = useState(true);
  const resetStoreFilters = useResetRecoilState(storeListFiltersAtom);
  const [styleToRemove, setStyleToRemove] = useState<any | undefined>(undefined);
  const [styleModalOpen, setStyleModalOpen] = useState(false);
  const [styleToEdit, setStyleToEdit] = useState<any | undefined>(undefined);

  const [updatesDone, setUpdatesDone] = useState(0);

  useEffect(() => {
    getPOAList(
      poaList, setPoaList, poaChannel,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poaChannel, updatesDone]);

  useEffect(() => {
    setExpandedList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poaChannel, updatesDone]);

  const handleExpand = (department: string, division: string) => {
    if (expandedList.includes(`${department}_${division}`)) {
      setExpandedList(expandedList.filter((item) => item !== `${department}_${division}`));
    } else {
      getGroupedPOAs(
        poaValues, setPoaValues, { department, division }, poaChannel,
      );
      setExpandedList([...expandedList, `${department}_${division}`]);
    }
  };

  const handleCheck = (
    department: string, division: string, poaDataEntries?: IPoaEntry[],
  ) => {
    if (poaDataEntries) {
      setActionDisabled?.(false);
    }

    if (checkedList.includes(`${department}_${division}`)) {
      setCheckedList((prev) => prev.filter((item) => item !== `${department}_${division}`));
    } else {
      getGroupedPOAs(
        poaValues, setPoaValues, { department, division }, poaChannel,
      );
      setCheckedList((prev) => [...prev, `${department}_${division}`]);
    }
  };

  const getColumns = useMemo(() => {
    return columns({
      handleEditStoreClick: (storeId: any) => {
        resetStoreFilters();
        setSelectedStore(storeId);
        history.push(`/stores/${storeId}/edit`);
      },
      handleRemoveSisterStoreClick: (storeId: any) => setSisterStoreDeleteId(storeId),
      editable,
      channelStoreList: poaList.channelStoreList,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varianceAllowed, poaList.channelStoreList]);

  const handleRemovePOAStyle = (style: any) => {
    message.success(`Style '${style?.name}' successfully removed`);
    setStyleToRemove(undefined);
  };

  const appendDynamicColumns = (cols: any, poaDynamicColumns: IPoaEntry[]) => {
    const newColumns = [...cols];
    const columnsToAdd = [
      ...(shouldSeeClasses ? poaDynamicColumns?.filter((e) => e.is_class && !e.is_custom) : []),
      ...(shouldSeeStyles ? poaDynamicColumns?.filter((e) => !e.is_class && !e.is_custom) : []),
      ...(shouldSeeCustom ? poaDynamicColumns?.filter((e) => e.is_custom) : []),
    ];

    for (let i = 0; i < columnsToAdd?.length; i += 1) {
      const columnName = columnsToAdd?.[i].name;
      const columnId = +columnsToAdd?.[i].id;
      const isClassColumn = columnsToAdd?.[i].is_class && !columnsToAdd?.[i].is_custom;
      const isStyleColumn = !columnsToAdd?.[i].is_class && !columnsToAdd?.[i].is_custom;
      const isCustomColumn = columnsToAdd?.[i].is_custom;

      const menu = !isCustomColumn ? undefined : (
        <div className="column-header-menu">
          <TableDropdownMenuComponent
            functions={{
              handleModifyPOAStyle: () => {
                setStyleToEdit(columnsToAdd?.[i]);
                setStyleModalOpen(true);
              },
              // handleRemovePOAStyle: () => setStyleToRemove(columnsToAdd?.[i]), // @TODO: temporarily disabled (coming soon)
            }}
            rowData={{
              style_id: columnId,
            }}
            dotsColor="#ffffff"
          />
        </div>
      );
      newColumns.push({
        key: `${columnId.toString()}_${columnName}`,
        dataIndex: columnName,
        title: !isCustomColumn ? columnName : (
          <div>
            {columnName.length > 20 ? `${columnName.substring(0, 20)}...` : columnName}
            {" "}
            {menu}
          </div>
        ),
        width: 136,
        flexGrow: 1,
        align: "center",
        // eslint-disable-next-line max-len
        className: `poa-editable-cell ${!editable ? "disable-editing" : ""} ${isClassColumn ? "class" : ""} ${isCustomColumn ? "custom" : ""}  ${isStyleColumn ? "style" : ""}`,
        cellRenderer: ({ rowData }: { rowData: any }) => {
          const currentField = rowData?.poa_values.find((s: IPoaValue) => (s.poa_category_id === columnId));

          const displayValueOrEmpty = () => {
            if (!currentField) {
              return (
                <div className="poa-overview-table-cell-empty">
                  <img
                    src={LineImage}
                    width={136}
                    height={50}
                    alt="N/A"
                  />
                </div>
              );
            }
            return `${currentField?.updated || currentField?.poa_qty}`;
          };

          const isFlagged = currentField?.poa_qty && Math.abs(((currentField?.updated / currentField?.poa_qty) * 100) - 100)
            > parseInt(varianceAllowed || "0%".replace("%", ""), 10);

          const isUpdated = Number(currentField?.updated).toFixed(2) !== Number(currentField?.poa_qty).toFixed(2);

          const updateValue = (v: string) => {
            const key = `${poaDynamicColumns?.[0]?.department}_${poaDynamicColumns?.[0]?.division}`;
            setActionDisabled?.(false);

            setPoaValues((prev: any) => {
              // Deep clone the state. Modify matched poa value for store and poa category and keep it saved in local storage
              const cloneState = JSON.parse(JSON.stringify(prev));
              const poaValuesState = cloneState[key];
              const clonedPoaValues = poaValuesState?.data?.[rowData?.store_id];
              let matchedPoaValueObj = clonedPoaValues?.poa_values?.find((pVal: any) => pVal.poa_category_id === columnId);
              poaValuesState.touched = true; // for prompt

              // Creating new entry
              if (!clonedPoaValues) {
                poaValuesState.data[rowData?.store_id] = rowData;
                matchedPoaValueObj = rowData?.poa_values?.find((pVal: any) => pVal.poa_category_id === columnId);
              }

              if (!matchedPoaValueObj) {
                poaValuesState.data[rowData?.store_id]?.poa_values?.push({
                  id: null,
                  poa_category_id: columnId,
                  poa_qty: "",
                  updated: v,
                });
              } else {
                matchedPoaValueObj.updated = v;
              }

              return cloneState;
            });
            if (!checkedList.includes(key)) {
              setCheckedList([...checkedList, key]);
            }
          };

          return (
            <div className={`poa-overview-table-cell ${isFlagged ? "flagged" : ""} ${isUpdated ? "updated" : ""}`}>
              {editable ? (
                <EditableCell
                  editable={editable}
                  cellData={isUpdated ? currentField?.updated : currentField?.poa_qty}
                  hasButtons={false}
                  allowSameValueSubmit
                  onOk={updateValue}
                  viewModeTooltip={isUpdated ? `Initial Value: ${currentField?.poa_qty || "Empty"}` : undefined}
                  min={0}
                  max={1000000}
                  cancelOnBlur
                  allowEmpty={!currentField?.poa_qty}
                />
              )
                : displayValueOrEmpty()}
            </div>
          );
        },
      });
    }

    return newColumns;
  };

  const handleRemoveSisterStore = async () => {
    setDeleteSSLoading(true);
    await updateStore(
      { id: sisterStoreDeleteId, sister_store_id: null }, storeList, setStoreList,
    );
    setDeleteSSLoading(false);
    setSisterStoreDeleteId(undefined);
  };

  const handleMaximizePOA = async (poa: IPoaEntry[], existingPoaValues: IPoaValue[]) => {
    if (!existingPoaValues) {
      await getGroupedPOAs(
        poaValues, setPoaValues, { department: poa?.[0].department, division: poa?.[0].division }, poaChannel,
      );
    }
    navigateToMaximizedScreen(poa, poaValues?.[`${poa?.[0].department}_${poa?.[0].division}`]);
  };

  const navigateToMaximizedScreen = useCallback((poa: any, pValues: any) => {
    const mergedValues = mergeObjectIntoArray(
      poaList?.channelStoreList,
      pValues?.data,
      "id",
      "store",
      "store_id",
      { poa_key: `${poa?.[0].department}_${poa?.[0].division}`, poa_values: [] },
    );
    setPoaEditing({ poa, poaValues: { status: pValues?.status, data: mergedValues } });
    history.push(paths.poa_edit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poaValues]);

  const clearPoaValuesEditingFromList = (poaKey: string) => {
    setPoaValues((prev: any) => {
      const updatedPoas = JSON.parse(JSON.stringify(prev));
      delete updatedPoas[poaKey];
      return updatedPoas;
    });
  };

  const handleSubmitStyle = async (styleValues?: any, isAdd?: boolean) => {
    const poaKey = `${styleToEdit?.department}_${styleToEdit?.division}`;
    // Call function depending on isAdd parameter (add or update)
    await (isAdd ? createCustomCategoryPOA : updateCustomCategoryPOA)(styleValues, [
      () => clearPoaValuesEditingFromList(poaKey),
      () => {
        setUpdatesDone((prev) => prev + 1);
        setStyleToEdit(undefined);
        setExpandedList([]);
      },
    ]);
  };

  return (
    <div
      className="poa-overview-widget"
      key={varianceAllowed + Object.keys(poaValues || {}).length?.toString()}
    >
      <DartPrompt
        title="Unassign Sister Store?"
        content={(
          <div>This store will no longer receive new POA updates from it’s sister store.</div>
        )}
        okText="Unassign Sister Store"
        cancelText="Cancel"
        visible={!!sisterStoreDeleteId}
        okButtonProps={{ loading: storeList.status === "request" || deleteSSLoading }}
        onCancel={() => setSisterStoreDeleteId(undefined)}
        onOk={() => handleRemoveSisterStore()}
      />
      <DartPrompt
        title={`Remove '${styleToRemove?.name}'`}
        content={(
          <div>
            This style will be permanently removed from
            <br />
            the database
            <hr className="splitter" />
            <div className="list">
              {
                [{
                  id: faker("number", 1000000),
                  name: faker("string", 30)?.toUpperCase(),
                }, {
                  id: faker("number", 1000000),
                  name: faker("string", 20)?.toUpperCase(),
                }, {
                  id: faker("number", 1000000),
                  name: faker("string", 15)?.toUpperCase(),
                }, {
                  id: faker("number", 1000000),
                  name: faker("string", 25)?.toUpperCase(),
                }]?.map((style: any, p) => {
                  return (
                    <p key={p}>
                      {style?.id}
                      {" • "}
                      {style?.name}
                    </p>
                  );
                })
              }
            </div>
          </div>
        )}
        okText="Remove"
        cancelText="Cancel"
        visible={!!styleToRemove}
        okButtonProps={{ loading: false }}
        onCancel={() => setStyleToRemove(undefined)}
        onOk={() => handleRemovePOAStyle(styleToRemove)}
        icon="Delete"
      />
      <CustomCategoryPOA
        open={styleModalOpen}
        setOpen={setStyleModalOpen}
        selectedStyle={styleToEdit}
        setSelectedStyle={setStyleToEdit}
        onOK={handleSubmitStyle}
      />
      {poaList?.status === "request"
        ? (
          <Spin
            size="large"
            style={{
              position: "absolute", left: 0, right: 0, marginTop: 64,
            }}
          />
        )
        : (poaList?.data || [])?.map((poaDivision: any, i: number) => {
          const poaListData = poaDivision?.data || [];
          const division = poaDivision?.key;

          // Check if any is selected, if yes, display "deselect all" button
          const anyFromListIsChecked = (poaListData || [])?.some((poa: any) => {
            const keyToTest = `${poa?.key}_${division}`;
            return checkedList.includes(keyToTest);
          });

          // Function that handles select all / deselect all in group
          const handleGroupCheck = (checked: boolean) => {
            const groupToAffect: any = [];
            for (let k = 0; k < poaListData.length; k += 1) {
              const department = poaListData[k]?.key;
              const keyToToggle = `${department}_${division}`;
              const isAlreadySelected = checkedList.includes(keyToToggle);

              if ((checked && !isAlreadySelected)) {
                groupToAffect.push({
                  key: keyToToggle, department, division,
                });
              } else if ((!checked && isAlreadySelected)) {
                groupToAffect.push({
                  key: keyToToggle, department, division,
                });
              }
            }

            // select or unselect from list
            for (let k = 0; k < groupToAffect?.length; k += 1) {
              const current = groupToAffect[k];
              handleCheck(
                current?.department, current?.division, [],
              );
            }
          };

          return (
            <div
              key={i.toString()}
              className="poa-overview-single"
            >
              <div className="top">
                <div className="left">
                  <div className="title">
                    {decideDivisionName(division)}
                  </div>
                  {editable && (
                  <div className="toggle-all">
                    <Button onClick={() => handleGroupCheck(!anyFromListIsChecked)}>
                      {anyFromListIsChecked ? "Deselect All" : "Select All"}
                    </Button>
                  </div>
                  )}
                </div>
              </div>
              <div className="bottom">
                {(poaListData || [])?.length > 0
                  ? ((poaListData || []) as any)?.map((poaListItem: { key: string, data: IPoaEntry[] }, o: number) => {
                    const poaParentKey = poaListItem.key;
                    const poaDataValues = poaListItem?.data || [];
                    const classCount = poaDataValues?.filter((p) => p.is_class && !p.is_custom).length;
                    const styleCount = poaDataValues?.filter((p) => !p.is_class && !p.is_custom).length;
                    const customCount = poaDataValues?.filter((p) => p.is_custom).length;

                    const fetchedData = poaValues[`${poaParentKey}_${division}`] || {};
                    const existingFetchedData: any = (Object.values(fetchedData?.data || {}) || []);

                    // Used in Table (this is store list + poa values intercepted with each other
                    const tableValues = mergeObjectIntoArray(
                      poaList?.channelStoreList,
                      fetchedData?.data,
                      "id",
                      "store",
                      "store_id",
                      { poa_key: `${poaParentKey}_${division}`, poa_values: [] },
                    );

                    // Sort tableValues by store name
                    const sortedTableValues = (Object.values(tableValues || {}) || [])
                      ?.sort((a: any, b: any) => {
                        if (a.store?.name > b.store?.name) return 1;
                        if (b.store?.name > a.store?.name) return -1;
                        return 0;
                      });

                    return (
                      <GroupedPoaCollapse
                        key={`${o}_${poaParentKey}_${division}`}
                        loading={fetchedData?.status === "request"}
                        disabled={poaList?.status === "request" || poaList?.status === "revalidate"}
                        poaEntry={{
                          ...poaDataValues?.[0], classCount, styleCount, customCount,
                        }}
                        {...(editable ? { onMaximizeClick: () => handleMaximizePOA(poaDataValues, existingFetchedData) } : {})}
                        expanded={
                            expandedList.indexOf(`${poaParentKey}_${division}`) > -1
                          }
                        onExpandToggle={() => handleExpand(poaParentKey, division)}
                        {...(editable
                          ? {
                            onCheckboxToggle: () => handleCheck(
                              poaParentKey, division, poaDataValues,
                            ),
                            checked: checkedList.indexOf(`${poaParentKey}_${division}`) > -1,
                          }
                          : {}
                          )}
                        expandedContent={fetchedData?.status !== "request" ? (
                          <DartTable
                            tableStyle="dark"
                            rowKey="store_id"
                            width="100%"
                            data={sortedTableValues}
                            height={534}
                            checkboxSize="sm"
                            columns={appendDynamicColumns(getColumns, poaDataValues)}
                            fixed
                            emptyRenderer={() => <div style={{ padding: "48px calc(50% - 64px)" }}>POA values are empty</div>}
                            headerHeight={[34, 34]}
                            headerRenderer={(p: any) => renderPOATableHeader(p, {
                              shouldSeeClasses,
                              shouldSeeStyles,
                              shouldSeeCustom,
                              setShouldSeeClasses,
                              setShouldSeeStyles,
                              setShouldSeeCustom,
                            })}
                          />
                        ) : (
                          <Spin
                            size="default"
                            style={{
                              position: "absolute", left: 0, right: 0, marginTop: 64,
                            }}
                          />
                        )}
                      />
                    );
                  }) : <></>}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default PoaOverview;
