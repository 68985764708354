import { message } from "antd";
import moment from "moment";
import apiClient, { apiClientWithoutRetry } from "../../../api/apiClient";
import endpoints from "../../../api/endpoints";
import { showErrorMessage } from "../../../utils/helpers";
import { orderTypes } from "../../../configs/constants";

interface allocationStoreData {
  [key: string]: string[]
}

const startAllocationProcesses = async (
  allocationProcesses: any, setAllocationProcesses: any, allocationStoreData: allocationStoreData, filters?: any,
) => {
  const shippingDate = typeof filters.shipping_date === typeof "string"
    ? moment(filters.shipping_date)?.format("YYYY-MM-DD")
    : filters.shipping_date?.format("YYYY-MM-DD");
  try {
    setAllocationProcesses({
      status: "request",
      data: allocationProcesses.data || {},
    });
    const dataToSend = (Object.keys(allocationStoreData)
      .map((key) => {
        return { [key]: allocationStoreData[key] };
      }));
    const dataObj: any = {};
    dataToSend.forEach((item: any) => {
      const key = Object.keys(item)[0];
      dataObj[key] = item[key];
    });
    const res:any = await apiClientWithoutRetry.post(endpoints.allocationProcess,
      {
        data: dataObj,
        channel: filters?.channel,
        country: filters?.country,
        brand: filters?.brand,
        ...(filters?.order_type === orderTypes.cross_dock ? { shipping_date: shippingDate } : {}),
      });
    setAllocationProcesses({
      status: "request",
      data: {
        processId: res.data?.process_ids || undefined,
        processData: [],
        processStatus: "in_progress",
        orderType: filters?.order_type,
        shippingDate,
      },
    });
    message.success("Allocation process started");

    await getMultipleAllocationProcesses(
      {
        status: "request",
        data: {
          processId: res.data?.process_ids || undefined,
          processData: [],
          processStatus: "in_progress",
          orderType: filters?.order_type,
          shippingDate,
        },
      }, setAllocationProcesses, res.data?.process_ids || undefined, "cc",
    );
  } catch (error: any) {
    setAllocationProcesses({
      status: "failed",
      data: allocationProcesses.data || {},
    });
    showErrorMessage(error, "Failed to initiate allocation process");
  }
};

const getAllocationProcess = async (
  allocationProcess: any, setAllocationProcess: any, processId: number, groupBy: "store" | "cc" = "cc", fromCreate = false, revalidating = false,
) => {
  try {
    setAllocationProcess({ status: revalidating ? "revalidate" : "request", data: allocationProcess.data || {} });
    if (!fromCreate || (allocationProcess?.status === "request" && fromCreate)) {
      const res:any = await apiClient.get(`${endpoints.allocationData}/${processId}?group_by=${groupBy}`);
      setAllocationProcess({
        status: "success",
        data:
          {
            processId: processId || undefined,
            processData: res.data.map((item: any) => {
              return {
                ...item,
                nested: item.nested?.map((nestedItem: any) => {
                  return {
                    ...nestedItem,
                    process_id: processId,
                  };
                }),
              };
            }) || undefined,
            processStatus: allocationProcess?.data?.processStatus === "finished" ? "finished" : "in_progress",
            orderType: allocationProcess?.data?.orderType,
            shippingDate: allocationProcess?.data?.shippingDate,
          },
      });
    }
  } catch (error: any) {
    setAllocationProcess({ status: "failed", data: allocationProcess.data || {} });
    showErrorMessage(error, "Failed to get ongoing allocation process");
  }
};

const getMultipleAllocationProcesses = async (
  allocationProcesses: any, setAllocationProcesses: any, processIds: number[], groupBy: "store" | "cc" = "cc", revalidating = false,
) => {
  try {
    setAllocationProcesses({
      status: revalidating ? "revalidate" : "request",
      data: allocationProcesses.data || {},
    });
    const dataObj: any = [];
    await Promise.all(processIds.map(async (pId) => {
      const res:any = await apiClient.get(`${endpoints.allocationData}/${pId}?group_by=${groupBy}`);
      dataObj.push(res.data.map((item: any) => {
        return {
          ...item,
          nested: item.nested?.map((nestedItem: any) => {
            return {
              ...nestedItem,
              process_id: pId,
            };
          }),
        };
      }));
    }));
    setAllocationProcesses({
      status: "success",
      data: {
        processId: processIds,
        processData: dataObj.flat(1) || [],
        processStatus: allocationProcesses?.data?.processStatus === "finished" ? "finished" : "in_progress",
        orderType: allocationProcesses?.data?.orderType,
        shippingDate: allocationProcesses?.data?.shippingDate,
      },
    });
  } catch (error: any) {
    setAllocationProcesses({
      status: "failed",
      data: allocationProcesses.data || {},
    });
    showErrorMessage(error, "Failed to get multiple ongoing allocation processes");
  }
};

const updateAllocationProcesses = async (props: {
  process_ids: number[],
  allocationProcess: any,
  setAllocationProcess: any,
  formData: any,
  groupBy: "store" | "cc",
}) => {
  try {
    props?.setAllocationProcess({ status: "revalidate", data: props?.allocationProcess.data || {} });
    if (props?.process_ids) {
      await Promise.all(props?.process_ids.map(async (pId) => {
        await apiClientWithoutRetry.patch(`${endpoints.allocationProcess}/${pId}`, {
          shipping_date: props?.formData.shippingDate, // @TODO: this is left... blocking DART-2556...
        });
      }));
      // Fetch multiple or single allocation processes depending on allocationProcessId(s)
      if (props?.process_ids?.length > 0 && typeof props?.process_ids === typeof []) {
        await getMultipleAllocationProcesses(
          {
            ...props?.allocationProcess,
            data: {
              ...props?.allocationProcess?.data,
              shippingDate: props?.formData.shippingDate,
            },
          },
          props?.setAllocationProcess,
          props?.process_ids || undefined,
          props?.groupBy,
          true,
        );
      }
    }
    message.success("Allocation processes updated");
    return true;
  } catch (error: any) {
    props?.setAllocationProcess({ status: "failed", data: props?.allocationProcess.data || {} });
    showErrorMessage(error, "Failed to update allocation processes");
    return false;
  }
};

const updateAllocationProcessData = async (props: {
  process_id: number,
  allocationProcess: any,
  setAllocationProcess: any,
  formData: any,
  groupBy: "store" | "cc",
  isBulk?: boolean,
  shouldRevalidate?: boolean,
  shouldShowMessage?: boolean,
  customSuccessMessage?: string,
  customErrorMessage?: string,
}) => {
  try {
    props?.setAllocationProcess({ status: "revalidate", data: props?.allocationProcess.data || {} });
    if ((props?.formData.store_id && props?.formData.sku && props?.formData.qty && props?.process_id) || (props?.isBulk && props?.process_id)) {
      await apiClientWithoutRetry.patch(`${endpoints.allocationData}/${props?.process_id}`, {
        data: props?.isBulk ? props?.formData : [
          {
            store_id: props?.formData.store_id,
            sku: props?.formData.sku,
            allocated_qty: props?.formData.qty,
          },
        ],
      });
      if (props?.shouldRevalidate) {
        // Fetch multiple or single allocation processes depending on allocationProcessId(s)
        if (props?.allocationProcess?.data?.processId?.length > 0 && typeof props?.allocationProcess?.data?.processId === typeof []) {
          await getMultipleAllocationProcesses(
            props?.allocationProcess,
            props?.setAllocationProcess,
            props?.allocationProcess?.data?.processId || undefined,
            props?.groupBy,
            true,
          );
        } else if (props?.allocationProcess?.data?.processId && typeof props?.allocationProcess?.data?.processId === typeof 1) {
          await getAllocationProcess(
            props?.allocationProcess,
            props?.setAllocationProcess,
            props?.allocationProcess?.data?.processId || undefined,
            props?.groupBy,
            false,
            true,
          );
        }
      }
      if (props?.shouldShowMessage) {
        if (props?.customSuccessMessage) {
          message.success(props?.customSuccessMessage);
        } else {
          message.success(props?.isBulk
            ? "Quantities distributed between sizes by relevant contributions"
            : `Quantity for ${props?.formData.sku} updated to ${props?.formData.qty}`);
        }
      }
      return true;
    }
    props?.setAllocationProcess({ status: "failed", data: props?.allocationProcess.data || {} });
    if (props?.shouldShowMessage) {
      if (props?.customErrorMessage) {
        showErrorMessage(props?.customErrorMessage);
      } else {
        showErrorMessage(props?.isBulk ? "Failed to update total" : "Failed to update quantity");
      }
    }
    return false;
  } catch (error: any) {
    props?.setAllocationProcess({ status: "failed", data: props?.allocationProcess.data || {} });
    if (props?.shouldShowMessage) {
      if (props?.customErrorMessage) {
        showErrorMessage(props?.customErrorMessage);
      } else {
        showErrorMessage(error, props?.isBulk ? "Failed to update total" : "Failed to update quantity");
      }
    }
    return false;
  }
};

const finishAllocationProcess = async (allocationProcess: any, setAllocationProcess: any) => {
  try {
    if (allocationProcess?.data?.processId) {
      setAllocationProcess({ status: "request", data: allocationProcess.data || {} });
      await apiClientWithoutRetry.patch(`${endpoints.allocationProcess}/${allocationProcess?.data?.processId}`, { status: "APPROVED" });
      message.success("Allocation process finished successfully");
      setAllocationProcess({
        status: "success",
        data:
          {
            processId: allocationProcess.data.processId || undefined,
            processData: allocationProcess.data.processData || undefined,
            processStatus: "finished",
            orderType: allocationProcess?.data?.orderType,
            shippingDate: allocationProcess?.data?.shippingDate,
          },
      });
    }
  } catch (error: any) {
    setAllocationProcess({ status: "failed", data: allocationProcess.data || {} });
    showErrorMessage(error, "Failed to finish allocation process");
  }
};

const finishMultipleAllocationProcesses = async (allocationProcesses: any, setAllocationProcesses: any) => {
  try {
    if (allocationProcesses?.data?.processId?.length > 0) {
      setAllocationProcesses({ status: "request", data: allocationProcesses.data || {} });
      await Promise.all(allocationProcesses?.data?.processId?.map(async (pId: number) => {
        await apiClientWithoutRetry.patch(`${endpoints.allocationProcess}/${pId}`, { status: "APPROVED" });
      }));
      message.success("Allocation process finished successfully");
      setAllocationProcesses({
        status: "success",
        data:
          {
            processId: allocationProcesses.data.processId || undefined,
            processData: allocationProcesses.data.processData || [],
            processStatus: "finished",
            orderType: allocationProcesses?.data?.orderType,
            shippingDate: allocationProcesses?.data?.shippingDate,
          },
      });
    }
  } catch (error: any) {
    setAllocationProcesses({ status: "failed", data: allocationProcesses.data || {} });
    showErrorMessage(error, "Failed to finish allocation process");
  }
};

const getAllocationHistoryList = async (allocationHistoryList: any, setAllocationHistoryList: any) => {
  try {
    setAllocationHistoryList({ status: "request", data: Object.values(allocationHistoryList.data) || [] });
    const res:any = await apiClient.get(endpoints.allocationProcess);
    setAllocationHistoryList({ status: "success", data: res.data || [] });
  } catch (error: any) {
    setAllocationHistoryList({ status: "failed", data: Object.values(allocationHistoryList.data) || [] });
    showErrorMessage(error, "Failed to get ongoing allocation process");
  }
};

const deleteSavedAllocationHistory = async (
  allocationHistoryList: any, setAllocationHistoryList: any, id: number, callback?: () => void,
) => {
  try {
    setAllocationHistoryList({ status: "request", data: allocationHistoryList.data || [] });
    await apiClient.delete(`${endpoints.allocationProcess}/${id}`);
    message.success("Allocation history deleted successfully");
    const res:any = await apiClient.get(endpoints.allocationProcess);
    setAllocationHistoryList({ status: "success", data: res.data || [] });
  } catch (error: any) {
    setAllocationHistoryList({ status: "failed", data: allocationHistoryList.data || [] });
    showErrorMessage(error, "Failed to delete allocation history");
  } finally {
    if (callback) {
      callback();
    }
  }
};

const deleteMultipleSavedAllocationHistory = async (
  allocationHistoryList: any, setAllocationHistoryList: any, ids: number[], callback?: () => void,
) => {
  try {
    setAllocationHistoryList({ status: "request", data: allocationHistoryList.data || [] });
    await Promise.all(ids?.map(async (pId: number) => {
      await apiClient.delete(`${endpoints.allocationProcess}/${pId}`);
    }));
    message.success("Allocation history deleted successfully");
    const res:any = await apiClient.get(endpoints.allocationProcess);
    setAllocationHistoryList({ status: "success", data: res.data || [] });
  } catch (error: any) {
    setAllocationHistoryList({ status: "failed", data: allocationHistoryList.data || [] });
    showErrorMessage(error, "Failed to delete allocation history");
  } finally {
    if (callback) {
      callback();
    }
  }
};

export {
  startAllocationProcesses,
  getAllocationProcess,
  getMultipleAllocationProcesses,
  finishAllocationProcess,
  finishMultipleAllocationProcesses,
  getAllocationHistoryList,
  updateAllocationProcesses,
  updateAllocationProcessData,
  deleteSavedAllocationHistory,
  deleteMultipleSavedAllocationHistory,
};
