/* eslint-disable */
import React, {useMemo} from "react";
import {Badge} from "antd";
import {IStoreProfile} from "../../../../global/interfaces";
import Icon from "../../../../components/Icon";
import paths from "../../../../configs/paths";
import {only} from "../../../../utils/helpers";
import serializeParams from "../../../../utils/helpers/serializeParams";

interface IAQStoreStageButtonsBlockProps {
  selectedCC: string;
  selectedChannel: string;
  selectedStage: number;
  stages: any;
  allStores: IStoreProfile[];
  loading: boolean;
}

const IAQStoreStageButtonsBlock: React.FC<IAQStoreStageButtonsBlockProps> = ({
  selectedCC,
  selectedChannel,
  selectedStage,
  stages,
  allStores,
  loading,
}) => {
  const getDataForStage = (stage: number) => {
    const returnObject: any = {
      selected: {
        store_ids: [],
        store_numbers: [],
      },
      deselected: {
        store_ids: [],
        store_numbers: [],
      },
    };

    const stageData = stages?.data?.[`Stage ${stage}`];

    if (stageData?.filters) {
      Object.keys(stageData?.filters).forEach((key) => {
        returnObject[key] = stageData?.filters?.[key];
      });
    }

    const stageResponse = stageData?.response || [];

    returnObject.selected.store_ids = stageResponse.map((store: { store_id: number }) => store.store_id);
    returnObject.selected.store_numbers = stageResponse.map((store: { store_number: number }) => store.store_number);

    const allStoreIds = allStores?.map((store) => store.id) || [];
    const allStoreNumbers = allStores?.map((store) => store.number) || [];

    returnObject.deselected.store_ids = allStoreIds.filter((id) => !returnObject.selected.store_ids.includes(id));
    returnObject.deselected.store_numbers = allStoreNumbers.filter((number) => !returnObject.selected.store_numbers.includes(number));

    return returnObject;
  };

  const decideButtons = useMemo(() => {
    if (loading) return <></>;
    const stageData = getDataForStage(selectedStage);
    const prevStageData = selectedStage > 1 ? getDataForStage(selectedStage - 1) : { deselected: { store_ids: [], store_numbers: [] } };
    // calculate deselectedStoreDifference to only show stores deselected in particular stage
    const deselectedStoreDifference = {
      store_ids: stageData.deselected.store_ids.filter((id: number) => !prevStageData?.deselected?.store_ids?.includes(id)),
      store_numbers: stageData.deselected.store_numbers.filter((number: number) => !prevStageData?.deselected?.store_numbers?.includes(number)),
    }
    switch (selectedStage) {
      case 1:
        return (
          <div className="button-group">
            <a href={`${paths.view_all_stores}?channel=${stageData.channel}&brand=${stageData.brand}&store_ids=${stageData.selected?.store_ids?.join(",")}`} target="_blank">
              <Icon name="Link" />
              Selected Stores
              <Badge
                count={stageData.selected?.store_ids?.length}
                showZero
                overflowCount={999}
                style={{ backgroundColor: "#52c41a", marginLeft: 6 }}
              />
            </a>
          </div>
        );
      case 2:
        // @TODO: need to add division and department filter if backend also adds it to improve performance &division=${stageData.division}&department=${stageData.department}
        return (
          <div className="button-group">
            <a href={`${paths.view_all_stores}?channel=${prevStageData.channel}&brand=${prevStageData.brand}&store_ids=${stageData.selected?.store_ids?.join(",")}`} target="_blank">
              <Icon name="Link" />
              Selected Stores
              <Badge
                count={stageData.selected?.store_ids?.length}
                showZero
                overflowCount={999}
                style={{ backgroundColor: "#52c41a", marginLeft: 6 }}
              />
            </a>
            <a href={`${paths.view_all_stores}?channel=${prevStageData.channel}&brand=${prevStageData.brand}&store_ids=${deselectedStoreDifference?.store_ids?.join(",")}`}
               target="_blank"
               style={!deselectedStoreDifference?.store_ids?.length ? {pointerEvents: "none", opacity: 0.5 } : {}}>
              <Icon name="Link"/>
              Deselected Stores
              <Badge
                count={deselectedStoreDifference?.store_ids?.length}
                showZero
                overflowCount={999}
                style={{backgroundColor: "#f85454", marginLeft: 6}}
              />
            </a>
          </div>
        );
      case 3:
        return (
          <div className="button-group">
            <a href={`${paths.poa_edit}?${serializeParams(only(["poa_category_id", "poa_category_division", "poa_category_department", "poa_category_name"], stageData))}&channel=${selectedChannel}&store_ids=${stageData.selected?.store_ids?.join(",")}`} target="_blank">
              <Icon name="Link" />
              Selected Store&apos;s POAs
              <Badge
                count={stageData.selected?.store_ids?.length}
                showZero
                overflowCount={999}
                style={{ backgroundColor: "#52c41a", marginLeft: 6 }}
              />
            </a>
            <a
              href={`${paths.poa_edit}?${serializeParams(only(["poa_category_id", "poa_category_division", "poa_category_department", "poa_category_name"], stageData))}&channel=${selectedChannel}&store_ids=${deselectedStoreDifference?.store_ids?.join(",")}`}
              target="_blank"
              style={!deselectedStoreDifference?.store_ids?.length ? {pointerEvents: "none", opacity: 0.5 } : {}}>
              <Icon name="Link"/>
              Deselected Store&apos;s POAs
              <Badge
                count={deselectedStoreDifference?.store_ids?.length}
                showZero
                overflowCount={999}
                style={{backgroundColor: "#f85454", marginLeft: 6}}
              />
            </a>
          </div>
        );
      case 4:
        return (
          <div className="button-group">
            <a href={`${paths.size_profile_edit}?${serializeParams(only(["size_run_id", "size_run_name"], stageData))}&channel=${selectedChannel}&store_ids=${stageData.selected?.store_ids?.join(",")}`} target="_blank">
              <Icon name="Link" />
              Selected Store&apos;s Size Runs
              <Badge
                count={stageData.selected?.store_ids?.length}
                showZero
                overflowCount={999}
                style={{ backgroundColor: "#52c41a", marginLeft: 6 }}
              />
            </a>
            <a
              href={`${paths.size_profile_edit}?${serializeParams(only(["size_run_id", "size_run_name"], stageData))}&channel=${selectedChannel}&store_ids=${deselectedStoreDifference?.store_ids?.join(",")}`}
              target="_blank"
              style={!deselectedStoreDifference?.store_ids?.length ? {pointerEvents: "none", opacity: 0.5 } : {}}>
              <Icon name="Link"/>
              Deselected Store&apos;s Size Runs
              <Badge
                count={deselectedStoreDifference?.store_ids?.length}
                showZero
                overflowCount={999}
                style={{backgroundColor: "#f85454", marginLeft: 6}}
              />
            </a>
          </div>
        );
      case 5:
        return (
          <div className="button-group">
            <a href={`${paths.newness_allocation_history}?cc=${selectedCC}&store_ids=${deselectedStoreDifference?.store_numbers?.join(",")}`}
               target="_blank"
               style={!deselectedStoreDifference?.store_numbers?.length ? {pointerEvents: "none", opacity: 0.5 } : {}}>
            <Icon name="Link" />
              Allocated Store History
              <Badge
                count={deselectedStoreDifference?.store_numbers?.length}
                showZero
                overflowCount={999}
                style={{ backgroundColor: "#f85454", marginLeft: 6 }}
              />
            </a>
          </div>
        );
      default:
        return <></>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStage, stages, allStores, selectedChannel]);

  return (
    <div className="iaq-store-stage-buttons">
      {decideButtons}
    </div>
  );
};

export default IAQStoreStageButtonsBlock;
