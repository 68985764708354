import React, { useMemo, useState } from "react";
import "./index.less";
import {
  Col, Form, Radio, Row,
} from "antd";
import DartInput from "../../../../../components/DartInput";
import { sizeRunFields } from "../../../../../utils/validations/sizeRun.fields";
import useEnums from "../../../../../utils/hooks/useEnums";
import { ISizeRun } from "../../../../../global/interfaces";
import DartSelect from "../../../../../components/DartSelect";
import { capitalize } from "../../../../../utils/helpers";
import { divisionDepartmentMapping } from "../../../../../configs/constants";

interface ISizeRunForm {
  form: any;
  sizeRunFormData?: ISizeRun;
  editable?: boolean;
}

const SizeRunForm: React.FC<ISizeRunForm> = ({
  form,
  sizeRunFormData,
  editable = true,
}) => {
  const {
    department, division,
  } = useEnums();

  const [selectedDivision, setSelectedDivision] = useState(sizeRunFormData?.division);
  const [selectedDepartment, setSelectedDepartment] = useState(sizeRunFormData?.department);
  const [selectedType, setSelectedType] = useState(sizeRunFormData?.size_run_type);
  const [selectedCategory, setSelectedCategory] = useState(sizeRunFormData?.size_category);
  const alphaNumericRegex = /^[a-zA-Z0-9.]+(?:\/[a-zA-Z0-9.]+)*(?:,[a-zA-Z0-9.]+(?:\/[a-zA-Z0-9.]+)*)*$/;
  const numericRegex = /^\d+(?:[/,]\d+)*$/;
  const numericHalfRegex = /^\d*\.?\d+(?:\/\d*\.?\d+)*(?:,\d*\.?\d+(?:\/\d*\.?\d+)*)*$/;
  const [sizeRangeHelper, setSizeRangeHelper] = useState<string>("");

  const decideValidation = useMemo(() => {
    if (!selectedType) {
      setSizeRangeHelper("");
      return undefined;
    }
    if (selectedType === "numerical" && selectedCategory === "HALF_RANGE"
      && selectedDepartment && selectedDepartment === department.FOOTWEAR && selectedDivision) {
      setSizeRangeHelper("e.g. '1,1.5,2/2.5,3'");
      return numericHalfRegex;
    }
    if (selectedType === "numerical" && selectedCategory === "WHOLE_RANGE" && selectedDepartment && selectedDivision) {
      setSizeRangeHelper("e.g. '1,2/3,4,5'");
      return numericRegex;
    }
    if (selectedType === "numerical" && selectedDepartment && selectedDivision && selectedDepartment !== department.FOOTWEAR) {
      setSizeRangeHelper("e.g. '1,2/3,4,5'");
      return numericRegex;
    }
    if (selectedType === "alpha" && selectedDepartment && selectedDivision) {
      setSizeRangeHelper("e.g. 'S,M,M/L,L,XL'");
      return alphaNumericRegex;
    }
    setSizeRangeHelper("");
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartment, selectedDivision, selectedType, selectedCategory, department]);

  // Map available Departments based on selected Division
  const availableDepartments = useMemo(() => {
    if (selectedDivision) {
      return (divisionDepartmentMapping as any)[selectedDivision as any];
    }
    return [];
  }, [selectedDivision]);

  const handleFieldChange = (vals: any) => {
    // If it's single form item change, and not Submit action (as it runs on subit as well)
    if (vals?.length === 1) {
      if (vals?.[0]?.name?.[0] === "division") {
        form.setFieldsValue({
          department: undefined, size_category: undefined, size_run_all: undefined, size_run_type: undefined,
        });
        setSelectedType(undefined);
        setSelectedDivision(vals?.[0]?.value);
      }
      if (vals?.[0]?.name?.[0] === "department") {
        form.setFieldsValue({
          size_category: undefined, size_run_all: undefined, size_run_type: undefined,
        });
        setSelectedType(undefined);
        setSelectedDepartment(vals?.[0]?.value);
      }
      if (vals?.[0]?.name?.[0] === "size_run_type") {
        setSelectedType(vals?.[0]?.value);
        form.setFieldsValue({ size_run_all: undefined });
      }
      if (vals?.[0]?.name?.[0] === "size_category") {
        setSelectedCategory(vals?.[0]?.value);
        form.setFieldsValue({ size_run_all: undefined });
      }
      if (vals?.[0]?.name?.[0] === "size_run_all") {
        if (vals?.[0]?.value) {
          form.setFieldsValue({ size_run_all: vals?.[0]?.value?.replace(" ", "") });
        } else {
          form.setFieldsValue({ size_run_all: undefined });
        }
      }
    }
  };

  return (
    <div className="size-run-form">
      <Form
        scrollToFirstError
        layout={editable ? "vertical" : "inline"}
        form={form}
        initialValues={{
          ...(sizeRunFormData || {}),
          size_run_all: typeof sizeRunFormData?.size_run_all === "string"
            ? sizeRunFormData?.size_run_all?.replace(/[{}]/g, "")
            : sizeRunFormData?.size_run_all,
          size_category: sizeRunFormData?.department === "FOOTWEAR"
            ? sizeRunFormData?.size_category
            : undefined,
        }}
        className="store-profile-details-top-form"
        onFieldsChange={(vals) => handleFieldChange(vals)}
      >
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <h2>Division & Department</h2>
            <Form.Item
              name="division"
              rules={sizeRunFields.division}
              wrapperCol={{ span: 12 }}
            >
              <DartSelect
                label="Division"
                selectOptions={Object.values(division).map((d) => {
                  return { value: d, label: capitalize(d.toLowerCase()) };
                })}
                hasBorder
                disabled={!editable || !!sizeRunFormData?.division}
                placeholder=""
              />
            </Form.Item>
            <Form.Item
              name="department"
              rules={sizeRunFields.department}
              wrapperCol={{ span: 12 }}
            >
              <DartSelect
                label="Department"
                selectOptions={availableDepartments.map((d: string) => {
                  return { value: d, label: d?.length > 3 ? capitalize(d.toLowerCase()) : d };
                })}
                hasBorder
                disabled={!editable || !!sizeRunFormData?.department}
                placeholder=""
              />
            </Form.Item>
            <hr className="light-splitter" />
            <h2>Size Information</h2>
            <Form.Item
              name="size_category"
              label={selectedDepartment !== department.FOOTWEAR ? "Size Category (Footwear Only)" : "Size Category"}
            >
              <Radio.Group
                size="large"
                disabled={selectedDepartment !== department.FOOTWEAR || !!sizeRunFormData?.size_category}
              >
                {editable || sizeRunFormData?.size_category === "WHOLE_RANGE" ?
                  <Radio value="WHOLE_RANGE">Whole</Radio> : ""}
                {editable || sizeRunFormData?.size_category === "HALF_RANGE" ?
                  <Radio value="HALF_RANGE">Half</Radio> : ""}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="size_run_type"
              label="Size Run Type"
              required
              rules={sizeRunFields.size_run_type}
            >
              <Radio.Group
                size="large"
                disabled={!!sizeRunFormData?.size_run_type}
              >
                {editable || sizeRunFormData?.size_run_type === "numerical" ?
                  <Radio value="numerical">0 - 20 (numerical)</Radio> : ""}
                {editable || sizeRunFormData?.size_run_type === "alpha" ?
                  <Radio value="alpha">XXXS - XXXL, 2XS-2XL (alpha/mixed)</Radio> : ""}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="size_run_all"
              label="Size Range"
              required
              rules={[
                ...(decideValidation ? [{
                  pattern: decideValidation as any,
                  message: "Please enter a valid size range",
                }] : []),
                {
                  required: true,
                  message: "This field is required",
                }]}
            >
              <DartInput
                editable={editable && !!selectedType && !sizeRunFormData?.size_run_all}
                placeholder={sizeRangeHelper}
              />
            </Form.Item>
            <Form.Item
              name="name"
              label="Name"
              required
              rules={sizeRunFields.name}
            >
              <DartInput
                editable={editable}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SizeRunForm;
