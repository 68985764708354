import { message } from "antd";
import moment from "moment";
import apiClient from "../../../api/apiClient";
import endpoints from "../../../api/endpoints";
import { IChannelTypes } from "../../../global/atoms/global-filters-atom";
import { IAllocationQuantityCC, IAllocQ } from "../../../global/interfaces";
import {
  only, removeNullValues, showErrorMessage,
} from "../../../utils/helpers";

type FilterAllocQ = {
  brand: string;
  channel: string;
}

const getAllocQList = async (
  allocQList: { data: IAllocQ[], status: string },
  setAllocQList: any,
  filters: FilterAllocQ,
) => {
  try {
    if (allocQList?.data?.length !== 0) {
      setAllocQList({
        key: `${filters?.brand}_${filters?.channel}`, status: "revalidate", data: allocQList.data,
      });
    } else {
      setAllocQList({
        key: `${filters?.brand}_${filters?.channel}`, status: "request", data: allocQList.data,
      });
    }
    if (filters.brand && filters.channel) {
      const res:any = await apiClient.get(endpoints.initialAllocationQuantities, { params: { ...only(["brand", "channel"], filters) } });
      setAllocQList({
        key: `${filters?.brand}_${filters?.channel}`,
        status: "success",
        data: transformListDataToGroupedByMonths(res?.data?.data),
      });
    } else {
      setAllocQList({
        key: `${filters?.brand}_${filters?.channel}`, status: "success", data: {},
      });
    }
  } catch (error: any) {
    setAllocQList({
      key: `${filters?.brand}_${filters?.channel}`, status: "failed", data: {},
    });
    showErrorMessage(error, "Failed to get initial allocation quantities list");
  }
};

const getAllocQData = async (
  allocQData: {[key:string]: { status: string, data: IAllocationQuantityCC[] }},
  setAllocQData: any,
  month: string,
  filters: { channel: IChannelTypes, brand: string, department?: string, division?: string },
  shouldKeepOldUAS = true,
) => {
  try {
    setAllocQData((prev: any) => {
      return {
        ...prev,
        [month]: {
          status: !allocQData?.[month]?.data || allocQData?.[month]?.status !== "success" ? "request" : "revalidate",
          data: allocQData?.[month]?.data || {},
        },
      };
    });

    const params = {
      channel: filters.channel,
      brand: filters.brand,
      department: filters.department,
      division: filters.division,
      newness_date: moment(month).format("YYYY-MM-DD"),
    };
    const res:any = await apiClient.get(endpoints.initialAllocationQuantitiesData, { params: removeNullValues(params) });
    setAllocQData((prev:any) => {
      const existingData = { ...prev?.[month]?.data };
      return {
        ...prev,
        [month]: {
          status: "success",
          data: mapDataWithIds(
            res.data?.data || [], existingData, shouldKeepOldUAS,
          ),
        },
      };
    });
  } catch (error: any) {
    setAllocQData((prev: any) => { return { ...prev, [month]: { status: "failed", data: {} } }; });
    showErrorMessage(error, "Failed to get initial allocation quantities data");
  }
};

const updateAllocQ = async (
  dataToSend?: any, cleanup?: (() => void)[], reFetchFn?: () => void,
) => {
  try {
    if (dataToSend?.data?.length > 0) {
      await apiClient.patch(endpoints.initialAllocationQuantitiesData, dataToSend);
      message.success(`${dataToSend?.data?.length} Style updated`);
    } else {
      message.info("There are no changes to update");
    }
    if (reFetchFn) {
      await reFetchFn();
    }
    if (cleanup) {
      for (let c = 0; c < cleanup?.length; c += 1) {
        cleanup[c]?.();
      }
    }
  } catch (error: any) {
    showErrorMessage(error, "Failed to update style colors");
  }
};

interface IAvailableAndSelectedStores {
  status: string,
  data: {
    selected_store: { store_id: number, poa_qty: string }[],
    available_store_ids: { store_id: number, poa_qty: string }[],
  },
}

const getIAQAvailableAndSelectedStores = async (
  styleStoreList: any, setStyleStoreList: any, newness_id?: number, callback?: (availableAndSelectedStores: IAvailableAndSelectedStores) => void,
) => {
  try {
    setStyleStoreList({ status: "request", data: styleStoreList.data || {} });
    if (newness_id) {
      const res = await apiClient.get(endpoints.initialAllocationQuantitiesStyleDetails.replace(":id", newness_id.toString()));
      setStyleStoreList({ status: "success", data: res.data || {} });
      callback?.({ status: "success", data: res.data || {} });
    }
  } catch (error: any) {
    setStyleStoreList({ status: "failed", data: [] });
    showErrorMessage(error, "Failed to get style details");
  }
};

const fetchIAQStoreStages = async (
  stages: any, setStages: any, cc: IAllocationQuantityCC,
) => {
  try {
    setStages({ status: "request", data: stages.data || [] });
    if (cc?.cc) {
      const res: any = await apiClient.get(endpoints.initialAllocationQuantitiesStoreStages.replace(":id", cc?.newness_id?.toString()));
      setStages({ status: "success", data: res?.data });
    }
  } catch (error: any) {
    setStages({
      status: "failed", data: [], count: 0,
    });
    showErrorMessage(error, "Failed to get CC store stages data");
  }
};

const updateIAQStorePOAs = async (
  dataToSend: any, newness_id?: number, callback?: () => void,
) => {
  try {
    if (newness_id) {
      await apiClient.patch(endpoints.initialAllocationQuantitiesStyleDetails.replace(":id", newness_id.toString()), dataToSend);
      callback?.();
      message.success("Style details updated");
      return true;
    }
    return false;
  } catch (error: any) {
    showErrorMessage(error, "Failed to update style details");
    return false;
  }
};

const getIAQComments = async (
  cc_id: number, setComments: any, setLoading?: any,
) => {
  try {
    // if (!setLoading) {
    //   const res: any = await apiClient.get(endpoints.initialAllocationQuantitiesComments, { params: { fulfilment_id: cc_id } });
    //   setComments?.(res?.data?.data || []);
    //   return;
    // }
    setLoading?.(true);
    if (cc_id) {
      const res: any = await apiClient.get(endpoints.initialAllocationQuantitiesComments, { params: { fulfilment_id: cc_id } });
      setLoading?.(false);
      setComments?.(res?.data?.data || []);
      return;
    }
    setLoading?.(false);
    setComments?.([]);
    return;
  } catch (error: any) {
    setLoading?.(false);
    setComments?.([]);
  }
};

const addIAQComment = async (
  cc_id?: number, comment?: string, setComments?: any, setLoading?: any,
) => {
  try {
    if (cc_id && comment) {
      await apiClient.post(endpoints.initialAllocationQuantitiesComments, { fulfilment_id: cc_id, text: comment });
      message.success("Comment successfully added");
      getIAQComments(
        cc_id, setComments, setLoading,
      );
    }
  } catch (error: any) {
    showErrorMessage(error, "Failed to add comment");
  }
};

export {
  getAllocQList,
  getAllocQData,
  updateAllocQ,
  getIAQAvailableAndSelectedStores,
  updateIAQStorePOAs,
  getIAQComments,
  addIAQComment,
  fetchIAQStoreStages,
};

const transformListDataToGroupedByMonths = (data: IAllocQ[]) => {
  const newObject: any = { recent: {}, all: {} };
  // Reverse list to get newest to oldest DART-2856
  // Assign month "recent" tag if its within past month and anytime in the future, otherwise recent: false
  const dateRecentFrom = moment().add(-2, "month").format("YYYY-MM");
  (data || [])?.reverse()?.map((each) => {
    if (!newObject.all.hasOwnProperty(each.newness_date)) {
      newObject.all[each.newness_date] = [];
    }
    newObject.all[each.newness_date].push(each);

    if (moment(each.newness_date).isAfter(moment(dateRecentFrom))) {
      if (!newObject.recent.hasOwnProperty(each.newness_date)) {
        newObject.recent[each.newness_date] = [];
      }
      newObject.recent[each.newness_date].push(each);
    }
    return each;
  });

  return newObject;
};

// Get dummy data of POA classes for IAQ table
export const getPOAClasses = () => {
  return [
    { id: 1, name: "Classic Boot" },
    { id: 2, name: "Minis" },
    { id: 3, name: "Tasman" },
    { id: 4, name: "Adirondack" },
    { id: 5, name: "Something" },
    { id: 6, name: "Something 2" },
    { id: 7, name: "Something 3" },
  ];
};

const mapDataWithIds = (
  newData: IAllocationQuantityCC[], existingData: any, shouldKeepOldUAS = true,
) => {
  // After fetch, select which newness_ids to keep in the list to avoid duplication or caching of outdated data
  const existingIds = newData?.map((d) => d.newness_id?.toString());
  const dt: any = only(existingIds, existingData);

  newData?.map((d) => {
    const randomClass = getPOAClasses()[Math.floor(Math.random() * getPOAClasses().length)];
    dt[d.newness_id] = {
      ...d,
      updated_uas: shouldKeepOldUAS ? dt?.[d.newness_id]?.updated_uas || d.uas : d.uas,
      updated_supply_by_date: shouldKeepOldUAS ? dt?.[d.newness_id]?.updated_supply_by_date || d.supply_by_date : d.supply_by_date,
      poa_class: randomClass,
      updated_poa_class: randomClass,
    };
    return d;
  });

  return dt;
};
