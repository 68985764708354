import React, { useMemo } from "react";
import "./index.less";
import {
  Col, Form, Tooltip,
} from "antd";
import moment, { Moment } from "moment";
import DartCheckbox from "../../../../components/DartCheckbox";
import DartProgress from "../../../../components/DartProgress";
import { INewnessRow } from "../../../../global/interfaces";
import tooltipValidationMessages from "../../../../utils/validations/tooltipValidationMessages";

type ccType = {
  ccs: INewnessRow[];
  percent: number;
}

interface IFormattedData {
  completeCC: ccType;
  readyCC: ccType;
  notReadyCC: ccType;
}

interface ISingleMonthBlock {
  currentMonth: Moment;
  isActive: boolean;
  colSpan?: number;
  formattedData?: IFormattedData;
  canSelect?: boolean;
}

const SingleMonthBlock:React.FC<ISingleMonthBlock> = ({
  currentMonth,
  isActive,
  colSpan = 8,
  formattedData,
  canSelect = false,
}) => {
  const readyCount = formattedData?.readyCC?.ccs?.length || 0;
  const notReadyCount = formattedData?.notReadyCC?.ccs?.length || 0;
  const completeCount = formattedData?.completeCC?.ccs?.length || 0;

  // Find the latter date in between ready, not ready and complete cc arrays
  const decideLastUpdate = useMemo(() => {
    const lastUpdate = formattedData?.completeCC?.ccs?.[0]?.last_update
      || formattedData?.readyCC?.ccs?.[0]?.last_update
      || formattedData?.notReadyCC?.ccs?.[0]?.last_update;

    if (lastUpdate) {
      return moment(lastUpdate).format("MM/DD/YYYY hh:mm A");
    }

    return "XX/XX/XXXX XX:XX AM";
  }, [formattedData]);

  return (
    <Col span={colSpan}>
      <Tooltip
        overlay={!canSelect ? tooltipValidationMessages.newness_filters : ""}
      >
        <div className="newness-by-month-col-wrapper single-month-block">
          <div className="month-wrapper">
            <Form.Item name={`${currentMonth?.format("YYYY-MM")}-01`}>
              <DartCheckbox
                label={`${currentMonth?.format("MMM YYYY").toUpperCase()} NEWNESS`}
                checked={isActive}
                disabled={!canSelect || (readyCount + notReadyCount) === 0}
              />
            </Form.Item>
            <div className="last-updated">
              Last updated
              {" "}
              <span data-testid={`${currentMonth?.format("MMM").toUpperCase()}_NEWNESS_LAST_UPDATED`}>{decideLastUpdate}</span>
            </div>
            <div className="month-cc-stats">
              <div>
                <h1>
                  {canSelect ? readyCount : 0}
                  {" CCs"}
                </h1>
                <h1>
                  {canSelect ? notReadyCount : 0}
                  {" CCs"}
                </h1>
              </div>
              <div>
                <span>Ready for allocation</span>
                <span>Not ready for allocation</span>
              </div>
            </div>
          </div>
          <DartProgress
            key={`${canSelect.toString()}_${readyCount}_${notReadyCount}_${completeCount}`}
            title="Newness is {{value}} complete"
            progress={[
              {
                percent: canSelect ? formattedData?.completeCC?.percent || 0 : 0,
                color: "#5FC7CB",
                content: "{{value}} CCs Completed",
              },
              {
                percent: canSelect ? formattedData?.readyCC?.percent || 0 : 0,
                color: "#7CA6BB",
                content: "{{value}} CCs Ready",
                actualPercent: 0,
              },
              {
                percent: canSelect ? formattedData?.notReadyCC?.percent || 0 : 0,
                color: "#E1E2E3",
                content: "{{value}} CCs Not Ready",
                actualPercent: 0,
              },
            ]}
            withFooter
          />
        </div>
      </Tooltip>
    </Col>
  );
};

export default SingleMonthBlock;
