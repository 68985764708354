import React, { useEffect, useState } from "react";
import "./index.less";
import { Form } from "antd";
import CompactSelect from "../../../../components/CompactSelect";
import { capitalize, removeNullValues } from "../../../../utils/helpers";
import useEnums from "../../../../utils/hooks/useEnums";

interface IUploadedFileListFiltersBlock {
  onFiltersChange?: (filters: any) => void;
  userList?: any[];
}

const UploadedFileListFiltersBlock:React.FC<IUploadedFileListFiltersBlock> = ({ onFiltersChange, userList }) => {
  const [form] = Form.useForm();
  const { upload_status } = useEnums();
  const [filters, setFilters] = useState({});

  // Handler to set filter according to chosen status
  const handleFieldChange = (field: any) => {
    const name = field?.[0]?.name?.[0];
    const value = field?.[0]?.value;
    setFilters((prev) => { return removeNullValues({ ...prev, [name]: value }); });
  };

  // Call to outside function (prop) in case filter is updated. see "handleFieldChange"
  useEffect(() => {
    onFiltersChange?.(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <Form
      form={form}
      onFieldsChange={handleFieldChange}
    >
      <div className="uploaded-file-list-filters-block">
        <Form.Item name="uploaded_by">
          <CompactSelect
            placeholder="Uploaded By"
            selectOptions={Object.values(userList || {})?.map((dt) => { return { value: dt, label: dt }; })}
          />
        </Form.Item>
        <Form.Item name="status">
          <CompactSelect
            placeholder="Status"
            selectOptions={Object.values(upload_status).map((dt) => { return { value: dt, label: capitalize(dt) }; })}
          />
        </Form.Item>
      </div>
    </Form>
  );
};

export default UploadedFileListFiltersBlock;
