import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import {
  Form, message, Modal,
} from "antd";
import { useRecoilState } from "recoil";
import LoadingIcon from "antd/lib/button/LoadingIcon";
import { NewnessUploadStep2 } from "../../widgets/NewnessUpload";
import DartButton from "../../../../components/DartButton";
import { removeNullValues } from "../../../../utils/helpers";
import {
  getUploadList, prepareUpload, uploadFile,
} from "../..";
import { uploadFileAtom, uploadFileListAtom } from "../../../../global/atoms";
import PageTitle from "../../../../components/PageTitle";
import UploadFilesTableBlock from "../../blocks/UploadFilesTableBlock";
import DartProgress from "../../../../components/DartProgress";
import UploadedFileListFiltersBlock from "../../blocks/UploadedFileListFiltersBlock";
import useEnums from "../../../../utils/hooks/useEnums";

const NewnessUploadListLayoutOld = () => {
  const { import_type } = useEnums();
  const [form] = Form.useForm();
  const [uploadStatus, setUploadStatus] = useRecoilState(uploadFileAtom);
  const [uploadListData, setUploadListData] = useRecoilState(uploadFileListAtom);
  const [currentStep, setCurrentStep] = useState<0 | 1 | 2>(0);
  const [preUploadData, setPreUploadData] = useState<any>({});
  const [fileData, setFileData] = useState<any>(undefined);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [tableData, setTableData] = useState<any>([]);
  const [initFetch, setInitFetch] = useState(false);
  const [tableFilters, setTableFilters] = useState<any>({});
  const [availableUsers, setAvailableUsers] = useState<any>([]);
  const [userListPassed, setUserListPassed] = useState(false);

  useEffect(() => {
    if (tableData?.length > 0) {
      if (!userListPassed) {
        const list: any = {};
        for (let i = 0; i < tableData.length; i += 1) {
          list[tableData[i].uploaded_by] = tableData[i].uploaded_by_email;
        }
        setAvailableUsers(list);
        setUserListPassed(true);
      }
    }
  }, [tableData, userListPassed]);

  // Get list of uploaded files
  useEffect(() => {
    if (!initFetch) {
      // Call Service that gets uploaded file list
      getUploadList(uploadListData, setUploadListData);
      setInitFetch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initFetch]);

  // Re-occurring useEffect that fetches the list again and again every 20 seconds to have updated data
  useEffect(() => {
    const interval = setInterval(() => {
      getUploadList(
        uploadListData, setUploadListData, true,
      );
    }, 20000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadListData]);

  // Filter data according to selected filters and prepares them to be accepted by the table
  useEffect(() => {
    setTableData((uploadListData?.data || []).filter((td: any) => {
      let authorFiltered = true;
      let statusFiltered = true;
      const importTypeFiltered = (td.import_type || "").toString().indexOf("FLOW_DOC") > -1;
      if (tableFilters.uploaded_by && td.uploaded_by_email === tableFilters.uploaded_by) {
        authorFiltered = true;
      } else if (tableFilters.uploaded_by) {
        authorFiltered = false;
      }
      if (tableFilters.status && td.status === tableFilters.status) {
        statusFiltered = true;
      } else if (tableFilters.status) {
        statusFiltered = false;
      }
      return authorFiltered && statusFiltered && importTypeFiltered ? td : false;
    }));
  }, [uploadListData?.data, tableFilters]);

  // Cleanup if returned to the first step of two-step flow
  useEffect(() => {
    if (currentStep === 1) {
      setUploadStatus({
        preUploadStatus: "initial", uploadStatus: "initial", controller: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  // Handler that validates form field and sets validated data to state
  const handleFormChange = (formItem: any) => {
    const fieldName = formItem?.[0]?.name?.[0];
    const fieldValue = formItem?.[0]?.value;
    if (fieldName !== "file") {
      setPreUploadData(removeNullValues({
        ...preUploadData,
        [fieldName]: fieldValue && fieldValue?.length ? fieldValue : undefined,
        doc_type: ["NEWNESS_DATA_VAR"],
        import_types: ["FLOW_DOC"],
      }));
    }
  };

  // Handler to cancel the upload process and reset all fields
  const handleCancel = () => {
    setCurrentStep(0);
    setPreUploadData({});
    setFileData(undefined);
    form.setFieldsValue({ file: undefined });
    form.resetFields();
    setUploadPercent(0);
  };

  // Callback function of progress bar to decide how much progress was done for upload (useful for big files)
  const onProgress = (progressEvent: any) => {
    setUploadPercent(5 + (progressEvent.loaded / progressEvent.total) * 95);
  };

  // Handler that uploads the file, setting correct "progress" for UI in the way
  const handleFileUpload = async (files: any) => {
    setUploadPercent(4);
    await prepareUpload(
      "NEWNESS_DATA_VAR", ["FLOW_DOC"], files?.file?.type || "undefined", setUploadStatus,
    ).then(async (response: any) => {
      setUploadPercent(5);
      if ((response?.status && response.status?.toString()?.[0] === "2") || uploadStatus.preUploadStatus === "success") {
        if (response?.data && fileData) {
          // Call Service that uploads the file to S3 pre-signed url
          await uploadFile(
            files.file, response?.data, setUploadStatus, onProgress,
          ).then((responseUpload: any) => {
            if (responseUpload.status && responseUpload.status?.toString()?.[0] !== "2") {
              setFileData(undefined);
              setUploadPercent(100);
              form.setFieldsValue({ file: undefined });
            } else {
              setUploadPercent(100);
              // Re-fetch when successfully uploaded
              getUploadList(uploadListData, setUploadListData);
              handleCancel();
            }
          });
        }
      } else {
        setFileData(undefined);
        handleCancel();
      }
    });
  };

  // Update progress bar UI visuals according to statuses of both steps
  const mapStatusToProgress = (status1?: string, status2?: string): any => {
    if (status1 === "success" && status2 === "success") {
      return "success";
    } if (status1 === "request" || status2 === "request") {
      return "request";
    } if (status1 === "failed" || status2 === "failed") {
      return "exception";
    }
    return "active";
  };

  // Memoized value that checks if upload is still in progress or not
  const isUploadInProgress = useMemo(() => {
    return uploadStatus.preUploadStatus === "request" || uploadStatus.uploadStatus === "request";
  }, [uploadStatus]);

  return (
    <div
      className="newness-upload-list-layout"
      // necessary for enum issue fix
      key={Object.values(import_type || {})?.length?.toString()}
    >
      <PageTitle
        title="File Upload (OLD)"
        icon="Document"
        rightNode={(
          <>
            <div data-testid="upload-file-init-button">
              <DartButton
                label={isUploadInProgress && currentStep === 0 ? "Upload in progress" : "Upload Flow Document"}
                icon={isUploadInProgress && currentStep === 0 ? (
                  <LoadingIcon
                    existIcon
                    prefixCls="custom-loading"
                  />
                ) : "Plus"}
                size="sm"
                disabled={isUploadInProgress}
                onClick={() => {
                  setCurrentStep(1);
                }}
              />
              {isUploadInProgress && currentStep === 0 ? (
                <div
                  className="minimized-upload-progress"
                  data-testid="minimized-progress-bar"
                >
                  <DartProgress progress={uploadPercent} />
                </div>
              ) : ""}
            </div>
          </>
        )}
      />

      <div
        className="newness-upload-list-filters"
        data-testid="upload-file-filters-wrapper"
      >
        <UploadedFileListFiltersBlock
          onFiltersChange={(filters) => setTableFilters(filters)}
          userList={availableUsers}
        />
      </div>

      <hr style={{ marginTop: 28 }} />

      <div data-testid="upload-file-table-wrapper">
        <UploadFilesTableBlock
          uploadFileList={tableData}
          loading={uploadListData.status === "request"}
        />
      </div>

      <Modal
        visible={currentStep === 1}
        closable
        destroyOnClose={false}
        title="Upload Flow Document"
        maskClosable={false}
        wrapClassName="newness-upload-modal"
        onCancel={() => {
          if (isUploadInProgress) {
            setCurrentStep(0);
          } else {
            handleCancel();
          }
        }}
        footer={(
          <div className="newness-upload-modal-footer align-left">
            {
              isUploadInProgress ||
              uploadStatus.uploadStatus === "success" ||
              uploadStatus.preUploadStatus === "success" ? (
                <DartButton
                  label="Cancel Upload"
                  type="danger"
                  onClick={() => { (uploadStatus?.controller as any)?.abort(); }}
                />
                ) : <></>
            }
          </div>
        )}
      >
        <div data-testid="newness-upload-modal-2">
          <NewnessUploadStep2
            form={form}
            formData={fileData}
            uploaderProps={{
              multiple: false,
              fileList: undefined,
              maxCount: 1,
              accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              progress: {
                showInfo: false,
                status: mapStatusToProgress(uploadStatus.uploadStatus, uploadStatus.preUploadStatus),
                success: {
                  percent: uploadPercent,
                  strokeColor: uploadStatus.uploadStatus === "failed" || uploadStatus.preUploadStatus === "failed" ? "#FC4848" : "#19945D",
                },
              },
              beforeUpload: (file: any) => {
                setUploadPercent(1);

                setFileData(undefined);
                // Validate file type to accept only excel sheets
                if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                  message.error("Selected file type is not allowed");
                  return;
                }
                setUploadPercent(2);
                // Sets file data according to user-selected file and then fires function "customRequest" to upload file
                setFileData(file);
                setUploadPercent(3);
              },
              customRequest: handleFileUpload,
            }}
            onFormChange={handleFormChange}
          />
        </div>
      </Modal>
    </div>
  );
};

export default NewnessUploadListLayoutOld;
