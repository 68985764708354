/* eslint-disable no-param-reassign */
import { selector } from "recoil";
import { sizeRunListAtom } from "../atoms";

export const sizeRunListSelector = selector({
  key: "size-run-list-selector",
  get: ({ get }) => {
    const atomData = get(sizeRunListAtom);

    if (atomData.status === "success") {
      // get parent keys: such as "WOMENS", "MENS", "KIDS"
      const sortedParentKeys = Object.keys(atomData?.data)
        .sort()
        .reverse();

      const sortedData: any = [];

      // loop through sorted parent keys and get child keys: such as "FOOTWEAR", "APPAREL", "ACCESSORIES"
      sortedParentKeys.map((key: string) => {
        const sortedChildKeys = Object.keys(atomData?.data?.[key])
          .sort()
          .reverse();

        const sortedChildData: any = [];

        // loop through sorted child keys and assign sorted data to sorted parent as child data
        sortedChildKeys.map((childKey: string) => {
          sortedChildData.push({ key: childKey, data: (atomData?.data?.[key]?.[childKey]) });
          return childKey;
        });

        // assign sorted child data to sorted parent data
        sortedData.push({ key, data: sortedChildData });

        return key;
      });

      // return modified object of size run list
      return ({
        status: atomData?.status, data: sortedData, channelStoreList: atomData.channelStoreList, originalData: atomData.originalData,
      });
    }
    return atomData;
  },
});

// DATA EXAMPLE:
/*
{
  "status": "success",
  "data": [
    {
      "key": "WOMEN'S",
      "data": [
        {
          "key": "FOOTWEAR",
          "data": [
            {
              "size_run_all": false,
              "size_run_max": 11.5,
              "division": "WOMEN'S",
              "name": "7.5 - 11.5",
              "department": "FOOTWEAR",
              "size_category": "FOOTWEAR",
              "size_run_min": 7.5,
              "id": 47
            },
            {
              "size_run_all": false,
              "size_run_max": 11,
              "division": "WOMEN'S",
              "name": "6 - 11",
              "department": "FOOTWEAR",
              "size_category": "FOOTWEAR",
              "size_run_min": 6,
              "id": 50
            }
          ]
        },
        {
          "key": "APPAREL",
          "data": [
            {
              "size_run_all": false,
              "size_run_max": "XL",
              "division": "WOMEN'S",
              "name": "XXS - XL",
              "department": "APPAREL",
              "size_category": "APPAREL",
              "size_run_min": "XXS",
              "id": 46
            }
          ]
        },
        {
          "key": "ACCESSORIES",
          "data": [
            {
              "size_run_all": false,
              "size_run_max": null,
              "division": "WOMEN'S",
              "name": "One Size",
              "department": "ACCESSORIES",
              "size_category": "ACCESSORIES",
              "size_run_min": null,
              "id": 48
            }
          ]
        }
      ]
    },
    {
      "key": "MEN'S",
      "data": [
        {
          "key": "FOOTWEAR",
          "data": [
            {
              "size_run_all": false,
              "size_run_max": 15,
              "division": "MEN'S",
              "name": "7 - 15",
              "department": "FOOTWEAR",
              "size_category": "FOOTWEAR",
              "size_run_min": 7,
              "id": 45
            }
          ]
        },
        {
          "key": "APPAREL",
          "data": [
            {
              "size_run_all": false,
              "size_run_max": "XXL",
              "division": "MEN'S",
              "name": "L - XXL",
              "department": "APPAREL",
              "size_category": "APPAREL",
              "size_run_min": "L",
              "id": 44
            }
          ]
        }
      ]
    },
    {
      "key": "KIDS'",
      "data": [
        {
          "key": "FOOTWEAR",
          "data": [
            {
              "size_run_all": false,
              "size_run_max": "L",
              "division": "KIDS'",
              "name": "S - L",
              "department": "FOOTWEAR",
              "size_category": "FOOTWEAR",
              "size_run_min": "S",
              "id": 43
            }
          ]
        }
      ]
    },
    {
      "key": "HOMECARE",
      "data": [
        {
          "key": "ALL",
          "data": [
            {
              "size_run_all": false,
              "size_run_max": null,
              "division": "HOMECARE",
              "name": "One Size",
              "department": "ALL",
              "size_category": "HOMECARE",
              "size_run_min": null,
              "id": 42
            }
          ]
        }
      ]
    }
  ]
}
*/
