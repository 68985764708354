import React, { useEffect } from "react";
import "./index.less";
import {
  Col, Form, message, Row,
} from "antd";
import { useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import DartInput from "../../../../components/DartInput";
import { IUser } from "../../../../global/interfaces";
import DartSegmentedSelect from "../../../../components/DartSegmentedSelect";
import CanAccess, {
  ADMIN, ALLOCATOR, canAccess, MANAGER, SUPER_ADMIN,
} from "../../../common/widgets/CanAccess";
import { userAtom } from "../../../../global/atoms";
import paths from "../../../../configs/paths";
import { regions } from "../../../../configs/constants";

interface IUserForm {
  form: any;
  userFormData?: IUser;
  editable?: boolean;
}

const UserForm: React.FC<IUserForm> = ({
  form,
  userFormData,
  editable = true,
}) => {
  const history = useHistory();
  const user = useRecoilValue(userAtom);

  const decideRoleOptions = () => {
    if (user?.role && canAccess(
      true, { role: user?.role }, { roles: [SUPER_ADMIN] },
    )) {
      return [ALLOCATOR, MANAGER, ADMIN, SUPER_ADMIN];
    }
    return [ALLOCATOR, MANAGER, ADMIN];
  };

  const decideRegionOptions = () => {
    if (user?.role && canAccess(
      true, { role: user?.role }, { roles: [SUPER_ADMIN] },
    )) {
      return Object.keys(regions);
    }
    return Object.keys(regions).filter((region) => region === user?.region);
  };

  useEffect(() => {
    if (userFormData?.role === SUPER_ADMIN && !canAccess(
      true, { role: user?.role }, { roles: [SUPER_ADMIN] },
    )) {
      message.warning("You are not authorized to edit this user");
      history.push(paths.user_list);
    }
  }, [user?.role, userFormData]);

  return (
    <div className="user-create-edit-form">
      <Form
        scrollToFirstError
        layout={editable ? "vertical" : "inline"}
        form={form}
        initialValues={{
          ...(userFormData || {}),
          region: userFormData?.region || user?.region,
          role: userFormData?.role || ALLOCATOR,
          enabled: userFormData?.enabled ? "true" : "false",
        }}
        className="store-profile-details-top-form"
      >
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Name"
              required
              rules={[
                {
                  required: true,
                  message: "Name is required",
                },
                {
                  min: 3,
                  message: "Name must be at least 3 characters",
                },
                {
                  max: 50,
                  message: "Name must be at most 50 characters",
                },
              ]}
            >
              <DartInput
                editable={editable}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              required
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
                {
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Email is invalid",
                },
                {
                  min: 5,
                  message: "Email must be at least 5 characters",
                },
                {
                  max: 50,
                  message: "Email must be at most 50 characters",
                },
              ]}
            >
              <DartInput
                editable={editable && !userFormData?.email}
              />
            </Form.Item>
            <Form.Item
              name="phone_number"
              label="Phone Number"
              required
              rules={[
                {
                  required: true,
                  message: "Phone Number is required",
                },
                {
                  pattern: /^\+[1-9]\d{1,14}$/,
                  message: "Phone Number is invalid",
                },
              ]}
            >
              <DartInput
                editable={editable}
              />
            </Form.Item>
            <Form.Item
              name="role"
              label="DART Role"
              required
            >
              <DartSegmentedSelect
                disabled={!!userFormData?.role && userFormData.username === user.username}
                options={decideRoleOptions()}
              />
            </Form.Item>
            <CanAccess requireRoles={[SUPER_ADMIN]}>
              <Form.Item
                name="region"
                label="DART Region"
                required
              >
                <DartSegmentedSelect options={decideRegionOptions()} />
              </Form.Item>
            </CanAccess>
            <Form.Item
              name="job_designation"
              label="Job Designation"
              required
              rules={[
                {
                  required: true,
                  message: "Job Designation is required",
                },
                {
                  min: 2,
                  message: "Job Designation must be at least 2 characters",
                },
                {
                  max: 50,
                  message: "Job Designation must be at most 50 characters",
                },
              ]}
            >
              <DartInput
                editable={editable}
              />
            </Form.Item>
            { userFormData && (
              <Form.Item
                name="enabled"
                label="User Enabled"
                required
              >
                <DartSegmentedSelect
                  disabled={!!userFormData?.role && userFormData.username === user.username}
                  options={["true", "false"]}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UserForm;
