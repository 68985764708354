const decideUploadStatusColor = (status: string) => {
  switch (status) {
    case "INITIATED": return "lime";
    case "UPLOADED": return "lime";
    case "QUEUED": return "magenta";
    case "PROCESSING": return "blue";
    case "COMPLETED": return "green";
    case "COMPLETED_WITH_ERRORS": return "orange";
    case "FAILED": return "red";
    default: return "grey";
  }
};

export default decideUploadStatusColor;
