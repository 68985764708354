import moment from "moment";
import { selector } from "recoil";
import { allocationHistoryListAtom } from "../atoms";

export const allocationHistoryListSelector = selector({
  key: "allocation-history-list-selector",
  get: ({ get }) => {
    // Group by allocation history timestamp + author + channel + brand
    const atom = get(allocationHistoryListAtom);
    const groupedHistoryItems: any = {};
    for (let i = 0; i < atom.data.length; i += 1) {
      const historyItem = atom.data[i];
      const itemDate = moment(historyItem.created_at).format("YYYY-MM-DD-HH-mm-ss");
      const key = `${itemDate.substring(0, itemDate?.length - 1)}-${historyItem.user_id}-${historyItem.channel}-${historyItem.brand}`;
      if (groupedHistoryItems[key]) {
        groupedHistoryItems[key].push(historyItem);
      } else {
        groupedHistoryItems[key] = [historyItem];
      }
    }
    return { status: atom?.status, data: groupedHistoryItems };
  },
});
