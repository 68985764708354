// TODO#ENUMS
const countryList = [
  {
    key: "us",
    value: "US",
    label: "United States",
  },
  {
    key: "ca",
    value: "CA",
    label: "Canada",
  },
];

const brandNames = [
  {
    key: "ugg",
    value: "UGG",
    label: "UGG",
  },
  {
    key: "sanuk",
    value: "SANUK",
    label: "SANUK",
  },
  {
    key: "hoka",
    value: "HOKA",
    label: "HOKA",
  },
  {
    key: "ahnu",
    value: "AHNU",
    label: "AHNU",
  },
];

const reviewViewByTypes = [
  {
    key: "cc",
    value: "cc",
    label: "CCs",
  },
  {
    key: "store",
    value: "store",
    label: "Store",
  },
];

const ccGroupByTypes = [
  {
    key: "none",
    value: "none",
    label: "None",
  },
  {
    key: "division",
    value: "division",
    label: "Division",
  },
  {
    key: "department",
    value: "department",
    label: "Department",
  },
  {
    key: "class",
    value: "class",
    label: "Class",
  },
];

const storeGroupByTypes = [
  {
    key: "none",
    value: "none",
    label: "None",
  },
  {
    key: "tier",
    value: "tier",
    label: "Tier",
  },
];

const messages = {
  dashboard_select_filters_to_continue: "Please select brand and channel to proceed",
  dashboard_select_filters_with_months_to_continue: "Please select brand, channel and months to proceed",
  dashboard_select_filters_less_to_continue: "Please select brand and channel to proceed",
  will_be_copied_from_sister_store: "Will be copied from sister store",
};

const divisionNames = {
  MEN: "MEN",
  WOMEN: "WOMEN",
  KID: "KID",
  HOME_CARE: "HOME_CARE",
};

const departmentNames = {
  FOOTWEAR: "FOOTWEAR",
  APPARELS: "APPARELS",
  ACCESSORIES: "ACCESSORIES",
  HOME_CARE: "HOME_CARE",
  COLD_WEATHER: "COLD_WEATHER",
};

const divisionDepartmentMapping = {
  WOMEN: ["FOOTWEAR", "APPARELS", "ACCESSORIES", "COLD_WEATHER"],
  MEN: ["FOOTWEAR", "APPARELS", "ACCESSORIES", "COLD_WEATHER"],
  KID: ["FOOTWEAR", "APPARELS", "ACCESSORIES", "COLD_WEATHER"],
  HOME_CARE: ["HOME_CARE"],
  NA: ["NA"],
};

const regions = {
  northamerica: "North America",
  europe: "EMEA",
  japan: "Japan",
  china: "China",
};

const orderTypes = {
  cross_dock: "cross_dock",
  regular: "regular",
};

const channelNames = [
  {
    key: "outlet",
    value: "OUTLET",
    label: "Outlet",
  },
  {
    key: "concept",
    value: "CONCEPT",
    label: "Concept",
  },
  {
    key: "flagship",
    value: "FLAGSHIP",
    label: "Flagship",
  },
];

export {
  countryList,
  brandNames,
  channelNames,
  divisionNames,
  departmentNames,
  reviewViewByTypes,
  ccGroupByTypes,
  storeGroupByTypes,
  messages,
  divisionDepartmentMapping,
  regions,
  orderTypes,
};
