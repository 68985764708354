const distributeSendMoreToSizes = (total: number, sizesToDistribute: any[]) => {
  let localSizesToDistribute = [...(sizesToDistribute || [])];
  let rest = [];
  if (total < localSizesToDistribute?.length) {
    localSizesToDistribute = [...(sizesToDistribute || [])]?.sort((a:any, b: any) => b.size_contribution - a.size_contribution)?.slice(0, total);
    rest = [...(sizesToDistribute || [])]
      ?.sort((a:any, b: any) => a.size_contribution - b.size_contribution)
      ?.slice(total, sizesToDistribute?.length);
  }
  const items = [];
  for (let i = 0; i < localSizesToDistribute.length; i += 1) {
    const v = total * localSizesToDistribute[i]?.size_contribution;
    let remains;
    let sendMore;
    if (v >= 1) {
      remains = v - Math.floor(v);
      sendMore = Math.floor(v);
    } else {
      sendMore = 1;
      remains = 0;
    }
    items.push({
      ...localSizesToDistribute[i],
      remains,
      sendMore,
      index: i,
    });
  }

  let s = items.reduce((a, v) => a + v.sendMore, 0);
  items.sort((a, b) => a.sendMore - b.sendMore);
  let index = items.length - 1;
  while (s > total) {
    items[index].sendMore -= 1;
    index -= 1;
    if (index < 0 || items[index].sendMore <= 1) {
      index = items.length - 1;
    }
    s -= 1;
  }

  if (items.reduce((a, v) => Math.min(a, v.sendMore), 1e9) === 0) {
    return false;
  }

  items.sort((a, b) => (a.remains - b.remains));

  index = items.length - 1;
  if (items[index]) {
    while (s < total) {
      items[index].sendMore += 1;
      items[index].remains = 0;
      index -= 1;
      if (index < 0 || items[index].sendMore <= 1) {
        index = items.length - 1;
      }
      s += 1;
    }
  }

  items.sort((a, b) => a.index - b.index);
  return [...items, ...rest?.map((restEach: any) => {
    return {
      ...restEach,
      sendMore: 0,
    };
  })];
};

export default distributeSendMoreToSizes;
