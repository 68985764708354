/* eslint-disable no-param-reassign,jsx-a11y/no-noninteractive-element-interactions */
import moment from "moment";
import React from "react";
import { Tooltip } from "antd";
import EditableCell from "../../../../components/EditableCell";
import Icon from "../../../../components/Icon";
import { IAllocationQuantityCC } from "../../../../global/interfaces";
import decideDivisionName from "../../../../utils/helpers/decideDivisionName";
import decideDepartmentName from "../../../../utils/helpers/decideDepartmentName";
import CompactSelect from "../../../../components/CompactSelect";
import { renderTag } from "../../../../utils/helpers";
import DartDatepicker from "../../../../components/DartDatepicker";

const columns = (props: {
  month?: string;
  editable?: boolean;
  isFrozenLeft?: boolean;
  showDepartmentAndDivision?: boolean;
  onUASChange?: (newness_id:number, value: any, month?: string) => void;
  onSupplyByDateChange?: (newness_id:number, value: any, month?: string) => void;
  onStyleClick?: (rowData?: IAllocationQuantityCC) => void;
  onPOAChange?: (newness_id:number, value: any, month?: string) => void;
  poaClasses?: any[];
  onCommentsOpen?: (cc: IAllocationQuantityCC) => void;
}): Record<string, any>[] => [
  {
    key: "cc",
    dataIndex: "cc",
    title: "Style Color",
    width: 155,
    align: "left",
    frozen: "left",
    className: "alloc-q-cell white-background",
    ...(props.isFrozenLeft ? { frozen: "left", className: "alloc-q-fixed-cell" } : {}),
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      const DEFAULT_UAS = 1;
      return (
        <div className="cc-with-comment">
          <span
            className={(rowData?.uas || 0) > 0 ? "underline" : ""}
            onClick={() => {
              if ((rowData?.uas || 0) > 0) {
                props.onStyleClick?.({
                  ...rowData,
                  uas: +(rowData.uas || DEFAULT_UAS),
                  updated_uas: +(rowData.updated_uas || rowData.uas || DEFAULT_UAS),
                });
              }
            }}
          >
            {rowData?.cc || "-"}
          </span>
          <span onClick={() => props.onCommentsOpen?.(rowData)}>
            <Icon
              name="Messages"
              size={18}
            />
          </span>
        </div>
      );
    },
  },
  {
    key: "description",
    dataIndex: "description",
    title: "Description",
    width: 220,
    align: "center",
    className: "alloc-q-cell",
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      return rowData?.description || "-";
    },
  },
  {
    key: "division",
    dataIndex: "division",
    title: "Division",
    width: 110,
    align: "center",
    className: "alloc-q-cell",
    hidden: !props.showDepartmentAndDivision,
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      return decideDivisionName(rowData?.division || "", "-");
    },
  },
  {
    key: "department",
    dataIndex: "department",
    title: "Dept",
    width: 140,
    align: "center",
    className: "alloc-q-cell",
    hidden: !props.showDepartmentAndDivision,
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      return decideDepartmentName(
        rowData?.department, false, "-",
      );
    },
  },
  {
    key: "class",
    dataIndex: "class",
    title: "Class",
    width: 130,
    align: "center",
    className: "alloc-q-cell",
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      return rowData?.class || rowData?.default_class || "-";
    },
  },
  {
    key: "tag",
    dataIndex: "tag",
    title: "Tag",
    width: 130,
    align: "center",
    className: "alloc-q-cell",
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      return rowData?.tag ? renderTag(rowData?.tag) : "-";
    },
  },
  {
    key: "gtm_collection",
    dataIndex: "gtm_collection",
    title: "Collection",
    width: 170,
    align: "center",
    className: "alloc-q-cell",
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      return rowData?.gtm_collection || "-";
    },
  },
  {
    key: "tier",
    dataIndex: "tier",
    title: "Tier",
    width: 50,
    align: "center",
    className: "alloc-q-cell",
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      const decideTierText = (tier?: string) => {
        switch (tier) {
          case "SPECIAL":
          case "CUSTOM":
            return "S";
          case "TIER_1": return "1";
          case "TIER_2": return "2";
          case "TIER_3": return "3";
          case "TIER_4": return "4";
          default: return "N/A";
        }
      };

      return decideTierText((rowData?.tier || "-")?.toString());
    },
  },
  {
    key: "stores",
    dataIndex: "stores",
    title: "Stores",
    width: 80,
    align: "center",
    className: "alloc-q-cell",
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      return rowData?.tns || "-";
    },
  },
  {
    key: "buy",
    dataIndex: "buy",
    title: "Buy",
    width: 70,
    align: "center",
    className: "alloc-q-cell",
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      return rowData?.buy || "-";
    },
  },
  {
    key: "supply_by_date",
    dataIndex: "supply_by_date",
    title: "Supply by Date",
    width: 130,
    align: "center",
    className: "alloc-q-cell datepicker-cell with-interaction white-background",
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      const isUpdated = rowData?.supply_by_date !== rowData?.updated_supply_by_date;

      const decideValue = rowData?.updated_supply_by_date || rowData?.supply_by_date;

      return (
        <div className={`alloc-q-editable-cell
          ${isUpdated ? "updated" : ""}
          ${rowData?.selected && rowData?.isOthersSelected ? "selected" : ""}`}
        >
          <DartDatepicker
            value={decideValue ? moment(decideValue) : undefined}
            onChange={(value) => props?.onSupplyByDateChange?.(
              rowData.newness_id, value, props?.month,
            )}
            placeholder="-"
            pickerProps={{
              allowClear: false,
              getPopupContainer: () => document.body,
            }}
          />
          {rowData?.selected && rowData?.isOthersSelected ? (
            <Icon
              className="linked-uas-values"
              tooltip="Linked to other selected Supply by Date values"
              tooltipPlacement="left"
              name="Link"
            />
          ) : ""}
        </div>
      );
    },
  },
  {
    key: "inventory_by_warehouse",
    dataIndex: "inventory_by_warehouse",
    title: "Av. Inventory per DC",
    width: 170,
    align: "center",
    className: "alloc-q-cell",
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      const formatter = Intl.NumberFormat("en", { notation: "standard" });

      const values = Object.values(rowData?.inventoryData?.per_warehouse || {});

      return (
        values.length > 2 ? (
          <div className="inventory-data-wrapper with-tooltip">
            <Tooltip
              placement="bottom"
              getTooltipContainer={() => document.body}
              overlay={(
                <div>
                  {values.map((value: any) => (
                    <div
                      key={value?.warehouse_id}
                      style={{ padding: "4px 12px" }}
                    >
                      <b>
                        {value?.warehouse_id}
                        {": "}
                        {formatter.format(value?.qty || 0)}
                      </b>
                    </div>
                  ))}
                </div>
              )}
            >
              <span>
                {formatter.format(rowData?.inventoryData?.totals?.qty || 0)}
              </span>
              <Icon
                name="Info"
                size={18}
              />
            </Tooltip>
          </div>
        ) : (
          <div className={`inventory-data-wrapper ${values?.length > 1 ? "multiple" : ""}`}>
            {values?.length > 0 ? (
              values.map((value: any) => (
                <div className="value-item">
                  <span>{value?.warehouse_id}</span>
                  <span>
                    {formatter.format(value?.qty || 0)}
                  </span>
                </div>
              ))
            ) : "-"}
          </div>
        )
      );
    },
  },
  {
    key: "initial_qty",
    dataIndex: "initial_qty",
    title: "Initial Qty",
    width: 100,
    align: "center",
    className: "alloc-q-cell",
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      return rowData?.iaq || rowData?.iaq === 0 ? rowData?.iaq : "-";
    },
  },
  {
    key: "uas",
    dataIndex: "uas",
    title: "UAS",
    width: 100,
    align: "center",
    className: "alloc-q-cell with-interaction white-background",
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      const isFlagged = false;
      const isUpdated = (!!rowData?.updated_uas || rowData?.updated_uas === 0)
        && rowData?.uas?.toString()?.replace(".00", "") !== rowData?.updated_uas?.toString()?.replace(".00", "");
      const initialUAS = rowData?.uas?.toString()?.replace(".00", "");

      return (
        <div className={`alloc-q-editable-cell
          ${isFlagged ? "flagged" : ""}
          ${isUpdated ? "updated" : ""}
          ${rowData?.selected && rowData?.isOthersSelected ? "selected" : ""}`}
        >
          <EditableCell
            editable
            cellData={Math.round(rowData?.updated_uas || rowData?.updated_uas === 0 ? rowData?.updated_uas : (rowData?.uas || 0))}
            allowSameValueSubmit={false}
            min={0}
            max={999999999}
            cancelOnBlur
            hasButtons={false}
            viewModeTooltip={isUpdated ? `Initial Value: ${initialUAS}` : undefined}
            onOk={(v) => props.onUASChange?.(
              rowData.newness_id, v, props?.month,
            )}
            emptyText={!rowData?.uas && rowData?.uas !== 0 && !rowData?.updated_uas && rowData?.updated_uas !== 0
              ? "EDIT"
              : Math.round(rowData?.updated_uas || rowData?.updated_uas === 0 ? rowData?.updated_uas : (rowData?.uas || 0)).toString()}
            allowDecimals={false}
          />
          {rowData?.selected && rowData?.isOthersSelected ? (
            <Icon
              className="linked-uas-values"
              tooltip="Linked to other selected UAS values"
              tooltipPlacement="left"
              name="Link"
            />
          ) : ""}
        </div>
      );
    },
  },
  {
    key: "newness_date",
    dataIndex: "newness_date",
    title: "Final ISD",
    width: 130,
    align: "center",
    className: "alloc-q-cell",
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      const date = rowData?.newness_date
        // ? moment(rowData?.newness_date).startOf("month").format("MM/DD/YYYY")
        ? moment(rowData?.newness_date).format("MM/DD/YYYY")
        : undefined;

      return (
        <div style={{ display: "flex", columnGap: 8 }}>
          {date || "-"}
          {" "}
          <Icon
            name="Date"
            size={20}
            color="#333E47"
          />
        </div>
      );
    },
  },
  {
    key: "allocation_status",
    dataIndex: "allocation_status",
    title: "Allocation Status",
    width: 180,
    align: "center",
    className: "alloc-q-cell",
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      return rowData?.allocated_store_count ? `Allocated to ${rowData?.allocated_store_count} stores` : "Not Allocated";
    },
  },
  {
    key: "map_break",
    dataIndex: "map_break",
    title: "Map Break",
    width: 145,
    align: "center",
    className: "alloc-q-cell",
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      // check if it contains two dashes, if yes, it's a date otherwise it's a string
      const isDate = rowData?.map_break?.indexOf("-") !== rowData?.map_break?.lastIndexOf("-");

      // eslint-disable-next-line no-nested-ternary
      const mapBreakShow = isDate && rowData?.map_break
        ? (
          moment(rowData?.map_break).isAfter(moment("1 January 1901"))
            ? moment(rowData?.map_break).format("MM/DD/YYYY")
            : "Map Protected"
        )
        : "None";

      return (
        <div style={{
          display: "flex", columnGap: 8, justifyContent: "center",
        }}
        >
          {mapBreakShow}
          {" "}
          <Icon
            name={isDate ? "Date" : "Lock"}
            size={20}
            color="#333E47"
          />
        </div>
      );
    },
  },
  {
    key: "poa",
    dataIndex: "poa",
    title: "POA",
    width: 150,
    align: "center",
    className: "alloc-q-cell white-background with-interaction",
    hidden: (process.env.REACT_APP_BASE_URL?.indexOf(".dev.") === -1 && process.env.REACT_APP_BASE_URL?.indexOf(".qa.") === -1) // @TODO: temp check to remove from prod
      || props?.poaClasses?.length === 0,
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      if ((process.env.REACT_APP_BASE_URL?.indexOf(".dev.") === -1 && process.env.REACT_APP_BASE_URL?.indexOf(".qa.") === -1) // @TODO: temp check to remove from prod
        || props?.poaClasses?.length === 0) return (<span>-</span>);

      const selectedValue = rowData?.updated_poa_class?.id
        ? { value: rowData?.updated_poa_class?.id?.toString(), label: rowData?.updated_poa_class?.name }
        : { value: rowData?.poa_class?.id?.toString(), label: rowData?.poa_class?.name };

      const isUpdated = rowData?.updated_poa_class?.id !== rowData?.poa_class?.id;

      return (
        <div className={`alloc-q-editable-cell ${isUpdated ? "updated" : ""}`}>
          <CompactSelect
            defaultValue={selectedValue}
            selectOptions={props.poaClasses?.map((poaClass) => ({
              value: poaClass.id?.toString(),
              label: poaClass.name,
            }))}
            onChange={(value) => {
              const selectedPoaClass = props.poaClasses?.find((e: {id: any, name: string}) => {
                return e.id?.toString() === value?.toString();
              });

              props.onPOAChange?.(
                rowData?.newness_id || 0, selectedPoaClass, props.month,
              );
            }}
            popupContainer={document.getElementById("alloc-q-table") || document.body}
            hasCustomSearch
            canClear={false}
            placeholder="Select POA"
          />
        </div>
      );
    },
  },
  {
    key: "size_profile",
    dataIndex: "size_profile",
    title: "Size Profile",
    width: 180,
    align: "center",
    className: "alloc-q-cell",
    cellRenderer: ({ rowData }: {
      rowData: IAllocationQuantityCC;
    }) => {
      return rowData?.size_profile || rowData?.size_run || "-";
    },
  },
];

export default columns;
