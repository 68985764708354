import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import "./index.less";
import {
  useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState,
} from "recoil";
import {
  Button, Empty, Pagination, Spin,
} from "antd";
import { useHistory } from "react-router-dom";
import {
  getSizeProfile, getSizeRunList, SizeRunCollapse,
} from "../..";
import DartTable from "../../../../../components/DartTable";
import columns from "./columns";
import EditableCell from "../../../../../components/EditableCell";
import {
  selectedSizeRunAtom,
  selectedStoreAtom,
  sizeProfileAtom,
  sizeProfileChannelAtom,
  sizeProfileEditingAtom,
  sizeRunExpandedListAtom,
  sizeRunListAtom,
  sizeRunSelectedListAtom,
  storeListAtom,
} from "../../../../../global/atoms";
import { sizeProfileSelector, sizeRunListSelector } from "../../../../../global/selectors";
import DartPrompt from "../../../../../components/DartPrompt";
import { updateStore } from "../../../../store";
import LineImage from "../../../../../assets/images/lines.png";
import decideDivisionName from "../../../../../utils/helpers/decideDivisionName";
import paths from "../../../../../configs/paths";
import { mergeObjectIntoArray } from "../../../../../utils/helpers";
import { storeListFiltersAtom } from "../../../../../global/atoms/store-list-filters-atom";
import { ISizeRun } from "../../../../../global/interfaces";
import { deleteSizeRun } from "../../services/sizeRun";

interface ISizeRunOverview {
  editable?: boolean;
  setActionDisabled?: (disabled?: boolean) => void;
  varianceAllowed?: string;
  filteredBySearchValue?: string;
}

const SizeRunOverview: React.FC<ISizeRunOverview> = ({
  editable,
  setActionDisabled,
  varianceAllowed,
  filteredBySearchValue,
}) => {
  const history = useHistory();
  const setSizeRunList = useSetRecoilState<any>(sizeRunListAtom);
  const setSelectedSizeRun = useSetRecoilState<any>(selectedSizeRunAtom);
  const sizeRunList = useRecoilValue<any>(sizeRunListSelector);
  const setSizeProfiles = useSetRecoilState<any>(sizeProfileAtom);
  const sizeProfiles = useRecoilValue<any>(sizeProfileSelector);
  const [expandedList, setExpandedList] = useRecoilState<number[]>(sizeRunExpandedListAtom);
  const [checkedList, setCheckedList] = useRecoilState<number[]>(sizeRunSelectedListAtom);
  const setSizeProfileEditing = useSetRecoilState<{ sizeRun: any, sizeProfile: any }>(sizeProfileEditingAtom);
  const [sisterStoreDeleteId, setSisterStoreDeleteId] = useState<number | undefined>(undefined);
  const [sizeRunDeleteEntity, setSizeRunDeleteEntity] = useState<ISizeRun | undefined>(undefined);
  const setSelectedStore = useSetRecoilState<any>(selectedStoreAtom);
  const [storeList, setStoreList] = useRecoilState<any>(storeListAtom);
  const [deleteSSLoading, setDeleteSSLoading] = useState(false);
  const [deleteSizeRunLoading, setDeleteSizeRunLoading] = useState(false);
  const sizeProfileChannel = useRecoilValue(sizeProfileChannelAtom);
  const resetStoreFilters = useResetRecoilState(storeListFiltersAtom);

  const PER_PAGE_VIEW = 10;
  const [paginationData, setPaginationData] = useState<any>({});

  useEffect(() => {
    if (sizeRunList?.data?.length) {
      const pagination:any = {};
      // For each division - set current page
      for (let i = 0; i < sizeRunList?.data?.length; i += 1) {
        pagination[sizeRunList?.data[i]?.key] = 1;
      }
      setPaginationData(pagination);
    }
    // eslint-disable-next-line
  }, [filteredBySearchValue]);

  useEffect(() => {
    setSelectedSizeRun(undefined);
    getSizeRunList(
      sizeRunList, setSizeRunList, sizeProfileChannel,
    );
  }, [sizeProfileChannel]);

  useEffect(() => {
    setExpandedList([]);
  }, [sizeProfileChannel]);

  const handleExpand = (sizeRunId: number, sizeRunAll: string) => {
    if (expandedList.includes(sizeRunId)) {
      setExpandedList(expandedList.filter((item) => item !== sizeRunId));
    } else {
      getSizeProfile(
        sizeProfiles, setSizeProfiles, sizeRunId, sizeRunAll, sizeProfileChannel,
      );
      setExpandedList([...expandedList, sizeRunId]);
    }
  };

  const handleCheck = (sizeRun: any, sizeProfileDataValues?: any) => {
    // when checked, check if size profile has any contribution less than or more than 100%, if yes block the button
    if (sizeProfileDataValues) {
      setActionDisabled?.(false);
      checkForFlaggedRows(sizeProfileDataValues);
    }

    if (checkedList.includes(sizeRun?.id)) {
      setCheckedList((prev) => prev.filter((item) => item !== sizeRun?.id));
    } else {
      getSizeProfile(
        sizeProfiles, setSizeProfiles, sizeRun?.id, sizeRun?.size_run_all, sizeProfileChannel,
      );
      setCheckedList((prev) => [...prev, sizeRun?.id]);
    }
  };

  const getColumns = useMemo(() => {
    return columns({
      handleEditStoreClick: (storeId: any) => {
        resetStoreFilters();
        setSelectedStore(storeId);
        history.push(`/stores/${storeId}/edit`);
      },
      handleRemoveSisterStoreClick: (storeId: any) => setSisterStoreDeleteId(storeId),
      editable,
      channelStoreList: sizeRunList.channelStoreList,
    });
  }, [varianceAllowed, sizeRunList.channelStoreList]);

  const realValue = (value?: number) => {
    if (value === undefined) {
      return 0;
    }
    return Math.round((value + Number.EPSILON) * 100) / 10000;
  };

  const checkForFlaggedRows = (sizeRun?: any) => {
    // Check if all stores have size profile data summed up to 100, if not disable the button
    if (sizeRun.some((v: any) => v?.totalContribution !== "OFF" && Math.round((v?.totalContribution) / 100) !== 100 && checkedList.includes(v.size_run_id))) {
      setActionDisabled?.(true);
      return true;
    }
    return false;
  };

  useEffect(() => {
    let shouldBeDisabled = false;
    checkedList.forEach((sizeRunId) => {
      const values = Object.values(sizeProfiles[sizeRunId]?.data || {});
      if (values.some((s: any) => s.totalContribution !== "OFF" && Math.round((s?.totalContribution) / 100) !== 100)) {
        shouldBeDisabled = true;
      }
    });
    setActionDisabled?.(shouldBeDisabled);
  }, [checkedList]);

  const appendDynamicColumns = (cols: any, profiles: any[]) => {
    const newColumns = [...cols];
    const sizeRunAll = (profiles as any)?.[0]?.size_run_all?.split(",");
    const isSingleSize = sizeRunAll?.length === 1;
    for (let i = 0; i < sizeRunAll?.length; i += 1) {
      const columnName = sizeRunAll?.[i];

      newColumns.push({
        key: columnName,
        dataIndex: columnName,
        title: columnName,
        width: isSingleSize ? 500 : 100,
        flexGrow: isSingleSize ? 5 : 1,
        align: "center",
        className: `size-profile-editable-cell ${isSingleSize || !editable ? "disable-editing" : ""}`,
        cellRenderer: ({ rowData }: { rowData: any }) => {
          const currentField = rowData?.sizes.find((s: { size: string }) => s.size === columnName);

          const isValidValue = (val: any) => {
            return !(val === "" || val === undefined);
          };

          const displayValueOrEmpty = () => {
            if (!currentField) {
              return (
                <div className="size-run-overview-table-cell-empty">
                  <img
                    src={LineImage}
                    width={136}
                    height={50}
                    alt="N/A"
                  />
                </div>
              );
            }

            return (currentField?.updated || currentField?.updated === 0) || (currentField?.contribution || currentField?.contribution === 0)
              ? `${realValue(currentField?.updated || currentField?.contribution)}%`
              : undefined;
          };

          const isFlagged = isValidValue(currentField?.contribution) && Math.abs(realValue(currentField?.contribution) - realValue(currentField?.updated))
            > parseInt(varianceAllowed || "0%".replace("%", ""), 10);

          const isUpdated = realValue(currentField?.updated) !== realValue(currentField?.contribution);

          const updateValue = (v: string) => {
            setActionDisabled?.(false);
            setSizeProfiles((prev: any) => {
              // Deep clone the state. Modify matched size for store and size run and keep it saved in local storage
              const cloneState = JSON.parse(JSON.stringify(prev));
              const sizeRunProfileState = cloneState[rowData.size_run_id];
              const clonedSizeProfile = sizeRunProfileState?.data?.[rowData?.store_id];
              let matchedSizeObj = clonedSizeProfile?.sizes?.find((sizeData: any) => sizeData.size === columnName);
              sizeRunProfileState.touched = true; // for prompt

              // Creating new entry
              if (!clonedSizeProfile) {
                sizeRunProfileState.data[rowData?.store_id] = rowData;
                matchedSizeObj = rowData?.sizes?.find((sizeData: any) => sizeData.size === columnName);
              }

              if (!matchedSizeObj) {
                sizeRunProfileState.data[rowData?.store_id]?.sizes?.push({
                  id: null,
                  size: columnName,
                  contribution: "",
                  updated: v !== "" ? +v * 100 : "",
                });

                // Sum of contribution % for all sizes
                sizeRunProfileState.data[rowData?.store_id].totalContribution = sizeRunProfileState.data[rowData?.store_id]?.sizes
                  ?.reduce((acc: number, sizeData: any) => acc + (sizeData?.updated || 0), 0);

                if (Math.round((sizeRunProfileState.data[rowData?.store_id]?.totalContribution) / 100) !== 100) {
                  setActionDisabled?.(true);
                }
              } else {
                matchedSizeObj.updated = v !== "" ? +v * 100 : "";
                const shouldRemoveContrib = clonedSizeProfile?.sizes?.every((s: any) => s.updated === "");
                // Sum of contribution % for all sizes
                clonedSizeProfile.totalContribution = shouldRemoveContrib
                  ? "OFF"
                  : clonedSizeProfile?.sizes?.reduce((acc: number, sizeData: any) => acc + (sizeData.updated || 0), 0);

                if (clonedSizeProfile?.totalContribution !== "OFF"
                  && Math.round((clonedSizeProfile?.totalContribution) / 100) !== 100
                  && !shouldRemoveContrib) {
                  setActionDisabled?.(true);
                }
              }

              return cloneState;
            });
            if (!checkedList.includes(profiles[i]?.size_run_id)) {
              setCheckedList([...checkedList, profiles[i]?.size_run_id]);
            }
          };

          const decideCellDataValue = () => {
            if (!isValidValue(currentField?.updated) && !isValidValue(currentField?.contribution)) return "";
            if (!isValidValue(currentField?.contribution) && isValidValue(currentField?.updated)) return realValue(currentField?.updated);
            return realValue(isUpdated ? currentField?.updated : currentField?.contribution);
          };

          return (
            <div className={`size-run-overview-table-cell ${isFlagged ? "flagged" : ""} ${isUpdated ? "updated" : ""}`}>
              {editable ? (
                <EditableCell
                  editable={editable}
                  cellData={decideCellDataValue()}
                  hasButtons={false}
                  allowSameValueSubmit
                  onOk={updateValue}
                  viewModeTooltip={isUpdated ? `Initial Value: ${realValue(currentField?.contribution) || "N/A "}%` : undefined}
                  valueSuffix={!isValidValue(currentField?.updated) ? "" : "%"}
                  min={0}
                  max={100}
                  cancelOnBlur
                  allowEmpty={!isValidValue(currentField?.contribution)}
                />
              )
                : displayValueOrEmpty()}
            </div>
          );
        },
      });
    }

    return newColumns;
  };

  const handleRemoveSisterStore = async () => {
    setDeleteSSLoading(true);
    await updateStore(
      { id: sisterStoreDeleteId, sister_store_id: null }, storeList, setStoreList,
    );
    setDeleteSSLoading(false);
    setSisterStoreDeleteId(undefined);
  };

  const handleDeleteSizeRun = async () => {
    setDeleteSizeRunLoading(true);
    await deleteSizeRun(
      { id: sizeRunDeleteEntity?.id }, sizeRunList, setSizeRunList,
    );
    setDeleteSizeRunLoading(false);
    setSizeRunDeleteEntity(undefined);
  };

  const handleMaximizeSizeRun = async (sizeRun: any, existingSizeProfile: any) => {
    if (!existingSizeProfile) {
      await getSizeProfile(
        sizeProfiles, setSizeProfiles, sizeRun?.id, sizeRun?.size_run_all, sizeProfileChannel,
      );
    }
    navigateToMaximizedScreen(sizeRun, sizeProfiles?.[sizeRun?.id]);
  };

  const navigateToMaximizedScreen = useCallback((sizeRun: any, sizeProfile: any) => {
    const mergedValues = mergeObjectIntoArray(
      sizeRunList?.channelStoreList,
      sizeProfile?.data,
      "id",
      "store",
      "store_id",
      {
        size_run_id: sizeRun.id,
        size_run_all: sizeRun.size_run_all,
        sizes: [],
        totalContribution: "OFF",
      },
    );
    setSizeProfileEditing({ sizeRun, sizeProfile: { status: sizeProfile?.status, data: mergedValues } });
    history.push(paths.size_profile_edit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeProfiles]);

  const handleEditSizeRun = useCallback((sizeRunId: number) => {
    setSelectedSizeRun(sizeRunId);
    history.push(paths.edit_size_run.replace(":id", sizeRunId.toString()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="size-run-overview-widget"
      key={varianceAllowed + Object.keys(sizeProfiles || {}).length?.toString()}
    >
      <DartPrompt
        title="Unassign Sister Store?"
        content={(
          <div>This store will no longer receive new size profile updates from it’s sister store.</div>
        )}
        okText="Unassign Sister Store"
        cancelText="Cancel"
        visible={!!sisterStoreDeleteId}
        okButtonProps={{ loading: storeList.status === "request" || deleteSSLoading }}
        onCancel={() => setSisterStoreDeleteId(undefined)}
        onOk={() => handleRemoveSisterStore()}
      />
      <DartPrompt
        title="Delete Size Run?"
        content={(
          <div>
            {`Size run "${sizeRunDeleteEntity?.name}" will be deleted from system. This action can not be reverted.`}
          </div>
        )}
        okText="Yes, delete size run"
        cancelText="Cancel"
        visible={!!sizeRunDeleteEntity}
        okButtonProps={{ loading: deleteSizeRunLoading }}
        onCancel={() => setSizeRunDeleteEntity(undefined)}
        onOk={() => handleDeleteSizeRun()}
      />
      {sizeRunList?.status === "request"
        ? (
          <Spin
            size="large"
            style={{
              position: "absolute", left: 0, right: 0, marginTop: 64,
            }}
          />
        )
        : (Object.values(sizeRunList?.data || {}))?.map((sizeRunDivision: any, i: number) => {
          const sizeRunListData = sizeRunDivision?.data || [];
          const division = sizeRunDivision?.key;

          // All size runs per division (include all departments)
          const sizeRunFlatPerDivision = sizeRunListData?.map((sizeRuns: any) => sizeRuns?.data)?.flat(1);

          // Check if any is selected, if yes, display "deselect all" button
          const anyFromListIsChecked = (sizeRunFlatPerDivision || [])?.some((sizeRunObject: any) => {
            return checkedList.includes(sizeRunObject?.id);
          });

          // Function that handles select all / deselect all in group
          const handleGroupCheck = (checked: boolean) => {
            const groupToAffect: any = [];
            for (let k = 0; k < sizeRunFlatPerDivision?.length; k += 1) {
              const sizeRun = sizeRunFlatPerDivision[k];
              const isAlreadySelected = checkedList.includes(sizeRun?.id);
              if ((checked && !isAlreadySelected)) {
                groupToAffect.push({ id: sizeRun?.id, ...sizeRun });
              } else if ((!checked && isAlreadySelected)) {
                groupToAffect.push({ id: sizeRun?.id, ...sizeRun });
              }
            }

            // select or unselect from list
            for (let k = 0; k < groupToAffect?.length; k += 1) {
              const current = groupToAffect[k];
              handleCheck(current);
            }
          };

          // For pagination of the data
          const sliceStart = ((paginationData?.[division] || 1) - 1) * PER_PAGE_VIEW;
          const sliceEnd = sliceStart + PER_PAGE_VIEW;

          const filteredBySearchSizeRuns = filteredBySearchValue
            ? (sizeRunFlatPerDivision || [])
              ?.filter((e: ISizeRun) => e.name && e.name?.toLowerCase()?.indexOf(filteredBySearchValue?.toLowerCase()) > -1)
            : (sizeRunFlatPerDivision || []);

          return (
            <div
              key={i.toString()}
              className="size-run-overview-single"
            >
              <div className="top">
                <div className="left">
                  <div className="title">
                    {decideDivisionName(division)}
                  </div>
                  {editable && (
                    <div className="toggle-all">
                      <Button onClick={() => handleGroupCheck(!anyFromListIsChecked)}>
                        {anyFromListIsChecked ? "Deselect All" : "Select All"}
                      </Button>
                    </div>
                  )}
                </div>
                <div className="extra">
                  <Pagination
                    simple
                    current={paginationData?.[division] || 1}
                    defaultCurrent={paginationData?.[division] || 1}
                    total={filteredBySearchSizeRuns?.length}
                    pageSize={PER_PAGE_VIEW}
                    onChange={(page) => setPaginationData({ ...paginationData, [division]: page })}
                    size="small"
                    hideOnSinglePage
                  />
                  <span>
                    {filteredBySearchValue && `Searched ${filteredBySearchSizeRuns?.length} from `}
                    {sizeRunFlatPerDivision?.length === 1
                      ? "1 Size Run"
                      : `${sizeRunFlatPerDivision?.length} Size Runs`}
                  </span>
                </div>
              </div>
              <div className="bottom">
                {
                  (filteredBySearchSizeRuns || [])?.length > 0
                    ? (filteredBySearchSizeRuns || [])?.slice(sliceStart, sliceEnd)?.map((sizeRun: any) => {
                      const existingSizeProfile = sizeProfiles?.[sizeRun?.id] || {};

                      // Used in Table (this is store list + size profile values intercepted with each other
                      const tableValues = mergeObjectIntoArray(
                        sizeRunList?.channelStoreList,
                        existingSizeProfile?.data,
                        "id",
                        "store",
                        "store_id",
                        {
                          size_run_id: sizeRun.id,
                          size_run_all: sizeRun.size_run_all,
                          sizes: [],
                          totalContribution: "OFF",
                        },
                      );

                      // Sort tableValues by store name
                      const sortedTableValues = (Object.values(tableValues || {}) || [])
                        ?.sort((a: any, b: any) => {
                          if (a.store?.name > b.store?.name) return 1;
                          if (b.store?.name > a.store?.name) return -1;
                          return 0;
                        });

                      const hasErrors = checkForFlaggedRows(sortedTableValues);

                      return (
                        <SizeRunCollapse
                          key={sizeRun?.id}
                          loading={existingSizeProfile?.status === "request"}
                          disabled={sizeRunList?.status === "request" || sizeRunList?.status === "revalidate"}
                          sizeRun={sizeRun}
                          hasError={hasErrors}
                          onSizeRunEdit={(sR) => { handleEditSizeRun(sR.id); }}
                          {...(editable ? { onMaximizeClick: () => handleMaximizeSizeRun(sizeRun, existingSizeProfile) } : {})}
                          expanded={
                          expandedList.indexOf(sizeRun?.id) > -1
                        }
                          onExpandToggle={() => handleExpand(sizeRun?.id, sizeRun?.size_run_all)}
                          {...(editable
                            ? {
                              onCheckboxToggle: () => handleCheck(sizeRun, sortedTableValues),
                              checked: checkedList.indexOf(sizeRun?.id) > -1,
                            }
                            : {}
                        )}
                          expandedContent={existingSizeProfile?.status !== "request" ? (
                            <DartTable
                              tableStyle="dark"
                              rowKey="id"
                              width="100%"
                              data={sortedTableValues || []}
                              height={184}
                              checkboxSize="sm"
                              columns={appendDynamicColumns(getColumns, sortedTableValues)}
                              fixed
                              emptyRenderer={() => (
                                <div style={{ padding: "48px calc(50% - 64px)" }}>
                                  Size Profile is
                                  empty
                                </div>
                              )}
                            />
                          ) : (
                            <Spin
                              size="default"
                              style={{
                                position: "absolute", left: 0, right: 0, marginTop: 64,
                              }}
                            />
                          )}
                        />
                      );
                    })
                    : <Empty description={`No matching size run for "${filteredBySearchValue}" was found`} />
                }
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SizeRunOverview;
