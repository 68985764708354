import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import "./index.less";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { Button, Spin } from "antd";
import columns from "./columns";
import {
  poaChannelAtom, poaExpandedListAtom, poaSelectedListAtom, poaValuesAtom,
} from "../../../../../global/atoms";
import decideDivisionName from "../../../../../utils/helpers/decideDivisionName";
import { IPoaEntry, IPoaValue } from "../../../../../global/interfaces";
import { mergeObjectIntoArray } from "../../../../../utils/helpers";
import DartPrompt from "../../../../../components/DartPrompt";
import DartButton from "../../../../../components/DartButton";
import {
  GroupedPoaCollapse, groupPOAsWithDepartmentDivision, renderPOATableHeader,
} from "../../index";
import DartTable from "../../../../../components/DartTable";
import { getUpdatablePOAs, importPOAs } from "../../services/poa";
import { poaListSelector } from "../../../../../global/selectors/poa-list-selector";
import EditableCell from "../../../../../components/EditableCell";
import Icon from "../../../../../components/Icon";
import { IChannelTypes } from "../../../../../global/atoms/global-filters-atom";

interface IPoaImportWidget {
  visible?: boolean;
  setVisible?: any;
  setActionDisabled?: (disabled?: boolean) => void;
}

const PoaImportWidget: React.FC<IPoaImportWidget> = ({
  visible, setVisible, setActionDisabled,
}) => {
  const poaList = useRecoilValue<any>(poaListSelector);
  const resetPoaValues = useResetRecoilState(poaValuesAtom);
  const [updatablePOAs, setUpdatablePOAs] = useState<any>({});
  const [expandedList, setExpandedList] = useState<string[]>([]);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const poaChannel = useRecoilValue(poaChannelAtom);
  const [shouldSeeClasses, setShouldSeeClasses] = useState(true);
  const [shouldSeeStyles, setShouldSeeStyles] = useState(true);
  const [shouldSeeCustom, setShouldSeeCustom] = useState(true);
  const resetMainExpandedList = useResetRecoilState(poaExpandedListAtom);
  const resetMainSelectedList = useResetRecoilState(poaSelectedListAtom);
  const [loading, setLoading] = useState(false);
  const [savePromptVisible, setSavePromptVisible] = useState(false);

  useEffect(() => {
    getUpdatablePOAs(
      updatablePOAs, setUpdatablePOAs, poaChannel,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poaChannel]);

  useEffect(() => {
    setExpandedList([]);
  }, [poaChannel]);

  const handleExpand = (department: string, division: string) => {
    if (expandedList.includes(`${department}_${division}`)) {
      setExpandedList(expandedList.filter((item) => item !== `${department}_${division}`));
    } else {
      setExpandedList([...expandedList, `${department}_${division}`]);
    }
  };

  const handleCheck = (
    department: string, division: string, poaDataEntries?: IPoaEntry[],
  ) => {
    if (poaDataEntries) {
      setActionDisabled?.(false);
    }

    if (checkedList.includes(`${department}_${division}`)) {
      setCheckedList((prev) => prev.filter((item) => item !== `${department}_${division}`));
    } else {
      setCheckedList((prev) => [...prev, `${department}_${division}`]);
    }
  };

  // Check selected departments and divisions, prepare values to be sent to BE
  const handleImport = useCallback(async () => {
    setSavePromptVisible(false);
    setLoading(true);
    // Based on store_id, push correct POA data into the array to send to BE
    const dataToSend = updatablePOAs?.rawData
      ?.filter((poa: IPoaValue) => {
        const key = `${poa?.poa_category?.department || "CUSTOM"}_${poa?.poa_category?.division || "OTHER"}`;
        return checkedList.includes(key);
      })
      ?.map((poa: IPoaValue) => {
        return {
          store_id: poa?.store_id,
          poa_category_id: poa?.poa_category_id,
          poa_qty: poa?.updated || poa?.poa_qty,
        };
      });

    // Send data to BE
    await importPOAs(dataToSend, () => {
      setLoading(false);
      getUpdatablePOAs(
        updatablePOAs, setUpdatablePOAs, poaChannel,
      );
      setVisible(false);
      setExpandedList([]);
      setCheckedList([]);
      setShouldSeeClasses(true);
      setShouldSeeStyles(true);
      setShouldSeeCustom(true);

      // update parent selected/expanded lists
      resetPoaValues();
      resetMainExpandedList();
      resetMainSelectedList();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList, updatablePOAs]);

  const handleCancel = () => {
    getUpdatablePOAs(
      updatablePOAs, setUpdatablePOAs, poaChannel,
    );
    setVisible(false);
    setExpandedList([]);
    setCheckedList([]);
    setShouldSeeClasses(true);
    setShouldSeeStyles(true);
    setShouldSeeCustom(true);
  };

  const getColumns = useMemo(() => {
    return columns({
      channelStoreList: poaList.channelStoreList,
    });
  }, [poaList.channelStoreList]);

  // Function that runs on modal open that re-calculates all POA values and ignores the ones sent from backend
  const calculateInitialPOAValues = () => {
    const doneKeys: string[] = [];
    for (let i = 0; i < updatablePOAs?.rawData?.length; i += 1) {
      const current = updatablePOAs?.rawData[i];
      const key = `pid.${current?.poa_category_id}_channel.${current?.store?.channel}_brand.${current?.store?.brand}`;
      if (doneKeys.indexOf(key) === -1) {
        // recalculate POA values for the key and override original poa_qty and poa_total values
        onPOAClassTotalUpdate(
          current?.store_id,
          current?.poa_category_id,
          current?.poa_total,
          current?.store?.channel,
          true,
        );
        doneKeys.push(key);
      }
    }
  };

  // Calculate initial POA values because it comes wrong from backend
  useEffect(() => {
    if (visible) {
      calculateInitialPOAValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onPOAClassTotalUpdate = (
    store_id: number, poa_category_id: number, poa_total: string, poa_channel: IChannelTypes, shouldOverrideOriginalValues?: boolean,
  ) => {
    // Recalculate poa_average, poa_totals and poa_qty based on poa_total value
    setUpdatablePOAs((prev: any) => {
      const storeBrand = poaList.channelStoreList?.find((e: any) => e?.id === store_id)?.brand;
      const storeMatchingCriteria = poaList.channelStoreList?.filter((e: any) => e?.channel === poa_channel && e?.brand === storeBrand);

      const rawData = [...prev?.rawData];

      const newFilteredPOAs = rawData
        ?.filter((e: IPoaValue) => e.poa_category_id === poa_category_id && e.store.channel === poa_channel && e.store?.brand === storeBrand);

      // if store doesn't exist in the list, add it
      if (!rawData?.find((e: IPoaValue) => e?.store_id === store_id && e.poa_category_id === poa_category_id && e.store.channel === poa_channel)) {
        const obj = {
          store_id,
          store: storeMatchingCriteria?.find((e: any) => e?.id === store_id),
          poa_category_id,
          poa_category: prev?.rawData?.find((e: IPoaValue) => e?.poa_category_id === poa_category_id)?.poa_category,
          poa_qty: "-1",
          poa_total: -1,
          updated: "0",
          updated_poa_total: 0,
        };
        rawData.push(obj);
        newFilteredPOAs.push(obj);
      }

      // if store exists, and we set empty string as value, it means we are removing the value (must only work for the ones that never had a value)
      if (poa_total === "") {
        const cellToRemove = rawData
          ?.find((e: IPoaValue) => e?.store_id === store_id
            && e.poa_category_id === poa_category_id
            && e.store.channel === poa_channel
            && e.store.brand === storeBrand);
        if (cellToRemove) {
          // if value never existed, remove it from the list
          if (cellToRemove?.poa_qty === "-1" && cellToRemove?.poa_total === -1) {
            rawData.splice(rawData.indexOf(cellToRemove), 1);
          }
        }
      }

      const newTotal = newFilteredPOAs?.reduce((acc: number, curr: IPoaValue) => acc + (
        curr?.store_id === store_id
          ? Number(poa_total || 0)
          : Number(curr?.updated_poa_total || curr?.updated_poa_total === 0 ? curr?.updated_poa_total : curr?.poa_total)
      ), 0);

      const newAverage = newTotal / storeMatchingCriteria?.length;

      // Generate new raw data as if it was coming from BE
      const newRawData = rawData?.map((poa: IPoaValue) => {
        if (poa?.poa_category_id === poa_category_id && poa?.store?.channel === poa_channel && poa.store?.brand === storeBrand) {
          const totalIfStoreDontMatch = Number(poa?.updated_poa_total || poa?.updated_poa_total === 0 ? poa?.updated_poa_total : poa?.poa_total);
          const totalIfStoreMatch = Number(poa_total || 0);
          const updatedTotal = Number(poa?.store_id === store_id ? totalIfStoreMatch : totalIfStoreDontMatch);
          return {
            ...poa,
            ...(shouldOverrideOriginalValues ? {
              poa_qty: newTotal === 0 ? "0" : Number((parseFloat((updatedTotal / newAverage).toString())).toFixed(2))?.toString(),
              poa_total: newTotal === 0 ? 0 : updatedTotal,
            } : {}),
            updated_poa_total: newTotal === 0 ? 0 : updatedTotal,
            updated: newTotal === 0 ? "0" : Number((parseFloat((updatedTotal / newAverage).toString())).toFixed(2))?.toString(),
          };
        }
        return poa;
      });

      // Return updated state with groupData updated from newRawData. Also update rawData without changing state status
      return {
        ...prev,
        groupData: groupPOAsWithDepartmentDivision(newRawData, shouldOverrideOriginalValues),
        rawData: newRawData,
      };
    });
  };

  const appendDynamicColumns = (cols: any, poaDynamicColumns: IPoaEntry[]) => {
    const newColumns = [...cols];
    const columnsToAdd = [
      ...(shouldSeeClasses ? poaDynamicColumns?.filter((e) => e.is_class && !e.is_custom) : []),
      ...(shouldSeeStyles ? poaDynamicColumns?.filter((e) => !e.is_class && !e.is_custom) : []),
      ...(shouldSeeCustom ? poaDynamicColumns?.filter((e) => e.is_custom) : []),
    ];

    for (let i = 0; i < columnsToAdd?.length; i += 1) {
      const columnName = columnsToAdd?.[i].name;
      const columnId = +columnsToAdd?.[i].id;
      const isClassColumn = columnsToAdd?.[i].is_class && !columnsToAdd?.[i].is_custom;
      const isStyleColumn = !columnsToAdd?.[i].is_class && !columnsToAdd?.[i].is_custom;
      const isCustomColumn = columnsToAdd?.[i].is_custom;

      newColumns.push({
        key: `${columnId.toString()}_${columnName}`,
        dataIndex: columnName,
        title: columnName.length > 30 ? `${columnName.substring(0, 30)}...` : columnName,
        width: 136,
        flexGrow: 1,
        align: "center",
        // eslint-disable-next-line max-len
        className: `poa-import-widget-editable-cell ${isClassColumn ? "class" : ""} ${isCustomColumn ? "custom" : ""} ${isStyleColumn ? "style" : ""}`,
        cellRenderer: ({ rowData }: { rowData: any }) => {
          const currentField = rowData?.poa_values.find((s: IPoaValue) => (s.poa_category_id === columnId));
          const isUpdated = currentField?.updated && Number(currentField?.updated).toFixed(2) !== Number(currentField?.poa_qty).toFixed(2);

          const displayValueOrEmpty = () => {
            return (
              <div>
                {currentField?.is_sister_store && <span className="is-sister-store"><Icon name="Branch" /></span>}
                <EditableCell
                  cellData={currentField?.updated_poa_total}
                  onOk={(total) => onPOAClassTotalUpdate?.(
                    rowData?.store_id,
                    columnId,
                    total,
                    rowData?.store?.channel,
                  )}
                  min={0}
                  cancelOnBlur
                  allowDecimals={false}
                  hasButtons={false}
                  allowSameValueSubmit
                  allowEmpty={currentField?.poa_qty === "-1" && currentField?.poa_total === -1}
                />
                {currentField?.updated_poa_total
                || currentField?.updated_poa_total === 0
                || currentField?.poa_total
                || currentField?.poa_total === 0 ? (
                  <div className="poa-value">
                    <small>POA: </small>
                    {isUpdated ? currentField?.updated : currentField?.poa_qty}
                  </div>
                  ) : ""}
              </div>
            );
          };

          return (
            <div className={`poa-import-widget-table-cell ${isUpdated ? "updated" : ""}`}>
              {displayValueOrEmpty()}
            </div>
          );
        },
      });
    }

    return newColumns;
  };

  const modalContent = () => {
    return (poaList?.data || [])?.map((poaDivision: any, i: number) => {
      const poaListData = poaDivision?.data || [];
      const division = poaDivision?.key;

      // If no update in division, skip
      if (!updatablePOAs?.groupData?.hasOwnProperty(division)) return "";

      // Check if any is selected, if yes, display "deselect all" button
      const anyFromListIsChecked = (poaListData || [])?.some((poa: any) => {
        const keyToTest = `${poa?.key}_${division}`;
        return checkedList.includes(keyToTest);
      });

      // Function that handles select all / deselect all in group
      const handleGroupCheck = (checked: boolean) => {
        const groupToAffect: any = [];
        for (let k = 0; k < poaListData.length; k += 1) {
          const department = poaListData[k]?.key;
          const keyToToggle = `${department}_${division}`;
          const isAlreadySelected = checkedList.includes(keyToToggle);

          if (updatablePOAs?.groupData?.[division]?.hasOwnProperty(department)) {
            if ((checked && !isAlreadySelected)) {
              groupToAffect.push({
                key: keyToToggle, department, division,
              });
            } else if ((!checked && isAlreadySelected)) {
              groupToAffect.push({
                key: keyToToggle, department, division,
              });
            }
          }
        }

        // select or unselect from list
        for (let k = 0; k < groupToAffect?.length; k += 1) {
          const current = groupToAffect[k];
          handleCheck(
            current?.department, current?.division, [],
          );
        }
      };

      return (
        <div
          key={i.toString()}
          className="poa-import-widget-single"
        >
          <div className="top">
            <div className="left">
              <div className="title">
                {decideDivisionName(division)}
              </div>
              <div className="toggle-all">
                <Button onClick={() => handleGroupCheck(!anyFromListIsChecked)}>
                  {anyFromListIsChecked ? "Deselect All" : "Select All"}
                </Button>
              </div>
            </div>
          </div>
          <div className="bottom">
            {(poaListData || [])?.length > 0
              ? ((poaListData || []) as any)?.map((poaListItem: { key: string, data: IPoaEntry[] }, o: number) => {
                const poaParentKey = poaListItem.key;

                // If no update in department, skip
                if (!updatablePOAs?.groupData?.[division]?.hasOwnProperty(poaParentKey)) return "";

                const poaDataValues = (poaListItem?.data || []).filter((p: any) => {
                  return (Object.values(updatablePOAs?.groupData?.[division]?.[poaParentKey]?.data) || [])?.some((store: any) => {
                    return store?.poa_values?.some((v: any) => v?.poa_category_id === p?.id);
                  });
                });

                const classCount = poaDataValues?.filter((p) => p.is_class).length;
                const styleCount = poaDataValues?.filter((p) => !p.is_class).length;

                const fetchedData = updatablePOAs?.groupData?.[division]?.[poaParentKey] || {};

                // Used in Table (this is store list + poa values intercepted with each other
                const tableValues = mergeObjectIntoArray(
                  poaList?.channelStoreList,
                  fetchedData?.data,
                  "id",
                  "store",
                  "store_id",
                  { poa_key: `${poaParentKey}_${division}`, poa_values: [] },
                );

                // Sort tableValues by store name
                const sortedTableValues = (Object.values(tableValues || {}) || [])
                  ?.sort((a: any, b: any) => {
                    if (a.store?.name > b.store?.name) return 1;
                    if (b.store?.name > a.store?.name) return -1;
                    return 0;
                  });

                return (
                  <GroupedPoaCollapse
                    key={`${o}_${poaParentKey}_${division}`}
                    loading={fetchedData?.status === "request"}
                    poaEntry={{
                      ...poaDataValues?.[0], classCount, styleCount,
                    }}
                    expanded={
                      expandedList.indexOf(`${poaParentKey}_${division}`) > -1
                    }
                    onExpandToggle={() => handleExpand(poaParentKey, division)}
                    onCheckboxToggle={() => handleCheck(
                      poaParentKey, division, poaDataValues,
                    )}
                    checked={checkedList.indexOf(`${poaParentKey}_${division}`) > -1}
                    expandedContent={fetchedData?.status !== "request" ? (
                      <DartTable
                        tableStyle="dark"
                        rowKey="store_id"
                        width="100%"
                        data={sortedTableValues}
                        height={534}
                        checkboxSize="sm"
                        columns={appendDynamicColumns(getColumns, poaDataValues)}
                        fixed
                        emptyRenderer={() => <div style={{ padding: "48px calc(50% - 64px)" }}>POA values are empty</div>}
                        headerHeight={[34, 34]}
                        headerRenderer={(p: any) => renderPOATableHeader(p, {
                          shouldSeeClasses,
                          shouldSeeStyles,
                          shouldSeeCustom,
                          setShouldSeeClasses,
                          setShouldSeeStyles,
                          setShouldSeeCustom,
                        })}
                      />
                    ) : (
                      <Spin
                        size="default"
                        style={{
                          position: "absolute", left: 0, right: 0, marginTop: 64,
                        }}
                      />
                    )}
                  />
                );
              }) : <></>}
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className="poa-import-widget"
      key={Object.keys(updatablePOAs?.groupData || {}).length?.toString()}
    >
      <DartButton
        icon="Plus"
        size="md"
        label={Object.keys(updatablePOAs?.groupData || {})?.length !== 0
        && updatablePOAs?.isSalesTransactionComplete === false ? "Import *" : "Import"}
        onClick={() => setVisible(true)}
        type="secondary"
        disabled={Object.keys(updatablePOAs?.groupData || {})?.length === 0}
        loading={updatablePOAs?.status === "request"}
        tooltip={updatablePOAs?.isSalesTransactionComplete === false ? "Sales data is not fully fetched yet" : undefined}
      />
      {visible && (
        <DartPrompt
          className="poa-import-widget-modal-container"
          icon="Flash"
          title="Import Ready to Update POA"
          visible={visible}
          onClose={handleCancel}
          onOk={() => setSavePromptVisible(true)}
          onCancel={handleCancel}
          okText="Import"
          okButtonProps={{ disabled: checkedList?.length === 0, loading }}
          cancelText="Cancel"
          width={1300}
          content={modalContent()}
          maskClosable={false}
        />
      )}
      <DartPrompt
        title="Are you sure ?"
        content={(
          <div>
            This will also change IAQ settings for CCs.
            <br />
            Are you sure you want to proceed?
          </div>
        )}
        okText="Proceed"
        cancelText="Cancel"
        visible={savePromptVisible}
        onCancel={() => setSavePromptVisible(false)}
        onOk={handleImport}
      />
    </div>
  );
};

export default PoaImportWidget;
