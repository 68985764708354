const decideStoreStatusColor = (status?: "LIVE" | "PLANNING" | "CLOSED" | "PAUSED") => {
  switch (status) {
    case "LIVE":
      return "#19945D";
    case "PLANNING":
      return "#5FC7CB";
    case "CLOSED":
      return "#FF6666";
    case "PAUSED":
      return "#FFDD00";
    default:
      return "black";
  }
};

export default decideStoreStatusColor;
