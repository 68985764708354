import React, { useEffect } from "react";
import {
  Route, Router, Switch,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { Layout, message } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useRecoilState, useRecoilValue } from "recoil";
import AuthGuard from "./guards/AuthGuard";
import { NotFoundRoute } from "./NotFoundRoute";
import MainRouter from "./MainRouter";
import SignIn from "../modules/auth/layouts/SignIn";
import { firstTimeLoginAtom, userAtom } from "../global/atoms";
import { getUser, SetPasswordLayout } from "../modules/auth";
import paths from "../configs/paths";
import { allocationProcessAtom } from "../global/atoms/allocation-process-atom";
import TopMenu from "../components/TopMenu";
import ForgotPasswordLayout from "../modules/auth/layouts/ForgotPasswordLayout";

const history = createBrowserHistory();

const AppRouter = () => {
  const allocationProcess = useRecoilValue(allocationProcessAtom);
  const [firstTimeLogin, setFirstTimeLogin] = useRecoilState(firstTimeLoginAtom);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [user, setUser] = useRecoilState(userAtom);
  // const { navigationPosition, setNavigationPosition } = useNavigationPosition();
  // const [init, setInit] = useState(false);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    getUser(user, setUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.data?.idToken?.jwtToken && window.location.href.indexOf("#loggedout") === -1) {
      if (firstTimeLogin) {
        setFirstTimeLogin(false);
        history.push(paths.newness_upload);
      }

      if (window.location.pathname === paths.signin) {
        history.push(paths.newness);
      }
      history.push(window.location.pathname + (window.location.search || ""));
    } else if (window.location.href.indexOf("#loggedout") === -1) {
      if (window.location.href.indexOf("set-password") === -1 && window.location.href.indexOf("forgot-password") === -1) {
        history.push(paths.signin);
      }
    } else {
      history.push(paths.signin);
      message.error("Invalid Session. You have been logged out.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, allocationProcess]);

  return (
    <Router history={history}>
      <Switch>
        <Route
          path={paths.signin}
          exact
        >
          <SignIn />
        </Route>
        <Route
          path={paths.set_password}
          exact
        >
          <SetPasswordLayout />
        </Route>
        <Route
          path={paths.forgot_password_with_email}
          exact
        >
          <ForgotPasswordLayout />
        </Route>
        <Route
          path={paths.forgot_password}
          exact
        >
          <ForgotPasswordLayout />
        </Route>
        <AuthGuard path="/">
          <Layout className="ant-layout">
            <div className="top-menu-wrapper">
              <TopMenu />
            </div>
            <Layout className="main-layout-content">
              <Content>
                <MainRouter />
              </Content>
            </Layout>
          </Layout>
        </AuthGuard>
        <NotFoundRoute />
      </Switch>
    </Router>
  );
};

export default AppRouter;
