const capitalize = (str = "") => {
  let temp = str;
  if (!temp) {
    temp = "";
  }
  temp = temp.toLowerCase()?.replace(/_/g, " ");
  return temp.replace(temp?.[0], temp?.[0]?.toUpperCase());
};

export default capitalize;
