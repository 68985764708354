import { message } from "antd";
import axios from "axios";
import apiClient from "../../../api/apiClient";
import { awsBaseUrl } from "../../../configs/awsconfig";
import endpoints from "../../../api/endpoints";
import { showErrorMessage } from "../../../utils/helpers";

const prepareUpload = async (
  doc_type: string, import_types: string[], file_type: string, setUploadStatus: any,
) => {
  try {
    const controller = new AbortController();
    setUploadStatus({ preUploadStatus: "request", controller });
    const res = await apiClient.post(
      awsBaseUrl + endpoints.uploadFIle, {
        doc_type,
        import_types,
        file_type,
      }, {
        signal: controller.signal,
      },
    );
    setUploadStatus({ preUploadStatus: "success", controller });
    return res;
  } catch (error: any) {
    setUploadStatus({ preUploadStatus: "failed", controller: undefined });
    if (error?.message === "canceled" || error === "e.request is undefined") {
      showErrorMessage(undefined, "Upload canceled");
    } else {
      showErrorMessage(error, "Failed to get upload link");
    }
    return {};
  }
};

type IProgressCB = ((p: any) => void) | undefined;

const uploadFile = async (
  file: any, uploadData: any, setUploadStatus: any, progressCB:IProgressCB = undefined,
) => {
  try {
    const controller = new AbortController();
    setUploadStatus({ uploadStatus: "request", controller });
    const { url, headers } = uploadData;
    const res = await axios.put(
      url, file, {
        headers,
        onUploadProgress: (p: any) => progressCB?.(p),
        signal: controller.signal,
      },
    );
    setUploadStatus({ uploadStatus: "success", controller });
    message.success("File uploaded successfully");
    return res;
  } catch (error: any) {
    setUploadStatus({ uploadStatus: "failed", controller: undefined });
    if (error?.message === "canceled" || error === "e.request is undefined") {
      showErrorMessage(undefined, "Upload canceled");
    } else {
      showErrorMessage(error, "Failed to upload file");
    }
    return {};
  }
};

const downloadFile = async (relativeUrl: string, isErrorFile: boolean) => {
  try {
    message.loading("Loading...", 1);
    await apiClient
      .get(relativeUrl, {
        headers: {
          "Content-Type": "application/json",
        },
        maxRedirects: 0,
      }).then((res:any) => {
        if (res.data.url) {
          window.open(res.data.url);
          message.success("Download started");
        } else {
          showErrorMessage(undefined, `Error while downloading ${isErrorFile ? "error log" : "file"}`);
        }
      });
    return true;
  } catch (error: any) {
    showErrorMessage(error, `Failed to download ${isErrorFile ? "error log" : "file"}`);
    return {};
  }
};

const getUploadList = async (
  uploadListData: any, setUploadListData: any, isRevalidate = false,
) => {
  try {
    setUploadListData({ status: isRevalidate ? "revalidate" : "request", data: uploadListData.data });
    const res:any = await apiClient.get(endpoints.uploadFIle);
    setUploadListData({ status: "success", data: res?.data?.data || [] });
  } catch (error: any) {
    if (!isRevalidate) {
      setUploadListData({ status: "failed", data: [] });
      showErrorMessage(error, "Failed to get upload file list");
    }
  }
};

const saveFlowDocFile = async (
  progress: any, setProgress: any, uploadData: any, isDryRun?: boolean, cb?: any,
) => {
  try {
    setProgress({ status: "request", data: {} });
    const res: any = await apiClient.post(endpoints.saveFlowDoc, {
      data: uploadData, dry_run: isDryRun,
    });
    const returnData = {
      status: "success",
      data: {
        message: res?.data?.message || "Success",
        cc_to_reset: res?.data?.data?.cc_to_reset || [],
        items_to_add: res?.data?.data?.items_to_add || [],
        items_to_update: res?.data?.data?.items_to_update || [],
        no_update_required: res?.data?.data?.no_update_required || [],
        database_result: res?.data?.data?.database_result || {},
        errors: res?.data?.data?.errors || {},
      },
    };
    setProgress(returnData);
    cb?.(returnData);
  } catch (error: any) {
    const returnData = {
      status: "failed",
      data: {
        message: "",
      },
    };
    setProgress(returnData);
    showErrorMessage(error, "Failed due to unknown reason");
    cb?.(returnData);
  }
};

export {
  prepareUpload,
  uploadFile,
  getUploadList,
  downloadFile,
  saveFlowDocFile,
};
