import { ISelectedCC } from "../../../global/interfaces";

const sortCCsByDivisionAndDescription = (dataEntries: any[]) => {
  return dataEntries.sort((a: ISelectedCC, b: ISelectedCC) => {
    // SORT BY DIVISION DESCENDING (as Woman > Man > Kid)
    if (a?.division && b?.division && a.division < b.division) {
      return 1;
    }
    if (a?.division && b?.division && a.division > b.division) {
      return -1;
    }
    // SORT BY DESCRIPTION ASCENDING
    if (a?.description && b?.description && a.description < b.description) {
      return -1;
    }
    if (a?.description && b?.description && a.description > b.description) {
      return 1;
    }
    return 0;
  });
};

export default sortCCsByDivisionAndDescription;
