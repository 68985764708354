import React, { useEffect, useState } from "react";
import "./index.less";
import { useRecoilState, useRecoilValue } from "recoil";
import { useHistory, useParams } from "react-router-dom";
import { globalFiltersAtom, manualReplenishmentAtom } from "../../../../global/atoms";
import PageTitle from "../../../../components/PageTitle";
import DartButton from "../../../../components/DartButton";
import PageSummary from "../../../../components/PageSummary";
import paths from "../../../../configs/paths";
import { IReplenishmentCC } from "../../../../global/interfaces";
import DartPrompt from "../../../../components/DartPrompt";
import {
  approveReplenishment, ManualReplenishmentReview, saveReplenishmentAsDraft,
} from "../..";

const ManualReplenishmentStepTwo = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const manualReplenishmentState = useRecoilValue(manualReplenishmentAtom);
  const [count, setCount] = useState(0);
  const [sendMoreCount, setSendMoreCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [draftSaveLoading, setDraftSaveLoading] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [globalFilters, setGlobalFilters] = useRecoilState(globalFiltersAtom);

  useEffect(() => {
    document.title = `DART - REVIEW ${manualReplenishmentState?.name}`;
    if (!globalFilters.brand || !globalFilters.channel) {
      setGlobalFilters((prev: any) => {
        return {
          ...prev, brand: manualReplenishmentState?.brand, channel: manualReplenishmentState?.channel,
        };
      });
    }
  }, [manualReplenishmentState?.name]);

  // Fetch data from BE on initial load and save it to state...
  useEffect(() => {
    if (parseInt(id, 10) && !manualReplenishmentState?.processId) {
      history.push(paths.manual_replenishment_step_one.replace(":id", id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Initial local state when recoil manualReplenishmentState state changes
  useEffect(() => {
    setCount(manualReplenishmentState?.ccs?.length || 0);
    setSendMoreCount(manualReplenishmentState?.ccs?.reduce((acc: any, cur: IReplenishmentCC) => {
      return acc + (cur?.sendMore || 0);
    }, 0));
  }, [manualReplenishmentState]);

  const goBack = () => {
    history.push(paths.manual_replenishment_step_one?.replace(":id", id));
  };

  // Ask user about confirmation on replenishment approval
  const confirmApproval = () => {
    setConfirmModalVisible(true);
  };

  // Save replenishment as draft
  const saveAsDraft = async () => {
    await saveReplenishmentAsDraft(
      manualReplenishmentState, setDraftSaveLoading, () => {
        history.push(paths.replenishment_dashboard);
      },
    );
  };

  // Save as draft (to have the latest data) and approve the replenishment
  const handleReplenishmentApprove = async () => {
    setLoading(true);
    await saveReplenishmentAsDraft(
      manualReplenishmentState, setDraftSaveLoading, async () => {
        await approveReplenishment(
          manualReplenishmentState, setLoading, () => {
            setConfirmModalVisible(false);
            history.push(paths.replenishment_dashboard);
          },
        );
      }, true,
    );
  };

  // Formatter to correctly display floats
  const formatter = Intl.NumberFormat("en", { notation: "standard" });

  return (
    <div className="manual-replenishment-step-two">
      <PageTitle
        title={loading ? "Loading..." : (manualReplenishmentState?.name || "Oops! Something went wrong")}
        rightNode={(
          <div className="replenishment-steps-wrapper">
            <div className="replenishment-step" />
            <div className={`replenishment-step active
            ${!loading && (manualReplenishmentState?.name === "" || !manualReplenishmentState?.name) ? "danger" : ""}`}
            />
          </div>
        )}
        onBack={goBack}
      />
      <div className="container-content">
        <div className="top">
          <div className="left">
            <PageSummary
              summaryContent={[{
                name: "Style-colors",
                value: count,
                flex: 12,
                loading,
              }, {
                name: "Send More Total",
                value: formatter.format(sendMoreCount || 0),
                flex: 12,
                loading,
              }]}
            />
          </div>
          <div className="right">
            <DartButton
              type="dark"
              label="SAVE AS DRAFT"
              loading={draftSaveLoading}
              disabled={loading || !count}
              onClick={saveAsDraft}
            />
            <DartButton
              type="primary"
              label="APPROVE REPLENISHMENT"
              loading={false}
              disabled={loading || !count}
              onClick={confirmApproval}
            />
            <DartPrompt
              title="APPROVE REPLENISHMENT"
              content={(
                <div>
                  Allocate your send more quantities.
                  <br />
                  This can’t be undone.
                </div>
              )}
              okText="Approve"
              cancelText="Cancel"
              visible={confirmModalVisible}
              okButtonProps={{ loading }}
              onCancel={() => setConfirmModalVisible(false)}
              onOk={handleReplenishmentApprove}
              icon="Info"
            />
          </div>
        </div>
        <hr />
        <div className="content">
          <ManualReplenishmentReview />
        </div>
      </div>
    </div>
  );
};

export default ManualReplenishmentStepTwo;
