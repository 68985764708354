import React, { useState } from "react";
import "./index.less";
import { Input } from "antd";
import { useDebouncedCallback } from "use-debounce";
import Icon, { IconType } from "../Icon";

interface IDartInput {
  className?: string;
  icon?: IconType | JSX.Element;
  label?: string;
  disabled?: boolean;
  editable?: boolean;
  value?: string;
  onChange?: (changeEvent: any) => void;
  onBlur?: (blurEvent: any) => void;
  description?: string;
  transparent?: boolean;
  placeholder?: string;
  action?: (value?: string) => void;
  actionButton?: JSX.Element;
  type?: "number" | "text" | "password";
  onKeyPress?: (e: any, v?: string, setValue?: (v: string) => void) => void;
  useDebounce?: boolean;
}

const DartInput: React.FC<IDartInput> = ({
  className,
  icon,
  label,
  disabled = false,
  editable = true,
  value,
  onChange,
  description,
  transparent = false,
  placeholder,
  action,
  actionButton,
  onBlur,
  type,
  onKeyPress,
  useDebounce,
}) => {
  const inputId = `f${(Math.random() * 10000).toString()}`;
  const [val, setVal] = useState(useDebounce ? value : "");

  const decideSuffix = () => {
    if (!!actionButton && !!action) {
      return (React.cloneElement(actionButton, { onClick: () => action?.(val) }));
    }
    if (!editable) {
      return (
        <Icon
          name="Lock"
          color="#000000"
          size={19}
        />
      );
    }
    return undefined;
  };

  const debouncedChange = useDebouncedCallback((e) => {
    onChange?.(e);
  }, 800);

  const handleChange = (e: any) => {
    setVal(e.target.value);
    if (useDebounce) {
      debouncedChange(e);
    } else {
      onChange?.(e);
    }
  };

  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      action?.(val);
    }
  };

  return (
    <div className={`dart-input ${transparent ? "transparent" : ""} ${className}`}>
      {label ? (
        <label
          htmlFor={inputId}
          className="dart-input-label"
        >
          {label}
        </label>
      ) : ""}
      <Input
        id={inputId}
        className={`dart-input-target ${!!actionButton && !!action ? "has-action" : ""}`}
        prefix={icon}
        value={useDebounce ? val : value}
        onChange={(v) => handleChange?.(v)}
        onKeyDown={(e) => handleEnter?.(e)}
        placeholder={placeholder}
        suffix={decideSuffix()}
        disabled={disabled || !editable}
        onBlur={onBlur}
        type={type}
        onKeyPress={(e) => onKeyPress?.(
          e, val, setVal,
        )}
      />
      {description ? (
        <div className="dart-input-description">
          {description}
        </div>
      ) : ""}
    </div>
  );
};

export default DartInput;
