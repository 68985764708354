const updateQueryHashParameter = (
  search: string, key: string, value: string, includeQuestionMark = true,
): string => {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, "i");
  // eslint-disable-next-line no-nested-ternary
  const separator = search.indexOf("?") !== -1 ? "&" : (includeQuestionMark ? "?" : "");

  if (!value || value === "") {
    return search.replace(re, "");
  }
  if (search.match(re)) {
    return search.replace(re, `$1${key}=${value}$2`);
  }

  return `${search + separator + key}=${value}`;
};

export default updateQueryHashParameter;
