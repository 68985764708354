/* eslint-disable */
const serializeParams = (params: any) => {
  const parts = [];
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const values = Array.isArray(params[key]) ? params[key] : [params[key]];
      for (const value of values) {
        parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  }
  return parts.join("&");
};

export default serializeParams;
