import React, { useEffect, useState } from "react";
import "./index.less";
import { Form, message } from "antd";
import { useRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import {
  selectedSizeRunAtom, sizeRunListAtom, sizeRunSingleDataAtom,
} from "../../../../../global/atoms";
import { ISizeRun } from "../../../../../global/interfaces";
import DartButton from "../../../../../components/DartButton";
import { SizeRunPageHeader } from "../../index";
import SizeRunForm from "../../blocks/SizeRunForm";
import { createSizeRun } from "../../services/sizeRun";
import paths from "../../../../../configs/paths";

interface IStoreProfileCreate {
  onBack: () => any;
}

const StoreProfileCreate: React.FC<IStoreProfileCreate> = ({
  onBack,
}) => {
  const [selectedSizeRun, setSelectedSizeRun] = useRecoilState(selectedSizeRunAtom);
  const [sizeRunCreateData, setSizeRunCreateData] = useRecoilState(sizeRunSingleDataAtom);
  const [sizeRunList, setSizeRunList] = useRecoilState(sizeRunListAtom);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [sizeRunData, setSizeRunData] = useState<ISizeRun>();
  const [sizeRunForm] = Form.useForm();

  useEffect(() => {
    setSelectedSizeRun(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    // check forms for errors
    let hasErrors = false;
    try {
      await sizeRunForm.validateFields();
    } catch (e) {
      hasErrors = true;
    }
    if (hasErrors) {
      message.error("Please check all fields are correct");
      return;
    }

    const sizeRunFormValues = sizeRunForm.getFieldsValue();

    if (!selectedSizeRun?.id && sizeRunList?.originalData?.find((item: any) => item.name === sizeRunFormValues.name)) {
      message.error("Size Run with specified name already exists");
      return;
    }

    const sizeRunAllArray = sizeRunFormValues.size_run_all?.split(",");

    // Create size run data object
    const finalData = {
      id: selectedSizeRun,
      division: sizeRunFormValues.division,
      department: sizeRunFormValues.department,
      size_category: sizeRunFormValues.department === "FOOTWEAR"
        ? sizeRunFormValues.size_category
        : undefined,
      size_run_type: sizeRunFormValues.size_run_type,
      size_run_all: sizeRunAllArray,
      size_run_min: sizeRunAllArray?.[0],
      size_run_max: sizeRunFormValues.size_run_all
        ? sizeRunAllArray?.[sizeRunAllArray?.length - 1]
        : undefined,
      name: sizeRunFormValues.name,
    };

    setSizeRunData({
      ...sizeRunData,
      ...finalData,
    });

    // Create size run request
    await createSizeRun(
      finalData,
      sizeRunList,
      setSizeRunList,
      setLoading,
      () => {
        history.push(paths.size_profile);
      },
    );
  };

  return (
    <div className="size-run-create">
      <SizeRunPageHeader
        onBack={onBack}
      />
      <SizeRunForm
        form={sizeRunForm}
      />
      <DartButton
        isSubmit
        loading={sizeRunCreateData.status === "request" || loading}
        disabled={sizeRunCreateData.status === "request" || loading}
        className="size-run-form-action"
        onClick={handleSubmit}
        label="Create"
        size="lg"
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default StoreProfileCreate;
