import moment from "moment";
import apiClient from "../../../api/apiClient";
import endpoints from "../../../api/endpoints";
import {
  only, serializeParams, showErrorMessage,
} from "../../../utils/helpers";

const getStoreTiersForCCs = async (
  storeTiers: any, setStoreTiers: any, filters: any, fulfilments: string[],
) => {
  try {
    setStoreTiers({ status: "request", data: storeTiers.data || {} });
    const months = filters?.months?.map((date: any) => moment(date, "MMM, YYYY").format("YYYY-MM-DD"));

    let res: any;
    try {
      // fetch with months and fulfilments and if API response is OK, proceed.
      res = await apiClient.get(endpoints.allocationStoreTiers, {
        params: {
          ...only(["brand", "channel"], filters),
          ...(months && { month: months }),
          ...(fulfilments && { fulfilments }),
        },
        paramsSerializer: (params) => serializeParams(params),
      });
    } catch (e) {
      // if there was an error, fetch without fulfilments and months
      res = await apiClient.get(endpoints.allocationStoreTiers, {
        params: {
          ...only(["brand", "channel"], filters),
        },
      });
    }

    const keys = Object.keys(res.data);
    let responseObj = {};
    for (let i = 0; i < keys.length; i += 1) {
      // for each cc, find cc stores and turn into object
      const ccStores = res.data[keys[i]];

      // for each special tier, group by cc
      const special = only(
        ["store_id", "store_name", "is_selected"], ccStores.filter((item: any) => item.store_tier === "Special"), true,
      );
        // for each tier, group by cc
      const tier1 = only(
        ["store_id", "store_name", "is_selected"], ccStores.filter((item: any) => item.store_tier === "Tier 1"), true,
      );
      const tier2 = only(
        ["store_id", "store_name", "is_selected"], ccStores.filter((item: any) => item.store_tier === "Tier 2"), true,
      );
      const tier3 = only(
        ["store_id", "store_name", "is_selected"], ccStores.filter((item: any) => item.store_tier === "Tier 3"), true,
      );
      const tier4 = only(
        ["store_id", "store_name", "is_selected"], ccStores.filter((item: any) => item.store_tier === "Tier 4"), true,
      );

      responseObj = {
        ...responseObj,
        [keys[i]]: {
          tier1: tier1 || [],
          tier2: tier2 || [],
          tier3: tier3 || [],
          tier4: tier4 || [],
          special: special || [],
        },
      };
    }
    setStoreTiers({ status: "success", data: responseObj || {} });
  } catch (error: any) {
    setStoreTiers({ status: "failed", data: storeTiers.data || {} });
    showErrorMessage(error, "Failed to get store tiers");
  }
};

export {
  getStoreTiersForCCs,
};
