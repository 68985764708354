/* eslint-disable */
import React, {useState} from "react";
import "./index.less";
import {Col, Form, Radio, Row,} from "antd";
import {ISelectOption, IStoreProfile} from "../../../../global/interfaces";
import DartInput from "../../../../components/DartInput";
import DartSelect from "../../../../components/DartSelect";
import Icon from "../../../../components/Icon";
import {storeFields} from "../../../../utils/validations/store.fields";
import DartPrompt from "../../../../components/DartPrompt";
import useEnums from "../../../../utils/hooks/useEnums";
import DartSegmentedSelect from "../../../../components/DartSegmentedSelect";
import countryCodeToName from "../../../../utils/helpers/countryCodeToName";
import {capitalize} from "../../../../utils/helpers";

interface IStoreProfileDetails {
  topForm: any;
  bottomForm: any;
  storeDetails?: IStoreProfile;
  editable?: boolean;
  availableSisterStores?: ISelectOption[];
  onSisterStoreSelect?: (sisterStoreId: number) => void;
}

const StoreProfileDetails:React.FC<IStoreProfileDetails> = ({
  topForm,
  bottomForm,
  storeDetails,
  editable = true,
  availableSisterStores = [],
  onSisterStoreSelect,
}) => {
  const { allocation_status, country, allocation_channel, brand } = useEnums();
  const [unlinkSisterStoreVisible, setUnlinkSisterStoreVisible] = useState<boolean>(false);
  const [unlinkSisterStoreModalVisible, setUnlinkSisterStoreModalVisible] = useState<boolean>(false);

  const countryMap = {
    USA: "United States",
    CANADA: "Canada",
  };

  const handleBottomFormFieldChange = (changedFields: any) => {
    if (changedFields?.[0]?.name?.[0] === "sister_store_id") {
      onSisterStoreSelect?.(changedFields?.[0]?.value);
    }
  };

  const handleUnlinkSisterStore = () => {
    bottomForm.setFieldsValue({ sister_store_id: null });
    setUnlinkSisterStoreModalVisible(false);
    setUnlinkSisterStoreVisible(false);
  };

  return (
    <div className="store-profile-details">
      <h2>STORE DETAILS</h2>
      <Form
        scrollToFirstError
        // layout={editable ? "vertical" : "inline"}
        layout="vertical"
        form={topForm}
        initialValues={{ brand: null, ...storeDetails }}
        className="store-profile-details-top-form"
      >
        {editable || storeDetails?.brand ? (
          <Form.Item
            name="brand"
            label="Brand"
            required
            rules={storeFields.brand}
            style={{ minWidth: 179 }}
          >
            <DartSegmentedSelect
              // options={editable ? ["UGG", "HOKA", "SANUK"] : [storeDetails?.brand || ""]}
              // disabled={!editable}
              options={Object.keys(brand)}
            />
          </Form.Item>
        ) : ""}
        {editable || storeDetails?.channel ? (
          <Form.Item
            name="channel"
            label="Channel"
            required
            rules={storeFields.channel}
          >
            <Radio.Group size="large">
              {/* {editable || storeDetails?.channel === allocation_channel.CONCEPT ? <Radio value={allocation_channel.CONCEPT}>Concept</Radio> : ""} */}
              {/* {editable || storeDetails?.channel === allocation_channel.OUTLET ? <Radio value={allocation_channel.OUTLET}>Outlet</Radio> : ""} */}
              {Object.keys(allocation_channel).map((key) => {
                return <Radio value={allocation_channel[key]}>{capitalize(key.toLowerCase())}</Radio>;
              })}
            </Radio.Group>
          </Form.Item>
        ) : ""}
      </Form>
      <hr className="splitter" />
      <Form
        scrollToFirstError
        form={bottomForm}
        initialValues={{
          ...storeDetails,
          sister_store_id: storeDetails?.sister_store?.id ? { label: storeDetails?.sister_store?.name, value: storeDetails?.sister_store?.id } : null,
        }}
        className="store-profile-details-bottom-form"
        onFieldsChange={(changedFields) => handleBottomFormFieldChange(changedFields)}
      >
        <Row gutter={[32, 32]}>
          <Col span={8}>
            <Form.Item
              name="number"
              rules={[
                ...storeFields.number,
                () => ({
                  validator(f, value) {
                    return ((value && value.toString().length === 5) || !value)
                      ? Promise.resolve()
                      : Promise.reject(new Error("Number must be 5 digits"));
                  },
                }),
              ]}
            >
              <DartInput
                label="Store No."
                description="(Leave blank to create a placeholder store)"
                // editable={editable || storeDetails?.status === allocation_status.PLANNING}
                // description={editable || storeDetails?.status === allocation_status.PLANNING ? "(Leave blank to create a placeholder store)" : undefined}
              />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              name="name"
              required
              rules={storeFields.name}
            >
              <DartInput
                label="Store Name"
                // editable={editable}
              />
            </Form.Item>
            <Form.Item
              name="country"
              required
              rules={storeFields.country}
            >
              <DartSelect
                label="Country"
                selectOptions={Object.values(country).map((item) => { return { label: countryCodeToName(item), value: item }; })}
                value={storeDetails?.country ? { value: storeDetails?.country, label: countryCodeToName(storeDetails?.country) } : undefined}
                hasBorder
                placeholder=""
                canClear={false}
              />
            </Form.Item>
            <Form.Item
              name="state"
              required
              rules={storeFields.state}
            >
              <DartInput
                label="State"
                // editable={editable}
              />
            </Form.Item>
            <Form.Item
              name="city"
              required
              rules={storeFields.city}
            >
              <DartInput
                label="City"
                // editable={editable}
              />
            </Form.Item>
            <Form.Item
              name="district_manager_name"
              rules={storeFields.district_manager_name}
            >
              <DartInput
                label="District (optional)"
              />
            </Form.Item>
            <div
              className="select-with-lock"
              onMouseEnter={() => setUnlinkSisterStoreVisible(true)}
              onMouseLeave={() => setUnlinkSisterStoreVisible(false)}
            >
              <Form.Item
                name="sister_store_id"
              >
                <DartSelect
                  label="Sister Store (optional)"
                  selectOptions={availableSisterStores}
                  hasBorder
                  disabled={!editable}
                  placeholder=""
                />
              </Form.Item>
              {!editable ? (
                <span className="select-disabled-lock">
                  {unlinkSisterStoreVisible && bottomForm.getFieldsValue().sister_store_id ? (
                    <Icon
                      name="Close"
                      size={19}
                      color="#000000"
                      style={{ cursor: "pointer" }}
                      onClick={() => setUnlinkSisterStoreModalVisible(true)}
                    />
                  ) : (
                    <Icon
                      name="Lock"
                      size={19}
                      color="#000000"
                    />
                  )}
                  <DartPrompt
                    title="Unassign Sister Store?"
                    content={(
                      <div>This store will no longer receive new size profile updates from it’s sister store.</div>
                    )}
                    okText="Unassign Sister Store"
                    cancelText="Cancel"
                    visible={unlinkSisterStoreModalVisible}
                    onCancel={() => setUnlinkSisterStoreModalVisible(false)}
                    onOk={handleUnlinkSisterStore}
                  />
                </span>
              ) : ""}
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default StoreProfileDetails;
