import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import {
  Alert, Button, Col, Form, Row, Skeleton,
} from "antd";
import moment, { Moment } from "moment";
import { useRecoilValue } from "recoil";
import { removeNullValues, repeat } from "../../../../utils/helpers";
import { SingleMonthBlock } from "../..";
import Icon from "../../../../components/Icon";
import { newnessListAtom } from "../../../../global/atoms";
import { INewnessRow } from "../../../../global/interfaces";
import { messages } from "../../../../configs/constants";

type ccType = {
  ccs: INewnessRow[];
  percent: number;
}

interface IFormattedData {
  completeCC: ccType;
  readyCC: ccType;
  notReadyCC: ccType;
}

interface INewnessByMonth {
  onMonthsSelect?: (selectedData: { month: string, data: IFormattedData }[]) => void;
  onSubmit?: (values: any) => void;
  filterData: any;
}

const NewnessByMonth:React.FC<INewnessByMonth> = ({
  onMonthsSelect, onSubmit, filterData,
}) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<any>({});
  const newnessList = useRecoilValue(newnessListAtom);
  const [selectedOffset, setSelectedOffset] = useState<number>(0);
  const [currentMonths, setCurrentMonths] = useState<Moment[]>([]);

  // Memoized value that if any required filter is missing, sets "canSelectNewness" as false.
  const canSelectNewness = useMemo(() => {
    return filterData.brand && filterData.channel && filterData.months?.length > 0;
  }, [filterData]);

  // Memoized Formatted list according to ISD of the CC (group by months of ISD)
  const formattedList = useMemo(() => {
    const dt = newnessList.data;
    const listByMonth: any = {};
    for (let i = 0; i < dt.length; i += 1) {
      const item = dt[i];
      const month = `${item?.isd?.substring(0, 7)}-01`;
      if (!listByMonth[month]) {
        listByMonth[month] = [];
      }

      listByMonth[month].push(item);
    }
    for (let i = 0; i < Object.keys(listByMonth).length; i += 1) {
      const key = Object.keys(listByMonth)[i];
      listByMonth[key] = sanitizeData(listByMonth[key], filterData);
    }

    const sortedListByMonth: any = {};

    Object.keys(listByMonth).sort().forEach((key) => {
      sortedListByMonth[key] = listByMonth[key];
    });

    return sortedListByMonth;
  }, [filterData, newnessList.data]);

  // Memoized value that decides to show the arrows of carousel selection or not. If more than 3 entities, show arrows.
  const isArrowsVisible = useMemo(() => {
    const keys = Object.keys(formattedList || {});
    return keys.length > 3;
  }, [formattedList]);

  // Decide which months need to be active based on the offset (to make newness month carousel work)
  useEffect(() => {
    const keys = Object.keys(formattedList);
    if (keys.length > 3) {
      setCurrentMonths(keys.slice(keys.length - (selectedOffset + 3), keys.length - selectedOffset).map((m) => moment(m)));
    } else {
      setCurrentMonths(keys.map((m) => moment(m)));
    }
  }, [formattedList, selectedOffset]);

  // Call onMonthsSelect function when one of the checkboxes are getting selected (in newness carousel)
  useEffect(() => {
    const selected = Object.keys(formData).filter((key) => formData[key]);
    const selectedWithData = selected.map((key) => ({
      month: key,
      data: formattedList[key],
    }));
    onMonthsSelect?.(selectedWithData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, formattedList]);

  const reRenderKey = useMemo(() => {
    return `${filterData.brand + filterData.channel + (filterData.months?.length || 0)
      + filterData.order_type + (filterData.shipping_date || "NA")}_${filterData.department?.length || 0}_${filterData.division?.length || 0}`;
  }, [filterData]);

  // Cleanup after any of the filters are changed
  useEffect(() => {
    setFormData({});
    form.resetFields();
    onMonthsSelect?.([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reRenderKey]);

  // Update form data with correct filters before sending request
  const handleFormUpdateBeforeRequest = (updatedField: any) => {
    setFormData((prev: any) => { return { ...prev, [updatedField?.[0]?.name?.[0]]: updatedField?.[0]?.value }; });
  };

  // Handler that calls "onSubmit" prop
  const handleSubmit = (values?: any) => {
    onSubmit?.(values);
  };

  // Handler that sets offset of the carousel items (newness months)
  const handleMonthChange = (type: "next" | "prev") => {
    if (type === "next") {
      if (selectedOffset > 0) {
        setSelectedOffset((prev) => prev - 1);
      }
    } else if (type === "prev") {
      if (selectedOffset < Object.keys(formattedList).length - 3) {
        setSelectedOffset((prev) => prev + 1);
      }
    }
  };

  return (
    <div className="newness-by-month">
      <Form
        form={form}
        onFinish={(values) => { setFormData(removeNullValues(values)); handleSubmit(values); }}
        onFieldsChange={handleFormUpdateBeforeRequest}
        preserve
      >
        {isArrowsVisible && (
          <div className="month-switcher">
            <Button
              disabled={selectedOffset >= Object.keys(formattedList).length - 3}
              ghost
              shape="circle"
              onClick={() => handleMonthChange("prev")}
              icon={(
                <Icon
                  name="DropdownArrow"
                  size={12}
                />
            )}
            />
            <Button
              disabled={selectedOffset <= 0}
              ghost
              shape="circle"
              onClick={() => handleMonthChange("next")}
              icon={(
                <Icon
                  name="DropdownArrow"
                  size={12}
                />
            )}
            />
          </div>
        )}
        {canSelectNewness ? (
          <Row gutter={[136, 40]}>
            {
            newnessList.status !== "request" ?
              currentMonths.map((month) => {
                return (
                  <SingleMonthBlock
                    key={month?.format("YYYY-MMM")}
                    currentMonth={month}
                    formattedData={formattedList[month?.format("YYYY-MM-DD")]}
                    canSelect={canSelectNewness}
                    isActive={formData[month?.format("YYYY-MM-DD")]}
                  />
                );
              })
              : repeat(SingleMonthBlockSkeleton(true), 3)
          }
          </Row>
        ) : (
          <Row gutter={[136, 40]}>
            <Col span={24}>
              <Alert
                icon={(
                  <Icon
                    name="Flash"
                    color="#E87324"
                    size={20}
                  />
                )}
                showIcon
                type="warning"
                description={messages.dashboard_select_filters_with_months_to_continue}
              />
            </Col>
            {repeat(SingleMonthBlockSkeleton(), 3)}
          </Row>
        )}
      </Form>
    </div>
  );
};

export default NewnessByMonth;

// Function that parses table data returned from BE. It's quite a big mess but not really hard to understand.
const sanitizeData = (monthData: any, filters: any) => {
  const filteredData = monthData?.filter((filterableDataItem: any) => {
    let shouldFilter = true;

    // if (filters?.department?.length > 0 && filters?.department?.includes("APPAREL|ACCESSORIES")) {
    //   if (filterableDataItem?.department?.toUpperCase()?.indexOf("APPAREL") === -1
    //     && filterableDataItem?.department?.toUpperCase()?.indexOf("ACCESSORIES") === -1
    //     && !filters?.department?.includes(filterableDataItem?.department?.toUpperCase())) {
    //     shouldFilter = false;
    //   }
    // } else
    // filter by departments
    if (filters?.department?.length > 0 && !filters?.department?.includes(filterableDataItem?.department?.toUpperCase())) {
      shouldFilter = false;
    }

    // filter by division
    if (filters?.division?.length > 0 && !filters?.division?.includes(filterableDataItem?.division?.toUpperCase())) {
      shouldFilter = false;
    }
    return shouldFilter;
  });

  // Group by "ready", "not-ready" and "completed" to calculate percentages
  const completeCC = filteredData?.filter((cc: INewnessRow) => !!cc?.allocation_process_id) || [];
  const readyCC = filteredData?.filter((cc: INewnessRow) => cc?.is_ready && !cc?.allocation_process_id) || [];
  const notReadyCC = filteredData?.filter((cc: INewnessRow) => !cc?.is_ready && !cc?.allocation_process_id) || [];

  // Total CC count to display
  const totalCount = completeCC.length + readyCC.length + notReadyCC.length;

  // Calculate percentages of "ready", "not-ready" and "completed" groups
  const completePercent = Math.round((completeCC.length * 100) / totalCount);
  const readyPercent = Math.round((readyCC.length * 100) / totalCount);
  const notReadyPercent = 100 - completePercent - readyPercent;

  return {
    completeCC: { ccs: completeCC, percent: completePercent },
    readyCC: { ccs: readyCC, percent: readyPercent },
    notReadyCC: { ccs: notReadyCC, percent: notReadyPercent },
  };
};

// Loader Skeleton for Month Block
const SingleMonthBlockSkeleton = (isActive?: boolean) => (
  <Col
    span="8"
    className="skeleton-wrapper-col"
  >
    <Skeleton.Input
      active={isActive}
      className="month"
    />
    <Skeleton.Input
      active={isActive}
      className="date"
    />
    <Skeleton.Input
      active={isActive}
      className="ccs"
    />
    <Skeleton.Input
      active={isActive}
      className="percent"
    />
    <Skeleton.Input
      active={isActive}
      className="progress"
    />
    <Skeleton.Input
      active={isActive}
      className="detail"
    />
    <Skeleton.Input
      active={isActive}
      className="detail"
    />
    <Skeleton.Input
      active={isActive}
      className="detail"
    />
  </Col>
);
