const numberToStringFloat = (value?: number) => { // 3485
  if (value === undefined) {
    return "0.0000";
  }
  if (value === 10000) {
    return "1.0000";
  }
  return (value / 10000).toFixed(4);
};

export default numberToStringFloat;
