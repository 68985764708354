import React, { useEffect, useState } from "react";
import {
  DatePicker, Select, Tag,
} from "antd";
import moment, { Moment } from "moment";
import "./index.less";
import { useDebouncedCallback } from "use-debounce";

interface IDartMultipleDatePicker {
  value?: string[];
  onChange?: (values?: string[]) => any;
  format?: string;
  selectProps?: any;
  datePickerProps?: any;
}

const DartMultipleDatePicker: React.FC<IDartMultipleDatePicker> = ({
  value: selectedDate = [],
  onChange,
  format = "YYYY-MM-DD",
  selectProps = {},
  datePickerProps = {},
}) => {
  const [localDates, setLocalDates] = useState(selectedDate);
  const [open, setOpen] = useState(false);
  const [resetFlag, setResetFlag] = useState(false);

  const debouncedChange = useDebouncedCallback((dates) => {
    onChange?.(dates);
  }, 1000);

  useEffect(() => {
    debouncedChange(localDates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localDates]);

  const onValueChange = (date: Moment) => {
    const t = date.format(format);
    const index = localDates.indexOf(t);
    const clone = [...localDates];
    if (index > -1) {
      clone.splice(index, 1);
    } else {
      clone.push(t);
    }
    setLocalDates(clone);
  };

  const dateRender = (currentDate: Moment) => {
    const isSelected = localDates.includes(currentDate.format(format));
    return (
      <div
        className={`ant-picker-cell-inner ${isSelected ? "selected active" : ""}`}
        onClick={() => onValueChange(currentDate)}
      >
        {currentDate.format("MMM")}
      </div>
    );
  };

  const renderTag = ({ value, onClose }: any) => {
    const handleClose = () => {
      onClose?.();
      setLocalDates(localDates.filter((t) => t !== value));
    };
    return (
      <Tag
        className="selected-date-tag"
        onClose={handleClose}
        closable
      >
        {moment(value, format).format(format)}
      </Tag>
    );
  };

  const handleReset = () => {
    setResetFlag((prev) => !prev);
    setOpen(false);
    setLocalDates([]);
  };

  return (
    <Select
      key={resetFlag.toString()}
      allowClear
      className="dart-multiple-datepicker"
      placeholder="Select dates"
      maxTagCount={1}
      maxTagPlaceholder={(omitted: string[]) => `+${omitted.length || " others"}`}
      {...selectProps}
      mode="multiple"
      value={localDates}
      onClear={handleReset}
      tagRender={renderTag}
      open={open}
      onFocus={() => setOpen(true)}
      onBlur={() => setOpen(false)}
      dropdownMatchSelectWidth={false}
      dropdownClassName="dart-multiple-datepicker-dropdown"
      dropdownStyle={{
        height: "300px", width: "280px", minWidth: "0",
      }}
      defaultOpen={false}
      showArrow
      dropdownRender={() => {
        return (
          <DatePicker
            key={resetFlag.toString()}
            {...datePickerProps}
            format={format}
            showToday={false}
            open
            monthCellRender={dateRender}
            style={{ ...(datePickerProps as any).style, visibility: "hidden" }}
            getPopupContainer={() =>
                document.getElementsByClassName("dart-multiple-datepicker-dropdown")[0] as HTMLElement}
          />
        );
      }}
    />
  );
};

export default DartMultipleDatePicker;
