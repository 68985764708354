import { Form } from "antd";
import React, { useEffect, useMemo } from "react";
import "./index.less";
import DartSegmentedSelect from "../../../../components/DartSegmentedSelect";
import { IAssortmentData } from "../../../../global/interfaces";
import { messages } from "../../../../configs/constants";
import useEnums from "../../../../utils/hooks/useEnums";
import { capitalize } from "../../../../utils/helpers";
import decideDepartmentName from "../../../../utils/helpers/decideDepartmentName";
import { storeFields } from "../../../../utils/validations/store.fields";
import DartDragSelect from "../../../../components/DartDragSelect";

interface IStoreAssortmentTiers {
  form: any;
  selected_assortment?: IAssortmentData;
  disabled?: boolean;
}

const StoreAssortmentTiers:React.FC<IStoreAssortmentTiers> = ({
  form,
  selected_assortment,
  disabled,
}) => {
  const {
    tier, division, department, warehouse_id,
  } = useEnums();

  const warehouseOptions = Object.values(warehouse_id || {})?.map((e) => { return { label: e, value: e }; });

  const tierOptions = ["None", ...Object.values(tier).map((e) => capitalize(e.replace("TIER_", "")))];

  const defaultWarehouseValue = useMemo(() => {
    return [{
      label: warehouseOptions?.[0]?.value,
      value: warehouseOptions?.[0]?.value,
      warehouse_id: warehouseOptions?.[0]?.value,
      priority: 0,
    }];
  }, [warehouseOptions]);

  useEffect(() => {
    if (selected_assortment) {
      const dataTiers:any = {};
      const assortment:any = selected_assortment;
      Object.keys(assortment).forEach((key) => {
        if (typeof assortment[key] === "object") {
          Object.keys(assortment[key]).forEach((nestedKey) => {
            dataTiers[`${key}.${nestedKey}`] = assortment[key][nestedKey];
          });
        } else {
          dataTiers[key] = assortment[key];
        }
      });

      Object.entries(dataTiers).forEach(([key, value]) => {
        const valueObj: { tier: string | number, warehouse_rels: any } = value as { tier: string, warehouse_rels: any };
        form.setFieldsValue({
          [`WAREHOUSE_${key}`]: valueObj?.warehouse_rels ? valueObj?.warehouse_rels?.map((each: any, index: number) => {
            return {
              label: each?.warehouse_id,
              value: each?.warehouse_id,
              warehouse_id: each?.warehouse_id,
              priority: each?.priority || index,
            };
          }) : [warehouseOptions?.[0]?.value],
        });
        if (valueObj?.tier === null || valueObj?.tier === undefined || valueObj?.tier === 0) {
          form.setFieldsValue({
            [key]: "None",
          });
        } else if (valueObj?.tier === tier.CUSTOM) {
          form.setFieldsValue({
            [key]: "Custom",
          });
        } else {
          form.setFieldsValue({
            [key]: (valueObj?.tier || "").toString().replace("TIER_", ""),
          });
        }
      });
    } else {
      form.setFieldsValue({
        [`WAREHOUSE_${division.WOMEN}.${department.FOOTWEAR}`]: defaultWarehouseValue,
        [`WAREHOUSE_${division.WOMEN}.${department.APPARELS}`]: defaultWarehouseValue,
        [`WAREHOUSE_${division.WOMEN}.${department.ACCESSORIES}`]: defaultWarehouseValue,
        [`WAREHOUSE_${division.WOMEN}.${department.COLD_WEATHER}`]: defaultWarehouseValue,
        [`WAREHOUSE_${division.MEN}.${department.FOOTWEAR}`]: defaultWarehouseValue,
        [`WAREHOUSE_${division.MEN}.${department.APPARELS}`]: defaultWarehouseValue,
        [`WAREHOUSE_${division.MEN}.${department.ACCESSORIES}`]: defaultWarehouseValue,
        [`WAREHOUSE_${division.MEN}.${department.COLD_WEATHER}`]: defaultWarehouseValue,
        [`WAREHOUSE_${division.KID}.${department.FOOTWEAR}`]: defaultWarehouseValue,
        [`WAREHOUSE_${division.KID}.${department.APPARELS}`]: defaultWarehouseValue,
        [`WAREHOUSE_${division.KID}.${department.ACCESSORIES}`]: defaultWarehouseValue,
        [`WAREHOUSE_${division.KID}.${department.COLD_WEATHER}`]: defaultWarehouseValue,
        [`WAREHOUSE_${division.HOME_CARE}.${department.HOME_CARE}`]: defaultWarehouseValue,
      });
    }
  }, [form, selected_assortment, tier]);

  const warehouseSelect = useMemo(() => {
    return (
      <DartDragSelect
        options={warehouseOptions}
        placeholder="Assign Warehouses (priority left to right)"
        noOptionsMessage={() => "All available warehouses selected"}
        defaultValue={defaultWarehouseValue}
      />
    );
  }, [warehouseOptions, defaultWarehouseValue]);

  return (
    <div className={`store-assortment-tiers ${disabled ? "disabled" : ""}`}>
      <h2 className="main-heading">
        ASSORTMENT TIERS
        {disabled ? <span>{messages.will_be_copied_from_sister_store}</span> : ""}
      </h2>
      <Form
        form={form}
        disabled={disabled}
      >
        <h3 className="sub-heading">WOMEN`S</h3>
        <div className="two-line">
          <Form.Item
            required
            name={`${division.WOMEN}.${department.FOOTWEAR}`}
            label={decideDepartmentName(department.FOOTWEAR)}
          >
            <DartSegmentedSelect options={tierOptions} />
          </Form.Item>
          <Form.Item
            rules={storeFields.warehouse}
            required
            name={`WAREHOUSE_${division.WOMEN}.${department.FOOTWEAR}`}
            label="Warehouse"
          >
            {warehouseSelect}
          </Form.Item>
        </div>
        <div className="two-line">
          <Form.Item
            required
            name={`${division.WOMEN}.${department.APPARELS}`}
            label={decideDepartmentName(department.APPARELS)}
          >
            <DartSegmentedSelect options={tierOptions} />
          </Form.Item>
          <Form.Item
            rules={storeFields.warehouse}
            required
            name={`WAREHOUSE_${division.WOMEN}.${department.APPARELS}`}
            label="Warehouse"
          >
            {warehouseSelect}
          </Form.Item>
        </div>

        <div className="two-line">
          <Form.Item
            required
            name={`${division.WOMEN}.${department.ACCESSORIES}`}
            label={decideDepartmentName(department.ACCESSORIES)}
          >
            <DartSegmentedSelect options={tierOptions} />
          </Form.Item>
          <Form.Item
            rules={storeFields.warehouse}
            required
            name={`WAREHOUSE_${division.WOMEN}.${department.ACCESSORIES}`}
            label="Warehouse"
          >
            {warehouseSelect}
          </Form.Item>
        </div>
        <div className="two-line">
          <Form.Item
            required
            name={`${division.WOMEN}.${department.COLD_WEATHER}`}
            label={decideDepartmentName(department.COLD_WEATHER)}
          >
            <DartSegmentedSelect options={tierOptions} />
          </Form.Item>
          <Form.Item
            rules={storeFields.warehouse}
            required
            name={`WAREHOUSE_${division.WOMEN}.${department.COLD_WEATHER}`}
            label="Warehouse"
          >
            {warehouseSelect}
          </Form.Item>
        </div>
        <hr className="light-splitter" />
        <h3 className="sub-heading">MEN`S</h3>

        <div className="two-line">
          <Form.Item
            required
            name={`${division.MEN}.${department.FOOTWEAR}`}
            label={decideDepartmentName(department.FOOTWEAR)}
          >
            <DartSegmentedSelect options={tierOptions} />
          </Form.Item>
          <Form.Item
            rules={storeFields.warehouse}
            required
            name={`WAREHOUSE_${division.MEN}.${department.FOOTWEAR}`}
            label="Warehouse"
          >
            {warehouseSelect}
          </Form.Item>
        </div>
        <div className="two-line">
          <Form.Item
            required
            name={`${division.MEN}.${department.APPARELS}`}
            label={decideDepartmentName(department.APPARELS)}
          >
            <DartSegmentedSelect options={tierOptions} />
          </Form.Item>
          <Form.Item
            rules={storeFields.warehouse}
            required
            name={`WAREHOUSE_${division.MEN}.${department.APPARELS}`}
            label="Warehouse"
          >
            {warehouseSelect}
          </Form.Item>
        </div>

        <div className="two-line">
          <Form.Item
            required
            name={`${division.MEN}.${department.ACCESSORIES}`}
            label={decideDepartmentName(department.ACCESSORIES)}
          >
            <DartSegmentedSelect options={tierOptions} />
          </Form.Item>
          <Form.Item
            rules={storeFields.warehouse}
            required
            name={`WAREHOUSE_${division.MEN}.${department.ACCESSORIES}`}
            label="Warehouse"
          >
            {warehouseSelect}
          </Form.Item>
        </div>
        <div className="two-line">
          <Form.Item
            required
            name={`${division.MEN}.${department.COLD_WEATHER}`}
            label={decideDepartmentName(department.COLD_WEATHER)}
          >
            <DartSegmentedSelect options={tierOptions} />
          </Form.Item>
          <Form.Item
            rules={storeFields.warehouse}
            required
            name={`WAREHOUSE_${division.MEN}.${department.COLD_WEATHER}`}
            label="Warehouse"
          >
            {warehouseSelect}
          </Form.Item>
        </div>
        <hr className="light-splitter" />
        <h3 className="sub-heading">KIDS`</h3>
        <div className="two-line">
          <Form.Item
            required
            name={`${division.KID}.${department.FOOTWEAR}`}
            label={decideDepartmentName(department.FOOTWEAR)}
          >
            <DartSegmentedSelect options={tierOptions} />
          </Form.Item>
          <Form.Item
            rules={storeFields.warehouse}
            required
            name={`WAREHOUSE_${division.KID}.${department.FOOTWEAR}`}
            label="Warehouse"
          >
            {warehouseSelect}
          </Form.Item>
        </div>
        <div className="two-line">
          <Form.Item
            required
            name={`${division.KID}.${department.APPARELS}`}
            label={decideDepartmentName(department.APPARELS)}
          >
            <DartSegmentedSelect options={tierOptions} />
          </Form.Item>
          <Form.Item
            rules={storeFields.warehouse}
            required
            name={`WAREHOUSE_${division.KID}.${department.APPARELS}`}
            label="Warehouse"
          >
            {warehouseSelect}
          </Form.Item>
        </div>
        <div className="two-line">
          <Form.Item
            required
            name={`${division.KID}.${department.ACCESSORIES}`}
            label={decideDepartmentName(department.ACCESSORIES)}
          >
            <DartSegmentedSelect options={tierOptions} />
          </Form.Item>
          <Form.Item
            rules={storeFields.warehouse}
            required
            name={`WAREHOUSE_${division.KID}.${department.ACCESSORIES}`}
            label="Warehouse"
          >
            {warehouseSelect}
          </Form.Item>
        </div>
        <div className="two-line">
          <Form.Item
            required
            name={`${division.KID}.${department.COLD_WEATHER}`}
            label={decideDepartmentName(department.COLD_WEATHER)}
          >
            <DartSegmentedSelect options={tierOptions} />
          </Form.Item>
          <Form.Item
            rules={storeFields.warehouse}
            required
            name={`WAREHOUSE_${division.KID}.${department.COLD_WEATHER}`}
            label="Warehouse"
          >
            {warehouseSelect}
          </Form.Item>
        </div>
        <hr className="light-splitter" />
        <h3 className="sub-heading">HOMECARE</h3>
        <div className="two-line">
          <Form.Item
            required
            name={`${division.HOME_CARE}.${department.HOME_CARE}`}
            label={decideDepartmentName(department.HOME_CARE)}
          >
            <DartSegmentedSelect options={tierOptions} />
          </Form.Item>
          <Form.Item
            rules={storeFields.warehouse}
            required
            name={`WAREHOUSE_${division.HOME_CARE}.${department.HOME_CARE}`}
            label="Warehouse"
          >
            {warehouseSelect}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default StoreAssortmentTiers;
