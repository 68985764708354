/* eslint-disable no-param-reassign,jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import { Dropdown, Tooltip } from "antd";
import { IStoreProfile } from "../../../../../global/interfaces";
import Icon from "../../../../../components/Icon";
import TableDropdownMenuComponent from "../../../../../components/TableDropdownMenuComponent";
import decideStoreStatusColor from "../../../../../utils/helpers/decideStoreStatusColor";

// structure
/*
{
  id: 69,
  store: {
    status: "LIVE",
    number: 86641,
    country: "USA",
    channel: "OUTLET",
    id: 140,
    name: `The Store Name 2 for: ${dummySizeRunId}`,
    sister_store: {
      name: "The Sister Store",
      id: 1232,
      number: 11111,
    },
  },
  store_id: 140,
  contribution: dummySizeRunId / 3,
  size: "9",
  size_run_id: dummySizeRunId,
},
*/

const columns = (props: {
  onStoreCheck?: (storeId?: any) => void;
  handleEditStoreClick?: (storeId?: any) => void;
  handleRemoveSisterStoreClick?: (storeId?: any) => void;
  editable?: boolean;
  isFrozenLeft?: boolean;
  channelStoreList?: IStoreProfile[];
}): Record<string, any>[] => [
  {
    key: "store",
    dataIndex: "store",
    title: (
      <div>
        <span>Store</span>
        <span>Total Contribution</span>
      </div>
    ),
    width: 500,
    flexShrink: 5,
    ...(props.isFrozenLeft ? { frozen: "left", className: "size-profile-fixed-cell" } : {}),
    cellRenderer: ({ rowData }: {
      rowData: { checked?: boolean, store?: IStoreProfile, contribution?: number, sizes?: any[], totalContribution: number | string }
    }) => {
      const currentStore = rowData?.store;
      const epsilonChecked = rowData?.totalContribution === "OFF"
        ? -1
        : Math.round(((rowData?.totalContribution as any) + Number.EPSILON) * 100) / 100;
      const realTotalContribution = epsilonChecked ? epsilonChecked / 100 : 0;
      const displayTotalContribution = Math.round((rowData?.totalContribution as any) / 100);
      return (
        <div className="size-run-overview-table-cell frozen">
          <div className="left">
            {/* {props.editable && (
            <DartCheckbox
              size="sm"
              checked={rowData?.checked}
              onChange={() => {
                props?.onStoreCheck?.(rowData?.store?.id);
                rowData.checked = !rowData?.checked;
              }}
            />
            )} */}
            <span className="store-number">{currentStore?.number}</span>
            <span className="vertical-splitter" />
            <span className="store-name">{currentStore?.name}</span>
            {currentStore?.sister_store?.number && (
              <Dropdown
                destroyPopupOnHide
                trigger={["hover"]}
                overlay={(
                  <span className="table-row-menu-overlay sister-store">
                    <Icon
                      name="Branch"
                      size={12}
                      color="#000000"
                    />
                    <span className="store-number">{currentStore?.sister_store?.number}</span>
                    <span className="vertical-splitter" />
                    <span className="store-name">{currentStore?.sister_store?.name}</span>
                  </span>
                )}
              >
                <Icon
                  name="Branch"
                  size={12}
                  color="#000000"
                  style={{ marginTop: 6 }}
                />
              </Dropdown>
            )}
            <span
              className="store-status"
              style={{ backgroundColor: decideStoreStatusColor(currentStore?.status) }}
            />
          </div>
          <div className="right">
            <TableDropdownMenuComponent
              rowData={rowData}
              functions={{
                handleEditStoreClick: () => props?.handleEditStoreClick?.(currentStore?.id),
                handleRemoveSisterStoreClick: currentStore?.sister_store?.id
                  ? () => props.handleRemoveSisterStoreClick?.(currentStore?.id)
                  : undefined,
                handleCopy: () => false,
                handlePaste: () => false,
              }}
            />
            {epsilonChecked !== -1 && (
              <Tooltip
                overlay={`Exact Contribution: ${realTotalContribution}%`}
                color="#333E47"
                placement="right"
                destroyTooltipOnHide
                trigger={["click"]}
              >
                <span className={`contribution ${displayTotalContribution !== 100 ? "flagged" : ""}`}>
                  {displayTotalContribution}
                  %
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },
];

export default columns;
