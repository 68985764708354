import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const sizeProfileEditingAtom = atom({
  key: "size-profile-editing",
  default: { sizeRun: {}, sizeProfile: {} },
  effects_UNSTABLE: [persistAtom],
});
