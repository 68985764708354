const valueToAssortmentValue = (value?: "Custom" | "1" | "2" | "3" | "4" | "None") => {
  switch (value) {
    case "Custom":
      return "CUSTOM";
    case "None":
    case undefined:
    case null:
      return null;
    default:
      return `TIER_${parseInt(value, 10)}`;
  }
};

export default valueToAssortmentValue;
