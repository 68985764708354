import { selector } from "recoil";
import { uploadFileAtom } from "../atoms/upload-file-atom";

export const uploadFileSelector = selector({
  key: "upload-file-selector",
  get: ({ get }) => {
    const atom = get(uploadFileAtom);
    return atom;
  },
});
