import * as React from "react";
import "./index.less";
import AntIcon from "@ant-design/icons";
import { Tooltip } from "antd";
import icons from "./icons";

interface IProps extends React.HTMLAttributes<HTMLSpanElement> {
  name: IconType;
  size?: number;
  color?: string;
  rotate?: number;
  tooltip?: string;
  tooltipPlacement?: "top" | "bottom" | "left" | "right";
}

const Icon: React.FC<IProps> = ({
  name,
  size,
  style,
  color,
  tooltip,
  tooltipPlacement = "right",
  ...rest
}) => {
  return (
    <span className={`dart-icon ${color ? "has-color" : ""}`}>
      {tooltip ? (
        <Tooltip
          placement={tooltipPlacement}
          overlay={tooltip}
        >
          <AntIcon
          /**
           * !! Icon should be exported from ./icons/index.ts, please add it there
           * */
            component={icons[name] as any}
            style={{
              ...style,
              ...(size ? { fontSize: size } : {}),
              ...(color ? { fill: color } : {}),
            }}
            {...rest}
            ref={null}
          />
        </Tooltip>
      ) : (
        <AntIcon
        /**
         * !! Icon should be exported from ./icons/index.ts, please add it there
         * */
          component={icons[name] as any}
          style={{
            ...style,
            ...(size ? { fontSize: size } : {}),
            ...(color ? { fill: color } : {}),
          }}
          {...rest}
          ref={null}
        />
      )}
    </span>
  );
};

export default Icon;

// available icons
export type CommonIconTypes =
  | "Profile"
  | "Bag"
  | "Tag"
  | "Document"
  | "Clip"
  | "Check"
  | "Close"
  | "Flash"
  | "Edit"
  | "Messages"
  | "UnreadMessages"
  | "DropdownArrow"
  | "Plus"
  | "Lock"
  | "Date"
  | "Delete"
  | "Store"
  | "Expand"
  | "Collapse"
  | "Refresh"
  | "Info"
  | "Dashboard"
  | "MenuDots"
  | "Branch"
  | "Copy"
  | "Filters"
  | "Search"
  | "Link"
  | "PlusBlock"
  | "Stars"
  | "Invalid"
  | "ArrowLeft"
  | "ArrowRight"
// available icon types
export type IconType =
  | CommonIconTypes
