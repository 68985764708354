import React, { useEffect, useState } from "react";
import "./index.less";
import { useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import { Form } from "antd";
import PageTitle from "../../../../components/PageTitle";
import { globalFiltersAtom, newnessDashboardFiltersAtom } from "../../../../global/atoms";
import { AllocationReadiness, NewnessPillFiltersBlock } from "../../index";

const AllocationLayout = () => {
  const [compact, setCompact] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const allocationFilters = useRecoilValue<any>(newnessDashboardFiltersAtom);
  const globalFilters = useRecoilValue<any>(globalFiltersAtom);

  // Set form values using recoil persisted state of selected dashboard filters
  useEffect(() => {
    form.setFieldsValue({
      ...allocationFilters, brand: globalFilters?.brand, channel: globalFilters?.channel,
    });
  }, [form, history, allocationFilters]);

  return (
    <div className="allocation-layout">
      <PageTitle
        title="allocation"
        icon="Tag"
        // rightNode="Deckers Allocation & Replenishment Tool"
        compactView={compact}
        leftNode={compact ? (
          <div
            className="newness-filters compact"
            data-testid="allocation-newness-filters"
          >
            <Form form={form}>
              <NewnessPillFiltersBlock showSelectedWithStatic />
            </Form>
          </div>
        ) : undefined}
      />
      {!compact ? (
        <div
          className="newness-filters"
          data-testid="allocation-newness-filters"
        >
          <Form form={form}>
            <NewnessPillFiltersBlock showSelectedWithStatic />
          </Form>
        </div>
      ) : <Form form={form} />}
      {compact && <hr className="header-split-hr" />}
      <AllocationReadiness
        compactHeader={compact}
        setCompactHeader={setCompact}
      />
    </div>
  );
};

export default AllocationLayout;
