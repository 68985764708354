import React, { useEffect, useMemo } from "react";
import "./index.less";
import DartTable from "../../../../components/DartTable";
import columns from "./columns";
import { ISelectedCC } from "../../../../global/interfaces";
import sortCCsByDivisionAndDescription from "../../utils/sortCCByDivisionAndDescription";

interface IStoreSelectionTableBlock {
  tableData?: ISelectedCC[];
  onStoreTierChange?: (cc: string, tiers: string[], tier: "special" | "tier1" | "tier2" | "tier3" | "tier4", isInitial?: boolean) => void;
}

const StoreSelectionTableBlock:React.FC<IStoreSelectionTableBlock> = ({
  tableData,
  onStoreTierChange,
}) => {
  const getColumns = useMemo(() => {
    return columns({ onStoreTierChange });
  }, [onStoreTierChange]);

  useEffect(() => {
    // This is necessary to update the table when rendering it first time with store sizes and tiers
    if (tableData) {
      const tiers = ["special", "tier1", "tier2", "tier3", "tier4"];
      for (let i = 0; i < tableData.length; i += 1) {
        for (let o = 0; o < tiers.length; o += 1) {
          // select store with is_selected status as default selected stores when modal is opened for first time
          const initiallySelected = tableData[i].store_list[tiers[o]]?.filter((s: any) => s.is_selected === true);
          onStoreTierChange?.(
            tableData[i].cc, initiallySelected || [], tiers[o] as "special" | "tier1" | "tier2" | "tier3" | "tier4", true,
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData?.length]);

  return (
    <div className="store-selection-table-block">
      <div id="store-selection-table-dropdown-wrapper" />
      <DartTable
        columns={getColumns}
        data={sortCCsByDivisionAndDescription(tableData || [])}
        width="100%"
        height="calc(100vh - 350px)"
        fixed={false}
        wrapperClassName="store-selection-table"
        headerHeight={60}
        rowHeight={72}
      />
    </div>
  );
};

export default StoreSelectionTableBlock;
