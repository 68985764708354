import {
  Avatar, Tag, Tooltip,
} from "antd";
import React from "react";
import { CloudDownloadOutlined, MinusOutlined } from "@ant-design/icons";
import moment from "moment";
import { createHash } from "crypto";
import { capitalize, xTimeAgo } from "../../../../utils/helpers";
import { downloadFile } from "../../services/upload";
import { IUploadFileRow } from "./index";
import decideUploadStatusColor from "../../../../utils/helpers/decideUploadStatusColor";
import decideImportType from "../../../../utils/helpers/decideImportType";

type dataType = { [key: string]: string };

const columns = (props: {
  doc_type: dataType, import_type: dataType, upload_status: dataType,
}): Record<string, any>[] => [
  {
    key: "id",
    dataIndex: "id",
    title: "ID",
    width: 40,
    flexGrow: 1,
    render: ({ rowData }: { rowData: IUploadFileRow }) => {
      return (
        <div className="upload-list-table-cell cursor-pointer">
          <Tooltip title={`UUID: ${rowData?.uuid}`}>
            <span className="id-wrapper">{rowData?.id}</span>
          </Tooltip>
        </div>
      );
    },
  },
  {
    key: "doc_type",
    dataIndex: "doc_type",
    title: "Document Type",
    width: 160,
    flexGrow: 2,
    hidden: true,
    cellRenderer: ({ rowData }: { rowData: IUploadFileRow }) => {
      const cellData = Object.values(props.doc_type).find((s) => s === rowData?.doc_type);
      return (
        <div className="upload-list-table-cell">
          {cellData || NAComponent}
        </div>
      );
    },
  },
  {
    key: "import_type",
    dataIndex: "import_type",
    title: "Import Type",
    width: 160,
    flexGrow: 1,
    cellRenderer: ({ rowData }: { rowData: IUploadFileRow }) => {
      const cellData = Object.values(props.import_type).filter((s) => (rowData?.import_type || "").toString().indexOf(s) > -1);
      return (
        <div className="upload-list-table-cell">
          {cellData
            ? decideImportType(cellData.toString())
            : NAComponent}
        </div>
      );
    },
  },
  {
    key: "uploaded_by_email",
    dataIndex: "uploaded_by_email",
    title: "Uploaded By",
    width: 100,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: { rowData: IUploadFileRow }) => {
      return (
        <div className="upload-list-table-cell uploaded-by-cell cursor-pointer">
          <Tooltip title={rowData?.uploaded_by}>
            <Avatar
              src={`https://robohash.org/${rowData?.uploaded_by
                ? createHash("sha256").update(rowData?.uploaded_by).digest("hex")
                : "johndoe"}.png?bgset=bg2`}
              size="large"
            />
          </Tooltip>
        </div>
      );
    },
  },
  {
    key: "updated_at",
    dataIndex: "updated_at",
    title: "Last update",
    width: 100,
    flexGrow: 1,
    cellRenderer: ({ rowData }: { rowData: IUploadFileRow }) => {
      return (
        <div className="upload-list-table-cell cursor-pointer">
          <Tooltip title={moment(rowData?.updated_at ? rowData?.updated_at : rowData?.created_at).format("MM/DD/YYYY HH:mm")}>
            {xTimeAgo(rowData?.updated_at || rowData?.created_at)}
          </Tooltip>
        </div>
      );
    },
  },
  {
    key: "status",
    dataIndex: "status",
    title: "Status",
    width: 100,
    flexGrow: 2,
    align: "center",
    cellRenderer: ({ rowData }: { rowData: IUploadFileRow }) => {
      const tagData = Object.values(props.upload_status).find((s) => s === rowData?.status) || "";
      return (
        <div className="upload-list-table-cell">
          <Tag
            color={decideUploadStatusColor(tagData)}
            style={tagData && tagData === "COMPLETED_WITH_ERRORS" ? { fontSize: 11 } : {}}
          >
            {capitalize(tagData)}
          </Tag>
        </div>
      );
    },
  },
  {
    key: "file",
    dataIndex: "file",
    title: "File",
    width: 100,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: { rowData: IUploadFileRow }) => {
      const statusData = Object.values(props.upload_status).find((s) => s === rowData?.status);
      return (
        <div className="upload-list-table-cell">
          {rowData?.upload_file_url && statusData !== "INITIATED"
            ? (
              <a
                onClick={() => (rowData?.upload_file_url ? downloadFile(rowData?.upload_file_url, false) : false)}
              >
                <Tooltip title="Download file">
                  <CloudDownloadOutlined style={{ fontSize: 20, color: "#959A9D" }} />
                </Tooltip>
              </a>
            )
            : (
              NAComponent
            )}
        </div>
      );
    },
  },
  {
    key: "error_file",
    dataIndex: "error_file",
    title: "Error Log",
    width: 100,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: { rowData: IUploadFileRow }) => {
      return (
        <div className="upload-list-table-cell">
          {rowData?.error_file_url
            ? (
              <a
                onClick={() => (rowData?.error_file_url ? downloadFile(rowData?.error_file_url, true) : false)}
              >
                <Tooltip title="Download error file">
                  <CloudDownloadOutlined style={{ fontSize: 20, color: "#FC4848" }} />
                </Tooltip>
              </a>
            )
            : (
              NAComponent
            )}
        </div>
      );
    },
  },
];

const NAComponent = <MinusOutlined style={{ fontSize: 14, color: "#C3C5C8" }} />;

export default columns;
