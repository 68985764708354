import React, {
  useCallback, useMemo, useState,
} from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { IReplenishmentCC } from "../../../../global/interfaces";
import "./index.less";
import { SelectedStyleColorBlockAdvanced } from "../../index";
import DartTable from "../../../../components/DartTable";
import columns from "./columns";
import EditableCell from "../../../../components/EditableCell";
import {
  onMinWOHUpdate,
  onSKUSendMoreUpdate,
  onStoreExcludedToggle,
  onStoreSendMoreUpdate,
} from "../../services/calculations";
import { manualReplenishmentAtom } from "../../../../global/atoms";

interface IReplenishCCDetails {
  ccDetails?: IReplenishmentCC;
  onSKUSizeClick?: (size: any) => void;
}

const ReplenishCCDetails:React.FC<IReplenishCCDetails> = ({
  ccDetails,
  onSKUSizeClick,
}) => {
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const setManualReplenishmentState = useSetRecoilState(manualReplenishmentAtom);
  const manualReplenishmentState = useRecoilValue(manualReplenishmentAtom);

  const handleSKUSizeClick = (record: any) => {
    onSKUSizeClick?.(record?.size);
  };

  // Child table columns
  const generateChildColumnsUsingSizes = useCallback((ccSizes?: any, parentId?: number) => {
    const currentStore = ccDetails?.stores?.find((store: any) => store.id === parentId);
    const totalSizeContribution = currentStore?.sizes?.reduce((acc: number, size: any) => acc + parseFloat(size?.size_contribution), 0);
    const hasSizeProfilesSetCorrectly = totalSizeContribution && Math.abs(totalSizeContribution - 1) < 0.01;
    return [
      {
        key: "size",
        dataIndex: "size",
        title: "",
        width: 250,
        align: "center",
        className: "nested-table-replenishment-cc-cell padding-0",
        cellRenderer: ({ rowData }: any) => {
          return (
            <div className={`anchor-title ${rowData?.key === "send_more" && !hasSizeProfilesSetCorrectly ? "hasError" : ""}`}>
              {rowData?.title}
              {
                rowData?.key === "send_more" && !hasSizeProfilesSetCorrectly ? (
                  <Tooltip overlay="Size Profiles for this store is not set properly">
                    <QuestionCircleOutlined style={{
                      color: "red", marginLeft: 4, fontSize: 16,
                    }}
                    />
                  </Tooltip>
                ) : ""
              }
            </div>
          );
        },
      },
      ...(ccSizes || []).map((ccSize: any) => {
        return {
          title: (
            <div
              onClick={() => handleSKUSizeClick(ccSize)}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {ccSize.size}
            </div>
          ),
          dataIndex: ccSize.size,
          key: ccSize.size,
          width: 150,
          align: "center",
          cellRenderer: ({ rowData }: any) => {
            const cellDataToRender = () => {
              switch (rowData?.key) {
                case "total":
                  return ccSize?.totalInventory;
                case "send_more":
                  return (
                    <EditableCell
                      cellData={ccSize?.sendMore}
                      onOk={(value: number) => (parentId ? onSKUSendMoreUpdate(
                        value, parentId, { ...ccSize, cc: ccDetails?.cc }, setManualReplenishmentState,
                      ) : false)}
                      allowEmpty={false}
                      canBeEmpty={false}
                      cancelOnBlur
                      min={0}
                      max={9999999}
                      allowDecimals={false}
                      hasButtons={false}
                    />
                  );
                case "final_inventory":
                  return ccSize?.finalInventory;
                default:
                  return null;
              }
            };
            return (
              <div className="size-cell">
                {cellDataToRender()}
              </div>
            );
          },
        };
      }),
    ];
  }, [ccDetails]);

  // Child table renderer function
  const childRenderTable = useCallback((ccSizes?: any, parentId?: number) => {
    return (
      <div className="expanded-row-child">
        <DartTable
          key={`${ccDetails?.sendMore}_key`}
          tableStyle="default"
          height={216}
          data={[
            { title: "Total Store Inv", key: "total" },
            { title: "Send More", key: "send_more" },
            { title: "Final Inv", key: "final_inventory" },
          ]}
          columns={generateChildColumnsUsingSizes(ccSizes, parentId)}
        />
      </div>
    );
  }, [ccDetails]);

  // Memoized Columns with props provided
  const getColumns = useMemo(() => {
    return columns({
      onSendMoreChange: (value: number, rowData: any) => (ccDetails ? onStoreSendMoreUpdate(
        value, rowData, ccDetails, setManualReplenishmentState,
      ) : false),
      onExcludeToggle: (value: boolean, rowData: any) => (ccDetails ? onStoreExcludedToggle(
        value, rowData, ccDetails, setManualReplenishmentState,
      ) : false),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ccDetails]);

  return (
    <div
      className="replenish-cc-details"
    >
      <div className="table-heading-section">
        {ccDetails && (
        <SelectedStyleColorBlockAdvanced
          key={ccDetails.cc}
          data={ccDetails}
          theme="dark"
          open
          onWOHChange={(WOH) => onMinWOHUpdate(
            WOH, ccDetails, setManualReplenishmentState,
          )}
        />
        )}
      </div>
      <div className="table-wrapper">
        <DartTable
          columns={getColumns}
          data={ccDetails?.stores?.map((v: any) => {
            return {
              ...v,
              id: v?.store?.id || v?.store?.number,
              childRender: !v?.store?.excluded ? childRenderTable(v.sizes, v?.store?.id || v?.store?.number) : null,
            };
          })}
          height="calc(100vh - 530px)" // Overflowing Final Review
          // height="calc(100vh - 680px)" // Not overflowing Final Review
          tableStyle="dark"
          expandable
          expandColumnKey="id"
          childHeight={240}
          onExpandedRowsChange={(expandedRowList: number[]) => setExpandedRows(expandedRowList)}
          defaultExpandedRowKeys={expandedRows}
        />
      </div>
    </div>
  );
};

export default ReplenishCCDetails;
