import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export type IChannelTypes = "OUTLET" | "CONCEPT" | "FLAGSHIP";
export type IBrandTypes = "UGG" | "HOKA" | "SANUK" | "AHNU";

export const globalFiltersAtom = atom({
  key: "global-filters",
  default: {
    brand: undefined, channel: undefined, region: {}, breadcrumb: [],
  },
  effects_UNSTABLE: [persistAtom],
});
