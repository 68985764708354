import React, { useEffect, useState } from "react";
import "./index.less";
import { Col, Row } from "antd";
import { useRecoilState } from "recoil";
import { StoreListFilters, StoreListView } from "../../index";
import { storeListFiltersAtom, storeListFiltersInitialState } from "../../../../global/atoms/store-list-filters-atom";
import { queryGet } from "../../../../utils/helpers";

const StoreListLayout:React.FC = () => {
  const storeIds = queryGet("store_ids");
  const division = queryGet("division");
  const department = queryGet("department");
  const channel = queryGet("channel");
  const brand = queryGet("brand");
  const storeNumbers = queryGet("store_numbers");
  const [storeFilters, setStoreFilters] = useRecoilState(storeListFiltersAtom);
  const [queryFilters, setQueryFilters] = useState({});
  const [usingQueryFilters, setUsingQueryFilters] = useState(false);

  useEffect(() => {
    if (storeIds || storeNumbers) {
      setUsingQueryFilters(true);
      setQueryFilters({
        ...storeListFiltersInitialState,
        storeIds: storeIds ? [{ label: "Advanced Filters Applied", value: storeIds }] : [],
        division: division ? [{ label: "Advanced Filters Applied", value: division }] : [],
        department: department ? [{ label: "Advanced Filters Applied", value: department }] : [],
        channel: channel ? [{ label: "Advanced Filters Applied", value: channel }] : [],
        brand: brand ? [{ label: "Advanced Filters Applied", value: brand }] : [],
        storeNumbers: storeNumbers ? [{ label: "Advanced Filters Applied", value: storeNumbers }] : [],
      });
    }
  }, [storeIds, division, department, channel, brand, storeNumbers]);

  return (
    <div className="store-list-layout">
      <Row>
        <Col span={6}>
          <StoreListFilters
            usingQueryFilters={usingQueryFilters}
            storeFilters={usingQueryFilters ? queryFilters : storeFilters}
            setStoreFilters={usingQueryFilters ? setQueryFilters : setStoreFilters}
          />
        </Col>
        <Col span={18}>
          <StoreListView
            storeFilters={usingQueryFilters ? queryFilters : storeFilters}
            setStoreFilters={usingQueryFilters ? setQueryFilters : setStoreFilters}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StoreListLayout;
