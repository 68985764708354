const endpoints = {
  base: process.env.REACT_APP_BASE_URL,
  api_logs: "/dart/logs/api",
  api_logs_detail: "/dart/logs/api/:id",
  enums: "/dart/enums",
  uploadFIle: "/dart/upload",
  allocationNewnessList: "/dart/allocation/newness",
  allocationNewnessSize: "/dart/allocation/newness_size",
  allocationStoreTiers: "/dart/allocation/store_tiers",
  allocationProcess: "/dart/allocation/process",
  allocationData: "/dart/allocation/data",
  store: "/dart/store",
  storeDepartmentRelationships: "/dart/store-dept-rel",
  sizeRunList: "/dart/size",
  sizeRunUpdate: "/dart/size/:id",
  sizeProfile: "/dart/size/:id/profile",
  updatableSizeProfiles: "/dart/size/updates",
  updateSizeProfiles: "/dart/size/size_profile_bulk_update",
  poaList: "/dart/poa-category",
  poaCategoryCreate: "/dart/poa-category",
  poaCategoryUpdate: "/dart/poa-category/:id",
  poaValues: "/dart/poa-category/poa",
  poaUpdateValues: "/dart/poa-category/poa-updates",
  updatePoaValues: "/dart/poa-category/poa_bulk_update",
  initialAllocationQuantities: "/dart/initial_allocation_quantities",
  initialAllocationQuantitiesData: "/dart/initial_allocation_quantities/data",
  initialAllocationQuantitiesStyleDetails: "/dart/initial_allocation_quantities/data/:id",
  initialAllocationQuantitiesStoreStages: "/dart/initial_allocation_quantities/:id/stages",
  replenishment: "/dart/replenishment",
  replenishmentProcess: "/dart/replenishment/:id",
  replenishmentProcessApprove: "/dart/replenishment/:id/allocation",
  initialAllocationQuantitiesComments: "/dart/comment",
  replenishmentSearchCC: "/dart/replenishment/cc",
  replenishmentDetailsCC: "/dart/replenishment/cc/details/:id",
  inventory: "/dart/inventory",
  inventory_all: "/dart/inventory/data",
  tags: "/dart/tag",
  usersList: "/dart/user",
  usersUpdate: "/dart/user/:id",
  saveFlowDoc: "/dart/flowdoc",
};

export default endpoints;
