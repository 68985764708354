import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row } from "antd";
import { useRecoilValue } from "recoil";
import { CCFinder, SelectedStyleColorBlock } from "../..";
import "./index.less";
import paths from "../../../../configs/paths";
import { IReplenishmentCC } from "../../../../global/interfaces";
import DartInput from "../../../../components/DartInput";
import DartButton from "../../../../components/DartButton";
import { globalFiltersAtom, manualReplenishmentAtom } from "../../../../global/atoms";
import { startManualReplenishment } from "../../services/replenishment";

const ManualCCSelection = () => {
  const history = useHistory();
  const [selectedStyles, setSelectedStyles] = useState<IReplenishmentCC[]>([]);
  const [replenishmentName, setReplenishmentName] = useState<string>("");
  const [startReplenishmentLoading, setStartReplenishmentLoading] = useState(false);
  const manualReplenishmentState = useRecoilValue(manualReplenishmentAtom);
  const { brand, channel } = useRecoilValue(globalFiltersAtom);

  useEffect(() => {
    document.title = "DART - MANUAL REPLENISHMENT";
  }, []);

  // If replenishment exists locally, redirect to it
  useEffect(() => {
    if (manualReplenishmentState?.processId) {
      history.push(paths.manual_replenishment_step_one.replace(":id", manualReplenishmentState?.processId));
    }
  }, [manualReplenishmentState]);

  const handleRemoveCC = (cc: string) => {
    setSelectedStyles((prev) => prev.filter((ccData) => ccData.cc !== cc));
  };

  const handleAddCC = (ccList: IReplenishmentCC[]) => {
    setSelectedStyles((prev) => {
      return [...prev, ...ccList
        .filter((c) => !prev.find((cc) => cc.cc === c.cc)),
      ];
    });
  };

  const handleStartManualReplenishment = async () => {
    const response = await startManualReplenishment(
      selectedStyles?.map((e) => e.cc),
      replenishmentName,
      { brand, channel },
      setStartReplenishmentLoading,
    );

    if (response && response?.process_id) {
      history.push(paths.manual_replenishment_step_one.replace(":id", response?.process_id));
    }
  };

  return (
    <div className="manual-cc-selection-page">
      <div className="container-content">
        <CCFinder
          title="MANUAL REPLENISHMENT"
          placeholder="Enter Style Colors (Space Separated) e.g. 1121254-BLK 1095141-BBLC 1016222-CRBO"
          onBack={() => history.push(paths.replenishment_dashboard)}
          errorMessage="Invalid style-color entered"
          hasAddButton
          onCCAdd={handleAddCC}
          disabled={startReplenishmentLoading}
        />
        <Row>
          <Col span={17}>
            <div className="selected-style-colors-wrapper">
              <h2>{`Style-Colors: ${selectedStyles?.length}`}</h2>
              <div className="selected-style-colors-list">
                <div className="inner-wrapper">
                  {selectedStyles?.length > 0
                    ? (selectedStyles.map((cc, index) => {
                      return (
                        <SelectedStyleColorBlock
                          key={index + cc.cc}
                          ccData={cc}
                          onRemove={startReplenishmentLoading ? (() => false) : handleRemoveCC}
                          theme="light"
                        />
                      );
                    }))
                    : (<div className="empty-list-placeholder">No style-colors added.</div>)}
                </div>
              </div>
            </div>
          </Col>
          <Col span={7}>
            <div className="replenishment-name-wrapper">
              <DartInput
                disabled={startReplenishmentLoading}
                placeholder="e.g. Feb Adirondack III Push"
                transparent={false}
                label="Replenishment Name"
                value={replenishmentName}
                onChange={(e) => setReplenishmentName(e.target.value)}
              />
              <DartButton
                disabled={selectedStyles.length === 0 || replenishmentName.length === 0}
                label="Start Replenishment"
                onClick={() => handleStartManualReplenishment()}
                tooltip={selectedStyles.length === 0 || replenishmentName.length === 0
                  ? "Please add style-colors and name to start replenishment."
                  : undefined}
                size="lg"
                loading={startReplenishmentLoading}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ManualCCSelection;
