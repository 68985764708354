import React from "react";
import "./index.less";
import Search from "antd/es/input/Search";

interface IDartSelect {
  placeholder?: string;
  onSearch?: (value: string) => void;
}

const DartPageTitleSearch: React.FC<IDartSelect> = ({
  placeholder = "Search",
  onSearch,
}) => {
  return (
    <div className="dart-page-title-search">
      <Search
        placeholder={placeholder}
        onSearch={(v) => onSearch?.(v || "")}
      />
    </div>
  );
};

export default DartPageTitleSearch;
