import React from "react";
import { MinusOutlined } from "@ant-design/icons";
import Icon from "../../../../components/Icon";
import { orderTypes } from "../../../../configs/constants";

/* TODO: Move to interface
  Data Structure: {
    allocatable_inventory: 490
    brand: "UGG"
    cc: "1116183-CPDP"
    cc_sizes: Array(14) [ {…}, {…}, {…}, … ] // APPENDED FIELD
    cc_tier: "Tier 4"
    channel: "concept"
    class: "Bags"
    klass: "Bags"
    country: "us"
    department: "Accessories"
    description: "Janey II Clear"
    division: "Women's"
    gtm_collection: "PLAID PUNK"
    id: "1116183-CPDP"
    initial_allocation: 203
    is_1psps_met: 1
    is_completed: 0
    is_min_qty_met: 1
    is_ready: 1
    isd: "2022-07-15"
    min_qty: 14
    period: "202207"
    size_run: "O/S"
    st_department: "W Accessories"
    store_avg: 6
    store_nr: 20
}
*/

const columns = (props: {
  hasGTM: boolean;
  orderType?: keyof typeof orderTypes;
  expandedRows?: string[],
  onExpandToggle?: (toggleValue: boolean) => void
}): Record<string, any>[] => [
  {
    key: "division",
    dataIndex: "division",
    title: "Division",
    width: 100,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="readiness-table-cell">
          {rowData.division || NAComponent}
        </div>
      );
    },
  },
  {
    key: "department",
    dataIndex: "department",
    title: "Department",
    width: 100,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="readiness-table-cell">
          {rowData?.department || NAComponent}
        </div>
      );
    },
  },
  {
    key: "description",
    dataIndex: "description",
    title: "Description",
    width: 100,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="readiness-table-cell">
          {rowData?.description || NAComponent}
        </div>
      );
    },
  },
  {
    key: "class",
    dataIndex: "class",
    title: "Class",
    width: 100,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="readiness-table-cell">
          {rowData.class || rowData.klass || NAComponent}
        </div>
      );
    },
  },
  {
    key: "is_1psps_met",
    dataIndex: "is_1psps_met",
    title: "1 psps",
    width: 100,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="readiness-table-cell">
          {rowData?.is_1psps_met
            ? (
              <Icon
                name="Check"
                color="#19945D"
                size={14}
              />
            )
            : (
              <Icon
                name="Close"
                color="#E87324"
                size={14}
              />
            )}
        </div>
      );
    },
  },
  {
    key: "is_min_qty_met",
    dataIndex: "is_min_qty_met",
    title: "Initial qty met",
    width: 100,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="readiness-table-cell">
          {rowData?.is_min_qty_met
            ? (
              <Icon
                name="Check"
                color="#19945D"
                size={14}
              />
            )
            : (
              <Icon
                name="Close"
                color="#E87324"
                size={14}
              />
            )}
        </div>
      );
    },
  },
  {
    key: "initial_allocation",
    dataIndex: "initial_allocation",
    title: "Initial allocation qty",
    width: 120,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="readiness-table-cell">
          {rowData?.initial_allocation || rowData?.initial_allocation === 0 ? rowData?.initial_allocation : NAComponent}
        </div>
      );
    },
  },
  {
    key: "allocatable_inventory",
    dataIndex: "allocatable_inventory",
    title: props.orderType === orderTypes.cross_dock ? "Future Inv (units)" : "Allocatable Inv (units)",
    width: 120,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="readiness-table-cell">
          {rowData?.allocatable_inventory || rowData?.allocatable_inventory === 0 ? rowData?.allocatable_inventory : NAComponent}
        </div>
      );
    },
  },
];

const NAComponent = <MinusOutlined style={{ fontSize: 14, color: "#C3C5C8" }} />;

export default columns;
