import React, {
  useEffect, useMemo, useState,
} from "react";
import { Button } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import Icon from "../../../../components/Icon";
import DartPrompt from "../../../../components/DartPrompt";
import {
  getAllStores, getStoreDepartmentRelationships, updateStoreDepartmentRelationships,
} from "../../services/store";
import useEnums from "../../../../utils/hooks/useEnums";
import "./index.less";
import CompactSelect from "../../../../components/CompactSelect";
import decideDepartmentName from "../../../../utils/helpers/decideDepartmentName";
import decideDivisionName from "../../../../utils/helpers/decideDivisionName";
import { storeListFiltersAtom } from "../../../../global/atoms/store-list-filters-atom";
import countryCodeToName from "../../../../utils/helpers/countryCodeToName";
import DartDragSelect from "../../../../components/DartDragSelect";
import { storeListAtom } from "../../../../global/atoms";

const WarehouseAssignWidget: React.FC = () => {
  const {
    country, department, division, warehouse_id, allocation_channel, brand,
  } = useEnums();
  const storeListFilters = useRecoilValue(storeListFiltersAtom);
  const [storeList, setStoreList] = useRecoilState(storeListAtom);
  const [selectedWarehouses, setSelectedWarehouses]
    = useState<any>([{
      label: Object.values(warehouse_id || {})?.[0],
      value: Object.values(warehouse_id || {})?.[0],
      warehouse_id: Object.values(warehouse_id || {})?.[0],
      priority: 0,
    }]);
  const [warehouseAssignVisible, setWarehouseAssignVisible] = useState(false);
  const [warehouseInputError, setWarehouseInputError] = useState<string | undefined>(undefined);
  const [selectedWarehousesCriteria, setSelectedWarehousesCriteria] = useState<any>({
    country: [],
    division: [],
    department: [],
    channel: [],
    brand: [],
  });
  const [storeDepartmentRelations, setStoreDepartmentRelations] = useState<any>([]);
  const [selectedStoreList, setSelectedStoreList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  // Do initial fetch for relationships to get available stores and store-dept-rel-ids
  useEffect(() => {
    getStoreDepartmentRelationships(storeDepartmentRelations, setStoreDepartmentRelations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set initial values based on filters outside this widget (for stores / on store list page)
  useEffect(() => {
    if (warehouseAssignVisible) {
      setSelectedWarehousesCriteria({
        country: storeListFilters?.country?.map((e: any) => e.value) || [],
        channel: storeListFilters?.channel?.map((e: any) => e.value) || [],
        brand: storeListFilters?.brand?.map((e: any) => e.value) || [],
        department: storeListFilters?.department?.map((e: any) => e.value) || [],
        division: storeListFilters?.division?.map((e: any) => e.value) || [],
      });
    }
  }, [storeListFilters, warehouseAssignVisible]);

  // Make sure country is initially selected
  useEffect(() => {
    if (!selectedWarehousesCriteria?.country?.[0]) {
      setSelectedWarehousesCriteria({
        ...selectedWarehousesCriteria,
        country: [Object.values(country || {})?.[0]],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWarehousesCriteria, country]);

  // Make sure that warehouse is initially selected
  useEffect(() => {
    if (!selectedWarehouses) {
      const dc = Object.values(warehouse_id || {})?.[0];
      setSelectedWarehouses({
        label: dc, value: dc, warehouse_id: dc, priority: 0,
      });
    }
  }, [selectedWarehouses, warehouse_id]);

  // Function that handles selection of dropdowns
  const handleSelectionChange = (key: string, value: any) => {
    if (key === "country") {
      const countriesSelected = value?.length > 0 ? value : [Object.values(country || {})?.[0]];
      setSelectedWarehousesCriteria({
        ...selectedWarehousesCriteria,
        country: countriesSelected,
      });
    } else {
      setSelectedWarehousesCriteria({
        ...selectedWarehousesCriteria,
        [key]: value?.map((v: any) => {
          return v;
        }),
      });
    }
  };

  // Function that checks if object matches criteria
  const filterRelationUsingCriteria = (relation: any, criteria: any) => {
    if (criteria?.country?.length > 0
      && !criteria?.country?.find((c: any) => c === relation?.store?.country)) {
      return false;
    }
    if (criteria?.division?.length > 0
      && !criteria?.division?.find((d: any) => d === relation?.division)) {
      return false;
    }
    if (criteria?.channel?.length > 0
      && !criteria?.channel?.find((d: any) => d === relation?.store?.channel)) {
      return false;
    }
    if (criteria?.brand?.length > 0
      && !criteria?.brand?.find((d: any) => d === relation?.store?.brand)) {
      return false;
    }
    return !(criteria?.department?.length > 0 && !criteria?.department?.find((d: any) => d === relation?.department));
  };

  // Calculate available store list based on country, department and division (AND operation)
  const availableStoreList = useMemo(() => {
    const distinctListOfStores:any = {};

    // Apply filters using selectedWarehousesCriteria
    const filteredList = storeDepartmentRelations?.data?.data?.filter((rel: any) => {
      return filterRelationUsingCriteria(rel, selectedWarehousesCriteria);
    });

    for (let i = 0; i < filteredList?.length; i += 1) {
      const rel = filteredList?.[i];
      if (rel?.store_id && !distinctListOfStores.hasOwnProperty(rel?.store_id)) {
        distinctListOfStores[rel?.store?._id] = rel?.store;
      }
    }

    return Object.values(distinctListOfStores);
  }, [
    storeDepartmentRelations,
    selectedWarehousesCriteria,
    selectedWarehousesCriteria?.country,
    selectedWarehousesCriteria?.division,
    selectedWarehousesCriteria?.department,
    selectedWarehousesCriteria?.brand,
    selectedWarehousesCriteria?.channel,
  ]);

  // select all stores when filter changes
  useEffect(() => {
    setSelectedStoreList(availableStoreList);
  }, [
    availableStoreList,
    selectedWarehousesCriteria?.country,
    selectedWarehousesCriteria?.division,
    selectedWarehousesCriteria?.department,
    selectedWarehousesCriteria?.brand,
    selectedWarehousesCriteria?.channel,
  ]);

  // Function that handles selection of stores
  const onStoreChange = (stores: any) => {
    setSelectedStoreList(availableStoreList?.filter((store: any) => stores?.find((s: any) => s === store?._id)));
  };

  // Submit values to BE
  const handleSubmit = async () => {
    // Apply filters using selectedWarehousesCriteria to get store-dept-rel-ids
    const filteredList = storeDepartmentRelations?.data?.data?.filter((rel: any) => {
      if (!filterRelationUsingCriteria(rel, selectedWarehousesCriteria)) {
        return false;
      }
      return !(selectedStoreList?.length > 0 && !selectedStoreList?.find((s: any) => s?.id === rel?.store?.id));
    });

    const response = await updateStoreDepartmentRelationships(
      filteredList, selectedWarehouses, setLoading,
    );

    if (response) {
      getAllStores(
        storeList, setStoreList, storeListFilters,
      );
      setWarehouseAssignVisible(false);
    }
  };

  // Cleanup on close
  useEffect(() => {
    if (!warehouseAssignVisible) {
      const dc = Object.values(warehouse_id || {})?.[0];
      setSelectedWarehouses([{
        label: dc, value: dc, warehouse_id: dc, priority: 0,
      }]);
      setSelectedStoreList([]);
      setSelectedWarehousesCriteria({
        country: [],
        division: [],
        department: [],
        channel: [],
        brand: [],
      });
    }
  }, [warehouseAssignVisible, warehouse_id]);

  return (
    <div className="warehouse-assignment-widget-container">
      <Button
        type="ghost"
        onClick={() => setWarehouseAssignVisible(true)}
        loading={storeDepartmentRelations?.status === "request"}
        disabled={storeDepartmentRelations?.status !== "success"}
      >
        <span className="icon-wrapper">
          <Icon
            name="Link"
            color="white"
            size={9}
          />
        </span>
        <span>Assign Warehouses</span>
      </Button>
      <DartPrompt
        className="warehouse-assign-modal-container"
        visible={warehouseAssignVisible}
        title="Assign Warehouses"
        onCancel={() => setWarehouseAssignVisible(false)}
        onClose={() => setWarehouseAssignVisible(false)}
        maskClosable={false}
        width={580}
        okText="Assign"
        cancelText="Cancel"
        okButtonProps={{
          loading,
          disabled: warehouseInputError && selectedWarehouses?.length === 0,
        }}
        closable
        onOk={handleSubmit}
        icon={(
          <Icon
            name="Store"
            color="rgb(232, 115, 36)"
          />
        )}
        content={(
          <div className="warehouse-assign-modal">
            <div className="country-selector selector">
              <span className="title">Country</span>
              <CompactSelect
                placeholder={Object.values(country || {})?.length
                  ? "No country selected"
                  : "No countries found"}
                selectPlaceholder="count"
                customMaxTagPlaceholder={(e) => (e?.length === 1
                  ? `${e?.[0]?.label}`
                  : `${e?.length} out of ${Object.values(country || {}).length} countries`)}
                selectOptions={Object.values(country || {})?.map((countryOption:any) => {
                  return {
                    label: countryCodeToName(countryOption),
                    value: countryOption,
                  };
                })}
                mode="multiple"
                canClear={false}
                showSearch={false}
                value={selectedWarehousesCriteria?.country}
                onChange={(newCountry) => handleSelectionChange("country", newCountry)}
                hasSelectAll={Object.values(country || {})?.length > 3}
              />
            </div>
            <div className="two-column">
              <div className="channel-selector selector">
                <span className="title">Channel</span>
                <CompactSelect
                  placeholder={Object.values(allocation_channel || {})?.length
                    ? "Not selected (all)"
                    : "Not found"}
                  selectPlaceholder="count"
                  customMaxTagPlaceholder={(e) => (e?.length === 1
                    ? e?.[0]?.value
                    : `${e?.length} out of ${Object.values(allocation_channel || {}).length} channels`)}
                  selectOptions={Object.values(allocation_channel || {})?.map((channelOption:any) => {
                    return {
                      label: channelOption,
                      value: channelOption,
                    };
                  })}
                  mode="multiple"
                  canClear
                  showSearch={false}
                  value={selectedWarehousesCriteria?.channel}
                  onChange={(newChannel) => handleSelectionChange("channel", newChannel)}
                />
              </div>
              <div className="brand-selector selector">
                <span className="title">Brand</span>
                <CompactSelect
                  placeholder={Object.values(brand || {})?.length
                    ? "Not selected (all)"
                    : "Not found"}
                  selectPlaceholder="count"
                  customMaxTagPlaceholder={(e) => (e?.length === 1
                    ? e?.[0]?.value
                    : `${e?.length} out of ${Object.values(brand || {}).length} brands`)}
                  selectOptions={Object.values(brand || {})?.map((brandOption:any) => {
                    return {
                      label: brandOption,
                      value: brandOption,
                    };
                  })}
                  mode="multiple"
                  canClear
                  showSearch={false}
                  value={selectedWarehousesCriteria?.brand}
                  onChange={(newBrand) => handleSelectionChange("brand", newBrand)}
                />
              </div>
            </div>
            <div className="division-selector selector">
              <span className="title">Division</span>
              <CompactSelect
                placeholder={Object.values(division || {})?.length
                  ? "No division selected (applies to all)"
                  : "No divisions found"}
                selectPlaceholder="count"
                customMaxTagPlaceholder={(e) => (e?.length === 1
                  ? `${decideDivisionName(e?.[0]?.value)}`
                  : `${e?.length} out of ${Object.values(division || {}).length} divisions`)}
                selectOptions={Object.values(division || {})?.map((divisionOption:any) => {
                  return {
                    label: decideDivisionName(divisionOption),
                    value: divisionOption,
                  };
                })}
                mode="multiple"
                canClear
                showSearch={false}
                value={selectedWarehousesCriteria?.division}
                onChange={(newDivision) => handleSelectionChange("division", newDivision)}
                hasSelectAll
              />
            </div>
            <div className="department-selector selector">
              <span className="title">Department</span>
              <CompactSelect
                placeholder={Object.values(department || {})?.length
                  ? "No department selected (applies to all)"
                  : "No departments found"}
                selectPlaceholder="count"
                customMaxTagPlaceholder={(e) => (e?.length === 1
                  ? `${decideDepartmentName(e?.[0]?.value)}`
                  : `${e?.length} out of ${Object.values(department || {}).length} departments`)}
                selectOptions={Object.values(department || {})?.map((departmentOption:any) => {
                  return {
                    label: decideDepartmentName(departmentOption),
                    value: departmentOption,
                  };
                })}
                mode="multiple"
                canClear
                showSearch={false}
                value={selectedWarehousesCriteria?.department}
                onChange={(newDepartment) => handleSelectionChange("department", newDepartment)}
                hasSelectAll
              />
            </div>
            <div className="store-selector selector">
              <span className="title">Stores</span>
              <CompactSelect
                placeholder={availableStoreList?.length
                  ? "No stores selected (applies to all)"
                  : "No stores found"}
                selectPlaceholder="count"
                customMaxTagPlaceholder={(e) => (e?.length === 1
                  ? `${e?.[0]?.label}`
                  : `${e?.length} out of ${availableStoreList?.length} stores`)}
                selectOptions={(availableStoreList || [])?.map((store: any) => { return { value: store?._id, label: store?.name }; })}
                mode="multiple"
                canClear={false}
                showSearch={false}
                value={(selectedStoreList || [])?.map((store: any) => store?._id)}
                onChange={onStoreChange}
                hasSelectAll
              />
            </div>
            <div className="warehouse-selector segmented">
              <span className="title">
                Warehouses
                {warehouseInputError && selectedWarehouses?.length === 0 && (
                <div className="error-message">
                  <Icon
                    name="Info"
                    color="#FC4848"
                  />
                  <span>
                    {warehouseInputError}
                  </span>
                </div>
                )}
              </span>
              <DartDragSelect
                options={Object.values(warehouse_id || {})?.map((warehouse) => {
                  return {
                    value: warehouse, label: warehouse, warehouse_id: warehouse, priority: 0,
                  };
                })}
                value={selectedWarehouses?.map((warehouse: any, index: number) => {
                  return {
                    value: warehouse?.warehouse_id,
                    label: warehouse?.warehouse_id,
                    warehouse_id: warehouse?.warehouse_id,
                    priority: warehouse?.priority || index,
                  };
                })}
                onChange={(warehouses: any) => {
                  if (warehouses?.length === 0) {
                    setWarehouseInputError("Please select at least 1 warehouse");
                  } else {
                    setWarehouseInputError(undefined);
                  }
                  const transformWarehouses = (warehouses || []).map((warehouse: any, index: number) => {
                    return {
                      warehouse_id: warehouse?.warehouse_id, priority: index, label: warehouse?.label, value: warehouse?.value,
                    };
                  });
                  setSelectedWarehouses(transformWarehouses);
                }}
                placeholder="Assign Warehouses (priority left to right)"
                noOptionsMessage={() => "All available warehouses selected"}
              />
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default WarehouseAssignWidget;
