import React from "react";
import "./index.less";
import { Button, Tooltip } from "antd";
import Icon, { IconType } from "../Icon";

interface IDartButton {
  icon?: IconType | JSX.Element;
  label?: string;
  disabled?: boolean;
  type?: "primary" | "secondary" | "default" | "danger" | "dark" | "ghost" | "danger_full";
  size?: "sm" | "md" | "lg";
  onClick?: (e: any) => void;
  formAction?: string;
  style?: any;
  loading?: boolean;
  className?: string;
  isSubmit?: boolean;
  tooltip?: string;
}

const DartButton: React.FC<IDartButton> = ({
  icon = undefined,
  label = "",
  disabled,
  size = "md",
  type = "primary",
  onClick,
  formAction,
  style = {},
  loading,
  className,
  isSubmit,
  tooltip,
}) => {
  return (
    <div
      className={`dart-button ${disabled ? "disabled" : ""} ${size} ${type} ${loading ? "loading" : ""} ${className || ""}`}
    >
      <Tooltip
        overlay={tooltip || false}
        destroyTooltipOnHide
        placement="bottom"
      >
        <Button
          formAction={formAction ?? undefined}
          onClick={(e) => (onClick ? onClick(e) : false)}
          disabled={!!disabled}
          style={style}
          loading={loading}
          htmlType={isSubmit ? "submit" : "button"}
        >
          <span className="dart-button-content">
            {!loading && (
            <>
              {icon && typeof icon === "string" ? <Icon name={icon} /> : (
                icon && (
                <span>
                  {icon}
                  {" "}
                </span>
                )
              )}
            </>
            )}
            {label && <span>{label}</span>}
          </span>
        </Button>
      </Tooltip>
    </div>
  );
};

export default DartButton;
