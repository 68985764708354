import React from "react";
import "./index.less";
import { Tooltip } from "antd";
import Icon, { IconType } from "../../../../components/Icon";

interface IReplenishmentStartBlock {
  title?: string;
  description?: string;
  icon?: IconType;
  onClick?: () => void;
  disabled?: boolean;
  disabledReason?: string;
}

const ReplenishmentStartBlock:React.FC<IReplenishmentStartBlock> = ({
  title,
  description,
  icon,
  onClick,
  disabled,
  disabledReason,
}) => {
  return (
    <Tooltip overlay={disabled && disabledReason ? disabledReason : false}>
      <div
        className={`replenishment-start-block ${!!onClick && !disabled ? "cursor-pointer" : ""} ${disabled ? "disabled" : ""}`}
        style={disabledReason && disabled ? { cursor: "help" } : {}}
        onClick={!disabled ? onClick : () => false}
      >
        {icon && (
        <div className="icon-wrapper">
          <Icon
            name={icon}
            size={18}
          />
        </div>
        )}
        {title && <h2>{title}</h2>}
        {description && <p>{description}</p>}
      </div>
    </Tooltip>
  );
};

export default ReplenishmentStartBlock;
