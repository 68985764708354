import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import {
  useRecoilState, useRecoilValue, useResetRecoilState,
} from "recoil";
import { useHistory } from "react-router-dom";
import moment from "moment";
import DartButton from "../../../../components/DartButton";
import {
  allocQDataAtom,
  allocQExpandedListAtom,
  allocQListAtom,
  allocQSelectedListAtom,
  globalFiltersAtom,
} from "../../../../global/atoms";
import PageSummary from "../../../../components/PageSummary";
import { AllocQOverview } from "../../index";
import paths from "../../../../configs/paths";
import DartPrompt from "../../../../components/DartPrompt";
import { updateAllocQ } from "../../services/allocQ";
import PageTitle from "../../../../components/PageTitle";
import PillSelect from "../../../../components/PillSelect";

const AllocQReview = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const allocQList = useRecoilValue(allocQListAtom);
  const allocQData = useRecoilValue(allocQDataAtom);
  const filters = useRecoilValue(globalFiltersAtom);
  const [allocQSelectedList, setAllocQSelectedList] = useRecoilState(allocQSelectedListAtom);
  const [actionDisabled, setActionDisabled] = useState<boolean | undefined>(false);
  const resetAllocQList = useResetRecoilState(allocQListAtom);
  const resetAllocQData = useResetRecoilState(allocQDataAtom);
  const resetSelectedAllocQ = useResetRecoilState(allocQSelectedListAtom);
  const resetExpandedAllocQ = useResetRecoilState(allocQExpandedListAtom);
  const [showUpdatePrompt, setShowUpdatePrompt] = useState(false);
  const [iaqMonthFilter, setIAQMonthFilter] = useState<"all" | "recent" | any>(localStorage.getItem("iaqMonthFilter") || "recent");

  const formatter = Intl.NumberFormat("en", { notation: "standard", maximumFractionDigits: 0 });

  // Toggle function for IAQ month filter
  const toggleIAQMonthFilter = (selected?: string) => {
    setAllocQSelectedList([]);
    localStorage.setItem("iaqMonthFilter", selected || "recent");
    setIAQMonthFilter(selected || "recent");
  };

  const getSummaryContent = useMemo(() => {
    const totalIAQ = Object.values(allocQList?.data?.[iaqMonthFilter === "all" ? "all" : "recent"] || {}).reduce((acc: number, cur: any) => {
      const iSum: any = Object.values(cur || {}).reduce((iAcc: any, iCur: any) => {
        return iAcc + (+iCur.iaq || 0);
      }, 0) || 0;
      return acc + iSum;
    }, 0);
    const totalStyles = Object.values(allocQList?.data?.[iaqMonthFilter === "all" ? "all" : "recent"] || {}).reduce((acc: number, cur: any) => {
      const iSum: any = Object.values(cur || {}).reduce((iAcc: any, iCur: any) => {
        return iAcc + (+iCur.cc_count || 0);
      }, 0) || 0;
      return acc + iSum;
    }, 0);

    return [
      {
        name: "Total Initial Allocation Quantity",
        value: formatter.format(totalIAQ) || "-",
        flex: 2,
      },
      {
        name: "Total Style Colors",
        value: formatter.format(totalStyles) || "-",
        flex: 1,
      },
      {
        name: "Visible Newness Months",
        value: (
          <PillSelect
            selectOptions={[{
              key: "recent",
              value: "recent",
              label: "Recent Only",
              count: Object.keys(allocQList?.data?.recent || {})?.length,
            }, {
              key: "all",
              value: "all",
              label: "Show All",
              count: Object.keys(allocQList?.data?.all || {})?.length,
            }]}
            multiple={false}
            value={[allocQList?.status === "success" && Object.keys(allocQList?.data?.recent || {})?.length === 0 ? "all" : iaqMonthFilter]}
            onChange={(s) => toggleIAQMonthFilter(s?.[0])}
          />
        ),
        flex: 2,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocQList, iaqMonthFilter]);

  const handleSaveAllocQChanges = async () => {
    setShowUpdatePrompt(false);
    setLoading(true);
    const dataToSend: any = { data: [] };
    const keys = Object.keys(allocQData);
    const values: any = Object.values(allocQData);
    for (let i = 0; i < keys.length; i += 1) {
      const nestedValues: any = Object.values(values[i]?.data || {});
      for (let o = 0; o < nestedValues?.length; o += 1) {
        const selectedMonthGrouped = allocQSelectedList.filter((f: string) => f?.indexOf(keys[i]) > -1);
        if ((nestedValues[o]?.uas !== nestedValues[o]?.updated_uas || nestedValues[o]?.supply_by_date !== nestedValues[o]?.updated_supply_by_date)
            && selectedMonthGrouped?.some((a: string) => a.indexOf(nestedValues[o]?.division) > -1 && a.indexOf(nestedValues[o]?.department) > -1)) {
          if (nestedValues[o]?.updated_uas || nestedValues[o]?.updated_uas === 0 || nestedValues[o]?.updated_supply_by_date) {
            dataToSend.data.push({
              newness_id: nestedValues[o]?.newness_id,
              ...(nestedValues[o]?.updated_uas || nestedValues[o]?.updated_uas === 0 ? { uas: nestedValues[o]?.updated_uas } : {}),
              ...(nestedValues[o]?.updated_supply_by_date ? { supply_by_date: nestedValues[o]?.updated_supply_by_date } : {}),
            });
          }
        }
      }
    }
    await updateAllocQ(dataToSend,
      [resetAllocQList, resetAllocQData, resetSelectedAllocQ, resetExpandedAllocQ]);
    setLoading(false);
    setTimeout(() => {
      history.push(paths.initial_allocation_quantity_dashboard);
    }, 300);
  };

  useEffect(() => {
    setActionDisabled(allocQSelectedList?.length === 0);
  }, [allocQSelectedList]);

  const decideLatestUpdate = useMemo(() => {
    const entries: any = Object.values(allocQList?.data || {});
    if (entries?.length > 0 && filters.channel && filters.brand) {
      let maxDate = "01/01/1970";
      for (let i = 0; i < entries?.length; i += 1) {
        for (let o = 0; o < entries?.[i]?.length; o += 1) {
          const updatedAt = entries?.[i]?.[o]?.last_updated;
          const d = moment(updatedAt).diff(moment(maxDate), "days");
          if (d > 0) {
            maxDate = updatedAt;
          }
        }
      }
      return maxDate;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocQList]);

  return (
    <div className="alloc-q-review-page">
      <DartPrompt
        title="Confirm changes?"
        content={(
          <div>
            Only selected newnesses will be updated.
            <br />
            Others will reset to their initial state.
            <br />
            Do you want to proceed?
          </div>
        )}
        okText="Confirm"
        cancelText="Cancel"
        visible={showUpdatePrompt}
        onCancel={() => setShowUpdatePrompt(false)}
        onOk={() => handleSaveAllocQChanges()}
      />
      <div className="alloc-q-dashboard-header">
        <PageTitle
          title="Update Review"
          onBack={() => history.push(paths.initial_allocation_quantity_dashboard)}
        />
      </div>
      <div className="container-content">
        <PageSummary
          title="Initial Allocation Update Summary"
          summaryContent={getSummaryContent}
          actionButton={(
            <DartButton
              size="lg"
              label="Update Initial Allocation"
              onClick={() => setShowUpdatePrompt(true)}
              loading={loading}
              disabled={actionDisabled}
            />
          )}
          actionButtonSubtext={decideLatestUpdate ? `Last Updated: ${moment(decideLatestUpdate).format("MM/DD/YYYY")}` : undefined}
          loading={allocQList?.status === "revalidate"}
        />
        <div className="update-review-section">
          <AllocQOverview
            iaqMonthFilter={iaqMonthFilter}
            editable
            setActionDisabled={setActionDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default AllocQReview;
