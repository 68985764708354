import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import "./index.less";
import { useRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import moment, { Moment } from "moment";
import { Modal } from "antd";
import DartTable from "../../../../components/DartTable";
import DartPrompt from "../../../../components/DartPrompt";
import PageTitle from "../../../../components/PageTitle";
import paths from "../../../../configs/paths";
import columns from "../../widgets/AllocQOverview/columns";
import {
  allocQAllStoreListAtom, allocQDataAtom, allocQEditingAtom,
} from "../../../../global/atoms";
import useEnums from "../../../../utils/hooks/useEnums";
import { IAllocationQuantityCC } from "../../../../global/interfaces";
import {
  getAllocQData, getPOAClasses, updateAllocQ, updateIAQStorePOAs,
} from "../../services/allocQ";
import {
  AllocQCommentsSider, AllocQFiltersBlock, AllocQStyleDetails,
} from "../../index";
import {
  only, queryGet, renderTag,
} from "../../../../utils/helpers";
import { getAllStores } from "../../../store";
import { allocQInventoryDataAtom } from "../../../../global/atoms/alloc-q-inventory-data";
import { fetchInventoryDataForBrandAndChannel } from "../../../common/services/cc";

const AllocQEdit = () => {
  const { confirm } = Modal;
  const history = useHistory();
  const {
    division, department, tier,
  } = useEnums();
  const [fetchedAllocationData, setFetchedAllocationData] = useRecoilState(allocQDataAtom);
  const [initAllocQValues, setInitAllocQValues] = useState<any>({});
  const [allocQEditing, setAllocQEditing] = useRecoilState<{allocQ?: any, allocQValues?: any[]}>(allocQEditingAtom);
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [filteredList, setFilteredList] = useState<any>([]);
  const [appliedDataFilters, setAppliedDataFilters] = useState<any>({
    division: "ALL",
    department: "ALL",
    tier: "ALL",
    tag: "ALL",
    uas: "ALL",
    search: "",
    newness_date: "ALL",
    sort_by: "None",
  });
  const [updatesDone, setUpdatesDone] = useState(0);
  const [wasTouched, setWasTouched] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [discardModalVisible, setDiscardModalVisible] = useState(false);
  const [ccUpdated, setCCUpdated] = useState(0);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectedStyle, setSelectedStyle] = useState<IAllocationQuantityCC | undefined>(undefined);
  const [commentsVisibleFor, setCommentsVisibleFor] = useState<IAllocationQuantityCC | undefined>(undefined);
  const [allocQAllStoreList, setAllocQAllStoreList] = useRecoilState(allocQAllStoreListAtom);
  const queryFilters = {
    channel: queryGet("channel"),
    brand: queryGet("brand"),
    date: queryGet("date"),
  };
  const [inventoryData, setInventoryData] = useRecoilState(allocQInventoryDataAtom);

  // Fetch inventory data (slow function) for brand and channel - this is optimized to be debounced and
  // checked every 20 minutes per brand or channel change
  useEffect(() => {
    if (queryFilters.brand && queryFilters.channel) {
      fetchInventoryDataForBrandAndChannel(
        inventoryData, setInventoryData, queryFilters.brand as any, queryFilters.channel as any, true, 20,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFilters.brand, queryFilters.channel]);

  // Formatter to correctly display floats
  const formatter = Intl.NumberFormat("en", { notation: "standard" });

  // Check if any of the fields were touched (to display "discard changes")
  useEffect(() => {
    const isTouched = (allocQEditing.allocQValues || [])?.filter((s) => s.uas !== s.updated_uas)?.length > 0;
    if (isTouched) {
      setWasTouched(true);
    }

    if (allocQAllStoreList.status !== "success") {
      getAllStores(
        allocQAllStoreList, setAllocQAllStoreList, { channel: [queryFilters.channel], brand: [queryFilters.brand] },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If query filters are changed, fetch initial allocation quantities data
  // Fetch data for specific date provided in IAQ (if valid date)
  useEffect(() => {
    if (queryFilters.channel && queryFilters.brand && queryFilters.date) {
      setAllocQEditing({
        allocQ: {
          channel: queryFilters.channel,
          brand: queryFilters.brand,
          date: queryFilters.date,
        },
        allocQValues: [],
      });
      // Call Service used for fetching IAQ data for specific month
      getAllocQData(
        fetchedAllocationData, setFetchedAllocationData, queryFilters.date, only(["brand", "channel"], queryFilters),
      );
    } else {
      setAllocQEditing({ allocQ: {}, allocQValues: [] });
      history.push(paths.initial_allocation_quantity_dashboard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch data if CC IAQ was updated
  useEffect(() => {
    if (queryFilters.channel && queryFilters.brand && ccUpdated > 0) {
      getAllocQData(
        fetchedAllocationData, setFetchedAllocationData, queryFilters?.date, only(["brand", "channel"], queryFilters), false,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ccUpdated]);

  // Check if specific month IAQ data is loading state or not
  const isDataLoading = useMemo(() => {
    return ["request", "revalidate"].indexOf(fetchedAllocationData?.[queryFilters?.date]?.status) !== -1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedAllocationData]);

  // If date is not passed, navigate to dashboard, else apply filters to the list
  useEffect(() => {
    if (!queryFilters.date) {
      history.push(paths.initial_allocation_quantity_dashboard);
    } else {
      filterListAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocQEditing?.allocQValues]);

  // Check length of the filtered list of IAQs
  const filteredListLength = useMemo(() => {
    return Object.keys(filteredList || []).length;
  }, [filteredList]);

  // Check number of values being edited
  const allocQEditingLength = useMemo(() => {
    return allocQEditing?.allocQValues?.length || 0;
  }, [allocQEditing]);

  // Filter list according to filters selected or search value provided
  const filterListAction = () => {
    setFiltersExpanded(false);
    setFilteredList(allocQEditing?.allocQValues?.filter((q: IAllocationQuantityCC) => {
      let shouldReturn = true;
      // filter by division
      if (q?.division?.toUpperCase() !== appliedDataFilters.division?.toUpperCase()
        && appliedDataFilters.division !== "ALL" && appliedDataFilters.division) {
        shouldReturn = false;
      }
      // filter by department
      if (q?.department?.toUpperCase() !== appliedDataFilters.department?.toUpperCase()
        && appliedDataFilters.department !== "ALL" && appliedDataFilters.department) {
        shouldReturn = false;
      }
      // filter by tier
      if (q?.tier?.toString()?.trim()?.replace("TIER_", "") !== appliedDataFilters.tier?.replace("TIER_", "")
        && appliedDataFilters.tier !== "ALL" && !!appliedDataFilters.tier) {
        shouldReturn = false;
      }
      // filter by department
      if (renderTag(q?.tag)?.toUpperCase() !== renderTag(appliedDataFilters.tag)?.toUpperCase()
        && appliedDataFilters.tag !== "ALL" && appliedDataFilters.tag) {
        shouldReturn = false;
      }
      // filter by Final ISD
      if (q?.newness_date?.toUpperCase() !== appliedDataFilters.newness_date?.toUpperCase()
        && appliedDataFilters.newness_date !== "ALL" && appliedDataFilters.newness_date) {
        shouldReturn = false;
      }
      // filter by With UAS and Empty UAS
      if ((q?.uas && (+q?.uas === 0) && appliedDataFilters.uas === "true")) {
        shouldReturn = false;
      } else if ((q?.uas && (+q?.uas !== 0) && appliedDataFilters.uas === "false")) {
        shouldReturn = false;
      }
      // filter by search value in description and cc
      if (appliedDataFilters?.search
        && appliedDataFilters?.search !== ""
        && q?.description?.toLowerCase()?.indexOf(appliedDataFilters?.search?.toLowerCase()) === -1
        && q?.cc?.toLowerCase()?.indexOf(appliedDataFilters?.search?.toLowerCase()) === -1
        && (q?.class || q?.default_class || "empty")?.toLowerCase()?.indexOf(appliedDataFilters?.search?.toLowerCase()) === -1) {
        shouldReturn = false;
      }
      return shouldReturn;
    })?.map((each: any) => each.cc));
  };

  // Handler function when UAS cell is updated. updates "updated_uas" field that is sent to BE later on
  const handleUASChange = useCallback((newness_id: number, value: string) => {
    // if more than one is selected, update in bulk
    if (selectedRows?.length > 1 && selectedRows?.indexOf(newness_id) > -1) {
      confirm({
        title: "Confirm bulk UAS update?",
        content: (
          <>
            <div>
              You are changing UAS value to
              {" "}
              <b>{parseFloat(value)}</b>
              {" "}
              for total of
              {" "}
              <b>
                {selectedRows?.length}
                {" "}
                selected CCs.
              </b>
              <br />
              Are you sure ?
            </div>
          </>
        ),
        okText: "Confirm",
        onOk: () => {
          setAllocQEditing((prev) => {
            return {
              allocQ: prev.allocQ,
              allocQValues: (prev.allocQValues || []).map((each: any) => {
                if (selectedRows.indexOf(each.newness_id) > -1) {
                  return {
                    ...each,
                    updated_uas: +value,
                  };
                }
                return each;
              }),
            };
          });
        },
      });
    } else {
      // else update only for current or 1 selected row
      setAllocQEditing((prev) => {
        return {
          allocQ: prev.allocQ,
          allocQValues: (prev.allocQValues || []).map((each: any) => {
            if (each.newness_id === newness_id) {
              return {
                ...each,
                updated_uas: +value,
              };
            }
            return each;
          }),
        };
      });
    }
    setUpdatesDone((prev) => prev + 1);
  }, [selectedRows, setAllocQEditing, updatesDone]);

  // Handler function when Supply by date cell is updated. updates "updated_supply_by_date" field that is sent to BE later on
  const handleSupplyByDateChange = useCallback((newness_id: number, momentValue: Moment | undefined) => {
    const value = momentValue ? momentValue.format("MM/DD/YYYY") : undefined;
    // if more than one is selected, update in bulk
    if (selectedRows?.length > 1 && selectedRows?.indexOf(newness_id) > -1) {
      confirm({
        title: "Confirm bulk Supply by Date update?",
        content: (
          <>
            <div>
              You are changing Supply by Date value to
              {" "}
              <b>{value}</b>
              {" "}
              for total of
              {" "}
              <b>
                {selectedRows?.length}
                {" "}
                selected CCs.
              </b>
              <br />
              Are you sure ?
            </div>
          </>
        ),
        okText: "Confirm",
        onOk: () => {
          setAllocQEditing((prev) => {
            return {
              allocQ: prev.allocQ,
              allocQValues: (prev.allocQValues || []).map((each: any) => {
                if (selectedRows.indexOf(each.newness_id) > -1) {
                  return {
                    ...each,
                    updated_supply_by_date: value,
                  };
                }
                return each;
              }),
            };
          });
        },
      });
    } else {
      // else update only for current or 1 selected row
      setAllocQEditing((prev) => {
        return {
          allocQ: prev.allocQ,
          allocQValues: (prev.allocQValues || []).map((each: any) => {
            if (each.newness_id === newness_id) {
              return {
                ...each,
                updated_supply_by_date: value,
              };
            }
            return each;
          }),
        };
      });
    }
    setUpdatesDone((prev) => prev + 1);
  }, [selectedRows, setAllocQEditing, updatesDone]);

  // Handler function when POA cell is updated. updates "updated_poa_class" field that is sent to BE later on
  const handlePOAChange = useCallback((newness_id: number, value: {id: number, name: string}) => {
    setAllocQEditing((prev) => {
      return {
        allocQ: prev.allocQ,
        allocQValues: (prev.allocQValues || []).map((each: any) => {
          if (each.newness_id === newness_id) {
            return {
              ...each,
              updated_poa_class: value,
            };
          }
          return each;
        }),
      };
    });
    setUpdatesDone((prev) => prev + 1);
  }, [selectedRows, setAllocQEditing, updatesDone]);

  // On row selection change, update list with selected boolean
  useEffect(() => {
    setAllocQEditing((prev) => ({
      ...prev,
      allocQValues: (prev?.allocQValues || [])?.map((s) => ({
        ...s,
        selected: selectedRows.indexOf(s.newness_id) !== -1,
        isOthersSelected: selectedRows?.length > 1,
      })),
    }));
  }, [selectedRows, updatesDone]);

  // Handler function when POA cell is updated. updates "updated_poa" field that is sent to BE later on
  const handleIAQDetailsChange = async (
    newness_id: number, value: string, selectedStoreIdsWithPoa?: { store_id?: number, poa_qty?: string }[],
  ) => {
    // update poa_qty's and UAS from Details Screen
    const detailsDataToSend: any = {
      data: {
        uas: value.toString(), newness_id, selected_stores: [],
      },
    };

    // Add selected stores to the request
    selectedStoreIdsWithPoa?.map((each) => {
      detailsDataToSend.data.selected_stores.push({
        store_id: each.store_id,
        poa_qty: each.poa_qty?.toString(),
      });
      return each;
    });

    // Send request to BE
    await updateIAQStorePOAs(
      detailsDataToSend, newness_id, () => {
        // Close modal & cleanup
        setSelectedStyle(undefined);
        setUpdatesDone(0);
        // Refresh the page data
        setCCUpdated((prev) => prev + 1);
      },
    );
  };

  // Async function that handles selection of CC to open modal
  const handleStyleClick = async (rowData?: IAllocationQuantityCC) => {
    setSelectedStyle(rowData);
  };

  // Function that opens comment sider for specific CC
  const handleCommentsOpen = (cc: IAllocationQuantityCC) => {
    setCommentsVisibleFor(cc);
  };

  // Memoized Columns with required functions provided
  const getColumns = useMemo(() => {
    return columns({
      editable: true,
      isFrozenLeft: false,
      showDepartmentAndDivision: true,
      onUASChange: handleUASChange,
      onSupplyByDateChange: handleSupplyByDateChange,
      onStyleClick: handleStyleClick,
      onPOAChange: handlePOAChange,
      poaClasses: getPOAClasses(),
      onCommentsOpen: handleCommentsOpen,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFilters, updatesDone, selectedRows]);

  // Handler to send request updated IAQ UAS values to BE
  const handleSaveAllocQChanges = async () => {
    setLoading(true);
    const dataToSend: any = { data: [] };
    const tempDataToSend: any = { data: [] };
    allocQEditing?.allocQValues?.map((each) => {
      if (each.uas !== each.updated_uas || each.supply_by_date !== each.updated_supply_by_date) {
        if (each.updated_uas || each.updated_uas === 0 || each.updated_supply_by_date) {
          dataToSend.data.push({
            newness_id: each.newness_id,
            ...(each.updated_uas || each.updated_uas === 0 ? { uas: each.updated_uas } : {}),
            ...(each.updated_supply_by_date ? { supply_by_date: each.updated_supply_by_date } : {}),
          });
        }
      }
      if (each.updated_poa_class?.id !== each.poa_class?.id) {
        tempDataToSend.data.push({ newness_id: each.newness_id, poa_class: each.updated_poa_class?.id });
      }
      return each;
    });

    // Call Service to update UAS values in IAQ (Bulk)
    await updateAllocQ(
      dataToSend, [], async () => {
        // after updateAllocQ finishes, re-fetch data
        await getAllocQData(
          fetchedAllocationData, setFetchedAllocationData, queryFilters?.date, only(["brand", "channel"], queryFilters), false,
        );
      },
    );
    setLoading(false);
    setUpdatesDone(0);
    setCCUpdated((prev) => prev + 1);
    setSelectedRows([]);
  };

  // Handler to discard all changes applied inside Edit screen
  const handleDiscardAllocQChanges = () => {
    setAllocQEditing({ allocQ: allocQEditing?.allocQ, allocQValues: [...initAllocQValues] });
    setDiscardModalVisible(false);
    setUpdatesDone(0);
    setSelectedRows([]);
  };

  // Set recoil state of IAQ being edited according to fetched data
  useEffect(() => {
    setAllocQEditing((prev) => {
      return {
        allocQ: {
          ...prev.allocQ,
          initial_allocation: Object.values(fetchedAllocationData?.[prev.allocQ?.date]?.data || {})
            .reduce((acc: number, cur: any) => {
              return acc + cur.iaq;
            }, 0),
          style_colors: fetchedAllocationData?.[prev.allocQ?.date]?.data
            ? Object.values(fetchedAllocationData?.[prev?.allocQ?.date]?.data)?.length
            : 0,
        },
        allocQValues: Object.values(fetchedAllocationData?.[prev.allocQ?.date]?.data || {}),
      };
    });
    setInitAllocQValues(Object.values(fetchedAllocationData?.[allocQEditing.allocQ?.date]?.data || {}));
  }, [fetchedAllocationData, ccUpdated]);

  // Cleanup function to reset recoil state of IAQ being edited
  useEffect(() => {
    return () => {
      setAllocQEditing({ allocQ: {}, allocQValues: [] });
    };
  }, []);

  // Parse, sort and filter through values that need to be displayed on the table
  const tableValues = useMemo(() => {
    const listValues = (allocQEditing?.allocQValues || [])?.filter((dt: any) => {
      return filteredList?.find((fL: any) => fL === dt.cc);
    });

    // Sort the list according to the selected sort_by value
    let sortedList: any;
    if (appliedDataFilters?.sort_by !== "None" && appliedDataFilters?.sort_by?.startsWith("UAS_")) {
      sortedList = listValues?.sort((a: any, b: any) => {
        if (+a.updated_uas === +b.updated_uas) {
          return a.cc - b.cc;
        }
        return appliedDataFilters?.sort_by?.endsWith("_ASC") ? +a.updated_uas - +b.updated_uas : +b.updated_uas - +a.updated_uas;
      });
    } else if (appliedDataFilters?.sort_by !== "None" && appliedDataFilters?.sort_by?.startsWith("QTY_")) {
      sortedList = listValues?.sort((a: any, b: any) => {
        if (+a.iaq === +b.iaq) {
          return a.cc - b.cc;
        }
        return appliedDataFilters?.sort_by?.endsWith("_ASC") ? +a.iaq - +b.iaq : +b.iaq - +a.iaq;
      });
    } else {
      sortedList = listValues?.sort((a: any, b: any) => {
        return a.cc - b.cc;
      });
    }

    const sortedListWithInventory = sortedList?.map((each: IAllocationQuantityCC) => {
      const channel_brand_key = `${queryFilters.channel}_${queryFilters.brand}`;
      if (each.cc) {
        return { ...each, inventoryData: inventoryData?.data?.[channel_brand_key]?.data?.[each.cc] };
      }
      return each;
    });

    return sortedListWithInventory;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocQEditing, filteredList, inventoryData, queryFilters.brand, queryFilters.channel]);

  // Get unique tags from tableValues
  const uniqueTags = useMemo(() => {
    const tags: string[] = [];
    if (allocQEditing?.allocQValues) {
      for (let i = 0; i < allocQEditing?.allocQValues?.length; i += 1) {
        if (allocQEditing?.allocQValues?.[i]?.tag && tags?.indexOf(allocQEditing?.allocQValues?.[i]?.tag) === -1) {
          tags.push(allocQEditing?.allocQValues?.[i]?.tag);
        }
      }
    }
    return tags;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableValues]);

  // Get unique newness dates from tableValues
  const uniqueNewnessDates = useMemo(() => {
    const newness_dates: string[] = [];
    if (allocQEditing?.allocQValues) {
      for (let i = 0; i < allocQEditing?.allocQValues?.length; i += 1) {
        if (allocQEditing?.allocQValues?.[i]?.newness_date && newness_dates?.indexOf(allocQEditing?.allocQValues?.[i]?.newness_date) === -1) {
          newness_dates.push(allocQEditing?.allocQValues?.[i]?.newness_date);
        }
      }
    }
    return newness_dates;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableValues]);

  return (
    <div className="alloc-q-edit-page">
      <AllocQStyleDetails
        selectedStyle={selectedStyle}
        setSelectedStyle={setSelectedStyle}
        onUpdate={handleIAQDetailsChange}
      />
      <div className="title-wrap">
        <PageTitle
          title={queryFilters?.date ? moment(queryFilters?.date).format("MMMM YYYY") : "N/A"}
          rightNode={(
            <div className="totals">
              <div className="initial-allocation">
                <div className="value">
                  {allocQEditing?.allocQ?.initial_allocation
                    ? formatter.format(allocQEditing?.allocQ?.initial_allocation)
                    : 0}
                </div>
                <div className="label">Initial Allocation</div>
              </div>
              <div className="style-colors">
                <div className="value">{allocQEditing?.allocQ?.style_colors ? formatter.format(allocQEditing?.allocQ?.style_colors) : 0}</div>
                <div className="label">Style Colors</div>
              </div>
            </div>
          )}
          onBack={() => { history.push(paths.initial_allocation_quantity_dashboard); }}
        />
      </div>
      <AllocQFiltersBlock
        filtersExpanded={filtersExpanded}
        setFiltersExpanded={setFiltersExpanded}
        filteredCount={filteredListLength}
        totalCount={allocQEditingLength}
        updatesDone={updatesDone}
        loading={loading}
        setDiscardModalVisible={setDiscardModalVisible}
        handleSaveAllocQChanges={handleSaveAllocQChanges}
        appliedDataFilters={appliedDataFilters}
        setAppliedDataFilters={setAppliedDataFilters}
        filterListAction={filterListAction}
        division={division}
        department={department}
        tier={tier}
        tags={uniqueTags || []}
        newnessDates={uniqueNewnessDates || []}
        wasTouched={wasTouched}
      />
      <div className={`alloc-q-edit-table ${filtersExpanded ? "condense" : ""}`}>
        <DartPrompt
          title="Discard Existing Changes?"
          content={(
            <div>Changes since you got on this page will be reverted to original</div>
          )}
          okText="Confirm"
          cancelText="Cancel"
          visible={discardModalVisible}
          onCancel={() => setDiscardModalVisible(false)}
          onOk={() => handleDiscardAllocQChanges()}
        />
        <DartTable
          key={`${filteredListLength.toString()}_${ccUpdated.toString()}`}
          loading={isDataLoading || allocQAllStoreList?.status === "request"}
          tableStyle="dark"
          height={filtersExpanded ? "calc(100vh - 700px)" : "calc(100vh - 339px)"}
          rowKey="cc"
          width="100%"
          data={tableValues || []}
          checkboxSize="sm"
          columns={getColumns}
          fixed
          emptyRenderer={() => (!isDataLoading
            ? <div style={{ padding: "48px calc(50% - 200px)" }}>Initial Allocation Quantities with selected filters is empty</div>
            : false)}
          headerHeight={34}
          onSelectedRowsChange={(rows) => {
            setSelectedRows(rows);
            setUpdatesDone((prev) => prev + 1);
          }}
          selectable
          selectColumnKey="newness_id"
          fixedFirstColumn
          hasSelectAll
          selectionColumnWidth={90}
          defaultSelectedRowKeys={selectedRows}
        />
      </div>
      <AllocQCommentsSider
        cc={commentsVisibleFor}
        setCC={setCommentsVisibleFor}
      />
    </div>
  );
};

export default AllocQEdit;
