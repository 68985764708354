const authToken = () => {
  const recoilState = localStorage.getItem("recoil-persist");
  if (recoilState) {
    const token = JSON.parse(recoilState).user?.data?.idToken?.jwtToken;
    return token;
  }
  return false;
};

export default authToken;
