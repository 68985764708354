import React, { useEffect } from "react";
import "./index.less";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { message } from "antd";
import { selectedUserAtom, userListAtom } from "../../../../global/atoms";
import paths from "../../../../configs/paths";
import { getUsers } from "../../services/user";
import { IUser } from "../../../../global/interfaces";
import { UserCreate, UserEdit } from "../../index";

const UserLayout:React.FC = () => {
  const { username } = useParams<{username: string}>();
  const [selectedUser, setSelectedUser] = useRecoilState<any>(selectedUserAtom);
  const [userList, setUserList] = useRecoilState(userListAtom);
  const history = useHistory();

  useEffect(() => {
    if (username) {
      setSelectedUser(username);
    } else if (selectedUser && !username && window.location.href.indexOf(paths.user_create) === -1) {
      history.push(paths.user_list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, username]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedUser]);

  // on ID change fetch users if no matching user with ID is found. if after this, still not found, redirect back (below useEffect)
  useEffect(() => {
    if (username && (userList?.data?.filter((u: IUser) => u.username === username)?.length === 0 || userList?.status !== "success")) {
      getUsers(userList, setUserList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  // check if user with ID exists, else redirect back to list
  useEffect(() => {
    if ((username && selectedUser
      && userList?.data?.length > 0
      && userList?.status === "success"
      && userList?.data?.filter((u: IUser) => u.username === username)?.length === 0) || (username && userList?.status === "failed")) {
      message.error("User not found");
      history.push(paths.user_list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, userList]);

  return (
    <div className="user-layout-wrap">
      <div className="user-layout">
        {selectedUser ? (
          // eslint-disable-next-line react/jsx-no-undef
          <UserEdit
            onBack={() => { setSelectedUser(undefined); history.push(paths.user_list); }}
          />
        ) : (
          <UserCreate
            onBack={() => { setSelectedUser(undefined); history.push(paths.user_list); }}
          />
        )}
      </div>
    </div>
  );
};

export default UserLayout;
