import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const poaChannelAtom = atom({
  key: "poa-channel",
  default: "ALL",
  effects_UNSTABLE: [persistAtom],
});
