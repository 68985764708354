import React from "react";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import "./index.less";
import DartCheckbox from "../DartCheckbox";

interface IDartCollapseCheckboxGroup {
  title?: string;
  options: { label: string, value: string }[];
  value: { label: string, value: string }[];
  onChange: (value: { label: string, value: string }[]) => void;
  isOpenByDefault?: boolean;
  editable?: boolean;
}

const DartCollapseCheckboxGroup: React.FC<IDartCollapseCheckboxGroup> = ({
  title,
  options,
  value,
  onChange,
  isOpenByDefault = true,
  editable = true,
}) => {
  const [open, setOpen] = React.useState(isOpenByDefault);

  const handleCollapseToggle = () => {
    setOpen(!open);
  };

  const handleCheckboxSelect = (option: { label: string, value: string}, checked: boolean) => {
    if (checked) {
      onChange([...value, option]);
    } else {
      onChange(value.filter((v) => v.value !== option.value));
    }
  };

  return (
    <div className="dart-collapse-checkbox-group">
      <div
        className="title"
        onClick={handleCollapseToggle}
      >
        <div className="left">
          {title}
        </div>
        <div className="right">
          {open ? (<MinusOutlined />) : (<PlusOutlined />)}
        </div>
      </div>
      <div className={`body ${open ? "visible" : "hidden"}`}>
        {options.map((option) => (
          <div
            key={option.value}
            className="checkbox-wrap"
          >
            <DartCheckbox
              size="sm"
              label={option.label}
              checked={!!value?.find((v) => v.value === option.value)}
              onChange={(checked) => handleCheckboxSelect(option, checked)}
              disabled={!editable}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DartCollapseCheckboxGroup;
