import React, { useEffect, useState } from "react";
import "./index.less";
import { Checkbox } from "antd";

interface IDartCheckbox {
  label?: string;
  checked?: boolean;
  onChange?: (isChecked: boolean, event?: any) => void,
  disabled?: boolean;
  size?: "xs" | "sm" | "md" | "lg";
  style?: any;
  className?: string;
}

const DartCheckbox: React.FC<IDartCheckbox> = ({
  label,
  disabled,
  size = "md",
  onChange,
  checked,
  style = {},
  className,
}) => {
  const elementId = `dart-checkbox-${Math.random().toString(36).substring(2)}`;
  const [selected, setSelected] = useState<boolean>(checked || false);

  useEffect(() => {
    setSelected(checked || false);
  }, [checked]);

  const handleCheckbox = (isChecked: boolean, event?: any) => {
    setSelected(isChecked);
    onChange?.(isChecked, event);
  };

  return (
    <div
      className={`dart-checkbox ${disabled ? "disabled" : ""} ${size} ${className}`}
    >
      <Checkbox
        tabIndex={-1}
        id={elementId}
        defaultChecked={selected}
        checked={selected}
        onChange={(e) => handleCheckbox(e.target.checked, e)}
        disabled={!!disabled}
        style={style}
      />
      {label && (
      <label
        htmlFor={elementId}
        className={`checkbox-title ${disabled ? "disabled" : ""} ${size} `}
      >
        {label}
      </label>
      )}
    </div>
  );
};

export default DartCheckbox;
