import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { Auth } from "aws-amplify";
import { awsBaseUrl } from "../configs/awsconfig";
import { authToken } from "../utils/helpers";
import paths from "../configs/paths";

const apiClient = axios.create({
  baseURL: awsBaseUrl,

  headers: {
    common: {
      Accept: "application/json",
      "Content-Type": "application/json",
    } as any,
  },
});

const apiClientWithoutRetry = axios.create({
  baseURL: awsBaseUrl,

  headers: {
    common: {
      Accept: "application/json",
      "Content-Type": "application/json",
    } as any,
  },
});

// eslint-disable-next-line consistent-return
const refreshAuthLogic = async (failedRequest: any) => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    await cognitoUser.refreshSession(currentSession.getRefreshToken(), async (err: any, session: any) => {
      if (err) {
        await Auth.signOut();
        window.location.href = `${paths.signin}#loggedout`;
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(err?.message || err || "Invalid Session. You have been logged out");
      }
      const { idToken } = session;
      // eslint-disable-next-line no-param-reassign
      failedRequest.response.config.headers.Authorization = `Bearer ${idToken.getJwtToken()}`;
      return Promise.resolve();
    });
  } catch (e: any) {
    if (e.message === "The user is not authenticated" || e === "The user is not authenticated") {
      await Auth.signOut();
      window.location.href = `${paths.signin}#loggedout`;
      return Promise.reject(e.message || "Invalid Session. You have been logged out");
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(e.message || "Unknown error");
  }
};

createAuthRefreshInterceptor(
  apiClient, refreshAuthLogic, {
    statusCodes: [401, 403], interceptNetworkError: true, pauseInstanceWhileRefreshing: true,
  },
);

apiClient.interceptors.request.use((config) => {
  const token = authToken();

  if (config.headers && token && token.length > 0) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

apiClient.interceptors.response.use((response) => {
  return response;
},
async (error) => {
  if (error?.response?.data?.error?.message) {
    // If the error response contains the expected structure
    return Promise.reject(error.response.data.error.message);
  } if (error?.response?.data?.message) {
    // If the error response contains a different structure
    return Promise.reject(error.response.data.message);
  }
  // Fallback to generic error message if the structure is unexpected
  return Promise.reject(error?.message || error?.toString());
});

apiClientWithoutRetry.interceptors.request.use((config) => {
  const token = authToken();

  if (config.headers && token && token.length > 0) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

apiClientWithoutRetry.interceptors.response.use((response) => {
  return response;
},
async (error) => {
  if (error?.response?.data?.error?.message) {
    // If the error response contains the expected structure
    return Promise.reject(error.response.data.error.message);
  } if (error?.response?.data?.message) {
    // If the error response contains a different structure
    return Promise.reject(error.response.data.message);
  }
  // Fallback to generic error message if the structure is unexpected
  return Promise.reject(error?.message || error?.toString());
});

export { apiClientWithoutRetry };

export default apiClient;
