import React from "react";
import "./index.less";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import { Amplify } from "aws-amplify";
import AppRouter from "./router/AppRouter";
import awsconfig from "./configs/awsconfig";

Amplify.configure(awsconfig);

ReactDOM.render(<React.StrictMode><RecoilRoot><AppRouter /></RecoilRoot></React.StrictMode>,
  document.getElementById("root"));
