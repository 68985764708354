import paths from "../../configs/paths";

export const globalFiltersRowVisibleFor = [
  paths.newness, // Dashboard
  paths.newness_allocation, // Allocation Newness
  paths.review_ccs, // Review Newness Allocation

  paths.initial_allocation_quantity_dashboard, // Initial Allocation Quantity Dashboard
  paths.initial_allocation_quantity_review, // Initial Allocation Quantity Dashboard
  paths.initial_allocation_quantity_edit, // Initial Allocation Quantity Dashboard

  paths.replenishment_dashboard, // Replenishment Dashboard
  paths.start_manual_replenishment, // Start Manual Replenishment
  paths.manual_replenishment_step_one, // Manual Replenishment Step 1
  paths.manual_replenishment_step_two, // Manual Replenishment Step 2
];

export const globalFiltersRowActiveFor = [
  paths.newness, // Dashboard

  paths.initial_allocation_quantity_dashboard, // Initial Allocation Quantity Dashboard

  paths.replenishment_dashboard, // Replenishment Dashboard
];
