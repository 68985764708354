import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import "./index.less";
import {
  Col, InputNumber, Row, Segmented,
} from "antd";
import moment from "moment";
import { useRecoilState, useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import { IAllocationQuantityCC, IStoreProfile } from "../../../../global/interfaces";
import DartPrompt from "../../../../components/DartPrompt";
import DartTable from "../../../../components/DartTable";
import Icon from "../../../../components/Icon";
import { fetchIAQStoreStages, getIAQAvailableAndSelectedStores } from "../../services/allocQ";
import { allocQAllStoreListAtom, globalFiltersAtom } from "../../../../global/atoms";
import { getAllStores } from "../../../store";
import paths from "../../../../configs/paths";
import { fetchInventoryDataForCC } from "../../../common";
import columnsAQ from "./columnsAQ";
import columnsDC from "./columnsDC";
import { renderTag } from "../../../../utils/helpers";
import IAQStagesBlock from "./IAQStoreStagesBlock";
import IAQStoreStageButtonsBlock from "./IAQStoreStageButtonsBlock";

export interface ISelectedCCStyleWithStores extends IAllocationQuantityCC {
  iaq?: number;
  uas?: number;
  updated_uas?: number;
  stores?: IStoreProfile[];
  excludedStores?: IStoreProfile[];
  includedStores?: IStoreProfile[];
  allocated_store_numbers?: number[];
}

interface IAllocQStyleDetails {
  selectedStyle?: IAllocationQuantityCC;
  setSelectedStyle?: any;
  onUpdate?: (newness_id: number, value: string, selectedStoreIdsWithPoa?: { store_id?: number, poa_qty?: string }[]) => void;
}

const AllocQStyleDetails:React.FC<IAllocQStyleDetails> = ({
  selectedStyle,
  setSelectedStyle,
  onUpdate,
}) => {
  const history = useHistory();
  const DEFAULT_UAS = 1; // Can't be 0 as it's divisor
  const [selectedStyleData, setSelectedStyleData] = useState<ISelectedCCStyleWithStores | undefined>(undefined);
  const [storesLoading, setStoresLoading] = useState(false);
  const [styleStoreList, setStyleStoreList] = useState<any>({ status: "initial", data: {} });
  const [initUAS, setInitUAS] = useState<number | undefined>(undefined);
  const [UASValue, setUASValue] = useState<number | undefined>(undefined);
  const [updateCount, setUpdateCount] = useState<number>(0);
  const [allocQAllStoreList, setAllocQAllStoreList] = useRecoilState(allocQAllStoreListAtom);
  const [loading, setLoading] = useState(false);
  const [ccInventory, setCCInventory] = useState<{status: string, data: any[], total?: number, future_total?: number}>({
    status: "initial", data: [], total: 0, future_total: 0,
  });
  const selectedFilters = useRecoilValue(globalFiltersAtom);
  const [selectedMode, setSelectedMode] = useState<"AQ" | "DC">("AQ");
  const [selectedStage, setSelectedStage] = useState(5);
  const [storeStages, setStoreStages] = useState({ status: "initial", data: {} });
  const [stagesVisible, setStagesVisible] = useState(false);

  // Callback function that fetches store/aq/poa data for each style (CC)
  const fetchDataForStyle = useCallback(async (style: IAllocationQuantityCC, initialSelectedUAS: number | undefined = undefined) => {
    setSelectedStyleData(undefined);
    setStoresLoading(true);
    setLoading(true);

    // If for some reasons stores are not fetched already, re-fetch all stores...
    if (selectedStyle && allocQAllStoreList?.status !== "success") {
      await getAllStores(
        allocQAllStoreList, setAllocQAllStoreList, { channel: [selectedFilters?.channel], brand: [selectedFilters?.brand] },
      );
    }

    if (selectedStyle) {
      await fetchIAQStoreStages(
        storeStages, setStoreStages, selectedStyle,
      );
      if (!selectedStyle.inventoryData) {
        await fetchInventoryDataForCC(
          ccInventory, setCCInventory, selectedStyle, selectedFilters?.channel,
        );
      }
    }

    // Call api to get available and selected stores for the style and callback a function that will merge storeIds with store profiles
    await getIAQAvailableAndSelectedStores(
      styleStoreList, setStyleStoreList, selectedStyle?.newness_id, (storeValues: any) => {
        const availableStoreIds = storeValues?.data?.available_store_ids?.length > 0 ? storeValues?.data?.available_store_ids : [];
        const selectedStores = storeValues?.data?.selected_stores?.length > 0 ? storeValues?.data?.selected_stores : [];
        const allocatedStoreIds = storeValues?.data?.allocated_store_numbers?.length > 0 ? storeValues?.data?.allocated_store_numbers : [];

        // Intersect availableStores and allStores to get the full store profile for available stores
        const availableStores = (allocQAllStoreList?.data || []).filter((st: IStoreProfile) => {
          return (availableStoreIds || [])?.map((s: { poa_qty: string, store_id: number }) => s.store_id).find((e: number) => e === st.number);
        });

        // Merge all together - selection statuses, poa, iaq and store information
        const availableStoresListWithDetails = (availableStores || [])?.map((st: IStoreProfile) => {
          const storeClone = { ...st };
          const defaultPOA = availableStoreIds.find((e: { poa_qty: string, store_id: number }) => e.store_id === st.number)?.poa_qty || 0;
          const selectedPOA = selectedStores.find((e: { poa_qty: string, store_id: number }) => e.store_id === st.number)?.poa_qty || 0;
          storeClone.poa = selectedPOA || defaultPOA;
          storeClone.updatedPoa = selectedPOA || defaultPOA;
          storeClone.iaq = Math.ceil((storeClone.poa || 0) * (initialSelectedUAS || initUAS || DEFAULT_UAS));
          storeClone.updatedIaq = Math.ceil((storeClone.poa || 0) * (initialSelectedUAS || initUAS || DEFAULT_UAS));
          storeClone.selected = !!selectedStores.find((e: { store_id: number, poa_qty: string }) => e.store_id === st.number);
          return storeClone;
        });

        if (style && style.cc) {
          const data = {
            ...style,
            stores: availableStoresListWithDetails.sort((a: IStoreProfile, b: IStoreProfile) => {
              // sort selected stores first and deselected store last
              if (a?.selected && !b?.selected) {
                return -1;
              }
              if (!a?.selected && b?.selected) {
                return 1;
              }
              // after sorting selected/deselected stores, sort by name
              if (a?.name && b?.name && a.name < b.name) {
                return -1;
              }
              if (a?.name && b?.name && a.name > b.name) {
                return 1;
              }
              return 0;
            }) || [],
            allocated_store_numbers: allocatedStoreIds || [],
            inventoryData: style.inventoryData,
          };
          setSelectedStyleData(data);
        }
      },
    );

    setStoresLoading(false);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UASValue, initUAS, selectedStyle, styleStoreList, selectedFilters?.channel, allocQAllStoreList?.status]);

  useEffect(() => {
    if (allocQAllStoreList?.status === "success" && !!selectedStyleData && storeStages?.status === "success") {
      let includedStoresList: IStoreProfile[] = [];
      // noinspection JSMismatchedCollectionQueryUpdate
      let excludedStoresList: IStoreProfile[] = [];
      if (selectedStage === 1) {
        // Included Stores on other stages are coming from stages API
        includedStoresList = (allocQAllStoreList?.data || [])?.filter((st: IStoreProfile) => {
          return ((storeStages?.data as any)?.[`Stage ${selectedStage}`] || {})?.response?.find((e: { store_id: number }) => e.store_id === st.id);
        });
      } else if (selectedStage === 5) {
        // Included Stores on Stage 5 are coming from backend
        includedStoresList = selectedStyleData?.stores || [];
        // Excluded Stores on Stage 5 are coming from stages API
        excludedStoresList = (allocQAllStoreList?.data || [])?.filter((st: IStoreProfile) => {
          return !((storeStages?.data as any)?.[`Stage ${selectedStage}`] || {})?.response?.find((e: { store_id: number }) => e.store_id === st.id);
        });
      } else {
        // Included Stores on other stages are coming from stages API
        includedStoresList = (allocQAllStoreList?.data || [])?.filter((st: IStoreProfile) => {
          return ((storeStages?.data as any)?.[`Stage ${selectedStage}`] || {})?.response?.find((e: { store_id: number }) => e.store_id === st.id);
        });
        // Excluded Stores on other stages are coming from stages API
        excludedStoresList = (allocQAllStoreList?.data || [])?.filter((st: IStoreProfile) => {
          return !((storeStages?.data as any)?.[`Stage ${selectedStage}`] || {})?.response?.find((e: { store_id: number }) => e.store_id === st.id);
        });
      }

      // Filter out excluded from last stage stores
      const excludedAfterLastStage = selectedStage > 1 ? (excludedStoresList || [])?.filter((st: IStoreProfile) => {
        return (storeStages?.data as any)?.[`Stage ${selectedStage - 1}`]?.response?.find((e: { store_id: number }) => e.store_id === st.id);
      }) : excludedStoresList;

      // Filter stores in both included and excluded lists
      const storesInBoth = (excludedAfterLastStage || [])?.filter((st: IStoreProfile) => {
        return includedStoresList.find((e: IStoreProfile) => e.id === st.id);
      });

      // Set included and excluded stores
      setSelectedStyleData((prev: any) => {
        return {
          ...prev,
          excludedStores: (excludedAfterLastStage || []).map((e) => {
            return {
              ...e,
              selected: false,
              is_excluded: true,
            };
          }),
          includedStores: ((selectedStage === 5 ? selectedStyleData?.stores : includedStoresList) || []).filter((st: IStoreProfile) => {
            return !storesInBoth.find((e: IStoreProfile) => e.id === st.id);
          })?.map((e) => {
            return {
              ...e,
              ...(selectedStage === 5 ? {} : { selected: true }),
              is_excluded: false,
            };
          }),
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStage, allocQAllStoreList?.data, selectedStyleData?.stores, storeStages?.data, allocQAllStoreList?.status]);

  // Recalculate POA when UAS value changes
  useEffect(() => {
    setSelectedStyleData((prev: any) => {
      return {
        ...(prev || {}),
        stores: [...(prev || { stores: [] })?.stores?.map((st: IStoreProfile) => {
          const storeClone = { ...st };

          storeClone.updatedIaq = Math.ceil(st?.updatedPoa !== st?.poa
            ? ((st as any)?.updatedPoa || 0) * (UASValue || UASValue === 0 ? UASValue : (initUAS || DEFAULT_UAS))
            : ((st as any)?.poa || 0) * (UASValue || UASValue === 0 ? UASValue : (initUAS || DEFAULT_UAS)));
          return storeClone;
        })],
      };
    });
  }, [UASValue, initUAS]);

  // Fetch data for the selected style when the selectedStyle prop changes
  useEffect(() => {
    if (selectedStyle) {
      setInitUAS(selectedStyle.uas || DEFAULT_UAS);
      setUASValue(selectedStyle.updated_uas || selectedStyle.updated_uas === 0 ? selectedStyle.updated_uas : (selectedStyle.uas || DEFAULT_UAS));
      const initialSelectedUAS = selectedStyle.updated_uas || selectedStyle.uas || DEFAULT_UAS;
      fetchDataForStyle(selectedStyle, initialSelectedUAS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStyle]);

  // Include or exclude the store from the IAQ. Updates the calculations as well for totals, AQ and POA
  const toggleStoreFromStyle = (storeId: number) => {
    setSelectedStyleData((prev: any) => {
      return {
        ...prev,
        stores: [...(prev || { stores: [] })?.stores?.map((st: IStoreProfile) => {
          const storeClone = { ...st };
          if (st.number === storeId) {
            storeClone.selected = !st.selected;
          }
          return storeClone;
        })],
      };
    });
    setUpdateCount((prev) => prev + 1);
  };

  // Close the modal when the selectedStyle prop is undefined
  const handleClose = () => {
    setSelectedStyle?.(undefined);
    setSelectedStyleData(undefined);
    setStoresLoading(false);
    setLoading(false);
    setStyleStoreList({ status: "initial", data: {} });
    setUASValue(DEFAULT_UAS);
    setInitUAS(DEFAULT_UAS);
    setUpdateCount(0);
    setStoreStages({
      status: "initial", data: [],
    });
    setStagesVisible(false);
    setCCInventory({
      status: "initial", data: [], total: 0, future_total: 0,
    });
    setSelectedMode("AQ");
  };

  useEffect(() => {
    setSelectedStage(5);
  }, [stagesVisible]);

  // Update the UAS value for the selected style (calls props "onUpdate")
  const handleUpdate = async () => {
    try {
      setLoading(true);
      if ((UASValue || UASValue === 0) && UASValue?.toString() && selectedStyle) {
        const selectedStoreIdsWithPoa = selectedStyleData?.stores?.filter((st: IStoreProfile) => st.selected)?.map((st: IStoreProfile) => {
          return {
            store_id: st.id,
            poa_qty: (Math.round((st.updatedPoa || 0) * 100) / 100)?.toString(),
          };
        });
        await onUpdate?.(
          selectedStyle.newness_id, UASValue?.toString(), selectedStoreIdsWithPoa,
        );
      }
    } finally {
      setLoading(false);
    }
  };

  // Const handle update AQ for store
  const handleUpdateAQForStore = useCallback((storeId: number, AQValue: number) => {
    setSelectedStyleData((prev: any) => {
      return {
        ...prev,
        stores: [...prev?.stores?.map((st: IStoreProfile) => {
          if (st.number === storeId && (UASValue || initUAS || DEFAULT_UAS)) {
            const storeClone = { ...st };
            // eslint-disable-next-line
            if (AQValue.toString() !== (Math.ceil((storeClone.poa || 0) * ((UASValue || UASValue === 0 ? UASValue : (initUAS || DEFAULT_UAS)))))?.toString()) {
              storeClone.updatedPoa = Math.floor((AQValue / ((UASValue || UASValue === 0 ? UASValue : (initUAS || DEFAULT_UAS)))) * 100) / 100;
            } else {
              storeClone.updatedPoa = storeClone.poa;
            }
            storeClone.updatedIaq = AQValue;
            return storeClone;
          }
          return st;
        })],
      };
    });
    setUpdateCount((prev) => prev + 1);
  }, [UASValue, initUAS]);

  // Memoize the columns for the table (AQs)
  const getAQColumns = useMemo(() => {
    return columnsAQ({
      editable: selectedStage === 5,
      ccTag: selectedStyle?.tag,
      handleEditStoreClick: (a) => {
        // open link in new tab
        window.open(`${paths.edit_store.replace(":id", a)}`, "_blank");
      },
      handleStoreToggleFromStyle: (a) => toggleStoreFromStyle(a),
      handleAQUpdate: (storeId, AQValue) => handleUpdateAQForStore(storeId, AQValue),
      uas: (UASValue || UASValue === 0 ? UASValue : (initUAS || DEFAULT_UAS)),
      stagesVisible,
      setStagesVisible,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCount, history, UASValue, handleUpdateAQForStore,
    selectedStyle, selectedStage, stagesVisible, allocQAllStoreList?.status]);

  // Memoize the data for the table (DCs)
  const getDCColumns = useMemo(() => {
    return columnsDC();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ccInventory]);

  // Calculate Memoized value of total IAQ using per AQ per store sum
  const calculatedTotalIAQ = useMemo(() => {
    return selectedStyleData?.stores?.reduce((acc: any, cur: any) => {
      if (cur?.selected) {
        return acc + parseInt(cur?.updatedIaq || cur?.iaq || "0", 10);
      }
      return acc;
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UASValue, selectedStyle, selectedStyleData, allocQAllStoreList?.status]);

  const stagesFullyLoaded = useMemo(() => {
    return storeStages.status === "success" && allocQAllStoreList?.status === "success";
  }, [storeStages?.status, allocQAllStoreList?.status]);

  // Memoize the render element for the modal
  const styleRenderElement = useMemo(() => {
    return (
      <div
        id="alloc-q-style-details-modal"
        className="alloc-q-style-details-modal-content"
      >
        <Row>
          <Col span={8}>
            <div className="style-info">
              <div>
                <span>{selectedStyle?.cc}</span>
                <span className="vertical-splitter">{" | "}</span>
                <span>{selectedStyle?.class}</span>
              </div>
              <div>
                <span className="date">
                  {"Allocation Date: "}
                  <span className="fade">
                    {
                    selectedStyle?.newness_date
                      ? moment(selectedStyle?.newness_date).startOf("month").format("MM/DD/YYYY")
                      : "N/A"
                  }
                  </span>
                  <Icon
                    name="Date"
                    color="#000"
                  />
                </span>
              </div>
              <div>
                <span className="allocated-to">
                  {"Allocated to: "}
                  {selectedStyleData?.allocated_store_numbers && selectedStyleData?.allocated_store_numbers?.length > 0 ? (
                    <a
                      className="fade"
                      /* eslint-disable-next-line */
                      href={`${paths.newness_allocation_history}?cc=${selectedStyle?.cc}&store_numbers=${selectedStyleData?.allocated_store_numbers?.join(",")}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {`${selectedStyleData?.allocated_store_numbers?.length} Stores`}
                    </a>
                  ) : (
                    <span className="fade">
                      {styleStoreList?.status !== "success" ? "Loading..." : "0 stores"}
                    </span>
                  )}
                  <Icon
                    name="Store"
                    color="#000"
                  />
                </span>
              </div>
              <div>
                <span className={`tag ${selectedStyle?.tag && selectedStyle?.tag !== "" ? "has-tag" : ""}`}>
                  {"Tag: "}
                  <span className="fade">
                    {
                    selectedStyle?.tag && selectedStyle?.tag !== ""
                      ? renderTag(selectedStyle?.tag)
                      : "-"
                    }
                  </span>
                  <Icon
                    name="Tag"
                    color="#000"
                    size={16}
                  />
                </span>
              </div>
            </div>
          </Col>
          <Col span={16}>
            <Row>
              <Col span={16}>
                <Segmented
                  size="large"
                  value={selectedMode}
                  onChange={(value) => setSelectedMode(value === "DC" ? "DC" : "AQ")}
                  options={[
                    {
                      label: (
                        <div className="totals lg">
                          <div className="value">
                            {calculatedTotalIAQ || "-"}
                          </div>
                          <div className="label">
                            Initial Allocation
                          </div>
                        </div>
                      ),
                      value: "AQ",
                    },
                    {
                      label: (
                        <div className="totals-wrapper">
                          <div className="totals lg">
                            <div className="value">
                              {selectedStyle?.inventoryData?.totals?.qty
                                || (ccInventory?.total || ccInventory?.total === 0 ? ccInventory?.total : "-")}
                            </div>
                            <div className="label">
                              Avail. Inv.
                            </div>
                          </div>
                          <div className="totals lg">
                            <div className="value">
                              {selectedStyle?.inventoryData?.totals?.future_qty
                              || ccInventory?.future_total || ccInventory?.future_total === 0 ? ccInventory?.future_total : "-"}
                            </div>
                            <div className="label">
                              Future Inv.
                            </div>
                          </div>
                        </div>
                      ),
                      value: "DC",
                    },
                  ]}
                />
              </Col>
              <Col span={8}>
                <div className="totals sm">
                  <div className="value">
                    <InputNumber
                      disabled={storesLoading || selectedStage !== 5}
                      defaultValue={Math.round((UASValue || UASValue === 0 ? UASValue : (initUAS || DEFAULT_UAS)))}
                      onChange={(value) => {
                        setUpdateCount((prev) => prev + 1);
                        setUASValue(Math.round(+value));
                      }}
                      min={0}
                      max={999999}
                      maxLength={6}
                      stringMode
                      controls={false}
                      precision={0}
                    />
                  </div>
                  <div
                    className="horizontal-splitter"
                    style={UASValue !== initUAS ? { backgroundColor: "green" } : {}}
                  />
                  <div className="label">
                    UAS
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{ height: 400 }}
          >
            {selectedMode === "AQ" ? (
              <div className="iaq-stores-with-stages-wrapper">
                {stagesVisible ? (
                  <IAQStagesBlock
                    stages={storeStages}
                    selectedStage={selectedStage}
                    setSelectedStage={setSelectedStage}
                    isLoaded={stagesFullyLoaded}
                  />
                ) : <></>}
                <div className="iaq-store-list-wrapper">
                  <DartTable
                    key={`${UASValue}_stage_${selectedStage}_visible_${stagesVisible}_${allocQAllStoreList?.status}`}
                    loading={storesLoading}
                    tableStyle="dark"
                    height="400px"
                    data={[...selectedStyleData?.includedStores || [], ...(stagesVisible ? selectedStyleData?.excludedStores || [] : [])]}
                    columns={getAQColumns}
                  />
                  {stagesVisible && selectedStyle?.cc ? (
                    <IAQStoreStageButtonsBlock
                      selectedCC={selectedStyle?.cc}
                      selectedChannel={selectedFilters?.channel}
                      selectedStage={selectedStage}
                      stages={storeStages}
                      allStores={allocQAllStoreList?.data}
                      loading={!stagesFullyLoaded}
                    />
                  ) : <></>}
                </div>
              </div>
            ) : (
              <DartTable
                key={`${UASValue}_${allocQAllStoreList?.status}`}
                loading={!selectedStyle?.inventoryData && ccInventory?.status === "request"}
                tableStyle="dark"
                height={ccInventory?.data?.length > 0 ? ((ccInventory?.data?.length * 50) + 50) : 400}
                data={selectedStyle?.inventoryData
                  ? Object.values(selectedStyle?.inventoryData?.per_warehouse || {})
                  : (ccInventory?.data || [])}
                columns={getDCColumns}
              />
            )}
          </Col>
        </Row>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStyleData, storesLoading, calculatedTotalIAQ, selectedMode, selectedStage, stagesFullyLoaded,
    stagesVisible, selectedFilters?.channel, allocQAllStoreList?.status]);

  return (
    <div className="alloc-q-style-details">
      <DartPrompt
        className="alloc-q-style-details-modal"
        title={undefined}
        visible={!!selectedStyle?.cc}
        width={1200}
        onOk={handleUpdate}
        onCancel={handleClose}
        okText="DONE"
        cancelText={updateCount > 0 ? "Discard Changes" : "Close"}
        askCancel={updateCount > 0}
        closable={false}
        okButtonProps={{
          loading: storesLoading || loading,
          disabled: (!UASValue && UASValue !== 0)
            || updateCount === 0
            || !selectedStyleData?.stores?.some((st: IStoreProfile) => st.selected)
            || selectedStage !== 5,
        }}
        icon={<></>}
        content={styleRenderElement}
        onClose={handleClose}
      />
    </div>
  );
};

export default AllocQStyleDetails;
