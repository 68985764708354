import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import {
  useRecoilState, useRecoilValue, useResetRecoilState,
} from "recoil";
import { useHistory } from "react-router-dom";
import { Alert } from "antd";
import moment from "moment";
import {
  allocQAllStoreListAtom, allocQDataAtom, allocQListAtom, globalFiltersAtom,
} from "../../../../global/atoms";
import PageSummary from "../../../../components/PageSummary";
import DartButton from "../../../../components/DartButton";
import paths from "../../../../configs/paths";
import { AllocQCardView } from "../../index";
import Icon from "../../../../components/Icon";
import { messages } from "../../../../configs/constants";
import { getAllStores } from "../../../store";
import PageTitle from "../../../../components/PageTitle";
import PillSelect from "../../../../components/PillSelect";

const AllocQDashboard = () => {
  const allocQList = useRecoilValue(allocQListAtom);
  const resetAllocQList = useResetRecoilState(allocQListAtom);
  const resetAllocQData = useResetRecoilState(allocQDataAtom);
  const selectedFilters = useRecoilValue<any>(globalFiltersAtom);
  const [allocQAllStoreList, setAllocQAllStoreList] = useRecoilState(allocQAllStoreListAtom);
  const [iaqMonthFilter, setIAQMonthFilter] = useState<"all" | "recent" | any>(localStorage.getItem("iaqMonthFilter") || "recent");
  const history = useHistory();

  const formatter = Intl.NumberFormat("en", { notation: "standard", maximumFractionDigits: 0 });

  // Fetch stores for Style Details modal when selectedStyle changes
  useEffect(() => {
    // Call API to fetch store data for the selected style
    if (selectedFilters.channel && selectedFilters.brand) {
      getAllStores(
        allocQAllStoreList, setAllocQAllStoreList, { brand: [selectedFilters.brand], channel: [selectedFilters.channel] },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters.channel, selectedFilters.brand]);

  // Toggle function for IAQ month filter
  const toggleIAQMonthFilter = (selected?: string) => {
    localStorage.setItem("iaqMonthFilter", selected || "recent");
    setIAQMonthFilter(selected || "recent");
  };

  // Calculate total IAQ and styles count according to allocQList data
  const getSummaryContent = useMemo(() => {
    const totalIAQ = Object.values(allocQList?.data?.[iaqMonthFilter === "all" ? "all" : "recent"] || {}).reduce((acc: number, cur: any) => {
      const iSum: any = Object.values(cur || {}).reduce((iAcc: any, iCur: any) => { return iAcc + (+iCur.iaq || 0); }, 0) || 0;
      return acc + iSum;
    }, 0);
    const totalStyles = Object.values(allocQList?.data?.[iaqMonthFilter === "all" ? "all" : "recent"] || {}).reduce((acc: number, cur: any) => {
      const iSum: any = Object.values(cur || {}).reduce((iAcc: any, iCur: any) => { return iAcc + (+iCur.cc_count || 0); }, 0) || 0;
      return acc + iSum;
    }, 0);
    const monthFilterDisabled = !selectedFilters.channel || !selectedFilters.brand;

    return [
      {
        name: "Total Initial Allocation Quantity",
        value: selectedFilters.channel && selectedFilters.brand ? formatter.format(totalIAQ) : "-",
        flex: 2,
      },
      {
        name: "Total Style Colors",
        value: selectedFilters.channel && selectedFilters.brand ? formatter.format(totalStyles) : "-",
        flex: 1,
      },
      {
        name: "Visible Newness Months",
        value: (
          <PillSelect
            selectOptions={[{
              key: "recent",
              value: "recent",
              label: "Recent Only",
              disabled: monthFilterDisabled || (allocQList?.status === "success" && Object.keys(allocQList?.data?.recent || {})?.length === 0),
              count: monthFilterDisabled ? 0 : Object.keys(allocQList?.data?.recent || {})?.length,
            }, {
              key: "all",
              value: "all",
              label: "Show All",
              disabled: monthFilterDisabled,
              count: monthFilterDisabled ? 0 : Object.keys(allocQList?.data?.all || {})?.length,
            }]}
            multiple={false}
            value={[allocQList?.status === "success" && Object.keys(allocQList?.data?.recent || {})?.length === 0 ? "all" : iaqMonthFilter]}
            onChange={(s) => toggleIAQMonthFilter(s?.[0])}
          />
        ),
        flex: 2,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocQList, iaqMonthFilter, selectedFilters.channel, selectedFilters.brand]);

  // Decide when last update happened through CCs
  const decideLatestUpdate = useMemo(() => {
    const entries: any = Object.values(allocQList?.data?.[iaqMonthFilter === "all" ? "all" : "recent"] || {});
    if (entries?.length > 0 && selectedFilters.channel && selectedFilters.brand) {
      let maxDate = "01/01/1970";
      for (let i = 0; i < entries?.length; i += 1) {
        for (let o = 0; o < entries?.[i]?.length; o += 1) {
          const updatedAt = entries?.[i]?.[o]?.last_updated;
          const d = moment(updatedAt).diff(moment(maxDate), "days");
          if (d > 0) {
            maxDate = updatedAt;
          }
        }
      }
      return maxDate;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocQList, iaqMonthFilter]);

  // Check to reset the data when different filters are selected
  useEffect(() => {
    if (selectedFilters?.brand && selectedFilters?.channel && allocQList?.key?.length > 0) {
      if (allocQList?.key?.indexOf(selectedFilters?.channel) === -1
        || allocQList?.key?.indexOf(selectedFilters?.brand) === -1) {
        resetAllocQList();
        resetAllocQData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters.channel, selectedFilters.brand]);

  return (
    <div className="alloc-q-dashboard-page">
      <div className="alloc-q-dashboard-header">
        <PageTitle title="Initial Allocation Quantity" />
      </div>
      <div className="container-content">
        <PageSummary
          title="Initial Allocation Summary"
          summaryContent={getSummaryContent}
          actionButton={(
            <DartButton
              size="lg"
              label="Review"
              onClick={() => history.push(paths.initial_allocation_quantity_review)}
              disabled={!selectedFilters.channel || !selectedFilters.brand
                || Object.values(allocQList?.data?.[iaqMonthFilter === "all" ? "all" : "recent"] || {})?.length === 0}
            />
          )}
          actionButtonSubtext={decideLatestUpdate ? `Last Updated: ${moment(decideLatestUpdate).format("MM/DD/YYYY")}` : undefined}
          loading={allocQList?.status === "revalidate"}
        />
        {selectedFilters.channel && selectedFilters.brand
          ? (<AllocQCardView iaqMonthFilter={iaqMonthFilter} />)
          : (
            <Alert
              style={{ marginTop: 32 }}
              icon={(
                <Icon
                  name="Flash"
                  color="#E87324"
                  size={20}
                />
              )}
              showIcon
              type="warning"
              description={messages.dashboard_select_filters_to_continue}
            />
          )}
      </div>
    </div>
  );
};

export default AllocQDashboard;
