/* eslint-disable no-param-reassign */
import React from "react";

const columnsDC = (): Record<string, any>[] => [
  {
    key: "dc",
    dataIndex: "dc",
    title: "Warehouse",
    width: 130,
    flexShrink: 1,
    align: "center",
    cellRenderer: ({ rowData }: {
      rowData: any
    }) => {
      return (
        <div className="style-details-table-cell center">
          {rowData?.warehouse_id}
        </div>
      );
    },
  },
  {
    key: "amount",
    dataIndex: "amount",
    title: "Allocatable Inventory",
    width: 100,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: {
      rowData: any
    }) => {
      return (
        <div className="style-details-table-cell center">
          {rowData?.qty}
        </div>
      );
    },
  },
  {
    key: "future_amount",
    dataIndex: "future_amount",
    title: "Future Inventory",
    width: 100,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: {
      rowData: any
    }) => {
      return (
        <div className="style-details-table-cell center">
          {rowData?.future_qty}
        </div>
      );
    },
  },
];

export default columnsDC;
