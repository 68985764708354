import { Spin } from "antd";
import React, { ReactNode } from "react";
import { Redirect, Route } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../global/atoms";

interface IProps {
  children: ReactNode;
  path: string;
}

const AuthGuard: React.FC<IProps> = ({ children, path }) => {
  const user = useRecoilValue(userAtom);
  const hash = window.location.hash || "";

  if (user?.data?.idToken?.jwtToken === undefined) {
    return (
      <div className="page-loader">
        <Spin size="large" />
      </div>
    );
  }

  return <Route path={path}>{user?.data?.idToken?.jwtToken ? children : <Redirect to={`/signin${hash}`} />}</Route>;
};

export default AuthGuard;
