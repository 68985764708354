import React, { useMemo } from "react";
import { Spin } from "antd";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../../global/atoms";

interface ICanAccess {
  requireRoles?: string[];
  requirePermissions?: string[];
  fallback?: JSX.Element | string;
  children: any;
}

export const SUPER_ADMIN = "superadmin";
export const ADMIN = "admin";
export const MANAGER = "manager";
export const ALLOCATOR = "allocator";

export const canAccess = (
  action: any,
  grants: {
    role?: string, permissions?: string[]
  } = { role: undefined, permissions: [] },
  requirements: {
    roles?: string[], permissions?: string[]
  } = { roles: [], permissions: [] },
  fallback: any = false,
) => {
  const { role, permissions } = grants;
  const { roles: requireRoles, permissions: requirePermissions } = requirements;

  const decideAccess = () => {
    if (!requireRoles && !requirePermissions) {
      return true;
    }
    let canAccessViaRole = false;
    let canAccessViaPermission = false;
    if (role && role?.length > 0) {
      const userHasAdminRole = role === SUPER_ADMIN;
      if (userHasAdminRole) {
        return userHasAdminRole;
      }
    }
    if (requireRoles && requireRoles?.length > 0) {
      if (role && role?.length === 0) {
        canAccessViaRole = false;
      }
      if (role && role?.length > 0) {
        canAccessViaRole = requireRoles.indexOf(role) >= 0;
      }
    }
    if (requirePermissions && requirePermissions?.length > 0) {
      if (permissions && permissions?.length === 0) {
        canAccessViaPermission = false;
      }
      if (permissions && permissions?.length > 0) {
        canAccessViaPermission = permissions?.some((permission: string) =>
          requirePermissions.indexOf((permission as any).name) >= 0);
      }
    }
    return canAccessViaRole || canAccessViaPermission;
  };

  if (typeof action === typeof Function) {
    return decideAccess() ? action?.() : fallback?.();
  }
  return decideAccess() ? action : fallback;
};

const CanAccess: React.FC<ICanAccess> = ({
  requireRoles,
  requirePermissions,
  fallback = <></>,
  children,
}) => {
  const authUser = useRecoilValue(userAtom);

  const decideAccess = useMemo(() => {
    if (!requireRoles && !requirePermissions) {
      return true;
    }
    let canAccessViaRole = false;
    let canAccessViaPermission = false;
    if (authUser?.role && authUser?.role?.length > 0) {
      const userHasSuperAdminRole = authUser.role === SUPER_ADMIN;
      if (userHasSuperAdminRole) {
        return userHasSuperAdminRole;
      }
    }
    if (requireRoles && requireRoles?.length > 0) {
      if (!authUser?.role || authUser?.role?.length === 0) {
        canAccessViaRole = false;
      }
      if (authUser?.role && authUser?.role?.length > 0) {
        canAccessViaRole = requireRoles.indexOf(authUser?.role) >= 0;
      }
    }
    if (requirePermissions && requirePermissions?.length > 0) {
      if (!authUser.permissions || authUser.permissions?.length === 0) {
        canAccessViaPermission = false;
      }
      if (authUser.permissions && authUser.permissions?.length > 0) {
        canAccessViaPermission = authUser.permissions?.some((permission: string) =>
          requirePermissions.indexOf((permission as any).name) >= 0);
      }
    }
    return canAccessViaRole || canAccessViaPermission;
  }, [requireRoles, requirePermissions, authUser.role, authUser.permissions]);

  if (authUser.status === "request") {
    return <Spin size="small" />;
  }

  return decideAccess ? (children || <></>) : fallback;
};

export default CanAccess;
