const paths = {
  api_logs: "/api-logs",
  newness: "/",
  newness_allocation: "/allocation",
  newness_allocation_history: "/allocation/history",
  review_ccs: "/allocation/review",
  newness_upload: "/upload",
  newness_upload_old: "/upload/old",
  signin: "/signin",
  new_store: "/stores/create",
  edit_store: "/stores/:id/edit",
  view_all_stores: "/stores",
  size_profile: "/setup/size-profile",
  size_profile_review: "/setup/size-profile/review",
  size_profile_edit: "/setup/size-profile/edit",
  new_size_run: "/setup/size-run/create",
  edit_size_run: "/setup/size-run/:id/edit",
  poa: "/setup/poa",
  poa_review: "/setup/poa/review",
  poa_edit: "/setup/poa/edit",
  initial_allocation_quantity_dashboard: "/initial-allocation-quantity",
  initial_allocation_quantity_edit: "/initial-allocation-quantity/edit",
  initial_allocation_quantity_review: "/initial-allocation-quantity/review",
  replenishment_dashboard: "/replenishment",
  replenishment_history: "/replenishment/history",
  start_manual_replenishment: "/replenishment/manual",
  manual_replenishment_step_one: "/replenishment/manual/:id",
  manual_replenishment_step_two: "/replenishment/manual/:id/review",
  set_password: "/signin/set-password/:email",
  forgot_password_with_email: "/signin/forgot-password/:email",
  forgot_password: "/signin/forgot-password",
  user_list: "/users",
  user_edit: "/users/:username/edit",
  user_create: "/users/create",
};

export default paths;
