import React from "react";
import "./index.less";

interface ISelectedStyleColorTabHead {
  cc: string;
  ccClass?: string;
}

const SelectedStyleColorTabHead:React.FC<ISelectedStyleColorTabHead> = ({
  cc,
  ccClass,
}) => {
  return (
    <div className="selected-style-color-tab-head">
      <div className="tab-top-card-title">
        {cc}
      </div>
      {ccClass && (
      <div className="tab-top-card-subtitle">
        {ccClass}
      </div>
      )}
    </div>
  );
};

export default SelectedStyleColorTabHead;
