import React from "react";
import "./index.less";
import Icon from "../../../../../components/Icon";
import { IPoaEntry } from "../../../../../global/interfaces";
import DartCheckbox from "../../../../../components/DartCheckbox";
import decideDepartmentName from "../../../../../utils/helpers/decideDepartmentName";
import DartButton from "../../../../../components/DartButton";

interface IPoaWithCounts extends IPoaEntry {
  classCount?: number;
  styleCount?: number;
  customCount?: number;
}

interface IGroupedPoaCollapse {
  poaEntry: IPoaWithCounts;
  expanded?: boolean;
  onExpandToggle?: () => void;
  expandedContent?: React.ReactNode | JSX.Element | string;
  checked?: boolean;
  onCheckboxToggle?: () => void;
  onMaximizeClick?: () => void;
  loading?: boolean;
  maximizeText?: string;
  extraContentClass?: string;
  disabled?: boolean;
}

const GroupedPoaCollapse:React.FC<IGroupedPoaCollapse> = ({
  poaEntry,
  expanded = false,
  onExpandToggle,
  expandedContent,
  checked,
  onCheckboxToggle,
  onMaximizeClick,
  loading,
  maximizeText,
  extraContentClass,
  disabled,
}) => {
  return (
    <div
      key={`${poaEntry?.department}_${poaEntry?.division}_${poaEntry?.id}`}
      className={`grouped-poa-setup ${expanded ? "has-expanded-content" : ""}`}
    >
      <div
        className="card-head"
      >
        <div className="left">
          {onCheckboxToggle && (
          <DartCheckbox
            size="sm"
            disabled={disabled}
            onChange={() => onCheckboxToggle?.()}
            checked={checked}
            className="checkbox-department"
          />
          )}
          <div className="title">
            {
              decideDepartmentName(
                poaEntry.department, false, "Custom",
              )
            }
          </div>
          {((expanded || checked) && onMaximizeClick) ? (
            <DartButton
              loading={loading}
              disabled={loading || disabled}
              size="sm"
              icon="Expand"
              onClick={onMaximizeClick}
              type="secondary"
              label={maximizeText || "Expand"}
            />
          ) : <></>}
        </div>
        <div
          className={`right ${disabled ? "cursor-not-allowed" : ""}`}
          onClick={() => (expandedContent && !disabled ? onExpandToggle?.() : false)}
        >
          <span className="content">
            {`${poaEntry.classCount
              ? `${poaEntry.classCount}${poaEntry.classCount === 1 ? " Class" : " Classes"}` : ""}${poaEntry.styleCount && poaEntry.classCount
              ? ", " : ""}${poaEntry.styleCount ? `${poaEntry.styleCount}${poaEntry.styleCount === 1
              ? " Style" : " Styles"}` : ""}${(poaEntry.styleCount && poaEntry.customCount) || (poaEntry.classCount && poaEntry.customCount)
              ? ", " : ""}${poaEntry.customCount ? `${poaEntry.customCount} Custom` : ""}`}
          </span>
          {expandedContent && onExpandToggle ? (
            <div className="collapse-arrow">
              {expanded ? (
                <Icon
                  name="DropdownArrow"
                  style={{ transform: "rotate(180deg)" }}
                />
              ) : (
                <Icon
                  name="DropdownArrow"
                  style={{ transform: "rotate(-90deg)" }}
                />
              )}
            </div>
          ) : ""}
        </div>
      </div>
      {expandedContent && (
      <div className={`expand-content ${expanded ? "visible" : ""} ${extraContentClass}`}>
        {expandedContent}
      </div>
      )}
    </div>
  );
};

export default GroupedPoaCollapse;
