import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import { message } from "antd";
import DartPrompt from "../../../../../components/DartPrompt";
import DartInput from "../../../../../components/DartInput";
import Icon from "../../../../../components/Icon";
import DartSelect from "../../../../../components/DartSelect";
import { departmentNames, divisionNames } from "../../../../../configs/constants";
import { arrayUnique } from "../../../../../utils/helpers";

interface ICustomCategoryPOA {
  selectedStyle?: any;
  setSelectedStyle?: any;
  open?: boolean;
  setOpen?: any;
  onOK?: (values: any, isAdd?: boolean) => void;
}

const DIVISION_DEPARTMENT_ENABLED = false;

const CustomCategoryPOA:React.FC<ICustomCategoryPOA> = ({
  selectedStyle,
  setSelectedStyle,
  open,
  setOpen,
  onOK,
}) => {
  const [selectedStyleData, setSelectedStyleData] = useState<any | undefined>(selectedStyle);
  const [loading, setLoading] = useState(false);
  const [addCount, setAddCount] = useState<any>(0);
  const [removeCount, setRemoveCount] = useState<any>(0);
  const [search, setSearch] = useState("");
  const [styleGroupName, setStyleGroupName] = useState("");
  const [selectedDivision, setSelectedDivision] = useState<any>(undefined);
  const [selectedDepartment, setSelectedDepartment] = useState<any>(undefined);
  const [selectedStyleColors, setSelectedStyleColors] = useState<any[]>([]);
  const [styleGroupNameError, setStyleGroupNameError] = useState<string | undefined>(undefined);
  const [touched, setTouched] = useState(false);

  // Use effect to set name on input and selected style details for edit when selected style changes
  useEffect(() => {
    setSelectedStyleData(selectedStyle);
    setStyleGroupName(selectedStyle?.name || "");
    setSelectedStyleColors(arrayUnique(selectedStyle?.fulfilments || [])?.map((e: string) => {
      return { id: e, name: e };
    }) || []);
  }, [selectedStyle]);

  // Close the modal when the close is clicked
  const handleClose = () => {
    setTouched(false);
    setOpen(false);
    setLoading(false);
    setSelectedStyle?.(undefined);
    setSelectedStyleData(undefined);
    setStyleGroupName("");
    setSelectedStyleColors([]);
    setSearch("");
    setAddCount(0);
    setRemoveCount(0);
  };

  // Update / Create the Style Group, calls prop onUpdate
  const handleUpdate = async () => {
    try {
      if (!styleGroupName.length) {
        setStyleGroupNameError("Custom category name should be between 1-40 characters");
        return;
      }
      if (!styleGroupNameError && touched) {
        setLoading(true);
        if (selectedStyleData) {
          onOK?.({
            id: selectedStyle.id,
            ccs: (selectedStyleColors || []).map((e) => e.name),
          }, false);
        } else if (styleGroupName) {
          onOK?.({
            name: styleGroupName,
            ccs: (selectedStyleColors || []).map((e) => e.name),
            division: selectedDivision ? selectedDivision.value : null,
            department: selectedDepartment ? selectedDepartment.value : null,
          }, true);
        }
        setLoading(false);
        handleClose();
      }
      if (!touched) {
        handleClose();
      }
    } catch (e: any) {
      message.error(`Error while ${selectedStyleData ? "updating" : "creating"} style group`);
      setLoading(false);
    }
  };

  // Function to filter the style colors based on search
  const searchedStyleColors = useMemo(() => {
    if (search === "") return [];
    return [{
      id: search.toUpperCase().replace(/ /g, "-"),
      name: search.toUpperCase().replace(/ /g, "-"),
    },
    ...(selectedStyleColors?.filter((styleColor: any) => {
      return styleColor.name.toLowerCase().includes(search.toLowerCase())
        && styleColor.name.toLowerCase() !== search.toLowerCase();
    }).slice(0, 4))];
  }, [selectedStyleColors, search]);

  // Handler function which adds selected style color to the list
  const handleAddNewStyle = (styleColor: any) => {
    const newSelectedStyleColors = [...selectedStyleColors, styleColor];
    setSelectedStyleColors(newSelectedStyleColors);
    setSearch("");
    setTouched(true);
  };

  // Handler function which removes selected style color from the list
  const handleRemoveStyle = (styleColor: any) => {
    const newSelectedStyleColors = selectedStyleColors?.filter((selectedStyleColor: any) => selectedStyleColor.id !== styleColor.id);
    setSelectedStyleColors(newSelectedStyleColors);
    setSearch("");
    setTouched(true);
  };

  // useEffect hook that counts the added and removed styles and displays before the list
  useEffect(() => {
    const addedStyles = selectedStyleColors?.filter((styleColor: any) => {
      return !selectedStyle?.selectedStyleColors?.find((selectedStyleColor: any) => selectedStyleColor.id === styleColor.id);
    });
    const removedStyles = selectedStyle?.selectedStyleColors?.filter((styleColor: any) => {
      return !selectedStyleColors?.find((selectedStyleColor: any) => selectedStyleColor.id === styleColor.id);
    });
    setAddCount((addedStyles || [])?.length || 0);
    setRemoveCount((removedStyles || [])?.length || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStyleColors]);

  return (
    <div className="custom-category-poa">
      <DartPrompt
        className="custom-category-poa-modal"
        title={selectedStyle ? `Modify '${selectedStyle?.name}'` : "Add new category"}
        visible={open}
        width={590}
        onOk={handleUpdate}
        onCancel={handleClose}
        okText={selectedStyle ? "Modify category" : "Create new category"}
        cancelText={false}
        closable
        maskClosable={false}
        okButtonProps={{
          loading,
        }}
        icon="Tag"
        content={(
          <div className="render-custom-category-poa">
            <h2>Enter CC to add</h2>
            <div className="search-input-wrapper">
              <DartInput
                transparent
                placeholder="e.g 1113190-BLK"
                value={search}
                onChange={(e: any) => {
                  setSearch(e.target.value);
                }}
                actionButton={(
                  <Icon
                    name="Search"
                    size={20}
                  />
                )}
              />
            </div>
            {search?.length > 0 ? (
              <div className="search-results-wrapper">
                {
                (searchedStyleColors || []).map((styleColor: any) => (
                  <div
                    key={styleColor?.id?.toString()}
                    className="search-result-single"
                  >
                    <div className="search-result-name">
                      {styleColor.name}
                    </div>
                    <div className="search-result-action">
                      {
                        selectedStyleColors?.find((selectedStyleColor: any) => selectedStyleColor.id === styleColor.id)
                          ? (<div className="green">Added!</div>)
                          : (
                            <div
                              className="orange"
                              onClick={() => handleAddNewStyle(styleColor)}
                            >
                              Add to category
                            </div>
                          )
                      }
                    </div>
                  </div>
                ))
              }
              </div>
            ) : ""}
            {addCount > 0 || removeCount > 0 ? (
              <div className="group-statistic-info">
                {addCount > 0 ? `${addCount} CC${addCount > 1 ? "s" : ""} added${removeCount > 0 ? ", " : ""}` : ""}
                {removeCount > 0 ? `${removeCount} CC${removeCount > 1 ? "s" : ""} removed` : ""}
              </div>
            ) : ""}
            <hr className="splitter" />
            {selectedStyleColors?.length > 0 ? (
              <div className="selected-styles-wrapper">
                {selectedStyleColors?.map((styleColor: any) => {
                  const isNewStyle = !selectedStyle?.selectedStyleColors?.find((selectedStyleColor: any) => selectedStyleColor.id === styleColor.id);
                  return (
                    <div
                      key={styleColor?.id?.toString()}
                      className={`selected-style-single ${isNewStyle ? "is-new" : ""}`}
                    >
                      <div className="selected-style-name">
                        {styleColor.name}
                      </div>
                      <div className="selected-style-remove-button">
                        <Icon
                          name="Delete"
                          size={18}
                          color="#333E47"
                          onClick={() => {
                            handleRemoveStyle(styleColor);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
                <hr className="splitter" />
              </div>
            ) : ""}
            {DIVISION_DEPARTMENT_ENABLED && (
              <>
                <DartSelect
                  label="Division"
                  value={selectedDivision}
                  selectOptions={Object.keys(divisionNames).map((e) => {
                    return { value: (divisionNames as any)[e], label: (divisionNames as any)[e]?.replace("_", " ") };
                  })}
                  onChange={(e: any) => {
                    setSelectedDivision({ value: e, label: e });
                  }}
                  popupContainer={(e) => e.parentElement.parentElement.parentElement.parentElement}
                />
                <DartSelect
                  label="Department"
                  value={selectedDepartment}
                  selectOptions={Object.keys(departmentNames).map((e) => {
                    return { value: (departmentNames as any)[e], label: (departmentNames as any)[e]?.replace("_", " ") };
                  })}
                  onChange={(e: any) => {
                    setSelectedDepartment({ value: e, label: e });
                  }}
                  popupContainer={(e) => e.parentElement.parentElement.parentElement.parentElement}
                />
              </>
            )}
            <div className="group-name-input">
              <DartInput
                editable={!selectedStyleData}
                className={styleGroupNameError ? "error" : ""}
                label="Custom category name"
                value={styleGroupName}
                onChange={(e: any) => {
                  setStyleGroupNameError(undefined);
                  if (e.target.value.length === 0 || e.target.value.length > 40) {
                    setStyleGroupNameError("Custom category name should be between 1-40 characters");
                  }
                  setTouched(true);
                  setStyleGroupName(e.target.value);
                }}
              />
              {styleGroupNameError ? (
                <div className="error-message">
                  {styleGroupNameError}
                </div>
              ) : ""}
            </div>
          </div>
        )}
        onClose={handleClose}
      />
    </div>
  );
};

export default CustomCategoryPOA;
