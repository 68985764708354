import "./index.less";
import React, {
  useCallback, useEffect, useState,
} from "react";
import { Layout, Spin } from "antd";
import classNames from "classnames";
import Icon from "../Icon";

const { Sider: AntSider } = Layout;

interface ISiderProps {
  openSiderKey?: string;
  children: JSX.Element;
  visible: boolean;
  siderWidth?: string;
  handleSiderToggle?: (toggle: boolean, record: any) => void;
  onClose?: () => void
  resizable?: boolean;
  minWidth?: number;
  maxWidth?: number;
  hasBackdrop?: boolean;
  styles?: any;
  preventCloseOnClass?: string[];
  wrapperClassName?: string;
  forId?: any;
  renderHeader?: any;
  renderFooter?: any;
  loading?: boolean;
}

const Sider: React.FC<ISiderProps> = ({
  openSiderKey,
  children,
  visible,
  siderWidth = "30%",
  handleSiderToggle,
  resizable,
  minWidth = 150,
  maxWidth = 1920,
  hasBackdrop = true,
  onClose,
  styles = {},
  preventCloseOnClass,
  wrapperClassName,
  forId = undefined,
  renderHeader,
  renderFooter,
  loading = false,
}) => {
  const [width, setWidth] =
    useState(openSiderKey ? (localStorage.getItem(openSiderKey) || siderWidth) : siderWidth);
  const [widthPx, setWidthPx] = useState(window.innerWidth / 2);
  const [resizing, setResizing] = useState<boolean>(false);
  const [sizeClass, setSizeClass] = useState("");
  const [isFullWidth, setIsFullWidth] = useState(false);
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    if (visible && !loading) {
      setLoaded(true);
    } else {
      setLoaded(false);
    }
  }, [visible, loading]);

  const onResize = useCallback((e: any) => {
    if (resizing) {
      const newSiderWidth = window.innerWidth - e.clientX;
      if (newSiderWidth >= minWidth) {
        const widthInPercentage = (newSiderWidth * 100) / window.innerWidth;
        if (widthInPercentage < maxWidth) {
          setWidthPx(newSiderWidth);
          setWidth(`${Math.round(widthInPercentage)}%`);
        }
      }
    }
  }, [resizing, minWidth, maxWidth]);

  const handleWindowResize = () => {
    setWidthPx((window.innerWidth / 100) * Number(width?.replace("%", "")));
    if (window.innerWidth < 768) {
      setIsFullWidth(true);
    } else {
      setIsFullWidth(false);
    }
  };

  const handleESC = (event: KeyboardEvent) => {
    if (event?.code === "Escape") {
      closeSider?.();
    }
  };

  useEffect(() => {
    if (widthPx < 1201 && widthPx > 768) {
      setSizeClass("md");
    } else if (widthPx < 768 && widthPx > 670) {
      setSizeClass(`sm ${isFullWidth ? "full" : ""}`);
    } else if (widthPx < 670) {
      setSizeClass(`sm mobile ${isFullWidth ? "full" : ""}`);
    } else {
      setSizeClass(isFullWidth ? "full" : "");
    }
  }, [isFullWidth, widthPx]);

  useEffect(() => {
    if (resizable) {
      window.addEventListener("mousemove", onResize);
      window.addEventListener("mouseup", stopResizing);
    }
    window.addEventListener("resize", handleWindowResize);
    window.addEventListener("keyup", handleESC);

    return () => {
      window.removeEventListener("mousemove", onResize);
      window.removeEventListener("mousemove", stopResizing);
      window.removeEventListener("resize", handleWindowResize);
      window.addEventListener("keyup", handleESC);
    };
  }, [resizing, onResize, resizable]);

  const closeSider = () => {
    onClose?.();
  };

  useEffect(() => {
    handleWindowResize();
    return () => {
      closeSider();
    };
  }, []);

  const handleDocumentClick = useCallback((e: any) => {
    if (visible) {
      const path = e.composedPath();
      if (preventCloseOnClass) {
        let preventClose = false;
        path.forEach((domObject: any) => {
          if (domObject && domObject.classList && domObject.classList.value.length > 0) {
            for (let i = 0; i < preventCloseOnClass.length; i += 1) {
              if (domObject.classList.value.indexOf(preventCloseOnClass[i]) >= 0) {
                preventClose = true;
              }
            }
          }
        });

        if (!preventClose) {
          handleSiderToggle?.(false, {});
        }
      }
    }
  }, [preventCloseOnClass, visible, handleSiderToggle]);

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
  }, [handleDocumentClick]);

  useEffect(() => {
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [handleDocumentClick]);

  const startResizing = (e: any) => {
    if (resizable) {
      e.preventDefault();
      setResizing(true);
    }
  };

  const stopResizing = () => {
    setResizing(false);
  };

  useEffect(() => {
    if (!resizing && openSiderKey) {
      localStorage.setItem(openSiderKey, width);
    }
  }, [resizing]);

  return (
    <>
      {loaded && (
        <AntSider
          className={classNames(
            wrapperClassName,
            "right-sidebar",
            { visible },
            { resizable },
            sizeClass,
            { "with-header": !!renderHeader },
          )}
          width={isFullWidth ? "100%" : width}
          style={{
            right: visible ? "0" : `-${isFullWidth ? "100%" : width}`,
            cursor: resizing ? "ew-resize" : "default",
            transition: resizing ? "none" : "all 0.2s",
            ...styles,
          }}
        >
          {renderHeader && (
            <div className="sidebar-header-wrapper">
              {renderHeader}
            </div>
          )}
          <div
            key={forId ? forId?.toString() : "sider-body"}
            className="dragger-wrapper"
          >
            <div
              className={`dragger ${resizing ? "resize-mode" : ""} ${resizable ? "resizable-cursor" : ""}`}
              onMouseDown={(e) => startResizing(e)}
            />
            {loaded ? children : <Spin className="sider-spin-loader" />}
          </div>
          <div
            className="icon-wrapper"
            onClick={closeSider}
          >
            <Icon
              name="Close"
              color="#333E47"
            />
          </div>
          {renderFooter && (
            <div className="sidebar-footer-wrapper">
              {renderFooter}
            </div>
          )}
        </AntSider>
      )}
      {hasBackdrop && visible && <div className="sider-backdrop-wrapper backdrop" />}
    </>
  );
};

export default Sider;
