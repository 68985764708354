import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const poaListAtom = atom({
  key: "poa-list",
  default: {
    status: "initial", data: [], channelStoreList: [],
  },
  effects_UNSTABLE: [persistAtom],
});
