import {
  Avatar, Dropdown, Form, Menu, Modal, Skeleton, Space,
} from "antd";
import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import {
  Link, useHistory, useLocation,
} from "react-router-dom";
import {
  useRecoilState, useRecoilValue, useResetRecoilState,
} from "recoil";
import { createHash } from "crypto";
import Icon from "../Icon";
import "./index.less";
import paths from "../../configs/paths";
import {
  allocationHistoryListAtom,
  globalFiltersAtom,
  manualReplenishmentAtom,
  newnessDashboardFiltersAtom,
  selectedCCsAtom,
  selectedNewnessAtom,
  uploadFileListAtom,
  userAtom,
} from "../../global/atoms";
import { allocationProcessAtom } from "../../global/atoms/allocation-process-atom";
import DartLogo from "../../assets/images/logo.png";
import { logout } from "../../modules/auth";
import {
  deleteMultipleSavedAllocationHistory,
  deleteSavedAllocationHistory,
} from "../../modules/newness/services/allocation";
import useRegion from "../../utils/hooks/useRegion";
import { globalFiltersRowActiveFor, globalFiltersRowVisibleFor } from "./config";
import CompactSelect from "../CompactSelect";
import { capitalize } from "../../utils/helpers";
import useEnums from "../../utils/hooks/useEnums";
import CanAccess, {
  ADMIN, canAccess, SUPER_ADMIN,
} from "../../modules/common/widgets/CanAccess";

const TopMenu: React.FC = () => {
  const [activeMenuType, setActiveMenuType] = useState<string | undefined>(undefined);
  const [childMenuItems, setChildMenuItems] = useState<any>(undefined);
  const { confirm, info } = Modal;
  const history = useHistory();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [user, setUser] = useRecoilState(userAtom);
  const resetUser = useResetRecoilState(userAtom);
  const resetUploadFileList = useResetRecoilState(uploadFileListAtom);
  const resetDashboardFilters = useResetRecoilState(newnessDashboardFiltersAtom);

  const [allocationProcess, setAllocationProcess] = useRecoilState(allocationProcessAtom);
  const [allocationHistory, setAllocationHistory] = useRecoilState(allocationHistoryListAtom);
  const [selectedNewness, setSelectedNewness] = useRecoilState(selectedNewnessAtom);
  const [selectedCCs, setSelectedCCs] = useRecoilState(selectedCCsAtom);
  const manualReplenishmentState = useRecoilValue(manualReplenishmentAtom);

  const { brand, allocation_channel } = useEnums();
  const [selectedFilters, setSelectedFilters] = useRecoilState<any>(globalFiltersAtom);
  const [localFilters, setLocalFilters] = useState(selectedFilters || {});
  const [globalFiltersForm] = Form.useForm();

  const region = useRegion();

  const allocationInProgressConfirm = useCallback((onOk?: () => void) => {
    if (allocationProcess?.data?.processId) {
      (allocationProcess?.data?.processStatus === "finished" ? info : confirm)({
        title: allocationProcess?.data?.processStatus === "finished"
          ? "Allocation is finished"
          : "Allocation in progress",
        content: allocationProcess?.data?.processStatus === "finished"
          ? "Start a new allocation process?"
          : (
            <p>
              Exiting will save the current allocation which can be accessed within 24 hours from Allocation History page.
              <br />
              <br />
              Do you want to save & exit ?
            </p>
          ),
        okText: allocationProcess?.data?.processStatus === "finished" ? "Start new" : "Save & Exit",
        cancelText: allocationProcess?.data?.processStatus === "finished" ? "Go back" : "Delete Allocation",
        cancelButtonProps: {
          danger: allocationProcess?.data?.processStatus !== "finished",
          onClick: () => {
            if (allocationProcess?.data?.processId?.length > 0 && typeof allocationProcess?.data?.processId === typeof []) {
              deleteMultipleSavedAllocationHistory(
                allocationHistory, setAllocationHistory, allocationProcess?.data?.processId || [],
              );
            } else if (allocationProcess?.data?.processId && typeof allocationProcess?.data?.processId === typeof 1) {
              deleteSavedAllocationHistory(
                allocationHistory, setAllocationHistory, allocationProcess?.data?.processId || 0,
              );
            }
            setSelectedNewness({});
            setSelectedCCs({ ready: [], not_ready: [] });
            setAllocationProcess({});
            resetDashboardFilters();
            history.push(paths.newness);
            Modal.destroyAll();
          },
        },
        maskClosable: true,
        closable: true,
        type: allocationProcess?.data?.processStatus === "finished" ? "success" : "warning",
        onOk: () => {
          setSelectedNewness({});
          setSelectedCCs({ ready: [], not_ready: [] });
          setAllocationProcess({});
          resetDashboardFilters();
          history.push(paths.newness);
        },
      });
    } else {
      onOk?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocationHistory, allocationProcess?.data?.processId, allocationProcess?.data?.processStatus, confirm, history, info]);

  const newnessMenuItems = useMemo(() => [
    {
      label: allocationProcess?.data?.processId ? "Dashboard" : <Link to={paths.newness}>Dashboard</Link>,
      key: "",
      onClick: () => (allocationProcess?.data?.processId ? allocationInProgressConfirm(() => history.push(paths.newness)) : false),
    },
    {
      label: allocationProcess?.data?.processId ? "Allocation" : <Link to={paths.newness_allocation}>Allocation</Link>,
      key: "allocation",
      disabled: !selectedNewness?.data?.length,
      onClick: () => (allocationProcess?.data?.processId ? allocationInProgressConfirm(() => history.push(paths.newness_allocation)) : false),
    },
    {
      label: <Link to={paths.review_ccs}>Review CCs</Link>,
      key: "allocation/review",
      disabled: !selectedNewness?.data?.length || !allocationProcess?.data?.processId,
    },
    {
      label: <Link to={paths.initial_allocation_quantity_dashboard}>Initial Allocation Quantity</Link>,
      key: "initial-allocation-quantity",
    },
    {
      label: "Setup",
      key: "setup",
      children: [
        {
          label: <Link to={paths.newness_upload}>File Upload (New)</Link>,
          key: "upload",
        },
        {
          label: <Link to={paths.newness_upload_old}>File Upload (Old)</Link>,
          key: "upload/old",
        },
        {
          label: <Link to={paths.poa}>POA</Link>,
          key: "setup/poa",
        },
        {
          label: <Link to={paths.size_profile}>Size Profile</Link>,
          key: "setup/size-profile",
        },
      ],
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [setUser, history, allocationProcess, selectedNewness, selectedCCs]);

  const replenishmentMenuItems = useMemo(() => [
    {
      label: <Link to="#">Dashboard</Link>,
      key: "replenishment",
      onClick: () => history.push(paths.replenishment_dashboard),
    },
    {
      label: !manualReplenishmentState?.processId ? "Manual" : <Link to="#">Manual</Link>,
      key: "replenishment/manual",
      disabled: !manualReplenishmentState?.processId,
      onClick: () => history.push(paths.start_manual_replenishment),
    },
    {
      label: <Link to="#">Automatic</Link>,
      key: "replenishment/automatic",
      disabled: true,
    },
    {
      label: <Link to="#">OTB</Link>,
      key: "replenishment/otb",
      disabled: true,
    },
    {
      label: "Setup",
      key: "setup",
      children: [
        {
          label: <Link to="#">Mins/Maxes</Link>,
          key: "replenishment/setup/min-maxes",
          disabled: true,
        },
        {
          label: <Link to="#">History</Link>,
          key: "replenishment/setup/history",
          disabled: true,
        },
      ],
    },
  ], [setUser, history, allocationProcess, selectedNewness, selectedCCs, selectedFilters, manualReplenishmentState]);

  const selectedMenuItem = useMemo(() => {
    return location.pathname.replace("/", "");
  }, [location]);

  // Apply saved filters from recoil state to local state
  useEffect(() => {
    setSelectedFilters({
      ...localFilters, region, breadcrumbs: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localFilters, region.key]);

  // Handler to set local filters to update the view according to those filters. Later this value is set to recoil state.
  const handleFiltersBeforeSubmit = (updatedField: any) => {
    setLocalFilters((prev: any) => {
      const isArr = typeof updatedField?.[0]?.value === typeof [];
      let value;
      if (isArr) {
        value = updatedField?.[0]?.value.length > 0 ? updatedField?.[0]?.value : undefined;
      } else {
        value = updatedField?.[0]?.value || undefined;
      }

      return { ...prev, [updatedField?.[0]?.name?.[0]]: value };
    });
  };

  const globalFiltersRowIsActiveForPath = useMemo(() => {
    const parsedPath = `/${selectedMenuItem?.replace(/\/[0-9-]+\//g, "/:id/")?.replace(/\/[0-9-]+/g, "/:id")}`;
    return !!globalFiltersRowActiveFor.find((path) => path === parsedPath);
  }, [selectedMenuItem]);

  const globalFiltersRowIsVisibleForPath = useMemo(() => {
    const parsedPath = `/${selectedMenuItem?.replace(/\/[0-9-]+\//g, "/:id/")?.replace(/\/[0-9-]+/g, "/:id")}`;
    return !!globalFiltersRowVisibleFor.find((path) => path === parsedPath);
  }, [selectedMenuItem]);

  const handleMenuExtend = (menuId: "newness" | "replenishment" | "reporting") => {
    if (menuId === activeMenuType) {
      setChildMenuItems(undefined);
      setActiveMenuType(undefined);
      return;
    }
    switch (menuId) {
      case "newness":
        setChildMenuItems(newnessMenuItems);
        setActiveMenuType("newness");
        return;
      case "replenishment":
        setChildMenuItems(replenishmentMenuItems);
        setActiveMenuType("replenishment");
        return;
      default: setChildMenuItems(undefined);
    }
  };

  useEffect(() => {
    setActiveMenuType(undefined);
    setChildMenuItems(undefined);
  }, [history, location]);

  useEffect(() => {
    globalFiltersForm.setFieldsValue({
      brand: selectedFilters?.brand,
      channel: selectedFilters?.channel,
    });
  }, [selectedFilters?.brand, selectedFilters?.channel]);

  const navigateToProfile = () => {
    if (canAccess(
      true, { role: user.role }, { roles: [SUPER_ADMIN, ADMIN] }, false,
    )) {
      history.push(paths.user_edit.replace(":username", user.username));
    }
  };

  return (
    <>
      <div className="dart-top-menu">
        <div className="left">
          <div className="logo">
            <img
              src={DartLogo}
              alt="logo"
            />
          </div>
          <Link
            className="to-dashboard-btn"
            to={!allocationProcess?.data?.processId ? paths.newness : "#"}
            onClick={() => (allocationProcess?.data?.processId ? allocationInProgressConfirm(() => history.push(paths.newness)) : false)}
          >
            <Icon
              name="Dashboard"
              size={18}
              color="#333E47"
            />
          </Link>
          <div className="menu">
            <a
              className={activeMenuType === "newness" ? "open" : ""}
              onClick={() => handleMenuExtend("newness")}
            >
              <Space>
                NEWNESS
                <Icon
                  name="DropdownArrow"
                  color="#959A9D"
                  size={10}
                />
              </Space>
            </a>
            <a
              className={activeMenuType === "replenishment" ? "open" : ""}
              onClick={() => handleMenuExtend("replenishment")}
            >
              <Space>
                REPLENISHMENT
                <Icon
                  name="DropdownArrow"
                  color="#959A9D"
                  size={10}
                />
              </Space>
            </a>
            <a style={{ color: "rgba(0, 0, 0, 0.25)", cursor: "not-allowed" }}>
              <Space>
                REPORTING
              </Space>
            </a>
            <CanAccess requireRoles={[SUPER_ADMIN, ADMIN]}>
              <a
                onClick={() => history.push(paths.user_list)}
                style={{
                  color: selectedMenuItem.indexOf("users") > -1 ? "#E87324" : "#333E47",
                }}
              >
                <Space>
                  USERS
                </Space>
              </a>
            </CanAccess>
          </div>
        </div>
        <div className="right">
          <div className="menu">
            <Link to={paths.view_all_stores}>
              <Icon
                name="Store"
                size={18}
                color={(selectedMenuItem.indexOf("stores") > -1
                  || selectedMenuItem.indexOf("?stage=1") > -1
                  || selectedMenuItem.indexOf("?stage=2") > -1
                  || selectedMenuItem.indexOf("?stage=5") > -1) ? "#E87324" : "#333E47"}
              />
            </Link>
            <CanAccess requireRoles={[SUPER_ADMIN]}>
              <Link to={paths.api_logs}>
                <Icon
                  name="Clip"
                  size={18}
                  color={selectedMenuItem.indexOf("api-logs") > -1 ? "#E87324" : "#333E47"}
                />
              </Link>
            </CanAccess>
            <Icon
              name="Messages"
              size={18}
              color="#959A9D"
              style={{ cursor: "not-allowed" }}
            />
            <Dropdown
              getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
              overlay={(
                <div className="profile-menu-wrapper">
                  <div
                    className="info cursor-pointer"
                    onClick={navigateToProfile}
                  >
                    <Avatar
                      className="avatar"
                      src={`https://robohash.org/${user?.username
                        ? createHash("sha256").update(user.username).digest("hex")
                        : "johndoe"}.png?bgset=bg2`}
                      size="default"
                    />
                    <span className="email">{user.email}</span>
                  </div>
                  <a onClick={
                    () => {
                      logout(setUser);
                      resetUser();
                      resetUploadFileList();
                      localStorage.removeItem("enums");
                      history.push(paths.signin);
                    }
                  }
                  >
                    Sign Out
                  </a>
                </div>
              )}
              placement="bottomRight"
              trigger={["click"]}
            >
              <Icon
                name="Profile"
                size={18}
                color="#333E47"
                style={{ cursor: "pointer" }}
              />
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="dart-global-filters-row">
        <div className="left">
          <div className="breadcrumbs">
            &nbsp;
          </div>
        </div>
        <div className="right">
          <div className="region-wrapper">
            {region.value ?? <Skeleton.Input active />}
          </div>
          {globalFiltersRowIsVisibleForPath && (
          <Form
            className="global-filters-form"
            form={globalFiltersForm}
            onFinish={(values) => {
              // handleSubmit(values);
            }}
            initialValues={{
              ...selectedFilters,
            }}
            onFieldsChange={handleFiltersBeforeSubmit}
          >
            <div className="vertical-separator" />
            <Form.Item name="brand">
              <CompactSelect
                placeholder="Brand"
                selectOptions={Object.values(brand).map((item) => { return { label: item, value: item }; })}
                disabled={!globalFiltersRowIsActiveForPath}
              />
            </Form.Item>
            <div className="vertical-separator" />
            <Form.Item name="channel">
              <CompactSelect
                placeholder="Channel"
                selectOptions={Object.values(allocation_channel).map((item) => { return { label: capitalize(item), value: item }; })}
                disabled={!globalFiltersRowIsActiveForPath}
              />
            </Form.Item>
          </Form>
          )}
        </div>
      </div>
      <div className={`dart-top-menu-bottom-section ${childMenuItems ? "shown" : ""}`}>
        <Menu
          overflowedIndicatorPopupClassName="dart-top-menu-bottom-section-popup"
          mode="horizontal"
          selectedKeys={[
            selectedMenuItem,
            selectedMenuItem.indexOf("?stage=3") > -1 ? "setup/poa" : "-1",
            selectedMenuItem.indexOf("?stage=5") > -1 ? "setup/size-profile" : "-1",
          ]}
          items={childMenuItems}
          getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
        />
      </div>
    </>
  );
};

export default TopMenu;
