/* eslint-disable no-param-reassign,jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import { Dropdown, Tooltip } from "antd";
import { IStoreProfile } from "../../../../../global/interfaces";
import Icon from "../../../../../components/Icon";
import decideStoreStatusColor from "../../../../../utils/helpers/decideStoreStatusColor";

const columns = (props: {
  channelStoreList?: IStoreProfile[];
}): Record<string, any>[] => [
  {
    key: "store",
    dataIndex: "store",
    title: (
      <div>
        <span>Store</span>
        <span>Total Contribution</span>
      </div>
    ),
    width: 500,
    flexShrink: 5,
    cellRenderer: ({ rowData }: {
      rowData: { checked?: boolean, store?: IStoreProfile, contribution?: number, sizes?: any[], totalContribution: number | string }
    }) => {
      const currentStore = rowData?.store;
      const epsilonChecked = rowData?.totalContribution === "OFF"
        ? -1
        : Math.round(((rowData?.totalContribution as any) + Number.EPSILON) * 100) / 100;
      const realTotalContribution = epsilonChecked ? epsilonChecked / 100 : 0;
      const displayTotalContribution = Math.round((rowData?.totalContribution as any) / 100);
      return (
        <div className="size-run-import-widget-table-cell frozen">
          <div className="left">
            <span className="store-number">{currentStore?.number}</span>
            <span className="vertical-splitter" />
            <span className="store-name">{currentStore?.name}</span>
            {currentStore?.sister_store && (
              <Dropdown
                destroyPopupOnHide
                trigger={["hover"]}
                overlay={(
                  <span className="table-row-menu-overlay sister-store">
                    <Icon
                      name="Branch"
                      size={12}
                      color="#000000"
                    />
                    <span className="store-number">{currentStore?.sister_store?.number}</span>
                    <span className="vertical-splitter" />
                    <span className="store-name">{currentStore?.sister_store?.name}</span>
                  </span>
                )}
              >
                <Icon
                  name="Branch"
                  size={12}
                  color="#000000"
                  style={{ marginTop: 6 }}
                />
              </Dropdown>
            )}
            <span
              className="store-status"
              style={{ backgroundColor: decideStoreStatusColor(currentStore?.status) }}
            />
          </div>
          <div className="right">
            {epsilonChecked !== -1 && (
              <Tooltip
                overlay={`Exact Contribution: ${realTotalContribution}%`}
                color="#333E47"
                placement="right"
                destroyTooltipOnHide
                trigger={["click"]}
              >
                <span className={`contribution ${displayTotalContribution !== 100 ? "flagged" : ""}`}>
                  {displayTotalContribution}
                  %
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },
];

export default columns;
