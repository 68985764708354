import React, { useState } from "react";
import "./index.less";
import {
  Button, Select, Spin,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import Icon from "../../../../components/Icon";
import { arrayUnique } from "../../../../utils/helpers";
import { IReplenishmentCC } from "../../../../global/interfaces";
import { searchReplenishmentCCs } from "../../services/replenishment";
import { globalFiltersAtom } from "../../../../global/atoms";

interface ICCFinder {
  onBack?: () => void;
  title?: string;
  tokenSeparator?: string;
  placeholder?: string;
  errorMessage?: string;
  hasAddButton?: boolean;
  onCCAdd?: (ccList: IReplenishmentCC[]) => void;
  disabled?: boolean;
}

const CCFinder: React.FC<ICCFinder> = ({
  onBack,
  title,
  tokenSeparator = " ",
  placeholder = "Search Style Colors",
  hasAddButton = true,
  errorMessage = "Invalid style-color entered",
  onCCAdd,
  disabled = false,
}) => {
  const ccFinderRef = React.createRef<any>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedCCs, setSelectedCCs] = useState<IReplenishmentCC[]>([]);
  const [invalidList, setInvalidList] = useState<IReplenishmentCC[]>([]);
  const [loadingCCs, setLoadingCCs] = useState<boolean>(false);
  const { brand, channel } = useRecoilValue(globalFiltersAtom);

  // Handle "X" click on CC tag
  const handleCCRemove = (value: string) => {
    setSelectedCCs((prev) => prev.filter((cc) => cc.cc !== value));
    setInvalidList((prev) => prev.filter((cc) => cc.cc !== value));
  };

  // Validate Each CC before adding to list
  const validateCC = (cc: string) => {
    if (!cc) return false;
    if (cc.length < 3) return false;
    if (cc.length > 20) return false;
    return (/[-a-zA-Z0-9 ]$/.test(cc));
  };

  // Add CCs to List on "Enter" key press
  const addCCsToList = async (strCCs: string) => {
    if (loadingCCs || disabled) return;

    const normalizedString = strCCs.replaceAll(",", " ");

    // Split CCs by token separator
    const ccArr = arrayUnique(normalizedString.split(tokenSeparator)?.filter((c) => !!c));

    // Fetch CC data from Search API
    const fetchedData = await searchReplenishmentCCs(
      ccArr, { brand, channel }, setLoadingCCs,
    );

    // compare local CCs with fetched data. if Key exists, CC exists, if not, CC is invalid
    const ccListWithFetchedData = ccArr?.map((cc) => {
      const ccKey: any = Object.keys(fetchedData || {})?.find((d: any) => d === cc);
      if (!ccKey) return ({ cc, valid: false });
      const fetchedObj = typeof fetchedData[ccKey] === "string" ? JSON.parse(fetchedData[ccKey]) : fetchedData[ccKey];
      return {
        cc: ccKey,
        valid: validateCC(cc), // This is important to validate CCs before adding to list
        ...(fetchedData[ccKey] ? fetchedObj : {}),
      };
    });

    // Set invalid CCs to invalid list
    setInvalidList((prev) => [...prev, ...ccListWithFetchedData.filter((c) => !c?.valid)]);

    // Filter duplicates and invalids
    const filteredCCs = ccListWithFetchedData.filter((c) => !selectedCCs.find((cc) => cc.cc === c.cc));

    // Set valid CCs to selected list
    setSelectedCCs((prev) => [...prev, ...filteredCCs]);

    // Clear input
    setSearchValue("");
  };

  // Add CCs to Parent List on "Add" button click
  const handleOnCCAdd = (ccList: IReplenishmentCC[]) => {
    if (ccList?.length > 0) {
      onCCAdd?.(ccList?.filter((c) => c?.valid));
      setSelectedCCs([]);
      setInvalidList([]);
    }
  };

  // Handle function to parse entered string into select tags on "Enter" key press or remove them on "Backspace"
  const handleKeyPressOnSelect = (e: any) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      if (e?.target?.value || e?.target?.value?.length > 3) {
        // If enter is clicked and search value is not empty, add CCs to list
        addCCsToList(e?.target?.value);
      } else if (searchValue === "" && invalidList.length === 0) {
        // If input is empty and enter is clicked, run parent "Add CC to list" function
        handleOnCCAdd(selectedCCs);
      }
    } else if (e.key === "Backspace" || e.keyCode === 8) {
      if (e?.target?.value?.length === 0) {
        handleCCRemove(selectedCCs[selectedCCs.length - 1]?.cc);
      }
    }
  };

  // Handle blur event on select
  const handleBlur = (e: any) => {
    if (e?.target?.value || e?.target?.value?.length > 3) {
      addCCsToList(e?.target?.value);
    }
  };

  return (
    <div className="cc-finder-widget-wrapper">
      <div className="cc-finder-widget">
        {onBack || title ? (
          <div className="header">
            {onBack && (
              <Button
                className="back-button"
                onClick={onBack}
                type="ghost"
                shape="circle"
              >
                <ArrowLeftOutlined style={{ fontSize: 16, fill: "red" }} />
              </Button>
            )}
            {title && <div className="title">{title}</div>}
          </div>
        ) : ""}
        <div className="input-wrapper">
          <Select
            ref={ccFinderRef}
            mode="tags"
            style={{ width: "100%" }}
            value={(selectedCCs || []).map((cc) => cc.cc) as any}
            dropdownRender={() => <></>}
            dropdownStyle={{ display: "none" }}
            showArrow={false}
            placeholder={placeholder}
            onSearch={setSearchValue}
            searchValue={searchValue}
            onBlur={(e) => handleBlur(e)}
            onKeyDown={(e) => handleKeyPressOnSelect(e)}
            disabled={loadingCCs || disabled}
            tagRender={({
              label, closable, onClose,
            }) => {
              const isInvalid = invalidList.find((c) => c.cc === label);
              return (
                <div className={`ant-select-selection-item ${isInvalid ? "invalid" : ""}`}>
                  <span className="ant-select-selection-item-content">{label as string}</span>
                  {closable && (
                    <span
                      className="ant-select-selection-item-remove"
                      onClick={() => {
                        onClose?.();
                        handleCCRemove(label as string);
                      }}
                    >
                      <Icon
                        name="Close"
                        size={9}
                      />
                    </span>
                  )}
                </div>
              );
            }}
          />
          {hasAddButton && (
            <Button
              disabled={invalidList?.length > 0 || loadingCCs || disabled}
              className={`add-button ${invalidList?.length > 0 ? "disabled" : ""}`}
              onClick={() => {
                if (invalidList?.length === 0) {
                  handleOnCCAdd?.(selectedCCs);
                }
              }}
              ghost
            >
              {loadingCCs ? <Spin /> : (
                <Icon
                  name="Plus"
                  size={24}
                />
              )}
            </Button>
          )}
        </div>
      </div>
      {invalidList.length > 0 && (
        <div className="error-message">
          <Icon
            name="Invalid"
            color="#EF1919"
            size={13}
          />
          {" "}
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default CCFinder;
