import React, { useEffect, useMemo } from "react";
import "./index.less";
import { useRecoilState, useRecoilValue } from "recoil";
import { Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import moment, { Moment } from "moment";
import { allocQListAtom, globalFiltersAtom } from "../../../../global/atoms";
import AllocQCard, { AllocQCardType } from "../../blocks/AllocQCard";
import { getAllocQList } from "../../services/allocQ";
import { IAllocQ } from "../../../../global/interfaces";
import paths from "../../../../configs/paths";
import { only } from "../../../../utils/helpers";
import { allocQInventoryDataAtom } from "../../../../global/atoms/alloc-q-inventory-data";
import { fetchInventoryDataForBrandAndChannel } from "../../../common/services/cc";

interface IAllocQCardView {
  iaqMonthFilter?: "recent" | "all";
}

const AllocQCardView: React.FC<IAllocQCardView> = ({
  iaqMonthFilter = "recent",
}) => {
  const [allocQList, setAllocQList] = useRecoilState(allocQListAtom);
  const selectedFilters = useRecoilValue<any>(globalFiltersAtom);
  const [inventoryData, setInventoryData] = useRecoilState(allocQInventoryDataAtom);
  const history = useHistory();

  // Fetch inventory data (slow function) for brand and channel - this is optimized to be debounced and
  // checked every 20 minutes per brand or channel change
  useEffect(() => {
    if (selectedFilters.brand && selectedFilters.channel) {
      fetchInventoryDataForBrandAndChannel(
        inventoryData, setInventoryData, selectedFilters.brand, selectedFilters.channel, false, 20,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters.channel, selectedFilters.brand]);

  // Re-fetch IAQ data on filter change (when channel or brand is changed)
  useEffect(() => {
    if (selectedFilters.channel && selectedFilters.brand) {
      getAllocQList(
        allocQList, setAllocQList, only(["brand", "channel"], selectedFilters),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters.brand, selectedFilters.channel]);

  // Function to decide "variation" prop value for "AllocQCard" component
  const indexToCardVariation = (index: number) => {
    let ret:AllocQCardType = "full";
    if (index >= 6) ret = "third-basic";
    else if (index >= 3) ret = "third";
    else if (index >= 1) ret = "half";
    return ret;
  };

  // Memoized const to render spin loader (when loading) or "Empty" placeholder component when no data was loaded
  const loaderOrEmpty = useMemo(() => {
    if (allocQList?.status === "request" || inventoryData?.status === "request") {
      return Array(12).fill(0).map((_, index) => (
        <AllocQCard
          key={index}
          loading
          variation={indexToCardVariation(index)}
        />
      ));
    }
    if ((allocQList?.status === "success" && inventoryData?.status === "success"
      && Object.values(allocQList?.data?.[iaqMonthFilter === "all" ? "all" : "recent"] || {})?.length === 0)) {
      return <Col span={24}><div className="empty">No Initial Allocation Quantities data found.</div></Col>;
    }
    return false;
  }, [allocQList, inventoryData, iaqMonthFilter]);

  return (
    <div className="alloc-q-card-view">
      <Row gutter={[32, 32]}>
        {loaderOrEmpty || (Object.values(allocQList?.data?.[iaqMonthFilter === "all" ? "all" : "recent"] || {}) as any)
          ?.map((nestedList: IAllocQ[], index: number) => {
            const month = (Object.keys(allocQList?.data?.[iaqMonthFilter === "all" ? "all" : "recent"] || {}) as any)[index];
            const summedIAQ = nestedList.reduce((acc: any, cur: any) => acc + (parseInt(cur.iaq, 10)), 0);
            const summedStyleCount = nestedList.reduce((acc: any, cur: any) => acc + (parseInt(cur.cc_count, 10)), 0);
            const lastUpdatedDateList = nestedList.map((e) => { return moment(e.last_updated); });
            // @ts-ignore
            const lastUpdatedDate = moment.max(lastUpdatedDateList as Moment[]);
            return (
              <AllocQCard
                key={month}
                date={month}
                initialAllocation={summedIAQ}
                styleColors={summedStyleCount}
                lastUpdated={lastUpdatedDate.format("MM/DD/YYYY")}
                variation={indexToCardVariation(index)}
                onDetailsClick={() => {
                  // eslint-disable-next-line
                  history.push(`${paths.initial_allocation_quantity_edit}?date=${month}&brand=${selectedFilters.brand}&channel=${selectedFilters.channel}`);
                }}
              />
            );
          })}
      </Row>
    </div>
  );
};

export default AllocQCardView;
