import React, { useMemo } from "react";
import "./index.less";
import { useHistory } from "react-router-dom";
import {
  useRecoilState, useRecoilValue, useResetRecoilState,
} from "recoil";
import PageTitle from "../../../../../components/PageTitle";
import DartButton from "../../../../../components/DartButton";
import DartInput from "../../../../../components/DartInput";
import { PoaOverview } from "../../index";
import paths from "../../../../../configs/paths";
import DartSelect from "../../../../../components/DartSelect";
import useEnums from "../../../../../utils/hooks/useEnums";
import {
  poaChannelAtom,
  poaExpandedListAtom,
  poaListAtom,
  poaSelectedListAtom,
  poaValuesAtom,
  poaVarianceAtom,
} from "../../../../../global/atoms";
import PageSummary from "../../../../../components/PageSummary";
import { capitalize } from "../../../../../utils/helpers";

const PoaDashboard = () => {
  const { allocation_channel } = useEnums();
  const history = useHistory();
  const poaList = useRecoilValue(poaListAtom);
  const resetPoaValuesList = useResetRecoilState(poaValuesAtom);
  const resetSelectedPoaList = useResetRecoilState(poaSelectedListAtom);
  const resetExpandedPoaList = useResetRecoilState(poaExpandedListAtom);
  const [variance, setVariance] = useRecoilState(poaVarianceAtom);
  const [poaChannel, setPoaChannel] = useRecoilState(poaChannelAtom);

  const getSummaryContent = useMemo(() => {
    return []; // @TODO: Temporarily disabled
    return [
      {
        name: "Updates Pending",
        value: "Coming soon",
        valueColor: "#E87324",
        flex: 2,
      },
      {
        name: "Flagged",
        value: "Coming soon",
      },
      {
        name: "New Comp Stores",
        value: "Coming soon",
      },
    ];
  }, []);

  const validateAndUpdateVariance = (e: any, isBlurring = false) => {
    if (isBlurring && variance?.toString() === "%") {
      setVariance("0%");
      return;
    }
    const valueInteger = parseInt(e.target.value?.replace(/%+/g, ""), 10);
    if (valueInteger > 100) {
      setVariance("100%");
    } else if (valueInteger < 0) {
      setVariance("0%");
    } else if (Number.isNaN(valueInteger)) {
      setVariance("%");
    } else {
      setVariance(`${valueInteger.toString()}%`);
    }
  };

  return (
    <div className="poa-dashboard-page">
      <PageTitle
        title="poa set up"
      />
      <div className="container-content">
        <PageSummary
          title="POA Summary"
          summaryContent={getSummaryContent}
          actionButton={(
            <DartButton
              size="lg"
              label="Review"
              onClick={() => history.push(paths.poa_review)}
            />
          )}
          // actionButtonSubtext="Last Updated: XX/XX/XX"
          extraContent={(
            <div className="variance-wrapper">
              <DartSelect
                label="Channel"
                selectOptions={[
                  { label: "All", value: "ALL" },
                  ...(Object.values(allocation_channel || {}).map((c) => ({ label: capitalize(c), value: c }))),
                ]}
                placeholder="Select channel"
                canClear={false}
                showSearch={false}
                value={poaChannel}
                onChange={(value) => {
                  resetExpandedPoaList();
                  resetSelectedPoaList();
                  resetPoaValuesList();
                  setPoaChannel(value);
                }}
              />
              <DartInput
                label="Flag update variance: "
                value={variance?.toString()}
                onChange={(e) => validateAndUpdateVariance(e, false)}
                onBlur={(e) => validateAndUpdateVariance(e, true)}
              />
            </div>
          )}
          loading={poaList?.status === "revalidate"}
        />
        <PoaOverview
          editable={false}
          varianceAllowed={variance}
        />
      </div>
    </div>
  );
};

export default PoaDashboard;
