import {
  brandNames, departmentNames, divisionNames,
} from "../../../../configs/constants";
import { decideDepartmentName, decideDivisionName } from "../../../../utils/helpers";

export type flowDocColumnType = {
    excelName: string,
    apiName: string,
    uiName: string,
    type: "not-editable" | "editable" | "resets-entity",
    input: "static" | "text" | "dropdown" | "date" | "number" | "boolean",
    width: number,
    fixed: "none" | "left" | "right",
    frozen: boolean,
    enum: any,
    validations: any[],
}

// "validations" array element types
// enum - Match enum value - any[]
// min - min length of string, number, array - number
// max - max length of string, number, array - number
// message - Will auto generate by template if not provided - string
// pattern - regex pattern - RegExp
// required - Is Required field ? - boolean
// type - string | number | boolean - string

export const SIZE_RUN_MISMATCH_ERROR_MESSAGE = "Size Run's department and division is not compatible with style color";

export const flowDocColumnValidations = () => { // Allowed column names mapped to their respective API names and validations
  return {
    "STYLE-COLOR": {
      hidden: true, // Hidden because this column is created from outside getColumns function. DON'T REMOVE WILL CAUSE DUPLICATE KEY ISSUE
      excelName: "STYLE-COLOR",
      apiName: "cc",
      uiName: "Style Color",
      type: "not-editable",
      input: "static",
      width: 220,
      fixed: "left",
      frozen: true,
      validations: [
        {
          required: true,
          message: "Style Color is required",
        },
        {
          type: "string",
          message: "Style Color must be string",
        },
      ],
    },
    BRAND: {
      excelName: "BRAND",
      apiName: "brand",
      uiName: "Brand",
      type: "resets-entity",
      input: "dropdown",
      width: 150,
      fixed: "none",
      frozen: false,
      enum: brandNames?.map((brand) => brand.value),
      validations: [
        {
          required: true,
          message: "Brand is required",
        },
        {
          type: "enum",
          enum: brandNames?.map((brand) => brand.value),
          message: `Brand should be one of: ${brandNames?.map((brand) => brand.value).join(", ")}`,
        },
      ],
    },
    DIVISION: {
      excelName: "DIVISION",
      apiName: "division",
      uiName: "Division",
      type: "resets-entity",
      input: "dropdown",
      width: 150,
      fixed: "none",
      frozen: false,
      enum: Object.values(divisionNames),
      validations: [
        {
          required: true,
          message: "Division is required",
        },
        {
          type: "enum",
          enum: Object.values(divisionNames),
          message: `Division should be one of: ${Object.values(divisionNames).map((e) => decideDivisionName(e)).join(", ")}`,
        }],
    },
    DEPARTMENT: {
      excelName: "DEPARTMENT",
      apiName: "department",
      uiName: "Department",
      type: "resets-entity",
      input: "dropdown",
      width: 150,
      fixed: "none",
      frozen: false,
      enum: Object.values(departmentNames),
      validations: [
        {
          required: true,
          message: "Department is required",
        }, {
          type: "enum",
          enum: Object.values(departmentNames),
          message: `Department should be one of: ${Object.values(departmentNames).map((e) => decideDepartmentName(e)).join(", ")}`,
        }],
    },
    CLASS: {
      excelName: "CLASS",
      apiName: "klass",
      uiName: "Class",
      type: "resets-entity",
      input: "text",
      width: 200,
      fixed: "none",
      frozen: false,
      validations: [
        {
          type: "string",
          message: "Class should be string",
        }],
    },
    SUBCLASS: {
      excelName: "SUBCLASS",
      apiName: "subklass",
      uiName: "Subclass",
      type: "editable",
      input: "text",
      width: 100,
      fixed: "none",
      frozen: false,
      validations: [{
        type: "string",
        message: "Subclass should be string",
      }],
    },
    COLLECTION: {
      excelName: "COLLECTION",
      apiName: "gtmcollections",
      uiName: "Collection",
      type: "editable",
      input: "text",
      width: 180,
      fixed: "none",
      frozen: false,
      validations: [{
        type: "string",
        message: "Collection should be string",
      }],
    },
    "REPLEN TYPE": {
      excelName: "REPLEN TYPE",
      apiName: "fulfilment_category",
      uiName: "Project Type",
      type: "editable",
      input: "dropdown",
      width: 180,
      fixed: "none",
      frozen: false,
      enum: ["NEWNESS", "REPLENISHMENT"],
      validations: [{
        type: "enum",
        enum: ["NEWNESS", "REPLENISHMENT"],
        message: "Project Type should be one of Newness, Replenishment",
      }, {
        required: true,
        message: "Project Type is required",
      }],
    },
    "STYLE NAME": {
      excelName: "STYLE NAME",
      apiName: "description",
      uiName: "Description",
      type: "editable",
      input: "text",
      width: 180,
      fixed: "none",
      frozen: false,
      validations: [{
        type: "string",
        message: "Description should be string",
      }],
    },
    "SIZE RUN": {
      excelName: "SIZE RUN",
      apiName: "size_run_id",
      uiName: "Size Run",
      type: "resets-entity",
      input: "dropdown",
      width: 220,
      fixed: "none",
      frozen: false,
      validations: [{
        required: true,
        message: "Size Run is required",
      },
      {
        type: "enum",
        message: SIZE_RUN_MISMATCH_ERROR_MESSAGE,
      }],
    },
    TIER: {
      excelName: "TIER",
      apiName: "tier",
      uiName: "Tier",
      type: "resets-entity",
      input: "dropdown",
      width: 100,
      fixed: "none",
      frozen: false,
      enum: ["Special", "Tier 1", "Tier 2", "Tier 3", "Tier 4"],
      validations: [{
        type: "enum",
        enum: [0, 1, 2, 3, 4],
        message: "Tier should be one of Special, Tier 1, Tier 2, Tier 3, Tier 4",
      }],
    },
    "FINAL ISD": {
      excelName: "Final ISD",
      apiName: "date",
      uiName: "Final ISD",
      type: "editable",
      input: "date",
      width: 250,
      fixed: "none",
      frozen: false,
      validations: [
        {
          required: true,
          message: "Final ISD is required",
        },
        {
          pattern: /\d{4}-\d{1,2}-\d{1,2}|\d{1,2}\/\d{1,2}\/\d{2,4}/g,
          message: "Final ISD should be a valid date",
        },
      ],
    },
    "MAP BREAK DATE": {
      excelName: "MAP BREAK DATE",
      apiName: "map_break",
      uiName: "Map Break Date",
      type: "editable",
      input: "date",
      width: 250,
      fixed: "none",
      frozen: false,
      validations: [{
        pattern: /\d{4}-\d{1,2}-\d{1,2}|Map Protected|\d{1,2}\/\d{1,2}\/\d{2,4}/g,
        message: "Map Break Date should be 'map protected' or a valid date",
      }],
    },
    "AP BUY": {
      excelName: "AP BUY",
      apiName: "buy",
      uiName: "Buy",
      type: "editable",
      input: "text",
      width: 100,
      fixed: "none",
      frozen: false,
      validations: [{
        type: "string",
        message: "Buy should be string",
      }],
    },
    TAG: {
      excelName: "TAG",
      apiName: "tag",
      uiName: "Tag",
      type: "editable",
      input: "text",
      width: 150,
      fixed: "none",
      frozen: false,
      validations: [{
        type: "string",
        message: "Tag should be string",
      }],
    },
  };
};

export const errorFromFlowDocValue = (
  value?: any, columnOptions?: any, mustBeInArray?: any[],
) => {
  let error = "";
  for (let i = 0; i < columnOptions?.validations?.length; i += 1) {
    const validation = columnOptions?.validations[i];
    if (mustBeInArray && mustBeInArray?.length > 0 && validation.type === "enum") {
      if (!mustBeInArray.includes(value)
        && !mustBeInArray?.map((item) => item.value)?.includes(value)
        && !mustBeInArray?.map((item) => item.label)?.includes(value)) {
        error = validation.message;
        break;
      }
    } else {
      if (validation.hasOwnProperty("pattern")) {
        if (!validation.pattern.test(value)) {
          error = validation.message;
          break;
        }
      }
      if (validation.hasOwnProperty("enum")) {
        if (!validation.enum.includes(value)) {
          error = validation.message;
          break;
        }
      }
      if (validation.hasOwnProperty("required")) {
        if (validation.required && !value) {
          error = validation.message;
          break;
        }
      }
      if (validation.hasOwnProperty("type") && !validation.hasOwnProperty("enum")) {
        if (typeof value !== validation.type) {
          error = validation.message;
          break;
        }
      }
    }
  }
  return error;
};
