import React from "react";
import "./index.less";
import {
  Alert, Form, Upload, UploadProps,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import PillSelect from "../../../../components/PillSelect";
import { uploadFileAtom } from "../../../../global/atoms";
import DartButton from "../../../../components/DartButton";
import decideImportType from "../../../../utils/helpers/decideImportType";

interface INewnessUploadStep1 {
  form: any;
  formData: any;
  importTypes: { [key: string]: string };
  onFormChange?: (values?: any) => void;
}

interface INewnessUploadStep2 {
  form: any;
  formData?: any;
  uploaderProps: UploadProps;
  onFormChange?: (values?: any) => void;
  buttonLabel?: string;
  disabled?: boolean;
  loading?: boolean;
}

const NewnessUploadStep1: React.FC<INewnessUploadStep1> = ({
  form,
  formData,
  importTypes,
  onFormChange,
}) => {
  const [{ preUploadStatus }] = useRecoilState(uploadFileAtom);

  return (
    <div className="newness-upload">
      <Form
        form={form}
        layout="vertical"
        onFieldsChange={(changedField) => onFormChange?.(changedField)}
      >
        <Form.Item
          name="import_types"
          required
          requiredMark
          label="Choose Import Type"
          className={formData.import_types ? "valid" : ""}
        >
          <PillSelect
            selectOptions={Object.values(importTypes)?.map((dt) => { return { value: dt, label: decideImportType(dt) }; })}
            multiple={false}
          />
        </Form.Item>
      </Form>
      {preUploadStatus === "failed" && (
      <Alert
        type="error"
        message="Failed to generate upload url"
      />
      )}
    </div>
  );
};

const NewnessUploadStep2: React.FC<INewnessUploadStep2> = ({
  form,
  formData,
  uploaderProps,
  onFormChange,
  buttonLabel,
  disabled,
  loading,
}) => {
  const [{ uploadStatus, preUploadStatus }] = useRecoilState(uploadFileAtom);

  const isLoading = uploadStatus === "request" || preUploadStatus === "request";

  return (
    <div className="newness-upload">
      <Form
        form={form}
        layout="vertical"
        onFieldsChange={onFormChange}
        className="file-upload-form"
      >
        <Form.Item
          name="file"
          required
          requiredMark
          className={`${(formData && uploadStatus === "initial")
          || uploadStatus === "request" ? "valid" : ""} ${uploadStatus === "failed" ? "has-error" : ""}`}
        >
          <Upload
            className={uploadStatus === "request" || preUploadStatus === "request" ? "is-upload-in-progress" : ""}
            {...uploaderProps}
          >
            <DartButton
              loading={isLoading || loading}
              disabled={!!formData || isLoading || disabled}
              label={isLoading ? "Uploading..." : (buttonLabel || "Select File To Upload")}
              icon={<UploadOutlined />}
            />
          </Upload>
        </Form.Item>
      </Form>
      {uploadStatus === "failed" && (
        <Alert
          type="error"
          message="Failed to upload selected file"
        />
      )}
    </div>
  );
};

export { NewnessUploadStep1, NewnessUploadStep2 };
