import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import { useHistory } from "react-router-dom";
import { useRecoilValue, useResetRecoilState } from "recoil";
import DartTable from "../../../../components/DartTable";
import { getReplenishmentHistory } from "../..";
import columns from "./columns";
import paths from "../../../../configs/paths";
import { globalFiltersAtom, manualReplenishmentAtom } from "../../../../global/atoms";

const ReplenishmentHistorySection:React.FC = () => {
  const history = useHistory();
  const [historyData, setHistoryData] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(false);
  const resetManualReplenishmentState = useResetRecoilState(manualReplenishmentAtom);
  const { brand, channel } = useRecoilValue(globalFiltersAtom);

  useEffect(() => {
    getReplenishmentHistory(
      setHistoryData, setHistoryLoading, true, { brand, channel },
    );
  }, [brand, channel]);

  const handleReplenishmentContinue = (id: number) => {
    resetManualReplenishmentState();
    history.push(paths.manual_replenishment_step_one?.replace(":id", id.toString()));
  };

  const getColumns = useMemo(() => {
    return columns({
      onDartIdClick: (id) => handleReplenishmentContinue(id),
    });
  }, []);

  return (
    <div className="replenishment-history-section">
      <div className="heading">
        <h1>HISTORY</h1>
        {brand && (
          <>
            <div className="vertical-splitter" />
            { brand }
          </>
        )}
        {channel && (
          <>
            <div className="vertical-splitter" />
            { channel }
          </>
        )}
      </div>
      <hr />
      <DartTable
        wrapperClassName="replenishment-history-table"
        loading={historyLoading}
        columns={getColumns}
        data={historyData || []}
        tableStyle="dark"
        height="246px"
        fixed
      />
    </div>
  );
};

export default ReplenishmentHistorySection;
