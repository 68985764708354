const decideDivisionName = (name: string, fallback?: string) => {
  switch (name) {
    case "MEN": return "Men’s";
    case "WOMEN": return "Women’s";
    case "KID": return "Kids’";
    case "HOME_CARE": return "Homecare";
    default: return fallback || "Other";
  }
};

export default decideDivisionName;
