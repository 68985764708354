import moment from "moment";
import { IAllocationQuantityCC } from "../../../global/interfaces";
import apiClient from "../../../api/apiClient";
import endpoints from "../../../api/endpoints";
import { showErrorMessage } from "../../../utils/helpers";
import { IBrandTypes, IChannelTypes } from "../../../global/atoms/global-filters-atom";
import { brandNames, channelNames } from "../../../configs/constants";

const fetchInventoryDataForCC = async (
  inventory: any, setInventory: any, cc: IAllocationQuantityCC, channel: IChannelTypes,
) => {
  try {
    setInventory({
      status: "request", data: inventory.data || [], total: 0, future_total: 0,
    });
    if (cc?.cc) {
      const res: any = await apiClient.get(endpoints.inventory, {
        params: {
          cc: cc.cc,
          channel,
        },
      });

      const inventoryDataByWarehouse: any = {};
      (res.data?.data || [])?.forEach((item: any) => {
        if (!inventoryDataByWarehouse?.hasOwnProperty(item.warehouse_id)) {
          inventoryDataByWarehouse[item.warehouse_id] = [item];
        } else {
          inventoryDataByWarehouse[item.warehouse_id].push(item);
        }
      });

      const inventoryDataSorted = [];

      for (let o = 0; o < Object.keys(inventoryDataByWarehouse).length; o += 1) {
        const warehouse = Object.keys(inventoryDataByWarehouse)[o];
        const warehouseData = inventoryDataByWarehouse[warehouse];
        inventoryDataSorted.push({
          warehouse_id: warehouse,
          qty: warehouseData.reduce((acc: number, cur: any) => {
            // current available inventory, aka available inventory
            if (moment().isAfter(moment(cur.available_date))) {
              return acc + parseInt(cur.qty, 10) || 0;
            }
            return acc;
          }, 0),
          future_qty: warehouseData.reduce((acc: number, cur: any) => {
            // available inventory in the future, aka future inventory
            if (moment().isBefore(moment(cur.available_date))) {
              return acc + parseInt(cur.qty, 10) || 0;
            }
            return acc;
          }, 0),
        });
      }

      setInventory({
        status: "success",
        data: inventoryDataSorted,
        total: inventoryDataSorted.reduce((acc, cur) => acc + cur.qty, 0),
        future_total: inventoryDataSorted.reduce((acc, cur) => acc + cur.future_qty, 0),
      });
    }
  } catch (error: any) {
    setInventory({
      status: "failed", data: [], total: 0, future_total: 0,
    });
    showErrorMessage(error, "Failed to get CC inventory data");
  }
};

const fetchInventoryDataForBrandAndChannel = async (
  inventoryData: any, setInventoryData: any, brand: IBrandTypes, channel: IChannelTypes, skipDebounce = false, debounceMinutes = 20,
) => {
  try {
    // Check if we should re-fetch based on expires_at date and channel and brand
    const shouldReload = skipDebounce
      || !inventoryData?.data?.[`${channel}_${brand}`]
      || moment().isAfter(inventoryData?.data?.[`${channel}_${brand}`]?.expires_at);

    if (!shouldReload) {
      // We don't need to fetch anything, so auto-success the function
      setInventoryData?.({
        status: "success", data: inventoryData.data || {},
      });
      return inventoryData.data || {};
    }

    // We need to fetch data for channel and brand because it's not there OR it's expired
    setInventoryData?.({
      status: "request", data: inventoryData.data || {},
    });

    // If channel and brand are provided, do a re-fetch
    if (brand && channel && brandNames.find((e) => e.value === brand) && channelNames.find((e) => e.value === channel)) {
      // Call the API for fetching inventory based on brand and channel
      const res: any = await apiClient.post(endpoints.inventory_all, {
        brand,
        channel,
      });

      // Initialize empty channel_brand CCs inventory data
      const inventoryDataCCs: any = {};
      // Loop over the data returned from backend
      (res.data?.data || [])?.forEach((item: any) => {
        // Transform backend sent SKU into CC by removing last STYLE-COLOR-SIZE's "-SIZE" part
        const cc = item.sku.substring(0, item.sku.lastIndexOf("-"));
        // If we don't already have the warehouse data from previous loop - create it
        // If we don't already have the CC data from previous loop - create it
        if (!inventoryDataCCs?.hasOwnProperty(cc)) {
          inventoryDataCCs[cc] = {
            cc, per_warehouse: {}, totals: { qty: 0, future_qty: 0 },
          };
        }
        // Initial qty and future_qty should be zero (0) because we are creating it in the object first time
        let qty = inventoryDataCCs[cc].per_warehouse[item.warehouse_id]?.qty || 0;
        let future_qty = inventoryDataCCs[cc].per_warehouse[item.warehouse_id]?.future_qty || 0;
        // Check if item.qty goes to future or current inventory
        if (moment().isAfter(moment(item.available_date))) {
          qty += item.qty;
          // Increase the totals for IAQ details page
          inventoryDataCCs[cc].totals.qty += item.qty;
        } else {
          future_qty += item.qty;
          // Increase the totals for IAQ details page
          inventoryDataCCs[cc].totals.future_qty += item.qty;
        }
        // Assign quantities to warehouse in per_warehouse data in the CC
        inventoryDataCCs[cc].per_warehouse[item.warehouse_id] = {
          qty,
          future_qty,
          warehouse_id: item.warehouse_id,
        };
      });

      // Add expiry date to the channelBrandObj object
      const channelBrandObj = {
        expires_at: moment().add(debounceMinutes, "minute"),
        data: inventoryDataCCs,
      };

      // Generate channel and brand key based on their values
      const channel_brand_key = `${channel}_${brand}`;

      // Set success state
      setInventoryData?.({
        status: "success",
        data: {
          ...inventoryData.data,
          [channel_brand_key]: channelBrandObj,
        },
      });
      return {
        ...inventoryData.data,
        [channel_brand_key]: channelBrandObj,
      };
    }

    // If channel and brand is not provided - skip the call
    setInventoryData?.({
      status: "success",
      data: inventoryData.data || {},
    });

    return inventoryData.data || {};
  } catch (error: any) {
    setInventoryData?.({
      status: "failed", data: inventoryData.data || {},
    });
    showErrorMessage(error, "Failed to get brand/channel inventory data");
    return inventoryData.data || {};
  }
};

export {
  fetchInventoryDataForCC,
  fetchInventoryDataForBrandAndChannel,
};
