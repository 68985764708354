import React, { useCallback, useMemo } from "react";
import "./index.less";
import { Tooltip } from "antd";
import DartTable from "../../../../components/DartTable";
import columns from "./columns";
import { flowDocColumnValidations } from "./validations";
import SelectableCell from "../../../../components/SelectableCell";
import Icon from "../../../../components/Icon";

interface IFlowDocPreviewBlock {
  enums: any;
  tableData: any[];
  onFieldUpdate?: (ccs: string[], fieldName: string, fieldValue: any, error: string) => void;
  availableSizeRuns: any;
  selectedColumns: any,
  setSelectedColumns: any,
}

const FlowDocPreviewBlock:React.FC<IFlowDocPreviewBlock> = ({
  enums,
  tableData,
  onFieldUpdate,
  availableSizeRuns,
  selectedColumns,
  setSelectedColumns,
}) => {
  // Memoized Columns with props provided
  const getColumns = useMemo(() => {
    const cols = columns({
      enums,
      fieldValidations: flowDocColumnValidations(),
      onFieldUpdate: (
        cc: string, fieldName: string, fieldValue: any, error: string,
      ) => {
        if (selectedColumns.length > 0 && selectedColumns.includes(cc)) {
          // Bulk update
          onFieldUpdate?.(
            selectedColumns, fieldName, fieldValue, error,
          );
        } else {
          // Single Update
          onFieldUpdate?.(
            [cc], fieldName, fieldValue, error,
          );
        }
      },
      availableSizeRuns,
    });

    return cols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enums, tableData, availableSizeRuns, selectedColumns]);

  // Component which allows us to select specific rows in the table and displays isd and cc name next to it
  const selectCellComponent = useCallback((props: any) => {
    return (
      <div className="flow-doc-preview-cell cc-cell">
        {SelectableCell(props, !props.disabled)}
        <span className="cc">
          {props.rowData?.cc}
        </span>
        {props.rowData?.errors && props.rowData?.errors?.filter((eObj: any) => eObj.error.length > 0)?.length > 0 ? (
          <Tooltip
            placement="right"
            getTooltipContainer={() => document.body}
            overlay={(
              <ul style={{ margin: 0 }}>
                {props.rowData?.errors?.map((errorObj: { column: string, error: string }) => {
                  if (errorObj?.error?.length > 0) {
                    return (
                      <li
                        key={errorObj.column}
                        style={{ padding: 0 }}
                      >
                        {errorObj.error}
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            )}
          >
            <span className="error-tooltip-icon">
              <Icon
                name="Info"
                color="red"
                className="info-icon"
              />
            </span>
          </Tooltip>
        ) : ""}

      </div>
    );
  }, []);

  return (
    <div
      key={`${tableData?.length}_flow_doc_${Object.values(enums.brand || {})?.length}`}
      className={`flow-doc-preview-block ${navigator.platform.match("Mac") !== null ? "macOS" : "windows"}`}
    >
      <DartTable
        tableStyle="dark"
        rowKey="cc"
        withBorderTop
        columns={getColumns}
        data={tableData || []}
        width="100%"
        height="calc(100vh - 400px)"
        fixed
        wrapperClassName="flow-doc-preview-table"
        headerHeight={60}
        emptyRenderer={() => (
          <div style={{ padding: "48px calc(50% - 64px)" }}>
            No CCs to show
          </div>
        )}
        fixedFirstColumn
        defaultSelectedRowKeys={selectedColumns}
        selectable
        hasSelectAll
        selectColumnKey="cc"
        selectionCellTitle="Style Color"
        renderSelectionCellTitle
        selectionColumnWidth={250}
        selectionCellComponent={selectCellComponent}
        onSelectedRowsChange={(ccs: string[]) => {
          setSelectedColumns(ccs);
        }}
      />
    </div>
  );
};

export default FlowDocPreviewBlock;
