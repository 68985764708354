import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { IStoreListFilters } from "../interfaces";

const { persistAtom } = recoilPersist();

export const storeListFiltersInitialState = {
  status: ["ALL"], country: [], channel: [], brand: [], tags: [],
};

export const storeListFiltersAtom = atom({
  key: "store-list-filters",
  default: storeListFiltersInitialState as IStoreListFilters,
  effects_UNSTABLE: [persistAtom],
});
