import React, { useEffect, useState } from "react";
import "./index.less";
import { Form, message } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  createStore, StoreAssortmentTiers, StoreProfileDetails, StoreProfileHeader,
} from "../..";
import DartButton from "../../../../components/DartButton";
import StoreAllocationSettings from "../../blocks/StoreAllocationSettings";
import StoreTags from "../../blocks/StoreTags";
import {
  selectedStoreAtom, storeListAtom, storeSingleDataAtom,
} from "../../../../global/atoms";
import { valueToAssortmentValue } from "../../../../utils/helpers";
import { ISelectOption, IStoreProfile } from "../../../../global/interfaces";
import DartPrompt from "../../../../components/DartPrompt";
import { storeListFiltersAtom } from "../../../../global/atoms/store-list-filters-atom";
import paths from "../../../../configs/paths";
import useEnums from "../../../../utils/hooks/useEnums";
import { fetchAvailableTags } from "../../../common";
import CanAccess, {
  ADMIN, MANAGER, SUPER_ADMIN,
} from "../../../common/widgets/CanAccess";

interface IStoreProfileCreate {
  onBack: () => any;
}

const StoreProfileCreate:React.FC<IStoreProfileCreate> = ({
  onBack,
}) => {
  const storeFilters = useRecoilValue(storeListFiltersAtom);
  const [storeCreateData, setStoreCreateData] = useRecoilState(storeSingleDataAtom);
  const [storeList, setStoreList] = useRecoilState(storeListAtom);
  const [selectedStore, setSelectedStore] = useRecoilState(selectedStoreAtom);
  const history = useHistory();
  const [assortmentForm] = Form.useForm();
  const [detailsForm] = Form.useForm();
  const [generalInformationForm] = Form.useForm();
  const [allocationSettingsForm] = Form.useForm();
  const [sisterStoreSelected, setSisterStoreSelected] = useState<number | undefined>(undefined);
  const [tagDeleteLoading, setTagDeleteLoading] = useState(false);
  const [tagDeleteOpen, setTagDeleteOpen] = useState(false);
  const [deleteTagName, setDeleteTagName] = useState<string | undefined>();
  const [availableTags, setAvailableTags] = useState<any | undefined>();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { warehouse_id } = useEnums();
  const warehouseOptions = Object.values(warehouse_id || {})?.map((e) => { return { label: e, value: e }; });

  useEffect(() => {
    document.title = "DART - ADD NEW STORE";
  }, []);

  useEffect(() => {
    setSelectedStore(undefined);
    fetchAvailableTags(availableTags, setAvailableTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const askTagDelete = (tag: string) => {
    setTagDeleteOpen(true);
    setDeleteTagName(tag);
  };

  const handleTagDelete = async () => {
    setTagDeleteLoading(true);
    setTagDeleteOpen(false);
    if (deleteTagName) {
      setAvailableTags((prev: any) => { return { ...prev, data: [...prev?.data?.filter((t: string) => t !== deleteTagName)] }; });
      setSelectedTags((prev: any) => prev.filter((t: string) => t !== deleteTagName));
    }
    setTagDeleteLoading(false);
  };

  const handleSubmit = async () => {
    // check forms for errors
    let hasErrors = false;
    try {
      await detailsForm.validateFields();
    } catch (e) {
      hasErrors = true;
    }
    try {
      await generalInformationForm.validateFields();
    } catch (e) {
      hasErrors = true;
    }
    try {
      await allocationSettingsForm.validateFields();
    } catch (e) {
      hasErrors = true;
    }
    try {
      await assortmentForm.validateFields();
    } catch (e) {
      hasErrors = true;
    }
    if (hasErrors) {
      message.error("Please check all fields are correct");
      return;
    }

    // get field values from each form
    const detailsValues = detailsForm.getFieldsValue();
    const generalInformationValues = generalInformationForm.getFieldsValue();
    const allocationSettingsValues = allocationSettingsForm.getFieldsValue();
    const assortmentValues = assortmentForm.getFieldsValue();
    const tags = selectedTags;

    // Convert assortment tiers from dot separated string to object with nested properties
    const assortmentTiers: any = {};
    const keys = Object.keys(assortmentValues);
    const values: any = Object.values(assortmentValues);

    for (let i = 0; i < values.length; i += 1) {
      const key = keys[i];
      const value = values[i];
      const [division, department] = key.split(".");

      // If it's warehouse_id selection, append it to corresponding division.department object
      if (value !== "undefined" && !division.startsWith("WAREHOUSE_")) {
        if (!assortmentTiers?.[division]) {
          assortmentTiers[division] = {};
        }
        if (!assortmentTiers?.[division]?.[department]) {
          assortmentTiers[division][department] = {};
        }
        assortmentTiers[division][department].tier = valueToAssortmentValue(value);
      } else if (division.startsWith("WAREHOUSE_")) {
        assortmentTiers[division.replace("WAREHOUSE_", "")][department].warehouse_rels
          = value?.map((e: ISelectOption, index: number) => {
            return {
              warehouse_id: e.value,
              priority: index,
            };
          }) || [{ warehouse_id: warehouseOptions?.[0]?.value, priority: 0 }];
      }
    }

    // Create store profile data object
    const finalData = {
      name: generalInformationValues.name,
      number: generalInformationValues.number,
      channel: detailsValues.channel,
      brand: detailsValues.brand?.toUpperCase(),
      country: generalInformationValues.country,
      allocation_management_team: allocationSettingsValues.allocation_management_team,
      opening_at: allocationSettingsValues.opening_at ? moment(allocationSettingsValues.opening_at).format("YYYY-MM-DD") : undefined,
      closing_at: allocationSettingsValues.closing_at ? moment(allocationSettingsValues.closing_at).format("YYYY-MM-DD") : undefined,
      allocation_start_at: allocationSettingsValues.allocation_start_at
        ? moment(allocationSettingsValues.allocation_start_at).format("YYYY-MM-DD") : undefined,
      allocation_stop_at: allocationSettingsValues.allocation_stop_at
        ? moment(allocationSettingsValues.allocation_stop_at).format("YYYY-MM-DD") : undefined,
      state: generalInformationValues.state,
      city: generalInformationValues.city,
      district_manager_name: generalInformationValues.district_manager_name,
      departments: assortmentTiers,
      sister_store_id: sisterStoreSelected || generalInformationValues?.sister_store_id,
      tags,
    };

    // Create or copy store profile request
    createStore(
      !!sisterStoreSelected,
      storeCreateData,
      setStoreCreateData,
      finalData,
      storeList,
      setStoreList,
      storeFilters,
    );
  };

  useEffect(() => {
    if (storeCreateData && (storeCreateData.data as any).id) {
      setStoreCreateData({ status: "initial", data: [] });
      history.push(paths.edit_store.replace(":id", (storeCreateData.data as any).id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeCreateData]);

  return (
    <div className="store-profile-create">
      <StoreProfileHeader
        onBack={onBack}
      />
      <StoreProfileDetails
        topForm={detailsForm}
        bottomForm={generalInformationForm}
        availableSisterStores={(storeList.data || [])
          ?.filter((store: IStoreProfile) => store?.status === "LIVE")
          ?.map((store: IStoreProfile) => { return { label: `${store.number} | ${store.name}`, value: store.id }; })}
        onSisterStoreSelect={(value: number) => setSisterStoreSelected(value)}
      />
      <hr className="splitter" />
      <StoreAssortmentTiers
        form={assortmentForm}
        disabled={!!sisterStoreSelected}
      />
      <hr className="splitter" />
      <StoreAllocationSettings form={allocationSettingsForm} />
      <CanAccess requireRoles={[SUPER_ADMIN, ADMIN, MANAGER]}>
        <>
          <hr className="splitter" />
          <StoreTags
            handleAddTag={(tag) => {
              setSelectedTags((prev: any) => [...prev, tag]);
            }}
            handleRemoveTag={(tag) => {
              setSelectedTags((prev:any) => prev.filter((t: string) => t !== tag));
            }}
            handleCreateTag={(tag) => {
              setAvailableTags((prev: any) => { return { ...prev, data: [...prev?.data, tag] }; });
            }}
            handleDeleteTag={(tag) => {
              askTagDelete(tag);
            }}
            selectedTags={selectedTags}
            availableTags={availableTags?.data || []}
            copiedFromSister={!!sisterStoreSelected}
            loading={availableTags?.status === "request"}
          />
        </>
      </CanAccess>
      <DartPrompt
        title="Remove this tag?"
        content={(
          <div>
            This tag will be removed from this store.
            <br />
            Are you sure you want to proceed?
          </div>
        )}
        okText="Yes, remove this tag"
        cancelText="No, return to store profile"
        visible={tagDeleteOpen}
        onCancel={() => setTagDeleteOpen(false)}
        onOk={handleTagDelete}
        okButtonProps={{ loading: tagDeleteLoading }}
      />
      <DartButton
        isSubmit
        loading={storeCreateData.status === "request"}
        disabled={storeCreateData.status === "request" || selectedTags?.length > 3}
        className="store-profile-form-action"
        onClick={handleSubmit}
        label="Create"
        size="lg"
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default StoreProfileCreate;
