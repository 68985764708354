import React from "react";
import { MinusOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import moment from "moment";
import { IApiLogRow } from "./index";
import Icon from "../../../../components/Icon";
import DartButton from "../../../../components/DartButton";

const columns = (props: {
  onViewFileClick: (id: number) => void;
}): Record<string, any>[] => [
  {
    key: "id",
    dataIndex: "id",
    title: "ID",
    width: 100,
    flexShrink: 1,
    align: "left",
    cellRenderer: ({ rowData }: { rowData: IApiLogRow }) => {
      return (
        <div className="api-log-table-cell cursor-pointer">
          <span className="id-wrapper">{rowData?.id}</span>
        </div>
      );
    },
  },
  {
    key: "api_type",
    dataIndex: "api_type",
    title: "API Type",
    width: 150,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: { rowData: IApiLogRow }) => {
      return (
        <div className="api-log-table-cell">
          {rowData?.api_type}
        </div>
      );
    },
  },
  {
    key: "method",
    dataIndex: "method",
    title: "Method",
    width: 110,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: { rowData: IApiLogRow }) => {
      const decideTagColor = (method: string) => {
        switch (method) {
          case "POST":
            return "orange";
          case "GET":
            return "green";
          case "PUT":
            return "blue";
          case "DELETE":
            return "volcano";
          case "PATCH":
            return "geekblue";
          case "OPTIONS":
            return "magenta";
          default:
            return "cyan";
        }
      };
      return (
        <div className="api-log-table-cell">
          <Tag
            color={rowData?.method ? decideTagColor(rowData?.method?.toUpperCase()) : "cyan"}
            style={{ width: 55 }}
          >
            {rowData?.method?.toUpperCase()}
          </Tag>
        </div>
      );
    },
  },
  {
    key: "status_code",
    dataIndex: "status_code",
    title: "Status Code",
    width: 100,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: { rowData: IApiLogRow }) => {
      const decideStatusColor = (status?: number) => {
        switch (status?.toString()?.charAt(0)) {
          case "1":
            return "#19945D";
          case "2":
            return "#19945D";
          case "3":
            return "#5FC7CB";
          case "4":
            return "#E87324";
          case "5":
            return "rgb(252, 72, 72)";
          default:
            return "#333E47";
        }
      };
      return (
        <div className="api-log-table-cell">
          <span style={{ color: decideStatusColor(rowData?.status_code) }}>
            {rowData?.status_code}
          </span>
        </div>
      );
    },
  },
  {
    key: "url",
    dataIndex: "url",
    title: "URL",
    width: 60,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: { rowData: IApiLogRow }) => {
      return (
        <div className="api-log-table-cell">
          {rowData?.url ? (
            <a
              href={rowData?.url}
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="Copy" />
            </a>
          ) : NAComponent}
        </div>
      );
    },
  },
  // {
  //   key: "created_at",
  //   dataIndex: "created_at",
  //   title: "Created at",
  //   width: 250,
  //   flexGrow: 1,
  //   align: "center",
  //   cellRenderer: ({ rowData }: { rowData: IApiLogRow }) => {
  //     return (
  //       <div className="api-log-table-cell">
  //         <Icon
  //           name="Date"
  //           size={20}
  //           style={{ marginRight: 6, marginTop: -4 }}
  //         />
  //         {rowData?.created_at ? moment(rowData?.created_at).format("HH:mm:ss DD/MM/YYYY") : NAComponent}
  //       </div>
  //     );
  //   },
  // },
  {
    key: "start_time",
    dataIndex: "start_time",
    title: "Start Time",
    width: 250,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: { rowData: IApiLogRow }) => {
      return (
        <div className="api-log-table-cell">
          {rowData?.start_time ? (
            <span className="time-cell">
              <span>{moment(rowData?.start_time).format("HH:mm:ss.SSS")}</span>
              <span>{moment(rowData?.start_time).format("DD/MM/YYYY")}</span>
            </span>
          ) : NAComponent}
        </div>
      );
    },
  },
  {
    key: "end_time",
    dataIndex: "end_time",
    title: "End Time",
    width: 250,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: { rowData: IApiLogRow }) => {
      return (
        <div className="api-log-table-cell">
          {rowData?.end_time ? (
            <span className="time-cell">
              <span>{moment(rowData?.end_time).format("HH:mm:ss.SSS")}</span>
              <span>{moment(rowData?.end_time).format("DD/MM/YYYY")}</span>
            </span>
          ) : NAComponent}
        </div>
      );
    },
  },
  {
    key: "duration",
    dataIndex: "duration",
    title: "Duration",
    width: 150,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: { rowData: IApiLogRow }) => {
      return (
        <div className="api-log-table-cell">
          <Icon
            name="Expand"
            size={20}
            style={{
              transform: "rotateZ(90deg)", marginRight: 6, marginTop: -4,
            }}
          />
          {rowData?.duration ? `${rowData?.duration?.toFixed(3)} sec` : NAComponent}
        </div>
      );
    },
  },
  // {
  //   key: "is_inbound",
  //   dataIndex: "is_inbound",
  //   title: "Is Inbound",
  //   width: 100,
  //   flexGrow: 1,
  //   align: "center",
  //   cellRenderer: ({ rowData }: { rowData: IApiLogRow }) => {
  //     return (
  //       <div className="api-log-table-cell">
  //         {rowData?.is_inbound ? (
  //           <Icon
  //             name="Check"
  //             size={16}
  //             color="#E87324"
  //           />
  //         ) : (
  //           <Icon
  //             name="Close"
  //             size={16}
  //           />
  //         )}
  //       </div>
  //     );
  //   },
  // },
  {
    key: "action",
    dataIndex: "action",
    title: "Action",
    width: 280,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: { rowData: IApiLogRow }) => {
      return (
        <div className="api-log-table-cell">
          <DartButton
            icon="Document"
            label="View Log"
            type="dark"
            loading={rowData?.loading}
            onClick={() => {
              if (rowData?.id) {
                props.onViewFileClick(rowData?.id);
              }
            }}
          />
        </div>
      );
    },
  },
];

const NAComponent = <MinusOutlined style={{ fontSize: 14, color: "#C3C5C8" }} />;

export default columns;
