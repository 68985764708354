import "./index.less";
import {
  Alert, Form, Input,
} from "antd";
import { LockOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import Icon from "../../../../components/Icon";
import DartButton from "../../../../components/DartButton";
import {
  sizeProfileAtom,
  sizeRunExpandedListAtom,
  sizeRunListAtom,
  sizeRunSelectedListAtom,
  userAtom,
} from "../../../../global/atoms";
import { authenticate } from "../..";
import DartLogo from "../../../../assets/images/logo.png";
import paths from "../../../../configs/paths";

const SignIn = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const history = useHistory();
  const [form] = Form.useForm();

  const resetSizeProfile = useResetRecoilState(sizeProfileAtom);
  const resetSizeRun = useResetRecoilState(sizeRunListAtom);
  const resetExpandedSizeRun = useResetRecoilState(sizeRunExpandedListAtom);
  const resetSelectedSizeRun = useResetRecoilState(sizeRunSelectedListAtom);

  useEffect(() => {
    if (user?.error) { setUser({ ...user, error: undefined }); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.data?.idToken]);

  const onSubmit = async (values: any) => {
    await authenticate(
      values.email, values.password, setUser,
    );

    // clear size profile and size run on new "session"
    resetSizeProfile();
    resetSizeRun();
    resetExpandedSizeRun();
    resetSelectedSizeRun();
  };

  const navigateToForgotPassword = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const { email } = form.getFieldsValue();
    if (email) {
      history.push(paths.forgot_password_with_email.replace(":email", email));
    } else {
      history.push(paths.forgot_password);
    }
  };

  return (
    <div className="login-form">
      <div className="logo-wrap">
        <img
          src={DartLogo}
          alt="DART"
        />
      </div>
      <h1 className="signin-header">
        Welcome to
        <br />
        Deckers Allocation & Replenishment Tool
      </h1>
      <div className="inner-wrap">
        <Form
          data-testid="login-form"
          name="normal_login"
          onFinish={(values) => onSubmit(values)}
          form={form}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required",
              },
              {
                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Email is invalid",
              },
            ]}
          >
            <Input
              data-testid="login-form-email-field"
              prefix={<Icon name="Profile" />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Password is required",
              },
            ]}
          >
            <Input
              data-testid="login-form-password-field"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <a
            className="forgot-password-button"
            href="#"
            onClick={navigateToForgotPassword}
          >
            Forgot password?
          </a>
          <Form.Item data-testid="login-form-submit-button">
            <DartButton
              type="primary"
              label="Sign In"
              size="md"
              loading={user.status === "request"}
              onClick={() => form.submit()}
            />
          </Form.Item>
          {user.error && (
            <Alert
              type="error"
              message={user.error}
            />
          )}
        </Form>
      </div>
    </div>
  );
};

export default SignIn;
