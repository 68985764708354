import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const allocationProcessAtom = atom({
  key: "allocation-process",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
