import React from "react";
import "./index.less";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Avatar, Button, Spin, Tooltip,
} from "antd";
import { useRecoilValue } from "recoil";
import { createHash } from "crypto";
import { IUser } from "../../../../global/interfaces";
import Icon from "../../../../components/Icon";
import { userAtom } from "../../../../global/atoms";

interface IExistingUser {
  user: IUser;
  onBack?: () => any;
  onDelete?: (username: string) => any;
  deleteLoading?: boolean;
}

interface INewUser {
  user?: never;
  onBack?: () => any;
  onDelete?: never;
  deleteLoading?: never;
}

const SizeRunPageHeader: React.FC<IExistingUser | INewUser> = ({
  onBack,
  onDelete,
  user,
  deleteLoading,
}) => {
  const authUser = useRecoilValue(userAtom);

  return (
    <div className="user-page-header">
      <div className="top-section">
        {onBack && (
          <Button
            className="back-button"
            onClick={onBack}
            type="ghost"
            shape="circle"
          >
            <ArrowLeftOutlined style={{ fontSize: 16 }} />
          </Button>
        )}
        {user && onDelete && user?.username ? (
          <Button
            className="delete-button"
            onClick={() => onDelete?.(user?.username || "")}
            type="ghost"
          >
            <p>Delete this User</p>
            {!deleteLoading ? (
              <Icon
                name="Delete"
                size={18}
                color="#000000"
              />
            ) : (
              <Spin
                size="small"
                style={{ marginTop: 4 }}
              />
            )}
          </Button>
        ) : <></>}
      </div>
      <div className="bottom-section">
        <div className="user-name">
          {!user
            ? (
              <span>Add New User</span>
            )
            : (
              <span>
                {user?.name}
              </span>
            )}
        </div>
        <Tooltip overlay={!user ? "This adorable robot avatar will be generated based on your email" : undefined}>
          <Avatar
            className="avatar"
            src={`https://robohash.org/${user?.username ? createHash("sha256").update(user.username).digest("hex") : "johndoe"}.png?bgset=bg2`}
            size="default"
          />
        </Tooltip>
        {authUser?.username === user?.username && (
          <div className="you-indicator">
            YOU
          </div>
        )}
      </div>
      <hr className="splitter" />
    </div>
  );
};

export default SizeRunPageHeader;
