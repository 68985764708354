import { selector } from "recoil";
import { newnessDashboardFiltersAtom } from "../atoms";

export const newnessDashboardFiltersSelector = selector({
  key: "newness-dashboard-filters-selector",
  get: ({ get }) => {
    const atom = get(newnessDashboardFiltersAtom);
    return atom;
  },
});
