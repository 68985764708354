import React, { useEffect } from "react";
import "./index.less";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { message } from "antd";
import { selectedSizeRunAtom, sizeRunListAtom } from "../../../../../global/atoms";
import paths from "../../../../../configs/paths";
import { ISizeRun } from "../../../../../global/interfaces";
import { getSizeRunList } from "../../services/sizeRun";
import SizeRunView from "../../widgets/SizeRunView";
import SizeRunCreate from "../../widgets/SizeRunCreate";

const SizeRunLayout:React.FC = () => {
  const { id } = useParams<{id: string}>();
  const [selectedSizeRun, setSelectedSizeRun] = useRecoilState(selectedSizeRunAtom);
  const [sizeRunList, setSizeRunList] = useRecoilState(sizeRunListAtom);
  const history = useHistory();

  useEffect(() => {
    if (id) {
      setSelectedSizeRun(+id);
    } else if (selectedSizeRun && !id) {
      history.push(paths.size_profile);
    }
  }, [selectedSizeRun, id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedSizeRun]);

  // on ID change fetch size runs if no matching size run with ID is found. if after this, still not found, redirect back (below useEffect)
  useEffect(() => {
    if ((id && +id && sizeRunList?.originalData?.filter((s: ISizeRun) => s.id === +id)?.length === 0) || sizeRunList?.status !== "success") {
      getSizeRunList(sizeRunList, setSizeRunList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // check if size run with ID exists, else redirect back to list
  useEffect(() => {
    if ((id && selectedSizeRun
      && sizeRunList?.originalData?.length > 0
      && sizeRunList?.status === "success"
      && sizeRunList?.originalData?.filter((s: ISizeRun) => s.id === +id)?.length === 0) || (id && sizeRunList?.status === "failed")) {
      message.error("Size Run not found");
      history.push(paths.size_profile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sizeRunList]);

  return (
    <div className="size-run-layout-wrap">
      <div className="size-run-layout">
        {selectedSizeRun ? (
          // eslint-disable-next-line react/jsx-no-undef
          <SizeRunView
            onBack={() => { setSelectedSizeRun(undefined); history.push(paths.size_profile); }}
          />
        ) : (
          <SizeRunCreate
            onBack={() => { setSelectedSizeRun(undefined); history.push(paths.size_profile); }}
          />
        )}
      </div>
    </div>
  );
};

export default SizeRunLayout;
