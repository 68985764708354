import moment from "moment";
import apiClient from "../../../api/apiClient";
import endpoints from "../../../api/endpoints";
import {
  only, serializeParams, showErrorMessage, tableToObjectArray,
} from "../../../utils/helpers";
import { ISelectedCC } from "../../../global/interfaces";
import { orderTypes } from "../../../configs/constants";

type FilterNewness = {
  brand: string;
  channel: string;
  months: string[];
  order_type?: keyof typeof orderTypes;
  shipping_date?: string;
}

const getNewnessList = async (
  newnessListData: any, setNewnessListData: any, filters: FilterNewness,
) => {
  try {
    setNewnessListData({
      status: "request", data: newnessListData.data, order_type: filters.order_type || orderTypes.regular, shipping_date: filters.shipping_date,
    });
    if (filters.brand && filters.channel && filters.months?.length > 0) {
      const months = filters?.months?.map((date) => moment(date, "MMM, YYYY").format("YYYY-MM-DD"));
      const res:any = await apiClient.get(endpoints.allocationNewnessList, {
        params:
          {
            ...only(["brand", "channel"], filters),
            ...(months && { month: months }),
            ...(filters.shipping_date ? { shipping_date: moment(filters.shipping_date).format("YYYY-MM-DD") } : {}),
          },
        paramsSerializer: (params) => serializeParams(params),
      });
      setNewnessListData({
        status: "success",
        data: tableToObjectArray(res.data.data, res.data.columns) || [],
        order_type: filters.order_type || orderTypes.regular,
        shipping_date: filters.shipping_date,
      });
    } else {
      setNewnessListData({
        status: "success", data: [], order_type: filters.order_type || orderTypes.regular, shipping_date: filters.shipping_date,
      });
    }
  } catch (error: any) {
    setNewnessListData({
      status: "failed", data: [], order_type: filters.order_type || orderTypes.regular, shipping_date: filters.shipping_date,
    });
    showErrorMessage(error, "Failed to get newness list");
  }
};

const appendNewnessSizesToList = async (
  selectedCCs: any, setSelectedCCs: any, filters: FilterNewness,
) => {
  try {
    setSelectedCCs({
      status: "request",
      data: selectedCCs || [],
      order_type: filters.order_type || orderTypes.regular,
      shipping_date: filters.shipping_date,
    });
    const res = await apiClient.get(endpoints.allocationNewnessSize, {
      params: {
        ...only(["channel", "brand"], filters),
        ...(filters.shipping_date ? { shipping_date: moment(filters.shipping_date).format("YYYY-MM-DD") } : {}),
      },
    });
    const newSelectedCCs: any = [];
    for (let i = 0; i < selectedCCs.length; i += 1) {
      const cc: any = {};
      cc.month = selectedCCs[i].month;
      cc.data = {};
      const ccData = cc.data;
      ccData.completeCC = {
        ccs: matchNewnessCCWithSizes(selectedCCs[i]?.data?.completeCC?.ccs || [], res),
        percent: selectedCCs[i]?.data?.completeCC?.percent,
      };
      ccData.readyCC = {
        ccs: matchNewnessCCWithSizes(selectedCCs[i]?.data?.readyCC?.ccs || [], res),
        percent: selectedCCs[i]?.data?.readyCC?.percent,
      };
      ccData.notReadyCC = {
        ccs: matchNewnessCCWithSizes(selectedCCs[i]?.data?.notReadyCC?.ccs || [], res),
        percent: selectedCCs[i]?.data?.notReadyCC?.percent,
      };
      cc.data = ccData;
      newSelectedCCs.push(cc);
    }
    setSelectedCCs({
      status: "success",
      data: newSelectedCCs || [],
      order_type: filters.order_type || orderTypes.regular,
      shipping_date: filters.shipping_date,
    });
  } catch (error: any) {
    setSelectedCCs({
      status: "failed",
      data: [],
      order_type: filters.order_type || orderTypes.regular,
      shipping_date: filters.shipping_date,
    });
    showErrorMessage(error, "Failed to get newness sizes");
  }
};

// HELPER FUNCTION TO MATCH NEWNESS CC'S WITH SIZES
const matchNewnessCCWithSizes = (selectedCCs: ISelectedCC[], newnessSizeResponse: any) => {
  const newnessList = selectedCCs;
  const newnessSizes = newnessSizeResponse?.data?.data || [];
  const newnessSizesKeys = Object.keys(newnessSizes);
  const modifiedNewnessList: any = [];
  for (let i = 0; i < newnessList.length; i += 1) {
    // Check if exists
    const hasCC = newnessSizesKeys.find((key) => key === newnessList[i].cc);
    // Remove duplicates
    const existsInModifiedList = modifiedNewnessList.find((item: any) => item.cc === newnessList[i].cc);
    if (hasCC && !existsInModifiedList) {
      // Push into new array with cc_sizes nesting
      modifiedNewnessList.push({
        ...newnessList[i],
        cc_sizes: [...(tableToObjectArray(newnessSizes[hasCC], newnessSizeResponse.data.columns) || [])],
      });
    }
  }
  return modifiedNewnessList;
};

export {
  getNewnessList,
  appendNewnessSizesToList,
};
