import React from "react";
import DartCheckbox from "../../../../components/DartCheckbox";

type visibilityProps = {
  shouldSeeClasses: boolean;
  shouldSeeStyles: boolean;
  shouldSeeCustom: boolean;
  setShouldSeeClasses: (value: boolean) => void;
  setShouldSeeStyles: (value: boolean) => void;
  setShouldSeeCustom: (value: boolean) => void;
}

const renderPOATableHeader = ({
  cells,
  columns: columnsList,
  headerIndex,
}: any, visibilityProps: visibilityProps) => {
  if (headerIndex === 1) return cells;
  const groupCells: any = [];
  const classColumnCount = columnsList.filter((c: any) => c?.className?.includes("class")).length;
  const styleColumnCount = columnsList.filter((c: any) => c?.className?.includes("style")).length;
  const customColumnCount = columnsList.filter((c: any) => c?.className?.includes("custom")).length;
  columnsList.forEach((column: any, columnIndex: any) => {
    let width = 0;
    if (columnIndex === 0) {
      width = cells[columnIndex].props.style.width;
      groupCells.push((
        <div
          key={`header-group-cell-${column.key}`}
          className="store-header-cell"
          style={{ ...cells[columnIndex].props.style, width }}
        >
          <DartCheckbox
            onChange={(checked) => { visibilityProps.setShouldSeeClasses(checked); }}
            checked={visibilityProps.shouldSeeClasses}
            label="Classes"
            size="xs"
          />
          <DartCheckbox
            onChange={(checked) => { visibilityProps.setShouldSeeStyles(checked); }}
            checked={visibilityProps.shouldSeeStyles}
            label="Styles"
            size="xs"
          />
          <DartCheckbox
            onChange={(checked) => { visibilityProps.setShouldSeeCustom(checked); }}
            checked={visibilityProps.shouldSeeCustom}
            label="Custom"
            size="xs"
          />
        </div>));
    } else if (visibilityProps.shouldSeeClasses
      && columnIndex === 1
      && classColumnCount > 0) {
      width = cells[columnIndex].props.style.width * classColumnCount;
      groupCells.push((
        <div
          key={`header-group-cell-${column.key}`}
          className="class-header-cell"
          style={{ ...cells[columnIndex].props.style, width }}
        >
          Classes
        </div>));
    } else if (visibilityProps.shouldSeeStyles
      && columnIndex === (visibilityProps.shouldSeeClasses ? classColumnCount : 0) + 1
      && styleColumnCount > 0) {
      width = cells[columnIndex].props.style.width * styleColumnCount;
      groupCells.push((
        <div
          key={`header-group-cell-${column.key}`}
          className="style-header-cell"
          style={{ ...cells[columnIndex].props.style, width }}
        >
          Styles
        </div>));
    } else if (visibilityProps.shouldSeeCustom
      && columnIndex === (visibilityProps.shouldSeeClasses ? classColumnCount : 0) + (visibilityProps.shouldSeeStyles ? styleColumnCount : 0) + 1
      && customColumnCount > 0) {
      width = cells[columnIndex].props.style.width * customColumnCount;
      groupCells.push((
        <div
          key={`header-group-cell-${column.key}`}
          className="custom-header-cell"
          style={{ ...cells[columnIndex].props.style, width }}
        >
          Custom
        </div>));
    }
  });
  return groupCells;
};

export default renderPOATableHeader;
