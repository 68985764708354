import React from "react";
import DartCheckbox from "../DartCheckbox";

const SelectableCell = ({
  rowData, rowIndex, column,
}: any, editable: boolean) => {
  const handleChange = (isChecked:boolean) => {
    const { onChange } = column;
    onChange({
      selected: isChecked, rowData, rowIndex,
    });
  };

  const { selectedRowKeys, rowKey } = column;
  const checked = selectedRowKeys.includes(rowData[rowKey]) || selectedRowKeys.includes(rowKey);

  return (
    <DartCheckbox
      className="withoutDisabledStyle"
      size="md"
      disabled={!editable}
      checked={checked}
      onChange={(v) => (editable ? handleChange(v) : false)}
    />
  );
};

export default SelectableCell;
