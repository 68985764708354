import React, {
  useCallback, useMemo, useState,
} from "react";
import "./index.less";
import moment from "moment";
import { useRecoilValue } from "recoil";
import DartTable from "../../../../components/DartTable";
import columns from "./columns";
import { ISelectedCC } from "../../../../global/interfaces";
import { removeNullValues } from "../../../../utils/helpers";
import { globalFiltersAtom } from "../../../../global/atoms";
import SelectableCell from "../../../../components/SelectableCell";
import { orderTypes } from "../../../../configs/constants";

interface IReadinessTableBlock {
  type: "ready" | "not_ready" | "completed";
  tableData?: ISelectedCC[];
  onSelectedRowsChange?: (selectedCCs: ISelectedCC[]) => void;
  orderType?: keyof typeof orderTypes;
}

const ReadinessTableBlock:React.FC<IReadinessTableBlock> = ({
  type,
  tableData,
  onSelectedRowsChange,
  orderType = orderTypes.regular,
}) => {
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const globalFilters = useRecoilValue<any>(globalFiltersAtom);

  // Handler to alter selected CC list
  const handleSelectedRowChange = (selectedRowsList: string[]) => {
    const filteredRows = (tableData || []).filter((td) => {
      return selectedRowsList.indexOf(td.id) > -1;
    });
    const sanitizedRows = filteredRows.map((r) => {
      return removeNullValues({
        ...r,
        childRender: null,
      });
    });
    onSelectedRowsChange?.(sanitizedRows);
  };

  // Memoized Columns with props provided
  const getColumns = useMemo(() => {
    return columns({
      hasGTM: globalFilters?.channel === "concept",
      orderType: orderType as keyof typeof orderTypes,
    });
  }, [orderType, globalFilters?.channel]);

  // Component which allows us to select specific rows in the table and displays isd and cc name next to it
  const selectCellComponent = useCallback((props: any) => {
    return (
      <div className="readiness-table-cell uuid-cell">
        {SelectableCell(props, !props.disabled)}
        <div className="wrapper">
          <div className="uuid">
            {props.rowData?.cc || props.rowData?.id}
          </div>
          {props.rowData?.isd && (
            <div className="date">
              {moment(props.rowData?.isd).format("MMM YYYY")}
            </div>
          )}
        </div>
      </div>
    );
  }, [type]);

  return (
    <div
      key={`${type}`}
      className={`readiness-table-block ${type}`}
    >
      <DartTable
        withBorderTop
        columns={getColumns}
        data={tableData}
        width="100%"
        height="calc(100vh - 324px)"
        fixed={false}
        wrapperClassName="readiness-table"
        headerHeight={60}
        childHeight={500}
        onExpandedRowsChange={(expandedRowList: number[]) => setExpandedRows(expandedRowList)}
        onSelectedRowsChange={handleSelectedRowChange}
        defaultExpandedRowKeys={expandedRows}
        selectable
        selectColumnKey="id"
        expandable
        hasExpandAll
        hasSelectAll
        expandColumnKey="id"
        selectionColumnWidth={260}
        selectionCellComponent={(props) => selectCellComponent({ ...props, disabled: type === "completed" })}
        defaultAllSelected={type === "ready"}
      />
    </div>
  );
};

export default ReadinessTableBlock;
