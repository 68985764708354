const countryCodeToName = (code: string): string => {
  switch (code) {
    case "US":
      return "United States";
    case "CA":
      return "Canada";
    case "GB":
      return "United Kingdom";
    case "AT":
      return "Austria";
    case "BE":
      return "Belgium";
    case "DE":
      return "Germany";
    case "FR":
      return "France";
    case "NL":
      return "Netherlands";
    case "CH":
      return "Switzerland";
    case "CN":
      return "China";
    case "JP":
      return "Japan";
    default:
      return code;
  }
};

export default countryCodeToName;
