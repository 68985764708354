import React from "react";
import {
  Button, message, Modal,
} from "antd";
import { Auth } from "aws-amplify";
import { showErrorMessage } from "../../../utils/helpers";
import { regions } from "../../../configs/constants";
import { canAccess, SUPER_ADMIN } from "../../common/widgets/CanAccess";
import paths from "../../../configs/paths";
import apiClient from "../../../api/apiClient";
import endpoints from "../../../api/endpoints";

const getUser = async (oldUser: any, setUser: any) => {
  try {
    setUser({
      status: "request", data: oldUser.data ?? {}, region: oldUser.region, role: oldUser.role, email: undefined, username: undefined,
    });
    const user = await Auth.currentSession();
    const authUser = await Auth.currentAuthenticatedUser();
    setUser({
      status: "success",
      data: user,
      region: authUser?.attributes?.["custom:region"],
      role: authUser?.attributes?.["custom:dart-role"],
      email: authUser?.attributes?.email,
      username: authUser?.username,
    });
  } catch (error: any) {
    setUser({
      status: "failed", data: {}, error: error?.message, region: undefined, role: undefined, email: undefined, username: undefined,
    });
  }
};

const authenticate = async (
  email: string, password: string, setUser: any,
) => {
  const { confirm } = Modal;
  try {
    setUser({
      status: "request", data: {}, region: undefined, role: undefined, email: undefined, username: undefined,
    });
    const user = await Auth.signIn(email, password);
    if (user.challengeName && user.challengeName === "NEW_PASSWORD_REQUIRED") {
      window.location.href = paths.set_password.replace(":email", email);
    }
    // Check if user is superadmin. If yes - choose region and sign in, else just sign in
    if (canAccess(
      true, { role: user?.attributes?.["custom:dart-role"] }, { roles: [SUPER_ADMIN] }, false,
    )) {
      const regionConfirm = confirm({
        title: "Choose DART Region",
        content: (
          <div style={{
            display: "flex", flexDirection: "column", rowGap: 4,
          }}
          >
            {
              Object.keys(regions).map((regionKey: any) => (
                <Button
                  key={regionKey}
                  type={user?.attributes?.["custom:region"] === regionKey ? "primary" : "default"}
                  onClick={() => {
                    changeUserRegion(
                      {
                        status: "success",
                        data: user.getSignInUserSession(),
                        role: user?.attributes?.["custom:dart-role"],
                        email: user?.attributes?.email,
                        username: user?.username,
                      },
                      setUser,
                      regionKey,
                    );
                    regionConfirm.destroy();
                  }}
                >
                  {regions[regionKey as keyof typeof regions]}
                </Button>
              ))
            }
          </div>
        ),
        icon: null,
        okButtonProps: { style: { display: "none" } },
        cancelButtonProps: { style: { display: "none" } },
      });
      return;
    }
    setUser({
      status: "success",
      data: user.getSignInUserSession(),
      region: user?.attributes?.["custom:region"],
      role: user?.attributes?.["custom:dart-role"],
      email: user?.attributes?.email,
      username: user?.username,
    });
  } catch (error: any) {
    setUser({
      status: "failed",
      data: {},
      error: error?.message || "Unexpected Error",
      region: undefined,
      role: undefined,
      email: undefined,
      username: undefined,
    });
  }
};

const completeNewPassword = async (
  email: string, oldPassword: string, newPassword: string, setUser: any,
) => {
  try {
    setUser({
      status: "request", data: {}, region: undefined, role: undefined, email: undefined, username: undefined,
    });
    const user = await Auth.signIn(email, oldPassword);
    if (!user.challengeName || user.challengeName !== "NEW_PASSWORD_REQUIRED") {
      window.location.href = paths.signin;
    }
    await Auth.completeNewPassword(user, newPassword);
    const newUserObj = await Auth.signIn(email, newPassword);
    setUser({
      status: "success",
      data: newUserObj.getSignInUserSession(),
      region: newUserObj?.attributes?.["custom:region"],
      role: newUserObj?.attributes?.["custom:dart-role"],
      email: newUserObj?.attributes?.email,
      username: newUserObj?.username,
    });
  } catch (error: any) {
    setUser({
      status: "failed",
      data: {},
      error: error?.message || "Could not update user password",
      region: undefined,
      role: undefined,
      email: undefined,
      username: undefined,
    });
  }
};

const changeUserRegion = async (
  currentUserData: any, setUser: any, region: string,
) => {
  try {
    const authUser = await Auth.currentAuthenticatedUser();
    if (authUser.attributes["custom:region"] !== region) {
      await apiClient.patch(
        endpoints.usersUpdate.replace(":id", authUser.username), { region }, {
          headers: {
            Authorization: `Bearer ${currentUserData.data?.idToken?.jwtToken}}`,
          },
        },
      );
      setUser({
        ...currentUserData,
        region,
      });
      message.success("Region changed successfully");
    } else {
      setUser({
        ...currentUserData,
        region,
      });
    }
  } catch (error: any) {
    showErrorMessage(error?.message || error, "Error while changing user region");
  }
};

const register = async (
  email: string, password: string, setUser: any,
) => {
  try {
    setUser({ status: "request", data: {} });
    const user = await Auth.signUp(email, password);
    setUser({ status: "success", data: user });
  } catch (error: any) {
    setUser({
      status: "failed", data: {}, error: error?.message || "Unexpected Error",
    });
  }
};

const logout = async (setUser: any) => {
  try {
    setUser({
      status: "request", data: {}, region: undefined, role: undefined, email: undefined, username: undefined,
    });
    await Auth.signOut({ global: true });
    setUser({
      status: "success", data: {}, region: undefined, role: undefined, email: undefined, username: undefined,
    });
  } catch (error: any) {
    setUser({
      status: "failed",
      data: {},
      error: error?.message || "Unexpected Error",
      region: undefined,
      role: undefined,
      email: undefined,
      username: undefined,
    });
  }
};

export {
  authenticate, register, logout, getUser, changeUserRegion, completeNewPassword,
};
