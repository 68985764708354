import React, {
  MouseEventHandler, useEffect, useState,
} from "react";
import "./index.less";
import Select, {
  components, MultiValueGenericProps, MultiValueProps, OnChangeValue, Props,
} from "react-select";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
  SortEndHandler,
} from "react-sortable-hoc";

interface IDartDragSelect extends Props{
  options: any[];
  value?: any[];
  placeholder?: string;
  hasRemoveAll?: boolean;
  hasSearch?: boolean;
  removeOnBackspace?: boolean;
  isMulti?: boolean;
  [key: string]: any;
}

const arrayMove = (
  array: readonly any[], from: number, to: number,
) => {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0],
  );
  return slicedArray;
};

const SortableMultiValue = SortableElement((props: MultiValueProps<any>) => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return (
    <components.MultiValue
      {...props}
      innerProps={innerProps}
    />
  );
});

const SortableMultiValueLabel = SortableHandle((props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />);

const SortableSelect = SortableContainer(Select) as React.ComponentClass<
  Props<any, true> & SortableContainerProps
  >;

const DartDragSelect:React.FC<IDartDragSelect> = ({
  options,
  value,
  onChange,
  placeholder = "Select options",
  hasRemoveAll = false,
  hasSearch = false,
  removeOnBackspace = false,
  isMulti = true,
  ...rest
}) => {
  const [selected, setSelected] = useState<readonly any[]>(value || rest?.defaultValue as any || []);

  useEffect(() => {
    onChange?.(selected as any, {} as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleChange = (selectedOptions: OnChangeValue<any, true>) => {
    setSelected(selectedOptions);
  };

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(
      selected, oldIndex, newIndex,
    );
    setSelected(newValue);
  };

  return (
    <SortableSelect
      className="dart-drag-select"
      classNamePrefix="dart-drag-select"
      useDragHandle
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      isMulti={isMulti as any}
      options={options}
      value={selected}
      onChange={handleChange}
      components={{
        // @ts-ignore
        MultiValue: SortableMultiValue,
        // @ts-ignore
        MultiValueLabel: SortableMultiValueLabel,
      }}
      isFocused
      isClearable={hasRemoveAll}
      isSearchable={hasSearch}
      placeholder={placeholder}
      backspaceRemovesValue={removeOnBackspace}
      closeMenuOnSelect={false}
      // closeMenuOnSelect={selected?.length + 1 === options?.length} // Auto close when last item selected
      noOptionsMessage={() => "No more options"}
      {...rest}
    />
  );
};

export default DartDragSelect;
