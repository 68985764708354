/* eslint-disable */
import {IReplenishmentCC} from "../../../global/interfaces";
import distributeSendMoreToSizes from "../utils/distributeSendMoreToSizes";

interface IUpdateObject {
  type: "minWOH" | "storeSendMore" | "skuSendMore" | "skuStoreSendMore" | "storeExcludedToggle";
  value: number | boolean,
  identifier: string,
  storeId?: number,
  ccIdentifier: string,
}

const onMinWOHUpdate = (minWOH: number, replenishment: IReplenishmentCC, setManualReplenishmentState: any) => {
  setManualReplenishmentState((prevState: any) => {
    return buildReplenishmentState({
      ...prevState,
      ccs: prevState.ccs.map((each: any) => {
        if ((each.cc || each) === replenishment.cc) {
          return {
            ...each,
            minWOH: +minWOH,
          };
        }
        return each;
      }),
    }, prevState?.ccData, {
      type: "minWOH",
      value: +minWOH,
      identifier: replenishment.cc,
      storeId: undefined,
      ccIdentifier: replenishment.cc,
    });
  });
};

const onStoreSendMoreUpdate = (sendMore: number, record: any, replenishment: IReplenishmentCC, setManualReplenishmentState: any) => {
  setManualReplenishmentState((prevState: any) => {
    return buildReplenishmentState({
      ...prevState,
      ccs: prevState.ccs.map((each: any) => {
        if ((each.cc || each) === replenishment.cc) {
          return {
            ...each,
            stores: each?.stores?.map((eachStore: any) => {
              if ((eachStore?.store?.id) === record?.store?.id) {
                const sendMoreDistributionPerSize: any[] | false = distributeSendMoreToSizes(+sendMore, eachStore?.sizes);
                return {
                  ...eachStore,
                  sendMore: +sendMore,
                  sizes: eachStore?.sizes?.map((size: any) => {
                    const distributedCount = !!sendMoreDistributionPerSize ? sendMoreDistributionPerSize?.find((k) => k?.size === size?.size)?.sendMore : 0;
                    return {
                      ...size,
                      sendMore: !!sendMoreDistributionPerSize ? (distributedCount || 0) : size?.sendMore,
                    }
                  })
                };
              }
              return eachStore;
            })
          };
        }
        return each;
      }),
    }, prevState?.ccData, {
      type: "storeSendMore",
      value: +sendMore,
      identifier: record?.store?.id,
      storeId: record?.store?.id,
      ccIdentifier: replenishment.cc,
    });
  });
};

const onStoreExcludedToggle = (excluded: boolean, record: any, replenishment: IReplenishmentCC, setManualReplenishmentState: any) => {
  setManualReplenishmentState((prevState: any) => {
    return buildReplenishmentState({
      ...prevState,
      ccs: prevState.ccs.map((each: any) => {
        if ((each.cc || each) === replenishment.cc) {
          return {
            ...each,
            stores: each?.stores?.map((eachStore: any) => {
              const reCalculatedStoreTotalSendMore = eachStore?.sizes?.reduce((acc: any, cur: any) => {
                return acc + (+cur?.sendMore);
              }, 0);
              if ((eachStore?.store?.id) === record?.store?.id) {
                return {
                  ...eachStore,
                  sendMore: reCalculatedStoreTotalSendMore,
                  excluded: excluded,
                };
              }
              return {
                ...eachStore,
                sendMore: reCalculatedStoreTotalSendMore,
              };
            })
          };
        }
        return each;
      }),
    }, prevState?.ccData, {
      type: "storeExcludedToggle",
      value: excluded,
      identifier: record?.store?.id,
      storeId: record?.store?.id,
      ccIdentifier: replenishment.cc,
    });
  });
};

const onSKUSendMoreUpdate = (sendMore: number, storeId: number, skuData: any, setManualReplenishmentState: any) => {
  setManualReplenishmentState((prevState: any) => {
    return buildReplenishmentState({
      ...prevState,
      ccs: prevState.ccs.map((each: any) => {
        if ((each.cc || each) === skuData.cc) {
          return {
            ...each,
            stores: each?.stores?.map((eachStore: any) => {
              if ((eachStore?.store?.id) === storeId) {
                const calculatedStoreTotalSendMore = eachStore?.sizes?.reduce((acc: any, cur: any) => {
                  return acc + (cur?.size === skuData?.size ? +sendMore : +cur?.sendMore);
                }, 0);
                return {
                  ...eachStore,
                  sendMore: calculatedStoreTotalSendMore,
                  sizes: eachStore?.sizes?.map((size: any) => {
                    if(size?.size === skuData?.size){
                      return {
                        ...size,
                        sendMore: +sendMore,
                      }
                    }
                    return size;
                  }),
                };
              }
              return eachStore;
            })
          };
        }
        return each;
      }),
    }, prevState?.ccData, {
      type: "skuSendMore",
      value: +sendMore,
      identifier: skuData?.size,
      storeId: storeId,
      ccIdentifier: skuData.cc,
    });
  })
};

const onSKUStoreSendMoreUpdate = (sendMore: number, storeId: number, skuData: any, setManualReplenishmentState: any) => {
  setManualReplenishmentState((prevState: any) => {
    return buildReplenishmentState({
      ...prevState,
      ccs: prevState.ccs.map((each: any) => {
        if ((each.cc || each) === skuData.cc) {
          return {
            ...each,
            stores: each?.stores?.map((eachStore: any) => {
              if ((eachStore?.store?.id) === storeId) {
                const calculatedStoreTotalSendMore = eachStore?.sizes?.reduce((acc: any, cur: any) => {
                  return acc + (cur?.size === skuData?.size ? +sendMore : +cur?.sendMore);
                }, 0);
                return {
                  ...eachStore,
                  sendMore: calculatedStoreTotalSendMore,
                  sizes: eachStore?.sizes?.map((size: any) => {
                    if(size?.size === skuData?.size){
                      return {
                        ...size,
                        sendMore: +sendMore,
                      }
                    }
                    return size;
                  }),
                };
              }
              return eachStore;
            })
          };
        }
        return each;
      }),
    }, prevState?.ccData, {
      type: "skuStoreSendMore",
      value: +sendMore,
      identifier: skuData?.size,
      storeId: storeId,
      ccIdentifier: skuData.cc,
    });
  })

};

const calculateStoreInventoryForCC = (data: any, returnValue: "OH" | "IT" | "OO" | "TTL" = "OH") => {
  return data.reduce((acc: number, each: any) => {
    if(each?.store?.excluded) return acc;
    return acc + (each?.storeInventory?.[returnValue] || 0);
  }, 0);
};

const calculateValueFromStoreList = (data: any, returnValue: "sendMore" | "finalInventory" = "sendMore") => {
  return data.reduce((acc: number, each: any) => {
    if(each?.store?.excluded) return acc;
    return acc + (each?.[returnValue] || 0);
  }, 0);
};

// Update object is very important field here. every calculation is based on it. proceed with caution
const buildReplenishmentState = (processData: any, ccData: any, updateObject?: IUpdateObject) => {
  const ccs =  (processData?.ccs || []).map((cc: any) => {
    const updatedStateData = cc;
    const fulfilment = processData?.fulfilments[cc?.cc || cc];
    const currentCCData = ccData[cc?.cc || cc];
    const ccMinWOH = cc?.minWOH || 0;
    const ccWOH = cc?.woh || 0;

    let dcAllocatableInventoryMap = {};
    let totalAllocatableInventory = 0;

    const dcKeys = Object.keys(currentCCData?.inventories || {});
    for(let t = 0; t < dcKeys.length; t += 1){
      const key = dcKeys[t];
      const value = currentCCData?.inventories[key];
      const dcTotal = Object.values(value).reduce((acc: any, each2: any) => acc + each2 || 0, 0);
      (dcAllocatableInventoryMap as any)[key] = { total: dcTotal, size_breakdown: value };
      totalAllocatableInventory += dcTotal ? (dcTotal as number) : 0;
    }

    let excludedStoreList = [...(cc?.excludedStores || [])];
    // Updates state: manual-replenishment/ccData/CC/stores
    const storesData = currentCCData?.stores?.map((store: any, index: number) => {
      let excluded = false;
      // Decide "excluded" based on the store number if in excluded list
      if(cc?.excludedStores?.indexOf(store?.store_number) > -1){
        excluded = true;
      }
      let each: any = {...store};
      let updatedStateStore = updatedStateData?.stores?.find((s: any) => s?.id === store?.store_number);
      const storeTotalOH:any = Object.values(each?.oh || {})?.reduce((acc: any, sum) => acc + (sum || 0), 0);
      const storeTotalIT:any = Object.values(each?.it || {})?.reduce((acc: any, sum) => acc + (sum || 0), 0);
      const storeTotalOO:any = Object.values(each?.oo || {})?.reduce((acc: any, sum) => acc + (sum || 0), 0);
      const storeTotalInventory = storeTotalOH + storeTotalIT + storeTotalOO;
      const storeLastWeekSale = each?.total?.last_wk_sls || 0;
      const storeLast4WeekSale = each?.is_new_item ? (each?.total?.last_wk_sls || 0) : ((each?.total?.last_4_wk_sls / 4) || 0);
      const storeCurrentWOH = Math.round(storeTotalInventory / storeLast4WeekSale);
      const storeSizeProfiles = each?.size_profiles;
      let storeSendMore = 0;
      // Store Send More = (Minimum WOH * weekly Avg of Last 4 Wks) - Store Total Inventory
      let fallbackSendMore = !storeLast4WeekSale ? 0 : Math.max(((ccMinWOH || 0) * storeLast4WeekSale) - (storeTotalInventory || 0), 0);
      if(!updateObject || ((updateObject && updateObject?.type === "minWOH"))){
        storeSendMore = fallbackSendMore;
      }
      if(updateObject && updateObject?.type === "storeSendMore"){
        storeSendMore = +updatedStateStore?.sendMore;
      }
      if(updateObject && updateObject?.type === "storeExcludedToggle"){
        storeSendMore = +updatedStateStore?.sendMore;
        if(cc?.cc === updateObject?.ccIdentifier && each?.store_number === updateObject?.storeId) {
          excluded = !!updateObject.value;
          if (!!updateObject.value) {
            if (excludedStoreList.indexOf(each?.store_number) === -1) {
              excludedStoreList = [...(excludedStoreList || []), each.store_number];
            }
          } else {
            if (excludedStoreList.indexOf(each?.store_number) > -1) {
              excludedStoreList = excludedStoreList.filter((store: any) => store !== each?.store_number);
            }
          }
        }
      }
      if(updateObject && updateObject?.type === "skuSendMore"){
          if (each?.store_number === updateObject?.storeId) {
            storeSendMore = +updatedStateStore?.sendMore;
          } else {
            storeSendMore = +updatedStateData?.stores?.find((s: any) => s?.id === store?.store_number)?.sendMore;
          }
      }
      if(updateObject && updateObject?.type === "skuStoreSendMore"){
        if(each?.store_number === updateObject?.storeId && cc?.cc === updateObject?.ccIdentifier){
          storeSendMore = updatedStateStore?.sizes?.reduce((acc: any, cur: any) => {
            return acc + (cur?.size === updateObject?.identifier ? +updateObject?.value : cur?.sendMore);
          }, 0);
        }else{
          storeSendMore = +updatedStateData?.stores?.find((s: any) => s?.id === store?.store_number)?.sendMore;
        }
      }
      const storeFinalInventory = storeTotalInventory + Math.ceil(storeSendMore); // Final Inventory = Store Total Inventory + Send More
      const storeTargetWOH = !storeLast4WeekSale ? 0 : (storeTotalInventory + Math.ceil(storeSendMore)) / (storeLast4WeekSale || 1); // Target WOH = (Total Inventory + "Send More") / Weekly Avg of Last 4 Wks

      // if minWOH is null, set sendMores to 0
      // if last4week data is null, WOH, TargetWOH and minWOH doesn't affect send more
      // CC targetWOH = finalInv / last4week sum
      // currentWOH per store = finalInv per store / last4week per store

      return {
        id: each?.store_number,
        store: {
          id: each?.store_number, // @TODO: somewhere in the future might cause a bug
          name: each?.store_name,
          number: each?.store_number,
          status: "LIVE",
          excluded: excluded,
        },
        lastWeekSales: storeLastWeekSale,
        last4WeeksSales: storeLast4WeekSale,
        woh: storeCurrentWOH,
        storeInventory: {
          OH: storeTotalOH,
          IT: storeTotalIT,
          OO: storeTotalOO,
          TTL: storeTotalInventory,
        },
        sendMore: Math.ceil(storeSendMore), // Calculated before return {}
        finalInventory: Math.ceil(storeFinalInventory), // Calculated before return {}
        targetWOH: Math.round(Math.max(ccMinWOH, storeTargetWOH)), // Calculated before return {}
        sizes: (fulfilment?.size_runs || []).map((size: any) => {
          const sizeTotalOH = each?.oh?.[size] || 0;
          const sizeTotalIT = each?.it?.[size] || 0;
          const sizeTotalOO = each?.oo?.[size] || 0;
          const sizeTotalTTL = sizeTotalOH + sizeTotalIT + sizeTotalOO;
          const sizeLast4WkSales = each?.is_new_item ? (each?.last_wk_sls[size] || 0) : ((each?.last_4_wk_sls[size] / 4) || 0);
          const sizeCurrentWOH =  Math.round(sizeTotalTTL / sizeLast4WkSales);
          const sizeAllocatableInventory = currentCCData?.inventories?.[size];
          let sizeSendMore = 0;
          const fallbackSendMore = !sizeLast4WkSales ? 0 : Math.max(((ccMinWOH || 0) * sizeLast4WkSales) - (sizeTotalTTL || 0), 0);
          if (updateObject && updateObject?.type === "skuStoreSendMore") {
            if(size === updateObject?.identifier && each?.store_number === updateObject?.storeId){
              if(cc?.cc === updateObject?.ccIdentifier) {
                sizeSendMore = +updateObject?.value;
              }else{
                sizeSendMore = +(updatedStateStore?.sizes?.find((sizeObj: any) => sizeObj.size === size)?.sendMore);
              }
            }else{
              sizeSendMore = +(updatedStateStore?.sizes?.find((sizeObj: any) => sizeObj.size === size)?.sendMore);
            }
          } else if (updateObject && updateObject?.type === "skuSendMore") {
              if (size === updateObject?.identifier && each?.store_number === updateObject?.storeId) {
                if(cc?.cc === updateObject?.ccIdentifier) {
                  sizeSendMore = +updateObject?.value;
                }else{
                  sizeSendMore = +(updatedStateStore?.sizes?.find((sizeObj: any) => sizeObj.size === size)?.sendMore);
                }
              } else {
                sizeSendMore = +(updatedStateStore?.sizes?.find((sizeObj: any) => sizeObj.size === size)?.sendMore);
              }
          } else if(updateObject && updateObject?.type === "storeSendMore"){
            sizeSendMore = +updatedStateStore?.sizes?.find((sizeObj: any) => sizeObj.size === size)?.sendMore;
          } else if(updateObject && updateObject?.type === "minWOH") {
            const sendMoreDistributionPerSize: any[] | false = distributeSendMoreToSizes(+storeSendMore, updatedStateStore?.sizes);
            const distributedCount = !!sendMoreDistributionPerSize ? sendMoreDistributionPerSize?.find((k) => k?.size === size)?.sendMore : 0;
            sizeSendMore = !!sendMoreDistributionPerSize ? (distributedCount || 0) : fallbackSendMore;
          } else if(updateObject && updateObject?.type === "storeExcludedToggle") {
            sizeSendMore = +updatedStateStore?.sizes?.find((sizeObj: any) => sizeObj.size === size)?.sendMore;
          } else {
            sizeSendMore = fallbackSendMore;
          }
          const sizeFinalInventory = sizeTotalTTL + Math.ceil(sizeSendMore);
          const remainingPercentage = 100 - Math.round((Math.ceil(sizeSendMore) / sizeAllocatableInventory) * 100);

          const sizeTargetWOH = (sizeTotalTTL + Math.ceil(sizeSendMore)) / (sizeLast4WkSales || 1);

          return {
            sku: `${cc?.cc || cc}-${size}`,
            size,
            storeInventory: {
              OH: sizeTotalOH,
              IT: sizeTotalIT,
              OO: sizeTotalOO,
              TTL: sizeTotalTTL,
            },
            last4WeeksSales: sizeLast4WkSales,
            woh: sizeCurrentWOH,
            targetWOH: Math.round(Math.max(ccMinWOH, sizeTargetWOH)),
            totalInventory: sizeTotalTTL,
            sendMore: Math.ceil(sizeSendMore),
            finalInventory: Math.ceil(sizeFinalInventory),
            remainingPercentage: remainingPercentage,
            size_contribution: storeSizeProfiles[size],
          };
        }),
      };
    });

    const ccFinalInventory = calculateValueFromStoreList(storesData, "finalInventory");

    const ccSendMore = calculateValueFromStoreList(storesData, "sendMore");

    const ccTotalSales = storesData?.reduce((acc: any, store: any) => {
      if(store?.store?.excluded) return acc;
      return acc + store?.last4WeeksSales;
    }, 0);

    const returnData: IReplenishmentCC = {
      cc: cc?.cc || cc,
      id: cc?.cc || cc,
      gtm: fulfilment?.gtm,
      description: fulfilment?.description,
      department: fulfilment?.department,
      division: fulfilment?.division,
      klass: fulfilment?.klass,
      woh: ccWOH,
      store_inv: calculateStoreInventoryForCC(storesData, "TTL"),
      allocatable_inv: currentCCData?.inventories_total,
      valid: !!(cc?.cc || cc) && (fulfilment?.size_runs || [])?.length >= 0,
      sizes: (fulfilment?.size_runs || [])?.map((sR: string) => {
        let sizeTotalAllocatableInventory = 0;
        let sizeDCAllocatableInventory = {};

        for(let sT = 0; sT < Object.keys(dcAllocatableInventoryMap).length; sT += 1){
          const key = Object.keys(dcAllocatableInventoryMap)[sT];
          const value = (dcAllocatableInventoryMap as any)[key]?.size_breakdown?.[sR?.toString()];
          sizeTotalAllocatableInventory += value ? value : 0;
          (sizeDCAllocatableInventory as any)[key] = { total: value };
        }

        const remainingPercentage = 100 - Math.round((storesData?.reduce((acc: any, store: any) => {
          return acc + store?.sizes?.find((e: any) => e.size === sR?.toString())?.sendMore
        }, 0) / sizeTotalAllocatableInventory) * 100);

        return {
          sku: `${(cc?.cc || cc)}-${sR?.toString()}`,
          size: sR?.toString(),
          isOverAllocated: remainingPercentage < 0 || !sizeTotalAllocatableInventory,
          allocatableInventory: sizeTotalAllocatableInventory,
          allocatableInventoryDC: sizeDCAllocatableInventory,
          remainingInventoryPercentage: sizeTotalAllocatableInventory === 0 ? 0 : remainingPercentage,
        };
      }),
      minWOH: ccMinWOH,
      storeInventory: {
        OH: calculateStoreInventoryForCC(storesData, "OH"),
        IT: calculateStoreInventoryForCC(storesData, "IT"),
        OO: calculateStoreInventoryForCC(storesData, "OO"),
      },
      total: calculateStoreInventoryForCC(storesData, "TTL"),
      sendMore: Math.ceil(ccSendMore),
      finalInventory: Math.ceil(ccFinalInventory),
      targetWOH: Math.round(Math.max(ccMinWOH, ccFinalInventory / (ccTotalSales || 1))),
      allocatableInventory: totalAllocatableInventory,
      allocatableInventoryDC: dcAllocatableInventoryMap,
      remainingInventoryPercentage: totalAllocatableInventory === 0 ? 0 : 100 - Math.round((Math.ceil(ccSendMore) / totalAllocatableInventory) * 100),
      stores: storesData,
      excludedStores: excludedStoreList || [],
    };
    return returnData;
  });

  return {
    ...processData,
    ccs: ccs,
  }
};

export {
  onMinWOHUpdate,
  onStoreSendMoreUpdate,
  onStoreExcludedToggle,
  onSKUSendMoreUpdate,
  onSKUStoreSendMoreUpdate,
  buildReplenishmentState,
};
