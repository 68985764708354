import {
  useEffect, useRef, useState,
} from "react";
import { useRecoilValue } from "recoil";
import apiClient from "../../api/apiClient";
import endpoints from "../../api/endpoints";
import { userAtom } from "../../global/atoms";
import { showErrorMessage } from "../helpers";
import useRegion from "./useRegion";
import { regions } from "../../configs/constants";

type KeyValuePair = {
  [key: string]: string;
}

type enumReturnType = {
  allocation_status: KeyValuePair;
  department: KeyValuePair,
  division: KeyValuePair,
  tier: KeyValuePair,
  alloc_status: KeyValuePair,
  allocation_channel: KeyValuePair,
  country: KeyValuePair,
  brand: KeyValuePair,
  warehouse_id: KeyValuePair,
  allocation_management_team: KeyValuePair,
  doc_type: KeyValuePair,
  import_type: KeyValuePair,
  upload_status: KeyValuePair,
  data_source: KeyValuePair,
  region: string,
};

const initialData = {
  allocation_status: {},
  department: {},
  division: {},
  tier: {},
  alloc_status: {},
  allocation_channel: {},
  country: {},
  brand: {},
  warehouse_id: {},
  allocation_management_team: {},
  doc_type: {},
  import_type: {},
  upload_status: {},
  data_source: {},
  region: undefined,
};

const useEnums = (): enumReturnType => {
  const [enums, setEnums] = useState<any>(initialData);
  const requestedRef = useRef(false);
  const { data: userData } = useRecoilValue(userAtom);
  const region = useRegion();

  const fetchAndSaveEnums = async (fetchForRegion: keyof typeof regions | undefined) => {
    if (userData?.idToken && !requestedRef.current) {
      requestedRef.current = true;
      try {
        const res: any = await apiClient.get(endpoints.enums);
        const enumsToSave = res?.data?.enums || {};
        enumsToSave.region = fetchForRegion;
        setEnums(enumsToSave);
        localStorage.setItem("enums", JSON.stringify(enumsToSave));
      } catch (error: any) {
        showErrorMessage(error, "Failed to fetch constant values");
      }
    }
  };

  useEffect(() => {
    let storedEnums = localStorage.getItem("enums");
    try {
      if (region.key) {
        storedEnums = storedEnums ? JSON.parse(storedEnums) : {};
        if (Object.keys(storedEnums || {}).length > 0) {
          if (region.key && !!(storedEnums as any).region && (storedEnums as any).region !== region.key) {
            fetchAndSaveEnums(region.key);
          }
          setEnums(storedEnums);
        } else {
          fetchAndSaveEnums(region.key);
        }
      }
    } catch (error: any) {
      showErrorMessage(error, "Error while reading constant values");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region.key]);

  return { ...(enums || initialData) };
};

export default useEnums;
