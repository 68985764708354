import { Empty } from "antd";
import React from "react";
import { Route } from "react-router-dom";

export const NotFoundRoute = () => {
  return (
    <Route path="*">
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: 230,
      }}
      >
        <h1 style={{ fontWeight: 800, fontFamily: "GothamBold, sans-serif" }}>404</h1>
        <Empty
          style={{ fontWeight: 600, fontFamily: "GothamLight, sans-serif" }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Unfortunately, page was not found. :("
        />
      </div>
    </Route>
  );
};
