import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const allocQEditingAtom = atom({
  key: "alloc-q-editing",
  default: { allocQ: {}, allocQValues: [] },
  effects_UNSTABLE: [persistAtom],
});
