import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import { Tabs } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import ReadinessTableBlock from "../../blocks/ReadinessTableBlock";
import DartButton from "../../../../components/DartButton";
import {
  globalFiltersAtom,
  newnessDashboardFiltersAtom,
  selectedCCsAtom,
  selectedNewnessAtom,
  storeTiersAtom,
} from "../../../../global/atoms";
import { ISelectedCC } from "../../../../global/interfaces";
import { getStoreTiersForCCs, StoreSelection } from "../../index";
import DartTable from "../../../../components/DartTable";
import childColumns from "../../blocks/ReadinessTableBlock/childColumns";
import { allocationProcessAtom } from "../../../../global/atoms/allocation-process-atom";
import paths from "../../../../configs/paths";
import sortCCsByDivisionAndDescription from "../../utils/sortCCByDivisionAndDescription";

interface IAllocationReadiness {
 compactHeader?: boolean;
 setCompactHeader?: (isCompact: boolean) => void;
}

type TabType = "ready" | "not_ready" | "completed";

const AllocationReadiness: React.FC<IAllocationReadiness> = ({
  compactHeader,
  setCompactHeader,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const selectedNewnesses = useRecoilValue(selectedNewnessAtom);
  const filters = useRecoilValue(newnessDashboardFiltersAtom);
  const globalFilters = useRecoilValue(globalFiltersAtom);
  const [selectedCCs, setSelectedCCs] = useRecoilState(selectedCCsAtom);
  const [storeTiers, setStoreTiers] = useRecoilState(storeTiersAtom);
  const allocationProcess = useRecoilValue(allocationProcessAtom);
  const [modalVisible, setModalVisible] = useState(false);

  // If we get on Allocation Readiness page without allocation process actually being started (persisted in recoil state) ->
  // -> navigate back to newness dashboard, or if store selection was already done, navigate forward to review cc state
  useEffect(() => {
    if (!selectedNewnesses?.data?.length) {
      history.push(paths.newness);
    }
    if (allocationProcess?.status !== "request" && allocationProcess?.data?.processId && allocationProcess?.data?.processData?.length > 0) {
      history.push(paths.review_ccs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNewnesses, allocationProcess]);

  // Child table renderer function
  const childRenderTable = (ccSizes?: any) => {
    return (
      <div className="expanded-row-child">
        <DartTable
          fixed
          width="100%"
          height={500}
          data={(ccSizes || []).map((v: any, i: number) => {
            return {
              id: i,
              ...v,
            };
          })}
          columns={childColumns({ orderType: selectedNewnesses?.order_type })}
        />
      </div>
    );
  };

  // Get the data according to tab selected (possible values: "Ready", "Not Ready", "Completed"
  const dataGetter = (tab: TabType) => {
    let notReadyData: ISelectedCC[] = [];
    let readyData: ISelectedCC[] = [];
    // let completeData: ISelectedCC[] = [];

    switch (tab) {
      case "not_ready":
        selectedNewnesses?.data?.forEach((newnessMonth: any) => {
          notReadyData = [...notReadyData, ...newnessMonth.data?.notReadyCC?.ccs?.map((cc: any) => {
            return {
              ...cc,
              id: cc.cc,
              childRender: childRenderTable(cc.cc_sizes),
            };
          })];
        });
        return sortCCsByDivisionAndDescription(notReadyData || []);
      // case "completed":
      //   selectedNewnesses?.data?.forEach((newnessMonth: any) => {
      //     completeData = [...completeData, ...newnessMonth.data?.completeCC?.ccs?.map((cc: any) => {
      //       return {
      //         ...cc,
      //         id: cc.cc,
      //         childRender: childRenderTable(cc.cc_sizes),
      //       };
      //     })];
      //   });
      //   return sortCCsByDivisionAndDescription(completeData || []);
      case "ready":
      default:
        selectedNewnesses?.data?.forEach((newnessMonth: any) => {
          readyData = [...readyData, ...newnessMonth.data?.readyCC?.ccs?.map((cc: any) => {
            return {
              ...cc,
              id: cc.cc,
              childRender: childRenderTable(cc.cc_sizes),
            };
          })];
        });
        return sortCCsByDivisionAndDescription(readyData || []);
    }
  };

  // Handler to alter selected CCs state
  const handleSelectedRows = (tab: TabType, rows: ISelectedCC[]) => {
    switch (tab) {
      case "not_ready":
        setSelectedCCs((prev: any) => { return { ...prev, not_ready: rows }; });
        break;
      // case "completed":
      //   setSelectedCCs((prev: any) => { return { ...prev, completed: rows }; });
      //   break;
      case "ready":
      default:
        setSelectedCCs((prev: any) => { return { ...prev, ready: rows }; });
        break;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const readyDataToShow = useMemo(() => dataGetter("ready"), [selectedNewnesses]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const notReadyDataToShow = useMemo(() => dataGetter("not_ready"), [selectedNewnesses]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const completedDataToShow = useMemo(() => dataGetter("completed"), [selectedNewnesses]);

  // Initial setter for selected rows. All ready rows are selected by default. All not-ready rows are unchecked by default
  useEffect(() => {
    handleSelectedRows("ready", readyDataToShow || []);
    handleSelectedRows("not_ready", []);
    // handleSelectedRows("completed", []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyDataToShow]);

  // Function that sets loader and navigates user to Store Selection step
  const navigateToStoreSelection = async () => {
    const ccIds = [...selectedCCs.ready, ...selectedCCs.not_ready].map((ccEntity: any) => ccEntity.cc);
    setLoading(true);
    // Call Service that gets data for Store Selection modal
    await getStoreTiersForCCs(
      storeTiers, setStoreTiers, {
        ...filters, brand: globalFilters?.brand, channel: globalFilters?.channel,
      }, ccIds,
    );
    setLoading(false);
    setModalVisible(true);
  };

  return (
    <div className="allocation-readiness-wrapper">
      <Tabs
        tabBarExtraContent={(
          <DartButton
            loading={loading}
            size="lg"
            onClick={navigateToStoreSelection}
            disabled={(selectedCCs?.ready?.length + selectedCCs?.not_ready?.length <= 0) || allocationProcess?.data?.processId}
            label="PROCEED"
          />
        )}
        defaultActiveKey="1"
      >
        <Tabs.TabPane
          tab={`READY (${readyDataToShow.length || 0})`}
          key="ready"
        >
          <ReadinessTableBlock
            type="ready"
            tableData={readyDataToShow}
            onSelectedRowsChange={(sR) => handleSelectedRows("ready", sR)}
            orderType={selectedNewnesses.order_type}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={`NOT READY (${notReadyDataToShow.length || 0})`}
          key="not-ready"
        >
          <ReadinessTableBlock
            type="not_ready"
            tableData={notReadyDataToShow}
            onSelectedRowsChange={(sR) => handleSelectedRows("not_ready", sR)}
            orderType={selectedNewnesses.order_type}
          />
        </Tabs.TabPane>
        {/* <Tabs.TabPane
          tab={`COMPLETED (${completedDataToShow.length || 0})`}
          key="completed"
        >
          <ReadinessTableBlock
            type="completed"
            tableData={completedDataToShow}
            onSelectedRowsChange={(sR) => handleSelectedRows("completed", sR)}
          />
        </Tabs.TabPane> */}
      </Tabs>
      <StoreSelection
        open={modalVisible}
        setOpen={setModalVisible}
      />
    </div>
  );
};

export default AllocationReadiness;
