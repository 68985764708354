import React from "react";
import { MinusOutlined } from "@ant-design/icons";
import moment from "moment";
import { orderTypes } from "../../../../configs/constants";

/* TODO: Move to interface
Data Structure: {
    allocatable_inventory: 70
    cc: "1131431-OLNT"
    channel: "concept"
    country: "us"
    initial_allocation: 20
    is_1psps_met: 1
    is_min_qty_met: 1
    min_qty: 14
    size_value: "11"
    sku: "1131431-OLNT-11"
    store_avg: 4
    store_nr: 20
  }
*/

const columns = (props: {
  orderType: keyof typeof orderTypes;
}): Record<string, any>[] => [
  {
    key: "sku",
    dataIndex: "sku",
    title: "",
    width: 200,
    flexGrow: 2,
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="readiness-table-cell">
          {rowData.sku || NAComponent}
        </div>
      );
    },
  },
  {
    key: "initial_allocation",
    dataIndex: "initial_allocation",
    title: "Initial allocation qty",
    width: 120,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="readiness-table-cell">
          {rowData?.initial_allocation || rowData?.initial_allocation === 0 ? rowData?.initial_allocation : NAComponent}
        </div>
      );
    },
  },
  {
    key: "allocatable_inventory",
    dataIndex: "allocatable_inventory",
    title: props.orderType === orderTypes.cross_dock ? "Future Inv (units)" : "Allocatable Inv (units)",
    width: 120,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="readiness-table-cell">
          {rowData?.allocatable_inventory || rowData?.allocatable_inventory === 0 ? rowData?.allocatable_inventory : NAComponent}
        </div>
      );
    },
  },
  {
    key: "variance",
    dataIndex: "variance",
    title: "Variance",
    width: 80,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      const variance = rowData.allocatable_inventory - rowData.initial_allocation;
      return (
        <div className={`readiness-table-cell ${variance < 0 ? "bold" : ""}`}>
          {variance || NAComponent}
        </div>
      );
    },
  },
  {
    key: "warehouse_id",
    dataIndex: "warehouse_id",
    title: "Warehouse",
    width: 110,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="readiness-table-cell">
          {rowData?.warehouse_id ? rowData?.warehouse_id : NAComponent}
        </div>
      );
    },
  },
  {
    key: "supply_date",
    dataIndex: "supply_date",
    title: "Next supply date",
    width: 120,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="readiness-table-cell">
          {rowData.supply_by_date || rowData.supply_date ? moment(rowData?.supply_by_date || rowData?.supply_date).format("MM/DD/YYYY") : NAComponent}
        </div>
      );
    },
  },
  {
    key: "min_qty",
    dataIndex: "min_qty",
    title: "Qty for next supply date",
    width: 120,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="readiness-table-cell">
          {rowData.next_supply || NAComponent}
        </div>
      );
    },
  },
];

const NAComponent = <MinusOutlined style={{ fontSize: 14, color: "#C3C5C8" }} />;

export default columns;
