import React, { useMemo, useState } from "react";
import "./index.less";
import {
  useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState,
} from "recoil";
import { useHistory } from "react-router-dom";
import { Modal } from "antd";
import PageTitle from "../../../../../components/PageTitle";
import DartButton from "../../../../../components/DartButton";
import { SizeRunOverview, updateSizeProfile } from "../../index";
import {
  sizeProfileAtom,
  sizeProfileChannelAtom,
  sizeProfileVarianceAtom,
  sizeRunExpandedListAtom,
  sizeRunListAtom,
  sizeRunSelectedListAtom,
} from "../../../../../global/atoms";
import paths from "../../../../../configs/paths";
import { sizeProfileSelector } from "../../../../../global/selectors";
import DartSelect from "../../../../../components/DartSelect";
import DartInput from "../../../../../components/DartInput";
import PageSummary from "../../../../../components/PageSummary";
import useEnums from "../../../../../utils/hooks/useEnums";
import { capitalize, queryGet } from "../../../../../utils/helpers";
import SizeProfileImportWidget from "../../widgets/SizeProfileImportWidget";
import DartPageTitleSearch from "../../../../../components/DartPageTitleSearch";

const SizeProfileReview = () => {
  const { info } = Modal;
  const { allocation_channel } = useEnums();
  const history = useHistory();
  const [variance, setVariance] = useRecoilState(sizeProfileVarianceAtom);
  const sizeRunList = useRecoilValue(sizeRunListAtom);
  const [promptedAboutChannel, setPromptedAboutChannel] = useState(false);
  const resetSizeProfiles = useResetRecoilState(sizeProfileAtom);
  const resetSizeRunSelectedList = useResetRecoilState(sizeRunSelectedListAtom);
  const resetSizeRunExpandedList = useResetRecoilState(sizeRunExpandedListAtom);
  const sizeProfiles = useRecoilValue<any>(sizeProfileSelector);
  const selectedSizeRuns = useRecoilValue<any>(sizeRunSelectedListAtom);
  const sizeProfileChannel = useRecoilValue(sizeProfileChannelAtom);
  const setSizeProfileChannel = useSetRecoilState(sizeProfileChannelAtom);
  const [actionDisabled, setActionDisabled] = useState<boolean | undefined>(false);
  const [loading, setLoading] = useState(false);
  const [sizeProfileImportVisible, setSizeProfileImportVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const useBrowserBack = queryGet("useBrowserBack") === "true";

  const getSummaryContent = useMemo(() => {
    return []; // @TODO: Temporarily disabled
    return [
      {
        name: "Flagged",
        value: "Coming soon",
      },
      {
        name: "New Comp Stores",
        value: "Coming soon",
      },
      {
        name: "Manual Overrides",
        value: "Coming soon",
      },
      {
        name: "Ready to Update",
        value: "Coming soon",
      },
    ];
  }, []);

  const updateSizeProfiles = async () => {
    setLoading(true);
    await updateSizeProfile(
      sizeProfiles, selectedSizeRuns, [resetSizeProfiles, resetSizeRunSelectedList, resetSizeRunExpandedList],
    );
    setLoading(false);
  };

  const validateAndUpdateVariance = (e: any, isBlurring = false) => {
    if (isBlurring && variance?.toString() === "%") {
      setVariance("0%");
      return;
    }
    const valueInteger = parseInt(e.target.value?.replace(/%+/g, ""), 10);
    if (valueInteger > 100) {
      setVariance("100%");
    } else if (valueInteger < 0) {
      setVariance("0%");
    } else if (Number.isNaN(valueInteger)) {
      setVariance("%");
    } else {
      setVariance(`${valueInteger.toString()}%`);
    }
  };

  return (
    <div className="size-profile-review-page">
      <div className="title-wrap">
        <PageTitle
          title="update review"
          rightNode={(
            <DartPageTitleSearch
              placeholder="Search (e.g 'W 5-10')"
              onSearch={(v) => setSearchValue(v || "")}
            />
          )}
          onBack={() => {
            if (useBrowserBack) {
              history.goBack();
            } else {
              history.push(paths.size_profile);
            }
          }}
        />
      </div>
      <div className="container-content">
        <PageSummary
          title="Update Summary"
          summaryContent={getSummaryContent}
          actionButton={(
            <>
              <SizeProfileImportWidget
                visible={sizeProfileImportVisible}
                setVisible={setSizeProfileImportVisible}
              />
              <DartButton
                loading={loading}
                size="lg"
                label="UPDATE SIZE PROFILES"
                onClick={() => (actionDisabled ? null : updateSizeProfiles())}
                disabled={actionDisabled || loading}
              />
            </>
          )}
          // actionButtonSubtext="Last Updated: XX/XX/XX"
          extraContent={(
            <div className="variance-wrapper">
              <DartSelect
                label="Channel"
                selectOptions={[
                  { label: "All", value: "ALL" },
                  ...(Object.values(allocation_channel || {}).map((c) => ({ label: capitalize(c), value: c }))),
                ]}
                placeholder="Select channel"
                canClear={false}
                showSearch={false}
                value={sizeProfileChannel}
                onChange={(value) => {
                  resetSizeProfiles();
                  resetSizeRunSelectedList();
                  resetSizeRunExpandedList();
                  setSizeProfileChannel(value);
                }}
                onOpen={() => {
                  const isTouched = Object.values(sizeProfiles || {}).some((profile: any) => profile?.touched);
                  if (!promptedAboutChannel && isTouched) {
                    info({
                      title: "Change channel ?",
                      content: "Changing channel will reset the size profile list and unsaved changes will be lost.",
                      okText: "Confirm",
                      maskClosable: true,
                      type: "warning",
                      onOk: () => {
                        setPromptedAboutChannel(true);
                      },
                      onCancel: () => {
                        setPromptedAboutChannel(true);
                      },
                    });
                  }
                }}
              />
              <DartInput
                label="Flag update variance: "
                value={variance?.toString()}
                onChange={(e) => validateAndUpdateVariance(e, false)}
                onBlur={(e) => validateAndUpdateVariance(e, true)}
              />
            </div>
          )}
          loading={sizeRunList?.status === "revalidate"}
        />
        <div className="update-review-section">
          {/* <h2>FLAGGED</h2> */}
          {/* <hr /> */}
          <SizeRunOverview
            editable
            varianceAllowed={variance}
            setActionDisabled={setActionDisabled}
            filteredBySearchValue={searchValue}
          />
        </div>
        {/* <div className="update-review-section">
          <h2>NEW COMP STORES</h2>
          <hr />
          <SizeRunOverview
            editable
            type="NEW_COMP_STORES"
            varianceAllowed={variance}
          />
        </div>
        <div className="update-review-section">
          <h2>MANUAL OVERRIDES</h2>
          <hr />
          <SizeRunOverview
            editable
            type="MANUAL_OVERRIDES"
            varianceAllowed={variance}
          />
        </div>
        <div className="update-review-section">
          <h2>READY TO UPDATE</h2>
          <hr />
          <SizeRunOverview
            editable
            type="READY_TO_UPDATE"
            varianceAllowed={variance}
          />
        </div> */}
      </div>
    </div>
  );
};

export default SizeProfileReview;
