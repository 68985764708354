import React from "react";
import { MinusOutlined } from "@ant-design/icons";
import moment from "moment";
import { IReplenishmentHistoryRowData } from "../../../../global/interfaces";

const columns = (props: {
  onDartIdClick: (id: number) => void,
}): Record<string, any>[] => [
  {
    key: "order",
    dataIndex: "order",
    title: "Order No.",
    width: 108,
    flexGrow: 1,
    align: "center",
    className: "replenishment-history-table-cell-wrapper",
    hidden: true,
    cellRenderer: ({ rowData }: { rowData: IReplenishmentHistoryRowData }) => {
      return (
        <div className={`replenishment-history-table-cell ${isDraft(rowData) ? "draft" : ""} ${isInvalid(rowData) ? "invalid" : ""}`}>
          {isDraft(rowData) ? "DRAFT" : valueOrNA(rowData?.order_id)}
        </div>
      );
    },
  },
  {
    key: "dart",
    dataIndex: "dart",
    title: "Dart ID",
    width: 76,
    flexGrow: 1,
    align: "center",
    className: "replenishment-history-table-cell-wrapper",
    cellRenderer: ({ rowData }: { rowData: IReplenishmentHistoryRowData }) => {
      return (
        <div
          className={`replenishment-history-table-cell
          ${isDraft(rowData) ? "draft underlined cursor-pointer" : ""} ${isInvalid(rowData) ? "invalid" : ""}`}
          onClick={() => (rowData?.dart_id && isDraft(rowData) ? props.onDartIdClick(rowData?.dart_id) : false)}
        >
          {valueOrNA(rowData?.dart_id)}
        </div>
      );
    },
  },
  {
    key: "replenishment",
    dataIndex: "replenishment",
    title: "Replenishment",
    width: 150,
    flexGrow: 1,
    align: "center",
    className: "replenishment-history-table-cell-wrapper",
    cellRenderer: ({ rowData }: { rowData: IReplenishmentHistoryRowData }) => {
      return (
        <div className={`replenishment-history-table-cell text-left ${isDraft(rowData) ? "draft" : ""} ${isInvalid(rowData) ? "invalid" : ""}`}>
          <div className="ellipsis">
            {valueOrNA(rowData?.replenishment)}
          </div>
        </div>
      );
    },
  },
  {
    key: "date",
    dataIndex: "date",
    title: "Order Date",
    width: 150,
    flexGrow: 1,
    align: "center",
    className: "replenishment-history-table-cell-wrapper",
    cellRenderer: ({ rowData }: { rowData: IReplenishmentHistoryRowData }) => {
      return (
        <div className={`replenishment-history-table-cell ${isDraft(rowData) ? "draft" : ""} ${isInvalid(rowData) ? "invalid" : ""}`}>
          {rowData?.order_date ? moment(rowData?.order_date).format("MM/DD/YY, HH:mm") : NAComponent}
        </div>
      );
    },
  },
  {
    key: "allocator",
    dataIndex: "allocator",
    title: "Allocator",
    width: 240,
    flexGrow: 1,
    align: "center",
    className: "replenishment-history-table-cell-wrapper",
    cellRenderer: ({ rowData }: { rowData: IReplenishmentHistoryRowData }) => {
      return (
        <div className={`replenishment-history-table-cell vertical-data ${isDraft(rowData) ? "draft" : ""} ${isInvalid(rowData) ? "invalid" : ""}`}>
          {valueOrNA(rowData?.allocator?.toString()?.substring(0, 30))}
        </div>
      );
    },
  },
  {
    key: "ccCount",
    dataIndex: "ccCount",
    title: "Style Colors",
    width: 100,
    flexGrow: 1,
    align: "center",
    className: "replenishment-history-table-cell-wrapper",
    cellRenderer: ({ rowData }: { rowData: IReplenishmentHistoryRowData }) => {
      return (
        <div className={`replenishment-history-table-cell ${isDraft(rowData) ? "draft" : ""} ${isInvalid(rowData) ? "invalid" : ""}`}>
          {valueOrNA(rowData?.metadata?.ccCount || rowData?.ccs?.length)}
        </div>
      );
    },
  },
  {
    key: "stores",
    dataIndex: "stores",
    title: "Stores",
    width: 90,
    flexGrow: 1,
    align: "center",
    className: "replenishment-history-table-cell-wrapper",
    cellRenderer: ({ rowData }: { rowData: IReplenishmentHistoryRowData }) => {
      return (
        <div className={`replenishment-history-table-cell ${isDraft(rowData) ? "draft" : ""} ${isInvalid(rowData) ? "invalid" : ""}`}>
          {valueOrNA(rowData?.metadata?.storesCount)}
        </div>
      );
    },
  },
  {
    key: "sendMore",
    dataIndex: "sendMore",
    title: "Send More",
    width: 90,
    flexGrow: 1,
    align: "center",
    className: "replenishment-history-table-cell-wrapper",
    cellRenderer: ({ rowData }: { rowData: IReplenishmentHistoryRowData }) => {
      return (
        <div className={`replenishment-history-table-cell ${isDraft(rowData) ? "draft" : ""} ${isInvalid(rowData) ? "invalid" : ""}`}>
          {valueOrNA(rowData?.metadata?.totalSendMore)}
        </div>
      );
    },
  },
  {
    key: "sent",
    dataIndex: "sent",
    title: "Sent",
    width: 90,
    flexGrow: 1,
    align: "center",
    className: "replenishment-history-table-cell-wrapper",
    cellRenderer: ({ rowData }: { rowData: IReplenishmentHistoryRowData }) => {
      return (
        <div className={`replenishment-history-table-cell ${isDraft(rowData) ? "draft" : ""} ${isInvalid(rowData) ? "invalid" : ""}`}>
          {valueOrNA(rowData?.sent_count)}
        </div>
      );
    },
  },
  {
    key: "cancelled",
    dataIndex: "cancelled",
    title: "Cancelled (%)",
    width: 120,
    flexGrow: 1,
    align: "center",
    className: "replenishment-history-table-cell-wrapper",
    cellRenderer: ({ rowData }: { rowData: IReplenishmentHistoryRowData }) => {
      return (
        <div className={`replenishment-history-table-cell ${isDraft(rowData) ? "draft" : ""} ${isInvalid(rowData) ? "invalid" : ""}`}>
          {
            rowData?.metadata?.totalSendMore && rowData?.sent_count
              ? `${Math.round(100 - ((rowData?.sent_count / rowData?.metadata?.totalSendMore) * 100))}%`
              : NAComponent
          }
        </div>
      );
    },
  },

];

const NAComponent = <MinusOutlined style={{ fontSize: 14, color: "#C3C5C8" }} />;

const valueOrNA = (value: any, excludeZero?: boolean) => (value || (value === 0 && !excludeZero) ? value : NAComponent);

const isDraft = (rowData: IReplenishmentHistoryRowData) => {
  return rowData?.metadata && Object.keys(rowData?.metadata || {})?.length > 0 && !rowData?.order_date;
};

const isInvalid = (rowData: IReplenishmentHistoryRowData) => {
  return (!rowData?.metadata || Object.keys(rowData?.metadata || {})?.length === 0) && !rowData?.order_date;
};

export default columns;
