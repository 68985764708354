import { Segmented } from "antd";
import React from "react";
import "./index.less";

type ISegmentValue = string | number;

interface IDartSegmentedSelect {
  size?: "small" | "middle" | "large";
  value?: ISegmentValue;
  options: ISegmentValue[];
  onChange?: (value: ISegmentValue) => void;
  disabled?: boolean;
}

const DartSegmentedSelect: React.FC<IDartSegmentedSelect> = ({
  size = "middle",
  value,
  options,
  onChange,
  disabled = false,
}) => {
  return (
    <div className={`dart-segmented-select ${size}`}>
      <Segmented
        size={size}
        options={options}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

export default DartSegmentedSelect;
