/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react";
import { Dropdown } from "antd";
import Icon from "../Icon";
import "./index.less";

interface AVAILABLE_FUNCTIONS {
  handleEditStoreClick?: (storeId?: any) => void;
  handleStoreToggleFromStyle?: (storeId?: any) => void;
  onExcludeToggle?: (excluded: boolean, rowData: any, storeId?: number) => void;
  handleRemoveSisterStoreClick?: any;
  handleCopy?: any,
  handlePaste?: any,
  handleRemovePOAStyle?: any,
  handleModifyPOAStyle?: any,
}

interface IDropdownComponentProps {
  functions: AVAILABLE_FUNCTIONS;
  rowData?: any;
  overlayClassName?: string;
  dotsColor?: string;
}

const TableDropdownMenuComponent: React.FC<IDropdownComponentProps> = ({
  functions,
  rowData,
  overlayClassName,
  dotsColor = "#333E47",
}) => {
  const [open, setOpen] = useState(false);
  const functionCount = Object.values(functions).filter((f) => !!f).length;

  return (
    <div className="table-row-menu">
      <Dropdown
        destroyPopupOnHide
        visible={open}
        onVisibleChange={(visible) => setOpen(visible)}
        trigger={["click"]}
        overlayClassName={overlayClassName}
        overlay={(
          <div
            className="table-row-menu-overlay"
            style={{
              height: 64 + ((functionCount - 1) * 44),
            }}
          >
            <Icon
              name="MenuDots"
              color="#000000"
              size={16}
              onClick={() => setOpen(false)}
            />
            <ul>

              {/* CASE 1: If handleEditStoreClick is passed as a prop, then show the "View Store Details" option */}
              {functions?.handleEditStoreClick && (
                <li onClick={() => {
                  functions?.handleEditStoreClick?.(rowData?.id);
                  setOpen(false);
                }}
                >
                  <Icon
                    name="Edit"
                    size={20}
                    color="#000000"
                  />
                  <span>View Store Details</span>
                </li>
              )}

              {/* CASE 2: If handleStoreToggleFromStyle is passed as a prop, then show "include/exclude store" toggle */}
              {functions?.handleStoreToggleFromStyle && (
                <li onClick={() => {
                  functions?.handleStoreToggleFromStyle?.(rowData?.number);
                  setOpen(false);
                }}
                >
                  {rowData?.selected ? (
                    <>
                      <Icon
                        name="Delete"
                        size={20}
                        color="#000000"
                      />
                      <span>Exclude Store</span>
                    </>
                  ) : (
                    <>
                      <Icon
                        name="Check"
                        size={20}
                        color="#000000"
                      />
                      <span>Include Store</span>
                    </>
                  )}
                </li>
              )}

              {/*  CASE 3: if handleRemoveSisterStoreClick is passed as prop, then show "un-assign sister store" option */}
              {functions?.handleRemoveSisterStoreClick && (
                <li
                  onClick={() => {
                    functions?.handleRemoveSisterStoreClick();
                    setOpen(false);
                  }}
                >
                  <Icon
                    name="Branch"
                    size={20}
                    color="#000000"
                  />
                  <span>Unassign Sister Store</span>
                </li>
              )}

              {/*  CASE 4: if handleCopy is passed as prop, then show row "Copy" option */}
              {functions?.handleCopy && (
                <li
                  className="disabled"
                  onClick={() => {
                    functions?.handleCopy();
                    setOpen(false);
                  }}
                >
                  <Icon
                    name="Copy"
                    size={20}
                    color="#000000"
                  />
                  <span>Copy</span>
                </li>
              )}

              {/*  CASE 5: if handlePaste is passed as prop, then show row "Paste" option */}
              {functions?.handlePaste && (
                <li
                  className="disabled"
                  onClick={() => {
                    functions?.handlePaste();
                    setOpen(false);
                  }}
                >
                  <Icon
                    name="Clip"
                    size={20}
                    color="#000000"
                  />
                  <span>Paste</span>
                </li>
              )}

              {/*  CASE 6: if handleRemovePOAStyle is passed as prop, then show row "Remove Style" option */}
              {functions?.handleRemovePOAStyle && (
                <li
                  onClick={() => {
                    functions?.handleRemovePOAStyle();
                    setOpen(false);
                  }}
                >
                  <Icon
                    name="Delete"
                    size={20}
                    color="#000000"
                  />
                  <span>Remove Style</span>
                </li>
              )}

              {/*  CASE 7: if handleModifyPOAStyle is passed as prop, then show row "Modify Style" option */}
              {functions?.handleModifyPOAStyle && (
                <li
                  onClick={() => {
                    functions?.handleModifyPOAStyle();
                    setOpen(false);
                  }}
                >
                  <Icon
                    name="Edit"
                    size={20}
                    color="#000000"
                  />
                  <span>Modify Style</span>
                </li>
              )}

              {/* CASE 8: If onExcludeToggle is passed as a prop, then show "include/exclude store" toggle (2nd variant) */}
              {functions?.onExcludeToggle && (
                <li onClick={() => {
                  functions?.onExcludeToggle?.(
                    !rowData?.store?.excluded, rowData, rowData?.store?.number,
                  );
                  setOpen(false);
                }}
                >
                  {!rowData?.store?.excluded ? (
                    <>
                      <Icon
                        name="Delete"
                        size={20}
                        color="#000000"
                      />
                      <span>Exclude Store</span>
                    </>
                  ) : (
                    <>
                      <Icon
                        name="Check"
                        size={20}
                        color="#000000"
                      />
                      <span>Include Store</span>
                    </>
                  )}
                </li>
              )}
            </ul>
          </div>
        )}
        placement="bottomLeft"
      >
        <Icon
          name="MenuDots"
          color={dotsColor}
          size={16}
          onClick={() => setOpen(true)}
        />
      </Dropdown>
    </div>
  );
};

export default TableDropdownMenuComponent;
