/* eslint-disable no-param-reassign */
import { selector } from "recoil";
import { poaValuesAtom } from "../atoms";

export const poaValuesSelector = selector({
  key: "poa-values-selector",
  get: ({ get }) => {
    const atomData = get(poaValuesAtom);

    return atomData;
  },
});
