import { message } from "antd";
import apiClient from "../../../api/apiClient";
import endpoints from "../../../api/endpoints";
import {
  only, serializeParams, showErrorMessage,
} from "../../../utils/helpers";
import { buildReplenishmentState } from "./calculations";
import { IReplenishmentCC } from "../../../global/interfaces";
import chunkArray from "../../../utils/helpers/chunkArray";
import { IChannelTypes } from "../../../global/atoms/global-filters-atom";

interface ISearchReplenishmentCCsFilters {
  channel?: string;
  brand?: string;
}

const searchReplenishmentCCs = async (
  ccToSearch: string[], filters: ISearchReplenishmentCCsFilters, setLoadingState: any, chunkSize = 20,
) => {
  try {
    setLoadingState(true);
    if (ccToSearch?.length === 0) {
      message.info("Please provide CCs in whitespace separated format");
    }
    if (filters.brand && filters.channel && ccToSearch?.length > 0) {
      const chunkedCCs = chunkArray(ccToSearch, chunkSize);
      let list: any = {};
      const awaitFor = [];
      for (let i = 0; i < chunkedCCs.length; i += 1) {
        awaitFor.push(apiClient.get(endpoints.replenishmentSearchCC, {
          params:
            {
              ...only(["brand", "channel"], filters),
              ...(chunkedCCs[i] && { cc: chunkedCCs[i] }),
            },
          paramsSerializer: (params) => serializeParams(params),
        }));
      }

      // Await for all responses for all chunks
      const combinedList = await Promise.all(awaitFor).then((responses: any) => {
        for (let e = 0; e < responses.length; e += 1) {
          if (responses[e]?.data) {
            list = { ...list, ...(responses[e]?.data || {}) };
          }
        }
        return list;
      }).catch((error) => {
        showErrorMessage(error, "Failed to search CCs");
        setLoadingState(false);
        return [];
      });
      setLoadingState(false);
      return combinedList;
    }
    message.info("No brand and channel selected");
    setLoadingState(false);
    return [];
  } catch (error: any) {
    setLoadingState(false);
    showErrorMessage(error, "Failed to search CCs");
    return [];
  }
};

const startManualReplenishment = async (
  ccs: string[], name: string, filters: ISearchReplenishmentCCsFilters, setLoadingState: any,
) => {
  try {
    setLoadingState(true);
    if (filters.brand && filters.channel && ccs?.length > 0) {
      const res: any = await apiClient.post(endpoints.replenishment, {
        ccs, name, brand: filters?.brand, channel: filters?.channel,
      });
      setLoadingState(false);
      return res?.data; // { process_id: X }
    }
    setLoadingState(false);
    return {};
  } catch (error: any) {
    setLoadingState(false);
    showErrorMessage(error, "Failed to start replenishment");
    return {};
  }
};

const fetchManualReplenishmentProcess = async (
  processId: number, setLoadingState: any, setManualReplenishmentState: any, channel: IChannelTypes,
) => {
  try {
    setLoadingState(true);
    if (processId && processId > 0) {
      const res: any = await apiClient.get(endpoints.replenishmentProcess.replace(":id", processId?.toString()));
      const draft = res?.data?.draft;
      if (Object.keys(draft || {})?.length > 0) {
        const draftedCCS = draft?.ccs || [];
        const draftedCCData = draft?.ccData || {};
        setLoadingState(false);
        setManualReplenishmentState({
          ...res.data,
          processId,
          name: res?.data?.name,
          ccData: draftedCCData,
          ccs: draftedCCS,
          // DUMMY DATA
          // ccData: { ...draftedCCData, "TEST-123": draftedCCData[Object.keys(draftedCCData)?.[0]] },
          // ccs: [...draftedCCS, ...draftedCCS?.map((e: any) => {
          //   return {
          //     ...e,
          //     cc: "TEST-123",
          //     sendMore: e.sendMore - 400,
          //     finalInventory: e.finalInventory - 1000,
          //     targetWOH: e.targetWOH + 1,
          //     remainingInventoryPercentage: e.remainingInventoryPercentage + 98,
          //   };
          // })],
        });
        return;
      }
      const ccs = res?.data?.ccs;
      const ccData: any = {};
      if (ccs?.length > 0) {
        await Promise.all(ccs.map(async (cc: any) => {
          const data = await fetchReplenishmentCCDetails(cc, channel);
          if (data?.stores?.length > 0) {
            ccData[cc] = data;
            // DUMMY DATA BELOW
            /*            ccData[cc] = { // DUMMY DATA TODO temporary
              ...data,
              stores: data.stores?.map((e: any, storeIndex: number) => {
                const index = storeIndex % 5;
                const modifiedLast4WKSales: any = {};
                const modifiedLastWKSales: any = {};
                const modifiedIT: any = {};
                const modifiedOH: any = {};
                const modifiedOO: any = {};
                const modifiedTTL: any = {};
                for (let i = 0; i < Object.keys(e?.last_4_wk_sls)?.length; i += 1) {
                  modifiedLast4WKSales[Object.keys(e?.last_4_wk_sls)[i]] = Math.round(((i + 1 + index) * 2) / 5);
                  modifiedLastWKSales[Object.keys(e?.last_4_wk_sls)[i]] = Math.round((i + 1 + index) / 5);
                  modifiedOH[Object.keys(e?.last_4_wk_sls)[i]] = Math.round((i + 1 + index) / 5);
                  modifiedIT[Object.keys(e?.last_4_wk_sls)[i]] = Math.round((i + 1 + index) / 5);
                  modifiedOO[Object.keys(e?.last_4_wk_sls)[i]] = Math.round((i + 1 + index) / 5);
                  modifiedTTL[Object.keys(e?.last_4_wk_sls)[i]] = Math.round((3 * (i + 1 + index)) / 5);
                }
                return {
                  ...e,
                  last_4_wk_sls: modifiedLast4WKSales,
                  last_wk_sls: modifiedLastWKSales,
                  oh: modifiedOH,
                  it: modifiedIT,
                  oo: modifiedOO,
                  ttl: modifiedTTL,
                  total: {
                    oh: Object.values(modifiedOH)?.reduce((acc:any, sum) => acc + sum || 0, 0),
                    it: Object.values(modifiedIT)?.reduce((acc:any, sum) => acc + sum || 0, 0),
                    oo: Object.values(modifiedOO)?.reduce((acc:any, sum) => acc + sum || 0, 0),
                    ttl: Object.values(modifiedTTL)?.reduce((acc:any, sum) => acc + sum || 0, 0),
                    last_4_wk_sls: Object.values(modifiedLast4WKSales)?.reduce((acc:any, sum) => acc + sum || 0, 0),
                    last_wk_sls: Object.values(modifiedLastWKSales)?.reduce((acc:any, sum) => acc + sum || 0, 0),
                  },
                };
              }),
            } */
          } else {
            ccData[cc] = { ...data, stores: [] };
          }
        }));
        setLoadingState(false);
        setManualReplenishmentState(buildReplenishmentState({
          ...res?.data,
          processId,
          name: res?.data?.name,
          ccs: res?.data?.ccs,
          ccData,
          // DUMMY DATA
          // ccData: { ...ccData, "TEST-123": ccData[Object.keys(ccData)?.[0]] },
          // ccs: [...res?.data?.ccs, ...res?.data?.ccs?.map((e: any) => { return { ...e, cc: "TEST-123" }; })],
        }, ccData));
      }
      setLoadingState(false);
    }
    setLoadingState(false);
  } catch (error: any) {
    setLoadingState(false);
    setManualReplenishmentState(undefined);
    showErrorMessage(error, "Failed to start replenishment");
  }
};

const fetchReplenishmentCCDetails = async (cc: string, channel: IChannelTypes) => {
  try {
    const res: any = await apiClient.get(endpoints.replenishmentDetailsCC.replace(":id", cc), {
      params: {
        channel,
      },
    });
    return res?.data;
  } catch (error: any) {
    return [];
  }
};

const getReplenishmentHistory = async (
  setHistoryData: any, setHistoryLoading: any, onlyDraftOrApproved?: boolean, filters?: any,
) => {
  try {
    setHistoryLoading(true);
    const res: any = await apiClient.get(endpoints.replenishment);
    const data = res?.data?.items?.reverse()?.filter((item: any) => {
      if (!filters?.brand && !filters?.channel) {
        return true;
      }
      if (filters?.brand && item.brand === filters?.brand && !filters?.channel) {
        return true;
      }
      if (filters?.channel && item.channel === filters?.channel && !filters?.brand) {
        return true;
      }
      if (filters?.brand && filters?.channel && item.brand === filters?.brand && item.channel === filters?.channel) {
        return true;
      }
      return false;
    });

    if (onlyDraftOrApproved) {
      // return only drafts and only the ones that was approved
      setHistoryData(data?.filter((e: any) => {
        return (e.metadata && Object.keys(e?.metadata || {})?.length > 0) || e?.order_date;
      }));
    } else {
      // return all replenishment processes
      setHistoryData(data);
    }
    setHistoryLoading(false);
  } catch (error: any) {
    showErrorMessage(error, "Failed to get replenishment history");
    setHistoryData([]);
    setHistoryLoading(false);
  }
};

const saveReplenishmentAsDraft = async (
  replenishmentData: any, setLoading: any, callback?: () => void, hideMessage?: boolean,
) => {
  try {
    setLoading(true);
    if (replenishmentData?.processId) {
      const totalSendMore = replenishmentData?.ccs?.reduce((acc: any, cur: IReplenishmentCC) => {
        return acc + (cur?.sendMore || 0);
      }, 0);

      const uniqueStores: string[] = [];

      if (replenishmentData?.ccs) {
        for (let i = 0; i < replenishmentData?.ccs?.length; i += 1) {
          for (let o = 0; o < replenishmentData?.ccs[i]?.stores?.length; o += 1) {
            if (!uniqueStores.includes(replenishmentData?.ccs[i]?.stores?.[o]?.store?.number)) {
              uniqueStores.push(replenishmentData?.ccs[i]?.stores?.[o]?.store?.number);
            }
          }
        }
      }

      const draftResponse = await apiClient.put(endpoints.replenishmentProcess?.replace(":id", replenishmentData?.processId),
        {
          draft: {
            ccs: replenishmentData?.ccs,
            ccData: replenishmentData?.ccData,
          },
          metadata: {
            ccCount: replenishmentData?.ccs?.length,
            storesCount: uniqueStores?.length,
            totalSendMore,
          },
        });

      if (draftResponse?.status === 200) {
        if (!hideMessage) {
          message.success("Replenishment saved as draft");
        }
        callback?.();
      } else if (!hideMessage) {
        message.error("Can not save replenishment as draft");
      }
    } else if (!hideMessage) {
      message.error("Can not save replenishment as draft");
    }
    setLoading(false);
  } catch (error: any) {
    if (!hideMessage) {
      message.error("Can not save replenishment as draft");
    }
    setLoading(false);
  }
};

const approveReplenishment = async (
  replenishmentData: any, setLoading: any, callback?: () => void,
) => {
  try {
    setLoading(true);
    if (replenishmentData?.processId) {
      const dataToSend = [];
      let sendMoreCounter = 0;
      const actualSendMore = replenishmentData?.ccs?.reduce((acc: any, cur: any) => {
        return acc + (cur?.sendMore || 0);
      }, 0);
      for (let i = 0; i < replenishmentData?.ccs?.length; i += 1) {
        const ccStores = replenishmentData?.ccs?.[i]?.stores?.filter((e: any) => !e?.store?.excluded);
        for (let s = 0; s < ccStores?.length; s += 1) {
          const storeSizes = ccStores[s].sizes;
          for (let sZ = 0; sZ < storeSizes?.length; sZ += 1) {
            const sizeData = storeSizes?.[sZ];
            dataToSend.push({
              sku: sizeData?.sku || sizeData?.cc,
              store_number: ccStores[s]?.store?.number,
              qty: sizeData?.sendMore || 0,
            });
            sendMoreCounter += sizeData.sendMore || 0;
          }
        }
      }
      if (sendMoreCounter !== actualSendMore) {
        console.error("Total 'send more' and actual sent 'send more' values do not match");
      }
      const approveResponse = await apiClient.post(endpoints.replenishmentProcessApprove?.replace(":id", replenishmentData?.processId),
        { allocation_data: dataToSend });
      if (approveResponse?.status === 200) {
        message.success("Replenishment approved");
        callback?.();
      } else {
        message.error("Error. Could not approve replenishment");
      }
    } else {
      message.error("Error. Could not approve replenishment");
    }
    setLoading(false);
  } catch (error: any) {
    message.error("Error. Could not approve replenishment");
    setLoading(false);
  }
};

export {
  searchReplenishmentCCs,
  startManualReplenishment,
  fetchManualReplenishmentProcess,
  fetchReplenishmentCCDetails,
  getReplenishmentHistory,
  saveReplenishmentAsDraft,
  approveReplenishment,
};
