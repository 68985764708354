import React, { useEffect, useState } from "react";
import "./index.less";
import { Tag } from "antd";
import { ISelectOption } from "../../global/interfaces";

interface IPillSelect {
  value?: string[];
  onChange?: (selected?: string[]) => void;
  selectOptions?: ISelectOption[];
  multiple?: boolean;
  disabled?: boolean;
  showOnlySelected?: boolean;
  selectIfOne?: boolean;
}

const PillSelect: React.FC<IPillSelect> = ({
  value = undefined,
  onChange,
  selectOptions,
  disabled,
  multiple = true,
  showOnlySelected = false,
  selectIfOne = false,
}) => {
  const [init, setInit] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[] | undefined>(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleChange = (selectedValue: string) => {
    if (multiple) {
      if (selected && selected?.indexOf(selectedValue) > -1) {
        const tempSelected = selected.filter((e) => e !== selectedValue);
        setSelected(tempSelected);
      } else {
        setSelected((prev) => [...(prev || []), selectedValue]);
      }
    } else {
      setSelected(selected?.[0].toString() === selectedValue.toString() ? undefined : [selectedValue]);
    }
  };

  useEffect(() => {
    if (selectIfOne && selectOptions?.length === 1) {
      setSelected([selectOptions[0]?.value.toString()]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions, selectIfOne]);

  useEffect(() => {
    if (init) {
      onChange?.(selected);
    } else {
      if (selectIfOne) {
        onChange?.(selected);
      }
      setInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div
      className={`dart-pill-select ${disabled ? "disabled" : ""}`}
    >
      {selectOptions?.filter((sO) => {
        if (showOnlySelected) {
          return selected && selected?.indexOf(sO.value.toString()) > -1;
        }
        return true;
      })?.map((pill) => {
        return (
          <Tag
            key={pill.key || pill.value}
            className={`pill ${
              selected
              && pill.value
              && selected?.indexOf(pill.value.toString()) > -1 ? "selected" : ""
            } ${pill.disabled ? "disabled" : ""}`}
            onClick={() => (!pill.disabled && !disabled && pill.value ? handleChange(pill.value.toString()) : false)}
          >
            {pill.label}
            {pill.count || pill.count === 0 ? (
              <span className="count">
                {`(${pill.count})`}
              </span>
            ) : ""}
          </Tag>
        );
      })}
    </div>
  );
};

export default PillSelect;
