const only = (
  keys: string[], obj: any, isArrayOfObjects?: boolean,
) => {
  if (!isArrayOfObjects) {
    const tempObj: any = {};
    keys.forEach((key) => {
      tempObj[key] = obj[key];
    });
    return tempObj;
  }
  const tempObj: any[] = [];
  for (let o = 0; o < obj.length; o += 1) {
    tempObj[o] = {};
    keys.forEach((key) => {
      tempObj[o][key] = obj[o][key];
    });
  }
  return tempObj;
};

export default only;
