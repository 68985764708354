import React from "react";
import "./index.less";
import { Tooltip } from "antd";
import { IReplenishmentCC } from "../../../../global/interfaces";
import Icon from "../../../../components/Icon";

interface ISelectedStyleColorBlockCollapsible {
  data: IReplenishmentCC;
  theme?: "dark" | "light";
  open?: boolean;
  onTitleClick?: (entity: string) => void;
  onOpenToggle?: (entity: string) => void;
  childRender?: any;
}

const SelectedStyleColorBlockCollapsible:React.FC<ISelectedStyleColorBlockCollapsible> = ({
  data,
  theme = "dark",
  open = false,
  onTitleClick,
  onOpenToggle,
  childRender,
}) => {
  const isValid = data?.valid && ((data.sendMore || data?.sendMore === 0) && data.allocatableInventory) && data.sendMore <= data.allocatableInventory;

  // Formatter to correctly display floats
  const formatter = Intl.NumberFormat("en", { notation: "standard" });

  return (
    <div className="collapse-wrapper">
      <div className={`selected-style-color-block-collapsible ${theme} ${open ? "open" : ""}`}>
        <div className="cc-info">
          <div className="left">
            <div
              className={`top ${onTitleClick ? "underlined" : ""}`}
              onClick={() => onTitleClick?.(data?.cc)}
            >
              <span className="bold">{data.cc}</span>
              {data.gtm && <span>{data.gtm}</span> }
              {data.description && <span>{data.description}</span>}
            </div>
            <div className="bottom">
              <span>{data.division}</span>
              <span>{data.department}</span>
              {data.klass && <span>{data.klass}</span>}
            </div>
          </div>
          <div className="right">
            <div className="min-woh">
              <div className="label">
                Min WOH
              </div>
              <div className="value">
                {formatter.format(data.minWOH || 0)}
              </div>
            </div>
            <div className="vertical-splitter" />
            <div className="storeInv">
              <div className="label margin-0">
                Store Inv
              </div>
              <div className="value">
                {formatter.format((data?.storeInventory?.OH || 0) + (data?.storeInventory?.IT || 0) + (data?.storeInventory?.OO || 0))}
              </div>
            </div>
            <div className="vertical-splitter" />
            <div className="sendMore">
              <div className="label">
                Send More
              </div>
              <div className={`value ${!isValid ? "flagged" : ""}`}>
                {formatter.format(data.sendMore || 0)}
              </div>
            </div>
            <div className="vertical-splitter" />
            <div className="finalInventory">
              <div className="label">
                Final Inv
              </div>
              <div className="value">
                {formatter.format(data.finalInventory || 0)}
              </div>
            </div>
            <div className="vertical-splitter" />
            <div className="targetWOH">
              <div className="label">
                Target WOH
              </div>
              <div className="value">
                {formatter.format(data.targetWOH || 0)}
              </div>
            </div>
            <div className="vertical-splitter" />
            <div className="allocatableInv">
              {
                Object.keys(data.allocatableInventoryDC || {}).length > 2 ? (
                  <>
                    <div className="label">
                      Allocatable Inv
                    </div>
                    <div className="value underlined cursor-pointer">
                      <Tooltip
                        placement="bottom"
                        getTooltipContainer={() => document.body}
                        overlay={(
                          <div>
                            {Object.keys(data.allocatableInventoryDC || {}).map((key) => (
                              <div
                                key={key}
                                style={{ padding: "4px 12px" }}
                              >
                                <b>
                                  {key}
                                  {": "}
                                  {formatter.format(data.allocatableInventoryDC?.[key]?.total || 0)}
                                </b>
                              </div>
                            ))}
                          </div>
                        )}
                      >
                        {formatter.format(data.allocatableInventory || 0)}
                      </Tooltip>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="label margin-0">
                      Allocatable Inv
                    </div>
                    <div className="value multiple">
                      {Object.keys(data.allocatableInventoryDC || {}).map((key) => (
                        <div className="value-item">
                          <span>{key}</span>
                          <span>{formatter.format(data.allocatableInventoryDC?.[key]?.total || 0)}</span>
                        </div>
                      ))}
                    </div>
                  </>
                )
              }

            </div>
            <div className="vertical-splitter" />
            <div className="remainingPercentage">
              <div className="label">
                Remaining (%)
              </div>
              <div className={`value ${!isValid ? "flagged" : ""}`}>
                {formatter.format(data.remainingInventoryPercentage || 0)}
                %
              </div>
            </div>
          </div>
          <div
            className="collapse-toggle"
            onClick={() => onOpenToggle?.(data?.cc)}
          >
            <Icon
              name="DropdownArrow"
              size={18}
              style={{ transform: open ? "rotateZ(180deg)" : "rotateZ(0deg)" }}
              color={theme === "dark" ? "#ffffff" : "#333E47"}
            />
          </div>
        </div>
      </div>
      {open && (
      <div className="collapse-child-render">
        {childRender}
      </div>
      )}
    </div>
  );
};

export default SelectedStyleColorBlockCollapsible;
