import React from "react";
import "./index.less";
import Icon from "../../../../components/Icon";
import DartCheckbox from "../../../../components/DartCheckbox";
import decideDepartmentName from "../../../../utils/helpers/decideDepartmentName";
import { IAllocQ } from "../../../../global/interfaces";
import decideDivisionName from "../../../../utils/helpers/decideDivisionName";

interface IAllocQCollapse {
  allocQ: IAllocQ;
  expanded?: boolean;
  onExpandToggle?: () => void;
  expandedContent?: React.ReactNode | JSX.Element | string;
  checked?: boolean;
  onCheckboxToggle?: () => void;
  loading?: boolean;
}

const AllocQCollapse:React.FC<IAllocQCollapse> = ({
  allocQ,
  expanded = false,
  onExpandToggle,
  expandedContent,
  checked,
  onCheckboxToggle,
  loading,
}) => {
  return (
    <div
      key={`${allocQ?.department}_${allocQ?.division}_${allocQ?.newness_date}`}
      className={`size-run-collapse ${expanded ? "has-expanded-content" : ""}`}
    >
      <div
        className="card-head"
      >
        <div className="left">
          {onCheckboxToggle && (
          <DartCheckbox
            size="sm"
            onChange={() => onCheckboxToggle?.()}
            checked={checked}
            className="checkbox-department"
          />
          )}
          <div className="title">
            {allocQ?.division ? decideDivisionName(allocQ?.division) : "N/A"}
            <span style={{ color: "#C3C5C8" }}> | </span>
            {decideDepartmentName(allocQ.department)}
          </div>
        </div>
        <div
          className="right"
          onClick={() => (expandedContent ? onExpandToggle?.() : false)}
        >
          <span className="content">
            {allocQ.cc_count
              ? `${allocQ?.cc_count > 1
                ? `${allocQ?.cc_count} Style Colors`
                : `${allocQ?.cc_count} Style Color`}`
              : ""}
          </span>
          {expandedContent && (
          <div className="collapse-arrow">
            {expanded ? (
              <Icon
                name="DropdownArrow"
                style={{ transform: "rotate(180deg)" }}
              />
            ) : (
              <Icon
                name="DropdownArrow"
                style={{ transform: "rotate(-90deg)" }}
              />
            )}
          </div>
          )}
        </div>
      </div>
      {expandedContent && (
      <div className={`expand-content ${expanded ? "visible" : ""}`}>
        {expandedContent}
      </div>
      )}
    </div>
  );
};

export default AllocQCollapse;
