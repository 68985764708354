import { message } from "antd";

type ErrorType = string | { message?: string };

export const bypassBackendErrorList = [
  "Internal server error", // When it's 500 and backend sends "Internal Server Error" as message
  "Network Error", // When it's 502 (CORS Missing Allow Origin)
];

const showErrorMessage = (error?: ErrorType, fallback = "Unhandled Error") => {
  if (
    error &&
    error.hasOwnProperty("message") &&
    bypassBackendErrorList.indexOf((error as any)?.message) === -1
  ) {
    return message.error((error as any)?.message);
  }

  if (
    error &&
    typeof error === typeof "string" &&
    bypassBackendErrorList.indexOf(error as string) === -1) {
    return message.error((error as string));
  }

  return message.error(fallback);
};

export default showErrorMessage;
