import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const allocQListAtom = atom({
  key: "alloc-q-list",
  default: {
    key: "", status: "request", data: { all: {}, recent: {} },
  },
  effects_UNSTABLE: [persistAtom],
});
