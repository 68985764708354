import { Badge, Tooltip } from "antd";
import React, { useMemo } from "react";
import { is } from "../../utils/helpers";
import "./index.less";

interface IProgressChunk {
  content?: string | JSX.Element;
  color?: string;
  percent: number;
  actualPercent?: number; // used in calculation of totals. keep it 0 if it doesn't add up to total
}

interface IDartProgress {
  title?: string; // use {{value}} in string to replace with sum of total % out of "max"
  progress: number | IProgressChunk[]
  color?: string;
  min?: number;
  max?: number;
  withFooter?: boolean;
  size?: "sm" | "md" | "lg";
  hideTooltips?: boolean;
  suffix?: string;
}

const DartProgress: React.FC<IDartProgress> = ({
  title,
  progress,
  color,
  min = 0,
  max = 100,
  withFooter,
  size,
  hideTooltips = false,
  suffix = "%",
}) => {
  const decideHeight = useMemo(() => {
    switch (size) {
      case "sm":
        return 15;
      case "md":
        return 20;
      case "lg":
        return 25;
      default:
        return 25;
    }
  }, [size]);

  const decideTotalValue = useMemo(() => {
    if (progress && is.array(progress)) {
      let total = 0;
      (progress as IProgressChunk[]).map((el) => {
        total += el.actualPercent ?? el.percent;
        return el;
      });
      return total;
    }
    return progress;
  }, [progress]);

  return (
    <div
      className="dart-progress"
    >
      {title && <div className="title">{title?.replace("{{value}}", `${decideTotalValue.toString()}${suffix}`)}</div>}
      {typeof progress === typeof [] ? (
        <div className="chunks-wrapper">
          {(progress as IProgressChunk[]).map((e, i) => {
            return (
              <Tooltip
                key={i}
                placement="bottom"
                title={e.content && !hideTooltips
                  ? (e.content.toString())?.replace("{{value}}", `${e.percent.toString()}${suffix}`)
                  : undefined}
              >
                <div
                  className="chunk"
                  style={{
                    backgroundColor: e.color || color || "#19945D",
                    height: decideHeight,
                    width: `calc(100% / (${(max - min) / (e.percent as number)}))`,
                  }}
                />
              </Tooltip>
            );
          })}
        </div>
      ) : (
        <div className="chunks-wrapper">
          <div
            className="chunk"
            style={{
              backgroundColor: color || "#19945D",
              height: decideHeight,
              width: `calc(100% / (${(max - min) / (progress as number)}))`,
            }}
          />
        </div>
      )}
      {withFooter && is.array(progress) ? (
        <div className="footer-wrapper">
          {(progress as IProgressChunk[]).map((e, i) => {
            return (
              <div
                className="footer-item"
                key={i}
              >
                <Badge
                  size="small"
                  color={e.color || color || "#19945D"}
                />
                {e.content && (e.content.toString())?.replace("{{value}}", `${e.percent.toString()}${suffix}`)}
              </div>
            );
          })}
        </div>
      ) : <></>}
    </div>
  );
};

export default DartProgress;
