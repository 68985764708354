import React from "react";
import { Spin } from "antd";
import "./index.less";

interface ISummaryContent {
  flex?: number;
  name: string;
  value: number | string | React.ReactNode;
  valueColor?: string;
  loading?: boolean;
}

interface IPageSummary {
  title?: string;
  summaryContent: ISummaryContent[];
  actionButton?: JSX.Element;
  actionButtonSubtext?: string;
  extraContent?: JSX.Element;
  loading?: boolean;
}

const PageSummary: React.FC<IPageSummary> = ({
  title,
  summaryContent,
  actionButton,
  actionButtonSubtext,
  extraContent,
  loading = false,
}) => {
  return (
    <div className="page-summary-widget">
      {title && (
      <>
        <h2 className="title">
          <span>
            {title}
            {loading && <div className="loader"><Spin /></div>}
          </span>
        </h2>
        <hr />
      </>
      )}
      <div className="page-summary-content-wrapper">
        <div className="left">
          {summaryContent?.map((content) => (
            <PageSummaryContent
              key={content.name}
              name={content.name}
              value={content.value}
              valueColor={content.valueColor}
              flex={content.flex}
              loading={content.loading}
            />
          ))}
        </div>
        <div className="right">
          {actionButton ? (
            <div className="action-button-wrapper">
              <div className="action-button">
                {actionButton}
              </div>
              {actionButtonSubtext ? (
                <span className="action-button-subtext">
                  {actionButtonSubtext}
                </span>
              ) : <></>}
            </div>
          ) : <></>}
        </div>
      </div>
      {extraContent ? (
        <div className="page-summary-extra-content-wrapper">
          {extraContent}
        </div>
      ) : <></>}
    </div>
  );
};

const PageSummaryContent: React.FC<ISummaryContent> = ({
  name,
  value,
  flex,
  valueColor,
  loading = false,
}) => {
  return (
    <div
      className="page-summary-content"
      style={{ flex: flex || 1, maxWidth: flex ? `${flex * 180}px` : "180px" }}
    >
      <div className="name">
        {name}
      </div>
      <div
        className={`value ${typeof value !== "object" && (value as any)?.toString()?.length > 7 ? "long" : ""}`}
        style={valueColor ? { color: valueColor } : {}}
      >
        {loading ? <Spin /> : value}
      </div>
    </div>
  );
};

export default PageSummary;
