import React, { useMemo, useState } from "react";
import "./index.less";
import { useRecoilValue } from "recoil";
import { FlowDocPreviewBlock } from "../../index";
import PageSummary from "../../../../components/PageSummary";
import useEnums from "../../../../utils/hooks/useEnums";
import { sizeRunListAtom } from "../../../../global/atoms";

interface IFlowDocPreview {
  flowDocChannel: string;
  flowDocComments: any[];
  flowDocHeaders: any[];
  flowDocData: any[];
  flowDocErrorCount: number;
  onFieldUpdate: (ccs: string[], fieldName: string, fieldValue: any, error: string) => void;
}

const FlowDocPreview: React.FC<IFlowDocPreview> = ({
  flowDocChannel,
  flowDocComments,
  flowDocHeaders,
  flowDocData,
  flowDocErrorCount,
  onFieldUpdate,
}) => {
  const enums = useEnums();
  const availableSizeRuns = useRecoilValue(sizeRunListAtom);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  const formattedTableData = useMemo(() => {
    if (!flowDocData) {
      return [];
    }
    return flowDocData;
  }, [flowDocData]);

  return (
    <div className="flow-doc-preview-wrapper">
      <div className="top">
        <PageSummary
          title={`Channel: ${flowDocChannel}`}
          summaryContent={[
            {
              name: "Style Colors",
              value: flowDocData?.length,
              flex: 1,
            },
            {
              name: "Guide",
              value: (
                <div className="guide-wrapper">
                  <div>
                    <span
                      className="circle-dot"
                      style={{ backgroundColor: "#19945D" }}
                    />
                    <p>Can be updated</p>
                  </div>
                  <div>
                    <span
                      className="circle-dot"
                      style={{ backgroundColor: "rgb(252, 72, 72)" }}
                    />
                    <p>Will overwrite existing Style Color</p>
                  </div>
                </div>
              ),
              flex: 2,
            },
            {
              name: "Errors in Flow Doc",
              value: flowDocErrorCount,
              valueColor: flowDocErrorCount > 0 ? "rgb(252, 72, 72)" : "black",
              flex: 1,
            },
            ...(selectedColumns.length > 1 ? [{
              name: "Editing in Bulk",
              value: `${selectedColumns.length} Style Colors`,
              valueColor: "#19945D",
              flex: 1,
            }] : []),
          ]}
        />
      </div>
      <div className="bottom">
        <FlowDocPreviewBlock
          enums={enums}
          tableData={formattedTableData}
          onFieldUpdate={onFieldUpdate}
          availableSizeRuns={availableSizeRuns}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
        />
      </div>
    </div>
  );
};

export default FlowDocPreview;
