import React, { useEffect } from "react";
import "./index.less";
import { Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { ReplenishmentStartBlock } from "../../index";
import paths from "../../../../configs/paths";
import { globalFiltersAtom, manualReplenishmentAtom } from "../../../../global/atoms";
import ReplenishmentHistorySection from "../../widgets/ReplenishmentHistorySection";

const ReplenishmentDashboard = () => {
  const history = useHistory();
  const { brand, channel } = useRecoilValue(globalFiltersAtom);
  const resetManualReplenishmentState = useResetRecoilState(manualReplenishmentAtom);

  useEffect(() => {
    document.title = "DART - REPLENISHMENT DASHBOARD";
    resetManualReplenishmentState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleManualReplenishmentStart = () => {
    resetManualReplenishmentState();
    history.push(paths.start_manual_replenishment);
  };

  return (
    <div className="replenishment-dashboard-page">
      <div className="container-content">
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <div className="wrapper ready-to-approve-wrapper">
              <h1>READY TO APPROVE</h1>
            </div>
          </Col>
          <Col span={12}>
            <div className="wrapper manual-replenishment-wrapper">
              <ReplenishmentStartBlock
                title="Start a Manual Replenishment"
                description="Manual style-color replenishment"
                icon="PlusBlock"
                onClick={handleManualReplenishmentStart}
                disabled={!brand || !channel}
                disabledReason="Brand and Channel not selected"
              />
            </div>
          </Col>
          <Col span={12}>
            <div className="wrapper auto-replenishment-wrapper">
              <ReplenishmentStartBlock
                title="Set Up Auto Replenishment"
                description="Assortment and default min/max set up"
                icon="Stars"
                disabled
              />
            </div>
          </Col>
          <Col span={24}>
            <div className="wrapper replenishment-summary-wrapper">
              <h1>SUMMARY</h1>
            </div>
          </Col>
          <Col span={24}>
            <div className="wrapper replenishment-history-wrapper">
              <ReplenishmentHistorySection />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ReplenishmentDashboard;
