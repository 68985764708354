import React, { useEffect, useState } from "react";
import "./index.less";
import { useHistory } from "react-router-dom";
import { Tag } from "antd";
import PageTitle from "../../../../components/PageTitle";
import { AllocationHistory } from "../../index";
import paths from "../../../../configs/paths";
import DartPageTitleSearch from "../../../../components/DartPageTitleSearch";
import { queryGet, removeNullValues } from "../../../../utils/helpers";

const AllocationHistoryLayout = () => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const useBrowserBack = queryGet("useBrowserBack") === "true";
  const storeIds = queryGet("store_ids");
  const cc = queryGet("cc");
  const [queryFilters, setQueryFilters] = useState({});
  const [usingQueryFilters, setUsingQueryFilters] = useState(false);

  useEffect(() => {
    if (cc || storeIds) {
      setUsingQueryFilters(true);
      setQueryFilters(removeNullValues({
        store_ids: storeIds?.length > 0 ? storeIds.split(",") : undefined,
        cc: cc?.length > 0 ? cc : undefined,
      }));
    } else {
      setUsingQueryFilters(false);
    }
  }, [cc, storeIds]);

  return (
    <div className="allocation-history-layout">
      <PageTitle
        title="allocation history"
        icon="Refresh"
        rightNode={(
          <>
            {usingQueryFilters && (
            <Tag
              closable
              onClose={() => {
                window.location.href = paths.newness_allocation_history;
              }}
            >
              Advanced Filters Applied
            </Tag>
            )}
            <DartPageTitleSearch
              placeholder="Search (e.g '123456-BLK')"
              onSearch={(v) => setSearchValue(v || "")}
            />
          </>
        )}
        onBack={() => {
          if (useBrowserBack) {
            history.goBack();
          } else {
            history.push(paths.newness);
          }
        }}
      />
      <div className="container-content">
        <AllocationHistory
          searchValue={searchValue}
          filterBy={queryFilters}
          usingQueryFilters={usingQueryFilters}
        />
      </div>
    </div>
  );
};

export default AllocationHistoryLayout;
