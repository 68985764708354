import React, { useEffect, useState } from "react";
import "./index.less";
import { Form, message } from "antd";
import {
  useRecoilState, useRecoilValue, useSetRecoilState,
} from "recoil";
import { useHistory } from "react-router-dom";
import { createUser, UserPageHeader } from "../../index";
import {
  selectedUserAtom, userAtom, userListAtom,
} from "../../../../global/atoms";
import { IUser } from "../../../../global/interfaces";
import DartButton from "../../../../components/DartButton";
import paths from "../../../../configs/paths";
import UserForm from "../../blocks/UserForm";

interface IUserCreate {
  onBack: () => any;
}

const UserCreate: React.FC<IUserCreate> = ({
  onBack,
}) => {
  const setSelectedUser = useSetRecoilState(selectedUserAtom);
  const authUser = useRecoilValue(userAtom);
  const [userList, setUserList] = useRecoilState(userListAtom);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<IUser>();
  const [userForm] = Form.useForm();

  useEffect(() => {
    setSelectedUser(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    // check forms for errors
    let hasErrors = false;
    try {
      await userForm.validateFields();
    } catch (e) {
      hasErrors = true;
    }
    if (hasErrors) {
      message.error("Please check all fields are correct");
      return;
    }

    const userFormValues = userForm.getFieldsValue();

    // Create size run data object
    const finalData = {
      name: userFormValues.name,
      email: userFormValues.email,
      phone_number: userFormValues.phone_number,
      role: userFormValues.role,
      ...(userFormValues.region ? { region: userFormValues.region } : { region: authUser?.region }), // Only super admin can do that
      job_designation: userFormValues.job_designation,
    };

    setUserData({
      ...userData,
      ...finalData,
    });

    // Create size run request
    await createUser(
      finalData,
      userList,
      setUserList,
      setLoading,
      () => {
        history.push(paths.user_list);
      },
    );
  };

  return (
    <div className="user-create-widget">
      <UserPageHeader
        onBack={onBack}
      />
      <UserForm
        form={userForm}
      />
      <DartButton
        isSubmit
        loading={loading}
        disabled={loading}
        className="user-create-form-action"
        onClick={handleSubmit}
        label="Create"
        size="lg"
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default UserCreate;
