import "./index.less";
import {
  Alert, Form, Input, message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useHistory, useParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { LockOutlined } from "@ant-design/icons";
import DartButton from "../../../../components/DartButton";
import { userAtom } from "../../../../global/atoms";
import DartLogo from "../../../../assets/images/logo.png";
import paths from "../../../../configs/paths";
import Icon from "../../../../components/Icon";

const ForgotPasswordLayout = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const [error, setError] = useState<string | JSX.Element>("");
  const [loading, setLoading] = useState(false);
  const { email } = useParams<{email: string}>();
  const [localEmail, setLocalEmail] = useState(undefined);
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    if (user?.error) { setUser({ ...user, error: undefined }); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.data?.idToken]);

  const onSubmit = async (values: any) => {
    setError("");
    setLoading(true);
    // Check if Email and Code is present
    if (!localEmail) {
      setError("Email is not present. Please try again.");
      setLoading(false);
      return;
    }
    if (!values.code) {
      setError("Code is not present. Please try again.");
      setLoading(false);
      return;
    }
    // Check if passwords match
    if (values.new !== values?.repeat) {
      setError("Passwords don't match. Please try again.");
      setLoading(false);
      return;
    }
    // Check if new password matches criteria
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,64}$/.test(values.new)) {
      setError((
        <ul>
          {values.new?.length < 8 && <li>At least 8 characters</li>}
          {!/^.{1,64}$/.test(values.new) && <li>Maximum of 64 characters</li>}
          {!/\W/.test(values.new) && <li>At least one special character</li>}
          {![...values.new].some((char: string) => /[A-Z]/.test(char)) && <li>At least one capital letter</li>}
          {![...values.new].some((char: string) => /[a-z]/.test(char)) && <li>At least one lowercase letter</li>}
          {![...values.new].some((char: string) => /\d/.test(char)) && <li>At least one numerical character</li>}
        </ul>
      ));
      setLoading(false);
      return;
    }

    try {
      await Auth.forgotPasswordSubmit(
        localEmail, values.code, values.new,
      );
    } catch (e:any) {
      if (e.code === "CodeMismatchException") {
        setError("Code is invalid. Please try again.");
      } else {
        setError(e.message);
      }
      setLoading(false);
      setLocalEmail(undefined);
      return;
    }

    message.success("Password changed successfully");

    history.push(paths.signin);
    setLoading(false);
  };

  const requestResetPasswordCode = async (values: any) => {
    setLoading(true);
    setError("");
    try {
      await Auth.forgotPassword(values.email);
      setLoading(false);
      setError("");
      setLocalEmail(values.email);
    } catch (e:any) {
      setLoading(false);
      setLocalEmail(undefined);
      if (e.code === "UserNotFoundException") {
        setError("User not found. Please check email field.");
      } else if (e.code === "NotAuthorizedException") {
        setError("Can not reset given user's password. Please contact support.");
      } else {
        setError(e.message);
      }
    }
  };

  return (
    <div className="forgot-password-form">
      <div
        className="logo-wrap"
        onClick={() => history.push(paths.signin)}
      >
        <img
          src={DartLogo}
          alt="DART"
        />
      </div>
      <h1 className="forgot-password-header">
        Forgot Password
      </h1>
      <h2 className="forgot-password-subheader">
        {localEmail
          ? "Please enter verification code and new password"
          : "Please enter email you want to reset password for"}
      </h2>
      <div className="inner-wrap">
        <Form
          name="reset_password"
          onFinish={(values) => (localEmail ? onSubmit(values) : requestResetPasswordCode(values))}
          form={form}
          initialValues={{ email }}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required",
              },
              {
                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Email is invalid",
              },
            ]}
          >
            <Input
              disabled={localEmail}
              prefix={<Icon name="Profile" />}
              placeholder="Email"
            />
          </Form.Item>
          {localEmail && (
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: "Verification Code is required",
              },
            ]}
          >
            <Input
              prefix={<Icon name="Link" />}
              placeholder="Verification Code (received in email)"
            />
          </Form.Item>
          )}
          {localEmail && (
          <Form.Item
            name="new"
            rules={[
              {
                required: true,
                message: "New Password is required",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="New Password"
            />
          </Form.Item>
          )}
          {localEmail && (
          <Form.Item
            name="repeat"
            rules={[
              {
                required: true,
                message: "Repeat New Password is required",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Repeat New Password"
            />
          </Form.Item>
          )}
          <Form.Item>
            <DartButton
              type="primary"
              label={localEmail ? "Change Password" : "Request Verification Code"}
              size="md"
              loading={user.status === "request" || loading}
              onClick={() => form.submit()}
            />
          </Form.Item>
          {user.error || error ? (
            <Alert
              className="error-wrapper"
              type="error"
              message={user.error || error}
            />
          ) : ""}
        </Form>
      </div>
    </div>
  );
};

export default ForgotPasswordLayout;
