import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import {
  useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState,
} from "recoil";
import { useHistory } from "react-router-dom";
import { Button, Tooltip } from "antd";
import PageTitle from "../../../../../components/PageTitle";
import {
  selectedStoreAtom,
  sizeProfileAtom,
  sizeProfileEditingAtom,
  sizeProfileVarianceAtom,
  sizeRunListAtom,
  sizeRunSelectedListAtom,
  storeListAtom,
} from "../../../../../global/atoms";
import paths from "../../../../../configs/paths";
import decideDivisionName from "../../../../../utils/helpers/decideDivisionName";
import decideDepartmentName from "../../../../../utils/helpers/decideDepartmentName";
import Icon from "../../../../../components/Icon";
import DartSelect from "../../../../../components/DartSelect";
import DartCheckbox from "../../../../../components/DartCheckbox";
import DartButton from "../../../../../components/DartButton";
import DartTable from "../../../../../components/DartTable";
import EditableCell from "../../../../../components/EditableCell";
import columns from "../../widgets/SizeRunOverview/columns";
import DartPrompt from "../../../../../components/DartPrompt";
import { updateStore } from "../../../../store";
import { getSizeProfile, updateSizeProfile } from "../../services/sizeProfile";
import { sizeRunListSelector } from "../../../../../global/selectors";
import { storeListFiltersAtom } from "../../../../../global/atoms/store-list-filters-atom";
import countryCodeToName from "../../../../../utils/helpers/countryCodeToName";
import useEnums from "../../../../../utils/hooks/useEnums";
import { mergeObjectIntoArray, queryGet } from "../../../../../utils/helpers";
import { getSizeRunList } from "../../services/sizeRun";
import { IChannelTypes } from "../../../../../global/atoms/global-filters-atom";

const SizeProfileEdit = () => {
  const history = useHistory();
  const variance = useRecoilValue(sizeProfileVarianceAtom);
  const sizeRunList = useRecoilValue<any>(sizeRunListSelector);
  const setSizeRunList = useSetRecoilState<any>(sizeRunListAtom);
  const setCheckedList = useSetRecoilState<number[]>(sizeRunSelectedListAtom);
  const [initProfileData, setInitProfileData] = useState<any>({});
  const [sizeProfileEditing, setSizeProfileEditing] = useRecoilState<{sizeRun: any, sizeProfile: any}>(sizeProfileEditingAtom);
  const resetSizeProfileEditing = useResetRecoilState(sizeProfileEditingAtom);
  const [actionDisabled, setActionDisabled] = useState<boolean | undefined>(false);
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [filteredList, setFilteredList] = useState<any>([]);
  const [appliedFilters, setAppliedFilters] = useState<any>({ status: ["PLANNING", "LIVE", "PAUSED"], location: undefined });
  const setSelectedStore = useSetRecoilState<any>(selectedStoreAtom);
  const [sisterStoreDeleteId, setSisterStoreDeleteId] = useState<number | undefined>(undefined);
  const [deleteSSLoading, setDeleteSSLoading] = useState(false);
  const [updatesDone, setUpdatesDone] = useState(0);
  const setSizeProfiles = useSetRecoilState<any>(sizeProfileAtom);
  const [loading, setLoading] = useState<boolean>(false);
  const [storeList, setStoreList] = useRecoilState<any>(storeListAtom);
  const [discardModalVisible, setDiscardModalVisible] = useState(false);
  const resetStoreFilters = useResetRecoilState(storeListFiltersAtom);
  const { country } = useEnums();
  const useBrowserBack = queryGet("useBrowserBack") === "true";
  const [sizeProfilesFetchedByFilters, setSizeProfilesValuesFetchedByFilters] = useState<any>(undefined);
  // initial should be undefined because otherwise it will redirect to size run review
  const [usingQueryFilters, setUsingQueryFilters] = useState<boolean | undefined>(undefined);
  const [filterListFlag, setFilterListFlag] = useState(0);

  const queryFilters = useMemo(() => {
    return {
      size_run_id: queryGet("size_run_id"),
      channel: queryGet("channel"),
      size_run_name: queryGet("size_run_name"),
      storeIds: queryGet("store_ids"),
    };
  }, []);

  useEffect(() => {
    // decide if using query filters
    if (queryFilters?.size_run_id && queryFilters?.storeIds) {
      setUsingQueryFilters(true);
    } else {
      setUsingQueryFilters(false);
    }
  }, [queryFilters]);

  useEffect(() => {
    const isTouched = !!sizeProfileEditing?.sizeProfile?.touched;
    if (isTouched) {
      setUpdatesDone((prev) => prev + 1);
    }
    if (!sizeProfileEditing?.sizeRun?.id && usingQueryFilters === false) {
      if (useBrowserBack) {
        history.goBack();
      } else {
        history.push(paths.size_profile_review);
      }
    } else if (usingQueryFilters === true) {
      if (!queryFilters.size_run_id) {
        if (useBrowserBack) {
          history.goBack();
        } else {
          history.push(paths.size_profile_review);
        }
      } else {
        // if using query filters
        // Get size run list filtered by query filters storeIds
        getSizeRunList(
          sizeRunList, setSizeRunList, queryFilters?.channel as IChannelTypes, true, queryFilters?.storeIds?.split(","),
        );
      }
    } else if (usingQueryFilters !== undefined) {
      checkForFlaggedRows(sizeProfileEditing?.sizeProfile);
      filterListAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usingQueryFilters, queryFilters]);

  useEffect(() => {
    if (sizeRunList?.status === "success" && usingQueryFilters && queryFilters?.size_run_id) {
      // Get size profile for specific size run id
      getSizeProfile(
        sizeProfilesFetchedByFilters,
        setSizeProfilesValuesFetchedByFilters,
        parseInt(queryFilters.size_run_id, 10),
        undefined,
        queryFilters?.channel as IChannelTypes,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usingQueryFilters, queryFilters, sizeRunList]);

  useEffect(() => {
    const fetchedSizeProfile = sizeProfilesFetchedByFilters?.[queryFilters?.size_run_id];
    if (usingQueryFilters && queryFilters?.size_run_id
      && sizeRunList?.status === "success" && fetchedSizeProfile?.status === "success") {
      const selectedSizeRunCategory = sizeRunList?.originalData
        ?.find((sizeRunCat: any) => queryFilters?.size_run_id?.toString() === sizeRunCat.id?.toString());
      const mergedValues = mergeObjectIntoArray(
        sizeRunList?.channelStoreList,
        fetchedSizeProfile?.data,
        "id",
        "store",
        "store_id",
        {
          size_run_id: queryFilters?.size_run_id,
          size_run_all: selectedSizeRunCategory?.size_run_all,
          sizes: [],
          totalContribution: "OFF",
        },
      );
      setSizeProfileEditing({ sizeRun: selectedSizeRunCategory, sizeProfile: { status: "success", data: mergedValues } });
      setInitProfileData({ status: "success", data: mergedValues });
      // Increase flag to filter list after setPoaEditing change because of async behaviour
      setFilterListFlag((prev) => prev + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usingQueryFilters, sizeProfilesFetchedByFilters?.status, queryFilters, sizeRunList?.status]);

  useEffect(() => {
    // if sizeProfileEditing changes because of queryParams, filter list again
    if (filterListFlag > 0) {
      checkForFlaggedRows(sizeProfileEditing?.sizeProfile);
      filterListAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterListFlag]);

  useEffect(() => {
    setInitProfileData(sizeProfileEditing?.sizeProfile);

    return () => {
      setSizeProfileEditing({ sizeRun: {}, sizeProfile: {} });
    };
  }, []);

  const filteredListLength = useMemo(() => {
    return Object.keys(filteredList || []).length;
  }, [filteredList]);

  const sizeProfileEditingLength = useMemo(() => {
    return Object.keys(sizeProfileEditing?.sizeProfile?.data || {})?.length;
  }, [sizeProfileEditing]);

  const onFilterByStatus = (status?: "PLANNING" | "LIVE" | "PAUSED") => {
    if (appliedFilters?.status?.indexOf(status) > -1) {
      setAppliedFilters({ ...appliedFilters, status: appliedFilters?.status?.filter((s: any) => s !== status) });
    } else {
      setAppliedFilters({ ...appliedFilters, status: [...appliedFilters?.status, status] });
    }
  };

  const filterListAction = () => {
    setFiltersExpanded(false);
    setFilteredList(Object.values(sizeProfileEditing?.sizeProfile?.data || {})?.filter((sP: any) => {
      let shouldReturn = true;
      if (queryFilters?.storeIds?.length > 0) {
        if (!queryFilters?.storeIds?.split(",")?.includes(sP?.store?.id?.toString())) {
          shouldReturn = false;
        }
      }
      if (appliedFilters?.location && sP?.store?.country !== appliedFilters?.location) {
        shouldReturn = false;
      }
      if (appliedFilters?.status?.length > 0 && appliedFilters?.status?.length !== 3) {
        if (!appliedFilters?.status?.includes(sP?.store?.status)) {
          shouldReturn = false;
        }
      }
      return shouldReturn;
    })?.map((each: any) => each.store_id));
  };

  const realValue = (value?: number) => {
    if (value === undefined) {
      return 0;
    }
    return Math.round((value + Number.EPSILON) * 100) / 10000;
  };

  const checkForFlaggedRows = (checkData?: any) => {
    // Check if all stores have size profile data summed up to 100, if not disable the button
    if (Object.values(checkData?.data || {})?.some((v: any) => v?.totalContribution !== "OFF" && Math.round(v?.totalContribution / 100) !== 100)) {
      setActionDisabled?.(true);
    }
  };

  const getColumns = useMemo(() => {
    return columns({
      handleEditStoreClick: (storeId: any) => {
        resetStoreFilters();
        setSelectedStore(storeId);
        history.push(`/stores/${storeId}/edit?useBrowserBack=true`);
      },
      handleRemoveSisterStoreClick: (storeId: any) => setSisterStoreDeleteId(storeId),
      editable: true,
      isFrozenLeft: false,
      channelStoreList: sizeRunList.channelStoreList,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variance, sizeRunList.channelStoreList, sizeRunList?.status]);

  // Append Sizes to Size Profile Columns
  const appendDynamicColumns = (cols: any, profiles: any[]) => {
    const newColumns = [...cols];
    const sizeRunAll = (profiles as any)?.[0]?.size_run_all?.split(",");
    const isSingleSize = sizeRunAll?.length === 1;
    for (let i = 0; i < sizeRunAll?.length; i += 1) {
      const columnName = sizeRunAll?.[i];

      newColumns.push({
        key: columnName,
        dataIndex: columnName,
        title: columnName,
        width: isSingleSize ? 500 : 100,
        flexGrow: isSingleSize ? 5 : 1,
        align: "center",
        className: `size-profile-editable-cell ${isSingleSize ? "disable-editing" : ""}`,
        cellRenderer: ({ rowData }: { rowData: any }) => {
          const currentField = rowData?.sizes.find((s: { size: string }) => s.size === columnName);

          const isValidValue = (val: any) => {
            return !(val === "" || val === undefined);
          };

          const isFlagged = isValidValue(currentField?.contribution) && Math.abs(realValue(currentField?.contribution) - realValue(currentField?.updated))
            > parseInt(variance || "0%".replace("%", ""), 10);
          const isUpdated = realValue(currentField?.updated) !== realValue(currentField?.contribution);

          const updateValue = (v: string) => {
            setActionDisabled?.(false);
            setSizeProfileEditing((prev) => {
              // Deep clone the state. Modify matched size for store and size run and keep it saved in local storage
              const cloneState = JSON.parse(JSON.stringify(prev));
              const sizeRunProfileState = cloneState?.sizeProfile;
              const clonedList = sizeRunProfileState?.data?.[rowData?.store_id];
              let matchedSizeObj = clonedList?.sizes?.find((sizeData: any) => sizeData.size === columnName);
              sizeRunProfileState.touched = true; // for prompt

              // Creating new entry
              if (!clonedList) {
                sizeRunProfileState.data[rowData?.store_id] = rowData;
                matchedSizeObj = rowData?.sizes?.find((sizeData: any) => sizeData.size === columnName);
              }

              if (!matchedSizeObj) {
                sizeRunProfileState.data[rowData?.store_id]?.sizes?.push({
                  id: null,
                  size: columnName,
                  contribution: "",
                  updated: v !== "" ? +v * 100 : "",
                });

                // Sum of contribution % for all sizes
                sizeRunProfileState.data[rowData?.store_id].totalContribution = sizeRunProfileState.data[rowData?.store_id]?.sizes
                  ?.reduce((acc: number, sizeData: any) => acc + (sizeData?.updated || 0), 0);

                if (Math.round(sizeRunProfileState.data[rowData?.store_id]?.totalContribution / 100) !== 100) {
                  setActionDisabled?.(true);
                }
              } else {
                matchedSizeObj.updated = v !== "" ? +v * 100 : "";
                const shouldRemoveContrib = clonedList?.sizes?.every((s: any) => s.updated === "");
                // Sum of contribution % for all sizes
                clonedList.totalContribution = shouldRemoveContrib
                  ? "OFF"
                  : clonedList?.sizes?.reduce((acc: number, sizeData: any) => acc + (sizeData.updated || 0), 0);

                if (clonedList?.totalContribution !== "OFF"
                  && Math.round(clonedList?.totalContribution / 100) !== 100
                  && !shouldRemoveContrib) {
                  setActionDisabled?.(true);
                }
              }

              const valuesToCheck: any = Object.values(sizeRunProfileState?.data);
              for (let cl = 0; cl < valuesToCheck?.length; cl += 1) {
                if (Math.round(valuesToCheck?.[cl]?.totalContribution / 100) !== 100 && valuesToCheck?.[cl]?.totalContribution !== "OFF") {
                  setActionDisabled?.(true);
                }
              }

              return cloneState;
            });
            setUpdatesDone((prev) => prev + 1);
          };

          const decideCellDataValue = () => {
            if (!isValidValue(currentField?.updated) && !isValidValue(currentField?.contribution)) return "";
            if (!isValidValue(currentField?.contribution) && isValidValue(currentField?.updated)) return realValue(currentField?.updated);
            return realValue(isUpdated ? currentField?.updated : currentField?.contribution);
          };

          return (
            <div className={`size-run-overview-table-cell ${isFlagged ? "flagged" : ""} ${isUpdated ? "updated" : ""}`}>
              <EditableCell
                editable
                cellData={decideCellDataValue()}
                hasButtons={false}
                allowSameValueSubmit
                onOk={updateValue}
                viewModeTooltip={isUpdated ? `Initial Value: ${realValue(currentField?.contribution) || "N/A "}%` : undefined}
                valueSuffix={!isValidValue(currentField?.updated) ? "" : "%"}
                min={0}
                max={100}
                cancelOnBlur
                allowEmpty={!isValidValue(currentField?.contribution)}
              />
            </div>
          );
        },
      });
    }

    return newColumns;
  };

  const handleRemoveSisterStore = async () => {
    setDeleteSSLoading(true);
    await updateStore(
      { id: sisterStoreDeleteId, sister_store_id: null }, storeList, setStoreList,
    );
    setDeleteSSLoading(false);
    setSisterStoreDeleteId(undefined);
  };

  const sizeProfileDataValues = useMemo(() => {
    return Object.values(sizeProfileEditing?.sizeProfile?.data || {})?.filter((dt: any) => {
      return filteredList?.find((fL: any) => fL === dt.store_id);
    })?.sort((a: any, b: any) => {
      if (a.store?.name > b.store?.name) return 1;
      if (b.store?.name > a.store?.name) return -1;
      return 0;
    });
  }, [sizeProfileEditing, filteredList]);

  const clearSizeProfileEditingFromList = (sizeRunId: number) => {
    setSizeProfiles((prev: any) => {
      const updatedSizeProfiles = JSON.parse(JSON.stringify(prev));
      delete updatedSizeProfiles[sizeRunId];
      return updatedSizeProfiles;
    });
    updateCheckedList(sizeRunId, true);
  };

  const handleSaveSizeProfileChanges = async () => {
    setLoading(true);
    const sizeRunId = sizeProfileEditing?.sizeRun?.id;
    const sizeProfileToSend = sizeProfileEditing?.sizeProfile;
    await updateSizeProfile(
      [sizeProfileToSend], [sizeRunId], [resetSizeProfileEditing, () => clearSizeProfileEditingFromList(sizeRunId)],
    );
    setLoading(false);
    setTimeout(() => {
      if (useBrowserBack) {
        history.goBack();
      } else {
        history.push(paths.size_profile_review);
      }
    }, 300);
  };

  const updateCheckedList = (sizeRunId: number, isRemove?: boolean) => {
    if (isRemove) {
      setCheckedList((prev) => prev.filter((item) => item !== sizeRunId));
    } else {
      setCheckedList((prev: any) => {
        const newList = [...prev];
        if (prev.indexOf(sizeRunId) === -1) {
          newList.push(sizeRunId);
        }
        return newList;
      });
    }
  };

  const handleDiscardSizeProfileChanges = () => {
    setSizeProfileEditing({ sizeRun: sizeProfileEditing?.sizeRun, sizeProfile: initProfileData });
    setDiscardModalVisible(false);
    setUpdatesDone(0);
  };

  const decideTooltipValue = useMemo(() => {
    let sizePart = "";
    if (sizeProfileEditing?.sizeRun?.size_category === "WHOLE_RANGE") sizePart = "Whole, ";
    if (sizeProfileEditing?.sizeRun?.size_category === "HALF_RANGE") sizePart = "Half, ";
    return sizePart + sizeProfileEditing.sizeRun.size_run_min !== sizeProfileEditing.sizeRun.size_run_max
      ? `${sizeProfileEditing.sizeRun.size_run_min} - ${sizeProfileEditing.sizeRun.size_run_max}` : "One Size";
  }, [sizeProfileEditing?.sizeRun]);

  const isLoading = useMemo(() => {
    return sizeRunList?.status === "request" || sizeRunList?.status === "revalidate" || sizeProfilesFetchedByFilters?.status === "request";
  }, [sizeRunList, sizeProfilesFetchedByFilters]);

  const pageTitle = useMemo(() => {
    return isLoading ? "Loading..."
      : `${sizeProfileEditing.sizeRun.department
        ? decideDivisionName(sizeProfileEditing.sizeRun.division) : ""} ${sizeProfileEditing.sizeRun.department
        ? decideDepartmentName(sizeProfileEditing.sizeRun.department) : decideTooltipValue}`;
  }, [isLoading, sizeProfileEditing?.sizeRun, decideTooltipValue]);

  return (
    <div className="size-profile-edit-page">
      <div className="title-wrap">
        <PageTitle
          title={pageTitle}
          tabTitle={`S/P: ${isLoading ? "Loading ..." : queryFilters?.size_run_name || sizeProfileEditing.sizeRun.name}`}
          rightNode={(
            <div>
              <Tooltip
                overlay={isLoading ? false : decideTooltipValue}
                placement="bottom"
              >
                <div className="size-indicator">
                  <Icon name="Info" />
                  {isLoading ? "Loading ..." : queryFilters?.size_run_name || sizeProfileEditing.sizeRun.name}
                </div>
              </Tooltip>
            </div>
          )}
          onBack={() => {
            if (useBrowserBack) {
              history.goBack();
            } else {
              history.push(paths.size_profile_review);
            }
          }}
        />
      </div>
      <div className={`size-profile-filters ${filtersExpanded ? "open" : ""}`}>
        <div className="container-content">
          <div className="top">
            <div className="left">
              <Button
                type="ghost"
                onClick={() => setFiltersExpanded(!filtersExpanded)}
              >
                <Icon
                  name="Filters"
                  size={18}
                  color="#333E47"
                />
                Filters
              </Button>
              <div className="vertical-splitter" />
              <div className="filtered-store-counter">
                {`${filteredListLength} stores${filteredListLength !== sizeProfileEditingLength
                  ? ` (${sizeProfileEditingLength - filteredListLength} hidden)`
                  : ""}
             `}
              </div>
            </div>
            <div className="right">
              {filtersExpanded ? (
                <Button
                  className="close-button"
                  onClick={() => setFiltersExpanded(false)}
                >
                  <Icon
                    name="Close"
                    color="#000000"
                    size={14}
                  />
                </Button>
              ) : (
                <>
                  {usingQueryFilters ? (
                    <Tooltip
                      overlay={(
                        <div>
                          Size Run ID:
                          {" "}
                          {queryFilters?.size_run_id}
                          <br />
                          Size Run Name:
                          {" "}
                          {queryFilters?.size_run_name}
                          <br />
                          Channel:
                          {" "}
                          {queryFilters?.channel}
                          <br />
                          Stores Included:
                          {" "}
                          {queryFilters?.storeIds?.split(",")?.length || 0}
                        </div>
                      )}
                      placement="left"
                      trigger={["click"]}
                    >
                      <DartButton
                        icon="Filters"
                        label="Advanced Filters Applied"
                        type="primary"
                        style={{ marginRight: 10 }}
                      />
                    </Tooltip>
                  ) : <></>}
                  {updatesDone > 0 && (
                  <DartButton
                    className="discard-changes-button"
                    onClick={() => setDiscardModalVisible(true)}
                    label="Discard Changes"
                  />
                  )}
                  <DartButton
                    disabled={actionDisabled || updatesDone === 0 || loading}
                    loading={loading}
                    onClick={handleSaveSizeProfileChanges}
                    label="Save Changes"
                  />
                </>
              )}
            </div>
          </div>
          <div className="bottom">
            <div className="filter-input-wrapper">
              <DartSelect
                label="Location"
                placeholder="All"
                selectOptions={Object.values(country).map((item) => { return { label: countryCodeToName(item), value: item }; })}
                onChange={(val) => {
                  setAppliedFilters({ ...appliedFilters, location: val });
                }}
                canClear
                showSearch={false}
              />
              <div className="status-selector">
                <span className="label">Store Status</span>
                <div className="checkbox-group-wrap">
                  <DartCheckbox
                    onChange={() => onFilterByStatus("PLANNING")}
                    size="md"
                    checked={appliedFilters?.status?.indexOf("PLANNING") > -1}
                    label="Planning"
                  />
                  <DartCheckbox
                    onChange={() => onFilterByStatus("LIVE")}
                    size="md"
                    checked={appliedFilters?.status?.indexOf("LIVE") > -1}
                    label="Live"
                  />
                  <DartCheckbox
                    onChange={() => onFilterByStatus("PAUSED")}
                    size="md"
                    checked={appliedFilters?.status?.indexOf("PAUSED") > -1}
                    label="Paused"
                  />
                </div>
              </div>
            </div>
            <hr className="filters-splitter" />
            <DartButton
              size="lg"
              label="APPLY FILTERS"
              onClick={filterListAction}
              className="apply-filters-button"
            />
          </div>
        </div>
      </div>
      <div className={`size-profile-edit-table ${filtersExpanded ? "condense" : ""}`}>
        <DartPrompt
          title="Unassign Sister Store?"
          content={(
            <div>This store will no longer receive new size profile updates from it’s sister store.</div>
          )}
          okText="Unassign Sister Store"
          cancelText="Cancel"
          visible={!!sisterStoreDeleteId}
          okButtonProps={{ loading: storeList.status === "request" || deleteSSLoading }}
          onCancel={() => setSisterStoreDeleteId(undefined)}
          onOk={() => handleRemoveSisterStore()}
        />
        <DartPrompt
          title="Discard Existing Changes?"
          content={(
            <div>Changes since you got on this page will be reverted to original</div>
          )}
          okText="Confirm"
          cancelText="Cancel"
          visible={discardModalVisible}
          onCancel={() => setDiscardModalVisible(false)}
          onOk={() => handleDiscardSizeProfileChanges()}
        />
        <DartTable
          key={`${filterListFlag?.toString()}_key`}
          loading={isLoading || storeList.status === "request"}
          tableStyle="dark"
          height={filtersExpanded ? "calc(100vh - 496px)" : "calc(100vh - 250px)"}
          rowKey="id"
          width="100%"
          data={sizeProfileDataValues || []}
          checkboxSize="sm"
          columns={appendDynamicColumns(getColumns, sizeProfileDataValues)}
          fixed
          emptyRenderer={() => <div style={{ padding: "48px calc(50% - 129px)" }}>Size Profile with selected filters is empty</div>}
        />
      </div>
    </div>
  );
};

export default SizeProfileEdit;
