import React, { useMemo, useState } from "react";
import "./index.less";
import { useHistory } from "react-router-dom";
import {
  useRecoilState, useRecoilValue, useResetRecoilState,
} from "recoil";
import PageTitle from "../../../../../components/PageTitle";
import DartButton from "../../../../../components/DartButton";
import DartInput from "../../../../../components/DartInput";
import { SizeRunOverview } from "../../index";
import paths from "../../../../../configs/paths";
import {
  sizeProfileAtom,
  sizeProfileChannelAtom,
  sizeProfileVarianceAtom,
  sizeRunExpandedListAtom,
  sizeRunListAtom,
  sizeRunSelectedListAtom,
} from "../../../../../global/atoms";
import DartSelect from "../../../../../components/DartSelect";
import useEnums from "../../../../../utils/hooks/useEnums";
import PageSummary from "../../../../../components/PageSummary";
import { capitalize } from "../../../../../utils/helpers";
import DartPageTitleSearch from "../../../../../components/DartPageTitleSearch";

const SizeProfileDashboard = () => {
  const { allocation_channel } = useEnums();
  const history = useHistory();
  const sizeRunList = useRecoilValue(sizeRunListAtom);
  const resetSizeProfileList = useResetRecoilState(sizeProfileAtom);
  const resetSizeRunSelectedList = useResetRecoilState(sizeRunSelectedListAtom);
  const resetSizeRunExpandedList = useResetRecoilState(sizeRunExpandedListAtom);
  const [variance, setVariance] = useRecoilState(sizeProfileVarianceAtom);
  const [sizeProfileChannel, setSizeProfileChannel] = useRecoilState(sizeProfileChannelAtom);
  const [searchValue, setSearchValue] = useState<string | undefined>();

  const getSummaryContent = useMemo(() => {
    return []; // @TODO: Temporarily disabled
    return [
      {
        name: "Updates Pending",
        value: "Coming soon",
        valueColor: "#E87324",
        flex: 2,
      },
      {
        name: "Flagged",
        value: "Coming soon",
      },
      {
        name: "New Comp Stores",
        value: "Coming soon",
      },
    ];
  }, []);

  const validateAndUpdateVariance = (e: any, isBlurring = false) => {
    if (isBlurring && variance?.toString() === "%") {
      setVariance("0%");
      return;
    }
    const valueInteger = parseInt(e.target.value?.replace(/%+/g, ""), 10);
    if (valueInteger > 100) {
      setVariance("100%");
    } else if (valueInteger < 0) {
      setVariance("0%");
    } else if (Number.isNaN(valueInteger)) {
      setVariance("%");
    } else {
      setVariance(`${valueInteger.toString()}%`);
    }
  };

  return (
    <div className="size-profile-dashboard-page">
      <PageTitle
        title="size management"
        rightNode={(
          <DartPageTitleSearch
            placeholder="Search (e.g 'W 5-10')"
            onSearch={(v) => setSearchValue(v || "")}
          />
        )}
      />
      <div className="container-content">
        <PageSummary
          title="Size Profile Summary"
          summaryContent={getSummaryContent}
          actionButton={(
            <>
              <DartButton
                size="md"
                type="ghost"
                label="New Size Run"
                icon="Plus"
                onClick={() => history.push(paths.new_size_run)}
              />
              <DartButton
                size="lg"
                label="Review"
                onClick={() => history.push(paths.size_profile_review)}
              />
            </>
          )}
          // actionButtonSubtext="Last Updated: XX/XX/XX"
          extraContent={(
            <div className="variance-wrapper">
              <DartSelect
                label="Channel"
                selectOptions={[
                  { label: "All", value: "ALL" },
                  ...(Object.values(allocation_channel || {}).map((c) => ({ label: capitalize(c), value: c }))),
                ]}
                placeholder="Select channel"
                canClear={false}
                showSearch={false}
                value={sizeProfileChannel}
                onChange={(value) => {
                  resetSizeProfileList();
                  resetSizeRunSelectedList();
                  resetSizeRunExpandedList();
                  setSizeProfileChannel(value);
                }}
              />
              <DartInput
                label="Flag update variance: "
                value={variance?.toString()}
                onChange={(e) => validateAndUpdateVariance(e, false)}
                onBlur={(e) => validateAndUpdateVariance(e, true)}
              />
            </div>
          )}
          loading={sizeRunList?.status === "revalidate"}
        />
        <SizeRunOverview
          editable={false}
          varianceAllowed={variance}
          filteredBySearchValue={searchValue}
        />
      </div>
    </div>
  );
};

export default SizeProfileDashboard;
