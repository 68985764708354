import React from "react";
import "./index.less";
import { Col, Skeleton } from "antd";
import moment from "moment";
import Icon from "../../../../components/Icon";

export type AllocQCardType = "full" | "half" | "third" | "third-condensed" | "third-basic" | "quarter";

interface IAllocQCard {
  date?: string;
  onDetailsClick?: (date?: string) => void;
  initialAllocation?: number;
  styleColors?: number
  lastUpdated?: string;
  variation?: AllocQCardType;
  loading?: boolean;
}

const AllocQCard: React.FC<IAllocQCard> = ({
  date,
  onDetailsClick,
  initialAllocation,
  styleColors,
  lastUpdated,
  variation = "full",
  loading,
}) => {
  // Card variates based on prop "variation". Switch function to decide what is the "span" of current card component
  const variationToColumnSpan = (cardVariation: AllocQCardType) => {
    switch (cardVariation) {
      case "full":
        return 24;
      case "half":
        return 12;
      case "third":
      case "third-condensed":
      case "third-basic":
        return 8;
      case "quarter":
        return 6;
      default:
        return 24;
    }
  };

  // Responsive version of "variationToColumnSpan" which also uses "variation" prop to decide responsive span
  const responsiveVariation = (cardVariation: AllocQCardType) => {
    switch (cardVariation) {
      case "half": return "full";
      case "third":
      case "third-condensed":
      case "third-basic":
        return "half";
      case "quarter":
        return "third";
      default: return "full";
    }
  };

  // Formatter to correctly display floats
  const formatter = Intl.NumberFormat("en", { notation: "standard" });

  return (
    <Col
      span={24}
      sm={variationToColumnSpan(responsiveVariation(variation))}
      md={variationToColumnSpan(responsiveVariation(variation))}
      lg={variationToColumnSpan(variation)}
    >
      {loading ? (
        <Skeleton.Avatar
          active
          shape="square"
          className={`alloc-q-card ${variation}`}
        />
      ) : (
        <div className={`alloc-q-card ${variation}`}>
          <div
            className="card-header"
            onClick={() => onDetailsClick?.(date)}
          >
            <div className="date">{moment(date).format("MMMM YYYY")}</div>
            <div className="updated-date">
              Last updated:
              {" "}
              {lastUpdated}
            </div>
            <div className="details-button">
              <Icon
                name="DropdownArrow"
                style={{ rotate: "-90deg" }}
              />
            </div>
          </div>
          <hr />
          <div className="card-body">
            <div className="totals">
              <div className="initial-allocation">
                <div className="value">{initialAllocation || initialAllocation === 0 ? formatter.format(initialAllocation) : "N/A"}</div>
                <div className="label">Initial Allocation</div>
              </div>
              <div className="style-colors">
                <div className="value">{styleColors ? formatter.format(styleColors) : "N/A"}</div>
                <div className="label">Style Colors</div>
              </div>
            </div>
          </div>
        </div>

      )}
    </Col>
  );
};

export default AllocQCard;
