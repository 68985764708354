import moment from "moment";
import React, { useMemo, useState } from "react";
import "./index.less";
import {
  Col, message, Row, Tag, Tooltip,
} from "antd";
import Icon from "../../../../components/Icon";
import { xTimeAgo } from "../../../../utils/helpers";
import { IBrandTypes, IChannelTypes } from "../../../../global/atoms/global-filters-atom";

export interface IAllocationHistoryListItem {
  id?: number;
  country?: "US" | "CA";
  brand?: IBrandTypes;
  channel?: IChannelTypes;
  warehouse_id?: string;
  cc_and_store?: { cc: string, store_id_list: string[] }[]
  active_upload_ids?: any;
  status?: "SAVED" | "SENT" | "APPROVED" | "FAILED";
  user_id?: string;
  user_email?: string;
  user_name?: string;
  created_at?: string;
  updated_at?: string;
  comment?: string;
  shipping_date?: null | string;
}

interface IAllocationHistoryItemGroupCollapse {
  warehouses?: any[];
  author?: string;
  date?: string;
  ccCount?: number;
  list: IAllocationHistoryListItem[];
  onListItemSelect?: (allocationProcess: IAllocationHistoryListItem) => void;
}

interface IAllocationHistoryListBlock {
  list?: { [key: string]: IAllocationHistoryListItem[] };
  onListItemSelect?: (allocationProcess: IAllocationHistoryListItem) => void;
  searchValue?: string;
  timeGap?: string;
}

const AllocationHistoryListBlock: React.FC<IAllocationHistoryListBlock> = ({
  list: groupedList,
  onListItemSelect,
  searchValue,
  timeGap,
}) => {
  return (
    <div className="allocation-history-list-block">
      {Object.values(groupedList || {})?.length > 0 ? (
        <Row gutter={[24, 24]}>
          {Object.values(groupedList || {})?.map((historyGroup, gIndex) => {
            const warehouseIds = historyGroup?.map((item) => item?.warehouse_id) || [];
            const ccTotalCount = historyGroup?.reduce((acc, item) => {
              const ccCount = Object.values(item?.cc_and_store || {})?.length;
              return acc + ccCount;
            }, 0);
            let lastUpdate = historyGroup[0]?.updated_at || historyGroup[0]?.created_at;
            for (let i = 1; i < historyGroup.length; i += 1) {
              if (moment(historyGroup[i]?.updated_at || historyGroup[i]?.created_at).isAfter(lastUpdate)) {
                lastUpdate = historyGroup[i]?.updated_at || historyGroup[i]?.created_at;
              }
            }
            return (
              <AllocationHistoryItemGroupCollapse
                key={`${historyGroup[0]?.id}_${gIndex}`}
                warehouses={warehouseIds}
                ccCount={ccTotalCount}
                author={historyGroup[0]?.user_name || historyGroup[0]?.user_email || "DART User"}
                date={lastUpdate}
                list={historyGroup}
                onListItemSelect={onListItemSelect}
              />
            );
          })}
        </Row>
      ) : (
        <div className="history-empty">
          <div>
            <Icon
              name="Refresh"
              size={30}
              color="#C3C5C8"
            />
          </div>
          <div>
            {searchValue
              ? `Sorry, there are no results matching for search '${searchValue}' and time of '${timeGap}'`
              : `No history for '${timeGap}' found`}
          </div>
        </div>
      )}
    </div>
  );
};

export default AllocationHistoryListBlock;

const AllocationHistoryItemGroupCollapse: React.FC<IAllocationHistoryItemGroupCollapse> = ({
  warehouses,
  author,
  date,
  ccCount,
  list,
  onListItemSelect,
}) => {
  const [open, setOpen] = useState(false);

  // Handler to navigate between History modals for different allocation processes
  const handleSelectedRowChange = (process?: IAllocationHistoryListItem) => {
    if (process && process?.id) {
      onListItemSelect?.(process);
    } else {
      message.error("Something went wrong while displaying allocation details.");
    }
  };

  // Decide color of the badge according to allocation process status (possible values: SAVED, APPROVED, SENT, FAILED)
  const statusToColor = (status?: string) => {
    switch (status) {
      case "APPROVED":
        return "green";
      case "SAVED":
        return "grey";
      case "SENT":
        return "blue";
      case "FAILED":
        return "red";
      default:
        return undefined;
    }
  };

  const isCrossDockOrder = useMemo(() => {
    return !!list?.[0]?.shipping_date;
  }, [list]);

  return (
    <>
      <div
        className={`allocation-history-list-item group-holder ${open ? "open" : ""}`}
        onClick={() => setOpen((prev) => !prev)}
        key={`${date}_${author}`}
      >
        <div className="details-button">
          <Icon
            name="DropdownArrow"
            style={{ transform: open ? "rotate(-180deg)" : "rotate(0deg)" }}
          />
        </div>
        <div className="top">
          <div className="time-ago-and-type">
            Last Update:
            {" "}
            {xTimeAgo(moment(date))}
            {isCrossDockOrder && (
            <Tag
              className="status-badge"
              style={{ background: "purple", color: "white" }}
            >
              Cross-Dock Order
            </Tag>
            )}
          </div>
          <div className="date">{moment(date).format("MM/DD/YYYY | hh:mm A")}</div>
        </div>
        <div className="middle">
          <div className="warehouses">
            {warehouses && warehouses.map((warehouse, index) => (
              <Tag
                className="status-badge"
                style={{ background: statusToColor(list?.[index]?.status), color: "white" }}
                key={index}
              >
                {`${warehouse} - ${list?.[index]?.status}`}
              </Tag>
            ))}
          </div>
        </div>
        <div className="bottom">
          <div className="count">
            {ccCount}
          </div>
          <div className="rest">
            {`CC${ccCount === 1 ? "" : "s"} Allocated in `}
            <b>{`${warehouses?.length} Warehouse${warehouses?.length === 1 ? "" : "s"}`}</b>
            {" by "}
            <b>{author}</b>
          </div>
        </div>
      </div>
      {open && (
        <div className="history-list-wrapper-block">
          <Row
            gutter={[24, 24]}
            className="block-list-items"
          >
            {(list || [])?.map((allocationItem: IAllocationHistoryListItem, key) => {
              const count = Object.values(allocationItem?.cc_and_store || {}).length;
              const statusColor = statusToColor(allocationItem?.status);
              return (
                <Col
                  key={key}
                  span={24}
                  xl={12}
                  xxl={12}
                >
                  <div
                    className="allocation-history-list-item"
                    onClick={() => handleSelectedRowChange(allocationItem)}
                    key={allocationItem?.id}
                  >
                    <div className="details-button">
                      <Icon
                        name="Search"
                      />
                    </div>
                    <div className="top">
                      <div className="time-ago-and-type">
                        {xTimeAgo(moment(allocationItem?.updated_at || allocationItem?.created_at))}
                        <Tooltip
                          overlay={`Warehouse ID: ${allocationItem?.warehouse_id}`}
                          placement="bottom"
                        >
                          <Tag
                            className="status-badge"
                            style={{ background: statusColor }}
                          >
                            {allocationItem?.warehouse_id}
                          </Tag>
                        </Tooltip>
                        {statusColor && (
                          <Tooltip
                            overlay={allocationItem?.status === "FAILED" ? allocationItem?.comment : undefined}
                            placement="right"
                          >
                            <Tag
                              className="status-badge"
                              style={{ background: statusColor }}
                            >
                              {allocationItem?.status}
                            </Tag>
                          </Tooltip>
                        )}
                      </div>
                      <div
                        className="date"
                      >
                        {moment(allocationItem?.updated_at || allocationItem?.created_at).format("MM/DD/YYYY | hh:mm A")}
                      </div>
                    </div>
                    <div className="bottom">
                      <div className="count">
                        {count}
                      </div>
                      <div className="rest">
                        {`CC${count === 1 ? "" : "s"} Allocated by `}
                        <b>{allocationItem?.user_name || allocationItem?.user_email || "DART User"}</b>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <hr />
        </div>
      )}
    </>
  );
};
