import { Tabs } from "antd";
import React, {
  useEffect, useLayoutEffect, useMemo, useState,
} from "react";
import DartButton from "../DartButton";
import "./index.less";
import DartPrompt from "../DartPrompt";

interface IDartDynamicTabsWithScrollProps {
  hasNavigation?: boolean; // Should show navigation buttons ?
  onPrev?: () => void; // Previous handler for tab handle
  onNext?: () => void; // Next handler for tab handle
  onRemove?: (tabIndex: string | number, tabs: any[]) => void; // Callback function for tab remove
  onClick?: (tabIndex: string | number) => void; // Callback function for tab click
  tabRender?: (tabData: any, element: any) => React.FC | React.ReactNode | string; // Render function for tab handle
  tabContentRender?: (tabData: any) => React.FC | React.ReactNode | string; // Render function for tab content
  tabs?: any[]; // Tabs passed from parent
  setTabs?: (tabs: any[]) => void; // Setter function from parent
  activeTab?: string; // Active tab from parent
  onTabChange?: (tabIndex: string | number, tabs: any[]) => void; // Callback function for tab change
  destroyInactiveTabPane?: boolean; // Should destroy inactive tab pane ?
  askRemoveText?: string; // Should ask for confirmation before removing tab ?
}

type PositionType = "left" | "right";

const DartDynamicTabsWithScroll:React.FC<IDartDynamicTabsWithScrollProps> = ({
  onClick,
  hasNavigation = true,
  onPrev,
  onNext,
  onRemove,
  tabRender,
  tabContentRender,
  tabs,
  setTabs,
  activeTab,
  onTabChange,
  destroyInactiveTabPane = true,
  askRemoveText = false,
}) => {
  const tabsRef = React.useRef<HTMLDivElement>(null);
  const [activeKey, setActiveKey] = useState(activeTab);
  const [localTabs, setLocalTabs] = useState(tabs);
  const [shouldFocus, setShouldFocus] = useState(tabs?.findIndex((tab) => tab.key === activeTab) || 0);
  const [shouldShadow, setShouldShadow] = useState(false);
  const [removeKey, setRemoveKey] = useState(undefined);

  // on localTabs change trigger shouldFocus update
  useEffect(() => {
    setShouldFocus(tabs?.findIndex((tab) => tab.key === activeTab) || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localTabs]);

  // Check whether total item count is more than screen can fit to display the overlay shadow on ends
  useLayoutEffect(() => {
    if (window.innerWidth < ((localTabs?.length || 0) * 184) + (hasNavigation ? 200 : 100)) {
      setShouldShadow(true);
    } else {
      setShouldShadow(false);
    }
  }, [localTabs, hasNavigation]);

  // Set local tabs from parent
  useEffect(() => {
    setLocalTabs(tabs);
  }, [tabs]);

  // Set active key from parent
  useEffect(() => {
    setActiveKey(activeTab);
  }, [activeTab]);

  // Set active key from local tabs when Next button is clicked and trigger callback to parent
  const handleNext = () => {
    if (shouldFocus < (localTabs?.length || 0) - 1) {
      setActiveKey(localTabs?.[shouldFocus + 1]?.key.toString() || "");
      setShouldFocus((prev) => prev + 1);
      onTabChange?.(localTabs?.[shouldFocus + 1]?.key.toString() || "", localTabs || []);
      onNext?.();
    }
  };

  // Set active key from local tabs when Prev button is clicked and trigger callback to parent
  const handlePrev = () => {
    if (shouldFocus > 0) {
      setActiveKey(localTabs?.[shouldFocus - 1]?.key.toString() || "");
      setShouldFocus((prev) => prev - 1);
      onTabChange?.(localTabs?.[shouldFocus - 1]?.key.toString() || "", localTabs || []);
      onPrev?.();
    }
  };

  // if hasNavigation prop is true, display buttons
  const ExtraSlots: Record<PositionType, React.ReactNode> = useMemo(() => {
    return {
      left: undefined,
      right: hasNavigation ? (
        <div className="tabs-navigation">
          <DartButton
            icon="ArrowLeft"
            onClick={handlePrev}
            disabled={shouldFocus <= 0}
          />
          <DartButton
            icon="ArrowRight"
            onClick={handleNext}
            disabled={shouldFocus === (localTabs?.length || 0) - 1}
          />
        </div>
      ) : undefined,
    };
  }, [onNext, onPrev, shouldFocus, localTabs]);

  // Handle tab remove click
  const handleRemove = (targetKey: string) => {
    const targetIndex = (localTabs || []).findIndex((pane) => pane.key.toString() === targetKey.toString());
    const newPanes = (localTabs || []).filter((pane) => pane.key.toString() !== targetKey.toString());
    if (newPanes?.length && targetKey === activeKey) {
      const { key } = newPanes?.[targetIndex === newPanes?.length ? targetIndex - 1 : targetIndex];
      setActiveKey(key);
    }
    setLocalTabs(newPanes || []);
    setTabs?.(newPanes || []);
  };

  // Handle tab edit click (remove or add) / add functionality not supported for now
  const handleEdit = (targetKey: any, action: "add" | "remove") => {
    if (action === "remove") {
      // If ask remove prop is there, show modal that asks for confirmation
      if (askRemoveText) {
        setRemoveKey(targetKey);
      } else {
        handleRemove(localTabs?.find((e) => e.key === targetKey)?.key);
        onRemove?.(localTabs?.find((e) => e.key === targetKey)?.key, localTabs || []);
        setRemoveKey(undefined);
      }
    }
  };

  // Handle tab change
  const handleChange = (key: string) => {
    setActiveKey(key);
    setShouldFocus((localTabs || []).findIndex((e) => e.key === key) || 0);
    onTabChange?.(key, localTabs || []);
  };

  return (
    <div className={`dart-dynamic-tabs-with-scroll ${hasNavigation ? "with-navigation" : ""}`}>
      <div className="remove-modal-wrapper">
        <DartPrompt
          visible={!!removeKey}
          width={476}
          title={askRemoveText}
          content={(
            <>
              Are you sure you want to remove
              <br />
              <b style={{ fontWeight: 600, fontFamily: "GothamBold, sans-serif" }}>{removeKey}</b>
              ?
            </>
          )}
          okText="Remove"
          cancelText="Cancel"
          onOk={() => {
            handleRemove(localTabs?.find((e) => e.key === removeKey)?.key);
            onRemove?.(localTabs?.find((e) => e.key === removeKey)?.key, localTabs || []);
            setRemoveKey(undefined);
          }}
          onCancel={() => setRemoveKey(undefined)}
          onClose={() => setRemoveKey(undefined)}
        />
      </div>
      <div className="tabs-wrapper">
        { shouldFocus !== 0 && shouldShadow ? <div className="tab-left-shadow" /> : "" }
        <div
          className="tabs"
          ref={tabsRef}
        >
          <Tabs
            tabBarExtraContent={localTabs?.length && localTabs?.length > 1 ? ExtraSlots : undefined}
            hideAdd
            onTabClick={(index) => onClick?.(index)}
            onChange={handleChange}
            activeKey={activeKey}
            type="editable-card"
            onEdit={handleEdit}
            destroyInactiveTabPane={destroyInactiveTabPane}
          >
            {(localTabs || []).map((tab: any, index) => (
              <Tabs.TabPane
                tab={(tabRender
                  ? tabRender(tab, React.cloneElement(tab, { onClick: () => onClick?.(index) }))
                  : React.cloneElement(tab, { onClick: () => onClick?.(index) })) as any}
                key={tab.key}
              >
                {tabContentRender ? tabContentRender(tab) : (tab?.content || "")}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>
        { shouldFocus !== (localTabs?.length || 0) - 1 && shouldShadow ? <div className="tab-right-shadow" /> : "" }
      </div>
    </div>
  );
};

export default DartDynamicTabsWithScroll;
