const distributeTotalToSKUs = (total: number, skusToDistribute: any[]) => {
  const items = [];
  for (let i = 0; i < skusToDistribute.length; i += 1) {
    const v = total * skusToDistribute[i]?.size_contribution;
    let remains;
    let whole;
    if (v >= 1) {
      remains = v - Math.floor(v);
      whole = Math.floor(v);
    } else {
      whole = 1;
      remains = 0;
    }
    items.push({
      ...skusToDistribute[i],
      remains,
      whole,
      index: i,
    });
  }

  let s = items.reduce((a, v) => a + v.whole, 0);
  items.sort((a, b) => a.whole - b.whole);
  let index = items.length - 1;
  while (s > total) {
    items[index].whole -= 1;
    index -= 1;
    if (index < 0 || items[index].whole <= 1) {
      index = items.length - 1;
    }
    s -= 1;
  }

  if (items.reduce((a, v) => Math.min(a, v.whole), 1e9) === 0) {
    return false;
  }

  items.sort((a, b) => (a.remains - b.remains));

  index = items.length - 1;
  if (items[index]) {
    while (s < total) {
      items[index].whole += 1;
      items[index].remains = 0;
      index -= 1;
      s += 1;
    }
  }

  items.sort((a, b) => a.index - b.index);

  return items;
};

export default distributeTotalToSKUs;
