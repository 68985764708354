import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const sizeRunSelectedListAtom = atom({
  key: "size-run-selected-list",
  default: [],
  effects_UNSTABLE: [persistAtom],
});
