import React from "react";
import "./index.less";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import Icon from "../../../../../components/Icon";
import { ISizeRun } from "../../../../../global/interfaces";

interface IExistingSizeRun {
  sizeRun: ISizeRun;
  onBack?: () => any;
  onDelete?: (storeId: number) => any;
  deleteLoading?: boolean;
}

interface INewSizeRun {
  sizeRun?: never;
  onBack?: () => any;
  onDelete?: never;
  deleteLoading?: never;
}

const SizeRunPageHeader: React.FC<IExistingSizeRun | INewSizeRun> = ({
  onBack,
  onDelete,
  sizeRun,
  deleteLoading,
}) => {
  return (
    <div className="size-run-page-header">
      <div className="top-section">
        {onBack && (
          <Button
            className="back-button"
            onClick={onBack}
            type="ghost"
            shape="circle"
          >
            <ArrowLeftOutlined style={{ fontSize: 16 }} />
          </Button>
        )}
        {sizeRun && onDelete && sizeRun?.id ? (
          <Button
            className="delete-button"
            onClick={() => onDelete?.(sizeRun?.id || 0)}
            type="ghost"
          >
            <p>Delete this Size Run</p>
            {!deleteLoading ? (
              <Icon
                name="Delete"
                size={18}
                color="#000000"
              />
            ) : (
              <Spin
                size="small"
                style={{ marginTop: 4 }}
              />
            )}
          </Button>
        ) : <></>}
      </div>
      <div className="bottom-section">
        <div className="size-run-name">
          {!sizeRun
            ? (
              <span>Add New Size Run</span>
            )
            : (
              <span>
                {sizeRun?.department ? (
                  <>
                    {sizeRun?.department}
                    <span className="small-splitter">|</span>
                  </>
                ) : (
                  <>
                    {sizeRun.size_run_min === "O/S" || sizeRun.size_run_max === "O/S"
                      ? (<span>One Size</span>)
                      : (
                        <span>
                          {sizeRun.size_run_min === sizeRun.size_run_max
                            ? `${sizeRun.size_run_max}`
                            : `${sizeRun.size_run_min} - ${sizeRun.size_run_max}`}
                        </span>
                      )}
                    <span className="small-splitter">|</span>
                  </>
                )}
                {sizeRun?.name}
              </span>
            )}
        </div>
      </div>
      <hr className="splitter" />
    </div>
  );
};

export default SizeRunPageHeader;
