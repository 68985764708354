import React, { useEffect, useState } from "react";
import "./index.less";
import { useRecoilState } from "recoil";
import { message } from "antd";
import PageTitle from "../../../../components/PageTitle";
import { reviewCCFiltersAtom } from "../../../../global/atoms";
import {
  ReviewCCFilters, ReviewCCs, updateAllocationProcessData,
} from "../..";
import { allocationProcessAtom } from "../../../../global/atoms/allocation-process-atom";
import {
  finishAllocationProcess,
  finishMultipleAllocationProcesses,
  getAllocationProcess,
  getMultipleAllocationProcesses,
  updateAllocationProcesses,
} from "../../services/allocation";
import { showErrorMessage } from "../../../../utils/helpers";

const ReviewCCsLayout = () => {
  const [reviewCCFilters, setReviewCCFilters] = useRecoilState<any>(reviewCCFiltersAtom);
  const [allocationProcess, setAllocationProcess] = useRecoilState<any>(allocationProcessAtom);
  const [updatesDone, setUpdatesDone] = useState<number>(0);
  const [selectedCCs, setSelectedCCs] = useState<string[]>([]);
  const [selectedStores, setSelectedStores] = useState<number[]>([]);

  useEffect(() => {
    setSelectedStores([]);
    setSelectedCCs([]);
  }, [reviewCCFilters.view]);

  // Handler that calls service to trigger finish allocation event with allocation process values provided by user (qty-s)
  const handleFinishAllocation = async () => {
    if (allocationProcess?.data?.processId?.length > 0 && typeof allocationProcess?.data?.processId === typeof []) {
      await finishMultipleAllocationProcesses(allocationProcess, setAllocationProcess);
    } else if (allocationProcess?.data?.processId && typeof allocationProcess?.data?.processId === typeof 1) {
      await finishAllocationProcess(allocationProcess, setAllocationProcess);
    }
  };

  const handleBulkAction = async (action: "is_hot" | "start_ship_date", value: any) => {
    setUpdatesDone((prev) => prev + 1);
    // Build data for multiple CC bulk store update of "is_hot"
    const dataToSendGroupedByProcessId: any = {}; // { process_id: [{ sku: string, store_id: string, is_hot: boolean }, ...], ... }
    const ccs: { cc: string, warehouse_id: string, isd: string }[] = [];
    if (selectedStores && selectedStores.length > 0) { // ===================== IF STORE VIEW
      // find CCs, and it's warehouse based on selectedStores key ex: 10048
      for (let i = 0; i < selectedStores.length; i += 1) {
        const storeId = selectedStores[i];
        const storeData = allocationProcess?.data?.processData?.find((each: any) => each.store_id === storeId);
        const nestedDataToPush = storeData.nested;
        for (let o = 0; o < nestedDataToPush?.length; o += 1) {
          if (!dataToSendGroupedByProcessId[nestedDataToPush[o].process_id]) {
            dataToSendGroupedByProcessId[nestedDataToPush[o].process_id] = [];
          }
          dataToSendGroupedByProcessId[nestedDataToPush[o].process_id].push({
            sku: nestedDataToPush[o]?.sku,
            store_id: storeId,
            allocated_qty: nestedDataToPush[o]?.allocated_qty,
            is_hot: action === "is_hot" ? value : nestedDataToPush[o].is_hot || false,
            start_ship_date: action === "start_ship_date" ? value : nestedDataToPush[o].start_ship_date || null,
          });
        }
      }
    } else if (selectedCCs && selectedCCs.length > 0) { // ===================== IF CC VIEW
      // find CCs, and it's warehouse based on selectedCCs key ex: 123123-CHE_US1_2023-01-01 -> US1
      for (let i = 0; i < selectedCCs.length; i += 1) {
        const cc_uuid = selectedCCs[i].substring(0, selectedCCs[i].indexOf("_"));
        const cc_warehouse_id = selectedCCs[i].substring(selectedCCs[i].indexOf("_") + 1, selectedCCs[i].lastIndexOf("_"));
        const cc_isd = selectedCCs[i].substring(selectedCCs[i].lastIndexOf("_") + 1);
        ccs.push({
          cc: cc_uuid,
          warehouse_id: cc_warehouse_id,
          isd: cc_isd,
        });
      }
      for (let o = 0; o < ccs.length; o += 1) {
        const originalCCObject = allocationProcess?.data?.processData?.find((each: any) => {
          return each.cc === ccs[o].cc && each.warehouse_id === ccs[o].warehouse_id && each.isd === ccs[o].isd;
        });
        const nestedDataToPush = originalCCObject.nested;
        const nestedProcessId = originalCCObject.process_id;
        for (let i = 0; i < nestedDataToPush?.length; i += 1) {
          if (!dataToSendGroupedByProcessId[nestedProcessId]) {
            dataToSendGroupedByProcessId[nestedProcessId] = [];
          }
          dataToSendGroupedByProcessId[nestedProcessId].push({
            sku: nestedDataToPush[i]?.sku,
            store_id: nestedDataToPush[i]?.store_id,
            allocated_qty: nestedDataToPush[i]?.allocated_qty,
            is_hot: action === "is_hot" ? value : nestedDataToPush[i].is_hot || false,
            start_ship_date: action === "start_ship_date" ? value : nestedDataToPush[i].start_ship_date || null,
          });
        }
      }
    }

    await Promise.all(Object.keys(dataToSendGroupedByProcessId).map(async (key) => {
      const response = await updateAllocationProcessData({
        process_id: Number(key),
        allocationProcess,
        setAllocationProcess,
        formData: dataToSendGroupedByProcessId[key],
        groupBy: reviewCCFilters?.view ?? "cc",
        isBulk: true,
        shouldRevalidate: false,
        shouldShowMessage: false,
      });
      if (!response) {
        throw new Error("Failed to update allocation process");
      }
    })).then(async () => {
      // Clear old selections
      setSelectedCCs([]);
      setSelectedStores([]);
      // Show success message
      message.success(`Successfully ${action === "is_hot"
        ? `${value ? "Marked as HOT" : "Marked as Not HOT"}`
        : `updated 'start ship date' to ${value}`}`);
      // Re-validate data
      if (allocationProcess?.data?.processId?.length > 0 && typeof allocationProcess?.data?.processId === typeof []) {
        await getMultipleAllocationProcesses(
          allocationProcess,
          setAllocationProcess,
          allocationProcess?.data?.processId || undefined,
          reviewCCFilters?.view ?? "cc",
          true,
        );
      } else if (allocationProcess?.data?.processId && typeof allocationProcess?.data?.processId === typeof 1) {
        await getAllocationProcess(
          allocationProcess,
          setAllocationProcess,
          allocationProcess?.data?.processId || undefined,
          reviewCCFilters?.view ?? "cc",
          false,
          true,
        );
      }
    }).catch(() => {
      // In case any of the requests failed, show error message
      showErrorMessage(`Failed to ${action === "is_hot" ? `${value ? "Mark as HOT" : "Mark as Not HOT"}` : `update 'start ship date' to ${value}`}`);
    });
  };

  const handleCrossDockShipDateChange = async (value: any) => {
    const processIds = typeof allocationProcess?.data?.processId === typeof 1
      ? [allocationProcess?.data?.processId]
      : allocationProcess?.data?.processId;
    await updateAllocationProcesses({
      process_ids: processIds,
      allocationProcess,
      setAllocationProcess,
      formData: {
        shippingDate: value?.format("YYYY-MM-DD"),
      },
      groupBy: reviewCCFilters?.view ?? "cc",
    });
  };

  return (
    <div className="review-ccs-page">
      <PageTitle
        title="Final review"
        icon="Clip"
      />
      <ReviewCCFilters
        key={updatesDone}
        bulkSelectedCCs={selectedCCs}
        bulkSelectedStores={selectedStores}
        handleBulkAction={handleBulkAction}
        onFiltersUpdate={(filters) => setReviewCCFilters(filters)}
        handleCrossDockShipDateChange={handleCrossDockShipDateChange}
        onSubmit={handleFinishAllocation}
      />
      <ReviewCCs
        selectedFilters={reviewCCFilters}
        selectedCCs={selectedCCs}
        setSelectedCCs={setSelectedCCs}
        selectedStores={selectedStores}
        setSelectedStores={setSelectedStores}
      />
    </div>
  );
};

export default ReviewCCsLayout;
