import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const allocQInventoryDataAtom = atom({
  key: "alloc-q-inventory-data",
  default: { data: {}, status: "initial" },
  effects_UNSTABLE: [persistAtom],
});
