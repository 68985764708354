import React, { useEffect, useState } from "react";
import "./index.less";
import { Select } from "antd";
import { ISelectOption } from "../../global/interfaces";
import Icon from "../Icon";

interface IDartSelect {
  value?: ISelectOption;
  onChange?: (selected?: string) => void;
  selectOptions?: ISelectOption[];
  disabled?: boolean;
  showArrow?: boolean;
  showSearch?: boolean;
  hasBorder?: boolean;
  placeholder?: string;
  canClear?: boolean;
  popupContainer?: (trigger?: any) => HTMLElement,
  label?: string;
  onOpen?: () => void;
}

const { Option } = Select;

const DartSelect: React.FC<IDartSelect> = ({
  value = undefined,
  onChange,
  selectOptions,
  disabled,
  showArrow = true,
  showSearch = false,
  hasBorder = false,
  placeholder = "Select",
  canClear = true,
  popupContainer,
  label,
  onOpen,
}) => {
  const inputId = `f${(Math.random() * 10000).toString()}`;
  const [selected, setSelected] = useState<ISelectOption | undefined>(value);

  const handleChange = (selectedValue: any) => {
    setSelected(selectedValue);
    onChange?.(selectedValue);
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <div
      className={`dart-select ${!hasBorder ? "no-border" : ""}`}
    >
      {label ? (
        <label
          htmlFor={inputId}
          className="dart-select-label"
        >
          {label}
        </label>
      ) : ""}
      <Select
        id={inputId}
        defaultValue={selected}
        value={selected}
        onChange={(val) => handleChange(val)}
        disabled={!!disabled}
        showArrow={showArrow}
        showSearch={showSearch}
        placeholder={placeholder}
        allowClear={canClear}
        suffixIcon={(
          <Icon
            name="DropdownArrow"
            className="ant-select-suffix"
          />
        )}
        onDropdownVisibleChange={(open) => (open ? onOpen?.() : false)}
        getPopupContainer={popupContainer}
      >
        {selectOptions?.map((opt) => {
          return (
            <Option
              key={opt.key ?? opt.value}
              value={opt.value}
              disabled={!!opt.disabled}
            >
              {opt.label}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default DartSelect;
