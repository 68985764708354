/* eslint-disable no-param-reassign,jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import { Dropdown } from "antd";
import { IPoaValue, IStoreProfile } from "../../../../../global/interfaces";
import Icon from "../../../../../components/Icon";
import TableDropdownMenuComponent from "../../../../../components/TableDropdownMenuComponent";
import decideStoreStatusColor from "../../../../../utils/helpers/decideStoreStatusColor";

const columns = (props: {
  onStoreCheck?: (storeId?: any) => void;
  handleEditStoreClick?: (storeId?: any) => void;
  handleRemoveSisterStoreClick?: (storeId?: any) => void;
  editable?: boolean;
  isFrozenLeft?: boolean;
  channelStoreList?: IStoreProfile[];
}): Record<string, any>[] => [
  {
    key: "store",
    dataIndex: "store",
    title: "Store",
    width: 300,
    flexShrink: 5,
    ...(props.isFrozenLeft ? { frozen: "left", className: "poa-fixed-cell" } : {}),
    cellRenderer: ({ rowData }: {
      rowData: {checked?: boolean, store_id?: number, poaValues?: IPoaValue[]}
    }) => {
      const currentStore = props.channelStoreList?.filter((st: IStoreProfile) => st.id === rowData?.store_id)?.[0];
      return (
        <div className="poa-overview-table-cell frozen">
          <div className="left">
            <span className="store-number">{currentStore?.number}</span>
            <span className="vertical-splitter" />
            <span className="store-name">{currentStore?.name}</span>
            {currentStore?.sister_store?.number && (
              <Dropdown
                destroyPopupOnHide
                trigger={["hover"]}
                overlay={(
                  <span className="table-row-menu-overlay sister-store">
                    <Icon
                      name="Branch"
                      size={12}
                      color="#000000"
                    />
                    <span className="store-number">{currentStore?.sister_store?.number}</span>
                    <span className="vertical-splitter" />
                    <span className="store-name">{currentStore?.sister_store?.name}</span>
                  </span>
                )}
              >
                <Icon
                  name="Branch"
                  size={12}
                  color="#000000"
                  style={{ marginTop: 6 }}
                />
              </Dropdown>
            )}
            <span
              className="store-status"
              style={{ backgroundColor: decideStoreStatusColor(currentStore?.status) }}
            />
          </div>
          <div className="right">
            <TableDropdownMenuComponent
              rowData={rowData}
              functions={{
                handleEditStoreClick: () => props?.handleEditStoreClick?.(currentStore?.id),
                handleRemoveSisterStoreClick: currentStore?.sister_store?.id
                  ? () => props.handleRemoveSisterStoreClick?.(currentStore?.id)
                  : undefined,
                handleCopy: () => false,
                handlePaste: () => false,
              }}
            />
          </div>
        </div>
      );
    },
  },
];

export default columns;
