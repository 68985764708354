const arrayCompare = (
  array1: any[], array2: any[], keyComparator?: any,
) => {
  if (array1 && !array2) return false;
  if (!array1 && array2) return false;
  if (array1.length !== array2.length) return false;
  for (let i = 0; i < array1.length; i += 1) {
    if (keyComparator) {
      if (array1[i]?.[keyComparator] !== array2[i]?.[keyComparator]) return false;
    } else if (array1[i] !== array2[i]) return false;
  }
  return true;
};

export default arrayCompare;
