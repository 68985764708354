import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import "./index.less";
import { useRecoilValue, useResetRecoilState } from "recoil";
import {
  Button, Empty, Pagination, Spin,
} from "antd";
import Search from "antd/es/input/Search";
import columns from "./columns";
import {
  sizeProfileAtom,
  sizeProfileChannelAtom,
  sizeRunExpandedListAtom,
  sizeRunSelectedListAtom,
} from "../../../../../global/atoms";
import decideDivisionName from "../../../../../utils/helpers/decideDivisionName";
import { mergeObjectIntoArray, numberToStringFloat } from "../../../../../utils/helpers";
import DartPrompt from "../../../../../components/DartPrompt";
import DartButton from "../../../../../components/DartButton";
import DartTable from "../../../../../components/DartTable";
import { sizeRunListSelector } from "../../../../../global/selectors";
import {
  calculateInitialSizeContributions,
  getUpdatableSPs,
  importSizeProfiles,
  transformSizeProfiles,
} from "../../services/sizeProfile";
import { SizeRunCollapse } from "../../index";
import { ISizeRun } from "../../../../../global/interfaces";
import EditableCell from "../../../../../components/EditableCell";
import Icon from "../../../../../components/Icon";

interface ISizeProfileImportWidget {
  visible?: boolean;
  setVisible?: any;
  setActionDisabled?: (disabled?: boolean) => void;
}

const SizeProfileImportWidget: React.FC<ISizeProfileImportWidget> = ({
  visible, setVisible, setActionDisabled,
}) => {
  const sizeRunsMain = useRecoilValue<any>(sizeRunListSelector);
  const resetSizeProfiles = useResetRecoilState(sizeProfileAtom);
  const [updatableSPs, setUpdatableSPs] = useState<any>({});
  const [expandedList, setExpandedList] = useState<number[]>([]);
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const sizeProfileChannel = useRecoilValue(sizeProfileChannelAtom);
  const resetMainExpandedList = useResetRecoilState(sizeRunExpandedListAtom);
  const resetMainSelectedList = useResetRecoilState(sizeRunSelectedListAtom);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<string | undefined>();

  const PER_PAGE_VIEW = 10;
  const [paginationData, setPaginationData] = useState<any>({});

  useEffect(() => {
    if (sizeRunsMain?.data?.length) {
      const pagination:any = {};
      // For each division - set current page
      for (let i = 0; i < sizeRunsMain?.data?.length; i += 1) {
        pagination[sizeRunsMain?.data[i]?.key] = 1;
      }
      setPaginationData(pagination);
    }
    // eslint-disable-next-line
  }, [searchValue]);

  useEffect(() => {
    getUpdatableSPs(
      updatableSPs, setUpdatableSPs, sizeProfileChannel, () => {
        calculateInitialSizeContributions(setUpdatableSPs);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeProfileChannel]);

  useEffect(() => {
    setExpandedList([]);
  }, [sizeProfileChannel]);

  const handleExpand = (sizeRunId: number) => {
    if (expandedList.includes(sizeRunId)) {
      setExpandedList(expandedList.filter((item) => item !== sizeRunId));
    } else {
      setExpandedList([...expandedList, sizeRunId]);
    }
  };

  const handleCheck = (sizeRun: any) => {
    if (checkedList.includes(sizeRun?.id)) {
      setCheckedList((prev) => prev.filter((item) => item !== sizeRun?.id));
    } else {
      setCheckedList((prev) => [...prev, sizeRun?.id]);
    }
  };

  // Check selected departments and divisions, prepare values to be sent to BE
  const handleImport = useCallback(async () => {
    setLoading(true);
    // Based on store_id, push correct POA data into the array to send to BE

    const sizeRunListFlat = (sizeRunsMain?.data || [])?.reduce((acc: any, curr: any) => {
      return [...acc, ...(curr?.data).reduce((acc2: any, cur2: any) => [...acc2, ...cur2?.data], [])];
    }, []);

    const data = updatableSPs?.rawData
      ?.filter((sp: any) => {
        const key = sp.size_run_id;
        return key && checkedList.includes(key);
      })
      ?.map((sp: any) => {
        return {
          size_run_id: sp?.size_run_id,
          store_id: sp?.store_id,
          size: sp?.size,
          contribution: sp?.updated || sp?.contribution,
          division: sizeRunListFlat?.find((item: any) => item?.id === sp?.size_run_id)?.division,
          department: sizeRunListFlat?.find((item: any) => item?.id === sp?.size_run_id)?.department,
        };
      });

    const chunkedData: any = {};

    data?.forEach((item: any) => {
      const { division, department } = item;
      if (!chunkedData[`${division}_${department}`]) {
        chunkedData[`${division}_${department}`] = [];
      }
      chunkedData[`${division}_${department}`].push(item);
    });

    // Send data to BE
    await importSizeProfiles(
      chunkedData,
      () => {
        setLoading(false);
        getUpdatableSPs(
          updatableSPs, setUpdatableSPs, sizeProfileChannel, () => {
            calculateInitialSizeContributions(setUpdatableSPs);
          },
        );
        setExpandedList([]);
        setCheckedList([]);

        // update parent selected/expanded lists
        resetSizeProfiles();
        resetMainExpandedList();
        resetMainSelectedList();
        setVisible(false);
      },
      () => {
        setLoading(false);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList, updatableSPs, sizeRunsMain]);

  const handleCancel = () => {
    setVisible(false);
    setExpandedList([]);
    setCheckedList([]);
  };

  const getColumns = useMemo(() => {
    return columns({
      channelStoreList: sizeRunsMain.channelStoreList,
    });
  }, [sizeRunsMain.channelStoreList]);

  const realValue = (value?: number) => {
    if (value === undefined) {
      return 0;
    }
    return Math.round((value + Number.EPSILON) * 100) / 10000;
  };

  const onSizeRunTotalUpdate = (
    store_id: number, size_run_id: number, size: string, size_run_total: string, allSizes: any,
  ) => {
    // Recalculate size_run_totals and contribution based on size_run_total value
    setUpdatableSPs((prev: any) => {
      // Check if it already exists in the backend rawData, if not add it manually (for new size profiles)
      const existsInRaw = prev?.rawData?.find((item: any) => item?.store_id === store_id && item?.size_run_id === size_run_id && item?.size === size);
      if (!existsInRaw) {
        const store = sizeRunsMain?.channelStoreList?.find((item: any) => item?.id === store_id);
        const storeRequiredData = {
          id: store_id,
          name: store?.name,
          number: store?.number,
          brand: store?.brand,
          channel: store?.channel,
          country: store?.country,
          sister_store: store?.sister_store,
          sister_store_id: store?.sister_store_id,
          status: store?.status,
        };
        for (let i = 0; i < allSizes?.length; i += 1) {
          if (allSizes[i] === size) {
            const newEntry = {
              contribution: "0",
              size,
              size_run_id,
              store: storeRequiredData,
              store_id,
              total: 0,
              updated_total: size_run_total,
              updated: "0",
            };
            prev?.rawData?.push(newEntry);
          } else {
            const newEntry = {
              contribution: "0",
              size: allSizes[i],
              size_run_id,
              store: storeRequiredData,
              store_id,
              total: 0,
              updated_total: 0,
              updated: "0",
            };
            prev?.rawData?.push(newEntry);
          }
        }
      }

      // Calculate new total
      const storeNewTotal = prev?.rawData
        ?.filter((item: any) => item?.store_id === store_id && item?.size_run_id === size_run_id)
        ?.reduce((acc: any, item: any) => {
          if (item?.size === size) {
            return acc + Number(size_run_total);
          }
          return acc + (item?.updated_total || Number(item?.updated_total) === 0 ? item?.updated_total : item?.total);
        }, 0);

      // Assemble new rawData
      const newRawData = prev?.rawData?.map((item: any) => {
        if (item?.store_id === store_id && item?.size_run_id === size_run_id) {
          const newOrOldValue = (item?.updated_total || Number(item?.updated_total) === 0 ? item?.updated_total : item?.total);
          const sizeTotal = item?.size === size ? Number(size_run_total) : newOrOldValue;
          return {
            ...item,
            updated: numberToStringFloat((sizeTotal / storeNewTotal) * 10000),
            updated_total: sizeTotal,
          };
        }
        return item;
      });

      const newUniqueKeysList:any = {};

      // Group newRawData by size_run_id
      newRawData?.map((item: any) => {
        const key = item?.size_run_id;
        if (!newUniqueKeysList?.hasOwnProperty(key)) {
          newUniqueKeysList[key] = [item];
        } else {
          newUniqueKeysList[key].push(item);
        }
        return item;
      });

      const newGroups: any = {};

      // Transform newRawData into newGroups
      Object.values(newUniqueKeysList).forEach((itemsList: any) => {
        const sizeRunId = itemsList[0]?.size_run_id;

        if (!newGroups.hasOwnProperty(sizeRunId)) {
          newGroups[sizeRunId] = {};
        }

        if (itemsList.length > 0) {
          newGroups[sizeRunId] = { status: "success", data: transformSizeProfiles(itemsList) };
        }
      });

      // Return updated state with groupData updated from newRawData. Also update rawData without changing state status
      return {
        ...prev,
        groupData: newGroups,
        rawData: newRawData,
      };
    });
  };

  const appendDynamicColumns = (cols: any, profiles: any[]) => {
    const newColumns = [...cols];
    const sizeRunAll = (profiles as any)?.filter((p: any) => !!p?.size_run_all)?.[0]?.size_run_all?.split(",");
    const isSingleSize = sizeRunAll?.length === 1;
    for (let i = 0; i < sizeRunAll?.length; i += 1) {
      const columnName = sizeRunAll?.[i];

      newColumns.push({
        key: columnName,
        dataIndex: columnName,
        title: columnName,
        width: isSingleSize ? 500 : 120,
        flexGrow: isSingleSize ? 5 : 1,
        align: "center",
        className: "size-profile-editable-cell",
        cellRenderer: ({ rowData }: { rowData: any }) => {
          const currentField = rowData?.sizes.find((s: { size: string }) => s.size === columnName);
          const isUpdated = currentField?.updated && Number(currentField?.updated).toFixed(2) !== Number(currentField?.contribution).toFixed(2);

          const displayValueOrEmpty = () => {
            return (
              <div>
                {currentField?.is_sister_store && <span className="is-sister-store"><Icon name="Branch" /></span>}
                <EditableCell
                  cellData={currentField?.updated_total || Number(currentField?.updated_total) === 0
                    ? currentField?.updated_total : currentField?.total}
                  onOk={(total) => onSizeRunTotalUpdate?.(
                    rowData?.store_id,
                    rowData?.size_run_id,
                    columnName,
                    total,
                    sizeRunAll,
                  )}
                  min={0}
                  max={100000}
                  cancelOnBlur
                  allowDecimals={false}
                  hasButtons={false}
                  allowSameValueSubmit
                  allowEmpty={currentField?.updated === "-1" && currentField?.contribution === -1}
                />
                {currentField?.total
                || currentField?.total === 0
                || currentField?.contribution
                || currentField?.contribution === 0 ? (
                  <div className="contribution-value">
                    <small>c%: </small>
                    {currentField?.updated || currentField?.contribution
                      ? `${realValue(currentField?.updated || Number(currentField?.updated) === 0
                        ? currentField?.updated : currentField?.contribution)}%`
                      : "0%"}
                  </div>
                  ) : ""}
              </div>
            );
          };

          return (
            <div className={`size-run-import-widget-table-cell ${isUpdated ? "updated" : ""}`}>
              {displayValueOrEmpty()}
            </div>
          );
        },
      });
    }

    return newColumns;
  };

  const modalContent = useMemo(() => {
    return (sizeRunsMain?.data || [])?.map((sizeRunDivision: any, i: number) => {
      const sizeRunListData = sizeRunDivision?.data || [];
      const division = sizeRunDivision?.key;

      // Updatable Size Profiles filtered
      const updatableSizeProfilesList = [];

      let sizeRunCount = 0;
      for (let k = 0; k < sizeRunListData?.length; k += 1) {
        for (let o = 0; o < sizeRunListData[k]?.data?.length; o += 1) {
          if (updatableSPs?.groupData?.[sizeRunListData[k]?.data?.[o]?.id]) {
            sizeRunCount += 1;
            updatableSizeProfilesList.push(sizeRunListData[k]?.data?.[o]);
          }
        }
      }

      // If no update in division, skip
      if (sizeRunCount === 0) return "";

      // Check if any is selected, if yes, display "deselect all" button
      const anyFromListIsChecked = (sizeRunListData || [])?.some((sizeRunObject: any) => {
        return sizeRunObject?.data?.some((sizeRun: ISizeRun) => checkedList.includes(sizeRun?.id));
      });

      // Function that handles select all / deselect all in group
      const handleGroupCheck = (checked: boolean) => {
        const groupToAffect: any = [];
        for (let k = 0; k < sizeRunListData.length; k += 1) {
          const current = sizeRunListData[k].data;
          for (let kk = 0; kk < current.length; kk += 1) {
            const sizeRunId = current[kk]?.id;
            const isAlreadySelected = checkedList.includes(sizeRunId);
            if (updatableSPs?.groupData?.hasOwnProperty(sizeRunId)) {
              if ((checked && !isAlreadySelected)) {
                groupToAffect.push({ id: sizeRunId });
              } else if ((!checked && isAlreadySelected)) {
                groupToAffect.push({ id: sizeRunId });
              }
            }
          }
        }

        // select or unselect from list
        for (let k = 0; k < groupToAffect?.length; k += 1) {
          const current = groupToAffect[k];
          handleCheck(current);
        }
      };

      // For pagination of the data
      const sliceStart = ((paginationData?.[division] || 1) - 1) * PER_PAGE_VIEW;
      const sliceEnd = sliceStart + PER_PAGE_VIEW;

      const filteredBySearchSizeRuns = searchValue
        ? (updatableSizeProfilesList || [])
          ?.filter((e: ISizeRun) => e.name && e.name?.toLowerCase()?.indexOf(searchValue?.toLowerCase()) > -1)
        : (updatableSizeProfilesList || []);

      return (
        <div
          key={i.toString()}
          className="size-run-import-widget-single"
        >
          <div className="top">
            <div className="left">
              <div className="title">
                {decideDivisionName(division)}
              </div>
              <div className="toggle-all">
                <Button onClick={() => handleGroupCheck(!anyFromListIsChecked)}>
                  {anyFromListIsChecked ? "Deselect All" : "Select All"}
                </Button>
              </div>
            </div>
            <div className="extra">
              <Pagination
                simple
                current={paginationData?.[division] || 1}
                defaultCurrent={paginationData?.[division] || 1}
                total={searchValue ? filteredBySearchSizeRuns?.length : sizeRunCount}
                pageSize={PER_PAGE_VIEW}
                onChange={(page) => setPaginationData({ ...paginationData, [division]: page })}
                size="small"
                hideOnSinglePage
              />
              <span>
                {searchValue && `Searched ${filteredBySearchSizeRuns?.length} from `}
                {sizeRunCount === 1
                  ? "1 Size Run"
                  : `${sizeRunCount} Size Runs`}
              </span>
            </div>
          </div>
          <div className="bottom">
            {
              (filteredBySearchSizeRuns || [])?.length > 0
                ? (filteredBySearchSizeRuns || [])?.slice(sliceStart, sliceEnd)?.map((sizeRun: any) => {
                // If no update in size run, skip
                  if (!updatableSPs?.groupData?.[sizeRun?.id]?.hasOwnProperty("data")) return "";

                  const existingSizeProfile = updatableSPs?.groupData?.[sizeRun?.id] || {};

                  // Used in Table (this is store list + size profile values intercepted with each other
                  const tableValues = mergeObjectIntoArray(
                    sizeRunsMain?.channelStoreList,
                    existingSizeProfile?.data,
                    "id",
                    "store",
                    "store_id",
                    {
                      size_run_id: sizeRun.id,
                      size_run_all: sizeRun.size_run_all,
                      sizes: [],
                      totalContribution: "OFF",
                    },
                  );

                  // Sort tableValues by store name
                  const sortedTableValues = (Object.values(tableValues || {}) || [])
                    ?.sort((a: any, b: any) => {
                      if (a.store?.name > b.store?.name) return 1;
                      if (b.store?.name > a.store?.name) return -1;
                      return 0;
                    });

                  return (
                    <SizeRunCollapse
                      key={sizeRun?.id}
                      sizeRun={sizeRun}
                      loading={existingSizeProfile?.status === "request"}
                      expanded={
                      expandedList.indexOf(sizeRun?.id) > -1
                    }
                      onExpandToggle={() => handleExpand(sizeRun?.id)}
                      onCheckboxToggle={() => handleCheck(sizeRun)}
                      checked={checkedList.indexOf(sizeRun?.id) > -1}
                      expandedContent={existingSizeProfile?.status !== "request" ? (
                        <DartTable
                          tableStyle="dark"
                          rowKey="id"
                          width="100%"
                          data={sortedTableValues || []}
                          height={184}
                          checkboxSize="sm"
                          columns={appendDynamicColumns(getColumns, sortedTableValues)}
                          fixed
                          emptyRenderer={() => <div style={{ padding: "48px calc(50% - 64px)" }}>Size Profile is empty</div>}
                        />
                      ) : (
                        <Spin
                          size="default"
                          style={{
                            position: "absolute", left: 0, right: 0, marginTop: 64,
                          }}
                        />
                      )}
                    />
                  );
                })
                : <Empty description={`No matching size run for "${searchValue}" was found`} />
            }
          </div>
        </div>
      );
    });
  }, [
    appendDynamicColumns,
    checkedList,
    expandedList,
    getColumns,
    handleCheck,
    handleExpand,
    paginationData,
    sizeRunsMain?.channelStoreList,
    sizeRunsMain?.data,
    updatableSPs?.groupData,
    searchValue,
  ]);

  return (
    <div
      className="size-run-import-widget"
      key={Object.keys(updatableSPs?.groupData || {}).length?.toString()}
    >
      <DartButton
        icon="Plus"
        size="md"
        label={Object.keys(updatableSPs?.groupData || {})?.length !== 0
        && updatableSPs?.isSalesTransactionComplete === false ? "Import *" : "Import"}
        onClick={() => setVisible(true)}
        type="secondary"
        disabled={Object.keys(updatableSPs?.groupData || {})?.length === 0}
        loading={updatableSPs?.status === "request"}
        tooltip={updatableSPs?.isSalesTransactionComplete === false ? "Sales data is not fully fetched yet" : undefined}
      />
      {visible && (
        <DartPrompt
          className="size-run-import-widget-modal-container"
          icon="Flash"
          title="Import Ready to Update Size Profiles"
          visible={visible}
          onClose={handleCancel}
          onOk={handleImport}
          onCancel={handleCancel}
          okText="Import"
          okButtonProps={{ disabled: checkedList?.length === 0, loading }}
          cancelText="Cancel"
          width={1300}
          content={(
            <div>
              <div className="modal-content-search">
                <Search
                  placeholder="Search (e.g 'W 5-10')"
                  onSearch={(v) => setSearchValue(v || "")}
                />
              </div>
              {modalContent}
            </div>
          )}
        />
      )}
    </div>
  );
};

export default SizeProfileImportWidget;
