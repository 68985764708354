import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const allocationHistoryListAtom = atom({
  key: "allocation-history-list",
  default: { status: "request", data: [] },
  effects_UNSTABLE: [persistAtom],
});
