const tableToObjectArray = (data: any[][], columns: string[]) => {
  return data.map((row) => {
    const obj: any = {};
    row.forEach((cell, i) => {
      obj[columns[i]] = cell;
    });
    return obj;
  });
};

export default tableToObjectArray;
