import React from "react";
import "./index.less";
import Icon from "../../../../components/Icon";

const StoreListEmpty:React.FC = () => {
  return (
    <div className="store-list-empty">
      <div>
        <Icon
          name="Store"
          size={30}
          color="#C3C5C8"
        />
      </div>
      <div>
        Sorry, there are no stores to display.
      </div>
    </div>
  );
};

export default StoreListEmpty;
