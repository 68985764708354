import React from "react";
import "./index.less";
import { Form } from "antd";
import PillSelect from "../../../../components/PillSelect";

interface INewnessPillFiltersBlock {
  showSelectedWithStatic?: boolean;
  disabled?: boolean;
}

const NewnessPillFiltersBlock:React.FC<INewnessPillFiltersBlock> = ({ showSelectedWithStatic = false, disabled }) => {
  return (
    <div className="newness-pill-filters-block">
      <div className="form-group-division">
        <span>Choose division:</span>
        <Form.Item name="division">
          <PillSelect
            showOnlySelected={showSelectedWithStatic}
            disabled={showSelectedWithStatic || disabled}
            selectOptions={[
              {
                value: "WOMEN", label: "Women's",
              },
              {
                value: "MEN", label: "Men's",
              },
              {
                value: "KID", label: "Kids'",
              },
              {
                value: "HOME_CARE", label: "Homecare",
              },
            ]}
          />
        </Form.Item>
      </div>
      <div className="form-group-and">
        <span>Choose department:</span>
        <Form.Item name="department">
          <PillSelect
            disabled={showSelectedWithStatic || disabled}
            showOnlySelected={showSelectedWithStatic}
            selectOptions={[
              {
                value: "APPAREL", label: "Apparel",
              },
              {
                value: "ACCESSORIES", label: "Accessories",
              },
              {
                value: "FOOTWEAR", label: "Footwear",
              },
              {
                value: "HOME_CARE", label: "Homecare",
              },
            ]}
          />
        </Form.Item>
      </div>
    </div>

  );
};

export default NewnessPillFiltersBlock;
