import React from "react";
import { Steps, Tooltip } from "antd";

interface IAQStagesBlockProps {
  selectedStage: number;
  setSelectedStage: (value: number) => void;
  stages: any;
  isLoaded: boolean;
  direction?: "vertical" | "horizontal";
  size?: "small" | "default";
  labelPlacement?: "horizontal" | "vertical";
}

const IAQStagesBlock:React.FC<IAQStagesBlockProps> = ({
  selectedStage,
  setSelectedStage,
  stages,
  isLoaded,
  direction = "vertical",
  size = "small",
  labelPlacement = "vertical",
}) => {
  return (
    <div className="iaq-store-stages-wrapper">
      <span className="stages-title">Stages</span>
      <Steps
        direction={direction}
        size={size}
        labelPlacement={labelPlacement}
        current={selectedStage - 1}
        onChange={(value) => setSelectedStage(value + 1)}
      >
        <Steps.Step
          status={!isLoaded ? "wait" : "process"}
          stepNumber={1}
          icon={(
            <Tooltip
              overlay={isLoaded
                ? `Stores filtered by Channel and Brand: ${(stages?.data as any)
                  ?.["Stage 1"]?.filters?.brand} and ${(stages?.data as any)?.["Stage 1"]?.filters?.channel}`
                : undefined}
              trigger={["hover"]}
              placement="left"
              getTooltipContainer={() => document.getElementsByClassName("alloc-q-style-details-modal")?.[0] as HTMLElement}
            >
              1
            </Tooltip>
          )}
          disabled={!isLoaded}
        />
        <Steps.Step
          status={!isLoaded ? "wait" : "process"}
          stepNumber={2}
          icon={(
            <Tooltip
              overlay={isLoaded
                ? `Stores filtered by Division and Department: ${(stages?.data as any)
                  ?.["Stage 2"]?.filters?.division} and ${(stages?.data as any)?.["Stage 2"]?.filters?.department}`
                : undefined}
              trigger={["hover"]}
              placement="left"
              getTooltipContainer={() => document.getElementsByClassName("alloc-q-style-details-modal")?.[0] as HTMLElement}
            >
              2
            </Tooltip>
          )}
          disabled={!isLoaded}
        />
        <Steps.Step
          status={!isLoaded ? "wait" : "process"}
          stepNumber={3}
          icon={(
            <Tooltip
              overlay={isLoaded
                ? `Stores with matching POA Class: ${(stages?.data as any)?.["Stage 3"]?.filters?.poa_category_name}`
                : undefined}
              trigger={["hover"]}
              placement="left"
              getTooltipContainer={() => document.getElementsByClassName("alloc-q-style-details-modal")?.[0] as HTMLElement}
            >
              3
            </Tooltip>
          )}
          disabled={!isLoaded}
        />
        <Steps.Step
          status={!isLoaded ? "wait" : "process"}
          stepNumber={4}
          icon={(
            <Tooltip
              overlay={isLoaded
                ? `Stores with matching Size Run: ${(stages?.data as any)?.["Stage 4"]?.filters?.size_run_name}`
                : undefined}
              trigger={["hover"]}
              placement="left"
              getTooltipContainer={() => document.getElementsByClassName("alloc-q-style-details-modal")?.[0] as HTMLElement}
            >
              4
            </Tooltip>
          )}
          disabled={!isLoaded}
        />
        <Steps.Step
          status={!isLoaded ? "wait" : "process"}
          stepNumber={5}
          icon={(
            <Tooltip
              overlay={isLoaded
                ? "Stores without allocation of this CC"
                : undefined}
              trigger={["hover"]}
              placement="left"
              getTooltipContainer={() => document.getElementsByClassName("alloc-q-style-details-modal")?.[0] as HTMLElement}
            >
              5
            </Tooltip>
          )}
          disabled={!isLoaded}
        />
      </Steps>
    </div>
  );
};

export default IAQStagesBlock;
