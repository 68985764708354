export const sizeRunFields = {
  name: [
    { required: true, message: "Please enter size run name" },
    { max: 45, message: "Size run name is too long" },
  ],
  department: [{ required: true, message: "Please select a department" }],
  division: [{ required: true, message: "Please select a division" }],
  size_run_type: [{ required: true, message: "Please select size run type" }],
  size_run_all: [{ required: true, message: "Please select size run ranges" }],
};
