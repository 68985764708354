import React, {
  useCallback, useEffect, useState,
} from "react";
import "./index.less";
import {
  useRecoilState, useRecoilValue, useSetRecoilState,
} from "recoil";
import { Spin } from "antd";
import { useHistory } from "react-router-dom";
import moment, { Moment } from "moment";
import DartTable from "../../../../components/DartTable";
import columns from "./columns";
import {
  allocQAllStoreListAtom,
  allocQDataAtom,
  allocQExpandedListAtom,
  allocQListAtom,
  allocQSelectedListAtom,
  globalFiltersAtom,
} from "../../../../global/atoms";
import paths from "../../../../configs/paths";
import {
  AllocQCollapse, AllocQCommentsSider, AllocQStyleDetails, getAllocQData, getAllocQList,
} from "../../index";
import { IAllocationQuantityCC, IAllocQ } from "../../../../global/interfaces";
import Icon from "../../../../components/Icon";
import { updateIAQStorePOAs } from "../../services/allocQ";
import { only, showErrorMessage } from "../../../../utils/helpers";
import { getAllStores } from "../../../store";
import { allocQInventoryDataAtom } from "../../../../global/atoms/alloc-q-inventory-data";
import { fetchInventoryDataForBrandAndChannel } from "../../../common/services/cc";

interface IAllocQOverview {
  iaqMonthFilter?: "recent" | "all";
  editable?: boolean;
  setActionDisabled?: (disabled?: boolean) => void;
}

const AllocQOverview: React.FC<IAllocQOverview> = ({
  iaqMonthFilter = "recent", editable, setActionDisabled,
}) => {
  const history = useHistory();
  const [allocQList, setAllocQList] = useRecoilState(allocQListAtom);
  const filters = useRecoilValue<any>(globalFiltersAtom);
  const allocQData = useRecoilValue<any>(allocQDataAtom);
  const setAllocQData = useSetRecoilState<any>(allocQDataAtom);
  const [expandedList, setExpandedList] = useRecoilState<string[]>(allocQExpandedListAtom);
  const [checkedList, setCheckedList] = useRecoilState<string[]>(allocQSelectedListAtom);
  const [updatesDone, setUpdatesDone] = useState(0);
  const [commentsVisibleFor, setCommentsVisibleFor] = useState<IAllocationQuantityCC | undefined>(undefined);
  const [allocQAllStoreList, setAllocQAllStoreList] = useRecoilState(allocQAllStoreListAtom);
  const [selectedStyle, setSelectedStyle] = useState<IAllocationQuantityCC | undefined>(undefined);
  const [inventoryData, setInventoryData] = useRecoilState(allocQInventoryDataAtom);

  // Fetch inventory data (slow function) for brand and channel - this is optimized to be debounced and
  // checked every 20 minutes per brand or channel change
  useEffect(() => {
    if (filters?.brand && filters?.channel) {
      fetchInventoryDataForBrandAndChannel(
        inventoryData, setInventoryData, filters.brand, filters.channel, false, 20,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.channel, filters.brand]);

  // Initial fetch for channel store list
  useEffect(() => {
    if (allocQAllStoreList.status !== "success") {
      getAllStores(
        allocQAllStoreList, setAllocQAllStoreList, { channel: [filters.channel], brand: [filters.brand] },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.channel, filters.channel]);

  // On filter change, collapse all expanded panels
  useEffect(() => {
    setExpandedList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // Handler to set specific panel as expanded. If data is not already there - fetch it, if it's there - load it
  const handleExpand = (allocQKey: string, allocQFilter: IAllocQ) => {
    if (expandedList.includes(allocQKey)) {
      setExpandedList(expandedList.filter((item) => item !== allocQKey));
    } else {
      // Service used for fetching IAQ data for specific month
      getAllocQData(
        allocQData, setAllocQData, allocQFilter.newness_date, {
          ...only(["brand", "channel"], filters), department: allocQFilter.department, division: allocQFilter.division,
        },
      );
      setExpandedList([...expandedList, allocQKey]);
    }
  };

  // Handler to check specific department-division combo of IAQ which fetches IAQ data if it doesn't already exist
  const handleCheck = (allocQKey: string, allocQFilter: IAllocQ) => {
    if (checkedList.includes(allocQKey)) {
      setCheckedList(checkedList.filter((item) => item !== allocQKey));
    } else {
      // Call Service used for fetching IAQ data for specific month
      getAllocQData(
        allocQData, setAllocQData, allocQFilter.newness_date, {
          ...only(["brand", "channel"], filters), department: allocQFilter.department, division: allocQFilter.division,
        },
      );
      setCheckedList([...checkedList, allocQKey]);
    }
  };

  // Handler to update UAS value of specific month, specific newness entry
  const handleUASChange = (
    newness_id: number, value: string, month?: string,
  ) => {
    if (month) {
      setAllocQData((prev: any) => {
        return {
          ...prev,
          [month]: {
            ...prev?.[month],
            data: {
              ...prev?.[month]?.data,
              [newness_id]: {
                ...prev?.[month]?.data?.[newness_id],
                updated_uas: value,
              },
            },
          },
        };
      });
      // Used to hydrate the components
      setUpdatesDone((prev) => prev + 1);
    }
  };

  // Handler function when Supply by date cell is updated. updates "updated_supply_by_date" field that is sent to BE later on
  const handleSupplyByDateChange = (
    newness_id: number, momentValue: Moment | undefined, month?: string,
  ) => {
    const value = momentValue ? momentValue.format("MM/DD/YYYY") : undefined;
    if (month) {
      setAllocQData((prev: any) => {
        return {
          ...prev,
          [month]: {
            ...prev?.[month],
            data: {
              ...prev?.[month]?.data,
              [newness_id]: {
                ...prev?.[month]?.data?.[newness_id],
                updated_supply_by_date: value,
              },
            },
          },
        };
      });
      // Used to hydrate the components
      setUpdatesDone((prev) => prev + 1);
    }
  };

  // Handler to update POA value of specific month, specific newness entry, specific store
  const handleIAQDetailsChange = async (
    newness_id: number, value: string, selectedStoreIdsWithPoa?: { store_id?: number, poa_qty?: string }[],
  ) => {
    // update poa_qty's and UAS from Details Screen
    const detailsDataToSend: any = {
      data: {
        uas: value.toString(), newness_id, selected_stores: [],
      },
    };

    // gather data to send to backend
    selectedStoreIdsWithPoa?.map((each) => {
      detailsDataToSend.data.selected_stores.push({
        store_id: each.store_id,
        poa_qty: each.poa_qty?.toString(),
      });
      return each;
    });

    // call api service that updates store quantity and UAS, after success, callback to refresh data
    await updateIAQStorePOAs(
      detailsDataToSend, newness_id, async () => {
        setUpdatesDone((prev) => prev + 1);
        try {
          // Refresh the page data
          if (filters.channel && filters.brand && selectedStyle?.newness_date) {
            // Refresh the list data
            await getAllocQList(
              allocQList, setAllocQList, only(["brand", "channel"], filters),
            );
            // Get month from newness we just updated
            const month = moment(selectedStyle?.newness_date).startOf("month").format("YYYY-MM");
            // Refresh the expanded alloc Q data
            await getAllocQData(
              allocQData, setAllocQData, month, {
                ...only(["brand", "channel"], filters), department: selectedStyle.department, division: selectedStyle.division,
              }, false,
            );
            setSelectedStyle(undefined);
          }
        } catch (e) {
          showErrorMessage("Error while updating IAQ Details");
        }
      },
    );
  };

  // Function that opens comment sider for specific CC
  const handleCommentsOpen = (cc: IAllocationQuantityCC) => {
    setCommentsVisibleFor(cc);
  };

  // Memoized Columns with required functions provided
  const getColumns = useCallback((month: string) => {
    return columns({
      month,
      editable,
      onUASChange: handleUASChange,
      onSupplyByDateChange: handleSupplyByDateChange,
      onStyleClick: setSelectedStyle,
      onCommentsOpen: handleCommentsOpen,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatesDone]);

  // Formatter to correctly display floats
  const formatter = Intl.NumberFormat("en", { notation: "standard" });

  return (
    <div
      className="alloc-q-overview-widget"
      key={Object.keys(allocQData || {}).length?.toString()}
    >
      <AllocQCommentsSider
        cc={commentsVisibleFor}
        setCC={setCommentsVisibleFor}
      />
      <AllocQStyleDetails
        selectedStyle={selectedStyle}
        setSelectedStyle={setSelectedStyle}
        onUpdate={handleIAQDetailsChange}
      />
      {allocQList?.status === "request" || inventoryData?.status === "request"
        ? (
          <Spin
            size="large"
            style={{
              position: "absolute", left: 0, right: 0, marginTop: 64,
            }}
          />
        )
        : (Object.values(allocQList?.data?.[iaqMonthFilter === "all" ? "all" : "recent"] || {}) as any)
          ?.map((allocQGrouped: IAllocQ[], i: number) => {
          // Render group-blocks of IAQ, calculate their AQ Sums and CC (Style) counts
            const month = (Object.keys(allocQList?.data?.[iaqMonthFilter === "all" ? "all" : "recent"] || {}) as any)[i];
            const summedIAQ = allocQGrouped.reduce((acc: any, cur: any) => acc + (parseInt(cur.iaq, 10)), 0);
            const summedStyleCount = allocQGrouped.reduce((acc: any, cur: any) => acc + (parseInt(cur.cc_count, 10)), 0);
            // Get last updated date for IAQ Group
            const lastUpdatedDateList = allocQGrouped.map((e) => { return moment(e.last_updated); });
            // @ts-ignore
            const lastUpdatedDate = moment.max(lastUpdatedDateList as Moment[]);

            return month ? (
              <div
                key={i.toString()}
                className="alloc-q-overview-single"
              >
                <div
                  className="top"
                  onClick={() => {
                    history.push(`${paths.initial_allocation_quantity_edit}?date=${month}&brand=${filters.brand}&channel=${filters.channel}`);
                  }}
                >
                  <div className="title">
                    {moment(month).format("MMMM YYYY")}
                  </div>
                  <div className="extra">
                    Last Updated:
                    {" "}
                    {` ${lastUpdatedDate.format("MM/DD/YY")}`}
                    <Icon
                      name="DropdownArrow"
                      style={{ rotate: "-90deg" }}
                    />
                  </div>
                </div>
                <div className="totals">
                  <div className="initial-allocation">
                    <div className="value">{summedIAQ || summedIAQ === 0 ? formatter.format(summedIAQ) : "N/A"}</div>
                    <div className="label">Initial Allocation</div>
                  </div>
                  <div className="style-colors">
                    <div className="value">{summedStyleCount ? formatter.format(summedStyleCount) : "N/A"}</div>
                    <div className="label">Style Colors</div>
                  </div>
                </div>
                <div className="bottom">
                  {(allocQGrouped || [])?.length > 0
                    ? ((allocQGrouped || []) as any)?.map((allocQSingleDept: any, o: number) => {
                    // Map department-division combos for specific IAQ month block.
                      const key = `${allocQSingleDept?.newness_date}_${allocQSingleDept?.department}_${allocQSingleDept?.division}`;
                      const monthData = allocQData?.[allocQSingleDept?.newness_date];
                      // Filter nested date for each department-division combo according to department and division
                      const nestedData = (Object.values(monthData?.data || {}) as any)
                        ?.filter((each: IAllocationQuantityCC) => {
                          return each.department === allocQSingleDept?.department && each.division === allocQSingleDept?.division;
                        })
                        ?.map((each: IAllocationQuantityCC) => {
                          const channel_brand_key = `${filters.channel}_${filters.brand}`;
                          if (each.cc) {
                            return { ...each, inventoryData: inventoryData?.data?.[channel_brand_key]?.data?.[each.cc] };
                          }
                          return each;
                        });

                      return (
                        <AllocQCollapse
                          key={`${o}_${key}`}
                          allocQ={allocQSingleDept}
                          loading={monthData?.status === "request" || inventoryData?.status === "request"}
                          expanded={
                            expandedList.indexOf(key) > -1
                          }
                          onExpandToggle={() => handleExpand(key, allocQSingleDept)}
                          {...(editable
                            ? {
                              onCheckboxToggle: () => handleCheck(key, allocQSingleDept),
                              checked: checkedList.indexOf(key) > -1,
                            }
                            : {}
                          )}
                          expandedContent={monthData?.status !== "request" && inventoryData?.status !== "request" ? (
                            <DartTable
                              loading={monthData?.status === "request" || monthData?.status === "revalidate" || inventoryData?.status === "request"}
                              tableStyle="dark"
                              rowKey="id"
                              width="100%"
                              data={nestedData || []}
                              height={184}
                              checkboxSize="sm"
                              fixedFirstColumn
                              columns={getColumns(month)}
                              fixed
                              emptyRenderer={() => <div style={{ padding: "48px calc(50% - 64px)" }}>Initial Allocation Quantities are empty</div>}
                            />
                          ) : (
                            <Spin
                              size="default"
                              style={{
                                position: "absolute", left: 0, right: 0, marginTop: 64,
                              }}
                            />
                          )}
                        />
                      );
                    }) : <></>}
                </div>
              </div>
            ) : <></>;
          })}
    </div>
  );
};

export default AllocQOverview;
