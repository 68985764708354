import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import {
  useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState,
} from "recoil";
import { useHistory } from "react-router-dom";
import {
  Button, message, Tooltip,
} from "antd";
import PageTitle from "../../../../../components/PageTitle";
import {
  poaEditingAtom,
  poaListAtom,
  poaSelectedListAtom,
  poaValuesAtom,
  poaVarianceAtom,
  selectedStoreAtom,
  storeListAtom,
} from "../../../../../global/atoms";
import paths from "../../../../../configs/paths";
import decideDivisionName from "../../../../../utils/helpers/decideDivisionName";
import decideDepartmentName from "../../../../../utils/helpers/decideDepartmentName";
import Icon from "../../../../../components/Icon";
import DartSelect from "../../../../../components/DartSelect";
import DartCheckbox from "../../../../../components/DartCheckbox";
import DartButton from "../../../../../components/DartButton";
import DartTable from "../../../../../components/DartTable";
import columns from "../../widgets/PoaOverview/columns";
import DartPrompt from "../../../../../components/DartPrompt";
import { updateStore } from "../../../../store";
import EditableCell from "../../../../../components/EditableCell";
import { IPoaEntry, IPoaValue } from "../../../../../global/interfaces";
import {
  createCustomCategoryPOA,
  getGroupedPOAs,
  getPOAList,
  updateCustomCategoryPOA,
  updatePOAs,
} from "../../services/poa";
import { poaListSelector } from "../../../../../global/selectors/poa-list-selector";
import { storeListFiltersAtom } from "../../../../../global/atoms/store-list-filters-atom";
import TableDropdownMenuComponent from "../../../../../components/TableDropdownMenuComponent";
import {
  faker, mergeObjectIntoArray, queryGet,
} from "../../../../../utils/helpers";
import countryCodeToName from "../../../../../utils/helpers/countryCodeToName";
import useEnums from "../../../../../utils/hooks/useEnums";
import { IChannelTypes } from "../../../../../global/atoms/global-filters-atom";
import { CustomCategoryPOA, renderPOATableHeader } from "../../index";

const PoaValuesEdit = () => {
  const history = useHistory();
  const variance = useRecoilValue(poaVarianceAtom);
  const poaList = useRecoilValue<any>(poaListSelector);
  const setPoaList = useSetRecoilState<any>(poaListAtom);
  const setCheckedList = useSetRecoilState<number[]>(poaSelectedListAtom);
  const [initPoaValues, setInitPoaValues] = useState<any>({});
  // eslint-disable-next-line
  const [poaEditing, setPoaEditing] = useRecoilState<{poa: IPoaEntry[], poaValues: { data?: IPoaValue[], touched?: boolean, status?: string }}>(poaEditingAtom);
  const resetPoaEditing = useResetRecoilState(poaEditingAtom);
  const [actionDisabled, setActionDisabled] = useState<boolean | undefined>(false);
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [filteredList, setFilteredList] = useState<any>([]);
  const [appliedFilters, setAppliedFilters] = useState<any>({ status: ["PLANNING", "LIVE", "PAUSED"], location: undefined });
  const setSelectedStore = useSetRecoilState<any>(selectedStoreAtom);
  const [sisterStoreDeleteId, setSisterStoreDeleteId] = useState<number | undefined>(undefined);
  const [deleteSSLoading, setDeleteSSLoading] = useState(false);
  const [updatesDone, setUpdatesDone] = useState(0);
  const setPoaValues = useSetRecoilState<any>(poaValuesAtom);
  const [loading, setLoading] = useState<boolean>(false);
  const [storeList, setStoreList] = useRecoilState<any>(storeListAtom);
  const [discardModalVisible, setDiscardModalVisible] = useState(false);
  const [shouldSeeClasses, setShouldSeeClasses] = useState(true);
  const [shouldSeeStyles, setShouldSeeStyles] = useState(true);
  const [shouldSeeCustom, setShouldSeeCustom] = useState(true);
  const resetStoreFilters = useResetRecoilState(storeListFiltersAtom);
  const [styleToRemove, setStyleToRemove] = useState<any | undefined>(undefined);
  const [styleModalOpen, setStyleModalOpen] = useState(false);
  const [styleToEdit, setStyleToEdit] = useState<any | undefined>(undefined);
  const { country } = useEnums();
  const [savePromptVisible, setSavePromptVisible] = useState(false);
  const useBrowserBack = queryGet("useBrowserBack") === "true";
  const [poaValuesFetchedByFilters, setPoaValuesFetchedByFilters] = useState<any>(undefined);
  // initial should be undefined because otherwise it will redirect to poa review
  const [usingQueryFilters, setUsingQueryFilters] = useState<boolean | undefined>(undefined);
  const [filterListFlag, setFilterListFlag] = useState(0);

  const queryFilters = useMemo(() => {
    return {
      poa_category_id: queryGet("poa_category_id"),
      division: queryGet("poa_category_division"),
      department: queryGet("poa_category_department"),
      channel: queryGet("channel"),
      poa_name: queryGet("poa_category_name"),
      storeIds: queryGet("store_ids"),
    };
  }, []);

  useEffect(() => {
    // decide if using query filters
    if (queryFilters?.poa_category_id && queryFilters?.storeIds) {
      setUsingQueryFilters(true);
    } else {
      setUsingQueryFilters(false);
    }
  }, [queryFilters]);

  useEffect(() => {
    const isTouched = !!poaEditing?.poaValues?.touched;
    if (isTouched) {
      setUpdatesDone((prev) => prev + 1);
    }
    if (!poaEditing?.poa?.[0]?.id && usingQueryFilters === false) {
      // if not using query filters and no poa id, redirect to poa review
      if (useBrowserBack) {
        history.goBack();
      } else {
        history.push(paths.poa_review);
      }
    } else if (usingQueryFilters === true) {
      // if using query filters
      // Get poa list filtered by query filters storeIds
      getPOAList(
        poaList, setPoaList, queryFilters?.channel as IChannelTypes, queryFilters?.storeIds?.split(","),
      );
      // Get poa values for specific department / division
      getGroupedPOAs(
        poaValuesFetchedByFilters,
        setPoaValuesFetchedByFilters,
        { department: queryFilters?.department, division: queryFilters?.division },
        queryFilters?.channel as IChannelTypes,
      );
    } else if (usingQueryFilters !== undefined) {
      // if not using any query filters
      filterListAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usingQueryFilters, queryFilters]);

  useEffect(() => {
    const poaKey = `${queryFilters?.department}_${queryFilters?.division}`;
    const fetchedPoa = poaValuesFetchedByFilters?.[poaKey];
    if (usingQueryFilters && queryFilters?.division && queryFilters?.department
      && poaList?.status === "success" && fetchedPoa?.status === "success") {
      const mergedValues = mergeObjectIntoArray(
        poaList?.channelStoreList,
        fetchedPoa?.data,
        "id",
        "store",
        "store_id",
        { poa_key: poaKey, poa_values: [] },
      );
      const selectedPOADivision = poaList?.data?.find((poaDiv: any) => poaDiv.key === queryFilters?.division)?.data;
      const selectedPOADepartment = selectedPOADivision?.find((poaDep: any) => poaDep.key === queryFilters?.department)?.data;
      const selectedPOACategory = queryFilters?.poa_category_id
        ? [selectedPOADepartment?.find((poaCat: any) => queryFilters?.poa_category_id?.toString() === poaCat.id?.toString())]
        : selectedPOADepartment;
      setPoaEditing({ poa: selectedPOACategory, poaValues: { status: "success", data: mergedValues } });
      setInitPoaValues({ status: "success", data: mergedValues });
      // Increase flag to filter list after setPoaEditing change because of async behaviour
      setFilterListFlag((prev) => prev + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usingQueryFilters, poaValuesFetchedByFilters, queryFilters, poaList?.status]);

  useEffect(() => {
    // if poaEditing changes because of queryParams, filter list again
    if (filterListFlag > 0) {
      filterListAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterListFlag]);

  useEffect(() => {
    setInitPoaValues(poaEditing?.poaValues);

    return () => {
      setPoaEditing({ poa: [], poaValues: {} });
    };
  }, []);

  const filteredListLength = useMemo(() => {
    return Object.keys(filteredList || []).length;
  }, [filteredList]);

  const poaValuesEditingLength = useMemo(() => {
    return Object.keys(poaEditing?.poaValues?.data || {})?.length;
  }, [poaEditing]);

  const onFilterByStatus = (status?: "PLANNING" | "LIVE" | "PAUSED") => {
    if (appliedFilters?.status?.indexOf(status) > -1) {
      setAppliedFilters({ ...appliedFilters, status: appliedFilters?.status?.filter((s: any) => s !== status) });
    } else {
      setAppliedFilters({ ...appliedFilters, status: [...appliedFilters?.status, status] });
    }
  };

  const filterListAction = () => {
    setFiltersExpanded(false);
    setFilteredList(Object.values(poaEditing?.poaValues?.data || {})?.filter((pV: any) => {
      let shouldReturn = true;
      if (queryFilters?.storeIds?.length > 0) {
        if (!queryFilters?.storeIds?.split(",")?.includes(pV?.store?.id?.toString())) {
          shouldReturn = false;
        }
      }
      if (appliedFilters?.location && pV?.store?.country !== appliedFilters?.location) {
        shouldReturn = false;
      }
      if (appliedFilters?.status?.length > 0 && appliedFilters?.status?.length !== 3) {
        if (!appliedFilters?.status?.includes(pV?.store?.status)) {
          shouldReturn = false;
        }
      }
      return shouldReturn;
    })?.map((each: any) => each.store_id));
  };

  const getColumns = useMemo(() => {
    return columns({
      handleEditStoreClick: (storeId: any) => {
        resetStoreFilters();
        setSelectedStore(storeId);
        history.push(`/stores/${storeId}/edit?useBrowserBack=true`);
      },
      handleRemoveSisterStoreClick: (storeId: any) => setSisterStoreDeleteId(storeId),
      editable: true,
      isFrozenLeft: false,
      channelStoreList: poaList.channelStoreList,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variance, poaList.channelStoreList, poaList?.status]);

  const handleRemovePOAStyle = (style: any) => {
    message.success(`Style '${style?.name}' successfully removed`);
    setStyleToRemove(undefined);
  };

  const appendDynamicColumns = (cols: any, poaDynamicColumns: IPoaEntry[]) => {
    const newColumns = [...cols];
    const columnsToAdd = [
      ...(shouldSeeClasses ? poaDynamicColumns?.filter((e) => e.is_class && !e.is_custom) : []),
      ...(shouldSeeStyles ? poaDynamicColumns?.filter((e) => !e.is_class && !e.is_custom) : []),
      ...(shouldSeeCustom ? poaDynamicColumns?.filter((e) => e.is_custom) : []),
    ];
    for (let i = 0; i < columnsToAdd?.length; i += 1) {
      const columnName = columnsToAdd?.[i].name;
      const columnId = +columnsToAdd?.[i].id;
      const isClassColumn = columnsToAdd?.[i].is_class && !columnsToAdd?.[i].is_custom;
      const isStyleColumn = !columnsToAdd?.[i].is_class && !columnsToAdd?.[i].is_custom;
      const isCustomColumn = columnsToAdd?.[i].is_custom;

      const menu = !isCustomColumn ? undefined : (
        <div className="column-header-menu">
          <TableDropdownMenuComponent
            functions={{
              handleModifyPOAStyle: () => {
                setStyleToEdit(columnsToAdd?.[i]);
                setStyleModalOpen(true);
              },
              // handleRemovePOAStyle: () => setStyleToRemove(columnsToAdd?.[i]), // @TODO: temporarily disabled (coming soon)
            }}
            rowData={{
              style_id: columnId,
            }}
            dotsColor="#ffffff"
          />
        </div>
      );

      newColumns.push({
        key: `${columnId.toString()}_${columnName}`,
        dataIndex: columnName,
        title: !isCustomColumn ? columnName : (
          <div>
            {columnName.length > 30 ? `${columnName.substring(0, 30)}...` : columnName}
            {" "}
            {menu}
          </div>
        ),
        width: 136,
        flexGrow: 1,
        align: "center",
        className: `poa-editable-cell ${isClassColumn ? "class" : ""} ${isCustomColumn ? "custom" : ""} ${isStyleColumn ? "style" : ""}`,
        cellRenderer: ({ rowData }: { rowData: any }) => {
          const currentField = rowData?.poa_values.find((s: IPoaValue) => (s.poa_category_id === columnId));

          const isFlagged = currentField?.poa_qty && Math.abs(((currentField?.updated / currentField?.poa_qty) * 100) - 100)
            > parseInt(variance || "0%".replace("%", ""), 10);

          const isUpdated = Number(currentField?.updated).toFixed(2) !== Number(currentField?.poa_qty).toFixed(2);

          const updateValue = (v: string) => {
            setActionDisabled?.(false);
            setPoaEditing((prev: any) => {
              // Deep clone the state. Modify matched poa value for store and poa category and keep it saved in local storage
              const cloneState = JSON.parse(JSON.stringify(prev));
              const poaValuesState = cloneState.poaValues;
              const clonedPoaValues = poaValuesState?.data?.[rowData?.store_id];
              let matchedPoaValueObj = clonedPoaValues?.poa_values?.find((pVal: any) => pVal.poa_category_id === columnId);
              poaValuesState.touched = true; // for prompt

              // Creating new entry
              if (!clonedPoaValues) {
                poaValuesState.data[rowData?.store_id] = rowData;
                matchedPoaValueObj = rowData?.poa_values?.find((pVal: any) => pVal.poa_category_id === columnId);
              }

              if (!matchedPoaValueObj) {
                poaValuesState.data[rowData?.store_id]?.poa_values?.push({
                  id: null,
                  poa_category_id: columnId,
                  poa_qty: "",
                  updated: v,
                });
              } else {
                matchedPoaValueObj.updated = v;
              }

              return cloneState;
            });
            setUpdatesDone((prev) => prev + 1);
          };

          return (
            <div className={`poa-overview-table-cell ${isFlagged ? "flagged" : ""} ${isUpdated ? "updated" : ""}`}>
              <EditableCell
                editable
                cellData={isUpdated ? currentField?.updated : currentField?.poa_qty}
                hasButtons={false}
                allowSameValueSubmit
                onOk={updateValue}
                viewModeTooltip={isUpdated ? `Initial Value: ${currentField?.poa_qty || "Empty"}` : undefined}
                min={0}
                max={1000000}
                cancelOnBlur
                allowEmpty={!currentField?.poa_qty}
              />
            </div>
          );
        },
      });
    }

    return newColumns;
  };

  const handleRemoveSisterStore = async () => {
    setDeleteSSLoading(true);
    await updateStore(
      { id: sisterStoreDeleteId, sister_store_id: null }, storeList, setStoreList,
    );
    setDeleteSSLoading(false);
    setSisterStoreDeleteId(undefined);
  };

  const poaDataValues = useMemo(() => {
    return Object.values(poaEditing?.poaValues?.data || {})?.filter((dt: any) => {
      return filteredList?.find((fL: any) => fL === dt.store_id);
    })?.sort((a: any, b: any) => {
      if (a.store?.name > b.store?.name) return 1;
      if (b.store?.name > a.store?.name) return -1;
      return 0;
    });
  }, [poaEditing, filteredList]);

  const clearPoaValuesEditingFromList = (poaKey: string) => {
    setPoaValues((prev: any) => {
      const updatedPoas = JSON.parse(JSON.stringify(prev));
      delete updatedPoas[poaKey];
      return updatedPoas;
    });
    if (updatesDone > 0) {
      updateCheckedList(poaKey, true);
    }
  };

  const handleSavePoaValuesChanges = async () => {
    setSavePromptVisible(false);
    setLoading(true);
    const poaKey = `${poaEditing?.poa?.[0]?.department}_${poaEditing?.poa?.[0]?.division}`;
    const poaValuesToSend = poaEditing?.poaValues;
    await updatePOAs(
      [poaValuesToSend], [poaKey], [resetPoaEditing, () => clearPoaValuesEditingFromList(poaKey)],
    );
    setLoading(false);
    setTimeout(() => {
      if (useBrowserBack) {
        history.goBack();
      } else {
        history.push(paths.poa_review);
      }
    }, 300);
  };

  const updateCheckedList = (poaKey: string, isRemove?: boolean) => {
    if (isRemove) {
      setCheckedList((prev: any) => prev.filter((item: any) => item !== poaKey));
    } else {
      setCheckedList((prev: any) => {
        const newList = [...prev];
        if (prev.indexOf(poaKey) === -1) {
          newList.push(poaKey);
        }
        return newList;
      });
    }
  };

  const handleDiscardPoaValuesChanges = () => {
    setPoaEditing({ poa: poaEditing?.poa, poaValues: initPoaValues });
    setDiscardModalVisible(false);
    setUpdatesDone(0);
  };

  const handleSubmitStyle = async (styleValues?: any, isAdd?: boolean) => {
    const poaKey = `${poaEditing?.poa?.[0]?.department}_${poaEditing?.poa?.[0]?.division}`;
    // Call function depending on isAdd parameter (add or update)
    await (isAdd ? createCustomCategoryPOA : updateCustomCategoryPOA)(styleValues, [
      () => clearPoaValuesEditingFromList(poaKey),
      () => {
        setTimeout(() => {
          if (useBrowserBack) {
            history.goBack();
          } else {
            history.push(paths.poa_review);
          }
        }, 300);
      },
    ]);
  };

  const isLoading = useMemo(() => {
    return poaList?.status === "request" || poaList?.status === "revalidate" || poaValuesFetchedByFilters?.status === "request";
  }, [poaList, poaValuesFetchedByFilters]);

  const pageTitle = useMemo(() => {
    return isLoading ? "Loading..."
      : `POA: ${decideDivisionName(queryFilters?.division || poaEditing?.poa?.[0]?.division)} ${decideDepartmentName(
        queryFilters?.department || poaEditing?.poa?.[0]?.department, false, "Custom",
      )}`;
  }, [poaEditing, queryFilters, isLoading]);

  return (
    <div className="poa-values-edit-page">
      <div className="title-wrap">
        <PageTitle
          title={pageTitle}
          tabTitle={`POA: ${pageTitle}`}
          onBack={() => {
            if (useBrowserBack) {
              history.goBack();
            } else {
              history.push(paths.poa_review);
            }
          }}
        />
      </div>
      <div className={`poa-values-filters ${filtersExpanded ? "open" : ""}`}>
        <div className="container-content">
          <div className="top">
            <div className="left">
              <Button
                type="ghost"
                onClick={() => setFiltersExpanded(!filtersExpanded)}
              >
                <Icon
                  name="Filters"
                  size={18}
                  color="#333E47"
                />
                Filters
              </Button>
              <div className="vertical-splitter" />
              <div className="filtered-store-counter">
                {`${filteredListLength} stores${filteredListLength !== poaValuesEditingLength
                  ? ` (${poaValuesEditingLength - filteredListLength} hidden)`
                  : ""}
             `}
              </div>
            </div>
            <div className="right">
              {filtersExpanded ? (
                <Button
                  className="close-button"
                  onClick={() => setFiltersExpanded(false)}
                >
                  <Icon
                    name="Close"
                    color="#000000"
                    size={14}
                  />
                </Button>
              ) : (
                <>
                  {usingQueryFilters ? (
                    <Tooltip
                      overlay={(
                        <div>
                          POA Category:
                          {" "}
                          {queryFilters?.poa_name}
                          <br />
                          Division:
                          {" "}
                          {queryFilters?.division}
                          <br />
                          Department:
                          {" "}
                          {queryFilters?.department || "Custom"}
                          <br />
                          Channel:
                          {" "}
                          {queryFilters?.channel}
                          <br />
                          Stores Included:
                          {" "}
                          {queryFilters?.storeIds?.split(",")?.length || 0}
                        </div>
                      )}
                      placement="left"
                      trigger={["click"]}
                    >
                      <DartButton
                        icon="Filters"
                        label="Advanced Filters Applied"
                        type="primary"
                        style={{ marginRight: 10 }}
                      />
                    </Tooltip>
                  ) : (
                    <DartButton
                      className="add-style-button"
                      icon="Plus"
                      label="Add Style"
                      type="default"
                      onClick={() => setStyleModalOpen(true)}
                    />
                  )}
                  {updatesDone > 0 && (
                  <DartButton
                    className="discard-changes-button"
                    onClick={() => setDiscardModalVisible(true)}
                    label="Discard Changes"
                  />
                  )}
                  <DartButton
                    disabled={actionDisabled || updatesDone === 0 || loading}
                    loading={loading}
                    onClick={() => setSavePromptVisible(true)}
                    label="Save Changes"
                  />
                </>
              )}
            </div>
          </div>
          <div className="bottom">
            <div className="filter-input-wrapper">
              <DartSelect
                label="Location"
                placeholder="All"
                selectOptions={Object.values(country).map((item) => { return { label: countryCodeToName(item), value: item }; })}
                onChange={(val) => {
                  setAppliedFilters({ ...appliedFilters, location: val });
                }}
                canClear
                showSearch={false}
              />
              <div className="status-selector">
                <span className="label">Store Status</span>
                <div className="checkbox-group-wrap">
                  <DartCheckbox
                    onChange={() => onFilterByStatus("PLANNING")}
                    size="md"
                    checked={appliedFilters?.status?.indexOf("PLANNING") > -1}
                    label="Planning"
                  />
                  <DartCheckbox
                    onChange={() => onFilterByStatus("LIVE")}
                    size="md"
                    checked={appliedFilters?.status?.indexOf("LIVE") > -1}
                    label="Live"
                  />
                  <DartCheckbox
                    onChange={() => onFilterByStatus("PAUSED")}
                    size="md"
                    checked={appliedFilters?.status?.indexOf("PAUSED") > -1}
                    label="Paused"
                  />
                </div>
              </div>
            </div>
            <hr className="filters-splitter" />
            <DartButton
              size="lg"
              label="APPLY FILTERS"
              onClick={filterListAction}
              className="apply-filters-button"
            />
          </div>
        </div>
      </div>
      <div className={`poa-values-edit-table ${filtersExpanded ? "condense" : ""}`}>
        <DartPrompt
          title="Unassign Sister Store?"
          content={(
            <div>This store will no longer receive new POA updates from it’s sister store.</div>
          )}
          okText="Unassign Sister Store"
          cancelText="Cancel"
          visible={!!sisterStoreDeleteId}
          okButtonProps={{ loading: storeList.status === "request" || deleteSSLoading }}
          onCancel={() => setSisterStoreDeleteId(undefined)}
          onOk={() => handleRemoveSisterStore()}
        />
        <DartPrompt
          title="Discard Existing Changes?"
          content={(
            <div>Changes since you got on this page will be reverted to original</div>
          )}
          okText="Confirm"
          cancelText="Cancel"
          visible={discardModalVisible}
          onCancel={() => setDiscardModalVisible(false)}
          onOk={() => handleDiscardPoaValuesChanges()}
        />
        <DartPrompt
          title={`Remove '${styleToRemove?.name}'`}
          content={(
            <div>
              This style will be permanently removed from
              <br />
              the database
              <hr className="splitter" />
              <div className="list">
                {
                  [{
                    id: faker("number", 1000000),
                    name: faker("string", 30)?.toUpperCase(),
                  }, {
                    id: faker("number", 1000000),
                    name: faker("string", 20)?.toUpperCase(),
                  }, {
                    id: faker("number", 1000000),
                    name: faker("string", 15)?.toUpperCase(),
                  }, {
                    id: faker("number", 1000000),
                    name: faker("string", 25)?.toUpperCase(),
                  }]?.map((style: any) => {
                    return (
                      <p>
                        {style?.id}
                        {" • "}
                        {style?.name}
                      </p>
                    );
                  })
                }
              </div>
            </div>
          )}
          okText="Remove"
          cancelText="Cancel"
          visible={!!styleToRemove}
          okButtonProps={{ loading: false }}
          onCancel={() => setStyleToRemove(undefined)}
          onOk={() => handleRemovePOAStyle(styleToRemove)}
          icon="Delete"
        />
        <DartTable
          key={`${filterListFlag?.toString()}_key`}
          loading={isLoading || storeList.status === "request"}
          tableStyle="dark"
          height={filtersExpanded ? "calc(100vh - 530px)" : "calc(100vh - 314px)"}
          rowKey="store_id"
          width="100%"
          data={poaDataValues || []}
          checkboxSize="sm"
          columns={appendDynamicColumns(getColumns, poaEditing?.poa)}
          fixed
          emptyRenderer={() => <div style={{ padding: "48px calc(50% - 129px)" }}>POAs with selected filters is empty</div>}
          headerHeight={[34, 34]}
          headerRenderer={(p: any) => renderPOATableHeader(p, {
            shouldSeeClasses,
            shouldSeeStyles,
            shouldSeeCustom,
            setShouldSeeClasses,
            setShouldSeeStyles,
            setShouldSeeCustom,
          })}
        />
        <CustomCategoryPOA
          open={styleModalOpen}
          setOpen={setStyleModalOpen}
          selectedStyle={styleToEdit}
          setSelectedStyle={setStyleToEdit}
          onOK={handleSubmitStyle}
        />
        <DartPrompt
          title="Are you sure ?"
          content={(
            <div>
              This will also change IAQ settings for CCs.
              <br />
              Are you sure you want to proceed?
            </div>
          )}
          okText="Proceed"
          cancelText="Cancel"
          visible={savePromptVisible}
          onCancel={() => setSavePromptVisible(false)}
          onOk={handleSavePoaValuesChanges}
        />
      </div>
    </div>
  );
};

export default PoaValuesEdit;
