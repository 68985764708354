import React, { useEffect, useState } from "react";
import "./index.less";
import { useRecoilState } from "recoil";
import { Form } from "antd";
import { apiLogsAtom } from "../../../../global/atoms";
import PageTitle from "../../../../components/PageTitle";
import { getApiLogs } from "../../services/logs";
import { ApiLogsListView } from "../..";
import CompactSelect from "../../../../components/CompactSelect";
import { removeNullValues } from "../../../../utils/helpers";

const NewnessUploadListLayout = () => {
  const [apiLogs, setApiLogs] = useRecoilState(apiLogsAtom);
  const [tableData, setTableData] = useState<any>([]);
  const [initFetch, setInitFetch] = useState(false);
  const [form] = Form.useForm();
  const [filters, setFilters] = useState<any>({});
  const [availableFilters, setAvailableFilters] = useState<any>({});

  // Get list of api logs
  useEffect(() => {
    if (!initFetch) {
      // Call Service that gets api logs
      getApiLogs(apiLogs, setApiLogs);
      setInitFetch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initFetch]);

  // Re-occurring useEffect that fetches the list again and again every 20 seconds to have updated data
  useEffect(() => {
    const interval = setInterval(() => {
      getApiLogs(
        apiLogs, setApiLogs, true,
      );
    }, 20000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiLogs]);

  // Fill available filters
  useEffect(() => {
    if (apiLogs?.status === "success" && apiLogs?.data?.length > 0) {
      const aF = apiLogs?.data?.reduce((acc: any, curr: any) => {
        Object.keys(curr).forEach((key) => {
          if (["method", "api_type", "status_code", "content_type"].indexOf(key) > -1) {
            if (!acc[key]) {
              acc[key] = [];
            }
            if (!acc[key].includes(curr[key])) {
              acc[key].push(curr[key]);
            }
          }
        });
        return acc;
      }, {});
      setAvailableFilters(aF);
    }
  }, [apiLogs]);

  // Filter data according to selected filters and prepares them to be accepted by the table
  useEffect(() => {
    // Filter data according to selected filters
    const filteredData = apiLogs?.data?.filter((dt: any) => {
      let include = true;
      Object.keys(filters).forEach((key) => {
        if (filters[key] && filters[key] !== dt[key]) {
          include = false;
        }
      });
      return include;
    })?.sort((a: any, b: any) => {
      return b.id - a.id;
    });
    setTableData(filteredData);
  }, [apiLogs?.data, filters]);

  // Handler to set filter according to chosen status
  const handleFieldChange = (field: any) => {
    const name = field?.[0]?.name?.[0];
    const value = field?.[0]?.value;
    setFilters((prev: any) => { return removeNullValues({ ...prev, [name]: value }); });
  };

  return (
    <div className="api-logs-layout">
      <PageTitle
        title="API Logs"
        icon="Clip"
      />

      <div
        className="api-logs-list-filters"
      >
        <Form
          form={form}
          onFieldsChange={handleFieldChange}
        >
          <div className="api-logs-list-filters-wrapper">
            <Form.Item name="method">
              <CompactSelect
                placeholder="Method"
                selectOptions={availableFilters?.method?.map((method: string) => { return { value: method, label: method.toUpperCase() }; })}
              />
            </Form.Item>
            <Form.Item name="api_type">
              <CompactSelect
                placeholder="API Type"
                selectOptions={availableFilters?.api_type?.map((type: string) => { return { value: type, label: type }; })}
              />
            </Form.Item>
            <Form.Item name="status_code">
              <CompactSelect
                placeholder="Status Code"
                selectOptions={availableFilters?.status_code?.map((code: string) => { return { value: code, label: code }; })}
              />
            </Form.Item>
          </div>
        </Form>
      </div>
      <hr style={{ marginTop: 28 }} />
      <div>
        <ApiLogsListView
          apiLogs={tableData}
          loading={apiLogs.status === "request"}
        />
      </div>
    </div>
  );
};

export default NewnessUploadListLayout;
