import React, { useEffect, useState } from "react";
import "./index.less";
import {
  useRecoilState, useRecoilValue, useResetRecoilState,
} from "recoil";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import {
  selectedUserAtom, userAtom, userListAtom,
} from "../../../../global/atoms";
import PageTitle from "../../../../components/PageTitle";
import UserListCards from "../../widgets/UserListCards";
import paths from "../../../../configs/paths";
import { SUPER_ADMIN } from "../../../common/widgets/CanAccess";
import { getUsers } from "../../services/user";
import DartPageTitleSearch from "../../../../components/DartPageTitleSearch";

const UserListLayout:React.FC = () => {
  const history = useHistory();
  const authUser = useRecoilValue(userAtom);
  const [users, setUsers] = useRecoilState(userListAtom);
  const resetSelectedUser = useResetRecoilState(selectedUserAtom);
  const [searchValue, setSearchValue] = useState<string | undefined>();

  useEffect(() => {
    resetSelectedUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUsers(users, setUsers);
    // eslint-disable-next-line
  }, []);

  const handleCreateUser = () => {
    history.push(paths.user_create);
  };

  const handleEditUser = (user: any) => {
    if (user.role === SUPER_ADMIN && authUser?.role !== SUPER_ADMIN) {
      message.warning("You are not authorized to edit this user");
    } else {
      history.push(paths.user_edit.replace(":username", user.username));
    }
  };

  return (
    <div className="user-list-layout">
      <PageTitle
        title="user management"
        rightNode={(
          <DartPageTitleSearch
            placeholder="Search (name | email)"
            onSearch={(v) => setSearchValue(v || "")}
          />
        )}
      />
      <UserListCards
        users={users}
        onCreate={handleCreateUser}
        onUpdate={handleEditUser}
        searchValue={searchValue}
      />
    </div>
  );
};

export default UserListLayout;
