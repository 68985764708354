import React from "react";
import { MinusOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import Icon from "../../../../components/Icon";
import EditableCell from "../../../../components/EditableCell";
import TableDropdownMenuComponent from "../../../../components/TableDropdownMenuComponent";
import { decideStoreStatusColor } from "../../../../utils/helpers";

/*
  Data Structure: {
    WOH: 8
    children: Array(3) [ {…}, {…}, {…} ]
    finalInventory: 5
    final_inventory: undefined
    last4WeeksSales: 683
    lastWeekSales: 1
    last_4_week_sales: undefined
    last_week_sales: undefined
    sendMore: 9
    send_more: undefined
    sizes: Array(5) [ {…}, {…}, {…}, … ]
    store: Object { id: 358, name: "imperdiet" }
    storeInventory: Object { OH: 8, IT: 5, OO: 2, TTL: 15 }
    "target-woh": undefined
    targetWOH: 4
    woh: undefined
}
*/

const columns = (props: {
  onSendMoreChange?: (value: number, rowData: any) => void;
  onExcludeToggle?: (excluded: boolean, rowData: any) => void;
}): Record<string, any>[] => [
  {
    key: "store",
    dataIndex: "store",
    title: "Store",
    width: 250,
    flexGrow: 1,
    align: "center",
    className: (row: any) => {
      if (row?.rowData?.store?.excluded) {
        return "replenish-cc-details-cell-parent store-details";
      }
      return "replenish-cc-details-cell-parent white-background store-details";
    },
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="replenish-cc-details-cell">
          <div className="left">
            <span className="store-number">{rowData?.store?.number}</span>
            <span className="vertical-splitter" />
            <span className="store-name">{rowData?.store?.name}</span>
            {rowData?.store?.sister_store?.number && (
            <Dropdown
              destroyPopupOnHide
              trigger={["hover"]}
              overlay={(
                <span className="table-row-menu-overlay sister-store">
                  <Icon
                    name="Branch"
                    size={12}
                    color="#000000"
                  />
                  <span className="store-number">{rowData?.store?.sister_store?.number}</span>
                  <span className="vertical-splitter" />
                  <span className="store-name">{rowData?.store?.sister_store?.name}</span>
                </span>
                  )}
            >
              <Icon
                name="Branch"
                size={12}
                color="#000000"
                style={{ marginTop: 6 }}
              />
            </Dropdown>
            )}
            {!rowData?.store?.excluded && (
            <span
              className="store-status"
              style={{ backgroundColor: decideStoreStatusColor(rowData?.store?.status) }}
            />
            )}
            <TableDropdownMenuComponent
              rowData={rowData}
              functions={{
                onExcludeToggle: props.onExcludeToggle,
              }}
              overlayClassName="exclude-store-action-dropdown"
            />
          </div>
        </div>
      );
    },
  },
  {
    key: "lastWeekSales",
    dataIndex: "lastWeekSales",
    title: "Last Wk Sls",
    width: 112,
    flexGrow: 1,
    align: "center",
    className: "replenish-cc-details-cell-parent",
    cellRenderer: ({ rowData }: any) => {
      if (rowData?.store?.excluded) {
        return valueOrNA(false);
      }
      return (
        <div className="replenish-cc-details-cell">
          {valueOrNA(rowData?.lastWeekSales)}
        </div>
      );
    },
  },
  {
    key: "last4WeeksSales",
    dataIndex: "last4WeeksSales",
    title: "Last 4 Wk Sls Avg",
    width: 126,
    flexGrow: 1,
    align: "center",
    className: "replenish-cc-details-cell-parent",
    cellRenderer: ({ rowData }: any) => {
      if (rowData?.store?.excluded) {
        return valueOrNA(false);
      }
      return (
        <div className="replenish-cc-details-cell">
          {valueOrNA(rowData?.last4WeeksSales)}
        </div>
      );
    },
  },
  {
    key: "woh",
    dataIndex: "woh",
    title: "WOH",
    width: 59,
    flexGrow: 1,
    align: "center",
    className: "replenish-cc-details-cell-parent",
    cellRenderer: ({ rowData }: any) => {
      if (rowData?.store?.excluded) {
        return valueOrNA(false);
      }
      return (
        <div className="replenish-cc-details-cell">
          {valueOrNA((Number.isFinite(rowData.woh) ? rowData.woh : 0), true)}
        </div>
      );
    },
  },
  {
    key: "storeInventory",
    dataIndex: "storeInventory",
    title: "Store Inv",
    width: 246,
    flexGrow: 1,
    align: "center",
    className: "replenish-cc-details-cell-parent with-sub-cells",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="replenish-cc-details-cell with-sub-cells">
          <div className="sub-cells">
            <div className="sub-cell">
              {!rowData?.store?.excluded && <span>OH</span>}
              <span>
                {rowData?.store?.excluded ? valueOrNA(false) : valueOrNA(rowData?.storeInventory?.OH)}
              </span>
            </div>
            <div className="sub-cell">
              {!rowData?.store?.excluded && <span>IT</span>}
              <span>
                {rowData?.store?.excluded ? valueOrNA(false) : valueOrNA(rowData?.storeInventory?.IT)}
              </span>
            </div>
            <div className="sub-cell">
              {!rowData?.store?.excluded && <span>OO</span>}
              <span>
                {rowData?.store?.excluded ? valueOrNA(false) : valueOrNA(rowData?.storeInventory?.OO)}
              </span>
            </div>
            <div className="sub-cell">
              {!rowData?.store?.excluded && <span>TTL</span>}
              <span>
                {rowData?.store?.excluded ? valueOrNA(false) : valueOrNA(rowData?.storeInventory?.TTL)}
              </span>
            </div>
          </div>
        </div>
      );
    },
  },
  {
    key: "sendMore",
    dataIndex: "sendMore",
    title: "Send More",
    width: 100,
    flexGrow: 1,
    align: "center",
    className: (row: any) => {
      if (row?.rowData?.store?.excluded) {
        return "replenish-cc-details-cell-parent";
      }
      return "replenish-cc-details-cell-parent white-background";
    },
    cellRenderer: ({ rowData }: any) => {
      if (rowData?.store?.excluded) {
        return valueOrNA(false);
      }
      return (
        <div className="replenish-cc-details-cell">
          <EditableCell
            cellData={Math.ceil(rowData?.sendMore || 0)}
            onOk={(v) => props?.onSendMoreChange?.(v, rowData)}
            hasButtons={false}
            allowDecimals={false}
            editable
            min={0}
            max={9999999}
            allowEmpty={false}
            canBeEmpty={false}
            cancelOnBlur
          />
        </div>
      );
    },
  },
  {
    key: "finalInventory",
    dataIndex: "finalInventory",
    title: "Final Inv",
    width: 94,
    flexGrow: 1,
    align: "center",
    className: "replenish-cc-details-cell-parent",
    cellRenderer: ({ rowData }: any) => {
      if (rowData?.store?.excluded) {
        return valueOrNA(false);
      }
      return (
        <div className="replenish-cc-details-cell">
          {valueOrNA(rowData?.finalInventory)}
        </div>
      );
    },
  },
  {
    key: "targetWOH",
    dataIndex: "targetWOH",
    title: "Target WOH",
    width: 112,
    flexGrow: 1,
    align: "center",
    className: "replenish-cc-details-cell-parent",
    cellRenderer: ({ rowData }: any) => {
      if (rowData?.store?.excluded) {
        return valueOrNA(false);
      }
      return (
        <div className="replenish-cc-details-cell">
          {valueOrNA(rowData.targetWOH)}
        </div>
      );
    },
  },
  {
    key: "id",
    dataIndex: "id",
    title: "",
    width: 180,
    flexShrink: 4,
    align: "right",
    className: "replenish-cc-details-cell-parent action-cell",
    cellRenderer: ({ rowData }: any) => {
      if (rowData?.store?.excluded) {
        return "";
      }
      return (
        <div className="replenish-cc-details-cell" />
      );
    },
  },
];

const NAComponent = <MinusOutlined style={{ fontSize: 14, color: "#C3C5C8" }} />;

const valueOrNA = (value: any, excludeZero?: boolean) => (value || (value === 0 && !excludeZero) ? value : NAComponent);

export default columns;
