import React, { useMemo, useState } from "react";
import "./index.less";
import {
  Badge, Button, Spin,
} from "antd";
import Icon from "../../../../components/Icon";
import DartInput from "../../../../components/DartInput";
import { messages } from "../../../../configs/constants";
import { renderTag } from "../../../../utils/helpers";

interface IStoreTags {
  handleCreateTag?: (tag: string) => void;
  handleDeleteTag?: (tag: string) => void;
  handleAddTag?: (tag: string) => void;
  handleRemoveTag?: (tag: string) => void;
  selectedTags?: string[];
  availableTags?: string[];
  copiedFromSister?: boolean;
  loading?: boolean;
}

const StoreTags:React.FC<IStoreTags> = ({
  handleCreateTag,
  handleDeleteTag,
  handleAddTag,
  handleRemoveTag,
  selectedTags = [],
  availableTags = [],
  copiedFromSister,
  loading = false,
}) => {
  const [mode, setMode] = useState<"normal" | "create" | "delete">("normal");
  const [error, setError] = useState(false);
  const [newTag, setNewTag] = useState<string | undefined>(undefined);

  const toggleTag = (tag: string) => {
    const tagExists = selectedTags.find((t) => t === tag);
    if (tagExists) {
      handleRemoveTag?.(tag);
    } else {
      handleAddTag?.(tag);
    }
  };

  const handleNew = (tag?: string) => {
    if (!tag) {
      return;
    }
    const tagExists = availableTags.find((t) => t === tag);
    if (tagExists) {
      setError(true);
    } else if (newTag && newTag !== "") {
      handleCreateTag?.(tag?.replace("#", ""));
      setNewTag(() => "");
    } else {
      setError(true);
    }
  };

  const decideCounterColor = useMemo(() => {
    if (selectedTags?.length > 3) {
      return "#FC4848";
    } if (selectedTags?.length === 0) {
      return "#333E47";
    }
    return "#19945D";
  }, [selectedTags]);

  return (
    <div className={`store-tags ${copiedFromSister ? "disabled" : ""}`}>
      <h2>
        STORE TAGS
        {copiedFromSister ? <span>{messages.will_be_copied_from_sister_store}</span> : ""}
      </h2>
      <div className="tags-wrapper">
        <span
          className="tag-counter"
          style={{
            color: decideCounterColor,
          }}
        >
          <Badge
            dot
            color={selectedTags?.length > 3 ? "#FC4848" : "#19945D"}
          />
          Selected Tags (
          {selectedTags?.length}
          /3)
        </span>
        <div className="tag-header">
          {
            mode === "normal" ? (
              <>
                <Button
                  key="create"
                  onClick={() => setMode("create")}
                >
                  Create new
                  <Icon
                    name="Plus"
                    color="#959A9D"
                    size={14}
                  />
                </Button>
                <Button
                  key="delete"
                  onClick={() => setMode("delete")}
                >
                  Delete tag
                  <Icon
                    name="Delete"
                    color="#959A9D"
                    size={18}
                  />
                </Button>
              </>
            ) : (
              <>
                <Button
                  key="normal"
                  onClick={() => setMode("normal")}
                >
                  Done
                  <Icon
                    name="Check"
                    color="#959A9D"
                    size={18}
                  />
                </Button>
              </>
            )
          }
        </div>
        <hr className="tag-container-splitter" />
        { mode === "create" && (
          <div className={`new-tag-wrapper ${error ? "has-error" : ""}`}>
            <DartInput
              placeholder="Create a new tag"
              transparent
              value={newTag}
              onChange={(e: any) => { setError(false); setNewTag(e.target.value); }}
              action={(value?: string) => handleNew?.(value)}
              actionButton={(
                <Button
                  type="ghost"
                  shape="circle"
                >
                  <Icon
                    name="Plus"
                    color="#333E47"
                    size={14}
                  />
                </Button>
            )}
            />
          </div>
        )}
        <h3>ALL TAGS</h3>
        <div className="tag-container">
          {!loading ? availableTags?.map((tag) => (
            <div
              className={`tag ${mode === "delete" ? "deleting" : ""} ${selectedTags.find((t) => t === tag) ? "selected" : ""}`}
              key={tag}
              onClick={() => {
                if (mode !== "delete") {
                  toggleTag(tag);
                }
              }}
            >
              <span className="tag-text">
                {renderTag(tag)}
                { mode === "delete" && (
                  <Button
                    type="ghost"
                    shape="circle"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleDeleteTag?.(tag);
                    }}
                  >
                    <Icon
                      name="Close"
                      size={10}
                      color="#333E47"
                    />
                  </Button>
                )}
              </span>
            </div>
          )) : <Spin style={{ margin: "0 auto" }} />}
        </div>
      </div>
    </div>
  );
};

export default StoreTags;
