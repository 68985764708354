import React, {
  useEffect, useMemo, useState,
} from "react";
import {
  Avatar, Skeleton, Spin,
} from "antd";
import moment from "moment";
import { createHash } from "crypto";
import Sider from "../../../../components/Sider";
import { IAllocationQuantityCC } from "../../../../global/interfaces";
import decideDivisionName from "../../../../utils/helpers/decideDivisionName";
import decideDepartmentName from "../../../../utils/helpers/decideDepartmentName";
import DartInput from "../../../../components/DartInput";
import DartButton from "../../../../components/DartButton";
import "./index.less";
import { addIAQComment, getIAQComments } from "../../services/allocQ";
import Icon from "../../../../components/Icon";

interface IAllocQCommentsSiderProps {
  cc?: IAllocationQuantityCC;
  setCC?: any;
}

const AllocQCommentsSider:React.FC<IAllocQCommentsSiderProps> = ({
  cc,
  setCC,
}) => {
  const [comments, setComments] = useState<any[]>([]);
  const [commentValue, setCommentValue] = useState<string | undefined>(undefined);
  const [addCommentLoading, setAddCommentLoading] = useState<boolean>(false);
  const [commentsLoading, setCommentsLoading] = useState<boolean>(false);

  // Add comment to IAQ
  const handleAddComment = async () => {
    setAddCommentLoading(true);
    if (commentValue && commentValue?.length > 0) {
      await addIAQComment(
        cc?.newness_id,
        commentValue,
        setComments,
        setCommentsLoading,
      );
      setCommentValue(undefined);
    }
    setAddCommentLoading(false);
  };

  // Get comments for IAQ
  useEffect(() => {
    if (cc?.newness_id) {
      getIAQComments(
        cc?.newness_id, setComments, setCommentsLoading,
      );
    }

    return () => {
      setComments([]);
    };
  }, [cc]);

  // Render comment "sider" title and subtitle which is CC and it's division and department
  const renderHeader = useMemo(() => {
    return (
      <>
        <div className="cc-name">
          {`${cc?.cc}`}
        </div>
        <div className="cc-details">
          {`${decideDivisionName(cc?.division || "loading")} ${decideDepartmentName(cc?.department)}`}
        </div>
      </>
    );
  }, [cc]);

  // Render input and button for sending comments
  const renderFooter = useMemo(() => {
    return (
      <div className="comment-add-wrapper">
        <DartInput
          placeholder="Enter comment here"
          onChange={(val) => setCommentValue(val?.target?.value)}
          value={commentValue}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleAddComment();
            }
          }}
          disabled={addCommentLoading}
        />
        <DartButton
          icon={addCommentLoading ? <Spin size="small" /> : "ArrowRight"}
          type="dark"
          disabled={addCommentLoading}
          onClick={() => handleAddComment()}
        />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cc, commentValue, addCommentLoading]);

  return (
    <Sider
      forId={cc?.newness_id}
      visible={!!cc}
      wrapperClassName="alloc-q-comments-sider"
      openSiderKey="alloc-q-comments-sider"
      resizable
      minWidth={450}
      maxWidth={900}
      onClose={() => setCC(undefined)}
      renderHeader={renderHeader}
      renderFooter={renderFooter}
    >
      <div className="comments-wrapper">
        {commentsLoading && comments?.length === 0 ? (
          <div className="comments-loading">
            <Skeleton.Input active />
            <Skeleton.Input active />
            <Skeleton.Input active />
            <Skeleton.Input active />
            <Skeleton.Input active />
          </div>
        ) : (
          <div className="comments">
            {comments?.length > 0 ? (
              <>
                {
                  comments?.map((comment) => (
                    <div
                      key={comment.id + comment.created_by_email}
                      className="comment"
                    >
                      <div className="left">
                        <Avatar
                          src={`https://robohash.org/${comment?.created_by
                            ? createHash("sha256").update(comment.created_by).digest("hex")
                            : "johndoe"}.png?bgset=bg2`}
                          size="large"
                        />
                      </div>
                      <div className="right">
                        <div className="author">
                          <b>{comment?.created_by_email}</b>
                          {/* <span>{`(${comment?.created_by_email})`}</span> */}
                        </div>
                        <div className="date">
                          {moment(comment?.created_at)?.format("HH:mm MM/DD/YYYY")}
                        </div>
                        <div className="text">
                          {comment?.text}
                        </div>
                      </div>
                    </div>
                  ))
                }
                {
                  commentsLoading && (
                    <div className="comments-loading-single">
                      <Skeleton.Input active />
                    </div>
                  )
                }
              </>
            ) : (
              <div className="empty-comments">
                <Icon
                  name="Messages"
                  size={48}
                />
                <div>
                  No comments found for
                  {" "}
                  <b>{cc?.cc}</b>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Sider>
  );
};

export default AllocQCommentsSider;
