import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import { Modal } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import {
  newnessDashboardFiltersAtom, selectedCCsAtom, storeTiersAtom,
} from "../../../../global/atoms";
import { StoreSelectionTableBlock } from "../..";
import DartButton from "../../../../components/DartButton";
import { arrayUnique } from "../../../../utils/helpers";
import { allocationProcessAtom } from "../../../../global/atoms/allocation-process-atom";
import paths from "../../../../configs/paths";
import { startAllocationProcesses } from "../../services/allocation";

interface IStoreSelection {
  open?: boolean;
  setOpen?: (isOpen: boolean) => void;
}

const StoreSelection:React.FC<IStoreSelection> = ({ open, setOpen }) => {
  const { confirm } = Modal;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [blacklistedCCs, setBlacklistedCCs] = useState<any>([]);
  const selectedCCs = useRecoilValue(selectedCCsAtom);
  const newnessFilters = useRecoilValue(newnessDashboardFiltersAtom);
  const globalFilters = useRecoilValue(newnessDashboardFiltersAtom);
  const storeTiers = useRecoilValue(storeTiersAtom);
  const [allocationProcess, setAllocationProcess] = useRecoilState(allocationProcessAtom);
  const [changedStoreTiers, setChangedStoreTiers] = useState<any>({});
  const [modalOpen, setModalOpen] = useState(false);

  // Change local state of modal visibility according to prop "open"
  useEffect(() => {
    setModalOpen(open || false);
  }, [open]);

  // Handler to set local state of modal visibility to be closed as well as firing prop function "setOpen" with value "false"
  const handleClose = () => {
    setOpen?.(false);
    setModalOpen(false);
  };

  // Memoized value of total number of ccs that are in ready + not ready groups and are selected.
  const ccTotalCount = useMemo(() => {
    return selectedCCs?.ready?.length + selectedCCs?.not_ready?.length;
  }, [selectedCCs]);

  // Merge data of "ready" and "not ready" groups to display them in a single table
  const mergedTableData = useMemo(() => {
    const emptyDataCCs:string[] = [];
    const editedDataCCs:string[] = [];
    Object.keys(changedStoreTiers).forEach((cc) => {
      const tierMerged = arrayUnique((Object.values(changedStoreTiers?.[cc]) || [])?.flat(1));
      if (tierMerged?.length === 0) {
        emptyDataCCs.push(cc);
      }
      if (Object.values(storeTiers.data[cc] || {}).flat(1).length !== tierMerged?.length) {
        editedDataCCs.push(cc);
      }
    });

    // Blacklisted CCs mean they don't qualify to be taken into Review CC step as store list is empty or other criteria not met
    setBlacklistedCCs(emptyDataCCs);

    if ((Object.keys(storeTiers.data || {}) || []).length > 0) {
      // Append Store List to CCs
      const mergedCCs = [...selectedCCs.ready, ...selectedCCs.not_ready];
      return mergedCCs.map((cc) => {
        const hasAtLeast1Store = Object.values(storeTiers.data[cc.cc] || {}).flat(1).length > 0;
        return {
          ...cc,
          // Criteria to be able to be sent to BE: should have at least one store and CC data should not be empty
          canBeSentToBackend: emptyDataCCs.indexOf(cc.cc) === -1 && hasAtLeast1Store,
          isChanged: editedDataCCs.indexOf(cc.cc) !== -1,
          store_list: hasAtLeast1Store ? storeTiers.data[cc.cc] : {
            special: [], tier1: [], tier2: [], tier3: [], tier4: [],
          },
          // If CC has been edited, use edited data, otherwise use data from BE for default selected store list by tiers
          selectedStoreList: changedStoreTiers[cc.cc] || {
            special: (storeTiers.data[cc.cc]?.special || [])?.filter((store: any) => store?.is_selected === true),
            tier1: (storeTiers.data[cc.cc]?.tier1 || [])?.filter((store: any) => store?.is_selected === true),
            tier2: (storeTiers.data[cc.cc]?.tier2 || [])?.filter((store: any) => store?.is_selected === true),
            tier3: (storeTiers.data[cc.cc]?.tier3 || [])?.filter((store: any) => store?.is_selected === true),
            tier4: (storeTiers.data[cc.cc]?.tier4 || [])?.filter((store: any) => store?.is_selected === true),
          },
        };
      });
    }
    return [];
  }, [selectedCCs, storeTiers, changedStoreTiers]);

  // Handler to be able to change selected store values for each cc according to the tier
  const handleStoreTierChange = (
    cc: string, tiers: string[], tier: "special" | "tier1" | "tier2" | "tier3" | "tier4", isInitial?: boolean,
  ) => {
    if ((isInitial && !changedStoreTiers[cc]) || !isInitial) {
      setChangedStoreTiers((prev: any) => {
        return {
          ...prev,
          [cc]: {
            special: prev?.[cc]?.special || [],
            tier1: prev?.[cc]?.tier1 || [],
            tier2: prev?.[cc]?.tier2 || [],
            tier3: prev?.[cc]?.tier3 || [],
            tier4: prev?.[cc]?.tier4 || [],
            [tier]: tiers,
          },
        };
      });
    }
  };

  // Handler that starts loading, gathers data needed to be sent to BE, merges those data, prompts the user if they wish to continue ->
  // -> and if they are sure all information was entered correctly, trigger allocation process for BE with values provided
  const handleReviewAllocation = async () => {
    setLoading(true);
    const ccStoreRequestData:any = {};
    let totalUpdateCount = 0;
    Object.keys(changedStoreTiers).forEach((cc) => {
      const tierMerged = arrayUnique((Object.values(changedStoreTiers?.[cc]) || [])?.flat(1));
      if (mergedTableData.find((mTD) => mTD.cc === cc)?.store_list) {
        const originalStoreCount = (Object.values(mergedTableData.find((mTD) => mTD.cc === cc)?.store_list))?.flat(1)?.length;
        if (tierMerged?.map((store) => store?.store_id || store)?.length > 0) {
          ccStoreRequestData[cc] = tierMerged?.map((store) => store?.store_id || store);
          totalUpdateCount += originalStoreCount - tierMerged.length;
        }
      }
    });
    confirm({
      title: "Confirm Store Selection?",
      content: (
        <>
          <div>
            Do you want to confirm store selection?
          </div>
          {totalUpdateCount > 0 && (
          <div>
            <b>{totalUpdateCount}</b>
            {" "}
            {totalUpdateCount === 1 ? "store is" : "stores are"}
            {" "}
            de-selected.
          </div>
          )}
        </>
      ),
      okText: "Confirm",
      onOk: () => {
        // Call Service to start allocation process according to store list provided and end loading after it's done
        startAllocationProcesses(
          allocationProcess, setAllocationProcess, ccStoreRequestData, {
            ...newnessFilters,
            brand: globalFilters?.brand,
            channel: globalFilters?.channel,
          },
        );
        setLoading(false);
      },
    });
  };

  // Close modal and redirect to next step (Review CC step) if loading finished and allocation is successfully started
  useEffect(() => {
    if (
      !loading
      && allocationProcess?.data?.processId
      && allocationProcess?.status === "success"
      && allocationProcess?.data?.processData?.length > 0
    ) {
      setModalOpen(false);
      history.push(paths.review_ccs);
    }
  }, [history, allocationProcess, loading]);

  return (
    <div className="store-selection-layout">
      <Modal
        visible={modalOpen}
        closable
        wrapClassName="store-selection-modal"
        onCancel={handleClose}
        width="calc(100vw - 128px)"
        footer={false}
        maskStyle={{
          background: "rgba(51, 62, 71, 0.32)",
          backdropFilter: "blur(8px)",
        }}
      >
        <div className="modal-title">
          <h1>Store Selection</h1>
          <h3>
            {ccTotalCount || 0}
            {" "}
            CCs selected
          </h3>
        </div>
        <div className="table-wrapper">
          <DartButton
            size="lg"
            label="Review Stores"
            onClick={handleReviewAllocation}
            loading={allocationProcess.status === "request"}
            disabled={!!allocationProcess?.data?.processId || blacklistedCCs?.length >= mergedTableData?.length}
          />
          <StoreSelectionTableBlock
            tableData={mergedTableData}
            onStoreTierChange={handleStoreTierChange}
          />
        </div>
      </Modal>
    </div>
  );
};

export default StoreSelection;
