const removeNullValues = (obj: any, excludeZero = false) => {
  const objClone: any = JSON.parse(JSON.stringify(obj || {}));

  Object.keys(objClone).forEach((key) => {
    if (excludeZero && objClone[key] === 0) {
      return;
    }
    if (!objClone[key] || (typeof objClone[key] === typeof [] && objClone[key]?.length === 0)) {
      delete objClone[key];
    }
  });

  return objClone;
};

export default removeNullValues;
