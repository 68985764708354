import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import moment from "moment";
import { Modal } from "antd";
import DartTable from "../../../../components/DartTable";
import columns from "./columns";
import { getApiLogDetail } from "../../services/logs";
import { showErrorMessage } from "../../../../utils/helpers";

export interface IApiLogRow {
  id?: number,
  api_type?: string,
  is_inbound?: boolean,
  url?: string,
  method?: "POST" | "GET" | "PUT" | "DELETE" | "PATCH" | "OPTIONS",
  content_type?: string,
  status_code?: number,
  start_time?: string,
  end_time?: string,
  duration?: number,
  created_at?: string,
  loading?: boolean,
}

interface IApiLogsListView {
  apiLogs?: IApiLogRow[];
  loading?: boolean;
}

const ApiLogsListView:React.FC<IApiLogsListView> = ({
  apiLogs,
  loading,
}) => {
  const [logLoadingForId, setLogLoadingForId] = useState<number | undefined>(undefined);
  const { confirm } = Modal;
  const [localLogs, setLocalLogs] = useState<IApiLogRow[]>(apiLogs || []);
  const queryLogId = window.location.hash;

  useEffect(() => {
    if (queryLogId && queryLogId?.length > 0 && queryLogId?.indexOf("#logId=") > -1) {
      try {
        fetchLogDetails(+(queryLogId?.replace("#logId=", "")));
      } catch (e) {
        showErrorMessage("Error while loading log");
        window.location.hash = "";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLocalLogs((apiLogs || []).map((log: IApiLogRow) => ({
      ...log,
      loading: logLoadingForId === log.id,
    })));
  }, [logLoadingForId, apiLogs]);

  // Fetch details and fire download function
  const fetchLogDetails = async (id: number) => {
    setLogLoadingForId(id);
    const logResponse = await getApiLogDetail(id);
    setLogLoadingForId(undefined);
    viewFile(logResponse);
  };

  // Download file in browser (JSON)
  const viewFile = (log: any) => {
    if (typeof log !== typeof {}) {
      showErrorMessage("Error while loading log file");
      setLogLoadingForId(undefined);
    }

    window.location.hash = `#logId=${log?.id?.toString()}`;

    confirm({
      title: `Viewing Log ${log?.id}: ${log?.method?.toUpperCase()} ${log?.api_type}`,
      content: (
        <pre>
          {JSON.stringify(
            log, null, 2,
          )}
        </pre>
      ),
      okText: "Download",
      onOk: () => {
        // create file in browser
        const fileName = `${log?.api_type}-${log?.method?.toUpperCase()}-${moment(log?.start_time).format("HH.mm.ss--DD.MM.YYYY")}-ID-${log?.id}`;
        const json = JSON.stringify(
          log, null, 2,
        );
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = href;
        link.download = `${fileName}.json`;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        setLogLoadingForId(undefined);
      },
      afterClose: () => {
        setLogLoadingForId(undefined);
        window.location.hash = "";
      },
      width: "80vw",
    });
  };

  // Memoized Columns with necessary props provided
  const getColumns = useMemo(() => {
    return columns({ onViewFileClick: (id: number) => fetchLogDetails(id) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="api-logs-list-view">
      <DartTable
        loading={loading}
        columns={getColumns}
        data={localLogs || []}
        width="100%"
        height="calc(100vh - 300px)"
        wrapperClassName="api-logs-table"
        rowKey="id"
        fixed={window.innerWidth <= 1201}
      />
    </div>
  );
};

export default ApiLogsListView;
