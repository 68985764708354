import React from "react";
import { Route, Switch } from "react-router-dom";
import { NotFoundRoute } from "./NotFoundRoute";
import {
  AllocationHistoryLayout,
  AllocationLayout,
  NewnessDashboard,
  NewnessUploadListLayout,
  NewnessUploadListLayoutOld,
  ReviewCCsLayout,
} from "../modules/newness";
import paths from "../configs/paths";
import StoreProfileLayout from "../modules/store/layouts/StoreProfileLayout";
import { StoreListLayout } from "../modules/store";
import {
  SizeProfileDashboard, SizeProfileEdit, SizeProfileReview, SizeRunLayout,
} from "../modules/setup/size-profile";
import {
  PoaDashboard, PoaValuesEdit, PoaValuesReview,
} from "../modules/setup/poa";
import {
  AllocQDashboard, AllocQEdit, AllocQReview,
} from "../modules/initial_allocation_quantity";
import {
  ManualCCSelection,
  ManualReplenishmentStepOne,
  ManualReplenishmentStepTwo,
  ReplenishmentDashboard,
} from "../modules/replenishment";
import { UserLayout, UserListLayout } from "../modules/user";
import CanAccess, { ADMIN, SUPER_ADMIN } from "../modules/common/widgets/CanAccess";
import { ApiLogs } from "../modules/common";

const MainRouter = () => {
  return (
    <Switch>
      <Route
        path={paths.api_logs}
        exact
      >
        <CanAccess
          requireRoles={[SUPER_ADMIN]}
          fallback={<div className="no-access">No Access</div>}
        >
          <ApiLogs />
        </CanAccess>
      </Route>
      <Route
        path={paths.newness}
        exact
      >
        <NewnessDashboard />
      </Route>
      <Route
        path={paths.newness_allocation}
        exact
      >
        <AllocationLayout />
      </Route>
      <Route
        path={paths.newness_allocation_history}
        exact
      >
        <AllocationHistoryLayout />
      </Route>
      <Route
        path={paths.newness_upload}
        exact
      >
        <NewnessUploadListLayout />
      </Route>
      <Route
        path={paths.newness_upload_old}
        exact
      >
        <NewnessUploadListLayoutOld />
      </Route>
      <Route
        path={paths.review_ccs}
        exact
      >
        <ReviewCCsLayout />
      </Route>
      <Route
        path={paths.view_all_stores}
        exact
      >
        <StoreListLayout />
      </Route>
      <Route
        path={paths.new_store}
        exact
      >
        <StoreProfileLayout />
      </Route>
      <Route
        path={paths.edit_store}
        exact
      >
        <StoreProfileLayout />
      </Route>
      <Route
        path={paths.size_profile}
        exact
      >
        <SizeProfileDashboard />
      </Route>
      <Route
        path={paths.size_profile_review}
        exact
      >
        <SizeProfileReview />
      </Route>
      <Route
        path={paths.size_profile_edit}
        exact
      >
        <SizeProfileEdit />
      </Route>
      <Route
        path={paths.new_size_run}
        exact
      >
        <SizeRunLayout />
      </Route>
      <Route
        path={paths.edit_size_run}
        exact
      >
        <SizeRunLayout />
      </Route>
      <Route
        path={paths.poa}
        exact
      >
        <PoaDashboard />
      </Route>
      <Route
        path={paths.poa_review}
        exact
      >
        <PoaValuesReview />
      </Route>
      <Route
        path={paths.poa_edit}
        exact
      >
        <PoaValuesEdit />
      </Route>
      <Route
        path={paths.initial_allocation_quantity_dashboard}
        exact
      >
        <AllocQDashboard />
      </Route>
      <Route
        path={paths.initial_allocation_quantity_edit}
        exact
      >
        <AllocQEdit />
      </Route>
      <Route
        path={paths.initial_allocation_quantity_review}
        exact
      >
        <AllocQReview />
      </Route>
      <Route
        path={paths.replenishment_dashboard}
        exact
      >
        <ReplenishmentDashboard />
      </Route>
      <Route
        path={paths.start_manual_replenishment}
        exact
      >
        <ManualCCSelection />
      </Route>
      <Route
        path={paths.manual_replenishment_step_one}
        exact
      >
        <ManualReplenishmentStepOne />
      </Route>
      <Route
        path={paths.manual_replenishment_step_two}
        exact
      >
        <ManualReplenishmentStepTwo />
      </Route>
      <Route
        path={paths.user_list}
        exact
      >
        <CanAccess
          requireRoles={[SUPER_ADMIN, ADMIN]}
          fallback={<div className="no-access">No Access</div>}
        >
          <UserListLayout />
        </CanAccess>
      </Route>
      <Route
        path={paths.user_create}
        exact
      >
        <CanAccess
          requireRoles={[SUPER_ADMIN, ADMIN]}
          fallback={<div className="no-access">No Access</div>}
        >
          <UserLayout />
        </CanAccess>
      </Route>
      <Route
        path={paths.user_edit}
        exact
      >
        <CanAccess
          requireRoles={[SUPER_ADMIN, ADMIN]}
          fallback={<div className="no-access">No Access</div>}
        >
          <UserLayout />
        </CanAccess>
      </Route>
      <NotFoundRoute />
    </Switch>
  );
};

export default MainRouter;
