import React, { useMemo, useState } from "react";
import "./index.less";
import {
  useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState,
} from "recoil";
import { useHistory } from "react-router-dom";
import { Modal } from "antd";
import PageTitle from "../../../../../components/PageTitle";
import DartButton from "../../../../../components/DartButton";
import paths from "../../../../../configs/paths";
import DartSelect from "../../../../../components/DartSelect";
import DartInput from "../../../../../components/DartInput";
import {
  poaChannelAtom,
  poaExpandedListAtom,
  poaListAtom,
  poaSelectedListAtom,
  poaValuesAtom,
  poaVarianceAtom,
} from "../../../../../global/atoms";
import { poaValuesSelector } from "../../../../../global/selectors/poa-values-selector";
import { PoaImportWidget, PoaOverview } from "../../index";
import { updatePOAs } from "../../services/poa";
import PageSummary from "../../../../../components/PageSummary";
import { capitalize, queryGet } from "../../../../../utils/helpers";
import useEnums from "../../../../../utils/hooks/useEnums";
import DartPrompt from "../../../../../components/DartPrompt";

const PoaValuesReview = () => {
  const { info } = Modal;
  const { allocation_channel } = useEnums();
  const history = useHistory();
  const [variance, setVariance] = useRecoilState(poaVarianceAtom);
  const poaList = useRecoilValue(poaListAtom);
  const [promptedAboutChannel, setPromptedAboutChannel] = useState(false);
  const resetPoaValues = useResetRecoilState(poaValuesAtom);
  const resetPoaSelectedList = useResetRecoilState(poaSelectedListAtom);
  const resetPoaExpandedList = useResetRecoilState(poaExpandedListAtom);
  const poaValues = useRecoilValue<any>(poaValuesSelector);
  const selectedPoaList = useRecoilValue<any>(poaSelectedListAtom);
  const poaChannel = useRecoilValue(poaChannelAtom);
  const setPoaChannel = useSetRecoilState(poaChannelAtom);
  const [actionDisabled, setActionDisabled] = useState<boolean | undefined>(false);
  const [loading, setLoading] = useState(false);
  const [poaImportVisible, setPoaImportVisible] = useState(false);
  const [savePromptVisible, setSavePromptVisible] = useState(false);
  const useBrowserBack = queryGet("useBrowserBack") === "true";

  const getSummaryContent = useMemo(() => {
    return []; // @TODO: Temporarily disabled
    return [
      {
        name: "Flagged",
        value: "Coming soon",
      },
      {
        name: "New Comp Stores",
        value: "Coming soon",
      },
      {
        name: "Manual Overrides",
        value: "Coming soon",
      },
      {
        name: "Ready to Update",
        value: "Coming soon",
      },
    ];
  }, []);

  const updatePOAValues = async () => {
    setSavePromptVisible(false);
    setLoading(true);
    await updatePOAs(
      poaValues, selectedPoaList, [resetPoaValues, resetPoaSelectedList, resetPoaExpandedList],
    );
    setLoading(false);
  };

  const validateAndUpdateVariance = (e: any, isBlurring = false) => {
    if (isBlurring && variance?.toString() === "%") {
      setVariance("0%");
      return;
    }
    const valueInteger = parseInt(e.target.value?.replace(/%+/g, ""), 10);
    if (valueInteger > 100) {
      setVariance("100%");
    } else if (valueInteger < 0) {
      setVariance("0%");
    } else if (Number.isNaN(valueInteger)) {
      setVariance("%");
    } else {
      setVariance(`${valueInteger.toString()}%`);
    }
  };

  return (
    <div className="poa-values-review-page">
      <div className="title-wrap">
        <PageTitle
          title="POA update review"
          onBack={() => {
            if (useBrowserBack) {
              history.goBack();
            } else {
              history.push(paths.poa);
            }
          }}
        />
      </div>
      <div className="container-content">
        <PageSummary
          title="POA Update Summary"
          summaryContent={getSummaryContent}
          actionButton={(
            <>
              <PoaImportWidget
                visible={poaImportVisible}
                setVisible={setPoaImportVisible}
              />
              <DartButton
                loading={loading}
                size="lg"
                label="UPDATE POAS"
                onClick={() => (actionDisabled ? null : setSavePromptVisible(true))}
                disabled={actionDisabled || loading}
              />
            </>
          )}
          extraContent={(
            <div className="variance-wrapper">
              <DartSelect
                label="Channel"
                selectOptions={[
                  { label: "All", value: "ALL" },
                  ...(Object.values(allocation_channel || {}).map((c) => ({ label: capitalize(c), value: c }))),
                ]}
                placeholder="Select channel"
                canClear={false}
                showSearch={false}
                value={poaChannel}
                onChange={(value) => {
                  resetPoaValues();
                  resetPoaSelectedList();
                  resetPoaExpandedList();
                  setPoaChannel(value);
                }}
                onOpen={() => {
                  const isTouched = Object.values(poaValues || {}).some((pValue: any) => pValue?.touched);
                  if (!promptedAboutChannel && isTouched) {
                    info({
                      title: "Change channel ?",
                      content: "Changing channel will reset the POA list and unsaved changes will be lost.",
                      okText: "Confirm",
                      maskClosable: true,
                      type: "warning",
                      onOk: () => {
                        setPromptedAboutChannel(true);
                      },
                      onCancel: () => {
                        setPromptedAboutChannel(true);
                      },
                    });
                  }
                }}
              />
              <DartInput
                label="Flag update variance: "
                value={variance?.toString()}
                onChange={(e) => validateAndUpdateVariance(e, false)}
                onBlur={(e) => validateAndUpdateVariance(e, true)}
              />
            </div>
          )}
          loading={poaList?.status === "revalidate" || poaList?.status === "request"}
        />
        <div className="update-review-section">
          <PoaOverview
            editable
            varianceAllowed={variance}
            setActionDisabled={setActionDisabled}
          />
        </div>
        <DartPrompt
          title="Are you sure ?"
          content={(
            <div>
              This will also change IAQ settings for CCs.
              <br />
              Are you sure you want to proceed?
            </div>
          )}
          okText="Proceed"
          cancelText="Cancel"
          visible={savePromptVisible}
          onCancel={() => setSavePromptVisible(false)}
          onOk={updatePOAValues}
        />
      </div>
    </div>
  );
};

export default PoaValuesReview;
