import React, { useEffect, useState } from "react";
import "./index.less";
import { Spin } from "antd";
import { useRecoilValue } from "recoil";
import PillSelect from "../../../../components/PillSelect";
import Icon from "../../../../components/Icon";
import DartButton from "../../../../components/DartButton";
import { UserListCardItem } from "../../index";
import { IUser } from "../../../../global/interfaces";
import { userAtom } from "../../../../global/atoms";

interface IUserListCards {
  users: any;
  searchValue?: string | undefined;
  onCreate?: () => void;
  onUpdate?: (user: any) => void;
}

const UserListCards:React.FC<IUserListCards> = ({
  users, searchValue, onCreate, onUpdate,
}) => {
  const [selectedRole, setSelectedRole] = useState<string | undefined>();
  const [localUserList, setLocalUserList] = useState<any>([]);
  const authUser = useRecoilValue(userAtom);

  const COUNTERS_INIT_STATE = {
    superadmin: 0,
    admin: 0,
    manager: 0,
    allocator: 0,
  };

  const [userCounts, setUserCounts] = useState<any>(COUNTERS_INIT_STATE);

  useEffect(() => {
    const localUserCounts = { ...COUNTERS_INIT_STATE };

    // Move filtered users to the local list and filter by search value and selected role
    if (users?.data && users?.data?.length > 0) {
      setLocalUserList((users?.data || [])?.filter((user: IUser) => {
        return (selectedRole === user.role || selectedRole === "ALL")
          && (searchValue ? (
            user?.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
              user?.email?.toLowerCase().includes(searchValue.toLowerCase())
          ) : true);
      }));

      // Pill users counter
      users?.data?.map((user: IUser) => {
        if (user.role) {
          localUserCounts[user.role as keyof typeof COUNTERS_INIT_STATE] += 1;
        }
        return user;
      });
    } else {
      setLocalUserList([]);
    }
    setUserCounts(localUserCounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, selectedRole, searchValue]);

  return (
    <div className="user-list-cards">
      <div className="user-list-cards-role-selector">
        <PillSelect
          multiple={false}
          selectOptions={[
            {
              key: "all",
              value: "ALL",
              label: "All Users",
              count: Object.values(userCounts).reduce((acc: any, cur: any) => acc + cur, 0) as number,
            },
            {
              key: "superadmin",
              value: "superadmin",
              label: "Super Admin",
              count: userCounts.superadmin,
              disabled: userCounts.superadmin === 0,
            },
            {
              key: "admin",
              value: "admin",
              label: "Admin",
              count: userCounts.admin,
              disabled: userCounts.admin === 0,
            },
            {
              key: "manager",
              value: "manager",
              label: "Manager",
              count: userCounts.manager,
              disabled: userCounts.manager === 0,
            },
            {
              key: "allocator",
              value: "allocator",
              label: "Allocator",
              count: userCounts.allocator,
              disabled: userCounts.allocator === 0,
            },
          ]}
          onChange={(v) => (v ? setSelectedRole(v[0]) : false)}
          value={[selectedRole || "ALL"]}
        />
        <DartButton
          type="primary"
          icon="Plus"
          onClick={onCreate}
          label="Add New User"
        />
      </div>
      <div className="user-list-content-wrapper">
        <hr />
        {
          users.status === "request" ? (
            <Spin />
          ) : (
            <div className="user-list">
              {localUserList?.length > 0 ?
                localUserList?.sort((a: any, b: any) => {
                  if (a.email < b.email) return -1;
                  if (a.email > b.email) return 1;
                  return 0;
                }).map((user: IUser, index: number) => (
                  <UserListCardItem
                    key={`${user.username}_${index}`}
                    user={user}
                    onUpdate={onUpdate}
                    authUser={authUser}
                  />
                ))
                : (
                  <div className="user-list-empty">
                    <div>
                      <Icon
                        name="Profile"
                        size={30}
                        color="#C3C5C8"
                      />
                    </div>
                    <div>
                      Sorry, there are no users to display.
                    </div>
                  </div>
                )}
            </div>
          )
        }
      </div>
    </div>
  );
};

export default UserListCards;
