import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import {
  Button, DatePicker, Dropdown, Form, Modal, Tooltip,
} from "antd";
import {
  useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState,
} from "recoil";
import { useHistory } from "react-router-dom";
import moment from "moment";
import DartButton from "../../../../components/DartButton";
import { removeNullValues } from "../../../../utils/helpers";
import {
  newnessDashboardFiltersAtom,
  reviewCCFiltersAtom,
  selectedCCsAtom,
  selectedNewnessAtom,
} from "../../../../global/atoms";
import {
  ccGroupByTypes, orderTypes, reviewViewByTypes, storeGroupByTypes,
} from "../../../../configs/constants";
import DartSelect from "../../../../components/DartSelect";
import { allocationProcessAtom } from "../../../../global/atoms/allocation-process-atom";
import paths from "../../../../configs/paths";
import DartInput from "../../../../components/DartInput";
import DartDatepicker from "../../../../components/DartDatepicker";

interface IReviewCCFilters {
  onFiltersUpdate?: (filterData?: any) => void;
  bulkSelectedCCs?: string[],
  bulkSelectedStores?: number[],
  handleBulkAction?: (action: "is_hot" | "start_ship_date", value: boolean | string | null) => void,
  handleCrossDockShipDateChange?: (value: any) => void,
  onSubmit?: (values?: any) => void;
}

const ReviewCCFilters: React.FC<IReviewCCFilters> = ({
  onFiltersUpdate,
  bulkSelectedCCs,
  bulkSelectedStores,
  handleBulkAction,
  handleCrossDockShipDateChange,
  onSubmit,
}) => {
  const { confirm } = Modal;
  const history = useHistory();
  const [form] = Form.useForm();
  const selectedFilters = useRecoilValue<any>(reviewCCFiltersAtom);
  const [allocationProcess, setAllocationProcess] = useRecoilState(allocationProcessAtom);
  const setSelectedNewness = useSetRecoilState(selectedNewnessAtom);
  const setSelectedCCs = useSetRecoilState(selectedCCsAtom);
  const resetDashboardFilters = useResetRecoilState(newnessDashboardFiltersAtom);
  const [formData, setFormData] = useState<any>(selectedFilters || {});

  // Call prop function "onFiltersUpdate" on filter form update
  useEffect(() => {
    onFiltersUpdate?.(removeNullValues(formData));
    // eslint-disable-next-line
  }, [formData]);

  // Handler to validate filter form fields before sending values to update function
  const handleFormUpdateBeforeRequest = (updatedField: any) => {
    const updatedFieldName = updatedField?.[0]?.name?.[0];
    const updatedFieldValue = updatedField?.[0]?.value;
    if (updatedFieldName === "view") {
      form.setFieldsValue({ group: updatedFieldValue === "cc" ? ccGroupByTypes[0] : storeGroupByTypes[0] });
    }
    setFormData((prev: any) => {
      const value = updatedField?.[0]?.value || undefined;
      return { ...prev, [updatedFieldName]: value };
    });
  };

  // Prompt user if they want to approve allocation or not
  const handleSubmit = (values?: any) => {
    confirm({
      title: "Approve Allocation",
      content: (
        <>
          <div>
            Are you sure you want to approve allocation?
          </div>
        </>
      ),
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        onSubmit?.(values);
      },
    });
  };

  // Memoized value to check if allocation was already finished or not
  const allocationFinished = useMemo(() => {
    return allocationProcess?.data?.processStatus === "finished";
  }, [allocationProcess]);

  // If allocation is finished clear all persisted data of it and navigate to newness dashboard after 1 second
  useEffect(() => {
    if (allocationFinished) {
      setSelectedNewness({});
      setSelectedCCs({ ready: [], not_ready: [] });
      setAllocationProcess({});
      resetDashboardFilters();
      setTimeout(() => {
        history.push(paths.newness);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocationFinished]);

  useEffect(() => {
    // count total number of units from qty_sum
    const totalUnits = allocationProcess?.data?.processData?.reduce((sum: number, cur: any) => {
      return sum + cur.qty_sum;
    }, 0);

    // count total number of CCs based on chosen view mode
    let totalCC = 0;
    if (formData?.view === "cc" || !formData?.view) {
      // if view mode is set by cc, we get array of cc count equivalent elements
      totalCC = allocationProcess?.data?.processData?.length;
    } else {
      // if view mode is store, we get store list instead of cc list, meaning we should iterate over each stores' each nested object.
      // then count unique CCs (not to be mistaken in SKU (CC+Size))
      const uniqueCCList: any = {};
      for (let i = 0; i < allocationProcess?.data?.processData?.length; i += 1) {
        const cur = allocationProcess?.data?.processData[i]?.nested;
        for (let o = 0; o < cur?.length; o += 1) {
          uniqueCCList[cur[o].cc as any] = true;
        }
      }
      totalCC = Object.values(uniqueCCList).length;
    }

    // set visual fields "total_cc" and "total_unit"s values accordingly
    if (allocationProcess?.status === "revalidate" || allocationProcess?.status === "request") {
      // show "loader" when loading
      form.setFieldsValue({ total_cc: "-", total_unit: "-" });
    } else {
      // show actual values
      form.setFieldsValue({ total_cc: totalCC || 0, total_unit: totalUnits || 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocationProcess?.status, form, allocationProcess?.data?.processData]);

  return (
    <div className="review-cc-filters">
      <Form
        form={form}
        onFinish={(values) => {
          setFormData(removeNullValues(values));
          handleSubmit(values);
        }}
        initialValues={{
          view: formData.view || "cc",
          group: formData.group || "none",
          shipping_date: moment(allocationProcess?.data?.shippingDate) || null,
        }}
        onFieldsChange={handleFormUpdateBeforeRequest}
      >
        <div className="review-cc-action-wrapper">
          <div className="filters-wrapper">
            <Form.Item
              name="view"
              label="View by"
            >
              <DartSelect
                selectOptions={reviewViewByTypes}
                canClear={false}
              />
            </Form.Item>
            <Form.Item
              name="group"
              label="Group by"
            >
              <DartSelect
                selectOptions={formData.view === "cc" ? ccGroupByTypes : storeGroupByTypes}
                canClear={false}
              />
            </Form.Item>
            <Form.Item
              name="total_cc"
              label="Total CCs"
            >
              <DartInput
                disabled
                type="text"
              />
            </Form.Item>
            <Form.Item
              name="total_unit"
              label="Total Units"
            >
              <DartInput
                disabled
                type="text"
              />
            </Form.Item>
            {(bulkSelectedCCs && bulkSelectedCCs?.length > 0) || (bulkSelectedStores && bulkSelectedStores?.length > 0) ? (
              <Form.Item
                name="actions"
                label="Bulk Actions"
              >
                <Dropdown.Button
                  getPopupContainer={(triggerNode) => triggerNode.parentNode?.parentNode as HTMLElement}
                  className={`review-cc-bulk-actions ${allocationProcess?.status === "revalidate" || allocationFinished ? "disabled" : ""}`}
                  overlayClassName={`review-cc-bulk-actions-dropdown ${allocationProcess?.status === "revalidate"
                  || allocationFinished ? "disabled" : ""}`}
                  trigger={["click"]}
                  overlay={(
                    <>
                      <Button
                        disabled={allocationProcess?.status === "revalidate" || allocationFinished}
                        onClick={() => handleBulkAction?.("is_hot", false)}
                      >
                        Unmark as Hot
                      </Button>
                      <DatePicker
                        disabled={allocationProcess?.status === "revalidate" || allocationFinished}
                        placeholder="Start Ship Date"
                        style={{ width: "160px" }}
                        onChange={(dateValue) => (dateValue ? handleBulkAction?.("start_ship_date", dateValue.format("YYYY-MM-DD")) : null)}
                        allowClear={false}
                        showToday={false}
                        renderExtraFooter={() => (
                          <DartButton
                            style={{ width: "100%", margin: "10px 0" }}
                            disabled={allocationProcess?.status === "revalidate" || allocationFinished}
                            onClick={() => handleBulkAction?.("start_ship_date", null)}
                            label="Clear Start Ship Date"
                          />
                        )}
                      />
                    </>
                  )}
                  onClick={() => handleBulkAction?.("is_hot", true)}
                  disabled={allocationProcess?.status === "revalidate" || allocationFinished}
                >
                  Mark as Hot
                </Dropdown.Button>
              </Form.Item>
            ) : ""}
            {(allocationProcess?.data?.orderType === orderTypes.cross_dock) ? (
              <div>
                <Form.Item
                  name="shipping_date"
                  label="Ship Date"
                >
                  <DartDatepicker
                    value={moment(allocationProcess?.data?.shippingDate)}
                    onChange={(val) => handleCrossDockShipDateChange?.(val)}
                    pickerProps={{
                      allowClear: false,
                      disabledDate: (current: any) => {
                        return current
                          && (current.isBefore(moment().add(3, "months"))
                            || current.isAfter(moment().add(9, "months")));
                      },
                    }}
                  />
                </Form.Item>
              </div>
            ) : ""}
          </div>
          <Tooltip
            placement="left"
            overlay={allocationFinished
              ? "Allocation is already finished. Please navigate to Dashboard to start a new allocation"
              : undefined}
          >
            <div className="action-button-wrapper">
              <DartButton
                disabled={allocationProcess?.status === "revalidate" || allocationFinished}
                onClick={() => form.submit()}
                label={allocationFinished ? "Allocation Finished" : "Approve Allocation"}
                size="lg"
                loading={allocationProcess?.status === "request"}
              />
            </div>
          </Tooltip>
        </div>
      </Form>
    </div>
  );
};

export default ReviewCCFilters;
