/* eslint-disable */
import {message} from "antd";
import apiClient from "../../../../api/apiClient";
import endpoints from "../../../../api/endpoints";
import {IChannelTypes} from "../../../../global/atoms/global-filters-atom";
import {IPoaCategory, IPoaValue} from "../../../../global/interfaces";
import {showErrorMessage} from "../../../../utils/helpers";

const getPOAList = async (
  poaList: any,
  setPOAList: any,
  poaChannel: "ALL" | IChannelTypes = "ALL",
  filterStoreListByIds?: string[],
) => {
  try {
    if (poaList.data.length === 0) {
      setPOAList({ status: "request", data: poaList.data || [], channelStoreList: poaList.channelStoreList || [] });
    } else {
      setPOAList({ status: "revalidate", data: poaList?.data || [], channelStoreList: poaList.channelStoreList || [] });
    }
    const res:any = await apiClient.get(endpoints.poaList);
    const transformedData: any = {};
    if (res?.data && res?.data?.data) {
      (res?.data?.data || [])?.map((poa: any) => {
        const division = poa.division && poa.division !== "null" ? poa.division : "OTHER";
        const department = poa.department && poa.department !== "null" ? poa.department : "CUSTOM";
        if (!transformedData[division]) {
          transformedData[division] = {};
        }
        if (!transformedData[division][department]) {
          transformedData[division][department] = [];
        }
        transformedData[division][department].push({
          ...poa,
          department,
          division,
        });
        return transformedData;
      });
    }
    const channelStoreRes:any = await apiClient.get(endpoints.store + (poaChannel !== "ALL"
      ? `?channel=${poaChannel}&status=LIVE&status=PAUSED&status=PLANNING`
      : "?status=LIVE&status=PAUSED&status=PLANNING"));
    const stores = filterStoreListByIds
      ? channelStoreRes?.data?.stores?.filter((store: any) => filterStoreListByIds.includes(store.id?.toString()))
      : channelStoreRes?.data?.stores;
    setPOAList({ status: "success", data: transformedData || [], channelStoreList: stores || [] });
  } catch (error: any) {
    setPOAList({ status: "failed", data: [], channelStoreList: [] });
    showErrorMessage(error, "Failed to get POA list");
  }
};

const getGroupedPOAs = async (
  groupedPOAs: any,
  setGroupedPOAs: any,
  poaDepartmentDivisionFilter: { division: string, department: string },
  poaChannel: "ALL" | IChannelTypes = "ALL",
) => {
  // This logics should be implemented in backend itself... possibly @TODO ?
  const division = poaDepartmentDivisionFilter.division && poaDepartmentDivisionFilter.division !== "null"
    ? poaDepartmentDivisionFilter.division : "OTHER";
  const divisionToSend = poaDepartmentDivisionFilter.division === "OTHER"
    ? "null" : poaDepartmentDivisionFilter.division;
  const department = poaDepartmentDivisionFilter.department && poaDepartmentDivisionFilter.department !== "null"
    ? poaDepartmentDivisionFilter.department : "CUSTOM";
  const departmentToSend = poaDepartmentDivisionFilter.department === "CUSTOM"
    ? "null" : poaDepartmentDivisionFilter.department;

  const key = `${department}_${division}`;

  try {
    if (!groupedPOAs?.[key]?.data || groupedPOAs?.[key]?.status !== "success") {
      setGroupedPOAs((prev: any) => { return { ...prev, [key]: { status: "request", data: groupedPOAs?.[key]?.data || {} } }; });
      const params = poaChannel === "ALL"
        ? { division: divisionToSend, department: departmentToSend }
        : { channel: poaChannel, division: divisionToSend, department: departmentToSend };

      const res:any = await apiClient.get(endpoints.poaValues, { params });
      setGroupedPOAs((prev:any) => { return { ...prev, [key]: { status: "success", data: transformPOAtoGrouped(res?.data?.data) || {} } }; });
    }
  } catch (error: any) {
    setGroupedPOAs((prev: any) => { return { ...prev, [key]: { status: "failed", data: {} } }; });
    showErrorMessage(error, "Failed to get POA values");
  }
};

const getUpdatablePOAs = async (
  updatablePOAs: any,
  setUpdatablePOAs: any,
  poaChannel: "ALL" | IChannelTypes = "ALL",
) => {
  try {
    setUpdatablePOAs({
      status: "request",
      groupData: updatablePOAs?.groupData || {},
      isSalesTransactionComplete: updatablePOAs?.isSalesTransactionComplete || undefined,
      rawData: updatablePOAs?.rawData || []
    });

    const res:any = new Promise(async (resolve) => {
      const res = await apiClient.get(endpoints.poaUpdateValues, poaChannel && poaChannel !== "ALL" ? { params: {channel: poaChannel} } : {})
      resolve(res);
    });

    const resForCustom:any = new Promise(async (resolve) => {
      const res = await apiClient.get(endpoints.poaUpdateValues, poaChannel && poaChannel !== "ALL"
        ? { params: {channel: poaChannel, for_custom: 1} }
        : { params: {for_custom: 1}});
      resolve(res);
    });

    await Promise.all([res, resForCustom]).then((values) => {
      const list = values[0]?.data?.data;
      const listForCustom = values[1]?.data?.data;
      const combinedList = list.concat(listForCustom);

      const groups = groupPOAsWithDepartmentDivision(combinedList);

      const transaction_complete = values.every((e) => e?.data?.is_sale_transaction_complete);

      setUpdatablePOAs({
        status: "success",
        groupData: groups,
        isSalesTransactionComplete: transaction_complete,
        rawData: combinedList,
      });
    })
  } catch (error: any) {
    setUpdatablePOAs({
      status: "failed",
      data: {},
      isSalesTransactionComplete: undefined,
      rawData: [],
    });
    showErrorMessage(error, "Failed to get updatable POA values");
  }
};

const updatePOAs = async (
  groupedPOAs: any, selectedPoas: string[], cleanup?: (() => void)[],
) => {
  try {
    let poasUpdated = {};
    let poaValuesCount = 0;
    const params: any = { updates: [] };
    const poaValues: any = Object.values(groupedPOAs);
    for (let i = 0; i < poaValues?.length; i += 1) {
      const poaValuePerStorePerCategory: any = Object.values(poaValues[i]?.data);
      for (let o = 0; o < poaValuePerStorePerCategory?.length; o += 1) {
        const poaCategoryDepartmentDivision = poaValuePerStorePerCategory[o]?.poa_key;
        if (selectedPoas.indexOf(poaCategoryDepartmentDivision) !== -1) {
          poasUpdated = { ...poasUpdated, [poaCategoryDepartmentDivision]: true };
          const storeId = poaValuePerStorePerCategory[o]?.store_id;
          const singlePoaValues = poaValuePerStorePerCategory[o]?.poa_values;
          for (let k = 0; k < singlePoaValues.length; k += 1) {
            const {
              poa_category_id, poa_qty, updated,
            } = singlePoaValues[k];
            if (updated !== poa_qty) {
              poaValuesCount += 1;
              params.updates.push({
                store_id: storeId,
                poa_category_id,
                poa_qty: updated,
              });
            }
          }
        }
      }
    }
    const countKeys = Object.keys(poasUpdated).length;
    if (poaValuesCount > 0 && countKeys > 0) {
      await apiClient.patch(endpoints.updatePoaValues, params);
      message.success(`${poaValuesCount} POA${poaValuesCount > 1 ? "s" : ""} in ${countKeys} department${countKeys > 1 ? "s" : ""} has been successfully updated`);
    } else {
      message.info("There are no changes to update");
    }
    if (cleanup) {
      for (let c = 0; c < cleanup?.length; c += 1) {
        cleanup[c]?.();
      }
    }
  } catch (error: any) {
    showErrorMessage(error, "Failed to update POA values");
  }
};

const importPOAs = async (dataToSend: IPoaValue[], callback: () => void) => {
  try {
    await apiClient.patch(endpoints.updatePoaValues, {
      updates: dataToSend,
    });
    message.success(`POA values has been successfully imported`);
    callback?.();
  } catch (error: any) {
    showErrorMessage(error, "Failed to import POA values");
  }
}

const createCustomCategoryPOA = async (dataToSend: IPoaCategory, cleanup?: (() => void)[]) => {
  try {
    const res = await apiClient.post(endpoints.poaCategoryCreate, {
      name: dataToSend.name,
      ...(dataToSend.division ? { division: dataToSend.division } : {}),
      ...(dataToSend.department ? { department: dataToSend.department } : {}),
      is_custom: true,
      ccs: dataToSend.ccs && dataToSend.ccs.length > 0 ? dataToSend.ccs : [],
    });
    message.success(`Custom POA category created successfully`);
    if (cleanup) {
      for (let c = 0; c < cleanup?.length; c += 1) {
        cleanup[c]?.();
      }
    }
  } catch (error: any) {
    showErrorMessage(error, "Failed to create custom POA category");
  }
}

const updateCustomCategoryPOA = async (dataToSend: IPoaCategory, cleanup?: (() => void)[]) => {
  try {
    const res = await apiClient.put(endpoints.poaCategoryUpdate
      .replace(":id", dataToSend.id || ""), {
      ccs: dataToSend.ccs && dataToSend.ccs.length > 0 ? dataToSend.ccs : [],
    });
    message.success(`Custom POA category updated successfully`);
    if (cleanup) {
      for (let c = 0; c < cleanup?.length; c += 1) {
        cleanup[c]?.();
      }
    }
  } catch (error: any) {
    showErrorMessage(error, "Failed to update custom POA category");
  }
}

const groupPOAsWithDepartmentDivision = (data: any, overrideOriginalValues?: boolean) => {
  const uniqueKeysList:any = {};

  data?.map((item: any) => {
    const key = `${item?.poa_category.department || "CUSTOM"}_${item?.poa_category.division || "OTHER"}`;
    if(!uniqueKeysList?.hasOwnProperty(key)){
      uniqueKeysList[key] = [item];
    }else{
      uniqueKeysList[key].push(item);
    }
    return item;
  });

  const groups: any = {};

  Object.values(uniqueKeysList).forEach((itemsList: any) => {
    const division = itemsList[0]?.poa_category.division || "OTHER";
    const department = itemsList[0]?.poa_category.department || "CUSTOM";
    if(!groups.hasOwnProperty(division)){
      groups[division] = {};
    }
    if(itemsList.length > 0){
      groups[division][department] = { status: "success", data: transformPOAtoGrouped(itemsList, true, overrideOriginalValues) };
    }
  });

  return groups;
}

const transformPOAtoGrouped = (data: any, shouldRoundNumbers = false, overrideOriginalValues = false) => {
  if (!data) return {};
  if (Object.keys(data)?.length === 0) return {};

  return data.reduce((acc:any, poaValues: IPoaValue) => {
    const { store_id } = poaValues;
    if (!acc[store_id]) {
      acc[store_id] = {
        store_id: null, poa_key: null, poa_values: [],
      };
    }

    acc[store_id].store_id = poaValues?.store_id;
    acc[store_id].poa_key = `${poaValues?.poa_category?.department || "CUSTOM"}_${poaValues?.poa_category?.division || "OTHER"}`;

    // This function is also used on UPDATE IMPORT POA values. CHANGE ONLY IF NECESSARY
    // round the poa_qty to 2 decimal places if necessary
    const poaQty = shouldRoundNumbers
      ? Number((parseFloat((poaValues?.poa_qty).toString())).toFixed(2))?.toString()
      : poaValues?.poa_qty;
    const updatedPoaQty = shouldRoundNumbers
      ? Number((parseFloat((poaValues?.updated || poaValues?.poa_qty).toString())).toFixed(2))?.toString()
      : Number(poaValues?.updated || poaValues?.poa_qty).toString();
    const poaTotal = poaValues?.poa_total;
    const updatedPoaTotal = poaValues?.updated_poa_total || poaValues?.updated_poa_total === 0 ? poaValues?.updated_poa_total : poaValues?.poa_total;

    acc[store_id].poa_values.push({
      id: poaValues?.id,
      poa_category_id: poaValues?.poa_category_id,
      poa_total: overrideOriginalValues ? updatedPoaTotal : poaTotal,
      updated_poa_total: updatedPoaTotal,
      poa_qty: overrideOriginalValues ? updatedPoaQty : poaQty,
      updated: updatedPoaQty,
      channel: poaValues.store?.channel,
      is_sister_store: poaValues?.is_sister_store,
    });

    return acc;
  }, {});
};

export {
  getPOAList,
  getGroupedPOAs,
  getUpdatablePOAs,
  updatePOAs,
  transformPOAtoGrouped,
  groupPOAsWithDepartmentDivision,
  importPOAs,
  createCustomCategoryPOA,
  updateCustomCategoryPOA,
};
