/* eslint-disable no-case-declarations */
import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import { useRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import { message, Spin } from "antd";
import DartTable from "../../../../components/DartTable";
import { allocationProcessAtom } from "../../../../global/atoms/allocation-process-atom";
import paths from "../../../../configs/paths";
import {
  getAllocationProcess,
  getMultipleAllocationProcesses,
  updateAllocationProcessData,
} from "../../services/allocation";
import getDataViewByCC from "./getDataViewByCC";
import getDataViewByStore from "./getDataViewByStore";
import distributeTotalToSKUs from "../../utils/distributeTotalToSKUs";

interface IReviewCCs {
  selectedFilters?: {
    view: "store" | "cc";
    group: "none" | "tier" | "division" | "department" | "gtm_collection";
  }
  selectedCCs: string[];
  setSelectedCCs: (ccs: any) => void;
  selectedStores: number[];
  setSelectedStores: (stores: any) => void;
}

const ReviewCCs: React.FC<IReviewCCs> = ({
  selectedFilters,
  selectedCCs,
  setSelectedCCs,
  selectedStores,
  setSelectedStores,
}) => {
  const history = useHistory();
  const [allocationProcess, setAllocationProcess] = useRecoilState(allocationProcessAtom);
  const [updatesDone, setUpdatesDone] = useState(0);

  // If there is no allocation process ongoing, navigate to newness dashboard
  useEffect(() => {
    if (!allocationProcess?.data?.processId) {
      history.push(paths.newness);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocationProcess]);

  // If there is allocation ongoing fetch the data for it
  useEffect(() => {
    // If there are multiple IDs, fetch multiple allocations, if single id, fetch single allocation
    if (allocationProcess?.data?.processId?.length > 0 && typeof allocationProcess?.data?.processId === typeof []) {
      // Call Service that fetches multiple ongoing allocation data with IDs
      getMultipleAllocationProcesses(
        allocationProcess,
        setAllocationProcess,
        allocationProcess?.data?.processId,
        selectedFilters?.view ?? "cc",
      );
    } else if (allocationProcess?.data?.processId && typeof allocationProcess?.data?.processId === typeof 1) {
      // Call Service that fetches ongoing allocation data with ID
      getAllocationProcess(
        allocationProcess,
        setAllocationProcess,
        allocationProcess?.data?.processId,
        selectedFilters?.view ?? "cc",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters?.view]);

  // Handle cell update of Review CC table
  const onUpdateAllocationData = async (
    process_id: number, sku: string, store_id: string, qty: number,
  ) => {
    // Call Service that updates quantity for specific SKU (CC)
    await updateAllocationProcessData({
      process_id,
      allocationProcess,
      setAllocationProcess,
      formData: {
        sku, store_id, qty,
      },
      groupBy: selectedFilters?.view ?? "cc",
      shouldRevalidate: true,
      shouldShowMessage: true,
    });
  };

  // Handle "total" cell update of Review CC table and re-allocate all other columns
  const onUpdateAllocationDataForAllColumns = async (
    process_id: number, skus: any[], store_id: string, total: number,
  ) => {
    const dataToSend = [];
    const distributedData: any = distributeTotalToSKUs(total, skus);

    if (!distributedData) {
      message.error("Cannot distribute total to SKUs");
      return;
    }

    for (let i = 0; i < distributedData.length; i += 1) {
      if (distributedData[i].whole <= 0) {
        message.error("Cannot distribute total to SKUs");
        return;
      }

      dataToSend.push({
        sku: distributedData[i].sku,
        store_id,
        allocated_qty: distributedData[i].whole,
      });
    }

    await updateAllocationProcessData({
      process_id,
      allocationProcess,
      setAllocationProcess,
      formData: dataToSend,
      groupBy: selectedFilters?.view ?? "cc",
      isBulk: true,
      shouldRevalidate: true,
      shouldShowMessage: true,
    });

    setUpdatesDone((prev) => prev + 1);
  };

  // Handle "is_hot" cell update of Review CC table
  const onUpdateHotSKUs = async (
    process_id: number, skus: any[], store_id: string, is_hot: boolean,
  ) => {
    // Build data for single CC single store update of "is_hot"
    const dataToSend = [];
    for (let i = 0; i < skus.length; i += 1) {
      dataToSend.push({
        sku: skus[i].sku,
        store_id,
        allocated_qty: skus[i].allocated_qty,
        is_hot,
        start_ship_date: skus[i].start_ship_date || null,
      });
    }

    await updateAllocationProcessData({
      process_id,
      allocationProcess,
      setAllocationProcess,
      formData: dataToSend,
      groupBy: selectedFilters?.view ?? "cc",
      isBulk: true,
      customSuccessMessage: `Successfully ${is_hot ? "Marked as HOT" : "Marked as Not HOT"}`,
      customErrorMessage: `Failed to ${is_hot ? "Mark as HOT" : "Mark as Not HOT"}`,
      shouldRevalidate: true,
      shouldShowMessage: true,
    });

    setUpdatesDone((prev) => prev + 1);
  };

  // Handle "start_ship_date" cell update of Review CC table
  const onUpdateStartShipDateSKUs = async (
    process_id: number, skus: any[], store_id: string, start_ship_date: string | null,
  ) => {
    // Build data for single CC single store update of "start_ship_date"
    const dataToSend = [];
    for (let i = 0; i < skus.length; i += 1) {
      dataToSend.push({
        sku: skus[i].sku,
        store_id,
        allocated_qty: skus[i].allocated_qty,
        is_hot: skus[i].is_hot || false,
        start_ship_date,
      });
    }

    await updateAllocationProcessData({
      process_id,
      allocationProcess,
      setAllocationProcess,
      formData: dataToSend,
      groupBy: selectedFilters?.view ?? "cc",
      isBulk: true,
      customSuccessMessage: `Successfully updated 'start ship date' to ${start_ship_date}`,
      customErrorMessage: `Failed to update 'start ship date' to ${start_ship_date}`,
      shouldRevalidate: true,
      shouldShowMessage: true,
    });

    setUpdatesDone((prev) => prev + 1);
  };

  // Memoized value that checks if editing is allowed or not based on allocation status
  const editAllowed = useMemo(() => {
    return allocationProcess?.data?.processStatus !== "finished";
  }, [allocationProcess]);

  const handleSelectedRowChange = (selectedRowData: any) => {
    if (selectedFilters?.view === "cc") { // if CC view no need to build selectedCCs object
      if (selectedCCs.indexOf(selectedRowData?.rowData?.id) > -1) {
        setSelectedCCs((prev: string[]) => prev.filter((cc) => cc !== selectedRowData?.rowData?.id));
      } else {
        setSelectedCCs((prev: string[]) => [...prev, selectedRowData?.rowData?.id]);
      }
    } else if (selectedFilters?.view === "store") { // if Store view need to build selectedCCs object
      if (selectedStores.indexOf(selectedRowData?.rowData?.id) > -1) {
        setSelectedStores((prev: string[]) => prev.filter((cc) => cc !== selectedRowData?.rowData?.id));
      } else {
        setSelectedStores((prev: string[]) => [...prev, selectedRowData?.rowData?.id]);
      }
    }
  };

  // Decide table data based on store view or cc view filter selected. It's memoized.
  const tableData = useMemo(() => {
    switch (selectedFilters?.view) {
      case "store":
        return getDataViewByStore({
          allocationProcess,
          selectedFilters,
          onUpdateAllocationData,
          editAllowed,
          onUpdateAllocationDataForAllColumns,
          selectedRows: selectedStores,
          onSelectToggle: handleSelectedRowChange,
          onUpdateHotSKUs,
          onUpdateStartShipDateSKUs,
        });
      case "cc":
      default:
        return getDataViewByCC({
          allocationProcess,
          selectedFilters,
          onUpdateAllocationData,
          editAllowed,
          onUpdateAllocationDataForAllColumns,
          selectedRows: selectedCCs,
          onSelectToggle: handleSelectedRowChange,
          onUpdateHotSKUs,
          onUpdateStartShipDateSKUs,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocationProcess.status, selectedFilters, updatesDone, selectedCCs, selectedStores]);

  return (
    <div
      key={selectedFilters ? selectedFilters?.view + selectedFilters?.group : "selectedFilters"}
      className={`review-ccs-wrapper ${selectedFilters?.view === "cc" ? "ccs" : "store"}`}
    >
      {allocationProcess?.status === "request" ? <Spin className="loader" /> : (
        <>
          {tableData?.groups && tableData?.groups?.length > 0 ? tableData?.groups?.map((group: any, i) => {
            return (
              <DartTable
                loading={allocationProcess?.status === "revalidate"}
                key={group?.id || i}
                withBorderTop={i === 0}
                columns={tableData.columns}
                data={tableData.data[i] as any}
                width="100%"
                height={tableData.height}
                fixed
                wrapperClassName="review-ccs-table"
                headerHeight={tableData.headerHeight}
                rowHeight={tableData.rowHeight}
                childHeight={tableData.childHeight}
                expandable
                expandColumnKey={tableData.expandColumnKey}
                hasExpandAll
                selectable
                hasSelectAll
                defaultSelectedRowKeys={selectedFilters?.view === "cc"
                  ? selectedCCs.filter((eachCC: string) => tableData.data[i].find((eachData: any) => eachData.id === eachCC))
                  : selectedStores.filter((eachStore: number) => tableData.data[i].find((eachData: any) => eachData.id === eachStore))}
                onSelectedRowsChange={(groupSelections: string[]) => {
                  // This works only for group all select / all deselect
                  // because selection component is in getDataViewByCC / getDataViewByStore
                  if (selectedFilters?.view === "cc") {
                    if (groupSelections?.length === 0) {
                      const keys = (tableData.data[i].map((eachData: any) => eachData.id));
                      setSelectedCCs((prev: string[]) => prev.filter((cc) => keys.indexOf(cc) === -1));
                    } else {
                      setSelectedCCs((prev: string[]) => [...prev, ...groupSelections]);
                    }
                  } else if (selectedFilters?.view === "store") {
                    if (groupSelections?.length === 0) {
                      const keys = (tableData.data[i].map((eachData: any) => eachData.id));
                      setSelectedStores((prev: string[]) => prev.filter((store) => keys.indexOf(store) === -1));
                    } else {
                      setSelectedStores((prev: string[]) => [...prev, ...groupSelections]);
                    }
                  }
                }}
                header={<div className="split-table-header">{(group?.title || group || "N/A").toUpperCase()}</div>}
              />
            );
          }) : (
            <DartTable
              loading={allocationProcess?.status === "revalidate"}
              key="review-cc"
              withBorderTop
              columns={tableData.columns}
              data={tableData.data}
              width="100%"
              height={tableData.height}
              fixed
              wrapperClassName="review-ccs-table"
              headerHeight={tableData.headerHeight}
              rowHeight={tableData.rowHeight}
              childHeight={tableData.childHeight}
              expandable
              expandColumnKey={tableData.expandColumnKey}
              hasExpandAll
              selectable
              hasSelectAll
              defaultSelectedRowKeys={selectedFilters?.view === "cc" ? selectedCCs : selectedStores}
              onSelectedRowsChange={(selections: string[]) => {
                if (selectedFilters?.view === "cc") {
                  setSelectedCCs(selections);
                } else if (selectedFilters?.view === "store") {
                  setSelectedStores(selections);
                }
              }}
              header={undefined}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ReviewCCs;
