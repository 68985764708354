const decideImportType = (importType: string) => {
  switch (importType) {
    case "FLOW_DOC": return "Flow Document";
    case "SIZE_LOOKUP": return "Size Lookup";
    case "TIER_LOOKUP": return "Tier Lookup";
    case "ITEM_LIST": return "Item List";
    case "STORE_LIST": return "Store List";
    case "STORE_SIZE": return "Store Size";
    case "NEWNESS_WT": return "Newness Walk-through";
    case "POA_LOOKUP": return "POA Lookup";
    default: return importType;
  }
};

export default decideImportType;
