import apiClient from "../../../api/apiClient";
import endpoints from "../../../api/endpoints";
import { showErrorMessage } from "../../../utils/helpers";

const fetchAvailableTags = async (availableTags: any, setAvailableTags: any) => {
  try {
    setAvailableTags({
      status: "request", data: availableTags?.data || [], count: 0,
    });

    const res: any = await apiClient.get(endpoints.tags);

    setAvailableTags({
      status: "success", data: res?.data?.data, total: res?.data?.data?.length,
    });
  } catch (error: any) {
    setAvailableTags({
      status: "failed", data: [], count: 0,
    });
    showErrorMessage(error, "Failed to get available tags");
  }
};

export {
  fetchAvailableTags,
};
