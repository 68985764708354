import React, { useMemo } from "react";
import "./index.less";
import { Button } from "antd";
import { Link } from "react-router-dom";
import Icon from "../../../../components/Icon";
import { IStoreProfile } from "../../../../global/interfaces";
import { decideStoreStatusExtra } from "../../utils/decideStoreStatusExtra";
import decideDivisionName from "../../../../utils/helpers/decideDivisionName";
import useEnums from "../../../../utils/hooks/useEnums";
import { capitalize, renderTag } from "../../../../utils/helpers";
import countryCodeToName from "../../../../utils/helpers/countryCodeToName";
import paths from "../../../../configs/paths";

interface IStoreListItem {
  store: IStoreProfile;
  onStoreEdit?: (storeId: number) => void;
  expanded?: boolean;
  onExpandToggle?: () => void;
}

const StoreListItem:React.FC<IStoreListItem> = ({
  store,
  onStoreEdit,
  expanded = false,
  onExpandToggle,
}) => {
  const { allocation_status } = useEnums();
  const divisionDepartmentMap = {
    MEN: {
      FOOTWEAR: "Footwear",
      APPARELS: "Apparel",
      ACCESSORIES: "Accessories",
      COLD_WEATHER: "Cold Weather",
    },
    WOMEN: {
      FOOTWEAR: "Footwear",
      APPARELS: "Apparel",
      ACCESSORIES: "Accessories",
      COLD_WEATHER: "Cold Weather",
    },
    KID: {
      FOOTWEAR: "Footwear",
      APPARELS: "Apparel",
      ACCESSORIES: "Accessories",
      COLD_WEATHER: "Cold Weather",
    },
    HOME_CARE: {
      HOME_CARE: "All Homeware",
    },
  };

  const storeStatusExtra = useMemo(() => decideStoreStatusExtra(store, allocation_status), [allocation_status, store]);

  const decideTierText = (tier: string) => {
    switch (tier) {
      case "CUSTOM": return "Special";
      case "TIER_1": return "1";
      case "TIER_2": return "2";
      case "TIER_3": return "3";
      case "TIER_4": return "4";
      default: return "None";
    }
  };

  return (
    <div
      key={store.number || store.name}
      className="store-list-item"
    >
      <div className="card-head">
        <div className="left">
          {store.number ? (
            <>
              {store.number}
              <span className="vertical-splitter" />
            </>
          ) : ""}
          {store.name}
        </div>
        <div className="right">
          {expanded ? (
            <Link
              to={`${paths.edit_store.replace(":id", (store.id || "").toString())}?useBrowserBack=true`}
              onClick={() => (store.id && onStoreEdit ? onStoreEdit?.(store.id) : false)}
            >
              <Button type="ghost">
                <span>Edit Store</span>
                <Icon
                  name="Edit"
                  size={20}
                  color="#333E47"
                />
              </Button>
            </Link>
          ) : <></>}
        </div>
      </div>
      <div className="card-body">
        <div
          className="collapse-content"
          onClick={onExpandToggle}
        >
          <div className="left">
            {store.city ? `${store.city}, ` : ""}
            {store.state ? `${store.state}, ` : ""}
            {store.country ? `${countryCodeToName(store?.country)}` : ""}
          </div>
          <div className="right">
            {store?.status || storeStatusExtra ? (
              <div className="store-status-wrapper">
                {store?.status && (
                  <div className={`store-status ${store?.status}`}>
                    {store?.status.toLowerCase()}
                  </div>
                )}
                {storeStatusExtra && (
                  <div className="store-status-extra">
                    {storeStatusExtra}
                  </div>
                )}
              </div>
            ) : <></>}
            {expanded ? (
              <Icon
                name="DropdownArrow"
                style={{ transform: "rotate(180deg)" }}
              />
            ) : (<Icon name="DropdownArrow" />)}
          </div>
        </div>
        <div className={`expand-content ${expanded ? "visible" : ""} ${store?.tags && store?.tags?.length > 0 ? "has-tags" : ""}`}>
          <div className="store-type">
            {`${capitalize(store.channel)} Store`}
          </div>
          <div className="store-departments">
            {(store.departments ? Object.keys(store.departments) : []).map((department) => (
              <div
                key={department}
                className="department"
              >
                <div className="department-name">
                  {decideDivisionName(department)}
                </div>
                <div className="department-divisions">
                  {Object.keys((divisionDepartmentMap as any)?.[department]).map((division) => (
                    <div
                      key={division}
                      className="division"
                    >
                      {(divisionDepartmentMap as any)?.[department][division]}
                      <span className="division-tier">
                        {decideTierText((store.departments as any)?.[department][division]?.tier)}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="store-tags-wrapper">
            {store.tags?.map((tag) => (
              <div
                key={tag}
                className="tag"
              >
                {renderTag(tag)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreListItem;
