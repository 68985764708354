import React, { useMemo } from "react";
import "./index.less";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import { IStoreProfile } from "../../../../global/interfaces";
import Icon from "../../../../components/Icon";
import { decideStoreStatusExtra } from "../../utils/decideStoreStatusExtra";
import useEnums from "../../../../utils/hooks/useEnums";
import countryCodeToName from "../../../../utils/helpers/countryCodeToName";

interface IExistingStore {
  store: IStoreProfile;
  onBack?: () => any;
  onDelete?: (storeId: number) => any;
  deleteLoading?: boolean;
}

interface INewStore {
  store?: never;
  onBack?: () => any;
  onDelete?: never;
  deleteLoading?: never;
}

const StoreProfileHeader: React.FC<IExistingStore | INewStore> = ({
  onBack,
  onDelete,
  store,
  deleteLoading,
}) => {
  const { allocation_status } = useEnums();
  const statusCanDelete = [allocation_status.PLANNING, allocation_status.CLOSED];

  const storeStatusExtra = useMemo(() => (store ? decideStoreStatusExtra(store, allocation_status) : ""), [allocation_status, store]);

  const decideStoreTier = useMemo(() => {
    switch (store?.tier) {
      case undefined:
        return "";
      case null:
        return "";
      case -1:
        return "Custom";
      default:
        return `TIER ${store?.tier}`;
    }
  }, [store]);

  return (
    <div className="store-profile-header">
      <div className="top-section">
        {onBack && (
          <Button
            className="back-button"
            onClick={onBack}
            type="ghost"
            shape="circle"
          >
            <ArrowLeftOutlined style={{ fontSize: 16 }} />
          </Button>
        )}
        {store && onDelete && store?.id && (store?.status && statusCanDelete.includes(store?.status)) ? (
          <Button
            className="delete-button"
            onClick={() => onDelete?.(store?.id || 0)}
            type="ghost"
          >
            <p>Remove Store</p>
            {!deleteLoading ? (
              <Icon
                name="Delete"
                size={18}
                color="#000000"
              />
            ) : (
              <Spin
                size="small"
                style={{ marginTop: 4 }}
              />
            )}
          </Button>
        ) : <></>}
      </div>
      <div className="middle-section">
        <div className="store-name">
          {!store
            ? (
              <span>Add New Store</span>
            )
            : (
              <span>
                {store?.number ? (
                  <>
                    {store?.number}
                    <span className="small-splitter">|</span>
                  </>
                ) : ""}
                {store?.name}
                {store?.tier ? (
                  <>
                    <span className="small-splitter">|</span>
                    {decideStoreTier}
                  </>
                ) : <></>}
              </span>
            )}
        </div>
        {store && (
        <div className="store-location">
          {store?.city ? `${store?.city}, ` : ""}
          {store?.state ? `${store?.state}, ` : ""}
          {store?.country ? `${countryCodeToName(store?.country)}` : ""}
        </div>
        )}
      </div>
      <hr className="splitter" />
      {store?.status || storeStatusExtra ? (
        <div className="bottom-section">
          {store?.status && (
          <div className={`store-status ${store?.status}`}>
            {store?.status}
          </div>
          )}
          {storeStatusExtra && (
          <div className="store-status-extra">
            {storeStatusExtra}
          </div>
          )}
        </div>
      ) : <></>}
    </div>
  );
};

export default StoreProfileHeader;
