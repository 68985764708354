/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { MinusOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import Icon from "../../../../components/Icon";
import CompactSelect from "../../../../components/CompactSelect";

/* TODO: Move to interface
  Data Structure: {
    allocatable_inventory: 490
    brand: "UGG"
    cc: "1116183-CPDP"
    cc_sizes: Array(14) [ {…}, {…}, {…}, … ] // APPENDED FIELD
    cc_tier: "Tier 4"
    channel: "concept"
    class: "Bags"
    klass: "Bags"
    country: "us"
    department: "Accessories"
    description: "Janey II Clear"
    division: "Women's"
    gtm_collection: "PLAID PUNK"
    id: "1116183-CPDP"
    initial_allocation: 203
    is_1psps_met: 1
    is_completed: 0
    is_min_qty_met: 1
    is_ready: 1
    isd: "2022-07-15"
    min_qty: 14
    period: "202207"
    size_run: "O/S"
    st_department: "W Accessories"
    store_avg: 6
    store_nr: 20
    store_list: Array(20) [ {…}, {…}, {…}, … ] // APPENDED FIELD
}
*/

const columns = (props: {
  onStoreTierChange?: (cc:string, tiers: string[], tier: "special" | "tier1" | "tier2" | "tier3" | "tier4", isInitial?: boolean) => void,
}): Record<string, any>[] => [
  {
    key: "cc",
    dataIndex: "cc",
    title: "",
    width: 100,
    flexGrow: 2,
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className={`store-selection-table-cell uuid-cell
        ${rowData.is_1psps_met ? "psps-met" : ""}
        ${!rowData.canBeSentToBackend ? "has-error" : ""}`}
        >
          <Tooltip
            placement="right"
            overlay={!rowData.canBeSentToBackend ? "CC will not be considered because no stores are selected" : undefined}
          >
            {rowData.cc || NAComponent}
          </Tooltip>
        </div>
      );
    },
  },
  {
    key: "is_1psps_met",
    dataIndex: "is_1psps_met",
    title: "Readiness",
    width: 50,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      if (!rowData.canBeSentToBackend) {
        return (
          <div className="store-selection-table-cell has-error">
            {NAComponent}
          </div>
        );
      }
      if (rowData.isChanged) {
        return (
          <div className="store-selection-table-cell">
            <Icon
              name="Edit"
              color="#19945D"
              size={18}
            />
          </div>
        );
      }
      return (
        <div className="store-selection-table-cell">
          {rowData?.is_1psps_met
            ? (
              <Icon
                name="Check"
                color="#19945D"
                size={18}
              />
            )
            : (
              <Icon
                name="Flash"
                color="#E87324"
                size={18}
              />
            )}
        </div>
      );
    },
  },
  {
    key: "division",
    dataIndex: "division",
    title: "Division",
    width: 90,
    flexGrow: 2,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="store-selection-table-cell">
          {rowData?.division || NAComponent}
        </div>
      );
    },
  },
  {
    key: "department",
    dataIndex: "department",
    title: "Department",
    width: 90,
    flexGrow: 2,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="store-selection-table-cell">
          {rowData?.department || NAComponent}
        </div>
      );
    },
  },
  {
    key: "description",
    dataIndex: "description",
    title: "Description",
    width: 100,
    flexGrow: 2,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="store-selection-table-cell">
          {rowData?.description || NAComponent}
        </div>
      );
    },
  },
  {
    key: "class",
    dataIndex: "class",
    title: "Class",
    width: 100,
    flexGrow: 2,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="store-selection-table-cell">
          {rowData?.class || rowData?.klass || NAComponent}
        </div>
      );
    },
  },
  {
    key: "stores",
    dataIndex: "stores",
    title: "Special stores",
    width: 100,
    flexGrow: 1,
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="store-selection-table-cell">
          <StoreSelectionCompactSelect
            selectOptions={rowData.store_list?.special}
            onChange={(value: any) => {
              props?.onStoreTierChange?.(
                rowData.cc, value, "special",
              );
            }}
            selectedData={rowData.selectedStoreList?.special}
          />
        </div>
      );
    },
  },
  {
    key: "tier",
    dataIndex: "tier",
    title: "Tier 1",
    width: 100,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="store-selection-table-cell">
          <StoreSelectionCompactSelect
            selectOptions={rowData.store_list?.tier1}
            onChange={(value: any) => {
              props?.onStoreTierChange?.(
                rowData.cc, value, "tier1",
              );
            }}
            selectedData={rowData.selectedStoreList?.tier1}
          />
        </div>
      );
    },
  },
  {
    key: "tier2",
    dataIndex: "tier2",
    title: "Tier 2",
    width: 100,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="store-selection-table-cell">
          <StoreSelectionCompactSelect
            selectOptions={rowData.store_list?.tier2}
            onChange={(value: any) => {
              props?.onStoreTierChange?.(
                rowData.cc, value, "tier2",
              );
            }}
            selectedData={rowData.selectedStoreList?.tier2}
          />
        </div>
      );
    },
  },
  {
    key: "tier3",
    dataIndex: "tier3",
    title: "Tier 3",
    width: 100,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="store-selection-table-cell">
          <StoreSelectionCompactSelect
            selectOptions={rowData.store_list?.tier3}
            onChange={(value: any) => {
              props?.onStoreTierChange?.(
                rowData.cc, value, "tier3",
              );
            }}
            selectedData={rowData.selectedStoreList?.tier3}
          />
        </div>
      );
    },
  },
  {
    key: "tier4",
    dataIndex: "tier4",
    title: "Tier 4",
    width: 100,
    flexGrow: 1,
    align: "center",
    cellRenderer: ({ rowData }: any) => {
      return (
        <div className="store-selection-table-cell">
          <StoreSelectionCompactSelect
            selectOptions={rowData.store_list?.tier4}
            onChange={(value: any) => {
              props?.onStoreTierChange?.(
                rowData.cc, value, "tier4",
              );
            }}
            selectedData={rowData.selectedStoreList?.tier4}
          />
        </div>
      );
    },
  },
];

const NAComponent = <MinusOutlined style={{ fontSize: 14, color: "#C3C5C8" }} />;

interface IStoreSelectionCompactSelect {
  selectOptions: any;
  onChange: (value: any) => void;
  selectedData: any[];
}

const StoreSelectionCompactSelect: React.FC<IStoreSelectionCompactSelect> = ({
  selectOptions, onChange, selectedData,
}) => {
  return (
    <CompactSelect
      placeholder={selectOptions?.length ? `${selectOptions.length} (0)` : "0 (0)"}
      selectPlaceholder="count"
      popupContainer={document.getElementById("store-selection-table-dropdown-wrapper")!}
      selectOptions={(selectOptions || [])?.map((store: any) => { return { value: store?.store_id || store, label: store?.store_name || store }; })}
      mode="multiple"
      canClear={false}
      showSearch={false}
      defaultValue={(selectedData || [])?.map((store: any) => { return { value: store?.store_id || store, label: store?.store_name || store }; })}
      onChange={onChange}
      hasSelectAll
    />
  );
};

export default columns;
