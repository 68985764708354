/* eslint-disable no-param-reassign */
import React from "react";
import { Button, Tooltip } from "antd";
import { IStoreProfile } from "../../../../global/interfaces";
import EditableCell from "../../../../components/EditableCell";
import TableDropdownMenuComponent from "../../../../components/TableDropdownMenuComponent";
import decideStoreStatusColor from "../../../../utils/helpers/decideStoreStatusColor";
import { renderTag } from "../../../../utils/helpers";
import Icon from "../../../../components/Icon";

const columnsAQ = (props: {
  ccTag?: string;
  onStoreCheck?: (storeId?: any) => void;
  handleEditStoreClick?: (storeId?: any) => void;
  handleStoreToggleFromStyle?: (storeId?: any) => void;
  handleAQUpdate?: (storeId: any, newAQValue: number) => void;
  uas?: number;
  editable?: boolean;
  stagesVisible?: boolean;
  setStagesVisible?: (visible: boolean) => void;
}): Record<string, any>[] => [
  {
    key: "store",
    dataIndex: "store",
    title: (
      <>
        Stores
        {props.stagesVisible
          ? (
            <Button
              className="hide-stages-button"
              icon={<Icon name="Invalid" />}
              onClick={() => props?.setStagesVisible?.(false)}
            >
              Hide Stages
            </Button>
          )
          : (
            <Button
              className="view-stages-button"
              icon={<Icon name="Info" />}
              onClick={() => props?.setStagesVisible?.(true)}
            >
              View Stages
            </Button>
          )}
      </>
    ),
    width: 400,
    flexShrink: 5,
    align: "left",
    style: { paddingLeft: 0, paddingRight: 0 },
    cellRenderer: ({ rowData }: {
      rowData: IStoreProfile
    }) => {
      const matchingTag = rowData?.tags?.find((tag) => renderTag(tag) === renderTag(props?.ccTag));
      return (
        <div className={`style-details-table-cell store-cell ${rowData?.selected ? "selected" : "deselected"}
          ${rowData?.is_excluded ? "excluded" : ""}`}
        >
          <div className="left">
            {matchingTag && (
              <div className="tag-wrapper">
                <Tooltip
                  overlay={renderTag(matchingTag)}
                  getTooltipContainer={(trigger) => trigger?.parentElement as HTMLElement}
                  placement="right"
                >
                  <Icon
                    name="Tag"
                    color="#000"
                    size={16}
                  />
                </Tooltip>
                <span className="vertical-splitter" />
              </div>
            )}
            <span
              className="store-number"
              style={matchingTag ? { fontWeight: "bold" } : {}}
            >
              {rowData?.number}
            </span>
            <span className="vertical-splitter" />
            <span
              className="store-name"
              style={matchingTag ? { fontWeight: "bold" } : {}}
            >
              {rowData?.name}
            </span>
            <span
              className="store-status"
              style={{ backgroundColor: decideStoreStatusColor(rowData?.status) }}
            />
          </div>
          <div className="right">
            <TableDropdownMenuComponent
              rowData={rowData}
              functions={{
                handleEditStoreClick: props?.handleEditStoreClick,
                ...(props?.editable ? { handleStoreToggleFromStyle: props?.handleStoreToggleFromStyle } : {}),
              }}
              overlayClassName="style-details-store-action-dropdown"
            />
          </div>
        </div>
      );
    },
  },
  {
    key: "aq",
    dataIndex: "aq",
    title: props?.editable ? "Allocation Quantity" : "AQ (Stage 5 Only)",
    width: 180,
    flexShrink: 5,
    align: "center",
    className: "no-padding",
    cellRenderer: ({ rowData }: {
      rowData: IStoreProfile
    }) => {
      const decideEmptyText = () => {
        if (!rowData?.selected) {
          return "-";
        }
        if (rowData?.iaq && (rowData as any)?.updatedIaq?.toString() === (rowData as any)?.iaq?.toString()) {
          return (rowData as any)?.iaq?.toString();
        }
        if (rowData?.iaq && (rowData as any)?.updatedIaq?.toString() !== (rowData as any)?.iaq?.toString()) {
          return `${(rowData as any)?.iaq.toString()}  →  ${(rowData as any)?.updatedIaq?.toString()}`;
        }
        return 0;
      };
      return (
        <div
          className={`style-details-table-cell center ${rowData?.selected ? "selected" : "deselected"} ${rowData?.is_excluded ? "excluded" : ""}
          ${(rowData as any)?.iaq?.toString() !== (rowData as any)?.updatedIaq?.toString() ? "has-change" : ""}`}
        >
          {props?.editable ? (
            <EditableCell
              editable={rowData?.selected && props?.uas !== 0}
              cellData={rowData?.selected ? (rowData as any)?.updatedIaq || (rowData as any)?.iaq || 0 : (rowData as any)?.iaq || 0}
              onOk={(value: number) => rowData.number && value && props.handleAQUpdate?.(rowData.number, value)}
              viewModeTooltip={(rowData as any)?.iaq}
              cancelOnBlur
              min={0}
              max={999999999}
              allowDecimals={false}
              hasButtons={false}
              emptyText={decideEmptyText()}
            />
          ) : <span>-</span>}
        </div>
      );
    },
  },
  {
    key: "poa",
    dataIndex: "poa",
    title: props?.editable ? "POA" : "POA (Stage 5 Only)",
    width: 180,
    flexShrink: 5,
    align: "center",
    className: "no-padding",
    cellRenderer: ({ rowData }: {
      rowData: IStoreProfile
    }) => {
      return (
        <div
          className={`style-details-table-cell center ${rowData?.selected ? "selected" : "deselected"} ${rowData?.is_excluded ? "excluded" : ""}
          ${(rowData as any)?.poa?.toString() !== (rowData as any)?.updatedPoa?.toString() ? "has-change" : ""}`}
        >
          {rowData?.selected && props.editable ? (
            <span>
              {(rowData as any)?.poa > 999999 ? 0 : Math.round((rowData as any)?.poa * 100) / 100}
              {(rowData as any)?.poa !== (rowData as any)?.updatedPoa && rowData?.selected && (
              <span>
                {"  →  "}
                {Math.round((rowData as any)?.updatedPoa * 100) / 100}
              </span>
              )}
            </span>
          ) : <span>-</span>}
        </div>
      );
    },
  },
];

export default columnsAQ;
