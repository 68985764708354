import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const selectedStoreAtom = atom({
  key: "selected-store",
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
