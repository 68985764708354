import React, { useMemo, useState } from "react";
import "./index.less";
import { Button, Tooltip } from "antd";
import Icon from "../../../../../components/Icon";
import { ISizeRun } from "../../../../../global/interfaces";
import DartCheckbox from "../../../../../components/DartCheckbox";
import decideDepartmentName from "../../../../../utils/helpers/decideDepartmentName";
import DartButton from "../../../../../components/DartButton";

interface ISizeRunCollapse {
  sizeRun: ISizeRun;
  expanded?: boolean;
  onExpandToggle?: () => void;
  expandedContent?: React.ReactNode | JSX.Element | string;
  checked?: boolean;
  onCheckboxToggle?: () => void;
  onMaximizeClick?: () => void;
  loading?: boolean;
  hasError?: boolean;
  onSizeRunEdit?: (sizeRun: ISizeRun) => void;
  onSizeRunDelete?: (sizeRun: ISizeRun) => void;
  maximizeText?: string;
  extraContentClass?: string;
  disabled?: boolean;
}

const SizeRunCollapse:React.FC<ISizeRunCollapse> = ({
  sizeRun,
  expanded = false,
  onExpandToggle,
  expandedContent,
  checked,
  onCheckboxToggle,
  onMaximizeClick,
  loading,
  hasError = false,
  onSizeRunEdit,
  onSizeRunDelete,
  maximizeText,
  extraContentClass,
  disabled,
}) => {
  const nameOrNA = sizeRun.name || "N/A";

  const [actionsVisible, setActionsVisible] = useState(false);

  const dotsOrArrow = useMemo(() => {
    return actionsVisible ? (
      <Button
        ghost
        shape="circle"
        onClick={() => setActionsVisible(false)}
      >
        <Icon
          name="DropdownArrow"
          color="#000000"
          size={16}
          style={{ transform: "rotate(90deg)" }}
        />
      </Button>
    ) : (
      <Button
        ghost
        shape="circle"
        onClick={() => setActionsVisible(true)}
      >
        <Icon
          name="MenuDots"
          color="#000000"
          size={16}
        />
      </Button>
    );
  }, [actionsVisible]);

  const sizeRunActions = useMemo(() => {
    if (!onSizeRunEdit && !onSizeRunDelete) {
      return <></>;
    }
    const actionCount = (onSizeRunEdit ? 1 : 0) + (onSizeRunDelete ? 1 : 0);
    return (
      <div className={`size-run-actions ${actionsVisible ? `visible-actions actionsX${actionCount}` : "hidden-actions"}`}>
        {dotsOrArrow}
        <div className="toggled-part">
          {onSizeRunEdit && (
          <DartButton
            size="sm"
            icon="Edit"
            type="primary"
            label="Edit"
            onClick={() => { onSizeRunEdit?.(sizeRun); setActionsVisible(false); }}
          />
          )}
          {onSizeRunDelete && (
          <DartButton
            size="sm"
            icon="Delete"
            type="danger_full"
            label="Delete"
            onClick={() => { onSizeRunDelete?.(sizeRun); setActionsVisible(false); }}
          />
          )}
        </div>
      </div>
    );
  }, [sizeRun, actionsVisible]);

  return (
    <div
      key={`${sizeRun?.department}_${sizeRun?.division}_${sizeRun?.id}`}
      className={`size-run-collapse ${expanded ? "has-expanded-content" : ""}`}
    >
      <div
        className="card-head"
      >
        <div className="left">
          {onCheckboxToggle ? (
            <DartCheckbox
              size="sm"
              disabled={disabled}
              onChange={() => onCheckboxToggle?.()}
              checked={checked}
              className={`checkbox-department ${hasError ? "with-error" : ""}`}
            />
          ) : (sizeRunActions)}
          <div className="title">{sizeRun.department ? decideDepartmentName(sizeRun.department) : nameOrNA}</div>
          <div className="vertical-split" />
          {sizeRun.size_category === "HALF_RANGE" && <span>Half</span>}
          {sizeRun.size_category === "WHOLE_RANGE" && <span>Whole</span>}
          <Tooltip
            overlay={sizeRun.size_run_min === "O/S" || sizeRun.size_run_max === "O/S"
              ? (<span>One Size</span>)
              : (
                <span>
                  {sizeRun.size_run_min === sizeRun.size_run_max
                    ? sizeRun.size_run_max
                    : `${sizeRun.size_run_min} - ${sizeRun.size_run_max}`}
                </span>
              )}
            placement="right"
            trigger={["click"]}
          >
            <div className="size-indicator">
              {sizeRun.name}
            </div>
          </Tooltip>
          {((expanded || checked) && onMaximizeClick) ? (
            <DartButton
              loading={loading}
              disabled={loading || disabled}
              size="sm"
              icon="Expand"
              onClick={onMaximizeClick}
              type={hasError ? "danger_full" : "secondary"}
              label={maximizeText || "Expand"}
            />
          ) : <></>}
        </div>
        <div
          className={`right ${disabled ? "cursor-not-allowed" : ""}`}
          onClick={() => (expandedContent && !disabled ? onExpandToggle?.() : false)}
        >
          {expandedContent && onExpandToggle ? (
            <div className="collapse-arrow">
              {expanded ? (
                <Icon
                  name="DropdownArrow"
                  style={{ transform: "rotate(180deg)" }}
                />
              ) : (
                <Icon
                  name="DropdownArrow"
                  style={{ transform: "rotate(-90deg)" }}
                />
              )}
            </div>
          ) : ""}
        </div>
      </div>
      {expandedContent && (
      <div className={`expand-content ${expanded ? "visible" : ""} ${extraContentClass}`}>
        {expandedContent}
      </div>
      )}
    </div>
  );
};

export default SizeRunCollapse;
