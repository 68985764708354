/* eslint-disable max-len,no-case-declarations */
import moment from "moment";
import { getNumberBetween } from "./index";

const faker = (type?: "word" | "string" | "number" | "boolean" | "division" | "department" | "date" | "uuid" | "email", length = 100) => {
  const randomLorem = lorems[getNumberBetween(-1, lorems.length - 1)];
  const splittedArr = randomLorem.split(" ");

  switch (type) {
    case "uuid":
      const endings = ["XL", "LG", "MD", "SM", "XS"];
      return `${getNumberBetween(1000000, 9999999).toString()}-${endings[getNumberBetween(-1, endings.length - 1)]}`;
    case "date":
      return moment().subtract(getNumberBetween(1, 100), "day").toISOString();
    case "boolean":
      return getNumberBetween(-1, 100) > 50;
    case "number":
      return getNumberBetween(-1, length || 100);
    case "division":
      return divisionArr[getNumberBetween(-1, divisionArr.length - 1)];
    case "department":
      return departmentArr[getNumberBetween(-1, departmentArr.length - 1)];
    case "word":
      return splittedArr[getNumberBetween(-1, splittedArr.length - 1)]
        .replaceAll(",", "")
        .replaceAll(".", "");
    case "email":
      return `${splittedArr[getNumberBetween(-1, splittedArr.length - 1)]
        .replaceAll(",", "")
        .replaceAll(".", "")
        .replaceAll(" ", "")}@fake.com`;
    case "string":
    default:
      const startAt = getNumberBetween(-1, randomLorem.length - length > 1 ? randomLorem.length - length : 0);
      return randomLorem.substring(startAt, startAt + length);
  }
};

const divisionArr: any = ["man", "woman", "kid"];

const departmentArr: any = ["footwear", "clothes", "accessories", "homecare"];

const lorems = [
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam iaculis eget nisi varius sollicitudin. Pellentesque vulputate viverra orci, sit amet mollis elit laoreet id. Pellentesque consectetur sem ac massa pulvinar elementum. Etiam ac tincidunt libero, sed tristique ex. Aliquam vehicula nunc non fringilla pulvinar. Donec turpis turpis, rutrum id leo et, venenatis viverra dolor. Proin rhoncus egestas massa in interdum. Nulla maximus lobortis mauris eget ultricies. Sed vitae tortor leo. Nulla convallis metus ac lorem ultrices, sed suscipit nunc euismod. Suspendisse sit amet elit consectetur, pretium augue non, condimentum orci.",
  "Duis dapibus purus leo, et rutrum mi tempus a. Ut vel euismod sem. Proin tincidunt mi sed ligula mattis auctor. Maecenas efficitur a est a dignissim. Phasellus in augue at mi dignissim porta. Integer id tempor nibh. Suspendisse venenatis sit amet sem vitae venenatis. Fusce ipsum nisl, scelerisque et pellentesque id, vehicula porttitor tortor. Aenean aliquam, leo vel elementum viverra, ante turpis facilisis sapien, sed tempor purus mi ut leo.",
  "Maecenas sed turpis tellus. Nulla tincidunt vulputate magna, sed gravida ex consectetur ac. Suspendisse potenti. Nunc nec commodo ligula, id faucibus enim. Nunc fringilla mollis massa, interdum pharetra lorem rutrum at. Nunc condimentum bibendum pharetra. Nullam dignissim sem quis ullamcorper mollis. Nulla a tortor id purus pulvinar tincidunt. Duis fringilla lobortis massa, vel tincidunt leo imperdiet sit amet. Nunc lacinia mattis bibendum. In hac habitasse platea dictumst. Sed eget mattis est. Mauris in urna quis lorem vestibulum faucibus at tincidunt eros. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Curabitur hendrerit, odio eget dapibus finibus, leo libero scelerisque neque, at scelerisque arcu lectus eu leo.",
  "Nam vel tempor diam. Proin sit amet iaculis mi. Mauris libero dolor, tincidunt rhoncus elit nec, consectetur faucibus ex. Aenean bibendum, turpis at laoreet aliquam, turpis ipsum tincidunt nulla, vitae luctus mauris ligula eu lorem. In ipsum lectus, consectetur at ullamcorper a, finibus at metus. Praesent ac diam vitae ipsum porttitor suscipit at quis mauris. Aliquam iaculis urna ut hendrerit ultrices. Cras finibus facilisis orci, ut imperdiet odio efficitur id. Morbi lectus est, ultricies id iaculis eu, imperdiet in mauris.",
  "Cras mattis dui quam, vel vehicula velit ultricies a. Pellentesque porttitor enim eu nulla dignissim, in molestie diam scelerisque. Mauris gravida hendrerit laoreet. Fusce consequat rhoncus placerat. Sed mollis lobortis sodales. Aliquam erat volutpat. Donec pharetra elementum ex. Cras mollis massa lectus, eu tincidunt augue faucibus et. In hac habitasse platea dictumst. Phasellus sit amet euismod augue, in convallis tortor.",
  "Nulla commodo lobortis ante, nec varius augue feugiat a. Donec vel dui orci. Vivamus pulvinar quam eu ultricies sodales. In mollis nec nisi quis eleifend. Proin vehicula vel enim sit amet facilisis. Donec id tortor sit amet magna sodales imperdiet vel ut neque. Curabitur egestas diam ut viverra dapibus. Donec in nisl eu enim suscipit accumsan. Vivamus accumsan mi dolor.",
  "Curabitur in ipsum malesuada, rutrum mauris et, hendrerit quam. Ut cursus tempus fermentum. Etiam auctor tellus nec nulla viverra interdum. Nunc scelerisque sagittis felis, non commodo ex molestie quis. Nulla sed dignissim enim. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nam eleifend dignissim sodales. Nunc a condimentum metus, quis rhoncus dui. Donec fringilla elit consequat purus pharetra laoreet. Donec malesuada, nulla eget varius pulvinar, dui nibh tincidunt nunc, quis mollis magna erat ut dolor. Fusce sed finibus mauris, nec imperdiet lectus.",
  "Vivamus sit amet turpis aliquet, auctor nibh vitae, luctus sapien. Nullam purus ex, pulvinar eu lacinia at, ultricies eu mi. Donec sed sodales dui, sed tempor mauris. Phasellus tristique posuere eros vitae tincidunt. Suspendisse finibus scelerisque nunc a pretium. Vivamus scelerisque ex non tristique dignissim. Ut eget lectus ac ligula pharetra molestie at et enim. Nulla est lectus, vulputate sit amet arcu eget, vulputate interdum leo. Praesent rutrum dui vel ipsum bibendum, sed pulvinar mauris consequat. Curabitur nulla dolor, eleifend ut consectetur at, luctus eget diam. Vivamus quam urna, vestibulum dignissim metus vitae, cursus ornare ipsum. Suspendisse potenti. Praesent pulvinar consequat nibh, et pharetra ante blandit at.",
  "Phasellus ultrices ligula ipsum, nec posuere risus maximus vitae. Proin consectetur sapien et libero tempor, nec tincidunt lectus lobortis. Donec at rhoncus neque. Sed fringilla augue diam, eu convallis est efficitur sed. Donec nec molestie enim. Donec eu mattis neque. Donec fermentum sem mauris, ac finibus enim accumsan venenatis. Suspendisse potenti. Vivamus et erat lorem. Sed quis justo ac metus viverra sodales. Sed elit libero, molestie ac commodo ut, tempus vitae justo. Vestibulum tincidunt orci purus, sed ultricies neque malesuada id. Aenean aliquam nibh leo, semper consequat felis pharetra quis. Duis facilisis viverra velit, at vehicula sem vehicula sit amet. Curabitur sed posuere nisl.",
  "Proin condimentum nisi in nisi aliquam pulvinar. Ut et ultrices libero. Aenean at eros scelerisque, feugiat libero vitae, pulvinar justo. Pellentesque sit amet ultricies nisl. Sed malesuada odio accumsan, accumsan lacus ut, ultrices arcu. Praesent non nisi blandit, pharetra elit dignissim, dignissim orci. Nullam turpis augue, eleifend ac eros ac, scelerisque aliquet erat. Vivamus suscipit est ac nulla vulputate vestibulum. Pellentesque ut scelerisque neque, ac pulvinar ligula. Nunc cursus leo a elit ultricies blandit. Quisque ac aliquet nisi, non facilisis metus. Pellentesque eu turpis varius, porttitor eros tristique, pulvinar est. Donec euismod quam eget ornare rutrum. In lobortis pharetra tortor, id varius sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin iaculis justo et turpis venenatis venenatis. ",
];

export default faker;
