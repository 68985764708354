import React, {
  useEffect, useMemo, useState,
} from "react";
import "./index.less";
import { Button, Spin } from "antd";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Link } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import PillSelect from "../../../../components/PillSelect";
import Icon from "../../../../components/Icon";
import { IStoreProfile } from "../../../../global/interfaces";
import {
  getAllStores, StoreListEmpty, StoreListItem, WarehouseAssignWidget,
} from "../../index";
import { selectedStoreAtom, storeListAtom } from "../../../../global/atoms";
import useEnums from "../../../../utils/hooks/useEnums";
import paths from "../../../../configs/paths";
import CompactSelect from "../../../../components/CompactSelect";
import { fetchAvailableTags } from "../../../common";
import {
  only, removeNullValues, renderTag,
} from "../../../../utils/helpers";

interface IStoreListView {
  storeFilters?: any;
  setStoreFilters?: (filters: any) => void;
}

const StoreListView:React.FC<IStoreListView> = ({
  storeFilters,
  setStoreFilters,
}) => {
  const { allocation_status } = useEnums();
  const setSelectedStore = useSetRecoilState(selectedStoreAtom);
  const [storeList, setStoreList] = useRecoilState(storeListAtom);
  const [expandedList, setExpandedList] = useState<number[]>([]);
  const [localStoreList, setLocalStoreList] = useState([]);
  const [availableTags, setAvailableTags] = useState<any>();
  const [filterByTags, setFilterByTags] = useState<any>([]);

  useEffect(() => {
    fetchAvailableTags(availableTags, setAvailableTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = "DART - STORE LIST";
  }, []);

  const COUNTERS_INIT_STATE = useMemo(() => {
    return {
      [allocation_status.PLANNING]: 0,
      [allocation_status.LIVE]: 0,
      [allocation_status.PAUSED]: 0,
      [allocation_status.CLOSED]: 0,
    };
  }, [allocation_status]);

  const [storeCounts, setStoreCounts] = useState<any>(COUNTERS_INIT_STATE);

  useEffect(() => {
    const localStoreCounts = { ...COUNTERS_INIT_STATE };

    // Move filtered stores to the local list
    if (storeList?.data && storeList?.data?.length > 0) {
      setLocalStoreList((storeList?.data || [])?.filter((store: IStoreProfile) => {
        return (storeFilters.status.indexOf(store.status) > -1 || storeFilters.status.indexOf("ALL") > -1)
          && (filterByTags?.length === 0 || (filterByTags?.length !== 0
              && store?.tags?.some((storeTag) => filterByTags?.indexOf(storeTag) > -1)
          )
          );
      })?.sort((a: any, b: any) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }));

      // Pill store counter
      storeList?.data?.map((store: IStoreProfile) => {
        localStoreCounts[store.status as any] += 1;
        return store;
      });
    } else {
      setLocalStoreList([]);
    }
    setStoreCounts(localStoreCounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeList, storeFilters.status, filterByTags]);

  const handleFetchStores = useDebouncedCallback((filters, filterByIds) => {
    getAllStores(
      storeList, setStoreList, filters, undefined, undefined, filterByIds,
    );
  }, 1200);

  useEffect(() => {
    const storeIdsOrNumbers = storeFilters?.storeIds?.length > 0 ? storeFilters?.storeIds?.length : (storeFilters?.storeNumbers || undefined);
    handleFetchStores(removeNullValues(only(["brand", "channel", "country", "division", "department"], storeFilters)) || {},
      storeIdsOrNumbers ? storeIdsOrNumbers?.[0]?.value?.split(",") : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    storeFilters.brand,
    storeFilters.channel,
    storeFilters.country,
    storeFilters?.storeIds,
    storeFilters?.division,
    storeFilters?.department,
    storeFilters?.storeNumbers,
  ]);

  const handleStoreEdit = (storeId: number) => {
    setSelectedStore(storeId);
  };

  const handleStoreCreate = () => {
    setSelectedStore(undefined);
  };

  const handleStoreCardToggle = (storeId: number) => {
    if (expandedList.includes(storeId)) {
      setExpandedList(expandedList.filter((id) => id !== storeId));
    } else {
      setExpandedList([...expandedList, storeId]);
    }
  };

  const handleExpandAll = () => {
    setExpandedList((storeList?.data || []).map((store: IStoreProfile) => store.id));
  };

  const handleCollapseAll = () => {
    setExpandedList([]);
  };

  const handleStatusSelection = (statuses: string[]) => {
    // logic to deselect every other status if ALL is selected and vice versa
    if ((statuses.includes("ALL") && !storeFilters?.status?.includes("ALL")) || statuses.length === 0) {
      setStoreFilters?.({
        ...(storeFilters || {}),
        status: ["ALL"],
      });
    } else if (statuses.includes("ALL") && storeFilters?.status?.includes("ALL") && statuses.length !== storeFilters?.status?.length) {
      setStoreFilters?.({
        ...(storeFilters || {}),
        status: statuses.filter((s) => s !== "ALL"),
      });
    } else {
      setStoreFilters?.({
        ...(storeFilters || {}),
        status: statuses,
      });
    }
  };

  return (
    <div className="store-list-view">
      <div className="store-list-status-selector">
        <PillSelect
          multiple
          selectOptions={allocation_status ? [
            {
              key: "all",
              value: "ALL",
              label: "All Stores",
            },
            {
              key: "planning",
              value: allocation_status.PLANNING,
              label: "Planning",
              count: storeCounts[allocation_status.PLANNING],
              disabled: storeCounts[allocation_status.PLANNING] === 0,
            },
            {
              key: "Live",
              value: allocation_status.LIVE,
              label: "Live",
              count: storeCounts[allocation_status.LIVE],
              disabled: storeCounts[allocation_status.LIVE] === 0,
            },
            {
              key: "Paused",
              value: allocation_status.PAUSED,
              label: "Paused",
              count: storeCounts[allocation_status.PAUSED],
              disabled: storeCounts[allocation_status.PAUSED] === 0,
            },
            {
              key: "Closed",
              value: allocation_status.CLOSED,
              label: "Closed",
              count: storeCounts[allocation_status.CLOSED],
              disabled: storeCounts[allocation_status.CLOSED] === 0,
            },
          ] : [{
            key: "all",
            value: "ALL",
            label: "All Stores",
          }]}
          onChange={(v) => (v ? handleStatusSelection(v) : false)}
          value={storeFilters?.status?.length === 0 ? ["ALL"] : storeFilters?.status}
        />
        <div className="tag-select-wrapper">
          <CompactSelect
            placeholder="Filter by Tags"
            selectPlaceholder="count"
            customMaxTagPlaceholder={(e) => (e?.length === 1
              ? `${renderTag(e?.[0]?.label)}`
              : `${e?.length} out of ${availableTags?.data?.length} tags`)}
            selectOptions={(availableTags?.data || [])
              ?.map((tag: any) => { return { value: tag, label: renderTag(tag) }; })}
            mode="multiple"
            canClear
            showSearch
            onChange={(tags) => setFilterByTags(tags)}
            hasSelectAll
          />
        </div>
      </div>
      <div className="store-list-wrapper">
        <div className="store-list-header">
          <div className="left">
            <Button onClick={handleExpandAll}>
              <Icon name="Expand" />
              <span>Expand All</span>
            </Button>
            <Button onClick={handleCollapseAll}>
              <Icon name="Collapse" />
              <span>Collapse All</span>
            </Button>
          </div>
          {storeList.status === "revalidate" ? <Spin className="revalidate-spin" /> : <></>}
          <div className="right">
            <WarehouseAssignWidget />
            <Link
              to={paths.new_store}
              onClick={handleStoreCreate}
            >
              <Button
                type="ghost"
              >
                <span className="icon-wrapper">
                  <Icon
                    name="Plus"
                    color="white"
                    size={9}
                  />
                </span>
                <span>Add Store</span>
              </Button>
            </Link>
          </div>
        </div>
        <hr />
        {
          storeList.status === "request" ? (
            <Spin />
          ) : (
            <div className="store-list">
              {localStoreList?.length > 0 ?
                localStoreList.map((store: IStoreProfile) => (
                  <StoreListItem
                    key={store.id || store.number}
                    store={store}
                    onStoreEdit={handleStoreEdit}
                    expanded={!!expandedList.find((id) => id === store.id)}
                    onExpandToggle={() => (store.id ? handleStoreCardToggle(store.id) : false)}
                  />
                ))
                : (<StoreListEmpty />)}
            </div>
          )
        }
      </div>
    </div>
  );
};

export default StoreListView;
