import React, { useEffect, useState } from "react";
import "./index.less";
import { Button, Select } from "antd";
import { ISelectOption } from "../../global/interfaces";
import Icon from "../Icon";
import DartInput from "../DartInput";

interface ICompactSelect {
  value?: ISelectOption | ISelectOption[];
  onChange?: (selected?: string | string[]) => void;
  selectOptions?: ISelectOption[];
  disabled?: boolean;
  showArrow?: boolean;
  showSearch?: boolean;
  hasBorder?: boolean;
  placeholder?: string;
  canClear?: boolean;
  popupContainer?: HTMLElement,
  selectPlaceholder?: "default" | "count";
  customMaxTagPlaceholder?: (selectedTags: any[] | undefined) => React.ReactNode;
  mode?: undefined | "multiple";
  allSelectedByDefault?: boolean;
  defaultValue?: ISelectOption | ISelectOption[];
  hasSelectAll?: boolean;
  hasCustomSearch?: boolean;
}

const { Option } = Select;

const CompactSelect: React.FC<ICompactSelect> = ({
  value = undefined,
  onChange,
  selectOptions,
  disabled,
  showArrow = true,
  showSearch = false,
  hasBorder = false,
  placeholder = "Select",
  canClear = true,
  popupContainer,
  selectPlaceholder = "default",
  customMaxTagPlaceholder = undefined,
  mode = undefined,
  allSelectedByDefault = false,
  defaultValue,
  hasSelectAll = false,
  hasCustomSearch = false,
}) => {
  const [selected, setSelected] = useState<ISelectOption | ISelectOption[] | undefined>(defaultValue || value);
  const [selectable, setSelectable] = useState<ISelectOption[] | undefined>(selectOptions);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setSelectable(selectOptions);
  }, [selectOptions]);

  const handleChange = (selectedValue: any) => {
    setSelected(selectedValue);
    onChange?.(selectedValue);
    setSearchValue("");
  };

  const handleSelectAll = (deselect = false) => {
    if (deselect) {
      setSelected([]);
      onChange?.([]);
    } else {
      setSelected((selectOptions || [])?.map((option) => option.value) as any);
      onChange?.((selectOptions || [])?.map((option) => option.value) as any);
    }
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    if (allSelectedByDefault) {
      setSelected((selectOptions || [])?.map((option) => option.value) as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSelectedByDefault]);

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (searchVal: any) => {
    setSearchValue(searchVal?.target?.value || "");
    if (searchVal) {
      const filteredOptions = (selectOptions || [])
        .filter((option) => option?.label?.toLowerCase().includes((searchVal?.target?.value || "").toLowerCase()));
      setSelectable(filteredOptions);
    } else {
      setSelectable(selectOptions);
    }
  };

  return (
    <div
      className={`dart-compact-select
      ${selectPlaceholder === "count" ? "variable-width" : ""}
      ${!hasBorder ? "no-border" : ""}
      ${mode === "multiple" && (selected as any)?.length === selectOptions?.length ? "all-selected" : ""}
      ${mode === "multiple" && (selected as any)?.length === 0 ? "none-selected" : ""}`}
    >
      <Select
        tabIndex={-1}
        defaultValue={defaultValue || selected}
        dropdownClassName="dart-compact-select-dropdown"
        value={selected}
        onChange={(val) => handleChange(val)}
        disabled={!!disabled}
        showArrow={!disabled && showArrow}
        showSearch={showSearch}
        placeholder={placeholder}
        allowClear={canClear}
        maxTagCount={selectPlaceholder === "count" ? 0 : 2}
        maxTagPlaceholder={(selectedTags) => {
          if (customMaxTagPlaceholder) return customMaxTagPlaceholder(selectedTags);
          return selectPlaceholder === "count"
            ? `${selectOptions?.length} (${selectedTags.length === selectOptions?.length ? "All" : selectedTags.length})`
            : undefined;
        }}
        suffixIcon={(
          <Icon
            name="DropdownArrow"
            className="ant-select-suffix"
          />
        )}
        getPopupContainer={(trigger) => popupContainer || trigger.parentElement as HTMLElement}
        mode={mode}
        dropdownRender={hasSelectAll || hasCustomSearch ? (menu) => {
          return (
            <>
              {hasCustomSearch && (
                <div className="dropdown-search">
                  <DartInput
                    transparent
                    onChange={handleSearch}
                    type="text"
                    placeholder="Search"
                    label={undefined}
                    value={searchValue}
                  />
                </div>
              )}
              <div className="dropdown-menu">
                {menu}
              </div>
              {hasSelectAll && (
                <div className="dropdown-actions">
                  <Button onClick={() => handleSelectAll()}>Select All</Button>
                  <Button onClick={() => handleSelectAll(true)}>Deselect All</Button>
                </div>
              )}
            </>
          );
        } : undefined}
      >
        {selectable?.map((opt) => {
          return (
            <Option
              key={opt.key ?? opt.value}
              value={opt.value}
              disabled={!!opt.disabled}
            >
              {opt.label}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default CompactSelect;
