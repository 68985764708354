import React from "react";
import "./index.less";
import { Avatar, Button } from "antd";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { createHash } from "crypto";
import Icon from "../../../../components/Icon";
import { capitalize } from "../../../../utils/helpers";
import { IUser } from "../../../../global/interfaces";

interface IUserListCardItem {
  user: IUser ;
  onUpdate?: (user?: IUser) => void;
  authUser?: any;
}

const UserListCardItem:React.FC<IUserListCardItem> = ({
  user, onUpdate, authUser,
}) => {
  return (
    <div className={`user-list-card-item ${user.enabled === false ? "disabled" : ""}`}>
      <div className="top">
        <div className="left">
          <Avatar
            className="avatar"
            src={`https://robohash.org/${user?.username ? createHash("sha256").update(user.username).digest("hex") : "johndoe"}.png?bgset=bg2`}
            size="default"
          />
          {authUser?.username === user?.username && (
            <div className="you-indicator">
              YOU
            </div>
          )}
        </div>
        <div className="right">
          <div className="name">{user.name || "N/A"}</div>
          <div className="position">{user.job_designation || "N/A"}</div>
          <div className="badges">
            <div className="role-badge">
              {user.role ? capitalize(user.role) : "Role N/A"}
            </div>
            <div className="region-badge">
              {user.region ? capitalize(user.region) : "Region N/A"}
            </div>
          </div>
        </div>
        {onUpdate && (
          <div className="actions">
            <Button
              type="ghost"
              className="edit-button"
              onClick={() => onUpdate && onUpdate(user)}
            >
              <Icon
                name="Edit"
                color="#333E47"
                size={16}
              />
            </Button>
          </div>
        )}
      </div>
      <hr />
      <div className="bottom">
        <div className="email">
          <MailOutlined />
          {user.email || "N/A"}
        </div>
        <div className="phone">
          <PhoneOutlined />
          {user.phone_number || "N/A"}
        </div>
      </div>
    </div>
  );
};

export default UserListCardItem;
