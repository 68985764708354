import React from "react";
import { message, notification } from "antd";
import apiClient from "../../../../api/apiClient";
import endpoints from "../../../../api/endpoints";
import {
  numberToStringFloat, showErrorMessage, stringFloatToNumber,
} from "../../../../utils/helpers";
import { divisionDepartmentMapping } from "../../../../configs/constants";
import { IChannelTypes } from "../../../../global/atoms/global-filters-atom";

const getSizeProfile = async (
  sizeProfile: any,
  setSizeProfile: any,
  sizeRunId: number,
  sizeRunAll?: string,
  sizeProfileChannel: "ALL" | IChannelTypes = "ALL",
) => {
  try {
    if (!sizeProfile?.[sizeRunId]?.data || sizeProfile?.[sizeRunId]?.status !== "success") {
      setSizeProfile((prev: any) => { return { ...prev, [sizeRunId]: { status: "request", data: sizeProfile?.[sizeRunId]?.data || {} } }; });
      const params = sizeProfileChannel === "ALL" ? {} : { channel: sizeProfileChannel };
      const res:any = await apiClient.get(endpoints.sizeProfile.replace(":id", sizeRunId.toString()), { params });
      setSizeProfile((prev:any) => { return { ...prev, [sizeRunId]: { status: "success", data: transformSizeProfiles(res?.data?.data, sizeRunAll) || {} } }; });
    }
  } catch (error: any) {
    setSizeProfile((prev: any) => { return { ...prev, [sizeRunId]: { status: "failed", data: {} } }; });
    showErrorMessage(error, "Failed to get size profile");
  }
};

const updateSizeProfile = async (
  sizeProfile: any, selectedSizeRuns: number[], cleanup?: (() => void)[],
) => {
  try {
    let sizeRunsUpdated = {};
    let sizeProfileCount = 0;
    // [{ "size_run_id": 1, "store_id": 1, "size": "XL", "contribution": 1000 }, ...]
    const params: any = { updates: [], headerRow: ["size_run_id", "store_id", "size", "contribution"] };
    const sizeRunProfiles: any = Object.values(sizeProfile);
    for (let i = 0; i < sizeRunProfiles?.length; i += 1) {
      const sizesPerStorePerRunList: any = Object.values(sizeRunProfiles[i]?.data);
      for (let o = 0; o < sizesPerStorePerRunList?.length; o += 1) {
        const sizeRunId = sizesPerStorePerRunList[o]?.size_run_id;
        if (selectedSizeRuns.indexOf(sizeRunId) !== -1) {
          sizeRunsUpdated = { ...sizeRunsUpdated, [sizeRunId]: true };
          const storeId = sizesPerStorePerRunList[o]?.store_id;
          const sizeList = sizesPerStorePerRunList[o]?.sizes;
          for (let k = 0; k < sizeList.length; k += 1) {
            const {
              size, contribution, updated,
            } = sizeList[k];
            if (updated !== contribution) {
              sizeProfileCount += 1;
              params.updates.push([
                sizeRunId,
                storeId,
                size,
                numberToStringFloat(updated),
              ]);
            }
          }
        }
      }
    }
    if (sizeProfileCount > 0 && Object.keys(sizeRunsUpdated).length > 0) {
      await apiClient.patch(endpoints.updateSizeProfiles, params);
      message.success(`${sizeProfileCount} profiles in ${Object.keys(sizeRunsUpdated).length} size run has been successfully updated`);
    } else {
      message.info("There are no changes to update");
    }
    if (cleanup) {
      for (let c = 0; c < cleanup?.length; c += 1) {
        cleanup[c]?.();
      }
    }
  } catch (error: any) {
    showErrorMessage(error, "Failed to update size profiles");
  }
};

const getUpdatableSPs = async (
  updatableSPs: any,
  setUpdatableSPs: any,
  sizeProfileChannel: "ALL" | IChannelTypes = "ALL",
  cb?: () => void,
) => {
  try {
    setUpdatableSPs({
      status: "request",
      groupData: updatableSPs?.groupData || {},
      isSalesTransactionComplete: updatableSPs?.isSalesTransactionComplete || undefined,
      rawData: updatableSPs?.rawData || [],
    });
    let list: any[] = [];
    let isSalesTransactionComplete = false;

    const possibleCombinations = Object.keys(divisionDepartmentMapping).map((division) => {
      return (divisionDepartmentMapping as any)[division]?.map((department: any) => {
        return { division, department };
      });
    }).flat(1);

    const awaitFor = [];
    for (let i = 0; i < possibleCombinations.length; i += 1) {
      const { division, department } = possibleCombinations[i];
      if (division !== "NA" && department !== "NA") {
        awaitFor.push(apiClient.get(endpoints.updatableSizeProfiles, sizeProfileChannel && sizeProfileChannel !== "ALL"
          ? {
            params: {
              channel: sizeProfileChannel, division, department,
            },
          }
          : { params: { division, department } }));
      } else {
        awaitFor.push(apiClient.get(endpoints.updatableSizeProfiles, sizeProfileChannel && sizeProfileChannel !== "ALL"
          ? {
            params: {
              channel: sizeProfileChannel,
            },
          }
          : { params: {} }));
      }
    }

    // Await for all responses from all combinations
    await Promise.all(awaitFor).then((responses) => {
      list = responses.map((response) => (response?.data as any)?.data || []).flat(1);
      isSalesTransactionComplete = responses.map((response) => (response?.data as any)?.is_sale_transaction_complete)
        ?.every((item) => item === true);
    }).catch((error) => {
      showErrorMessage(error, "Failed to get updatable size profiles");
      setUpdatableSPs({
        status: "failed",
        data: {},
        isSalesTransactionComplete: undefined,
        rawData: [],
      });
    });

    const uniqueKeysList:any = {};

    list?.map((item: any) => {
      const key = item?.size_run_id;
      if (!uniqueKeysList?.hasOwnProperty(key)) {
        uniqueKeysList[key] = [item];
      } else {
        uniqueKeysList[key].push(item);
      }
      return item;
    });

    const groups: any = {};

    Object.values(uniqueKeysList).forEach((itemsList: any) => {
      const sizeRunId = itemsList[0]?.size_run_id;

      if (!groups.hasOwnProperty(sizeRunId)) {
        groups[sizeRunId] = {};
      }

      if (itemsList.length > 0) {
        groups[sizeRunId] = { status: "success", data: transformSizeProfiles(itemsList) };
      }
    });

    setUpdatableSPs({
      status: "success",
      groupData: groups,
      isSalesTransactionComplete,
      rawData: list,
    });

    cb?.();
  } catch (error: any) {
    setUpdatableSPs({
      status: "failed",
      data: {},
      isSalesTransactionComplete: undefined,
      rawData: [],
    });
    showErrorMessage(error, "Failed to get updatable Size Profile values");
  }
};

const importSizeProfiles = async (
  chunkedData: any, callback: () => void, failedCallback: () => void,
) => {
  const BATCH_SIZE = 7000;
  const FAILED_BATCHES: any = [];

  await Promise.all(Object.keys(chunkedData)?.map(async (chunkId: string) => {
    const chunks = [];
    for (let i = 0; i < chunkedData?.[chunkId].length; i += BATCH_SIZE) {
      const chunk = chunkedData?.[chunkId].slice(i, i + BATCH_SIZE);
      chunks.push(chunk);
    }
    await Promise.all(chunks?.map(async (chunk: any) => {
      await apiClient.patch(endpoints.updateSizeProfiles, {
        updates: chunk?.map((item: any) => ([item?.size_run_id, item?.store_id, item?.size, item?.contribution])),
        headerRow: ["size_run_id", "store_id", "size", "contribution"],
      })?.catch(() => {
        const chunkName = `${chunk?.[0]?.division} - ${chunk?.[0]?.department}`;
        if (chunk?.[0]?.division && chunk?.[0]?.department && !FAILED_BATCHES?.includes(chunkName)) {
          FAILED_BATCHES.push(chunkName);
        }
      });
    }));
  })).then(() => { // SUCCESS
    message.success("Size Profile values has been successfully imported");
    callback?.();
  }, () => { // FAILED
    message.error("Failed to import one or more size profile values");
    notification.error({
      message: "Failed Import List:",
      description: (
        <ul style={{ paddingLeft: 24, margin: 0 }}>
          {FAILED_BATCHES?.map((e: string) => {
            return (
              <li>{e}</li>
            );
          })}
        </ul>
      ),
      duration: 0,
      placement: "bottomRight",
    });
    failedCallback?.();
  });
};

const mapSizeToNumber = (size: string) => {
  switch (size) {
    case "One Size":
    case "XXS":
      return 0;
    case "XS":
      return 1;
    case "S":
      return 2;
    case "M":
      return 3;
    case "L":
      return 4;
    case "XL":
      return 5;
    case "XXL":
      return 6;
    default: return parseInt(size, 10);
  }
};

const calculateInitialSizeContributions = (setSizeProfiles: any) => {
  setSizeProfiles((prev: any) => {
    const newRawData = prev?.rawData?.map((item: any) => {
      const sizeRunStoreData = prev?.groupData?.[item?.size_run_id]?.data?.[item?.store_id];
      const storeTotals = sizeRunStoreData?.sizes?.reduce((acc: any, cur: any) => acc + (cur?.total || 0), 0);

      return {
        ...item,
        contribution: numberToStringFloat((item?.total / storeTotals) * 10000),
        updated: numberToStringFloat((item?.total / storeTotals) * 10000),
      };
    });

    const newUniqueKeysList:any = {};

    newRawData?.map((item: any) => {
      const key = item?.size_run_id;
      if (!newUniqueKeysList?.hasOwnProperty(key)) {
        newUniqueKeysList[key] = [item];
      } else {
        newUniqueKeysList[key].push(item);
      }
      return item;
    });

    const newGroups: any = {};

    Object.values(newUniqueKeysList).forEach((itemsList: any) => {
      const sizeRunId = itemsList[0]?.size_run_id;

      if (!newGroups.hasOwnProperty(sizeRunId)) {
        newGroups[sizeRunId] = {};
      }

      if (itemsList.length > 0) {
        newGroups[sizeRunId] = { status: "success", data: transformSizeProfiles(itemsList) };
      }
    });

    // Return updated state with groupData updated from newRawData. Also update rawData without changing state status
    return {
      ...prev,
      groupData: newGroups,
      rawData: newRawData,
    };
  });
};

const transformSizeProfiles = (data: any, sizeRunAll?: string) => {
  if (!data) return {};
  if (Object.keys(data)?.length === 0) return {};

  const sizeProfileGroups = data.reduce((acc:any, sizeProfile: any) => {
    const { store_id } = sizeProfile;
    if (!acc[store_id]) {
      acc[store_id] = {
        store_id: null, size_run_id: null, sizes: [],
      };
    }
    if (!acc[store_id]?.totalContribution) {
      acc[store_id].totalContribution = 0;
    }
    const contrib = stringFloatToNumber(sizeProfile?.contribution);
    const updatedContrib = stringFloatToNumber((sizeProfile?.updated || Number(sizeProfile?.updated) === 0
      ? sizeProfile?.updated : sizeProfile?.contribution));
    acc[store_id].store_id = sizeProfile?.store_id;
    acc[store_id].size_run_id = sizeProfile?.size_run_id;
    acc[store_id].size_run_all = sizeRunAll;
    acc[store_id].totalContribution += (updatedContrib || Number(updatedContrib) === 0 ? updatedContrib : contrib);
    acc[store_id].sizes.push({
      id: sizeProfile?.id,
      contribution: sizeProfile?.contribution ? contrib : "",
      updated: sizeProfile?.contribution || sizeProfile?.updated ? updatedContrib : "",
      size: sizeProfile?.size,
      total: sizeProfile?.total,
      updated_total: (sizeProfile?.updated_total || Number(sizeProfile?.updated_total) === 0 ? sizeProfile?.updated_total : sizeProfile?.total),
      is_sister_store: sizeProfile?.is_sister_store,
    });

    return acc;
  }, {});

  const sortedSizeProfileGroups:any = {};
  Object.keys(sizeProfileGroups).forEach((storeId) => {
    if (!sortedSizeProfileGroups[storeId]) {
      sortedSizeProfileGroups[storeId] = { ...sizeProfileGroups[storeId] };
    }
    if (!sortedSizeProfileGroups[storeId].sizes) {
      sortedSizeProfileGroups[storeId].sizes = [];
    }
    sortedSizeProfileGroups[storeId].sizes = sizeProfileGroups[storeId].sizes.sort((a:any, b:any) => {
      if (typeof a.size === "string") {
        return mapSizeToNumber(a.size) - mapSizeToNumber(b.size);
      }
      return a.size - b.size;
    });
  });

  return sortedSizeProfileGroups;
};

export {
  getSizeProfile,
  updateSizeProfile,
  getUpdatableSPs,
  importSizeProfiles,
  transformSizeProfiles,
  calculateInitialSizeContributions,
};
