import React, { useMemo } from "react";
import { useSetRecoilState } from "recoil";
import { IReplenishmentCC } from "../../../../global/interfaces";
import "./index.less";
import columns from "./columns";
import { SelectedStyleColorBlockAdvanced } from "../../index";
import DartTable from "../../../../components/DartTable";
import { onSKUStoreSendMoreUpdate, onStoreExcludedToggle } from "../../services/calculations";
import { manualReplenishmentAtom } from "../../../../global/atoms";

interface IReplenishSKUDetails {
  skuDetails?: IReplenishmentCC;
}

const ReplenishSKUDetails:React.FC<IReplenishSKUDetails> = ({
  skuDetails,
}) => {
  const setManualReplenishmentState = useSetRecoilState(manualReplenishmentAtom);

  // Memoized Columns with props provided
  const getColumns = useMemo(() => {
    return columns({
      onSendMoreChange: (
        value: string, storeId: number, rowData: IReplenishmentCC,
      ) => (value ? onSKUStoreSendMoreUpdate(
        +value, storeId, { ...rowData, cc: skuDetails?.cc }, setManualReplenishmentState,
      ) : false),
      onExcludeToggle: (value: boolean, rowData: any) => (skuDetails ? onStoreExcludedToggle(
        value, rowData, { ...rowData, cc: skuDetails?.cc }, setManualReplenishmentState,
      ) : false),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="replenish-sku-details"
    >
      <div className="table-heading-section">
        {skuDetails && (
          <SelectedStyleColorBlockAdvanced
            key={skuDetails.cc}
            data={skuDetails}
            theme="dark"
            open
          />
        )}
      </div>
      <div className="table-wrapper">
        <DartTable
          columns={getColumns}
          data={skuDetails?.stores}
          tableStyle="dark"
          height="calc(100vh - 530px)" // Overflowing Final Review
          // height="calc(100vh - 680px)" // Not overflowing Final Review
        />
      </div>
    </div>
  );
};

export default ReplenishSKUDetails;
