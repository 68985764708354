import apiClient from "../../../api/apiClient";
import endpoints from "../../../api/endpoints";
import { showErrorMessage } from "../../../utils/helpers";

const getApiLogs = async (
  apiLogs: any, setApiLogs: any, isRevalidate = false,
) => {
  try {
    setApiLogs({
      status: isRevalidate ? "revalidate" : "request", data: apiLogs?.data || [],
    });

    const res: any = await apiClient.get(endpoints.api_logs);

    setApiLogs({
      status: "success", data: res?.data?.data,
    });
  } catch (error: any) {
    setApiLogs({
      status: "failed", data: [],
    });
    showErrorMessage(error, "Failed to get API Logs");
  }
};

const getApiLogDetail = async (logId: number) => {
  try {
    const res: any = await apiClient.get(endpoints.api_logs_detail.replace(":id", logId?.toString()));
    return res?.data?.data;
  } catch (error: any) {
    showErrorMessage(error, `Failed to get API Log Details for log ID: ${logId}`);
    return null;
  }
};

export {
  getApiLogs,
  getApiLogDetail,
};
