import { message } from "antd";
import apiClient from "../../../../api/apiClient";
import endpoints from "../../../../api/endpoints";
import { removeNullValues, showErrorMessage } from "../../../../utils/helpers";
import { IChannelTypes } from "../../../../global/atoms/global-filters-atom";

const getSizeRunList = async (
  sizeRunList: any,
  setSizeRunList: any,
  sizeRunChannel: "ALL" | IChannelTypes = "ALL",
  shouldGetStores = true,
  filterStoreListByIds?: string[],
) => {
  try {
    if (sizeRunList.data.length === 0) {
      setSizeRunList({
        status: "request",
        data: sizeRunList.data || [],
        channelStoreList: sizeRunList.channelStoreList || [],
        originalData: sizeRunList.originalData || [],
      });
    } else {
      setSizeRunList({
        status: "revalidate",
        data: sizeRunList?.data || [],
        channelStoreList: sizeRunList.channelStoreList || [],
        originalData: sizeRunList.originalData || [],
      });
    }
    const res:any = await apiClient.get(endpoints.sizeRunList);
    const transformedData: any = {};
    if (res?.data && res?.data?.data) {
      (res?.data?.data || [])?.map((sizeRun: any) => {
        if (!transformedData[sizeRun.division]) {
          transformedData[sizeRun.division] = {};
        }
        if (!transformedData[sizeRun.division][sizeRun.department]) {
          transformedData[sizeRun.division][sizeRun.department] = [];
        }
        transformedData[sizeRun.division][sizeRun.department].push(sizeRun);
        return transformedData;
      });
    }

    if (shouldGetStores && (!sizeRunList.channelStoreList || !sizeRunList.channelStoreList?.length)) {
      const channelStoreRes: any = await apiClient.get(endpoints.store + (sizeRunChannel !== "ALL"
        ? `?channel=${sizeRunChannel}&status=LIVE&status=PAUSED&status=PLANNING`
        : "?status=LIVE&status=PAUSED&status=PLANNING"));

      const stores = filterStoreListByIds
        ? channelStoreRes?.data?.stores?.filter((store: any) => filterStoreListByIds.includes(store.id?.toString()))
        : channelStoreRes?.data?.stores;

      setSizeRunList({
        status: "success",
        data: transformedData || [],
        channelStoreList: stores || [],
        originalData: res?.data?.data || [],
      });
    } else {
      const stores = filterStoreListByIds
        ? sizeRunList.channelStoreList?.filter((store: any) => filterStoreListByIds.includes(store.id?.toString()))
        : sizeRunList.channelStoreList;

      setSizeRunList({
        status: "success",
        data: transformedData || [],
        channelStoreList: stores || [],
        originalData: res?.data?.data || [],
      });
    }
  } catch (error: any) {
    setSizeRunList({
      status: "failed",
      data: [],
      channelStoreList: [],
      originalData: [],
    });
    showErrorMessage(error, "Failed to get size run list");
  }
};

const createSizeRun = async (
  sizeRun: any, sizeRunList: any, setSizeRunList: any, setLoading?: any, callback?: any,
) => {
  try {
    setLoading?.(true);
    await apiClient.post(endpoints.sizeRunList, removeNullValues(sizeRun));
    await getSizeRunList(
      sizeRunList, setSizeRunList, "ALL", false,
    );
    message.success(`Size run '${sizeRun?.name}' created successfully`);
    setLoading?.(false);
    callback?.();
  } catch (error: any) {
    setLoading?.(false);
    showErrorMessage(error, "Failed to create size run");
  }
};

const updateSizeRun = async (
  sizeRun: any, sizeRunList: any, setSizeRunList: any, setLoading?: any,
) => {
  try {
    setLoading?.(true);
    await apiClient.patch(endpoints.sizeRunUpdate?.replace(":id", sizeRun?.id), sizeRun);
    await getSizeRunList(
      sizeRunList, setSizeRunList, "ALL", false,
    );
    message.success(`Size run '${sizeRun?.name}' updated successfully`);
    setLoading?.(false);
  } catch (error: any) {
    setLoading?.(false);
    showErrorMessage(error, "Failed to update size run");
  }
};

const deleteSizeRun = async (
  sizeRun: any, sizeRunList: any, setSizeRunList: any, setLoading?: any,
) => {
  try {
    setLoading?.(true);
    await apiClient.delete(endpoints.sizeRunUpdate?.replace(":id", sizeRun?.id));
    await getSizeRunList(
      sizeRunList, setSizeRunList, "ALL", false,
    );
    message.success("Size run deleted successfully");
    setLoading?.(false);
  } catch (error: any) {
    setLoading?.(false);
    showErrorMessage(error, "Failed to delete size run");
  }
};

export {
  getSizeRunList,
  createSizeRun,
  updateSizeRun,
  deleteSizeRun,
};
