import { message } from "antd";
import apiClient from "../../../api/apiClient";
import endpoints from "../../../api/endpoints";
import { only, showErrorMessage } from "../../../utils/helpers";

export const getUsers = async (users: any, setUsers: any) => {
  try {
    setUsers({ status: "request", data: users.data || [] });
    const res: any = await apiClient.get(endpoints.usersList);
    setUsers({ status: "success", data: res?.data?.data || [] });
  } catch (error: any) {
    setUsers({ status: "failed", data: [] });
    showErrorMessage(error, "Failed to get users");
  }
};

export const createUser = async (
  user: any, users: any, setUsers: any, setLoading?: any, cb?: any,
) => {
  try {
    setUsers({ status: "request", data: users.data || [] });
    setLoading?.(true);
    const res = await apiClient.post(endpoints.usersList, user);
    setLoading?.(false);
    setUsers({ status: "success", data: res.data || [] });
    message.success("User created successfully");
    cb?.();
  } catch (error: any) {
    setLoading?.(false);
    setUsers({ status: "failed", data: [] });
    showErrorMessage(error, "Failed to create user");
  }
};

export const updateUser = async (
  user: any, setLoading?: any, cb?: any,
) => {
  try {
    setLoading?.(true);
    await apiClient.patch(endpoints.usersUpdate.replace(":id", user.username),
      only(["name", "phone_number", "role", "region", "job_designation", "enabled"], user));
    setLoading?.(false);
    message.success("User updated successfully");
    cb?.();
  } catch (error: any) {
    setLoading?.(false);
    showErrorMessage(error, "Failed to update user");
  }
};
