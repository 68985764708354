import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const poaEditingAtom = atom({
  key: "poa-editing",
  default: { poa: [], poaValues: {} },
  effects_UNSTABLE: [persistAtom],
});
