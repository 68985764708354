import moment from "moment";
import { IStoreProfile } from "../../../global/interfaces";

export const decideStoreStatusExtra = (store: IStoreProfile, allocation_status: {[key: string]: string}) => {
  if (store) {
    switch (store?.status) {
      case allocation_status.LIVE:
        return `Store Opened: ${moment(store?.opening_at).format("MM/DD/YYYY")}`;
      case allocation_status.CLOSED:
        return `Store Closed: ${moment(store?.closing_at).format("MM/DD/YYYY")}`;
      case allocation_status.PAUSED:
        return `Store Paused: ${moment(store?.allocation_start_at).format("MM/DD/YYYY")}`;
      case allocation_status.PLANNING:
        return `Store Started: ${moment(store?.created_at).format("MM/DD/YYYY")}`;
      default:
        return undefined;
    }
  }
  return undefined;
};
