/* eslint-disable no-case-declarations */
import React from "react";
import { MinusOutlined } from "@ant-design/icons";
import moment from "moment";
import { IFlowDocCC } from "../../../../global/interfaces/IFlowDocCC";
import {
  errorFromFlowDocValue, flowDocColumnType, flowDocColumnValidations,
} from "./validations";
import CompactSelect from "../../../../components/CompactSelect";
import DartInput from "../../../../components/DartInput";
import { decideDepartmentName, decideDivisionName } from "../../../../utils/helpers";
import DartDatepicker from "../../../../components/DartDatepicker";

/* TODO: Move to interface
  Data Structure: {
    cc: "F1110-BLK" // Used outside of this function for selection columns and bulk edits
    brand: "UGG"
    buy: "Hello"
    channel: "Outlet"
    department: "Footwear"
    description: "W Classic Short"
    division: "Women's"
    date: "12/1/23"
    map_break: "Map Protected"
    gtmcollections: "Classic Heritage"
    klass: "Classic Boot"
    fulfilment_category: "New"
    subklass: "Short"
    tag: "GEORGE"
    tier: "Tier 4"
  }
*/

const columns = (props: {
  fieldValidations: any,
  enums: any,
  onFieldUpdate: (cc: string, fieldName: string, fieldValue: any, error: string) => void,
  availableSizeRuns: any,
}): Record<string, any>[] => (Object.values(props.fieldValidations) as any)
  ?.filter((e: any) => !e.hidden)
  ?.map((field: flowDocColumnType) => {
    return {
      key: field.apiName,
      dataIndex: field.apiName,
      title: (
        <>
          {field.uiName}
          {field.type !== "not-editable" ? (
            <span
              className="circle-dot"
              style={{ backgroundColor: field.type === "resets-entity" ? "rgb(252, 72, 72)" : "#19945D" }}
            />
          ) : ""}
        </>
      ),
      width: field.width,
      flexGrow: 1,
      align: "center",
      className: field.type,
      frozen: field.frozen,
      fixed: field.fixed,
      cellRenderer: (tableInfo: { rowData: IFlowDocCC }) => {
        const { rowData } = tableInfo;

        const extractDisplayLabel = (val?: string | number) => {
          switch (field.apiName) {
            case "division":
              return typeof val === typeof "string" ? decideDivisionName(val as any) : val;
            case "department":
              return typeof val === typeof "string" ? decideDepartmentName(val as any) : val;
            case "fulfilment_category":
              return ({
                NEWNESS: "Newness",
                REPLENISHMENT: "Replenishment",
              } as any)[val as any];
            default:
              return val;
          }
        };

        const extractValue = (val?: string | number) => {
          switch (field.apiName) {
            case "tier":
              return val || val === 0 ? {
                "Tier 1": 1,
                "Tier 2": 2,
                "Tier 3": 3,
                "Tier 4": 4,
                Special: 0,
              }[val?.toString()] : undefined;
            default:
              return val;
          }
        };

        let fieldValue = (rowData as any)?.[field.apiName];

        if (field.apiName === "map_break") {
          if (fieldValue === "1900-01-01" || fieldValue === "Map Protected") {
            fieldValue = moment("1900-01-01");
          } else {
            fieldValue = moment((rowData as any)?.[field.apiName], "YYYY-MM-DD");
          }
        } else if (field.apiName === "date") {
          fieldValue = moment((rowData as any)?.[field.apiName], "YYYY-MM-DD");
        }

        const formatDateValue = (value: any) => {
          if (field.apiName === "map_break" && !value) {
            return "1900-01-01";
          }
          if (field.apiName === "map_break") {
            return value.format("YYYY-MM-DD");
          }
          if (field.apiName === "date") {
            return value.format("YYYY-MM-DD");
          }
          return value;
        };

        const errorMessage = rowData.errors && rowData.errors?.find((err: any) => err.column === field.apiName);

        let filteredAvailableDropdownOptions: any;

        if (field.apiName === "size_run_id" && rowData?.division && rowData?.department) {
          const departments = props?.availableSizeRuns?.data?.[rowData?.division];
          const sizeRuns = departments?.[rowData?.department];
          filteredAvailableDropdownOptions = sizeRuns?.map((sizeRun: any) => { return { label: sizeRun.name, value: sizeRun.id }; });
        }

        return (
          <div className={`flow-doc-preview-cell ${field.apiName}-cell ${errorMessage && errorMessage?.error?.length > 0 ? "has-error" : ""}`}>
            {(() => {
              switch (field.input) {
                case "static":
                  return (
                    <span
                      key={fieldValue}
                      className={field.apiName}
                    >
                      {fieldValue || fieldValue === 0 ? fieldValue : NAComponent}
                    </span>
                  );
                case "dropdown":
                  return (
                    <CompactSelect
                      key={fieldValue}
                      popupContainer={document.body}
                      canClear={false}
                      selectOptions={filteredAvailableDropdownOptions || field.enum?.map((e: string | number) => {
                        return {
                          label: extractDisplayLabel(e),
                          value: extractValue(e),
                        };
                      })}
                      value={fieldValue || fieldValue === 0 ? { value: fieldValue } : undefined}
                      onChange={(value) => props.onFieldUpdate(
                        rowData?.cc,
                        field.apiName,
                        value,
                        errorFromFlowDocValue(
                          value, (flowDocColumnValidations() as any)[field.excelName], filteredAvailableDropdownOptions,
                        ),
                      )}
                    />
                  );
                case "text":
                  return (
                    <DartInput
                      key={fieldValue}
                      value={fieldValue}
                      onChange={(value) => props.onFieldUpdate(
                        rowData?.cc,
                        field.apiName,
                        value?.target?.value,
                        errorFromFlowDocValue(value?.target?.value, (flowDocColumnValidations() as any)[field.excelName]),
                      )}
                      useDebounce
                    />
                  );
                case "date":
                  return field.apiName === "map_break" && (fieldValue?.format("YYYY-MM-DD") === "1900-01-01" || fieldValue === "Map Protected") ? (
                    <div className="map-break-picker">
                      <span>Map Protected</span>
                      <DartDatepicker
                        key={fieldValue.toString()}
                        value={moment()}
                        onChange={(value) => props.onFieldUpdate(
                          rowData?.cc,
                          field.apiName,
                          formatDateValue(value),
                          errorFromFlowDocValue(formatDateValue(value), (flowDocColumnValidations() as any)[field.excelName]),
                        )}
                        pickerProps={{
                          getPopupContainer: () => document.body,
                          disabledDate: (current: any) => current && current < moment("2000-01-01", "YYYY-MM-DD"),
                          allowClear: false,
                        }}
                      />
                    </div>
                  ) : (
                    <DartDatepicker
                      key={fieldValue.toString()}
                      value={fieldValue.isValid() ? fieldValue : undefined}
                      onChange={(value) => props.onFieldUpdate(
                        rowData?.cc,
                        field.apiName,
                        formatDateValue(value),
                        errorFromFlowDocValue(formatDateValue(value), (flowDocColumnValidations() as any)[field.excelName]),
                      )}
                      pickerProps={{
                        allowClear: field.apiName === "map_break",
                        getPopupContainer: () => document.body,
                        disabledDate: (current: any) => current && current < moment("2000-01-01", "YYYY-MM-DD"),
                      }}
                    />
                  );
                default:
                  return (
                    <span
                      key={fieldValue}
                      className={field.apiName}
                    >
                      {fieldValue || fieldValue === 0 ? fieldValue : NAComponent}
                    </span>
                  );
              }
            })()}
          </div>
        );
      },
    };
  });

const NAComponent = <MinusOutlined style={{ fontSize: 14, color: "#C3C5C8" }} />;

export default columns;
